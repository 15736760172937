<template>
    <div class="y_login_1">
        <img src="../../../images/img_6.png" alt="">
    </div>
    <div class="y_authorzed_login_1">
        <div class="y_authorzed_login_2">
            <img src="../../../images/img_7.png" alt="">
        </div>
        <div class="y_authorzed_login_3">
            <router-link to="" class="y_authorzed_login_4">授权登录</router-link>
        </div>
    </div>
</template>

<style scoped>
    .y_login_1{
        width: 100%;
        position: relative;
        z-index: -1;
        height: 100vh;
    }
    .y_login_1 img{
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }
    .y_login_2{
        width: 6.6rem;
        background: #fff;
        overflow: hidden;
        border-radius: 0.2rem;
        height: 6.5rem;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
        position: absolute;
    }
    .y_login_3{
        padding: 0rem 0.4rem;
        display: flex;
        align-content: center;
        height: 100%;
        flex-wrap: wrap;
    }
    .y_login_4{
        width: 100%;
    }
    .y_login_5{
        width: 100%;
        margin-bottom: 0.4rem;
    }
    .y_login_5 h2{
        font-size: 0.36rem;
        color: #999;
    }
    .y_login_6{
        width: 100%;
        height: 0.9rem;
        overflow: hidden;
        background: #F4F4F4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 0.5rem;
        overflow: hidden;
    }
    .y_login_6 /deep/ input{
        flex: 1;
        background: transparent;
        border-radius: 0rem;
        border: none;
        height: 0.9rem;
        line-height: 0.9rem;
        font-size: 0.3rem;
        color: #333;
        padding: 0rem 0rem 0rem 0.4rem;
    }
    .y_login_7{
        margin-top: 0.4rem;
    }
    .y_login_8{
        width: 100%;
        margin-top: 0.4rem;
        height: 0.9rem;
        border-radius: 1rem;
        overflow: hidden;
        background: #FECE0A;
    }
    .y_login_8 p{
        width: 100%;
        color: #393536;
        font-size: 0.36rem;
        line-height: 0.9rem;
        font-weight: 600;
        text-align: center;
    }

    /*  */
    .y_my_change_phone_one_5{
        width: 3rem;
        text-align: center;
    }
    .y_my_change_phone_one_5 div{
        font-size: 0.3rem;
        color: #FECE0A;
    }

    .y_authorzed_login_1{
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 6rem;
        transform: translate(-50%,-50%);
    }
    .y_authorzed_login_2{
        width: 100%;
        height: 0.75rem;
        text-align: center;
    }
    .y_authorzed_login_2 img{
        height: 100%;
        object-fit: cover;
    }
    .y_authorzed_login_3{
        width: 100%;
        margin-top: 0.5rem;
        border-radius: 1rem;
        overflow: hidden;
    }
    .y_authorzed_login_4{
        width: 100%;
        height: 0.9rem;
        line-height: 0.9rem;
        text-align: center;
        background: #FECE0A;
        color:#393536;
        font-size: 0.36rem;
        font-weight: 600;
        display: block;
    }
</style>

<script>
    export default {
        data(){
            return {
            }
        },  
        components:{

        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
        },
        methods: {

        }
    }
</script>