<template>
    <div class="y_push_date_tg_1">
        <div class="y_push_date_tg_2">
            <div class="y_push_date_tg_3">
                <p>余额<span>{{ available_balance }}</span>元</p>
            </div>
            <div class="y_push_date_tg_4">
                <p @click="showDialog(true)">充值余额</p>
            </div>
        </div>
        <!-- 高级推送 轮播 -->
        <div class="y_push_date_gj_lb_1">
            <img src="../../../images/img_3.png" alt="">
        </div>
        <div class="y_push_date_gj_lb_4">
            <div class="y_push_date_gj_lb_2 y_push_date_gj_hf_1">
                <div class="y_push_date_gj_hf_2">
                    <p>已有<span>{{adspace_list.view_number_business}}</span>人浏览，人均浏览次数<span>{{(adspace_list.view_number_total_business/adspace_list.view_number_business).toFixed(0)}}</span>次</p>
                </div>
                <div class="y_push_date_gj_hf_2">
                    <p>推送时间:{{adspace_list.push_starttime}}~{{adspace_list.push_endtime}}</p>
                </div>
                <div class="y_push_date_gj_lb_5 y_push_date_gj_hf_3">
                    <div class="y_push_date_gj_lb_6">
                        <h2>推送倒计时：</h2>
                        <p><span>{{date_day}}</span>天<span>{{date_hour}}</span>时<span>{{date_minute}}</span>分<span>{{date_second}}</span>秒</p>
                    </div>
                    <h3 @click="djs_show(push_status)">+时</h3>
                </div>
                <div class="y_push_date_gj_hf_2">
                    <p>总点击:已扣费/总预算：<span>{{(cost_adspace_list.click_total_fee-cost_adspace_list.left_click_fee).toFixed(2)}}元 /  {{cost_adspace_list.click_total_fee}}元</span></p>
                </div>
                <div class="y_push_date_gj_hf_2">
                    <p>总曝光:已扣费/总预算：<span>{{(cost_adspace_list.exposure_total_fee-cost_adspace_list.left_exposure_fee).toFixed(2)}}元 /  {{cost_adspace_list.exposure_total_fee}}元</span></p>
                </div>
                <div class="y_push_date_gj_lb_5 y_push_date_gj_hf_3">
                    <div class="y_push_date_gj_lb_6">
                        <h2>已完成/预设总曝光量：</h2>
                        <p><span>{{cost_adspace_list.exposure_number-cost_adspace_list.left_exposure_number}} / {{cost_adspace_list.exposure_number}}</span></p>
                    </div>
                    <h3 @click="bgl_cilck">+曝光量</h3>
                </div>
                <div class="y_push_date_gj_hf_2">
                    <p>总点击人数：<span>{{cost_adspace_list.click_number-cost_adspace_list.left_click_number}}</span></p>
                </div>
            </div>

            <div class="y_push_date_gj_lb_9" :class="undergraduate_display == '0'?'':'y_display_none'">
                <div class="y_push_date_gj_lb_10">
                    <div class="y_push_date_gj_lb_11">
                        <h2>本科学校</h2>
                        <div class="y_push_date_gj_lb_12">
                            <div class="y_push_date_gj_lb_13">
                                <h2>每天每人曝光</h2>
                                <p>{{cost_adspace_list.undergraduate_exposure_price*1}}元</p>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                            <div class="y_push_date_gj_lb_13">
                                <h2>每人点击</h2>
                                <p>{{cost_adspace_list.undergraduate_click_price*1}}元</p>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="y_push_date_gj_lb_14" :class="item.school_type_text == 'undergraduate' ?'':'y_display_none'" v-for="(item,index) in school_list" :key="index">
                        <div class="y_push_date_gj_lb_15">
                            <h2>{{item.school_title}}</h2>
                            <div class="y_push_date_gj_lb_16">
                                <h2>推送给</h2>
                                <p>{{item.grade_level_texts_title}}</p>
                            </div>
                        </div>
                        <div class="y_push_date_gj_lb_17">
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>曝光人次</h2>
                                    <!-- <img src="../../../images/icon_21.png" alt=""> -->
                                </div>
                                <p>{{item.exposure_number - item.left_exposure_number}}</p>
                            </div>
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>点击人数</h2>
                                    <!-- <img src="../../../images/icon_21.png" alt=""> -->
                                </div>
                                <p>{{item.click_number-item.left_click_number}}</p>
                            </div>
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>已扣费</h2>
                                    <!-- <img src="../../../images/icon_21.png" alt=""> -->
                                </div>
                                <p>{{(item.click_total_fee-item.left_click_fee+item.exposure_total_fee-item.left_exposure_fee).toFixed(2)}}</p>
                            </div>
                        </div>
                        <div class="y_push_date_gj_lb_17" :class="bgl_display == '0'?'y_display_none':''">
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19 ">
                                    <h2>增加曝光量</h2>
                                </div>
                                <div class="y_push_date_gj_hf_4">
                                    <el-input type="number" v-model="item.expact_exposure_number" placeholder="请输入" @input="school_exposure"></el-input>
                                </div>
                            </div>
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>预计新增点击人数</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <p>{{item.expact_click_number}}</p>
                            </div>
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>预算新增</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <p>{{((item.expact_exposure_number*cost_adspace_list.undergraduate_exposure_price)*1+(item.expact_click_number*cost_adspace_list.undergraduate_click_price)*1).toFixed(2)}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="y_push_date_gj_lb_9" :class="specialty_display == '0'?'':'y_display_none'">
                <div class="y_push_date_gj_lb_10">
                    <div class="y_push_date_gj_lb_11">
                        <h2>专科学校</h2>
                        <div class="y_push_date_gj_lb_12">
                            <div class="y_push_date_gj_lb_13">
                                <h2>每天每人曝光</h2>
                                <p>{{cost_adspace_list.specialty_exposure_price*1}}元</p>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                            <div class="y_push_date_gj_lb_13">
                                <h2>每人点击</h2>
                                <p>{{cost_adspace_list.specialty_click_price*1}}元</p>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="y_push_date_gj_lb_14" :class="item.school_type_text == 'specialty' ?'':'y_display_none'" v-for="(item,index) in school_list" :key="index">
                        <div class="y_push_date_gj_lb_15">
                            <h2>{{item.school_title}}</h2>
                            <div class="y_push_date_gj_lb_16">
                                <h2>推送给</h2>
                                <p>{{item.grade_level_texts_title}}</p>
                            </div>
                        </div>
                        <div class="y_push_date_gj_lb_17">
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>曝光人次</h2>
                                    <!-- <img src="../../../images/icon_21.png" alt=""> -->
                                </div>
                                <p>{{item.exposure_number - item.left_exposure_number}}</p>
                            </div>
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>点击人数</h2>
                                    <!-- <img src="../../../images/icon_21.png" alt=""> -->
                                </div>
                                <p>{{item.click_number-item.left_click_number}}</p>
                            </div>
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>已扣费</h2>
                                    <!-- <img src="../../../images/icon_21.png" alt=""> -->
                                </div>
                                <p>{{(item.click_total_fee-item.left_click_fee+item.exposure_total_fee-item.left_exposure_fee).toFixed(2)}}</p>
                            </div>
                        </div>

                        <div class="y_push_date_gj_lb_17" :class="bgl_display == '0'?'y_display_none':''">
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19 ">
                                    <h2>增加曝光量</h2>
                                </div>
                                <div class="y_push_date_gj_hf_4">
                                    <el-input type="number" v-model="item.expact_exposure_number" placeholder="请输入" @input="school_exposure"></el-input>
                                </div>
                            </div>
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>预计新增点击人数</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <p>{{item.expact_click_number}}</p>
                            </div>
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>预算新增</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <p>{{((item.expact_exposure_number*cost_adspace_list.specialty_exposure_price)*1+(item.expact_click_number*cost_adspace_list.specialty_click_price)*1).toFixed(2)}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

        <div class="y_push_date_tg_19"></div>
        <div class="y_push_date_tg_20">
            <div class="y_push_date_tg_21">
                <div class="y_push_date_tg_22">
                    <p>预计新增总曝光人次<span>{{exposure_people_max}}</span>，预计新增总点击量<span>{{cilck_people_max}}</span></p>
                </div>
                <div class="font-s">
                    <div class="font-s1">新增预算总计<span>{{(Total_money*1).toFixed(2)}}</span><p v-show="price_comparison == '0'?'':true">元，余额不足，</p></div>
                    <h2 @click="showDialog(true)" v-show="price_comparison == '0'?'':true">点击充值</h2>
                </div>
                <div class="y_push_date_tg_24 y_number_places_4">
                    <h2 class="y_number_places_3" @click="back_to">返回</h2>
                    <p class="y_number_places_5" @click="submit">确定</p>
                </div>
            </div>
        </div>
        <!-- 余额不足 弹出 -->
        <div class="y_balance_not_pop_1" :class="balance_not_is == '0'?'y_display_none':'y_display_block'">
            <div class="y_balance_not_pop_2">
                <div class="y_balance_not_pop_3">
                    <p>预算总计<span>{{(Total_money*1).toFixed(2)}}</span>元，当前余额<span>{{principal_amount}}</span>元 余额不足，请先充足余额</p>
                </div>
                <div class="y_balance_not_pop_4">
                    <p class="y_balance_not_pop_5" @click="balance_not_back">返回</p>
                    <p class="y_balance_not_pop_6" @click="balance_not_recharge(true)">充值余额</p>
                </div>
            </div>
        </div>
        <!-- 公共弹窗 阴影-->
        <div class='y_comm_shadows' :class="comm_shadows_value == '1'?'y_comm_shadows_block':''"></div>
        <!-- 倒计时 -->
        <div class="y_push_date_zp_11" :class="djs_pop_value == '0'?'':'y_push_date_zp_11_active'">
            <div class="y_push_date_zp_12">
                <h2 :class="class_id == '1' ? '':'y_display_none'">轮播图截止时间延时到</h2>
                <h2 :class="class_id == '2' ? '':'y_display_none'">开平弹窗截止时间延时到</h2>
            </div>
            <div class="y_push_date_zp_13">
                <div class="y_push_date_zp_14 y_push_date_zp_14_1">
                    <el-input type="number" v-model="tc_date_year" :placeholder="push_endtime.year"></el-input>
                </div>
                <div class="y_push_date_zp_15">
                    <p>年</p>
                </div>
                <div class="y_push_date_zp_14 y_push_date_zp_14_2">
                    <el-input type="number" v-model="tc_date_month" :placeholder="push_endtime.month"></el-input>
                </div>
                <div class="y_push_date_zp_15">
                    <p>月</p>
                </div>
                <div class="y_push_date_zp_14 y_push_date_zp_14_2">
                    <el-input type="number" v-model="tc_date_day" :placeholder="push_endtime.day"></el-input>
                </div>
                <div class="y_push_date_zp_15">
                    <p>日</p>
                </div>
                <div class="y_push_date_zp_14 y_push_date_zp_14_2">
                    <el-input type="number" v-model="tc_date_time" :placeholder="push_endtime.time"></el-input>
                </div>
                <div class="y_push_date_zp_15">
                    <p>时</p>
                </div>
                <div class="y_push_date_zp_14 y_push_date_zp_14_2">
                    <el-input type="number" v-model="tc_date_minute" :placeholder="push_endtime.minute"></el-input>
                </div>
                <div class="y_push_date_zp_15">
                    <p>分</p>
                </div>
            </div>
            <div class="y_push_date_zp_16">
                <p class="y_push_date_zp_17" @click="djs_hide()">返回</p>
                <p class="y_push_date_zp_18" @click="djs_determine()">确定</p>
            </div>
        </div>
    </div>
    <kefu></kefu>
    <recharge v-show="dialog_visible" :dialog_visible="dialog_visible" @dialogVisibleEvent="showDialog"></recharge>
</template>

<style scoped>
    .y_push_date_tg_1{
        width: 100%;
        min-height: 100vh;
        background: #F4F5F7;
    }
    .y_push_date_tg_2{
        padding: 0rem 0.45rem;
        height: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_push_date_tg_3 p{
        font-size: 0.28rem;
        color: #333;
    }
    .y_push_date_tg_3 p span{
        color:#FECE0A;
        font-size: .28rem;
    }
    .y_push_date_tg_4 p{
        font-size: 0.28rem;
        color: #333;
        text-decoration:underline
    }
    .y_push_date_tg_5{
        padding: 0.28rem 0.25rem;
        background: #fff;
    }
    .y_push_date_tg_6{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.15rem;
    }
    .y_push_date_tg_7{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_date_tg_7 p{
        font-size: 0.24rem;
        color: #999;
    }
    .y_push_date_tg_7 h2{
        margin-left: 0.15rem;
        color: #333;
        font-weight: 600;
        font-size: 0.28rem;
    }
    .y_push_date_tg_7 h2 span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_8{
        display: inline-block;
        background: #FECE0A;
        padding: 0rem 0.15rem;
        height: 0.46rem;
        border-radius: 0.1rem;
    }
    .y_push_date_tg_8 p{
        line-height: 0.46rem;
        color: #333;
        font-size: 0.26rem;
    }
    .y_push_date_tg_9{
        width: 100%;
        margin-bottom: 0.15rem;
    }
    .y_push_date_tg_9 p{
        color: #999;
        font-size: 0.24rem;
        line-height: 0.3rem;
    }
    .y_push_date_tg_9 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_10{
        width: 100%;
    }
    .y_push_date_tg_11{
        padding: 0rem 0.35rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.8rem;
    }
    .y_push_date_tg_11 p{
        color:#333;
        font-size: 0.28rem;
    }
    .y_push_date_tg_11 h2{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_12{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.8rem;
    }
    .y_push_date_tg_12 p{
        flex: 1;
        text-align: center;
        color: #999;
        font-size: 0.26rem;
    }
    .y_push_date_tg_12 p span{
        color: #FECE0A;
        font-size: 0.26rem;
    }
    .y_push_date_tg_13{
        width: 100%;
        background: #fff;
        padding-bottom: 0.4rem;
    }
    .y_push_date_tg_14{
        display: flex;
        justify-content: flex-start;
    }
    .y_push_date_tg_15{
        flex:1;
    }
    .y_push_date_tg_16{
        width: 100%;
    }
    .y_push_date_tg_16 h2{
        width: 100%;
        line-height: 1rem;
        text-align: center;
        color: #333;
        font-size: 0.28rem;    
    }
    .y_push_date_tg_16_color h2{
        color: #FECE0A;
    } 
    .y_push_date_tg_17{
        width: 100%;
    }
    .y_push_date_tg_17>p{
        font-size: 0.28rem;
        color: #333;
        width: 100%;
        text-align: center;
    }
    .y_push_date_tg_18{
        width: 100%;
        text-align: center;
        margin-top: 0.25rem;
    }
    .y_push_date_tg_18 /deep/ input{
        width: 1.6rem;
        height: 0.8rem;
        border-radius: 0.1rem;
        background: #F7F8FA;
        overflow: hidden;
        padding: 0rem;
        line-height: 0.8rem;
        margin: 0 auto;
        font-size: 0.28rem;
        color: #ccc;
        text-align: center;
        border: none;
    }
    .y_push_date_tg_18 p{
        color: #FECE0A;
        font-size: 0.28rem;
        line-height: 0.8rem;
    }
    .y_push_date_tg_19{
        width: 100%;
        height: 5rem;
        background: transparent;
    }
    .y_push_date_tg_20{
        width: 100%;
        height: 2.7rem;
        background: #fff;
        position: fixed;
        left: 0rem;
        bottom: 0rem;
    }
    .y_push_date_tg_21{
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        padding: 0rem 0.25rem;
        height: 100%;
    }
    .y_push_date_tg_22{
        width: 100%;
        text-align: center;
    }
    .y_push_date_tg_22 p{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_date_tg_22 p span{
        color:#FECE0A;
        font-size: 0.28rem;
    }
    .font-s{
        display: flex;
        margin-top: 0.2rem;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .font-s p{
        color: #333;
        font-size: 0.28rem;
    }
    .font-s p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .font-s h2{
        color: #FECE0A;
        font-size: 0.28rem;
        text-decoration:underline;
    }
    .y_push_date_tg_24{
        width: 100%;
        margin-top: 0.3rem;
    }
    .y_push_date_tg_24 p{
        height: 0.8rem;
        background: #FECE0A;
        line-height: 0.8rem;
        width: 100%;
        border-radius: 0.1rem;
        text-align: center;
        font-size:0.32rem;
        color: #333;
        font-weight: 600;
    }

    /* 高级推送 轮播 */
    .y_push_date_gj_lb_1{
        width: 100%;
        height: 2rem;
    }
    .y_push_date_gj_lb_1 img{
        width: 100%;
        object-fit: cover;
    }
    .y_push_date_gj_lb_4{
        width: 100%;
        background: #fff;
    }
    .y_push_date_gj_lb_2{
        padding: 0rem 0.35rem;
        border-bottom: 0.01rem solid #EEEEEE;
    }
    .y_push_date_gj_lb_3{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1rem;
    }
    .y_push_date_gj_lb_3 h2{
        color: #999;
        font-size: 0.28rem;
        margin-right: 0.25rem;
    }
    .y_push_date_gj_lb_3 p{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_date_gj_lb_5{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.3rem;
    }
    .y_push_date_gj_lb_6{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_date_gj_lb_6 h2{
        color: #999;
        font-size: 0.28rem;
        margin-right: 0.2rem;
    }
    .y_push_date_gj_lb_6 p{
        color: #333333;
        font-size: 0.28rem;
    }
    .y_push_date_gj_lb_6 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_gj_lb_5 h3{
        line-height: 0.45rem;
        background: #FECE0A;
        display: inline-block;
        border-radius: 0.1rem;
        padding: 0rem 0.3rem;
        color: #333;
        font-size: 0.26rem;
    }
    .y_push_date_gj_lb_7{
        width: 100%;
        height: 1.5rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_date_gj_lb_8{
        flex: 1;
        display: flex;
        height: 100%;
        align-content: center;
        flex-wrap: wrap;
    }
    .y_push_date_gj_lb_8 h2{
        width: 100%;
        text-align: center;
        color: #999;
        font-size: 0.28rem;
    }
    .y_push_date_gj_lb_8 p{
        width: 100%;
        text-align: center;
        margin-top: 0.2rem;
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_gj_lb_9{
        padding: 0rem 0.25rem 0.3rem;
    }
    .y_push_date_gj_lb_10{
        padding: 0rem 0.2rem;
        background: #FECE0A;
        border-radius: 0.1rem;
        padding-bottom: 0.3rem;
    }
    .y_push_date_gj_lb_11{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1rem;
    }
    .y_push_date_gj_lb_11>h2{
        font-size: 0.32rem;
        color: #333;
        font-weight: 600;
        width: 1.5rem;
    }
    .y_push_date_gj_lb_12{
        display: flex;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        background: rgba(255,255,255,0.8);
        border-radius: 0.1rem;
        height: 0.6rem;
    }
    .y_push_date_gj_lb_13{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 0.2rem;
    }
    .y_push_date_gj_lb_13 h2{
        color: #999;
        font-size: 0.24rem;
    }
    .y_push_date_gj_lb_13 p{
        margin-right: 0.1rem;
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_gj_lb_13 img{
        width: 0.2rem;
        height: 0.2rem;
        margin-left: 0.05rem;
    }
    .y_push_date_gj_lb_14{
        border-radius: 0.1rem;
        overflow: hidden;
        background: #fff;
        margin-bottom: 0.2rem
    }
    .y_push_date_gj_lb_15{
        height: 0.9rem;
        background: #FFFBED;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_date_gj_lb_15>h2{
        margin-left: 0.3rem;
        color: #333;
        font-size: 0.28rem;
        min-width: 2rem;
        font-weight: 600;
        max-width: 1rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
    .y_push_date_gj_lb_16{
        display: flex;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        margin-left: 0.1rem;
    }
    .y_push_date_gj_lb_16 h2{
        font-size: 0.28rem;
        color: #999;

    }
    .y_push_date_gj_lb_16 p{
        color: #FECE0A;
        font-size: 0.28rem;
        margin-left: 0.1rem;
    }
    .y_push_date_gj_lb_17{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1.5rem;
    }
    .y_push_date_gj_lb_18{
        flex:1;
        flex-wrap: wrap;
        display: flex;
        height: 100%;
        align-content: center;
    }
    .y_push_date_gj_lb_19{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .y_push_date_gj_lb_19>h2{
        color: #333333;
        font-size: 0.25rem;
    }
    .y_push_date_gj_lb_19 img{
        width: 0.2rem;
        height: 0.2rem;
        /* margin-left: 0.1rem; */
    }
    .y_push_date_gj_lb_18 p{
        width: 100%;
        text-align: center;
        color: #FECE0A;
        font-size: 0.36rem;
        margin-top: 0.2rem;
    }
    /* 高级推送 横幅 */
    .y_push_date_gj_hf_1{
        padding-top: 0.3rem;
        border-bottom: none;
    }
    .y_push_date_gj_hf_2{
        width: 100%;
        margin-bottom: 0.25rem;
    }
    .y_push_date_gj_hf_2 p{
        color: #999;
        font-size: 0.28rem;
    }
    .y_push_date_gj_hf_2 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_gj_hf_3{
        padding-bottom: 0.15rem;
    }
    .y_push_date_gj_hf_4{
        width: 100%;
        text-align: center;
        /* margin-top: 0.15rem; */
    }
    .y_push_date_gj_hf_4 /deep/ input{
        width: 1.6rem;
        height: 0.6rem;
        padding: 0rem;
        text-align: center;
        line-height: 0.6rem;
        color: #333;
        font-size: 0.28rem;
        background: #F7F8FA;
        border-radius: 0rem;
        border: none;
        margin: 0rem auto;
    }
    .y_push_date_gj_hf_4 /deep/ input::placeholder{
        font-size: 0.24rem;
        color: #ccc;
    } 

    .y_push_date_zp_11{
        width: 6.4rem;
        height: 3.6rem;
        background: #fff;
        border-radius: 0.1rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 20;
        display: none;
        flex-wrap: wrap;
        align-content: center;
    }
    .y_push_date_zp_11_active{
        display: flex;
    }
    .y_push_date_zp_12{
        width: 100%;
        text-align: center;
    }
    .y_push_date_zp_12 h2{
        color:#999;
        font-size: 0.3rem;
    }
    .y_push_date_zp_13{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 0.3rem;
    }
    .y_push_date_zp_14{
        background: #F7F8FA;
        height: 0.8rem;
        border-radius: 0.1rem;
        overflow: hidden;
    }
    .y_push_date_zp_14 input{
        height: 0.8rem;
        padding: 0rem 0.1rem 0rem 0.15rem;
        background: transparent;
        width: 100%;
        border: none;
        font-size: 0.28rem;
        color: #FECE0A;
    }
    .y_push_date_zp_14 /deep/ input{
        height: 0.8rem;
        padding: 0rem 0.1rem 0rem 0.15rem;
        background: transparent;
        width: 100%;
        border: none;
        font-size: 0.28rem;
        color: #FECE0A;
    }
    .y_push_date_zp_14_1{
        width: 1rem;
    }
    .y_push_date_zp_14_2{
        width: 0.6rem;
    }
    .y_push_date_zp_15{
        display: inline-block;
        margin: 0rem 0.1rem;
    }
    .y_push_date_zp_15 p{
        font-size: 0.28rem;
        color: #333;
    }
    .y_push_date_zp_16{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.35rem;
        width: 100%;
    }
    .y_push_date_zp_16 p{
        width: 2rem;
        height: 0.7rem;
        border-radius: 1rem;
        text-align: center;
        line-height: 0.7rem;
        font-size: 0.3rem;
    }
    .y_push_date_zp_17{
        background: #ECECEC;
        color: #666;
        margin-right: 0.1rem;
    }
    .y_push_date_zp_18{
        color: #333;
        background: #FECE0A;
    }
    .y_push_date_zp_19_active{
        display: flex;
    }
    .y_number_places_3 {
        flex: 1;
        height: 0.8rem;
        background: #ccc;
        line-height: 0.8rem;
        width: 100%;
        border-radius: 0.1rem;
        text-align: center;
        font-size: 0.32rem;
        color: #333;
        font-weight: 600;
        margin-right: 0.2rem;
    }
    .y_number_places_4{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_number_places_5{
        flex: 1;
    }
    .y_display_none{
        display: none;
    }
    .font-s1{
        color: #333;
        font-size: 0.28rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .font-s1>span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .font-s1>p{
        color: #333;
        font-size: 0.28rem;
    }
</style>

<script>
    import kefu from '../../components/kefu/kefu'
    import recharge from '../../components/recharge/recharge'
    import {user_info} from '../../api/request.js' // 用户信息
    import {adspace_info} from '../../api/request.js' // 广告位详情，高级推广
    import {renew_adspace} from '../../api/request.js' // 广告位的续费和修改
    import {school_grade_list} from '../../api/request.js'   // 所有学校和年级列表  
    export default {
        data(){
            return {
                input: '',
                //公共 钱包充值
                dialog_visible: false,
                available_balance:'',

                recruit_id:'',
                push_status:'',
                adspace_list:'',
                cost_adspace_list:'',
                school_list:'',
                class_id:'1', // 1是轮播图 2是开屏弹窗
                comm_shadows_value:'0',
                djs_pop_value:'0',
                date_day:'',     // 剩余 天
                date_hour:'',    // 剩余 时
                date_minute:'',  // 剩余 分
                date_second:'',  // 剩余 秒

                tc_date_year:'',  // 弹窗时间 年
                tc_date_month:'', // 弹窗时间 月
                tc_date_day:'', // 弹窗时间 日
                tc_date_time:'', // 弹窗时间 时
                tc_date_minute:'', // 弹窗时间 分
                recruit_date_new:'',  // 新增时间
                news_total_price:'0',  // 新曝光总价
                news_total_num:'0',   // 新曝光总量
                bgl_display:'0',
                push_endtime:'',      // placeholder 推送
                undergraduate_display:'0',  // 没有年级时隐藏整个年级学校
                specialty_display:'0',      // 没有年级时隐藏整个年级学校

                exposure_people_max:'0',    // 曝光人数至多
                cilck_people_max:'0', // 点击人数至多
                Total_money:'0',    // 预算总价
                price_comparison:'0',  // 价格比较 默认为0不显示
                principal_amount:'',  // 本金金额
                balance_not_is:'0',  // 余额不足
                user:'',
            }
        },  
        components:{
            kefu,
            recharge,
        },
        created(){
            // 获取页面传参 推送类型
            if(this.$route.query.recruit_id !== undefined){
                this.recruit_id = this.$route.query.recruit_id
                this.push_status = this.$route.query.push_status
            }
        },
        mounted(){
            let token = this.$cookies.get('token')
            var that = this;
            let user = JSON.parse(sessionStorage.getItem('user'))
            that.user = user
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            that.token = token
            // if(that.user == 'user'){
            //     that.principal_amount = that.user.balance*1 + that.user.bonus_fee*1
            // }else{
            //     let user = JSON.parse(sessionStorage.getItem('user'))
            //     that.user = user
            //     that.principal_amount = that.user.balance*1 + that.user.bonus_fee*1
            // }
            let recruit_id = this.recruit_id
            user_info({token:token}).then((res)=>{
                console.log(res,'用户信息')
                let balance = res.data.balance  // 	余额
                let bonus_fee = res.data.bonus_fee // 奖励金
                let frozen_fee = res.data.frozen_fee  // 冻结金额
                let deposit_fee = res.data.deposit_fee  // 押金金额
                let available_balance = ''      // 可用余额

                this.balance = balance
                this.bonus_fee = bonus_fee
                this.frozen_fee = frozen_fee
                this.deposit_fee = deposit_fee
                this.available_balance = balance*1 + bonus_fee*1
                this.user = res.data
                this.principal_amount = res.data.balance*1 + res.data.bonus_fee*1
            })
            adspace_info({token:token,adspace_id:recruit_id}).then((res)=>{
                console.log(res,'推送中-高级推广,横幅 ')
                this.adspace_list = res.adspace
                this.cost_adspace_list = res.cost_adspace

                this.timestampToTime(res.adspace.push_endtime_stamptime*1)

                this.push_endtime = {year:res.adspace.push_endtime.slice(0,4),month:res.adspace.push_endtime.slice(5,7),day:res.adspace.push_endtime.slice(8,10),time:res.adspace.push_endtime.slice(11,13),minute:res.adspace.push_endtime.slice(14,16)}
                let school_list = res.cost_adspace_school
                let undergraduate_num = '0'
                let specialty_num = '0'
                school_grade_list({city_id:sessionStorage.getItem('city_id_chun')}).then((res_school)=>{
                    console.log(res_school,'所有学校和年级列表')
                    let school_undergraduate = res_school.school_undergraduate
                    let school_specialty = res_school.school_specialty
                    for(let i=0;i<school_undergraduate.length;i++){
                        for(let k=0;k<school_list.length;k++){
                            if(school_list[k].school_type_text == 'undergraduate'){
                                if(school_list[k].school_id == school_undergraduate[i].school_id){
                                    school_list[k].expact_exposure_number = ''
                                    school_list[k].expact_click_number = school_undergraduate[i].expact_click_number
                                }
                            }
                        }
                    }
                    for(let i=0;i<school_specialty.length;i++){
                        for(let k=0;k<school_list.length;k++){
                            if(school_list[k].school_type_text == 'specialty'){
                                if(school_list[k].school_id == school_specialty[i].school_id){
                                    school_list[k].expact_exposure_number = ''
                                    school_list[k].expact_click_number = school_specialty[i].expact_click_number
                                }
                            }
                        }
                    }

                    console.log(school_list,'1111---')
                    for(let i=0;i<school_list.length;i++){
                        school_list[i].news_num = ''
                        school_list[i].grade_title = []
                        for(let p=0;p<school_list[i].grade_list.length;p++){
                            school_list[i].grade_title.push(school_list[i].grade_list[p].title)
                        }
                        if(school_list[i].school_type_text == "undergraduate"){
                            undergraduate_num++
                        }
                        if(school_list[i].school_type_text == "specialty"){
                            specialty_num++
                        }
                    }
                    if(undergraduate_num == '0'){
                        this.undergraduate_display = '1'
                    }
                    if(specialty_num == '0'){
                        this.specialty_display = '1'
                    }
                    this.school_list = school_list
                })
                
            })
        },
        methods: {
            // 充值
            showDialog(visible) {
                this.dialog_visible = visible;
            },
            // 招聘倒计时
            timestampToTime (timestamp) {
                setInterval(()=>{
                    let time = timestamp*1 - parseInt(new Date().getTime() / 1000);
                    let day = parseInt(time / (60*60*24))
                    let hour = parseInt((time - day*60*60*24 ) / (60*60))
                    let minute = parseInt((time - day *60*60*24 - hour *60*60) / 60)
                    let second = parseInt((time - day *60*60*24 - hour *60*60 - minute*60))
                    if(timestamp*1 < parseInt(new Date().getTime()/ 1000)){
                        this.date_day = '0'
                        this.date_hour = '0'
                        this.date_minute = '0'
                        this.date_second = '0'
                    }{
                        this.date_day = day
                        this.date_hour = hour
                        this.date_minute = minute
                        this.date_second = second
                    }
                },1000)
            },
            // +曝光量 价格计算
            budget_money(){
                let school_list = this.school_list
                let exposure_people_max = ''
                let cilck_people_max = ''
                let Total_money = ''
                let cost_adspace_list = this.cost_adspace_list
                console.log(school_list)
                for(let i=0;i<school_list.length;i++){
                    exposure_people_max = exposure_people_max*1 + school_list[i].expact_exposure_number*1
                    cilck_people_max = cilck_people_max*1 + school_list[i].expact_click_number*1
                    if(school_list[i].school_type_text == "undergraduate"){
                        Total_money = Total_money*1 + ((cost_adspace_list.undergraduate_exposure_price*1*school_list[i].expact_exposure_number*1)+(cost_adspace_list.undergraduate_click_price*1*school_list[i].expact_click_number*1))
                    }
                    console.log(exposure_people_max,cilck_people_max)
                    if(school_list[i].school_type_text == "specialty"){
                        Total_money = Total_money*1 + ((cost_adspace_list.specialty_exposure_price*1*school_list[i].expact_exposure_number*1)+(cost_adspace_list.specialty_click_price*1*school_list[i].expact_click_number*1))
                    }
                }
                this.exposure_people_max = exposure_people_max
                this.cilck_people_max = cilck_people_max
                this.Total_money = Total_money
                let principal_amount = this.principal_amount
                if(principal_amount*1 < Total_money*1){
                    this.price_comparison = '1'
                }else{
                    this.price_comparison = '0'
                }
            },
            // 余额不足弹出 返回
            balance_not_back(){
                this.comm_shadows_value = '0'
                this.balance_not_is = '0'
            }, 
            // 学校曝光量 总金额
            school_exposure(){
               this.budget_money()
            },  
            // +时 选择
            djs_show(select_is){
                console.log(select_is)
                this.comm_shadows_value = '1'
                this.djs_pop_value = '1'
            },
            // 倒计时 弹窗 关闭
            djs_hide(){
                this.comm_shadows_value = '0'
                this.djs_pop_value = '0'
            },
            // 增加曝光量 显示
            bgl_cilck(){
                this.bgl_display = '1'
            },
            // 倒计时确定
            djs_determine(){
                let tc_date_year = this.tc_date_year
                let tc_date_month = this.tc_date_month
                let tc_date_day = this.tc_date_day
                let tc_date_time = this.tc_date_time
                let tc_date_minute = this.tc_date_minute
                let push_endtime_stamptime = this.adspace_list.push_endtime_stamptime
                let date = `${tc_date_year}-${tc_date_month}-${tc_date_day} ${tc_date_time}:${tc_date_minute}:00`
                let time1 = Date.parse(date)
                if(push_endtime_stamptime*1000>time1*1){
                    this.$message({
                        message: '请输入正确时间',
                        duration:'2000'
                    })
                    return
                }else{
                    this.recruit_date_new = date
                    this.tc_date_year_one = tc_date_year
                    this.tc_date_month_one = tc_date_month
                    this.tc_date_day_one = tc_date_day
                    this.tc_date_time_one = tc_date_time
                    this.tc_date_minute_one = tc_date_minute

                }
                this.comm_shadows_value = '0'
                this.djs_pop_value = '0'
                console.log(push_endtime_stamptime)
            },
            // 返回
            back_to(){
                this.$router.go(-1)
            },
            // 提交
            submit(){
                let token = this.token
                let principal_amount = this.principal_amount
                let Total_money = this.Total_money
                if(principal_amount*1 < Total_money*1){
                    this.comm_shadows_value = '1'
                    this.balance_not_is = '1'
                    return
                }
                let adspace_id = this.recruit_id
                let push_endtime = this.recruit_date_new
                let old_time = this.adspace_list.push_endtime

                let school_list = this.school_list
                let school_json = []
                for(let i=0;i<school_list.length;i++){
                    school_json.push({school_id:school_list[i].school_id,exposure_number:school_list[i].expact_exposure_number,school_type_text:school_list[i].school_type_text})
                }
                if(push_endtime == ''){
                    renew_adspace({token,adspace_id,push_endtime:old_time,school_json:JSON.stringify(school_json)}).then((res)=>{
                        console.log(res,'横幅1')
                        if(res.error_code == '0'){
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                            var that = this;
                            setTimeout(function () {
                                that.$router.push({path:'/'}) 
                            },1000);
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else{
                    renew_adspace({token,adspace_id,push_endtime,school_json:JSON.stringify(school_json)}).then((res)=>{
                        console.log(res,'横幅2')
                        if(res.error_code == '0'){
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                            var that = this;
                            setTimeout(function () {
                                that.$router.push({path:'/'}) 
                            },1000);
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }

            },
        },
    }
</script>