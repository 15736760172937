<template>
    <div class="y_index">
        <!-- <div class='y_index_1'>
            <router-link class='y_index_2' to='/'>如何推送？</router-link>
            <router-link class='y_index_2' to='/'>如何收费？</router-link>
        </div> -->
        <push_charges></push_charges>
        <div class="y_recruit_1">
          <img src="../../../images/img_3.png" alt="">
        </div>
        <div class='y_recruit_two_1 y_display_none' :class="index_val == '0'? 'y_display_block':''">
            <div class='y_recruit_two_2'>
                <div class='y_recruit_two_3'>
                    <h2>招聘截止时间</h2>
                    <!-- <h3>发布招聘固定基础费用<span>N</span>元</h3> -->
                </div>
                <div class='y_recruit_two_4' @click="pop_time_one_block">
                    <p>{{time_one_Value}}</p>
                    <img src="../../../images/icon_12.png" alt="">
                </div>
            </div>
            <div class='y_recruit_two_5'>
                <div class='y_recruit_two_6'>
                    <div class='y_recruit_two_7'>
                        <p>*</p>
                        <h2>设定咨询人数上限</h2>
                    </div>
                    <div class='y_recruit_two_8'>
                        <p>每人咨询消耗<span>{{common_push_consulting_price}}</span>元</p>
                    </div>
                </div>
                <div class='y_recruit_two_9'>
                    <div class='y_recruit_two_10'>
                        <el-input type="text" placeholder="请输入" @input="zx_input"  v-model="zx_number_people_value"></el-input>
                        <p>人</p>
                    </div>
                    <div class='y_recruit_two_8'>
                        <p>预算<span>{{zx_number_people_money}}</span>元</p>
                    </div>
                </div>
                <div class='y_recruit_two_11'>
                    <h2>友情说明</h2>
                    <p>{{business_consulting_info}}</p>
                </div>
            </div>

            <div class='y_push_setting_1'>
                <div class='y_push_setting_2'>
                    <div class='y_push_setting_3'>
                        <p>给每人最多推送</p>
                    </div>
                    <div class='y_push_setting_4' @click="pop_one_block">
                        <p>{{onepeoples_max}}次</p>
                        <img src="../../../images/icon_13.png" alt="">
                    </div>
                </div>
            </div>
            <div class='y_push_setting_8'>
                <div class='y_push_setting_14'>
                    <div class='y_push_setting_15 y_flex_2'>
                        <p>单次曝光</p>
                    </div>
                    <div class='y_push_setting_15 y_flex_1'>
                        <p>设置曝光量</p>
                    </div>
                    <div class='y_push_setting_15 y_flex_1'>
                        <p>预算</p>
                    </div>
                </div>
            </div>
            <div class='y_push_setting_16'>
                <div class='y_push_setting_18'>
                    <div class='y_push_setting_17 y_flex_2'>
                        <p>{{common_push_exposure_price}}元</p>
                    </div>
                    <div class='y_push_setting_17 y_push_setting_19 y_flex_1'>
                        <el-input v-model="num_input" placeholder="请输入" @input="num_select"></el-input>
                    </div>
                    <div class='y_push_setting_17 y_push_setting_20 y_flex_1'>
                        <p>{{budget}}元</p>
                    </div>
                </div>
            </div>
            <div class="y_topics_select_three_1">
                <div class="y_topics_select_three_2">
                    <p>您选择的<span>{{list_select_length}}</span>个话题的最近7天浏览总人数为<span>M</span>，浏览总量为Q。</p>
                    <p>固定提示语*****</p>
                </div>
            </div>

        </div>  



        <div class="y_topics_select_three_0 y_display_none" :class="index_val == '1'? 'y_display_block':''">
            <div class='y_recruit_two_2' :class="from_table == 'recruit' ? '':'y_display_none'">
                <div class='y_recruit_two_3'>
                    <h2>招聘截止时间</h2>
                    <!-- <h3>发布招聘固定基础费用<span>N</span>元</h3> -->
                </div>
                <div class='y_recruit_two_4' @click="pop_time_one_block">
                    <p>{{time_one_Value}}</p>
                    <img src="../../../images/icon_12.png" alt="">
                </div>
            </div>
            <!-- <div class='y_recruit_two_5'>
                <div class='y_recruit_two_6'>
                    <div class='y_recruit_two_7'>
                        <p>*</p>
                        <h2>设定咨询人数上限</h2>
                    </div>
                    <div class='y_recruit_two_8'>
                        <p>每人咨询消耗<span>N</span>元</p>
                    </div>
                </div>
                <div class='y_recruit_two_9'>
                    <div class='y_recruit_two_10'>
                        <el-input v-model="zx_people_num_input" placeholder="请输入" @input="zx_people_num_select"></el-input>
                        <p>人</p>
                    </div>
                    <div class='y_recruit_two_8'>
                        <p>预算<span>N</span>元</p>
                    </div>
                </div>
            </div> -->
            <div class='y_push_setting_1'>
                <div class='y_push_setting_2'>
                    <div class='y_push_setting_3'>
                        <p>给每人最多推送</p>
                    </div>
                    <div class='y_push_setting_4' @click="pop_one_block">
                        <p>{{onepeoples_max}}次</p>
                        <img src="../../../images/icon_13.png" alt="">
                    </div>
                </div>
            </div>
            <div class='y_push_setting_5'>
                <h2>推送时间</h2>
                <div class='y_push_setting_6'>
                    <div class='y_push_setting_7' @click="pop_time_two_block">
                        <p>{{time_two_Value}}</p>
                    </div>
                    <p>-</p>
                    <div class='y_push_setting_7' @click="pop_time_three_block">
                        <p>{{time_three_Value}}</p>
                    </div>
                </div>
            </div>
            <div class='y_push_setting_8'>
                <div class='y_push_setting_14'>
                    <div class='y_push_setting_15 y_flex_2'>
                        <p>单次曝光</p>
                    </div>
                    <div class='y_push_setting_15 y_flex_1'>
                        <p>设置曝光量</p>
                    </div>
                    <div class='y_push_setting_15 y_flex_1'>
                        <p>预算</p>
                    </div>
                </div>
            </div>
            <div class='y_push_setting_16'>
                <div class='y_push_setting_18'>
                    <div class='y_push_setting_17 y_flex_2'>
                        <p>{{price}}元</p>
                    </div>
                    <div class='y_push_setting_17 y_push_setting_19 y_flex_1'>
                        <el-input v-model="num_input" placeholder="请输入" @input="num_select"></el-input>
                    </div>
                    <div class='y_push_setting_17 y_push_setting_20 y_flex_1'>
                        <p>{{budget}}元</p>
                    </div>
                </div>
            </div>
            <div class="y_topics_select_three_1">
                <div class="y_topics_select_three_2">
                    <p>您选择的<span>{{list_select_length}}</span>个话题的最近7天浏览总人数为<span>{{ days_7_total_scan }}</span>，浏览总量为{{days_total_scan}}。</p>
                    <p>{{business_side_fixed_cues}}</p>
                </div>
            </div>
        </div>

        <!-- 时间选择 最小值 -->
        <div class='y_pop_time_min' :class='pop_time_two_value == "0"?"":"y_pop_time_min_active"'>
            <van-datetime-picker v-model="currentDate" disabled="disabled" type="datetime" title="" :min-date="minDate" @confirm='pop_time_two_dete' @cancel='pop_time_two_cancel'/>
        </div>
        <!-- 时间选择 最大值 --> 
        <div class='y_pop_time_min' :class='pop_time_three_value == "0"?"":"y_pop_time_min_active"'>
            <van-datetime-picker v-model="currentDate" disabled="disabled" type="datetime" title="" :min-date="minDate" @confirm='pop_time_three_dete' @cancel='pop_time_three_cancel'/>
        </div>
        <!-- 公共 阴影 -->
        <div class='y_recruit_two_shade' :class="shade_value == '0'?'':'y_recruit_two_shade_block'"></div>
        
        <!-- 公共弹窗 阴影-->
        <div class='y_comm_shadows' :class="comm_shadows_value == '1'?'y_comm_shadows_block':''"></div>
        <!-- 弹窗 余额充足 -->
        <!-- <div class='y_recruit_two_pop' :class="pop_one_value == '0'?'':'y_recruit_two_pop_block'">
            <div class='y_recruit_two_pop_one_1'>
                <div class='y_recruit_two_pop_one_2'>
                    <p>喵君将尽快审核完毕，<br>审核通过后自动发布</p>
                </div>
                <div class='y_recruit_two_pop_one_3'>
                    <p class='y_recruit_two_pop_one_4' @click="pop_one_none">返回</p>
                    <p class='y_recruit_two_pop_one_5'>提交审核</p>
                </div>
            </div>
        </div> -->
        <!-- 弹窗 不足余额 -->
        <div class='y_recruit_two_pop'>
            <div class='y_recruit_two_pop_one_1'>
                <div class='y_recruit_two_pop_one_2'>
                    <p>预算总计<span>元，当前余额</span>元<br>余额不足，请先充足余额</p>
                </div>
                <div class='y_recruit_two_pop_one_3'>
                    <p class='y_recruit_two_pop_one_4'>返回</p>
                    <p class='y_recruit_two_pop_one_5'>充值余额</p>
                </div>
            </div>
        </div>
        <!-- 弹窗 友情说明 -->
        <div class="y_recruit_two_tips" :class="tips_value == '0'? '':'y_recruit_two_tips_block'">
            <div class="y_recruit_two_tips_1">
                <h2>友情说明</h2>
            </div>
            <div class="y_recruit_two_tips_2">
                <p>友情说明文字友情说明文字友情说明文字友情说明文字友情说明文字友情说明文字友情说明文字友情说明文字友情说明文字友情说明文字友情说明文字友情说明文字友情说明文字友情说明文。</p>
            </div>
            <div class="y_recruit_two_tips_3">
                <p>我知道了</p>
            </div>
            <div class="y_recruit_two_tips_4">
                <div class="y_recruit_two_tips_5 " :class="tips_no == '0'?'y_recruit_two_tips_5_none':'y_recruit_two_tips_5_block'" @click="tips_date()">
                    <img src="../../../images/icon_20.png" alt="">
                    <img src="../../../images/icon_19.png" alt="">
                </div>
                <div class="y_recruit_two_tips_6">
                    <p>不再提示</p>
                </div>
            </div>
        </div>
        <!-- 给每人最多推送 弹窗 -->
        <div class='y_person_push_1' :class="pop_one_value == '0'?'y_none':'y_block'">
            <div class='y_person_push_2'>
                <h2>给每人最多推送</h2>
            </div>
            <div class='y_person_push_3'>
                <input type="text" name="" id="" placeholder="请填写" :value="onepeoples_max" @change="onepeoples_max_value">>
                <p>次</p>
            </div>
            <div class='y_person_push_2 y_person_push_5'>
                <h2>给同一人一天内最多推送</h2>
            </div>
            <div class='y_person_push_4'>
                <p v-for="oneday_onepeople in oneday_onepeoples" :key="oneday_onepeople.index" :class='oneday_onepeople.is == "0"?"":"y_person_push_4_active"' @click="oneday_onepeople_dete(oneday_onepeople.index)">{{oneday_onepeople.text}}</p>
            </div>
            <div class='y_person_push_6'>
                <!-- <p class='y_person_push_7' @click="pop_one_none">取消</p> -->
                <p class='y_person_push_8' @click="pop_one_select">确定</p>
            </div>
        </div>
        <!-- 时间选择 -->
        <div class='y_pop_time_min' :class='pop_time_one_value == "0"?"":"y_pop_time_min_active"'>
            <van-datetime-picker v-model="currentDate" disabled="disabled" type="datetime" title="" :min-date="minDate"  @confirm='pop_time_one_dete' @cancel='pop_time_one_cancel'/>
        </div>
        <!-- 底部垫高 -->
        <div class='y_recruit_two_dian'></div>
            <div class='y_recruit_two_foot_1' v-show="hideshow">
                <div class='y_recruit_two_foot_2'>
                    <div class='y_recruit_two_foot_3 y_recruit_two_foot_3_1' :class="index_val == '0'? 'y_display_block':'y_display_none'">
                        <p>预算总计<span>{{xxl_budget}}</span>元<h2 v-show="price_comparison == '0'?'':true">，余额不足，<span @click="showDialog(true)">点击充值</span></h2></p>
                    </div>
                    <div class='y_recruit_two_foot_3 y_recruit_two_foot_3_1' :class="index_val == '1'? 'y_display_block':'y_display_none'">
                        <p>预算总计<span>{{budget}}</span>元<h2 v-show="price_comparison == '0'?'':true">，余额不足，<span @click="showDialog(true)">点击充值</span></h2></p>
                    </div>
                    <div class='y_recruit_two_foot_4'>
                        <p class='y_recruit_two_foot_5' @click="previous_step">上一步</p>
                        <p class='y_recruit_two_foot_6' @click="pop_up">发布</p>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- 余额不足 弹出 -->
        <div class="y_balance_not_pop_1" :class="balance_not_is == '0'?'y_display_none':'y_display_block'">
            <div class="y_balance_not_pop_2">
                <div class="y_balance_not_pop_3">
                    <p>预算总计<span>{{budget}}</span>元，当前余额<span>{{principal_amount}}</span>元 余额不足，请先充足余额</p>
                </div>
                <div class="y_balance_not_pop_4">
                    <p class="y_balance_not_pop_5" @click="balance_not_back">返回</p>
                    <p class="y_balance_not_pop_6" @click="balance_not_recharge(true)">充值余额</p>
                </div>
            </div>
        </div>

        <!-- 提交审核 -->
        <div class="y_submit_review_pop_1" :class="submit_review_is == '0'?'y_display_none':'y_display_block'">
            <div class="y_submit_review_pop_2">
                <div class="y_submit_review_pop_3">
                    <p>喵君将尽快审核完毕，<br>审核通过后自动发布</p>
                </div>
                <div class="y_submit_review_pop_4">
                    <p class="y_submit_review_pop_5" @click="submit_review_back">返回</p>
                    <p class="y_submit_review_pop_6" @click="submit_review_recharge">提交审核</p>
                </div>
            </div>
        </div>
    <kefu></kefu>
    <recharge v-show="dialog_visible" :dialog_visible="dialog_visible" @dialogVisibleEvent="showDialog"></recharge>
</template>
<style scoped>
    .y_recruit_two_3 h2{
        color:#999;
    }
    .y_recruit_two_foot_4 p{
        flex: 1;
    }
    .y_recruit_two_foot_4 .y_recruit_two_foot_6{
        flex: 2;
    }
    .y_topics_select_three_1{
        width: calc(100% - 0.7rem);
        padding: 0.1rem 0.35rem 0.6rem;
        background: #fff;
    }
    .y_topics_select_three_2{
        padding: 0.27rem 0.35rem 0.2rem;
        background: #FFFBED;
    }
    .y_topics_select_three_2 p{
        color:#999;
        font-size: 0.28rem;
        margin-bottom: 0.2rem;
    }
    .y_topics_select_three_2 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_topics_select_three_0{
        width: 100%;
        background: #fff;
    }
    /* 修改 */
    .y_push_setting_5{
        width: calc(100% - 0.7rem);
        padding: 0rem 0rem 0.38rem !important;
        margin: 0rem 0.35rem;
    }
    .y_push_setting_17 /deep/ input{
        font-size: 0.28rem;
        color: #333;
        line-height: 0.8rem;
        width: 100%;
        border: none;
        background: transparent;
        padding-left: 0.2rem;
        height: 0.8rem;
        padding-right: 0rem;
    }
    .y_display_block{
        display: block;
    }
    .y_recruit_two_10 input{
        width: 100%;
    }

    .y_recruit_two_foot_3_1 h2{
        display: inline-block;
        font-size: 0.28rem;
    }
    /* 余额不足 弹出 */
    .y_balance_not_pop_1{
        width: 5.2rem;
        height: 3.5rem;
        background: #fff;
        border-radius: 0.1rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 20;
        overflow: hidden;
    }
    .y_balance_not_pop_2{
        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        height: 100%;
    }
    .y_balance_not_pop_3{
        width: 100%;
        text-align: center;
    }    
    .y_balance_not_pop_3 p{
        color: #333;
        font-size: 0.3rem;
    }
    .y_balance_not_pop_3 p span{
        color: #FECE0A;
        font-size: 0.3rem;
    }
    .y_balance_not_pop_4{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
    }
    .y_balance_not_pop_4 p{
        width: 2rem;
        height: 0.7rem;
        border-radius: 1rem;
        text-align: center;
        font-size: 0.3rem;
        line-height: 0.7rem;
    }
    .y_balance_not_pop_5{
        color: #666;
        background: #ECECEC;
        margin-right: 0.1rem;
    }
    .y_balance_not_pop_6 {
        color: #333;
        background: #FECE0A;
    }
    /* 余额不足 弹出 */

    /* 提交审核 */
    .y_submit_review_pop_1{
        width: 5.2rem;
        height: 3.5rem;
        background: #fff;
        border-radius: 0.1rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 20;
        overflow: hidden;
    }
    .y_submit_review_pop_2{
        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        height: 100%;
    }
    .y_submit_review_pop_3{
        width: 100%;
        text-align: center;
    }    
    .y_submit_review_pop_3 p{
        color: #333;
        font-size: 0.3rem;
    }
    .y_submit_review_pop_4{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
    }
    .y_submit_review_pop_4 p{
        width: 2rem;
        height: 0.7rem;
        border-radius: 1rem;
        text-align: center;
        font-size: 0.3rem;
        line-height: 0.7rem;
    }
    .y_submit_review_pop_5{
        color: #666;
        background: #ECECEC;
        margin-right: 0.1rem;
    }
    .y_submit_review_pop_6 {
        color: #333;
        background: #FECE0A;
    }
    /* 提交审核 */
</style>
<script>
import kefu from '../../components/kefu/kefu'
import recharge from '../../components/recharge/recharge'
import push_charges from '../../components/push_charges/push_charges'

import axios from 'axios'   // 接口
import {user_info} from '../../api/request.js' // 用户信息
import {all_prices} from '../../api/request.js'   // 商务端所有价格的信息
import {configuration_fields} from '../../api/request.js'   // 配置字段
import {edit_blog_info_list} from '../../api/request.js'   // 添加和编辑帖子，信息类推广

import {blog_info_info} from '../../api/request.js'   // 帖子详情,信息类推广
import {recruit_info_info} from '../../api/request.js'   // 招聘详情,信息类推广
import {edit_recruit_info} from '../../api/request.js'   // 添加和编辑招聘，信息类推广

import {submit_check_blog_info} from '../../api/request.js'   // 帖子提交审核
import {submit_check_recruit_info} from '../../api/request.js'  // 信息类 招聘提交审核


export default {
    data(){
        return {
            docmHeight: document.documentElement.clientHeight ||document.body.clientHeight,
            showHeight: document.documentElement.clientHeight ||document.body.clientHeight,
            hideshow:true,  //显示或者隐藏footer

            token:'',
            shade_value:'0',
            pop_one_value:'0',
            dialog_visible: false,
            tips_value:'0',
            tips_no:'0',  // 有请说明 不再提示
            minDate: new Date(),
            // 第一个时间选择
            time_one_Value:'点击设定', // 招聘截止时间
            pop_time_one_value:'0',
            // 第二个时间选择
            time_two_Value:'请选择时间',
            pop_time_two_value:'0',
            // 第三个时间选择
            time_three_Value:'请选择时间',
            pop_time_three_value:'0',

            comm_shadows_value:'',
            onepeoples_max:'1',  // 每人最多推送多少次
            oneday_onepeoples:[
                {index:'1',text:'1',is:'1'},
                {index:'2',text:'2',is:'0'},
                {index:'3',text:'3',is:'0'},
            ],
            number_max:'1',  // 同一人一天内最多推送
            index_val:'1',  // 第二步传来的值决定 已经不用了

            posting_arr:'',  // 第二步列表 
            price:'',        // 单价
            num_input:'',    // 曝光量 数量
            budget:'0',       // 预算
            city_id_chun:'',  // 城市id

            // 待推送 编辑 话题 招聘
            adspace_id:'0',       // 编辑 进入传id
            promotion_value:'0',  // 进入传value  0代表正常进入，1为待推送进入
            id_index:'',  // 判断话题，小组，活动 1 = 话题, 2 = 小组, 3 = 活动,
            info_ids:'', 
            from_table:'', // 帖子还是招聘 帖子=blog，招聘=recruit
            recruit_date_key:'',

            price_comparison:'0',  // 价格比较 默认为0不显示
            principal_amount:'',  // 总 本金金额
            balance_not_is:'0',  // 余额不足
            submit_review_is:'0', // 提交审核
            back_award_id:'',       // 提交审核之后返回列表id
            user:'',  // 用户金额信息
            bj_news:'0', // 编辑新推送 0为正常进入 1是编辑新推送
            xxl_select:'', // 信息类 推送类型 topic话题，group小组，activity活动
            xxl_budget:'0', // 信息类 总价
            zx_number_people_value:'',  // 咨询人数
            common_push_fixed_price:'', // 推广的固定费用
            common_push_consulting_price:'', // 推广的咨询单价
            common_push_exposure_price:'', // 推广的曝光单价
            zx_number_people_money:'0',   // 预算咨询总价
            list_select_length:'',
            days_7_total_scan:'',       // 7天总浏览量
            days_total_scan:'',         // 总浏览量
            business_side_fixed_cues:'',
        }
    },
    components:{
        kefu,
        recharge,
        push_charges,
    },
    created(){
        if(this.$route.query.adspace_id !== undefined){
            if(this.$route.query.from_table == 'blog'){
                // 待推送 编辑 帖子类
                this.adspace_id = this.$route.query.adspace_id
                this.promotion_value = this.$route.query.promotion_value
                this.info_ids = this.$route.query.info_ids
                this.type_name = this.$route.query.name
                this.id_index = this.$route.query.index_index
                this.from_table = this.$route.query.from_table
                this.bj_news = this.$route.query.bj_news
            }else if(this.$route.query.from_table == 'recruit'){
                // 待推送 编辑 招聘类
                this.adspace_id = this.$route.query.adspace_id
                this.promotion_value = this.$route.query.promotion_value
                this.info_ids = this.$route.query.info_ids
                this.from_table = this.$route.query.from_table
                this.id_index = this.$route.query.id_index
                this.bj_news = this.$route.query.bj_news
                this.index_val = '1'
            }  
        }else{
            if(this.$route.query.from_table == 'recruit'){
                this.index_val = '0'
                this.from_table = 'recruit'
            }else{
                this.index_val = '1'
                this.from_table = ''
            }
        }
        if(this.$route.query.xxl_select !== undefined){
            this.promotion_value = this.$route.query.promotion_value
            this.from_table = this.$route.query.from_table
            this.xxl_select = this.$route.query.xxl_select
            this.index_val = '0'
        }
    },
    watch:{
        $route(to,from){
            // 商务端所有价格的信息
            let recruit_date = JSON.parse(sessionStorage.getItem('recruit_date_key'));
            this.recruit_date = recruit_date
            let Total_money = JSON.parse(sessionStorage.getItem('Total_money'));
            this.Total_money = Total_money
            if(this.recruit_date !== null){
                all_prices({job_type_id:this.recruit_date.job_type_id_3}).then((res)=>{
                    let xxl_select = this.xxl_select
                    if(xxl_select == 'topic'){
                        this.common_push_fixed_price = res.job_type.topic_push_fixed_price
                        this.common_push_consulting_price = res.job_type.topic_push_consulting_price
                        this.common_push_exposure_price = res.job_type.activity_push_fixed_price
                    }else if(xxl_select == 'group'){
                        this.common_push_fixed_price = res.job_type.group_push_fixed_price
                        this.common_push_consulting_price = res.job_type.group_push_consulting_price
                        this.common_push_exposure_price = res.job_type.activity_push_consulting_price
                    }else if(xxl_select == 'activity'){
                        this.common_push_fixed_price = res.job_type.group_push_fixed_price
                        this.common_push_consulting_price = res.job_type.group_push_consulting_price
                        this.common_push_exposure_price = res.job_type.activity_push_exposure_price
                    }
                })
            }
            // 获取页面传参 推送类型

            if(this.$route.query.adspace_id !== undefined){
                if(this.$route.query.from_table == 'blog'){
                    // 待推送 编辑 帖子类
                    this.adspace_id = this.$route.query.adspace_id
                    this.promotion_value = this.$route.query.promotion_value
                    this.info_ids = this.$route.query.info_ids
                    this.type_name = this.$route.query.name
                    this.id_index = this.$route.query.index_index
                    this.from_table = this.$route.query.from_table
                    this.bj_news = this.$route.query.bj_news
                }else if(this.$route.query.from_table == 'recruit'){
                    // 待推送 编辑 招聘类
                    this.adspace_id = this.$route.query.adspace_id
                    this.promotion_value = this.$route.query.promotion_value
                    this.info_ids = this.$route.query.info_ids
                    this.from_table = this.$route.query.from_table
                    this.id_index = this.$route.query.id_index
                    this.bj_news = this.$route.query.bj_news
                    this.index_val = '1'
                }  
            }else{
                if(this.$route.query.from_table == 'recruit'){
                    this.index_val = '0'
                    this.from_table = 'recruit'
                }else{
                    this.index_val = '1'
                    this.from_table = ''
                }
            }
            if(this.$route.query.xxl_select !== undefined){
                this.promotion_value = this.$route.query.promotion_value
                this.from_table = this.$route.query.from_table
                this.xxl_select = this.$route.query.xxl_select
                this.index_val = '0'
            }

            let list_select = JSON.parse(sessionStorage.getItem('list_select'));
            this.list_select = list_select
            this.list_select_length = list_select.length
            for(let i=0;i<list_select.length;i++){
                this.days_7_total_scan = this.days_7_total_scan*1 + list_select[i].hot_number_7_days*1
                this.days_total_scan = this.days_total_scan*1 + list_select[i].hot_number*1
            }
        },
        //监听显示高度
        showHeight:function() {
            if(this.docmHeight > this.showHeight){
                //隐藏
                this.hideshow=false
            }else{
                //显示
                this.hideshow=true
            }
        }
        
    },
    mounted(){
        var that = this;
        let token = this.$cookies.get('token')
        let user = JSON.parse(sessionStorage.getItem('user'))
        that.user = user
        if(token == null){
            this.$message({
                message: '请先登录！',
                duration:'2000'
            })
      
            setTimeout(function () {
                that.$router.push({path:'/login'}) 
            },2500);
        }
        that.token = token
        //监听事件
        window.onresize = ()=>{
          return(()=>{
              this.showHeight = document.documentElement.clientHeight || document.body.clientHeight;
          })()
        }
        let city_id_chun = sessionStorage.getItem('city_id_chun')   
        this.city_id_chun = city_id_chun
        
        let list_select = JSON.parse(sessionStorage.getItem('list_select'));
        that.list_select = list_select
        that.list_select_length = list_select.length
        for(let i=0;i<list_select.length;i++){
            that.days_7_total_scan = that.days_7_total_scan*1 + list_select[i].hot_number_7_days*1
            that.days_total_scan = that.days_total_scan*1 + list_select[i].hot_number*1
        }
        // 用户信息
        user_info({token:token}).then((res)=>{
            this.total_Balance = (res.data.balance*1) + (res.data.bonus_fee*1)  // 总共 余额
            this.user = res.data
            this.principal_amount = res.data.balance*1 + res.data.bonus_fee*1
        })
        let recruit_date = JSON.parse(sessionStorage.getItem('recruit_date_key'));
        this.recruit_date = recruit_date

        // 商务端所有价格的信息
        if(this.recruit_date !== null){
            all_prices({job_type_id:this.recruit_date.job_type_id_3,}).then((res)=>{
                let xxl_select = this.xxl_select
                if(xxl_select == 'topic'){
                    this.common_push_fixed_price = res.job_type.topic_push_fixed_price
                    this.common_push_consulting_price = res.job_type.topic_push_consulting_price
                    this.common_push_exposure_price = res.job_type.activity_push_fixed_price
                }else if(xxl_select == 'group'){
                    this.common_push_fixed_price = res.job_type.group_push_fixed_price
                    this.common_push_consulting_price = res.job_type.group_push_consulting_price
                    this.common_push_exposure_price = res.job_type.activity_push_consulting_price
                }else if(xxl_select == 'activity'){
                    this.common_push_fixed_price = res.job_type.group_push_fixed_price
                    this.common_push_consulting_price = res.job_type.group_push_consulting_price
                    this.common_push_exposure_price = res.job_type.activity_push_exposure_price
                }
            })
        }
       
        // 配置字段
        configuration_fields({}).then((res)=>{
          let business_how_to_charge = res.data.business_how_to_charge    // 如何收费
          let business_how_to_push = res.data.business_how_to_push     // 如何推送
          let business_consulting_info = res.data.business_consulting_info  // 设定咨询人数上限 友情提示
          let business_cost_common_info = res.data.business_cost_common_info  // 招聘普通推广的友情提示
          this.business_how_to_charge = business_how_to_charge
          this.business_how_to_push = business_how_to_push
          this.business_consulting_info = business_consulting_info
          this.business_cost_common_info = business_cost_common_info
          this.business_side_fixed_cues = res.data.business_side_fixed_cues
        })
        // 编辑进入
        let adspace_id = this.adspace_id
        let promotion_value = this.promotion_value
        let info_ids = this.info_ids
        let index_index = this.index_index
        let from_table = this.from_table
        let xxl_select = this.xxl_select
        if(xxl_select == ''){
            if(promotion_value == '1'){
                if(from_table == 'blog'){
                    blog_info_info({token:token,blog_id:adspace_id}).then((res) =>{

                        this.time_two_Value = res.blog.push_starttime
                        this.time_three_Value = res.blog.push_endtime
                        this.onepeoples_max = res.blog.max_push_number
                        this.number_max = res.blog.max_push_number_day
                        let oneday_onepeoples = this.oneday_onepeoples
                        for(let i=0;i<oneday_onepeoples.length;i++){
                            oneday_onepeoples[i].is = '0'
                            if(i == res.blog.max_push_number_day*1-1){
                                oneday_onepeoples[i].is = '1'
                            }
                        }
                        this.oneday_onepeoples = oneday_onepeoples
                        this.num_input = res.cost_info.left_exposure_number
                        this.budget = res.cost_info.total_fee*1
                    })
                }else if(from_table == 'recruit'){
                    let recruit_date_key = JSON.parse(sessionStorage.getItem('recruit_date_key'))
                    this.recruit_date_key = recruit_date_key
                    recruit_info_info({token:token,recruit_id:adspace_id}).then((res) =>{
                        this.recruit_info_list = res
                        this.time_two_Value = res.recruit.push_starttime
                        this.time_three_Value = res.recruit.push_endtime
                        this.onepeoples_max = res.recruit.max_push_number
                        this.number_max = res.recruit.max_push_number_day
                        let oneday_onepeoples = this.oneday_onepeoples
                        for(let i=0;i<oneday_onepeoples.length;i++){
                            oneday_onepeoples[i].is = '0'
                            if(i == res.recruit.max_push_number_day*1-1){
                                oneday_onepeoples[i].is = '1'
                            }
                        }
                        this.oneday_onepeoples = oneday_onepeoples
                        this.num_input = res.cost_info.left_exposure_number
                        this.budget = res.cost_info.total_fee*1
                        this.time_one_Value = res.recruit.recruit_endtime
                        this.zx_people_num_input = res.recruit.consulting_people_number
                    })
                }

            }
        }else{
            if(from_table == 'recruit'){
                let recruit_date_key = JSON.parse(sessionStorage.getItem('recruit_date_key'))
                this.recruit_date_key = recruit_date_key
                if(adspace_id !== '0'){
                    recruit_info_info({token:token,recruit_id:adspace_id}).then((res) =>{
                        this.recruit_info_list = res
                        this.time_two_Value = res.recruit.push_starttime
                        this.time_three_Value = res.recruit.push_endtime
                        this.onepeoples_max = res.recruit.max_push_number
                        this.number_max = res.recruit.max_push_number_day
                        this.zx_number_people_value = res.recruit_consulting.people_number
                        this.zx_number_people_money = res.recruit_consulting.total_fee
                        let oneday_onepeoples = this.oneday_onepeoples
                        for(let i=0;i<oneday_onepeoples.length;i++){
                            oneday_onepeoples[i].is = '0'
                            if(i == res.recruit.max_push_number_day*1-1){
                                oneday_onepeoples[i].is = '1'
                            }
                        }
                        this.oneday_onepeoples = oneday_onepeoples
                        this.num_input = res.cost_info.left_exposure_number
                        this.budget = res.cost_info.total_fee*1
                        this.time_one_Value = res.recruit.recruit_endtime
                        this.zx_people_num_input = res.recruit.consulting_people_number
                        this.xxl_budget = this.zx_number_people_money*1 + this.budget*1
                    })
                }
            }
            let recruit_date_key = JSON.parse(sessionStorage.getItem('recruit_date_key'))
            this.recruit_date_key = recruit_date_key
        }
        
        if(from_table !== 'recruit'){
            let posting_arr = JSON.parse(sessionStorage.getItem('posting_arr'));
            if(posting_arr !== null){
                this.posting_arr = posting_arr
                all_prices({price_type:posting_arr.price_type}).then((res) =>{
                    let push_price = res.push_price
                    for(let i=0;i<push_price.length;i++){
                        if(push_price[i].id == posting_arr.push_price_id){
                            if(posting_arr.push_type_text == 'topic'){
                                this.price = push_price[i].topic_push_exposure_price
                            }else if(posting_arr.push_type_text == 'group'){
                                this.price = push_price[i].group_push_exposure_price
                            }else if(posting_arr.push_type_text == 'activity'){
                                this.price = push_price[i].activity_push_exposure_price
                            }
                        }
                    }
                })
            }
        }

        let arr = []

        if(xxl_select == 'topic'){
            for(let i=0;i<list_select.length;i++){
                arr.push(list_select[i].topic_id)
            }
            all_prices({job_type_ids:this.recruit_date.job_type_id_3,push_type_text:'topic',price_type:'recruit'}).then((res)=>{
                this.common_push_fixed_price = res.job_type.topic_push_fixed_price
                this.common_push_consulting_price = res.job_type.topic_push_consulting_price
                this.common_push_exposure_price = res.job_type.activity_push_fixed_price
            })
        }else if(xxl_select == 'group'){
            for(let i=0;i<list_select.length;i++){
                arr.push(list_select[i].group_id)
            }
            all_prices({job_type_ids:this.recruit_date.job_type_id_3,push_type_text:'group',price_type:'recruit'}).then((res)=>{
                this.common_push_fixed_price = res.job_type.topic_push_fixed_price
                this.common_push_consulting_price = res.job_type.topic_push_consulting_price
                this.common_push_exposure_price = res.job_type.activity_push_fixed_price
            })
        }else if(xxl_select == 'activity'){
            for(let i=0;i<list_select.length;i++){
                arr.push(list_select[i].activity_id)
            }
            all_prices({job_type_ids:this.recruit_date.job_type_id_3,push_type_text:'activity',price_type:'recruit'}).then((res)=>{
                this.common_push_fixed_price = res.job_type.topic_push_fixed_price
                this.common_push_consulting_price = res.job_type.topic_push_consulting_price
                this.common_push_exposure_price = res.job_type.activity_push_fixed_price
            })
        }

        let info_ids_one = JSON.stringify(arr)
        this.info_ids = info_ids_one.replace(/\[|]|"/g,"")

    },
    activated(){
        let token = this.$cookies.get('token')
        if(token == null){
            this.$message({
                message: '请先登录！',
                duration:'2000'
            })
            var that = this;
            setTimeout(function () {
                that.$router.push({path:'/login'}) 
            },2500);
        }
        this.token = token
    },
    methods: {
        // 咨询 input
        zx_input(){
            let common_push_consulting_price = this.common_push_consulting_price
            let zx_number_people_value = this.zx_number_people_value
            this.zx_number_people_money = (common_push_consulting_price*1)*(zx_number_people_value*1) 
            let xxl_select = this.xxl_select
            if(xxl_select == ''){
                this.Total_Total_money = this.Total_money*1 + this.common_push_fixed_price*1 + this.zx_number_people_money*1
            }else{
                let budget = this.budget
                this.xxl_budget = budget*1 + this.zx_number_people_money*1
                if(this.principal_amount*1 < this.xxl_budget*1){
                    this.price_comparison = '1'
                }else{
                    this.price_comparison = '0'
                }
            }
            
        },
        pop_up(e){
            let budget = this.budget  // 总价
            let xxl_select = this.xxl_select
            let xxl_budget = this.xxl_budget // 信息类招聘 总价
            let principal_amount = this.principal_amount  // 本金金额
            if(xxl_select == ''){
                if(principal_amount*1 < budget*1){
                    this.comm_shadows_value = '1'
                    this.balance_not_is = '1'
                    return
                }
            }else{
                if(principal_amount*1 < xxl_budget*1){
                    this.comm_shadows_value = '1'
                    this.balance_not_is = '1'
                    return
                }
            }

            let posting_arr = this.posting_arr
            let token = this.token
            let blog_id = '0'  // 发帖为0
            let content = posting_arr.content  // 帖子内容
            let city_id = this.city_id_chun  // 城市id
            let max_push_number = this.onepeoples_max // 每人最多推送多少次
            let max_push_number_day = this.number_max // 同一人一天内最多推送
            let push_starttime = this.time_two_Value  // 开始时间
            let push_endtime = this.time_three_Value  // 结束时间
            let from_table = this.from_table
            let recruit_date_key = this.recruit_date_key
            if(xxl_select == ''){
                if(push_starttime && push_endtime == '请选择时间'){
                    this.$message({
                        message: '请选择时间',
                        duration:'2000'
                    })
                    return
                }
            }

            let price_type = posting_arr.price_type  // 推送内容类别
            let push_price_id = posting_arr.push_price_id  // 推送内容类别id
            let longitude = posting_arr.work_place_longitude  // 经度  // 暂时为空
            let latitude = posting_arr.work_place_latitude   // 纬度  // 暂时为空
            let is_allow_comment = posting_arr.is_allow_comment // 是否允许评论
            let vote_type_text = posting_arr.vote_type_text  // 投票类型
            let push_type_text = posting_arr.push_type_text  // 推送类型
            let exposure_number = this.num_input  // 曝光次数

            let promotion_value = this.promotion_value
            let adspace_id = this.adspace_id
            
            let info_ids = posting_arr.info_ids   // 信息id
            let info_list = ''

            let gallery = posting_arr.gallery         // 多图
            let img_list = ''
            let vote_title = "";
            if(posting_arr.vote_title !== "" || posting_arr.vote_title !== undefined || posting_arr.vote_title !== null){
                vote_title = posting_arr.vote_title // 投票选项标题
            }
            let vote_list = ''

            if(xxl_select == ''){
                for(let i=0;i<vote_title.length;i++){
                    vote_list = vote_list +','+ vote_title[i]
                }
                vote_title = vote_list.substr(1,vote_list.length) 
                for(let i=0;i<gallery.length;i++){
                    img_list = img_list +','+ gallery[i]
                }
                gallery = img_list.substr(1,img_list.length)    // 传 gallery
            }
            
            if(promotion_value == '0'){
                let info_ids_one = JSON.stringify(info_ids)
                this.info_ids = info_ids_one.replace(/\"/g,"")
                edit_blog_info_list({token:token,blog_id:blog_id,content:content,city_id:city_id,max_push_number:max_push_number,max_push_number_day:max_push_number_day,push_starttime:push_starttime,push_endtime:push_endtime,price_type:price_type,push_price_id:push_price_id,longitude:longitude,latitude:latitude,is_allow_comment:is_allow_comment,vote_type_text:vote_type_text,vote_title:vote_title,gallery:gallery,push_type_text:push_type_text,info_ids:this.info_ids,exposure_number:exposure_number}).then((res)=>{
                    if(res.error_code == '1'){
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        }) 
                    }else{
                        this.comm_shadows_value = '1'
                        this.submit_review_is = '1'
                        this.back_award_id = res.blog_id
                    }
                })
            }else if(promotion_value == '1'){

                if(from_table == 'blog'){
                    let info_ids = posting_arr.info_ids   // 信息id
                    let bj_news = this.bj_news
                    if(bj_news =='1'){
                        edit_blog_info_list({token:token,blog_id:'0',content:content,city_id:city_id,max_push_number:max_push_number,max_push_number_day:max_push_number_day,push_starttime:push_starttime,push_endtime:push_endtime,price_type:price_type,push_price_id:push_price_id,longitude:longitude,latitude:latitude,is_allow_comment:is_allow_comment,vote_type_text:vote_type_text,vote_title:vote_title,gallery:gallery,push_type_text:push_type_text,info_ids:info_ids,exposure_number:exposure_number}).then((res)=>{
                            if(res.error_code == '1'){
                                this.$message({
                                    message: res.msg,
                                    duration:'2000'
                                }) 
                            }else{
                                this.comm_shadows_value = '1'
                                this.submit_review_is = '1'
                                this.back_award_id = res.blog_id
                            }
                        })
                    }else{
                        edit_blog_info_list({token:token,blog_id:adspace_id,content:content,city_id:city_id,max_push_number:max_push_number,max_push_number_day:max_push_number_day,push_starttime:push_starttime,push_endtime:push_endtime,price_type:price_type,push_price_id:push_price_id,longitude:longitude,latitude:latitude,is_allow_comment:is_allow_comment,vote_type_text:vote_type_text,vote_title:vote_title,gallery:gallery,push_type_text:push_type_text,info_ids:info_ids,exposure_number:exposure_number}).then((res)=>{
                            if(res.error_code == '1'){
                                this.$message({
                                    message: res.msg,
                                    duration:'2000'
                                }) 
                            }else{
                                this.comm_shadows_value = '1'
                                this.submit_review_is = '1'
                                this.back_award_id = res.blog_id
                            }
                        })
                    }
                }else if(from_table == 'recruit'){
                    let gallery = recruit_date_key.gallery         // 多图
                    let img_list = ''
                    for(let i=0;i<gallery.length;i++){
                        img_list = img_list +','+ gallery[i]
                    }
                    gallery = img_list.substr(1,img_list.length)    // 传 gallery
                    let bj_news = this.bj_news
                    if(bj_news == '1'){
                        edit_recruit_info({token:token,recruit_id:'0',title:recruit_date_key.title,city_id:city_id,max_push_number:max_push_number,max_push_number_day:max_push_number_day,push_starttime:push_starttime,push_endtime:push_endtime,exposure_number:exposure_number,push_type_text:this.xxl_select,info_ids:this.info_ids,job_content:recruit_date_key.job_content,work_place_type:recruit_date_key.work_place_type,work_place_longitude:recruit_date_key.work_place_longitude,work_place_latitude:recruit_date_key.work_place_latitude,work_place_address:recruit_date_key.work_place_address,phone:recruit_date_key.phone,weixin_number:recruit_date_key.weixin_number,telephone:recruit_date_key.telephone,qq:recruit_date_key.qq,email:recruit_date_key.email,job_type_id_1:recruit_date_key.job_type_id_1,job_type_id_2:recruit_date_key.job_type_id_2,job_type_id_3:recruit_date_key.job_type_id_3,sex_demand:recruit_date_key.sex_demand,education_ids:recruit_date_key.education_ids,other_demand:recruit_date_key.other_demand,work_time:recruit_date_key.work_time,salary_type:recruit_date_key.salary_type,salary_fee:recruit_date_key.salary_fee,min_salary_fee:recruit_date_key.min_salary_fee,max_salary_fee:recruit_date_key.max_salary_fee,salary_self:recruit_date_key.salary_self,salary_unit:recruit_date_key.salary_unit,settle_type:recruit_date_key.settle_type,settle_type:recruit_date_key.settle_type,welfare_ids:recruit_date_key.welfare_ids,commission_type:recruit_date_key.commission_type,achievement_percent:recruit_date_key.achievement_percent,piece_money:recruit_date_key.piece_money,commission_self:recruit_date_key.commission_self,recruit_endtime:this.recruit_info_list.recruit.recruit_endtime,people_number:recruit_date_key.people_number,gallery:gallery,general_type_id_1:this.recruit_info_list.recruit.general_type_id_1,general_type_id_2:this.recruit_info_list.recruit.general_type_id_2,consulting_people_number:this.zx_number_people_value}).then((res)=>{
                            if(res.error_code == '1'){
                                this.$message({
                                    message: res.msg,
                                    duration:'2000'
                                }) 
                            }else{
                                this.comm_shadows_value = '1'
                                this.submit_review_is = '1'
                                this.back_award_id = res.recruit_id
                            }
                        })
                    }else{
                        edit_recruit_info({
                            token:token,
                            recruit_id:adspace_id,
                            title:recruit_date_key.title,city_id:city_id,
                            max_push_number:max_push_number,
                            max_push_number_day:max_push_number_day,
                            exposure_number:exposure_number,
                            push_type_text:this.xxl_select,
                            info_ids:this.info_ids,
                            job_content:recruit_date_key.job_content,
                            work_place_type:recruit_date_key.work_place_type,
                            work_place_longitude:recruit_date_key.work_place_longitude,
                            work_place_latitude:recruit_date_key.work_place_latitude,
                            work_place_address:recruit_date_key.work_place_address,
                            phone:recruit_date_key.phone,
                            weixin_number:recruit_date_key.weixin_number,
                            telephone:recruit_date_key.telephone,
                            qq:recruit_date_key.qq,
                            email:recruit_date_key.email,
                            job_type_id_1:recruit_date_key.job_type_id_1,
                            job_type_id_2:recruit_date_key.job_type_id_2,
                            job_type_id_3:recruit_date_key.job_type_id_3,
                            sex_demand:recruit_date_key.sex_demand,
                            education_ids:recruit_date_key.education_ids,
                            other_demand:recruit_date_key.other_demand,
                            work_time:recruit_date_key.work_time,
                            salary_type:recruit_date_key.salary_type,
                            salary_fee:recruit_date_key.salary_fee,
                            min_salary_fee:recruit_date_key.min_salary_fee,
                            max_salary_fee:recruit_date_key.max_salary_fee,
                            salary_self:recruit_date_key.salary_self,
                            salary_unit:recruit_date_key.salary_unit,
                            settle_type:recruit_date_key.settle_type,
                            settle_type:recruit_date_key.settle_type,
                            welfare_ids:recruit_date_key.welfare_ids,
                            commission_type:recruit_date_key.commission_type,
                            achievement_percent:recruit_date_key.achievement_percent,
                            piece_money:recruit_date_key.piece_money,
                            commission_self:recruit_date_key.commission_self,
                            recruit_endtime:this.time_one_Value,
                            people_number:recruit_date_key.people_number,
                            gallery:gallery,
                            consulting_people_number:this.zx_number_people_value
                        }).then((res)=>{
                            if(res.error_code == '1'){
                                this.$message({
                                    message: res.msg,
                                    duration:'2000'
                                }) 
                            }else{
                                this.comm_shadows_value = '1'
                                this.submit_review_is = '1'
                                this.back_award_id = res.recruit_id
                            }
                        })
                    }
                }
            }
        },
        pop_one_none(e){
            this.comm_shadows_value = '0'
            this.pop_one_value = '0'
        },
        pop_one_select(e){
            let oneday_onepeoples = this.oneday_onepeoples
            for(let i=0;i<oneday_onepeoples.length;i++){
                if(oneday_onepeoples[i].is == '1'){
                    this.number_max = oneday_onepeoples[i].text
                }
            }
            this.comm_shadows_value = '0'
            this.pop_one_value = '0'
        },
        // 曝光量 input
        num_select(){
            let num_input = this.num_input
            let price = this.price
            let xxl_select = this.xxl_select
            let common_push_exposure_price = this.common_push_exposure_price
            if(xxl_select == ''){
                this.budget = ((num_input*1) * (price*1)).toFixed(2)
            }else{
                this.budget = ((num_input*1) * (common_push_exposure_price*1)).toFixed(2)
                let zx_number_people_money = this.zx_number_people_money
                this.xxl_budget = zx_number_people_money*1 + this.budget*1
            }
            let principal_amount = this.principal_amount
            if(xxl_select == ''){
                if(principal_amount*1 < this.budget*1){
                    this.price_comparison = '1'
                }else{
                    this.price_comparison = '0'
                }
            }else{
                if(principal_amount*1 < this.xxl_budget*1){
                    this.price_comparison = '1'
                }else{
                    this.price_comparison = '0'
                }
            }
        },
        // 设定咨询人数上限 input
        zx_people_num_select(){
            let zx_people_num_input = this.zx_people_num_input
            this.zx_people_num_input = zx_people_num_input
        },
        // 充值
        showDialog(visible) {
          this.dialog_visible = visible;
        },
        // 推送跳转
        goTo_push_setting(e){
            this.$router.push('/push_settings');
        },
        // 友情说明 不再提示
        tips_date(e){
            let is_val = this.tips_no
            if(is_val == '0'){
                this.tips_no = '1'
            }else{
                this.tips_no = '0'
            }
        },
        // 第一个时间选择 弹窗
        pop_time_one_block(e){
            this.shade_value = '1'
            this.pop_time_one_value = '1'
        },
        pop_time_one_dete(val){
            let year = val.getFullYear()
            let month = val.getMonth() + 1
            let day = val.getDate()
            let hour = val.getHours()
            let minute = val.getMinutes()
            if (month >= 1 && month <= 9) { month = `0${month}` }
            if (day >= 1 && day <= 9) { day = `0${day}` }
            if (hour >= 0 && hour <= 9) { hour = `0${hour}` }
            if (minute >= 0 && minute <= 9) { minute = `0${minute}` }
            this.time_one_Value = `${year}-${month}-${day} ${hour}:${minute}`

            this.shade_value = '0'
            this.pop_time_one_value = '0'
        },
        pop_time_one_cancel(e){
            this.shade_value = '0'
            this.pop_time_one_value = '0'
        },
        // 第二个时间选择 弹窗
        pop_time_two_block(e){
            this.comm_shadows_value = '1'
            this.pop_time_two_value = '1'
        },
        pop_time_two_dete(val){
            let year = val.getFullYear()
            let month = val.getMonth() + 1
            let day = val.getDate()
            let hour = val.getHours()
            let minute = val.getMinutes()
            if (month >= 1 && month <= 9) { month = `0${month}` }
            if (day >= 1 && day <= 9) { day = `0${day}` }
            if (hour >= 0 && hour <= 9) { hour = `0${hour}` }
            if (minute >= 0 && minute <= 9) { minute = `0${minute}` }
            this.time_two_Value = `${year}-${month}-${day} ${hour}:${minute}`

            this.comm_shadows_value = '0'
            this.pop_time_two_value = '0'
        },
        pop_time_two_cancel(e){
            this.comm_shadows_value = '0'
            this.pop_time_two_value = '0'
        },
        // 第三个时间选择 弹窗
        pop_time_three_block(e){
            this.comm_shadows_value = '1'
            this.pop_time_three_value = '1'
        },
        pop_time_three_dete(val){
            let year = val.getFullYear()
            let month = val.getMonth() + 1
            let day = val.getDate()
            let hour = val.getHours()
            let minute = val.getMinutes()
            if (month >= 1 && month <= 9) { month = `0${month}` }
            if (day >= 1 && day <= 9) { day = `0${day}` }
            if (hour >= 0 && hour <= 9) { hour = `0${hour}` }
            if (minute >= 0 && minute <= 9) { minute = `0${minute}` }
            this.time_three_Value = `${year}-${month}-${day} ${hour}:${minute}`

            this.comm_shadows_value = '0'
            this.pop_time_three_value = '0'
        },
        pop_time_three_cancel(e){
            this.comm_shadows_value = '0'
            this.pop_time_three_value = '0'
        },
        // 给每人最多推送
        pop_one_block(e){
            this.comm_shadows_value = '1'
            this.pop_one_value = '1'
        },
        onepeoples_max_value(e){
            const { value } = e.target;
            this.onepeoples_max = value
        },
        // 上一步
        previous_step(){
            let from_table = this.from_table
            if(from_table == 'blog'){
                this.$router.push({
                    path:'/topics_select_two',
                })
            }else if(from_table == 'recruit'){
                this.$router.go(-1)
            }else{
                this.$router.go(-1)
            }

        },
        oneday_onepeople_dete(index){
            let oneday_onepeoples_list = this.oneday_onepeoples
            for(let i=0;i<oneday_onepeoples_list.length;i++){
                oneday_onepeoples_list[i].is = '0'
                if(oneday_onepeoples_list[i].index == index){
                    oneday_onepeoples_list[i].is = '1'
                }
            }
            this.oneday_onepeoples = oneday_onepeoples_list
        },
        // 余额不足弹出 返回
        balance_not_back(){
            this.comm_shadows_value = '0'
            this.balance_not_is = '0'
        },  
        // 余额不足弹出 充值
        balance_not_recharge(visible){
            this.dialog_visible = visible;
            this.comm_shadows_value = '0'
            this.balance_not_is = '0'
        }, 
        // 提交审核 返回
        submit_review_back(){
            this.comm_shadows_value = '0'
            this.submit_review_is = '0'
            var that = this;
            setTimeout(function () {
                that.$router.push({path:'/'}) 
            },1000);
        },
        // 提交审核 确定
        submit_review_recharge(){
            let back_award_id = this.back_award_id
            let token = this.token
            let from_table = this.from_table
            if(from_table == 'blog'){
                submit_check_blog_info({token,blog_id:back_award_id}).then((res) =>{
                    if(res.error_code == '0'){
                        this.$message({
                            message:res.msg,
                            duration:'2000'
                        })
                        var that = this;
                        setTimeout(function () {
                            that.$router.push({path:'/'}) 
                        },1000);
                    }else{
                        this.$message({
                            message:res.msg,
                            duration:'2000'
                        })
                    }
                })
            }else if(from_table == ''){
                submit_check_blog_info({token,blog_id:back_award_id}).then((res) =>{
                    if(res.error_code == '0'){
                        this.$message({
                            message:res.msg,
                            duration:'2000'
                        })
                        var that = this;
                        setTimeout(function () {
                            that.$router.push({path:'/'}) 
                        },1000);
                    }else{
                        this.$message({
                            message:res.msg,
                            duration:'2000'
                        })
                    }
                })
            }else if(from_table == 'recruit'){
                submit_check_recruit_info({token,recruit_id:back_award_id}).then((res) =>{ 
                    if(res.error_code == '0'){
                        this.$message({
                            message:res.msg,
                            duration:'2000'
                        })
                        var that = this;
                        setTimeout(function () {
                            that.$router.push({path:'/'}) 
                        },1000);
                    }else{
                        this.$message({
                            message:res.msg,
                            duration:'2000'
                        })
                    }
                })
            }
            
        },
    },
}
</script>