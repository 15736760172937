<template>
    <div class="y_my_edit_profile_1">
        <div class="y_my_earnings_1">
            <div class="y_my_earnings_2">
                <div class="y_my_earnings_3">
                    <h2>累计总收益</h2>
                </div>
                <p>{{my_rebate_fee_total}}元</p>
            </div>
            <div class="y_my_earnings_2" @click="pop_up_two">
                <div class="y_my_earnings_3">
                    <h2>{{ pop_two_is == '0'?'点击选择':pop_two_value}}</h2>
                    <img src="../../../images/icon_13.png" alt="">
                </div>
                <p v-if="week_month_year == '1'?true:false">{{ rebate_fee_week }}</p>
                <p v-if="week_month_year == '2'?true:false">{{ rebate_fee_month }}</p>
                <p v-if="week_month_year == '3'?true:false">{{ rebate_fee_year }}</p>
            </div>
            <div class="y_my_earnings_2">
                <div class="y_my_earnings_3">
                    <h2>累计提现</h2>
                </div>
                <p>{{withdraw_fee}}元</p>
            </div>
            <div class="y_my_earnings_2 y_my_earnings_6">
                <div class="y_my_earnings_4">
                    <div class="y_my_earnings_3">
                        <h2>待提现收益</h2>
                    </div>
                    <p>{{rebate_fee}}元</p>
                </div>
                <router-link to="/my_withdrawal_page" class="y_my_earnings_5">
                    <p>提现</p>
                </router-link>
            </div>
        </div>
        
        <div class="y_my_earnings_7">
            <div class="y_my_earnings_8">
                <div class="y_my_earnings_9">
                    <p>已邀请<span>{{invited_count}}位</span>新用户加入</p>
                </div>
                <div class="y_my_earnings_10">
                    <div class="y_my_earnings_11" :class="invited_is == '0'?'y_invited_is':''" v-for="(item,index) in invited_list" :key="index">
                        <div class="y_my_earnings_12">
                            <img :src="''+item.head_portrait" alt="">
                        </div>
                        <div class="y_my_earnings_13">
                            <div class="y_my_earnings_14">
                                <h2>{{item.phone}}</h2>
                                <p>剩余<span>{{item.left_days}}天</span>内可为您产生返现</p>
                            </div>
                            <div class="y_my_earnings_15">
                                <p>已为您产生<span>{{item.rebate_fee_total}}元</span>返现收益</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="y_my_earnings_16" :class="invited_is == '0'? '':'y_display_none'" @click="invited_show">
                    <p>查看全部</p>
                </div>
            </div>
        </div>

        <!-- 公共弹窗   遮罩层 -->
        <div class='y_pop_masking' :class='pop_masking == 0 ? "":"y_masking_block"'></div>
        <!-- 工作性质 弹窗 第二个 -->
        <div class='y_pop_two_1' :class='pop_two == 0 ? "y_display_none":"y_display_block"' style="height: auto !important;">
          <div class='y_pop_two_3' @click="pop_two_none">
            <img src="../../../images/icon_17.png" alt="">
          </div>
          <div class='y_pop_two_2' style="height: auto !important;">
            <p v-for='pop_two in pop_twos' :key='pop_two.id' @click="pop_two_select(pop_two.id)" :value='pop_two.text'>{{pop_two.text}}</p>
          </div>
        </div>

    </div>
</template>
<style scoped>
    .y_my_edit_profile_1{
        min-height: 100vh;
        width: 100%;
        background: #F4F5F7;
    }

    .y_my_earnings_1{
        width: 100%;
        background: #fff;
    }
    .y_my_earnings_2{
        padding:0rem 0.25rem;
        height: 1.2rem;
        border-bottom: 0.01rem solid #EEEEEE;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_my_earnings_3{
        min-width: 1.6rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_my_earnings_3 h2{
        font-size: 0.28rem;
        color: #333;
    }
    .y_my_earnings_3 img{
        width: 0.16rem;
        object-fit: cover;
        margin-left: 0.1rem;
    }
    .y_my_earnings_2 p{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_my_earnings_4{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_my_earnings_6{
        justify-content: space-between;
    }
    .y_my_earnings_5 p{
        width: 1.4rem;
        height: 0.5rem;
        text-align: center;
        border-radius: 1rem;
        line-height: 0.5rem;
        background: #FECE0A;
        color: #fff;
        font-size: 0.24rem;
    }
    .y_my_earnings_7{
        width: 100%;
        margin-top: 0.2rem;
        background: #fff;
    }
    .y_my_earnings_8{
        padding: 0rem 0.25rem 0rem 0.35rem;
    }
    .y_my_earnings_9{
        width: 100%;
        height: 1rem;
        display: flex;
        align-items: center;
    }
    .y_my_earnings_9 p{
        font-size: 0.24rem;
        color: #aaa;
    }
    .y_my_earnings_9 p span{
        color: #FECE0A;
        font-size: 0.24rem;
    }
    .y_my_earnings_10{
        width: 100%;
    }
    .y_my_earnings_11{
        display: flex;
        justify-content: flex-start;
        width: 100%;
        height: 1.5rem;
    }
    .y_my_earnings_12{
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 1rem;
        overflow: hidden;
        margin-right: 0.2rem;
        margin-top: 0.2rem;
    }
    .y_my_earnings_12 img{
        width: 100%;
        object-fit: cover;
    }
    .y_my_earnings_13{
        flex: 1;
        height: 100%;
        border-bottom: 0.01rem solid #eee;
    }
    .y_my_earnings_14{
        width: 100%;
        height: 0.6rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.9rem;
    }
    .y_my_earnings_14 h2{
        font-size: 0.3rem;
        color: #333;
        margin-right: 0.25rem;
        font-weight: 600;
    }
    .y_my_earnings_14 p{
        color: #888;
        font-size: 0.24rem;
    }
    .y_my_earnings_14 p span{
        color: #FECE0A;
        font-size: 0.24rem;
    }
    .y_my_earnings_15{
        width: 100%;
    }
    .y_my_earnings_15 p{
        font-size: 0.24rem;
        color: #888;
    }
    .y_my_earnings_15 p span{
        color: #FECE0A;
        font-size: 0.24rem;
    }
    .y_my_earnings_16{
        width: 100%;
        height: 0.9rem;
    }
    .y_my_earnings_16 p{
        text-align: center;
        width: 100%;
        height: 100%;
        line-height: 0.9rem;
        color: #333;
        font-size: 0.24rem;
    }

    /* 工作性质 第二个 弹窗 */
    .y_pop_two_1{
      width: 100%;
      bottom: 0rem;
      /* height: auto !important; */
      height: 3.2rem;
      background: #fff;
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
      position: fixed;
      z-index: 11;
      display: none;
    }
    .y_pop_two_2{
      height: calc(50vh - 0.2rem);
      margin-top: 0.2rem;
      overflow: scroll;
      width: 100%;
    }
    .y_pop_two_3{
      position: absolute;
      top: 0rem;
      right: 0rem;
      width: 0.8rem;
      height: 0.8rem;
    }
    .y_pop_two_2 p{
      width: 100%;
      line-height: 1rem;
      text-align: center;
      font-size: 0.3rem;
      color: #333;
      border-bottom: 0.01rem solid #eee;
    }
    .y_pop_two_3 img{
      width: 100%;
      height: 100%;
    }
    .y_display_block{
        display: block;
    }
    /* 工作性质 第二个 弹窗 */

    .y_my_earnings_10 .y_invited_is:nth-child(n+4){
        display: none;
    }
</style>

<script>
    import axios from 'axios'   // 接口
    import {rebate_detail} from '../../api/request.js' // 我的收益详情
    import {down_user_list} from '../../api/request.js' // 下级用户列表
    
    export default {
        data(){
            return {
                pop_masking:'0',
                // 联系方式 第二个弹窗
                pop_twos:[
                    {id:'1',text:'本周收益',is:'0'},
                    {id:'2',text:'本月收益',is:'0'},
                    {id:'3',text:'今年收益',is:'0'},
                ],
                pop_two:'0',
                pop_two_is:'1',
                pop_two_value:'本月收益',

                my_rebate_fee_total:'', // 我的 累计总收益
                rebate_fee_week:'',  //    我的 本周
                rebate_fee_month:'', //    我的 月收入
                rebate_fee_year:'',  //    我的 本年
                rebate_fee:'', // 我的 剩余返利金额
                withdraw_fee:'', // 我的 已提现金额

                week_month_year:'2', // 周月年选择

                invited_list:'',    // 已邀请的列表
                invited_count:'',   // 已邀请 下级用户总数
                invited_is:'0',      // 是否为0 判断全部展开
            }
        },  
        components:{

        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            // 我的收益详情
            rebate_detail({token:token}).then((res) =>{
                console.log(res,'我的收益详情')
                this.my_rebate_fee_total = res.rebate_fee_total
                this.rebate_fee_week = res.rebate_fee_week
                this.rebate_fee_month = res.rebate_fee_month
                this.rebate_fee_year = res.rebate_fee_year
                this.rebate_fee = res.rebate_fee
                this.withdraw_fee = res.withdraw_fee
            })
            // 下级用户列表
            down_user_list({token:token,page:''}).then((res) =>{
                console.log(res,'下级用户列表')
                this.invited_list = res.data
                this.invited_count = res.count
            })
        },
        methods: {
            // 工作性质 弹窗 第二个
            pop_up_two(e){
              this.pop_masking = '1'
              this.pop_two = '1'
            },
            pop_two_none(e){
              this.pop_masking = '0'
              this.pop_two = '0'
            },
            pop_two_select(e){
                console.log(e)
                let pop_twos = this.pop_twos
                for(let i=0;i<pop_twos.length;i++){
                    if(pop_twos[i].id == e){
                        this.pop_two_value = pop_twos[i].text
                        this.week_month_year = e
                    }
                }
                this.pop_masking = '0'
                this.pop_two = '0'
              return
              this.pop_two_value = e.path[0].innerText
              this.pop_two_is = '1'
              this.pop_masking = '0'
              this.pop_two = '0'
              let pop_twos_list = this.pop_twos
              for(let i=0;i<pop_twos_list.length;i++){
                  pop_twos_list[i].is = '0'
                  if(pop_twos_list[i].text == e.path[0].innerText){
                      pop_twos_list[i].is = '1'
                      this.week_month_year = i
                  }
              }
              this.pop_twos = pop_twos_list
              console.log(this.pop_twos)
            },
            // 全部展开
            invited_show(){
                this.invited_is = '1'
            }
        },
    }

</script>