<template>
    <div class="y_push_date_tg_1">
        <div class="y_push_date_tg_2">
            <div class="y_push_date_tg_3">
                <p>余额<span>{{ available_balance }}</span>元</p>
            </div>
            <div class="y_push_date_tg_4">
                <p @click="showDialog(true)">充值余额</p>
            </div>
        </div>
        <!-- 高级推送 轮播 -->
        <div class="y_push_date_gj_lb_1">
            <img src="../../../images/img_3.png" alt="">
        </div>

        <div class="y_push_date_cj_1">
            <div class="y_push_date_cj_2">
                <div class="y_push_date_cj_3">
                    <h2>下线时间：</h2>
                    <p>{{award_list.open_time_string}}</p>
                </div>
                <div class="y_push_date_cj_3">
                    <h2>参与抽奖总人数：</h2>
                    <p><span>{{award_list.award_joiner_count}}</span></p>
                </div>
                <div class="y_push_date_cj_3">
                    <h2>点击浏览总人数：</h2>
                    <p><span>{{award_list.click_number}}</span></p>
                </div>
                <div class="y_push_date_cj_3">
                    <h2>共扣费/总预算：</h2>
                    <p><span>{{(award_list.click_total_fee-award_list.left_click_fee).toFixed(2)}}元 / {{award_list.click_total_fee}}元</span></p>
                </div>
            </div>
        </div>

        <div class="y_push_date_cj_4">
            <div class="y_push_date_cj_5" :class="undergraduate_display == '0'?'y_display_none':''">
                <div class="y_push_date_cj_6">
                    <div class="y_push_date_cj_7">
                        <h2>本科学校中推送给</h2>
                        <p>{{grade_list_undergraduate}}</p>
                    </div>
                    <div class="y_push_date_cj_8">
                        <p>每人点击<span>{{ award_list.click_price_undergraduate }}</span>元</p>
                        <p>点击浏览人数</p>
                        <p>共扣费/预算</p>
                    </div>
                </div>
                <div class="y_push_date_cj_9">
                    <div class="y_push_date_cj_10" :class="item.school_type_text == 'undergraduate' ?'':'y_display_none'" v-for="(item,index) in school_list" :key="index">
                        <div class="y_push_date_cj_11">
                            <h2>{{item.school_title}}</h2>
                            <p></p>
                        </div>
                        <div class="y_push_date_cj_11">
                            <h2>{{item.click_number}}</h2>
                            <p></p>
                        </div>
                        <div class="y_push_date_cj_11 y_push_date_cj_12">
                            <h2>{{item.click_total_fee/item.click_price*1-item.left_click_number}}元/{{item.click_total_fee}}元</h2>
                            <p><span>{{item.click_total_fee/item.click_price*1 - item.left_click_number }}</span>人参与抽奖</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="y_push_date_cj_5" :class="specialty_display == '0'?'y_display_none':''">
                <div class="y_push_date_cj_6">
                    <div class="y_push_date_cj_7">
                        <h2>专科学校中推送给</h2>
                        <p>{{grade_list_specialty}}</p>
                    </div>
                    <div class="y_push_date_cj_8">
                        <p>每人点击<span>{{ award_list.click_price_specialty }}</span>元</p>
                        <p>点击浏览人数</p>
                        <p>共扣费/预算</p>
                    </div>
                </div>
                <div class="y_push_date_cj_9">
                    <div class="y_push_date_cj_10" :class="item.school_type_text == 'specialty' ?'':'y_display_none'" v-for="(item,index) in school_list" :key="index">
                        <div class="y_push_date_cj_11">
                            <h2>{{item.school_title}}</h2>
                            <p></p>
                        </div>
                        <div class="y_push_date_cj_11">
                            <h2>{{item.click_number}}</h2>
                            <p></p>
                        </div>
                        <div class="y_push_date_cj_11 y_push_date_cj_12">
                            <h2>{{item.click_total_fee/item.click_price*1-item.left_click_number}}元/{{item.click_total_fee}}元</h2>
                            <p><span>{{item.click_total_fee/item.click_price*1 - item.left_click_number }}</span>人参与抽奖</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <kefu></kefu>
    <recharge v-show="dialog_visible" :dialog_visible="dialog_visible" @dialogVisibleEvent="showDialog"></recharge>
</template>

<style scoped>
    .y_push_date_tg_1{
        width: 100%;
        min-height: 100vh;
        background: #F4F5F7;
    }
    .y_push_date_tg_2{
        padding: 0rem 0.45rem;
        height: 0.8rem;
        display: flex; 
        justify-content: space-between;
        align-items: center;
    }
    .y_push_date_tg_3 p{
        font-size: 0.28rem;
        color: #333;
    }
    .y_push_date_tg_3 p span{
        color:#FECE0A;
        font-size: .28rem;
    }
    .y_push_date_tg_4 p{
        font-size: 0.28rem;
        color: #333;
        text-decoration:underline
    }

    /* 高级推送 轮播 */
    .y_push_date_gj_lb_1{
        width: 100%;
        height: 2rem;
    }
    .y_push_date_gj_lb_1 img{
        width: 100%;
        object-fit: cover;
    }
    /* 高级推送 抽奖 */
    .y_push_date_cj_1{
        width: 100%;
        background: #fff;
    }
    .y_push_date_cj_2{
        padding: 0.4rem 0.25rem 0.5rem;
    }
    .y_push_date_cj_3{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0.25rem;
    }
    .y_push_date_cj_3 h2{
        font-size: 0.24rem;
        color: #999;
    }
    .y_push_date_cj_3 p{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_date_cj_3 p span{
        color:#FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_cj_2 .y_push_date_cj_3:nth-last-child(1){
        margin-bottom: 0rem;
    }
    .y_push_date_cj_4{
        width: 100%;
    }
    .y_push_date_cj_5{
        width: 100%;
    }
    .y_push_date_cj_6{
        width: 100%;
        background: #F7F8FA;
        height: 1.6rem;
    }
    .y_push_date_cj_7{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.8rem;
        padding: 0rem 0.3rem;
    }
    .y_push_date_cj_7 h2{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_date_cj_7 p{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_cj_8{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.8rem;
    }
    .y_push_date_cj_8 p{
        flex: 1;
        text-align: center;
        font-size: 0.28rem;
        color: #999;
    }
    .y_push_date_cj_8 p span{
        font-size: 0.28rem;
        color: #FECE0A;
    }
    .y_push_date_cj_9{
        width: 100%;
        background: #fff;
        padding-bottom: 0.5rem;
    }
    .y_push_date_cj_10{
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
    .y_push_date_cj_11{
        flex: 1;
    }
    .y_push_date_cj_11 h2{
        width: 100%;
        line-height: 1rem;
        color: #333;
        font-size: 0.28rem;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 2.6rem;
    }
    .y_push_date_cj_11 p{
        width: 100%;
        text-align: center;
        font-size: 0.28rem;
        color: #333;
    }
    .y_push_date_cj_12 p span{
        font-size: 0.28rem;
        color: #FECE0A;
    }
    .y_push_date_cj_12 h2{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_display_none{
        display: none;
    }
</style>

<script>
    import kefu from '../../components/kefu/kefu'
    import recharge from '../../components/recharge/recharge'
    import axios from 'axios'   // 接口
    import {user_info} from '../../api/request.js' // 用户信息
    import {award_info} from '../../api/request.js' // 抽奖详情
    export default {
        data(){
            return {
                input: '',
                 //公共 钱包充值
                 dialog_visible: false,

                recruit_id:'0', // 待推送 进入传id
                award_list:'',
                grade_list_undergraduate:'',   // 年级数组 本科
                grade_list_specialty:'',    // 年级数组 大专
                school_list:'',    // 学校列表
                undergraduate_display:'0',
                specialty_display:'0',
            }
        },  
        components:{
            kefu,
            recharge,
            axios,
        },
        created(){
            // 获取页面传参 推送类型
            if(this.$route.query.recruit_id !== undefined){
                this.recruit_id = this.$route.query.recruit_id
            }
        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            user_info({token:token}).then((res)=>{
                console.log(res,'用户信息')
                let balance = res.data.balance  // 	余额
                let bonus_fee = res.data.bonus_fee // 奖励金
                let frozen_fee = res.data.frozen_fee  // 冻结金额
                let deposit_fee = res.data.deposit_fee  // 押金金额
                let available_balance = ''      // 可用余额

                this.balance = balance
                this.bonus_fee = bonus_fee
                this.frozen_fee = frozen_fee
                this.deposit_fee = deposit_fee
                this.available_balance = balance*1 + bonus_fee*1 
            })
            let recruit_id = this.recruit_id
            award_info({token:token,award_id:recruit_id}).then((res) =>{
                console.log(res,'抽奖详情-已下线')
                this.award_list = res.award
                
                let grade_list_undergraduate = []
                let res_grade_list_undergraduate = res.award.grade_list_undergraduate
                for(let i=0;i<res_grade_list_undergraduate.length;i++){
                    grade_list_undergraduate.push(res_grade_list_undergraduate[i].title)
                }
                this.grade_list_undergraduate = JSON.stringify(grade_list_undergraduate).replace(/\[|]|"/g,"")

                let grade_list_specialty = []
                let res_grade_list_specialty = res.award.grade_list_specialty
                for(let i=0;i<res_grade_list_specialty.length;i++){
                    grade_list_specialty.push(res_grade_list_specialty[i].title)
                }
                this.grade_list_specialty = JSON.stringify(grade_list_specialty).replace(/\[|]|"/g,"")

                let school_list = res.award_school
                let undergraduate_num = '0'
                let specialty = '0'
                for(let i=0;i<school_list.length;i++){
                    if(school_list[i].school_type_text == "undergraduate"){
                        undergraduate_num++
                    }
                }
                if(undergraduate_num !== '0'){
                    this.undergraduate_display = '1'
                }
                for(let i=0;i<school_list.length;i++){
                    if(school_list[i].school_type_text == "specialty"){
                        specialty++
                    }
                }
                if(specialty !== '0'){
                    this.specialty_display = '1'
                }

                this.school_list = school_list
            })
        },
        methods: {
            // 充值
            showDialog(visible) {
                this.dialog_visible = visible;
            },
        },
    }
</script>