
<template>
    <div class="y_my_edit_profile_1">
        <div class="y_my_change_phone_one_1">
            <div class="y_my_change_phone_one_3 y_my_change_phone_one_8">
                <div class="y_my_earnings_3" @click="pop_up_two">
                    <h2>{{ pop_two_is == '0'?'点击选择':pop_two_value}}</h2>
                    <img src="../../../images/icon_13.png" alt="">
                </div>
                <div class="y_my_change_phone_one_4">
                    <el-input v-model="input_one" placeholder="请输入"></el-input>
                </div>
            </div>
            <div class="y_my_change_phone_one_3 y_my_change_phone_one_8">
                <div class="y_my_withdrawal_1">
                    <h2>提现金额</h2>
                </div>
                <div class="y_my_change_phone_one_4">
                    <el-input v-model="input_three" :placeholder="rebate_fee"></el-input>
                </div>
            </div>
        </div>
        <div class="y_my_change_phone_one_6"></div>
        <div class="y_my_change_phone_one_7" @click="withdrawal_submit">
            <p>提交</p>
        </div>

        <!-- 公共弹窗   遮罩层 -->
        <div class='y_pop_masking' :class='pop_masking == 0 ? "":"y_masking_block"'></div>
        <!-- 工作性质 弹窗 第二个 -->
        <div class='y_pop_two_1' :class='pop_two == 0 ? "y_display_none":"y_display_block"'>
            <div class='y_pop_two_3' @click="pop_two_none">
                <img src="../../../images/icon_17.png" alt="">
            </div>
            <div class='y_pop_two_2'>
                <p v-for='pop_two in pop_twos' :key='pop_two.id' @click="pop_two_select(pop_two.id)" :value='pop_two.text'>{{pop_two.text}}</p>
            </div>
        </div>

    </div>
    
</template>
<style scoped>
    .y_my_edit_profile_1{
        min-height: 100vh;
        width: 100%;
        background: #F4F5F7;
    }
    .y_my_change_phone_one_1{
        width: 100%;
        background: #fff;
    }
    .y_my_change_phone_one_2{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1.2rem;
        border-bottom: 0.01rem solid #EDEDED;
        padding: 0rem 0.3rem;
    }
    .y_my_change_phone_one_2 h2{
        font-size: 0.3rem;
        color:#333;
        margin-right: 0.35rem;
    }
    .y_my_change_phone_one_2 p{
        font-size: 0.3rem;
        color: #000;
    }
    .y_my_change_phone_one_3{
        padding: 0rem 0.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.2rem;
    }
    .y_my_change_phone_one_4{
        flex: 1;
    }
    .y_my_change_phone_one_4 /deep/ input{
        background: transparent;
        padding: 0rem;
        height: 1.2rem;
        line-height: 1.2rem;
        color:#333;
        font-size: 0.3rem;
        border-radius: 0rem;
        border: none;
    }
    .y_my_change_phone_one_5{
        width: 2rem;
        text-align: center;
    }
    .y_my_change_phone_one_5 div{
        font-size: 0.3rem;
        color: #FECE0A;
    }
    .y_my_change_phone_one_6{
        width: 100%;
        height: 2.2rem;
        background: transparent;
    }
    .y_my_change_phone_one_7{
        position: fixed;
        bottom: 0.6rem;
        width: calc(100% - 0.5rem);
        left: 0.25rem;
        background: #FECE0A;
        text-align: center;
        height: 1rem;
        line-height: 1rem;
        border-radius: 0.1rem;
        overflow: hidden;
        font-size: 0.32rem;
        color: #333;
    }
    .y_my_change_phone_one_8{
        border-bottom: 0.01rem solid #EDEDED;
    }

    /* 提现页面 */
    .y_my_withdrawal_1 h2{
        font-size: 0.3rem;
        color: #333;
        margin-right: 0.2rem;
    }

    .y_my_earnings_3{
        min-width: 1.6rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_my_earnings_3 h2{
        font-size: 0.3rem;
        color: #333;
    }
    .y_my_earnings_3 img{
        width: 0.16rem;
        object-fit: cover;
        margin-left: 0.1rem;
    }
</style>
<script>
    import axios from 'axios'   // 接口
    import {rebate_detail} from '../../api/request.js' // 我的收益详情
    import {add_withdraw} from '../../api/request.js' // 添加提现
    
    export default {
        data(){
            return {
                pop_masking:'0',
                pop_two_value:'',
                pop_two:'0',
                pop_two_is:'0',

                pay_value:'',
                pop_twos:[
                    {id:'1',text:'支付宝',is:'0',value:'alipay'},
                    {id:'2',text:'微信',is:'0',value:'微信'}
                ],
                token:'',
                input_one: '',
                input_three:'',
                rebate_fee:'', // 我的 剩余返利金额
            }
        },  
        components:{

        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            // 我的收益详情
            rebate_detail({token:token}).then((res) =>{
                console.log(res,'我的收益详情')
                this.rebate_fee = res.rebate_fee
            })
        },
        methods: {
            // 工作性质 弹窗 第二个
            pop_up_two(e){
              this.pop_masking = '1'
              this.pop_two = '1'
            },
            pop_two_none(e){
              this.pop_masking = '0'
              this.pop_two = '0'
            },
            pop_two_select(e){
              console.log(e)
              this.pop_two_is = '1'
              this.pop_masking = '0'
              this.pop_two = '0'
              let pop_twos_list = this.pop_twos
              for(let i=0;i<pop_twos_list.length;i++){
                  pop_twos_list[i].is = '0'
                  if(pop_twos_list[i].id == e){
                        pop_twos_list[i].is = '1'
                        if(i == '0'){
                            this.pay_value = 'alipay'
                            this.pop_two_value = '支付宝'
                        }else if(i == '1'){
                            this.pay_value = 'weixin'
                            this.pop_two_value = '微信'
                        }
                  }
              }
              this.pop_twos = pop_twos_list
              console.log(this.pop_twos)
            },
            // 提现提交
            withdrawal_submit(){
                let token = this.token
                let input_one = this.input_one
                let input_three = this.input_three
                let rebate_fee = this.rebate_fee
                let pay_value = this.pay_value
                let pop_two_value = this.pop_two_value
                if(pop_two_value == ''){
                    this.$message({
                        message:'请选择提现方式',
                        duration:'2000'
                    })
                    return
                }
                if(input_one == '' || input_three == ''){
                    this.$message({
                        message:'请输入',
                        duration:'2000'
                    })
                    return
                }
                if(input_three > rebate_fee){
                    this.$message({
                        message:'超出提现金额',
                        duration:'2000'
                    })
                    return
                }
                // 添加提现
                add_withdraw({token:token,price:input_three,pay_type:'rebate_fee',withdraw_type:pay_value}).then((res) =>{
                    console.log(res,'添加提现')
                    if(res.error_code == '0'){
                        this.$message({
                            message:'提交成功',
                            duration:'1000'
                        })
                        var that = this;
                        setTimeout(function () {
                            that.$router.push({path:'/'}) 
                        },1000);
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'1000'
                        })
                    }
                })
            }    
        },
    }

</script>
<style>
    .y_pop_two_1{
        height: 14vh;
    }
</style>