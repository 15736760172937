
<template>
    <div class="y_my_edit_profile_1">
        <div class="y_my_personal_one_1">
            <div class="y_my_personal_one_2">
                <h2>【个人认证】</h2>
                <p>{{gr_accreditation}}</p>
            </div>
        </div>
        <div class="y_my_personal_one_3">
            <div class="y_my_personal_one_4">
                <div class="y_my_personal_one_5">
                    <h2>姓名</h2>
                </div>
                <div class="y_my_personal_one_6">
                    <el-input v-model="input_one" placeholder="请输入真实姓名"></el-input>
                </div>
            </div>
            <div class="y_my_personal_one_4">
                <div class="y_my_personal_one_5"> 
                    <h2>身份证号</h2>
                </div>
                <div class="y_my_personal_one_6">
                    <el-input v-model="input_two" placeholder="请输入身份证号"></el-input>
                </div>
            </div>
        </div>

        <div class="y_my_change_phone_one_6"></div>
        <div class="y_my_change_phone_one_7" @click="go_certification">
            <p>去认证</p>
        </div>
    </div>
</template>

<style scoped>
    .y_my_edit_profile_1{
        min-height: 100vh;
        width: 100%;
        background: #F4F5F7;
    }
    .y_my_personal_one_1{
        padding: 0.4rem 0.25rem 0rem;
    }
    .y_my_personal_one_2{
        min-height: 2.2rem;
        background: #fff;
        border-radius: 0.1rem;
        overflow: hidden;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        padding: 0rem 0.4rem;
        box-shadow: 0 0 0.2rem 0.05rem #ccc;
    }
    .y_my_personal_one_2 h2{
        color: #333;
        font-size: 0.32rem;
        font-weight: 600;
        width: 100%;
    }
    .y_my_personal_one_2 p{
        width: 100%;
        margin-top: 0.2rem;
        color: #999;
        font-size: 0.28rem;
    }
    .y_my_personal_one_3{
        width: 100%;
        margin-top: 0.4rem;
        background: #fff;
    }
    .y_my_personal_one_4{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.2rem;
        padding: 0rem 0.3rem;
        border-bottom: 0.01rem solid #EDEDED;
    }
    .y_my_personal_one_5{
        width: 2rem;
    }
    .y_my_personal_one_5 h2{
        font-size: 0.3rem;
        color: #999;
    }
    .y_my_personal_one_6{
        flex:1;
    }
    .y_my_personal_one_6 /deep/ input{
        width: 100%;
        height: 1.2rem;
        border-radius: 0rem;
        border:none;
        background: transparent;
        line-height: 1.2rem;
        font-size: 0.3rem;
        color: #333;
        text-align: right;
    }

    .y_my_change_phone_one_6{
        width: 100%;
        height: 2.2rem;
        background: transparent;
    }
    .y_my_change_phone_one_7{
        position: fixed;
        bottom: 0.6rem;
        width: calc(100% - 0.5rem);
        left: 0.25rem;
        background: #FECE0A;
        text-align: center;
        height: 1rem;
        line-height: 1rem;
        border-radius: 0.1rem;
        overflow: hidden;
        font-size: 0.32rem;
        color: #333;
    }
</style>
<script>
    import {configuration_fields} from '../../api/request.js' // 配置字段的信息
    export default {
        data(){
            return {
                input_one: '',
                input_two:'',
                gr_accreditation:'',

            }
        },  
        components:{

        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            // 配置字段的信息
            configuration_fields({}).then((res)=>{
                console.log(res,'配置字段的信息')
                this.gr_accreditation = res.data.personal_authentication
            })
        },
        methods: {
            // 去认证
            go_certification(){
                let input_one = this.input_one
                let input_two = this.input_two
                if(input_one == '' || input_two == ''){
                    this.$message({
                        message:'请填写',
                        duration:'1000'
                    })
                    return
                }
                this.$router.push({
                    path:'/my_personal_certification_two',
                    query: {
                        name:input_one,
                        id_card:input_two
                    }
                })
            }   
        },
    }

</script>