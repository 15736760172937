<template>
    <div class="y_index">
        <div class='y_index_1'>
            <div class='y_index_2' @click="tuisong_show()">如何推送？</div>
            <div class='y_index_2' @click="shoufei_show()">如何收费？</div>
        </div>
        <!-- 如何推送？ -->
        <div class="y_tuisong_1" :class="pop_tuisong == '0' ? 'y_display_none':'y_display_block'">
            <div class="y_tuisong_2">
              <div class="y_tuisong_3">
                <h2>如何推送？</h2>
              </div>
              <p v-html="business_how_to_push" class="y_tuisong_4"></p>
              <div class="y_tuisong_5">
                <p @click="tuisong_hide()">知道了</p>
              </div>
            </div>
        </div>
        <!-- 如何收费？ -->
        <div class="y_tuisong_1" :class="pop_shoufei == '0' ? 'y_display_none':'y_display_block'">
            <div class="y_tuisong_2">
              <div class="y_tuisong_3">
                <h2>如何收费？</h2>
              </div>
              <p v-html="business_how_to_charge" class="y_tuisong_4"></p>
              <div class="y_tuisong_5">
                <p @click="shoufei_hide()">知道了</p>
              </div>
            </div>
        </div>
        <!-- 公共弹窗   遮罩层 -->
        <div class='y_pop_masking' :class='pop_masking == 0 ? "":"y_masking_block"'></div>

        <div class="y_recruit_1">
          <img src="../../../images/img_3.png" alt="">
        </div>
        
        <div class='y_push_setting_1'>
            <div class='y_push_setting_2'>
                <div class='y_push_setting_3'>
                    <p>给每人最多推送</p>
                </div>
                <div class='y_push_setting_4' @click="pop_one_block">
                    <p>{{onepeoples_max}}次</p>
                    <img src="../../../images/icon_13.png" alt="">
                </div>
            </div>
        </div>

        <div class='y_push_setting_5'>
            <h2>推送时间</h2>
            <div class='y_push_setting_6'>
                <div class='y_push_setting_7' @click="pop_time_one_block">
                    <p>{{time_one_Value}}</p>
                </div>
                <p>-</p>
                <div class='y_push_setting_7' @click="pop_time_two_block">
                    <p>{{time_two_Value}}</p>
                </div>
            </div>
        </div>
        <div class='y_push_setting_8' @click="bachelor_show">
            <div class='y_push_setting_9'>
                <div class='y_push_setting_10'>
                    <div class='y_push_setting_11'>
                        <p>本科学校中推送给</p>
                    </div>
                    <div class='y_push_setting_12'>
                        <p>{{bachelor_dete_list}}</p>
                    </div>
                </div>
                <div class='y_push_setting_13'>
                    <img src="../../../images/icon_24.png" alt="">
                </div>
            </div>
            <div class='y_push_setting_14'>
                <div class='y_push_setting_15 y_flex_2'>
                    <p>单次曝光<span>{{undergraduate_money}}</span>元</p>
                </div>
                <div class='y_push_setting_15 y_flex_1'>
                    <p>设置曝光量</p>
                </div>
                <div class='y_push_setting_15 y_flex_1'>
                    <p>预算</p>
                </div>
            </div>
        </div>
        <div class='y_push_setting_16'>

            <div class='y_push_setting_18' v-for="undergraduate_school_list in undergraduate_school_lists" :key="undergraduate_school_list.grade_level_text">
                <div class='y_push_setting_17 y_flex_2'>
                    <p>{{undergraduate_school_list.title}}</p>
                </div>
                <div class='y_push_setting_17 y_push_setting_19 y_flex_1'>
                    <input type="number" readonly="readonly"  v-model="undergraduate_school_list.input" @input="undergraduate_input(undergraduate_school_list.school_id)" placeholder="请输入" @click="user_number_click(1,undergraduate_school_list.school_id)">
                </div>
                <div class='y_push_setting_17 y_push_setting_20 y_flex_1'>
                    <p>{{undergraduate_school_list.money}}元</p>
                </div>
            </div>

        </div>
        <div class='y_push_setting_8' @click="specialty_show">
            <div class='y_push_setting_9'>
                <div class='y_push_setting_10'>
                    <div class='y_push_setting_11'>
                        <p>专科学校中推送给</p>
                    </div>
                    <div class='y_push_setting_12'>
                        <p>{{specialty_dete_list}}</p>
                    </div>
                </div>
                <div class='y_push_setting_13'>
                    <img src="../../../images/icon_24.png" alt="">
                </div>
            </div>
            <div class='y_push_setting_14'>
                <div class='y_push_setting_15 y_flex_2'>
                    <p>单次曝光<span>{{specialty_money}}</span>元</p>
                </div>
                <div class='y_push_setting_15 y_flex_1'>
                    <p>设置曝光量</p>
                </div>
                <div class='y_push_setting_15 y_flex_1'>
                    <p>预算</p>
                </div>
            </div>
        </div>
        <div class='y_push_setting_16'>
            <div class='y_push_setting_18' v-for="specialty_school_list in specialty_school_lists" :key="specialty_school_list.grade_level_text">
                <div class='y_push_setting_17 y_flex_2'>
                    <p>{{specialty_school_list.title}}</p>
                </div>
                <div class='y_push_setting_17 y_push_setting_19 y_flex_1'>
                    <input type="number" readonly="readonly"  v-model="specialty_school_list.input" @input="specialty_input(specialty_school_list.school_id)" placeholder="请输入" @click="user_number_click(2,specialty_school_list.school_id)">
                </div>
                <div class='y_push_setting_17 y_push_setting_20 y_flex_1'>
                    <p>{{specialty_school_list.money}}元</p>
                </div>
            </div>
        </div>
    </div>

    <div class='y_push_setting_21'></div>
    <div class='y_push_setting_22' v-show="hideshow">
        <div class='y_push_setting_23'>
            <div class='y_push_setting_24'>
                <p>总曝光量<span>{{Total_input}}</span>，预算总计<span>{{Total_money}}</span>元</p>
            </div>
            <div class="y_push_setting_25_1" style="height: 0.4rem;width: 100%;margin-top: 0.2rem;" v-show="price_comparison == '1'? '' : true "></div>
            <div class='y_push_setting_25' v-show="price_comparison == '0'?'':true">
                <p class='y_push_setting_29'>余额不足，</p>
                <p class='y_push_setting_30' @click="showDialog(true)">点击充值</p>
            </div>
            <div class='y_push_setting_26'>
                <p class='y_push_setting_27' v-if="promotion_value == '0'?true:false" @click="back_value_two()">返回</p>
                <p class='y_push_setting_27 y_push_setting_27_1' v-if="promotion_value == '0'?(general_index == '1'?fales:true):false" @click="back_value()">完成</p>
                <p class='y_push_setting_28' :class="general_index == '1'?'':'y_display_none'" @click="publish_pop()">发布</p>
            </div>
        </div>
    </div>


    <!-- 公共弹窗 阴影-->
    <div class='y_comm_shadows' :class="comm_shadows_value == '1'?'y_comm_shadows_block':''"></div>
    <!-- 给每人最多推送 弹窗 -->
    <div class='y_person_push_1' :class="pop_one_value == '0'?'y_none':'y_block'">
        <div class='y_person_push_2'>
            <h2>给每人最多推送</h2>
        </div>
        <div class='y_person_push_3'>
            <input type="number" name="" id="" placeholder="请填写" :value="onepeoples_max" @change="onepeoples_max_value">
            <p>次</p>
        </div>
        <div class='y_person_push_2 y_person_push_5'>
            <h2>给同一人一天内最多推送</h2>
        </div>
        <div class='y_person_push_4'>
            <p v-for="oneday_onepeople in oneday_onepeoples" :key="oneday_onepeople.index" :class='oneday_onepeople.is == "0"?"":"y_person_push_4_active"' @click="oneday_onepeople_dete(oneday_onepeople.index)">{{oneday_onepeople.text}}</p>
        </div>
        <div class='y_person_push_6'>
            <p class='y_person_push_7' @click="pop_one_none">取消</p>
            <p class='y_person_push_8' @click="pop_one_none">确定</p>
        </div>
    </div>
    <!-- 时间选择 最小值 -->
    <div class='y_pop_time_min' :class='pop_time_one_value == "0"?"":"y_pop_time_min_active"' v-show="hideshow">
        <van-datetime-picker v-model="currentDate" disabled="disabled" type="datetime" title="" :min-date="minDate" :max-date="maxDate" @confirm='pop_time_one_dete' @cancel='pop_time_one_cancel'/>
    </div>
    <!-- 时间选择 最大值 --> 
    <div class='y_pop_time_min' :class='pop_time_two_value == "0"?"":"y_pop_time_min_active"' v-show="hideshow">
        <van-datetime-picker v-model="currentDate" disabled="disabled" type="datetime" title="" :min-date="minDate" :max-date="maxDate" @confirm='pop_time_two_dete' @cancel='pop_time_two_cancel'/>
    </div>
    <!-- 本科年级 -->
    <div class='y_bachelor_school_1' :class="bachelor_school_value == '0'?'':'y_bachelor_school_active'">
        <div class='y_bachelor_school_2'>
            <h2>选择推送给哪些年级</h2>
            <p>单次曝光<span>{{undergraduate_money}}</span>元</p>
        </div>
        <div class='y_bachelor_school_3'>
            <p v-for="bachelor_school in bachelor_schools" :key="bachelor_school.grade_level_text" @click="bachelor_dete(bachelor_school.grade_level_text)" :class='bachelor_school.is == "0"?"":"y_bachelor_school_3_active"'>{{bachelor_school.title}}</p>
        </div>
        <div class='y_bachelor_school_4'>
            <p class='y_bachelor_school_5' @click="bachelor_hide">返回</p>
            <p class='y_bachelor_school_6' @click="bachelor_determine">确定</p>
        </div>
    </div>

    <!-- 专科年级 -->
    <div class='y_bachelor_school_1' :class="specialty_school_value == '0'?'':'y_bachelor_school_active'">
        <div class='y_bachelor_school_2'>
            <h2>选择推送给哪些年级</h2>
            <p>单次曝光<span>{{specialty_money}}</span>元</p>
        </div>
        <div class='y_bachelor_school_3'>
            <p v-for="specialty_school in specialty_schools" :key="specialty_school.grade_level_text" @click="specialty_dete(specialty_school.grade_level_text)" :class='specialty_school.is == "0"?"":"y_bachelor_school_3_active"'>{{specialty_school.title}}</p>
        </div>
        <div class='y_bachelor_school_4'>
            <p class='y_bachelor_school_5' @click="specialty_hide">返回</p>
            <p class='y_bachelor_school_6' @click="specialty_determine">确定</p>
        </div>
    </div>

    <!-- 发布确定 弹窗 -->
    <!-- 曝光量弹出 -->
    <div class='y_publish_pop_1' :class="publish_pop_value == '0'?'':'y_publish_pop_1_active'">
        <div class='y_publish_pop_2'>
            <div class='y_publish_pop_3'>
                <h2>您选择推送给<span v-if="user_number_is == '1'?true:false">{{ bachelor_dete_list }}</span><span v-if="user_number_is == '2'?true:false">{{specialty_dete_list}}</span>的用户总数为<p>{{user_number_grade_people_num}}</p></h2>
                 <h2>给每人最多推送<p>{{onepeoples_max}}</p>次</h2>
            </div>
            <div class='y_publish_pop_4'>
                <input type="number" placeholder="请设置曝光量" v-model="school_num_input_val" @input="school_num_input()">
            </div>
            <div class='y_publish_pop_5'>
                <p>单次曝光<span v-if="user_number_is == '1'?true:false">{{undergraduate_money}}</span><span v-if="user_number_is == '2'?true:false">{{specialty_money}}</span>元,预算<span>{{user_number_total_price}}</span>元</p>
            </div>
            <div class='y_publish_pop_6'>
                <p class='y_publish_pop_7' @click="publish_no()">返回</p>
                <p class='y_publish_pop_8' @click="publish_yes()">确定</p>
            </div>
        </div>
    </div>



    <!-- 余额不足 弹出 -->
    <div class="y_balance_not_pop_1" :class="balance_not_is == '0'?'y_display_none':'y_display_block'">
        <div class="y_balance_not_pop_2">
            <div class="y_balance_not_pop_3">
                <p>预算总计<span>{{Total_money}}</span>元，当前余额<span>{{principal_amount}}</span>元 余额不足，请先充足余额</p>
            </div>
            <div class="y_balance_not_pop_4">
                <p class="y_balance_not_pop_5" @click="balance_not_back">返回</p>
                <p class="y_balance_not_pop_6" @click="balance_not_recharge(true)">充值余额</p>
            </div>
        </div>
    </div>

    <!-- 提交审核 -->
    <div class="y_submit_review_pop_1" :class="submit_review_is == '0'?'y_display_none':'y_display_block'">
        <div class="y_submit_review_pop_2">
            <div class="y_submit_review_pop_3">
                <p>喵君将尽快审核完毕，<br>审核通过后自动发布</p>
            </div>
            <div class="y_submit_review_pop_4">
                <p class="y_submit_review_pop_5" @click="submit_review_back">返回</p>
                <p class="y_submit_review_pop_6" @click="submit_review_recharge">提交审核</p>
            </div>
        </div>
    </div>

    <kefu></kefu>
    <recharge v-show="dialog_visible" :dialog_visible="dialog_visible" @dialogVisibleEvent="showDialog"></recharge>
</template>
<style>
    @import url(./push_settings_style.css);
    /* 余额不足 弹出 */
    .y_balance_not_pop_1{
        width: 5.2rem;
        height: 3.5rem;
        background: #fff;
        border-radius: 0.1rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 20;
        overflow: hidden;
    }
    .y_balance_not_pop_2{
        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        height: 100%;
    }
    .y_balance_not_pop_3{
        width: 100%;
        text-align: center;
    }    
    .y_balance_not_pop_3 p{
        color: #333;
        font-size: 0.3rem;
    }
    .y_balance_not_pop_3 p span{
        color: #FECE0A;
        font-size: 0.3rem;
    }
    .y_balance_not_pop_4{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
    }
    .y_balance_not_pop_4 p{
        width: 2rem;
        height: 0.7rem;
        border-radius: 1rem;
        text-align: center;
        font-size: 0.3rem;
        line-height: 0.7rem;
    }
    .y_balance_not_pop_5{
        color: #666;
        background: #ECECEC;
        margin-right: 0.1rem;
    }
    .y_balance_not_pop_6 {
        color: #333;
        background: #FECE0A;
    }
    /* 余额不足 弹出 */

    /* 提交审核 */
    .y_submit_review_pop_1{
        width: 5.2rem;
        height: 3.5rem;
        background: #fff;
        border-radius: 0.1rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 20;
        overflow: hidden;
    }
    .y_submit_review_pop_2{
        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        height: 100%;
    }
    .y_submit_review_pop_3{
        width: 100%;
        text-align: center;
    }    
    .y_submit_review_pop_3 p{
        color: #333;
        font-size: 0.3rem;
    }
    .y_submit_review_pop_4{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
    }
    .y_submit_review_pop_4 p{
        width: 2rem;
        height: 0.7rem;
        border-radius: 1rem;
        text-align: center;
        font-size: 0.3rem;
        line-height: 0.7rem;
    }
    .y_submit_review_pop_5{
        color: #666;
        background: #ECECEC;
        margin-right: 0.1rem;
    }
    .y_submit_review_pop_6 {
        color: #333;
        background: #FECE0A;
    }
    /* 提交审核 */

    .y_flex_2{
        text-align: left !important;
    }
</style>

<script>
import axios from 'axios'   // 接口
import {school_grade_list} from '../../api/request.js' // 所有学校和年级列表
import {all_prices} from '../../api/request.js' // 商务端所有价格的信息
import {configuration_fields} from '../../api/request.js'   // 配置字段
import {edit_blog_common} from '../../api/request.js'   // 添加和编辑帖子，普通推广

import {school_grade_user_number} from '../../api/request.js'   // 根据学校和各个年级，统计出学生人数

import {recruit_common_info} from '../../api/request.js'   // 招聘详情,普通推广
import {blog_common_info} from '../../api/request.js'   // 帖子详情,普通推广
import {submit_check_blog_common} from '../../api/request.js'   // 帖子提交审核
import { ref } from 'vue';
import kefu from '../../components/kefu/kefu'
import recharge from '../../components/recharge/recharge'
import {user_info} from '../../api/request.js' // 用户信息

export default {
    data(){
      return {
            docmHeight: document.documentElement.clientHeight ||document.body.clientHeight,
            showHeight: document.documentElement.clientHeight ||document.body.clientHeight,
            hideshow:true,  //显示或者隐藏footer

            pop_tuisong:'0',
            pop_shoufei:'0',
            // 如何收费
            business_how_to_charge:'',
            // 如何推送
            business_how_to_push:'',
            pop_masking:'0',
            recruit_date:'',   // 存储的数组
            shade_value:'0',
            pop_one_value:'0',
            dialog_visible: false,
            onepeoples_max:'1',  // 单个用户的最大推送次数
            comm_shadows_value:'',
            oneday_onepeoples:[
                {index:'1',text:'1',is:'1'},
                {index:'2',text:'2',is:'0'},
                {index:'3',text:'3',is:'0'},
            ],
            // 第一个时间选择
            time_one_Value:'请选择时间',
            pop_time_one_value:'0',
            // 第二个时间选择
            time_two_Value:'请选择时间',
            pop_time_two_value:'0',
            // 本科大学 列表
            bachelor_schools:[
                {index:'1',text:'大一',is:'0'},
                {index:'2',text:'大二',is:'0'},
                {index:'3',text:'大三',is:'0'},
                {index:'4',text:'大四',is:'0'},
                {index:'5',text:'大五',is:'0'},
                {index:'6',text:'研一',is:'0'},
                {index:'7',text:'研二',is:'0'},
                {index:'8',text:'研三',is:'0'},
            ],
            // 专科大学 列表
            specialty_schools:'',

            bachelor_school_value:'0',
            specialty_school_value:'0',
            publish_pop_value:'0',
            //公共 钱包充值
            dialog_visible: false,
            // 单个用户每日的最大推送次数
            oneday_onepeoples_value:'1',
            
            // 本科年级 确定列表
            bachelor_dete_list:'',
            // 本科单价
            undergraduate_money:'',
            // 本科学校 列表
            undergraduate_school_lists:'',  
            // 本科 曝光总量 
            undergraduate_total_input:'',
            // 本科 预算总价 
            undergraduate_total_money:'',
            // 本科年级 传id
            bachelor_dete_id:'',

            // 专科年级 传id
            specialty_dete_id:'',
            // 专科年级 确定列表
            specialty_dete_list:'',
            // 专科大学 列表
            school_specialty_list:'',
            // 专科单价
            specialty_money:'',
            // 专科学校 列表
            specialty_school_lists:'',
            // 专科 曝光总量 
            specialty_total_input:'',
            // 专科 预算总价 
            specialty_total_money:'',

            // 总共曝光量
            Total_input:'0',
            // 总共 预算
            Total_money:'0',

            // 普通 推送
            general_index:'',  // 当为1时是普通推送
            general_index_index:'', // 当为1时是推广 2是招生
            general_list:'',  // 普通推广
            city_id_chun:'',  // 存 城市 id
            vote_type_text:'',  // 传 投票类型

            recruit_id:'0', // 待推送 进入传id
            recruitment_value:'0', // 进入传value  0代表正常进入，1为待推送进入

            blog_id:'0', // 推广 进入传id
            promotion_value:'0', // 进入传value  0代表正常进入，1为待推送进入

            price_comparison:'0',  // 价格比较 默认为0不显示
            principal_amount:'',  // 总 本金金额
            balance_not_is:'0',  // 余额不足
            submit_review_is:'0', // 提交审核
            back_award_id:'',       // 提交审核之后返回列表id
            user:'',  // 用户金额信息
            bj_news:'0', // 编辑新推送 0为正常进入 1是编辑新推送
            xxl_select:'', // 信息类 推送类型 topic话题，group小组，activity活动
            

            user_number_is:'0',     // 当前选中的是本科还是专科 1是本科 2是专科
            school_num_input_val:'',    // 输入框的值
            user_number_grade:'',  // 年级展示
            user_number_grade_people_num:'', // 年级人数
            user_number_total_price:'0',   // 总价
            school_num_id:'',     // 当前选中的学校id
            minDate:'',
            maxDate:'',
            timestamp_one:'',       // 第一个时间戳
            timestamp_two:'',       // 第二个时间戳
        }
    },
    components:{
        kefu,
        recharge,
        axios,
    },
    created(){
        // 获取页面传参 推送类型
        this.general_index = this.$route.query.general_index
        this.general_index_index = this.$route.query.general_index_index

        if(this.$route.query.recruit_id !== undefined){
            this.recruit_id = this.$route.query.recruit_id
            this.recruitment_value = this.$route.query.recruitment_value
            this.bj_news = this.$route.query.bj_news
        }
        if(this.$route.query.blog_id !== undefined){
            this.blog_id = this.$route.query.blog_id
            this.promotion_value = this.$route.query.promotion_value
            this.bj_news = this.$route.query.bj_news
        }
        if(this.$route.query.xxl_select !== undefined){
            this.xxl_select = this.$route.query.xxl_select
        }
    },
    watch:{
        '$route'(val,from){
          // 帖子传参
        },
        //监听显示高度
        showHeight:function() {
            if(this.docmHeight > this.showHeight){
                //隐藏
                this.hideshow=false
            }else{
                //显示
                this.hideshow=true
            }
        }
    },
    // 进入页面开始执行
    mounted(){
        let token = this.$cookies.get('token')
        let that = this;
        let user = JSON.parse(sessionStorage.getItem('user'))
        if(token == null){
            this.$message({
                message: '请先登录！',
                duration:'2000'
            })
            setTimeout(function () {
                that.$router.push({path:'/login'}) 
            },2500);
        }
        that.token = token
        // 钱包金额
        user_info({token:token}).then((res)=>{
            this.user = res.data
            this.principal_amount = res.data.balance*1 + res.data.bonus_fee*1
        })
        // if(that.user == 'user'){
        //     that.principal_amount = that.user.balance*1 + that.user.bonus_fee*1
        // }else{
        //     let user = JSON.parse(sessionStorage.getItem('user'))
        //     that.user = user
        //     that.principal_amount = that.user.balance*1 + that.user.bonus_fee*1
        // }

        //监听事件
        window.onresize = ()=>{
          return(()=>{
              this.showHeight = document.documentElement.clientHeight || document.body.clientHeight;
          })()
        }

        let recruit_date = JSON.parse(sessionStorage.getItem('recruit_date_key'));
        let city_id_chun = sessionStorage.getItem('city_id_chun')  // 传 城市
        let general_list = JSON.parse(sessionStorage.getItem('general_list'));  // 普通推广 列表 传
        this.recruit_date = recruit_date
        this.general_list = general_list
        this.city_id_chun = city_id_chun
        let general_index = this.general_index
        let general_index_index = this.general_index_index

        let recruit_id = this.recruit_id
        let recruitment_value = this.recruitment_value
        if(recruitment_value == '1'){
            recruit_common_info({token:token,recruit_id:recruit_id}).then((res_one) =>{
                console.log(res_one,'招聘详情,普通推广')
                this.onepeoples_max = res_one.recruit.max_push_number
                let oneday_onepeoples = this.oneday_onepeoples
                let max_push_number_day = res_one.recruit.max_push_number_day
                for(let i=0;i<oneday_onepeoples.length;i++){
                    oneday_onepeoples[i].is = '0'
                    if(oneday_onepeoples[i].text == max_push_number_day){
                        oneday_onepeoples[i].is = '1'
                    }
                }
                this.oneday_onepeoples = oneday_onepeoples
                this.time_one_Value = res_one.recruit.push_starttime
                this.time_two_Value = res_one.recruit.push_endtime
                let grade_ids_undergraduate = res_one.cost_common.grade_undergraduate  // 带入的本科id
                let grade_ids_specialty = res_one.cost_common.grade_specialty  // 带入的专科id

 
                let bachelor_schools = res_one.cost_common.grade_undergraduate
                let bachelor_dete_id = ''
                for(let i=0;i<bachelor_schools.length;i++){
                    bachelor_dete_id = bachelor_dete_id +','+ bachelor_schools[i].grade_level_text
                }
                this.bachelor_dete_id = JSON.stringify(bachelor_dete_id.substr(1,bachelor_dete_id.length))

                let specialty_schools = res_one.cost_common.grade_specialty
                let specialty_dete_id = ''
                for(let i=0;i<specialty_schools.length;i++){
                    specialty_dete_id = specialty_dete_id +','+ specialty_schools[i].grade_level_text
                }
                this.specialty_dete_id = JSON.stringify(specialty_dete_id.substr(1,specialty_dete_id.length))

                // this.bachelor_dete_id = res_one.cost_common.grade_ids_undergraduate
                // this.specialty_dete_id = res_one.cost_common.grade_ids_specialty

                let cost_common_school = res_one.cost_common_school
                let undergraduate_total_money = ''
                let specialty_total_money = ''
                for(let i=0;i<cost_common_school.length;i++){
                    if(cost_common_school[i].school_type_text == 'undergraduate'){
                        undergraduate_total_money = undergraduate_total_money*1 + cost_common_school[i].total_fee*1
                    }
                    if(cost_common_school[i].school_type_text == 'specialty'){
                        specialty_total_money = specialty_total_money*1 + cost_common_school[i].total_fee*1
                    }
                }
                this.undergraduate_total_money = undergraduate_total_money
                this.specialty_total_money = specialty_total_money

                this.Total_money = res_one.cost_common.exposure_total_fee
                school_grade_list({is_show_cost_common:'1',city_id:sessionStorage.getItem('city_id_chun')}).then((res)=>{
                    if(res.error_code == '0'){
                        console.log(res,'学校和年级')
                        let grade_undergraduate_list = res.grade_undergraduate  // 本科年级
                        let school_undergraduate_list = res.school_undergraduate   // 本科学校列表

                        let grade_specialty_list = res.grade_specialty  //  专科年级
                        let school_specialty_list = res.school_specialty  // 专科学校列表

                        let bachelor_dete_list = []
                        let specialty_dete_list = []
                        // 本科 年级
                        for(let i=0;i<grade_undergraduate_list.length;i++){
                            grade_undergraduate_list[i].is = '0'
                            for(let k=0;k<grade_ids_undergraduate.length;k++){
                                if(grade_undergraduate_list[i].grade_level_text == grade_ids_undergraduate[k].grade_level_text){
                                    grade_undergraduate_list[i].is = '1'
                                    bachelor_dete_list.push(grade_undergraduate_list[i].title)
                                }
                            }
                        }
                        let specialty_dete_list_one = JSON.stringify(bachelor_dete_list)
                        this.bachelor_dete_list = specialty_dete_list_one.replace(/\[|]|"/g,"")
                        // this.bachelor_dete_list = bachelor_dete_list
                        // 专科 年级
                        for(let i=0;i<grade_specialty_list.length;i++){
                            grade_specialty_list[i].is = '0'
                            for(let k=0;k<grade_ids_specialty.length;k++){
                                if(grade_specialty_list[i].grade_level_text == grade_ids_specialty[k].grade_level_text){
                                    grade_specialty_list[i].is = '1'
                                    specialty_dete_list.push(grade_specialty_list[i].title)
                                }
                            }
                        }
                        let specialty_dete_list_two = JSON.stringify(specialty_dete_list)
                        this.specialty_dete_list = specialty_dete_list_two.replace(/\[|]|"/g,"")
                        // this.specialty_dete_list = specialty_dete_list

                        let left_num = ''
                        for(let i=0;i<school_undergraduate_list.length;i++){
                            school_undergraduate_list[i].input = ''
                            school_undergraduate_list[i].money = ''
                            for(let k=0;k<cost_common_school.length;k++){
                                if(cost_common_school[k].school_type_text == 'undergraduate'){
                                    if(school_undergraduate_list[i].school_id == cost_common_school[k].school_id){
                                        school_undergraduate_list[i].input = cost_common_school[k].exposure_number
                                        left_num = left_num*1 + cost_common_school[k].exposure_number*1
                                        school_undergraduate_list[i].money = cost_common_school[k].total_fee
                                    }
                                }
                            }
                        }
                        let right_num = ''
                        for(let i=0;i<school_specialty_list.length;i++){
                            school_specialty_list[i].input = ''
                            school_specialty_list[i].money = ''
                            for(let k=0;k<cost_common_school.length;k++){
                                if(cost_common_school[k].school_type_text == 'specialty'){
                                    if(school_specialty_list[i].school_id == cost_common_school[k].school_id){
                                        school_specialty_list[i].input = cost_common_school[k].exposure_number
                                        right_num = right_num*1 + cost_common_school[k].exposure_number*1
                                        school_specialty_list[i].money = cost_common_school[k].total_fee
                                    }
                                }
                            }
                        }
                        this.Total_input = right_num*1 + left_num*1
                        this.bachelor_schools = grade_undergraduate_list   // 赋值 本科年级
                        this.undergraduate_school_lists = school_undergraduate_list  // 赋值 本科学校列表
                        this.specialty_schools = grade_specialty_list   // 赋值 专科年级
                        this.specialty_school_lists = school_specialty_list  // 赋值 专科学校列表
                    }else{
                        this.$message({
                            message:res.msg,
                            duration:'2000'
                        })
                    }
                })


            })

        }

        let blog_id = this.blog_id
        let promotion_value = this.promotion_value
        if(promotion_value == '1'){
            blog_common_info({token:token,blog_id:blog_id}).then((res_one) =>{
                console.log(res_one,'帖子详情,普通推广')
                this.onepeoples_max = res_one.blog.max_push_number
                let oneday_onepeoples = this.oneday_onepeoples
                let max_push_number_day = res_one.blog.max_push_number_day
                for(let i=0;i<oneday_onepeoples.length;i++){
                    oneday_onepeoples[i].is = '0'
                    if(oneday_onepeoples[i].text == max_push_number_day){
                        oneday_onepeoples[i].is = '1'
                    }
                }
                this.oneday_onepeoples = oneday_onepeoples
                this.time_one_Value = res_one.blog.push_starttime
                this.time_two_Value = res_one.blog.push_endtime
                this.undergraduate_money = res_one.cost_common.common_push_undergraduate_exposure_price
                this.specialty_money = res_one.cost_common.common_push_specialty_exposure_price
                
                this.Total_money = res_one.cost_common.exposure_total_fee
                let cost_common_school = res_one.cost_common_school
                let grade_ids_undergraduate = res_one.cost_common.grade_undergraduate  // 带入的本科id
                let grade_ids_specialty = res_one.cost_common.grade_specialty  // 带入的专科id
                console.log(grade_ids_undergraduate)
                let bachelor_schools = res_one.cost_common.grade_undergraduate
                let bachelor_dete_id = ''
                for(let i=0;i<bachelor_schools.length;i++){
                    bachelor_dete_id = bachelor_dete_id +','+ bachelor_schools[i].grade_level_text
                }
                this.bachelor_dete_id = JSON.stringify(bachelor_dete_id.substr(1,bachelor_dete_id.length))

                let specialty_schools = res_one.cost_common.grade_specialty
                let specialty_dete_id = ''
                for(let i=0;i<specialty_schools.length;i++){
                    specialty_dete_id = specialty_dete_id +','+ specialty_schools[i].grade_level_text
                }
                this.specialty_dete_id = JSON.stringify(specialty_dete_id.substr(1,specialty_dete_id.length))
                
                let undergraduate_total_money = ''
                let specialty_total_money = ''
                for(let i=0;i<cost_common_school.length;i++){
                    if(cost_common_school[i].school_type_text == 'undergraduate'){
                        undergraduate_total_money = undergraduate_total_money*1 + cost_common_school[i].total_fee*1
                    }
                    if(cost_common_school[i].school_type_text == 'specialty'){
                        specialty_total_money = specialty_total_money*1 + cost_common_school[i].total_fee*1
                    }
                }
                this.undergraduate_total_money = undergraduate_total_money
                this.specialty_total_money = specialty_total_money

                this.Total_money = res_one.cost_common.exposure_total_fee
                
                school_grade_list({is_show_cost_common:'1',city_id:sessionStorage.getItem('city_id_chun')}).then((res)=>{
                    if(res.error_code == '0'){
                        console.log(res,'学校和年级')
                        let grade_undergraduate_list = res.grade_undergraduate  // 本科年级
                        let school_undergraduate_list = res.school_undergraduate   // 本科学校列表

                        let grade_specialty_list = res.grade_specialty  //  专科年级
                        let school_specialty_list = res.school_specialty  // 专科学校列表

                        let bachelor_dete_list = []
                        let specialty_dete_list = []
                        // 本科 年级
                        for(let i=0;i<grade_undergraduate_list.length;i++){
                            grade_undergraduate_list[i].is = '0'
                            for(let k=0;k<grade_ids_undergraduate.length;k++){
                                if(grade_undergraduate_list[i].grade_level_text == grade_ids_undergraduate[k].grade_level_text){
                                    grade_undergraduate_list[i].is = '1'
                                    bachelor_dete_list.push(grade_undergraduate_list[i].title)
                                }
                            }
                        }
                        let specialty_dete_list_one = JSON.stringify(bachelor_dete_list)
                        this.bachelor_dete_list = specialty_dete_list_one.replace(/\[|]|"/g,"")
                        // this.bachelor_dete_list = bachelor_dete_list
                        // 专科 年级
                        for(let i=0;i<grade_specialty_list.length;i++){
                            grade_specialty_list[i].is = '0'
                            for(let k=0;k<grade_ids_specialty.length;k++){
                                if(grade_specialty_list[i].grade_level_text == grade_ids_specialty[k].grade_level_text){
                                    grade_specialty_list[i].is = '1'
                                    specialty_dete_list.push(grade_specialty_list[i].title)
                                }
                            }
                        }
                        let specialty_dete_list_two = JSON.stringify(specialty_dete_list)
                        this.specialty_dete_list = specialty_dete_list_two.replace(/\[|]|"/g,"")
                        // this.specialty_dete_list = specialty_dete_list

                        let left_num = ''
                        for(let i=0;i<school_undergraduate_list.length;i++){
                            school_undergraduate_list[i].input = ''
                            school_undergraduate_list[i].money = ''
                            for(let k=0;k<cost_common_school.length;k++){
                                if(cost_common_school[k].school_type_text == 'undergraduate'){
                                    if(school_undergraduate_list[i].school_id == cost_common_school[k].school_id){
                                        school_undergraduate_list[i].input = cost_common_school[k].exposure_number
                                        left_num = left_num*1 + cost_common_school[k].exposure_number*1
                                        school_undergraduate_list[i].money = cost_common_school[k].total_fee
                                    }
                                }
                            }
                        }
                        let right_num = ''
                        for(let i=0;i<school_specialty_list.length;i++){
                            school_specialty_list[i].input = ''
                            school_specialty_list[i].money = ''
                            for(let k=0;k<cost_common_school.length;k++){
                                if(cost_common_school[k].school_type_text == 'specialty'){
                                    if(school_specialty_list[i].school_id == cost_common_school[k].school_id){
                                        school_specialty_list[i].input = cost_common_school[k].exposure_number
                                        right_num = right_num*1 + cost_common_school[k].exposure_number*1
                                        school_specialty_list[i].money = cost_common_school[k].total_fee
                                    }
                                }
                            }
                        }
                        this.Total_input = right_num*1 + left_num*1
                        this.bachelor_schools = grade_undergraduate_list   // 赋值 本科年级
                        this.undergraduate_school_lists = school_undergraduate_list  // 赋值 本科学校列表
                        this.specialty_schools = grade_specialty_list   // 赋值 专科年级
                        this.specialty_school_lists = school_specialty_list  // 赋值 专科学校列表
                    }else{
                        this.$message({
                            message:res.msg,
                            duration:'2000'
                        })
                    }
                })
            })
        }
        // 配置字段
        configuration_fields({}).then((res)=>{
            console.log(res,'配置字段')
            let business_how_to_charge = res.data.business_how_to_charge    // 如何收费
            let business_how_to_push = res.data.business_how_to_push     // 如何推送
            this.business_how_to_charge = business_how_to_charge
            this.business_how_to_push = business_how_to_push

            
        })
        // 所有学校和年级列表
        if(recruitment_value == '0' || recruitment_value == '0'){
            school_grade_list({is_show_cost_common:'1',city_id:sessionStorage.getItem('city_id_chun')}).then((res)=>{
                if(res.error_code == '0'){
                    console.log(res,'学校和年级')
                    let grade_undergraduate_list = res.grade_undergraduate  // 本科年级
                    let school_undergraduate_list = res.school_undergraduate   // 本科学校列表
                    let grade_specialty_list = res.grade_specialty  //  专科年级
                    let school_specialty_list = res.school_specialty  // 专科学校列表
                    // 本科 年级
                    for(let i=0;i<grade_undergraduate_list.length;i++){
                        grade_undergraduate_list[i].is = '0'
                    }
                    // 专科 年级
                    for(let i=0;i<grade_specialty_list.length;i++){
                        grade_specialty_list[i].is = '0'
                    }

                    for(let i=0;i<school_undergraduate_list.length;i++){
                        school_undergraduate_list[i].input = ''
                        school_undergraduate_list[i].money = ''
                    }
                    for(let i=0;i<school_specialty_list.length;i++){
                        school_specialty_list[i].input = ''
                        school_specialty_list[i].money = ''
                    }
                    this.bachelor_schools = grade_undergraduate_list   // 赋值 本科年级
                    this.undergraduate_school_lists = school_undergraduate_list  // 赋值 本科学校列表
                    this.specialty_schools = grade_specialty_list   // 赋值 专科年级
                    this.specialty_school_lists = school_specialty_list  // 赋值 专科学校列表
                }else{
                    this.$message({
                        message:res.msg,
                        duration:'2000'
                    })
                }
            })
        }
        console.log(this.recruit_date)
        // 所有价格
        if(general_index_index == '1'){
            all_prices({price_type:'push'}).then((res)=>{
                console.log(res,'所有价格')
                let all_prices_list = res.push_price
                for(let i=0;i<all_prices_list.length;i++){
                    if(all_prices_list[i].id == general_list.push_price_id){
                        this.undergraduate_money = all_prices_list[i].common_push_undergraduate_exposure_price  // 赋值 本科单价
                        this.specialty_money = all_prices_list[i].common_push_specialty_exposure_price  // 赋值 本科单价
                    }
                }
            })
        }else if(general_index_index == '2'){
            all_prices({price_type:'student'}).then((res)=>{
                console.log(res,'所有价格')
                let all_prices_list = res.push_price
                for(let i=0;i<all_prices_list.length;i++){
                    if(all_prices_list[i].id == general_list.push_price_id){
                        this.undergraduate_money = all_prices_list[i].common_push_undergraduate_exposure_price  // 赋值 本科单价
                        this.specialty_money = all_prices_list[i].common_push_specialty_exposure_price  // 赋值 本科单价
                    }
                }
            })
        }else{
            all_prices({job_type_ids:this.recruit_date.job_type_id_3,push_type_text:'common',price_type:'recruit'}).then((res)=>{
                console.log(res,'所有价格')
                this.undergraduate_money = res.job_type.common_push_undergraduate_exposure_price  // 赋值 本科单价
                this.specialty_money = res.job_type.common_push_specialty_exposure_price  // 赋值 专科单价
            })
        }

        setInterval(()=>{
            if(this.$route.query.page_up !== undefined){
                this.back_value()
            }
        },220)

    },
    methods: {
        // 如何推送
        tuisong_show(){
          this.pop_masking = '1'
          this.pop_tuisong = '1'
        },
        tuisong_hide(){
          this.pop_masking = '0'
          this.pop_tuisong = '0'
        },

        // 如何收费
        shoufei_show(){
          this.pop_masking = '1'
          this.pop_shoufei = '1'
        },
        shoufei_hide(){
          this.pop_masking = '0'
          this.pop_shoufei= '0'
        },
        // 给同一人一天内最多推送
        oneday_onepeople_dete(index){
            let oneday_onepeoples_list = this.oneday_onepeoples
            for(let i=0;i<oneday_onepeoples_list.length;i++){
                oneday_onepeoples_list[i].is = '0'
                if(oneday_onepeoples_list[i].index == index){
                    oneday_onepeoples_list[i].is = '1'
                    this.oneday_onepeoples_value = oneday_onepeoples_list[i].index
                }
            }
            this.oneday_onepeoples = oneday_onepeoples_list
        },
        // 各个学校用户数量 弹窗 显示
        user_number_click(school_tpye,school_id){
            console.log(school_tpye)
            let undergraduate_school_lists = this.undergraduate_school_lists
            let specialty_school_lists = this.specialty_school_lists

            if(school_tpye == '1'){
                school_grade_user_number({school_id:school_id,school_type_text:'undergraduate',grade_level_texts:this.bachelor_dete_id.replace(/\"/g,"")}).then((res)=>{
                    console.log(res,'根据学校和各个年级，本科')
                    this.user_number_grade_people_num = res.user_number
                    for(let i=0;i<undergraduate_school_lists.length;i++){
                        if(undergraduate_school_lists[i].school_id == school_id){
                            this.school_num_input_val = undergraduate_school_lists[i].input
                            this.user_number_total_price = undergraduate_school_lists[i].money
                        }
                    }
                })
            }
            if(school_tpye == '2'){
                school_grade_user_number({school_id:school_id,school_type_text:'specialty',grade_level_texts:this.specialty_dete_id.replace(/\"/g,"")}).then((res)=>{
                    console.log(res,'根据学校和各个年级，专科')
                    this.user_number_grade_people_num = res.user_number
                    for(let i=0;i<specialty_school_lists.length;i++){
                        if(specialty_school_lists[i].school_id == school_id){
                            this.school_num_input_val = specialty_school_lists[i].input
                            this.user_number_total_price = specialty_school_lists[i].money
                        }
                    }
                })
            }

            this.user_number_is = school_tpye
            this.school_num_id = school_id
            this.comm_shadows_value = '1'
            this.publish_pop_value = '1'

        },
        // 各个学校用户数量 输入框
        school_num_input(){
            let user_number_is = this.user_number_is
            let school_num_input_val = this.school_num_input_val
            let undergraduate_money = this.undergraduate_money
            let specialty_money = this.specialty_money
            if(user_number_is == '1'){
                this.user_number_total_price = (undergraduate_money*school_num_input_val*1).toFixed(2)
            }else if(user_number_is == '2'){
                this.user_number_total_price = (specialty_money*school_num_input_val*1).toFixed(2)
            }
            console.log(school_num_input_val)
        },
        // 各个学校用户数量 弹窗 确定
        publish_yes(){
            let user_number_is = this.user_number_is
            let undergraduate_school_lists = this.undergraduate_school_lists
            let specialty_school_lists = this.specialty_school_lists
            let school_num_id = this.school_num_id
            let school_num_input_val = this.school_num_input_val
            let undergraduate_money = this.undergraduate_money
            let specialty_money = this.specialty_money
            console.log(undergraduate_school_lists)
            if(user_number_is =='1'){
                for(let i=0;i<undergraduate_school_lists.length;i++){
                    if(undergraduate_school_lists[i].school_id == school_num_id){
                        undergraduate_school_lists[i].input = school_num_input_val
                        undergraduate_school_lists[i].money = school_num_input_val*undergraduate_money*1
                    }
                }
                this.undergraduate_school_lists = undergraduate_school_lists
                this.undergraduate_input(school_num_id)
                this.specialty_input()
            }
            if(user_number_is =='2'){
                for(let i=0;i<specialty_school_lists.length;i++){
                    if(specialty_school_lists[i].school_id == school_num_id){
                        specialty_school_lists[i].input = school_num_input_val
                        specialty_school_lists[i].money = school_num_input_val*specialty_money*1
                    }
                }
                this.specialty_school_lists = specialty_school_lists
                this.specialty_input(school_num_id)
                this.undergraduate_input()
            }

            this.school_num_input_val = ''
            this.user_number_total_price = '0'
            this.comm_shadows_value = '0'
            this.publish_pop_value = '0'

        },
        // 各个学校用户数量 弹窗 返回
        publish_no(e){
            this.comm_shadows_value = '0'
            this.publish_pop_value = '0'
            this.school_num_input_val = ''
            this.user_number_total_price = '0'
        },
        onepeoples_max_value(e){
            const { value } = e.target;
            console.log(value);
            this.onepeoples_max = value
        },
        pop_one_block(e){
            this.comm_shadows_value = '1'
            this.pop_one_value = '1'
        },
        pop_one_none(e){
            this.comm_shadows_value = '0'
            this.pop_one_value = '0'
        },
        // 第一个时间选择 弹窗
        pop_time_one_block(e){
            this.comm_shadows_value = '1'
            this.pop_time_one_value = '1'
        },
        pop_time_one_dete(val){
            let year = val.getFullYear()
            let month = val.getMonth() + 1
            let day = val.getDate()
            let hour = val.getHours()
            let minute = val.getMinutes()
            if (month >= 1 && month <= 9) { month = `0${month}` }
            if (day >= 1 && day <= 9) { day = `0${day}` }
            if (hour >= 0 && hour <= 9) { hour = `0${hour}` }
            if (minute >= 0 && minute <= 9) { minute = `0${minute}` }
            let time_one_Value = `${year}-${month}-${day} ${hour}:${minute}`

            let timestamp_two = this.timestamp_two
            let time_one_Value_1 = time_one_Value.substring(0,19);    
            let time_one_Value_2 = time_one_Value_1.replace(/-/g,'/'); 
            let timestamp_one = new Date(time_one_Value_2).getTime();
            if(timestamp_two == ''){

            }else{
                if(timestamp_one > timestamp_two){
                    this.$message({
                        message:'不能大于最大时间',
                        duration:'2000'
                    })
                    return
                }
            }
            this.timestamp_one = timestamp_one

            this.time_one_Value = time_one_Value
            this.comm_shadows_value = '0'
            this.pop_time_one_value = '0'
            console.log(this.time_one_Value)
        },
        pop_time_one_cancel(e){
            this.comm_shadows_value = '0'
            this.pop_time_one_value = '0'
        },
        // 第二个时间选择 弹窗
        pop_time_two_block(e){
            this.comm_shadows_value = '1'
            this.pop_time_two_value = '1'
        },
        pop_time_two_dete(val){
            let year = val.getFullYear()
            let month = val.getMonth() + 1
            let day = val.getDate()
            let hour = val.getHours()
            let minute = val.getMinutes()
            if (month >= 1 && month <= 9) { month = `0${month}` }
            if (day >= 1 && day <= 9) { day = `0${day}` }
            if (hour >= 0 && hour <= 9) { hour = `0${hour}` }
            if (minute >= 0 && minute <= 9) { minute = `0${minute}` }
            let time_two_Value = `${year}-${month}-${day} ${hour}:${minute}`

            let timestamp_one = this.timestamp_one
            let time_one_Value_1 = time_two_Value.substring(0,19);    
            let time_one_Value_2 = time_one_Value_1.replace(/-/g,'/'); 
            let timestamp_two = new Date(time_one_Value_2).getTime();
            if(timestamp_one == ''){

            }else{
                if(timestamp_one > timestamp_two){
                    this.$message({
                        message:'不能小于最小时间',
                        duration:'2000'
                    })
                    return
                }
            }
            this.timestamp_two = timestamp_two

            this.time_two_Value = time_two_Value
            this.comm_shadows_value = '0'
            this.pop_time_two_value = '0'
            console.log(this.time_two_Value)
        },
        pop_time_two_cancel(e){
            this.comm_shadows_value = '0'
            this.pop_time_two_value = '0'
        },
        // 本科年级 选择
        bachelor_dete(grade_id){
            let bachelor_schools_list = this.bachelor_schools
            for(let i=0;i<bachelor_schools_list.length;i++){
                if(bachelor_schools_list[i].grade_level_text == grade_id){
                    if(bachelor_schools_list[i].is == '0'){
                        bachelor_schools_list[i].is = '1'
                    }else{
                        bachelor_schools_list[i].is = '0'
                    }
                }
            }
            this.bachelor_schools = bachelor_schools_list
        },
        // 本科 弹窗 显示
        bachelor_show(e){
            this.comm_shadows_value = '1'
            this.bachelor_school_value = '1'
        },
        // 本科 弹窗 隐藏
        bachelor_hide(e){
            this.comm_shadows_value = '0'
            this.bachelor_school_value = '0'
        },

        // 专科年级 选择
        specialty_dete(grade_id){
            let specialty_schools_list = this.specialty_schools
            for(let i=0;i<specialty_schools_list.length;i++){
                if(specialty_schools_list[i].grade_level_text == grade_id){
                    if(specialty_schools_list[i].is == '0'){
                        specialty_schools_list[i].is = '1'
                    }else{
                        specialty_schools_list[i].is = '0'
                    }
                }
            }
            this.specialty_schools = specialty_schools_list
        },
        // 专科年级 确定
        specialty_determine(){
            let specialty_schools = this.specialty_schools
            let specialty_dete_list = []
            // let specialty_dete_id = []
            let specialty_dete_id = ''
            for(let i=0;i<specialty_schools.length;i++){
                if(specialty_schools[i].is == '1'){
                    specialty_dete_list.push(specialty_schools[i].title)
                    // specialty_dete_id.push(specialty_schools[i].grade_id)
                    specialty_dete_id = specialty_dete_id +','+ specialty_schools[i].grade_level_text
                }
            }
            // this.specialty_dete_list = JSON.stringify(specialty_dete_list)
            let specialty_dete_list_one = JSON.stringify(specialty_dete_list)
            this.specialty_dete_list = specialty_dete_list_one.replace(/\[|]|"/g,"")

            this.specialty_dete_id = JSON.stringify(specialty_dete_id.substr(1,specialty_dete_id.length))
            console.log(this.specialty_dete_list,this.specialty_dete_id)
            this.comm_shadows_value = '0'
            this.specialty_school_value = '0'
        }, 
        
        // 专科 弹窗 显示
        specialty_show(e){
            this.comm_shadows_value = '1'
            this.specialty_school_value = '1'
        },
         // 专科 弹窗 隐藏
        specialty_hide(e){
            this.comm_shadows_value = '0'
            this.specialty_school_value = '0'
        },
        // 充值
        showDialog(visible) {
            this.dialog_visible = visible;
        },
        // 发布 弹窗
        publish_pop(e){
            let Total_money = this.Total_money  // 总价
            let principal_amount = this.principal_amount  // 本金金额
            console.log(principal_amount)
            if(principal_amount*1 < Total_money*1){
                this.comm_shadows_value = '1'
                this.balance_not_is = '1'
                return
            }
            if(Total_money == '0'){
                this.$message({
                    message:'至少选择一所学校！',
                    duration:'2000'
                })
                return
            }
            let general_list = this.general_list
            let token = this.token
            let blog_id = this.blog_id  // 发帖时为0
            let content = general_list.content  // 帖子内容
            let city_id = this.city_id_chun   // 城市id
            let max_push_number = this.onepeoples_max  // 单个用户的最大推送次数
            let max_push_number_day = this.oneday_onepeoples_value   // 单个用户每日的最大推送次数
            let grade_ids_undergraduate = this.bachelor_dete_id // 本科年级id
            let grade_ids_specialty = this.specialty_dete_id         // 专科年级id
            let push_starttime = this.time_one_Value                 // 推送开始时间
            let push_endtime = this.time_two_Value                   // 推送开始时间
 
            if(push_starttime && push_endtime == '请选择时间'){
                this.$message({
                    message:'请选择时间',
                    duration:'2000'
                })
                return
            }
            let school_list = []
            let undergraduate_school_lists = this.undergraduate_school_lists
            let specialty_school_lists = this.specialty_school_lists
            // console.log(undergraduate_school_lists,specialty_school_lists,'1111``````',grade_ids_undergraduate)
            // return
            for(let i=0;i<undergraduate_school_lists.length;i++){
                if(undergraduate_school_lists[i].input !== ''){
                    let undergraduate_list = ''
                    undergraduate_list = {school_id:undergraduate_school_lists[i].school_id,exposure_number:undergraduate_school_lists[i].input,school_type_text:'undergraduate'}
                    school_list.push(undergraduate_list)
                }
            }
            for(let i=0;i<specialty_school_lists.length;i++){
                if(specialty_school_lists[i].input !== ''){
                    let specialty_list = ''
                    specialty_list = {school_id:specialty_school_lists[i].school_id,exposure_number:specialty_school_lists[i].input,school_type_text:'specialty'}
                    school_list.push(specialty_list)
                }
            }

            let school_json = school_list   // 学校信息数组

            let price_type_chuan_chuan = this.general_index_index
            if(price_type_chuan_chuan == '1'){
                price_type_chuan = 'push'
                this.price_type_chuan = 'push'
            }else if(price_type_chuan_chuan == '2'){
                price_type_chuan = 'student'
                this.price_type_chuan = 'student'
            }
            let price_type_chuan = this.price_type_chuan
            let price_type = price_type_chuan   // 推送内容类别 传
            let push_price_id = general_list.push_price_id // 推送内容类别id
            let longitude = general_list.longitude     // 经度
            let latitude = general_list.latitude       // 纬度
            let is_allow_comment = general_list.is_allow_comment  // 是否允许评论
            let vote_type_text = general_list.vote_type_text  // 投票类型

            let vote_title = general_list.vote_title   // 投票选项标题
            console.log(vote_title,'1111111````')
            let vote_title_list = ''
            for(let i=0;i<vote_title.length;i++){
                vote_title_list = vote_title_list +','+ vote_title[i]
            }
            vote_title = vote_title_list.substr(1,vote_title_list.length)  // 传

            let gallery = general_list.gallery  // 多图
            let img_list = ''
            for(let i=0;i<gallery.length;i++){
                img_list = img_list +','+ gallery[i]
            }
            gallery = img_list.substr(1,img_list.length)  // 传
            let bj_news = this.bj_news
            if(bj_news == '1'){
                edit_blog_common({token:token,blog_id:'0',content:content,city_id:city_id,max_push_number:max_push_number,max_push_number_day:max_push_number_day,grade_level_texts_undergraduate:grade_ids_undergraduate.substring(1,grade_ids_undergraduate.length-1),grade_level_texts_specialty:grade_ids_specialty.substring(1,grade_ids_specialty.length-1),push_starttime:push_starttime,push_endtime:push_endtime,school_json:JSON.stringify(school_json),price_type:price_type,push_price_id:push_price_id,longitude:longitude,latitude:latitude,is_allow_comment:is_allow_comment,vote_type_text:vote_type_text,vote_title:vote_title,gallery:gallery}).then((res)=>{
                    if(res.error_code == '0'){
                        console.log(res)
                        this.comm_shadows_value = '1'
                        this.submit_review_is = '1'
                        this.back_award_id = res.blog_id
                    }else if(res.error_code == '1'){
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        }) 
                    }
                }) 
            }else{
                edit_blog_common({token:token,blog_id:blog_id,content:content,city_id:city_id,max_push_number:max_push_number,max_push_number_day:max_push_number_day,grade_level_texts_undergraduate:grade_ids_undergraduate.substring(1,grade_ids_undergraduate.length-1),grade_level_texts_specialty:grade_ids_specialty.substring(1,grade_ids_specialty.length-1),push_starttime:push_starttime,push_endtime:push_endtime,school_json:JSON.stringify(school_json),price_type:price_type,push_price_id:push_price_id,longitude:longitude,latitude:latitude,is_allow_comment:is_allow_comment,vote_type_text:vote_type_text,vote_title:vote_title,gallery:gallery}).then((res)=>{
                    if(res.error_code == '0'){
                        console.log(res)
                        this.comm_shadows_value = '1'
                        this.submit_review_is = '1'
                        this.back_award_id = res.blog_id
                    }else if(res.error_code == '1'){
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        }) 
                    }
                })
            }
        },
        // 提交审核 返回
        submit_review_back(){
            this.comm_shadows_value = '0'
            this.submit_review_is = '0'
            var that = this;
            setTimeout(function () {
                that.$router.push({path:'/'}) 
            },1000);
        },
        // 提交审核 确定
        submit_review_recharge(){
            let back_award_id = this.back_award_id
            let token = this.token
            submit_check_blog_common({token,blog_id:back_award_id}).then((res) =>{
                console.log(res,'提交审核')
                if(res.error_code == '0'){
                    this.$message({
                        message:res.msg,
                        duration:'2000'
                    })
                    var that = this;
                    setTimeout(function () {
                        that.$router.push({path:'/'}) 
                    },1000);
                }else{
                    this.$message({
                        message:res.msg,
                        duration:'2000'
                    })
                }
            })
        },
        // 本科年级 确定
        bachelor_determine(){
            let bachelor_schools = this.bachelor_schools
            let bachelor_dete_list = []
            // let bachelor_dete_id = []
            let bachelor_dete_id = ''
            for(let i=0;i<bachelor_schools.length;i++){ 
                if(bachelor_schools[i].is == '1'){
                    bachelor_dete_list.push(bachelor_schools[i].title)
                    // bachelor_dete_id.push(bachelor_schools[i].grade_id)
                    bachelor_dete_id = bachelor_dete_id +','+ bachelor_schools[i].grade_level_text
                }
            }
            // this.bachelor_dete_list = JSON.stringify(bachelor_dete_list)
            let bachelor_dete_list_one = JSON.stringify(bachelor_dete_list)
            this.bachelor_dete_list = bachelor_dete_list_one.replace(/\[|]|"/g,"")

            this.bachelor_dete_id = JSON.stringify(bachelor_dete_id.substr(1,bachelor_dete_id.length))
            console.log(this.bachelor_dete_list,this.bachelor_dete_id)
            this.comm_shadows_value = '0'
            this.bachelor_school_value = '0'
        },
        // 本科
        undergraduate_input(school_id){
            console.log(school_id)
            console.log(this.undergraduate_school_lists)
            let undergraduate_money = this.undergraduate_money               // 本科 单个总价
            let undergraduate_school_lists = this.undergraduate_school_lists // 本科 学校列表
            let undergraduate_total_input = ''   // 本科 总曝光量
            let undergraduate_total_money = ''   // 本科 预算总价

            let specialty_money = this.specialty_money               // 专科 单个总价
            let specialty_total_input = this.specialty_total_input   // 专科 总曝光量
            console.log(specialty_total_input,'专科 总曝光量')
            let specialty_total_money = this.specialty_total_money  // 专科 预算总价
            let Total_input = ''    // 总曝光量
            let Total_money = ''    // 预算总价
   
            for(let i=0;i<undergraduate_school_lists.length;i++){
                if(undergraduate_school_lists[i].school_id == school_id){
                    undergraduate_school_lists[i].money = (undergraduate_money*undergraduate_school_lists[i].input).toFixed(2)
                }
            }
            for(let i=0;i<undergraduate_school_lists.length;i++){
                undergraduate_total_input = undergraduate_total_input*1 +  undergraduate_school_lists[i].input*1
            }
            undergraduate_total_money = ((undergraduate_money*1)*undergraduate_total_input).toFixed(2)
            this.undergraduate_total_money = undergraduate_total_money
            this.undergraduate_total_input = undergraduate_total_input
            this.Total_input = undergraduate_total_input*1 + specialty_total_input*1
            this.Total_money = (specialty_total_money*1 + undergraduate_total_money*1).toFixed(2)
            this.undergraduate_school_lists = undergraduate_school_lists
            let principal_amount = this.principal_amount
            console.log(principal_amount*1,this.Total_money*1,specialty_total_money,undergraduate_total_money)
            if(principal_amount*1 < this.Total_money*1){
                this.price_comparison = '1'
            }else{
                this.price_comparison = '0'
            }
        },
        // 专科
        specialty_input(school_id){
            console.log(school_id)
            console.log(this.specialty_school_lists)
            let specialty_money = this.specialty_money               // 专科 单个总价
            let specialty_school_lists = this.specialty_school_lists // 专科 学校列表
            let specialty_total_input = ''   // 专科 总曝光量
            let specialty_total_money = ''   // 专科 预算总价

            let undergraduate_money = this.undergraduate_money               // 本科 单个总价
            let undergraduate_total_input = this.undergraduate_total_input   // 本科 总曝光量
            let undergraduate_total_money = this.undergraduate_total_money  // 本科 预算总价
            let Total_input = ''    // 总曝光量
            let Total_money = ''    // 预算总价
            for(let i=0;i<specialty_school_lists.length;i++){
                if(specialty_school_lists[i].school_id == school_id){
                    specialty_school_lists[i].money = (specialty_money*specialty_school_lists[i].input).toFixed(2)
                }
            }
            for(let i=0;i<specialty_school_lists.length;i++){
                specialty_total_input = specialty_total_input*1 +  specialty_school_lists[i].input*1
            }
            specialty_total_money = ((specialty_money*1)*specialty_total_input).toFixed(2)
            this.specialty_total_money = specialty_total_money
            this.specialty_total_input = specialty_total_input
            this.Total_input = specialty_total_input*1 + undergraduate_total_input*1
            this.Total_money = (undergraduate_total_money*1 + specialty_total_money*1).toFixed(2)
            this.specialty_school_lists = specialty_school_lists

            let principal_amount = this.principal_amount
            if(principal_amount*1 < this.Total_money*1){
                this.price_comparison = '1'
            }else{
                this.price_comparison = '0'
            }
        },
        
        
        // 返回上一级的传值
        back_value(){
            let recruit_date = this.recruit_date  // 存值
            let max_push_number = this.onepeoples_max // 单个用户的最大推送次数
            let max_push_number_day = this.oneday_onepeoples_value  // 单个用户每日的最大推送次数
            let push_starttime = this.time_one_Value   // 推送开始时间
            let push_endtime = this.time_two_Value  // 推送结束时间
            // if(push_starttime && push_endtime == '请选择时间'){
            //     this.$message({
            //         message:'请选择时间',
            //         duration:'2000'
            //     })
            //     return
            // }
            let grade_ids_undergraduate = this.bachelor_dete_id // 本科年级id
            let grade_ids_specialty = this.specialty_dete_id    // 专科年级id
            let recruitment_value = this.recruitment_value
            if(recruitment_value !== '1'){
                if(grade_ids_undergraduate.substring(1,grade_ids_undergraduate.length-1) == '' && grade_ids_specialty.substring(1,grade_ids_specialty.length-1) ==''){
                    this.$message({
                        message:'至少选择一个年级！',
                        duration:'2000'
                    })
                    return
                }
            }
            let school_list = []
            let undergraduate_school_lists = this.undergraduate_school_lists
            let specialty_school_lists = this.specialty_school_lists
            for(let i=0;i<undergraduate_school_lists.length;i++){
                if(undergraduate_school_lists[i].input !== ''){
                    let undergraduate_list = ''
                    undergraduate_list = {school_id:undergraduate_school_lists[i].school_id,exposure_number:undergraduate_school_lists[i].input,school_type_text:'undergraduate'}
                    school_list.push(undergraduate_list)
                }
            }
            for(let i=0;i<specialty_school_lists.length;i++){
                if(specialty_school_lists[i].input !== ''){
                    let specialty_list = ''
                    specialty_list = {school_id:specialty_school_lists[i].school_id,exposure_number:specialty_school_lists[i].input,school_type_text:'specialty'}
                    school_list.push(specialty_list)
                }
            }

            let school_json = school_list   // 学校信息数组

            recruit_date.max_push_number = max_push_number
            recruit_date.max_push_number_day = max_push_number_day
            recruit_date.push_starttime = push_starttime
            recruit_date.push_endtime = push_endtime
            recruit_date.grade_ids_undergraduate = grade_ids_undergraduate
            recruit_date.grade_ids_specialty = grade_ids_specialty
            recruit_date.school_json = school_json
            
            let dd = JSON.stringify(recruit_date)
            sessionStorage.setItem("recruit_date_key",dd)

            // 单存总预算
            let Total_money = this.Total_money
            sessionStorage.setItem("Total_money",Total_money)

            console.log(school_list,recruit_date)

            let recruit_id = this.recruit_id

            if( recruitment_value == '0'){
                let xxl_select = this.xxl_select
                if(xxl_select == ''){
                    this.$router.push({path:'/recruit_index_two'})
                }else{
                    this.$router.push({
                        path:'/recruit_index_two',
                        query: {
                            xxl_select:xxl_select,
                        }
                    })
                }
                

            }else if(recruitment_value == '1'){
                let bj_news = this.bj_news
                if(bj_news == '1'){
                    this.$router.push({
                        path:'/recruit_index_two',
                        query: {
                            recruit_id: recruit_id,
                            recruitment_value:'1',
                            bj_news:'1'
                        }
                    })
                }else{
                    this.$router.push({
                        path:'/recruit_index_two',
                        query: {
                            recruit_id: recruit_id,
                            recruitment_value:'1'
                        }
                    })
                }
            }
            
        },
        // 返回上一级的传值
        back_value_two(){
            this.$router.go(-1)
        },
        // 余额不足弹出 返回
        balance_not_back(){
            this.comm_shadows_value = '0'
            this.balance_not_is = '0'
        },  
        // 余额不足弹出 充值
        balance_not_recharge(visible){
            this.dialog_visible = visible;
            this.comm_shadows_value = '0'
            this.balance_not_is = '0'
        }, 
    },
    
    setup() {
        const currentDate = ref(new Date());
        return {
          minDate: new Date(),
          maxDate: new Date(2035, 11, 1),
          currentDate,
        };
    },
}
</script>