<template>
    <div class="y_sweep_settings_lxr_1">
        <div class="y_sweep_settings_lxr_2">
            <router-link to='/sweepstakes_settings_lxr_two' class="y_sweep_settings_lxr_3">
                <img src="../../../images/icon_33.png" alt="">
                <p>添加新设置</p>
            </router-link>
            <div class="y_sweep_settings_lxr_4">
                <div class="y_sweep_settings_lxr_9" v-for="(contact_list,index) in contact_lists" :key="index">
                    <div class="y_sweep_settings_lxr_10">
                        <div class="y_sweep_settings_lxr_11">
                            <h2>{{ contact_list.school.title }}</h2>
                        </div>
                        <div class="y_sweep_settings_lxr_12">
                            <p>领奖联系↓</p>
                        </div>
                    </div>

                    <div class="y_sweep_settings_lxr_5">
                        <!-- <div class="y_sweep_settings_lxr_6" @click="contact_select_one(contact_list.award_contact.id,contact_list.award_contact.phone)"> -->
                        <div class="y_sweep_settings_lxr_6">
                            <img src="../../../images/icon_41.png" alt="">
                            <h2>{{contact_list.award_contact.phone}}</h2>
                            <div class="y_sweep_settings_lxr_8">
                                <img :src="''+contact_list.award_contact.qrcode_img" alt="">
                            </div>
                        </div>
                        <div class="y_sweep_settings_lxr_7" @click="bianji_lxr_block(contact_list.award_contact.id)">
                            <img src="../../../images/icon_40.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- 编辑 or 删除 弹窗 -->
    <div class="y_sweep_lxr_pop_1" :class="delete_pop == '0'?'y_display_none':'y_sweep_lxr_pop_1_active'">
        <div class="y_sweep_lxr_pop_2" @click="bianji_lxr_none()">
            <img src="../../../images/icon_17.png" alt="">
        </div>
        <div class="y_sweep_lxr_pop_3">
            <h2 @click="contact_editor">编辑</h2>
            <h2 @click="contact_dele">删除</h2>
        </div>
    </div>
    <div class="y_sweep_settings_lxr_two_7"></div>
    <div class="y_sweep_settings_lxr_two_8" @click="contact_save">
        <h2>返回抽奖</h2>
    </div>

    <!-- 公共 阴影 -->
    <div class='y_recruit_two_shade' :class="shade_value == '0'?'':'y_recruit_two_shade_block'"></div>
</template>

<style scoped>
    .y_sweep_settings_lxr_1{
        width: 100%;
        min-height: 100vh;
        background: #F4F5F7;
    }
    .y_sweep_settings_lxr_2{
        padding: 0.3rem;
    }
    .y_sweep_settings_lxr_3{
        width: 100%;
        height: 1.4rem;
        background: #fff;
        border-radius: 0.1rem;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.2rem;
    }
    .y_sweep_settings_lxr_3 img{
        width: 0.3rem;
        height: 0.3rem;
        margin-right: 0.2rem;
    }
    .y_sweep_settings_lxr_3 p{
        color: #FECE0A;
        font-size: 0.3rem;
    }
    .y_sweep_settings_lxr_4{
        width: 100%;
    }
    .y_sweep_settings_lxr_5{
        background: #FFFBED;
        padding: 0rem 0.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1rem;
        /* margin-bottom: 0.2rem; */
        border-radius: 0.1rem;
        border: 0.01rem solid #FECE0A;
        overflow: hidden;
    }
    .y_sweep_settings_lxr_6{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }
    .y_sweep_settings_lxr_6>img{
        width: 0.3rem;
        margin-right: 0.25rem;
    }
    .y_sweep_settings_lxr_6  h2{
        font-size: 0.3rem;
        color: #333;
        min-width: 4rem;
    }
    .y_sweep_settings_lxr_7{
        width: 1rem;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .y_sweep_settings_lxr_7 img{
        width: 0.35rem;
    }
    .y_sweep_settings_lxr_8{
        width: 0.8rem;
        height: 0.8rem;
    }
    .y_sweep_settings_lxr_8 img{
        width: 0.8rem;
        height: 0.8rem;
        object-fit: cover;
    }
    .y_sweep_settings_lxr_9{
        width: calc(100% - 0.4rem);
        background: #fff;
        border-radius: 0.1rem;
        padding: 0rem 0.2rem 0.3rem;
        margin-bottom: 0.2rem;
    }
    .y_sweep_settings_lxr_10{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0.9rem;
    }
    .y_sweep_settings_lxr_11{
        flex: 1;
    }
    .y_sweep_settings_lxr_11 h2{
        font-size: 0.32rem;
        color: #333333;
        font-weight: 600;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
    .y_sweep_settings_lxr_12 p{
        color: #999999;
        font-size: 0.3rem;
    }
    /* 编辑 删除 弹窗 */
    .y_sweep_lxr_pop_1{
        width: 100%;
        background: #fff;
        height: 2.4rem;
        position: fixed;
        bottom: 0rem;
        left: 0rem;
        z-index: 20;
        border-top-left-radius: 0.3rem;
        border-top-right-radius: 0.3rem;
        overflow: hidden;
    }
    .y_sweep_lxr_pop_1_active{
        display: block;
    }
    .y_sweep_lxr_pop_2{
        position: absolute;
        top: 0rem;
        right: 0rem;
        width: 0.8rem;
        height: 0.8rem;
    }
    .y_sweep_lxr_pop_2 img{
        width: 100%;
    }
    .y_sweep_lxr_pop_3{
        width: 100%;
    }
    .y_sweep_lxr_pop_3 h2{
        width: 100%;
        line-height: 1.2rem;
        text-align: center;
        font-size: 0.3rem;
        color: #333;
        border-bottom: 0.01rem solid #EEEEEE;
    }
    .y_sweep_lxr_pop_3 h2:nth-last-child(1){
        border: none;
    }
    .y_sweep_settings_lxr_two_7{
        width: 100%;
        height: 1.5rem;
        background: transparent;
    }
    .y_sweep_settings_lxr_two_8{
        width: 100%;
        position: fixed;
        bottom: 0rem;
        left: 0rem;
        height: 1.3rem;
        background: #fff;
        display: flex;
        align-items: center;
    }
    .y_sweep_settings_lxr_two_8 h2{
        max-width: 6.9rem;
        width: 100%;
        height: 0.9rem;
        background: #FECE0A;
        color: #333;
        font-size: 0.32rem;
        text-align: center;
        line-height: 0.9rem;
        margin: 0rem auto;
        border-radius: 0.1rem;
    }
</style>

<script>
import { Dialog } from "vant";
import axios from 'axios'   // 接口
import {award_contact_list} from '../../api/request.js' // 联系人列表
import {delete_award_contact} from '../../api/request.js' // 删除联系人
export default {
        data(){
            return {
                shade_value:'0',  // 公共 阴影
                delete_pop:'0',
                contact_lists:'',  // 联系人列表
                contact_list_id:'',  // 联系人列表 id
                contact_jump_judgment:'',  // 0为 领奖联系方式入口 1为富文本入口
                contact_jump_index:'', //  富文本 index

                adspace_id:'0',       // 广告位列表，高级推广 进入传id
                promotion_value:'0',  // 进入传value  0代表正常进入，1为待推送进入
            }
        },
        components:{
            
        },
        created(){
            // 获取页面传参 推送类型
            this.contact_jump_judgment = this.$route.query.contact_jump_judgment
            this.contact_jump_index = this.$route.query.contact_jump_index
        
            if(this.$route.query.adspace_id !== undefined){
                this.adspace_id = this.$route.query.adspace_id
                this.promotion_value = this.$route.query.promotion_value
            }
        },
        watch:{
            '$route'(val,from){
                console.log(val,'sweepstakes_settings_lxr')
                this.contact_jump_judgment = val.query.contact_jump_judgment
                this.contact_jump_index = val.query.contact_jump_index
            }
        },
        // 进入页面开始执行
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            // 联系人列表
            award_contact_list({token:token,page:'1',}).then((res) =>{
                console.log(res,'联系人列表')
                this.contact_lists = res.data
            })
        },
        methods: {
            // 编辑 or 删除  显示
            bianji_lxr_block(id){
                this.shade_value = '1'
                this.delete_pop = '1'
                this.contact_list_id = id
            },
            // 编辑 or 删除  关闭
            bianji_lxr_none(){
                this.shade_value = '0'
                this.delete_pop = '0'
            },
            // 联系人编辑
            contact_editor(){
                let contact_list_id = this.contact_list_id
                this.shade_value = '0'
                this.delete_pop = '0'
                this.$router.push({
                    path:'/sweepstakes_settings_lxr_two',
                    query: {
                        id: contact_list_id,
                    }
                })
            },
            // 联系人删除
            contact_dele(){
                let token = this.token
                let contact_list_id = this.contact_list_id
                delete_award_contact({token:token,award_contact_id:contact_list_id}).then((res) =>{
                    if(res.error_code == '0'){
                        this.$message({
                            message:'删除成功',
                            duration:'2000'
                        })
                        award_contact_list({token:token,page:'1'}).then((res) =>{
                            this.contact_lists = res.data
                        })
                        this.shade_value = '0'
                        this.delete_pop = '0'
                        console.log(res,'联系人删除')
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }

                })
            },
            // 返回抽奖页面 
            contact_save(){
                var that = this;
                setTimeout(function () {
                    that.$router.go(-1)
                },1000);
            },
            contact_select_one(id,phone){
                console.log(id,phone)
                let contact_jump_judgment = this.contact_jump_judgment
                let contact_jump_index = this.contact_jump_index

                let adspace_id = this.adspace_id
                let promotion_value = this.promotion_value
                if(promotion_value == '1'){
                    if( contact_jump_judgment == '0'){
                    Dialog.confirm({
                        message:'确定选中？',
                    })
                    .then(() => {
                        this.$router.push({
                            path:'/sweepstakes_settings',
                            query: {
                                contact_list_phone:phone,
                                contact_list_id:id,
                                contact_list_phone_one:'0',
                                adspace_id:adspace_id,
                                promotion_value:promotion_value
                            }
                        })
                    })
                    .catch(() => {

                    })
                }else if(contact_jump_judgment == '1'){
                    Dialog.confirm({
                        message:'确定选中？',
                    })
                    .then(() => {
                        this.$router.push({
                            path:'/richtext',
                            query: {
                                contact_list_phone:phone,
                                contact_jump_index:contact_jump_index,
                                contact_list_id:id,
                                
                            }
                        })
                    })
                    .catch(() => {

                    })
                }
                }else{
                    if( contact_jump_judgment == '0'){
                    Dialog.confirm({
                        message:'确定选中？',
                    })
                    .then(() => {
                        this.$router.push({
                            path:'/sweepstakes_settings',
                            query: {
                                contact_list_phone:phone,
                                contact_list_id:id,
                                contact_list_phone_one:'0',
                            }
                        })
                    })
                    .catch(() => {

                    })
                }else if(contact_jump_judgment == '1'){
                    Dialog.confirm({
                        message:'确定选中？',
                    })
                    .then(() => {
                        this.$router.push({
                            path:'/richtext',
                            query: {
                                contact_list_phone:phone,
                                contact_jump_index:contact_jump_index,
                                contact_list_id:id,
                                
                            }
                        })
                    })
                    .catch(() => {

                    })
                }
                }
                
                
            },
        },
    }
</script>