<template>
    <div class="y_index">
        <!-- <div class='y_index_1'>
            <router-link class='y_index_2' to='/'>关于喵君</router-link>
            <router-link class='y_index_2' to='/'>使用攻略</router-link>
        </div> -->
        <push_charges></push_charges>
        <div class="y_recruit_1">
          <img src="../../../images/img_3.png" alt="">
        </div>

        <div class="y_advanced_push_three_1">
            <h2>上传横幅封面图片</h2>
            <p>（图片尺寸要求750*200px）</p>
        </div>
        <div class="y_advanced_push_three_2">
            <div class="y_advanced_push_three_xg_1">
                <el-upload class="avatar-uploader" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :show-file-list="false" :on-success="banner_success" :before-upload="banner_settings">
                    <img src="../../../images/img_8.png" v-if="img == ''? true : false " alt="">
                    <img :src="''+img"  v-if="img == ''? false:true"  alt="">
                </el-upload>
            </div>
        </div>
        
        <div class="y_external_select_xg_1">
            <div class="y_external_select_xg_2">
                <h2 class="y_external_select_xg_3">跳转方式</h2>
                <div class="y_external_select_xg_4">
                    <el-select v-model="external_value" @change='external_select' placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
        </div>

        <div class="y_advanced_push_three_5" :class="external_value == 'detail'?'':'y_display_none'">
            <div class="y_advanced_push_three_1">
                <h2>推送内容编辑</h2>
            </div>
            <div class="y_advanced_push_three_6">
                <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea"></el-input>
            </div>
            <div class="y_advanced_push_three_7">
                <div class='y_recruit_13'>
                    <el-upload class="upload-demo" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :on-preview="handlePreview" :on-success="hand_success" :on-remove="handleRemove" :file-list="fileList" list-type="picture">
                        <div class='y_recruit_14'>
                            <img src="../../../images/icon_14.png" alt="">
                        </div>
                    </el-upload>
                </div>
            </div>
        </div>
        <div class="y_external_select_xg_5" :class="external_value == 'url'?'':'y_display_none'">
            <div class="y_advanced_push_three_5">
                <div class="y_advanced_push_three_1">
                    <h2>跳转链接</h2>
                </div>
                <div class="y_external_select_xg_6">
                    <el-input v-model="url" placeholder="请输入链接"></el-input>
                </div>
            </div>
        </div>
       

        
        <!-- 底部垫高 -->
        <div class='y_recruit_two_dian'></div>
        <div class='y_recruit_two_foot_1'>
            <div class='y_recruit_two_foot_2'>
                <div class='y_recruit_two_foot_4'>
                    <p class='y_recruit_two_foot_5' @click="pop_step">上一步</p>
                    <!-- <div class="y_advanced_push_three_9">
                        <img src="../../../images/icon_15.png" alt="">
                        <h3>预览</h3>
                    </div> -->
                    <p class='y_recruit_two_foot_6' @click="pop_up">发布</p>
                </div>
            </div>
        </div>
    </div>

    <kefu></kefu>
</template>
<style scoped>
    .y_advanced_push_three_1{
        width: 100%;
        height: 1.05rem;
        background: #fff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_advanced_push_three_1 h2{
        font-size: 0.28rem;
        color: #333;
        padding-left: 0.3rem;
        font-weight: 600;
    }
    .y_advanced_push_three_1 p{
        color: #999;
        font-size: 0.28rem;
    }
    .y_advanced_push_three_2{
        width: 100%;
        background: #f6f6f6;
        height: 2rem;
        
    }
    .y_advanced_push_three_3{
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
    }
    .y_advanced_push_three_3 p{
        width: 100%;
        text-align: center;
        font-size: 0.28rem;
        color: #999;
        margin-top: 0.15rem;
    }
    .y_advanced_push_three_4{
        width: 100%;
        height: 0.76rem;
        text-align: center;
    }
    .y_advanced_push_three_4 img{
        height: 100%;
        object-fit: cover;
    }
    .y_advanced_push_three_5{
        width: 100%;
        background: #fff;
        padding-bottom: 0.4rem;
    }
    .y_advanced_push_three_6{
        margin: 0rem 0.35rem;
    }
    .y_advanced_push_three_6 /deep/ textarea{
        padding: 0.3rem 0.2rem;
        background: #F7F8FA;
        border-radius: 0.1rem;
        height: 2.6rem;
        font-size: 0.28rem;
        color: #333;
        border: none;
    }
    .y_advanced_push_three_6 /deep/ textarea::placeholder{
        color: #ccc;
        font-size: 0.28rem;
    }
    .y_advanced_push_three_7{
        margin: 0.44rem 0.35rem 0rem;
    }
    .y_advanced_push_three_8{
        width: 100%;
    }
    .y_advanced_push_three_8 img{
        width: 2.2rem;
        height: 2.2rem;
    }
    .y_advanced_push_three_9{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }
    .y_advanced_push_three_9 img{
        width: 0.34rem;
        height: 0.26rem;
    }
    .y_advanced_push_three_9 h3{
        margin-left: 0.05rem;
        color: #FECE0A;
        font-size: 0.3rem;
    }


    /* 修改的公共样式 */
    .y_recruit_two_dian{
        height: 1.2rem;
    }
    .y_recruit_two_foot_1{
        height: 1.2rem;
    }
    .y_recruit_two_foot_4{
        margin-top: 0rem;
    }
    .y_recruit_two_foot_2 .y_recruit_two_foot_4{
        justify-content: space-between;
        width: 100%;
    }
    .y_recruit_two_foot_4 .y_recruit_two_foot_5{
        flex: none;
        width: 1.8rem;
        margin-left: 0.25rem;
    }
    .y_recruit_two_foot_4 .y_recruit_two_foot_6{
        width: 3rem;
        flex: none;
        margin-right: 0.25rem;
    }

    /* 修改 */
    .y_advanced_push_three_xg_1{
        width: 100%;
        height: 2rem;
        overflow: hidden;
    }
    .y_advanced_push_three_xg_1 img{
        /* width: 100%; */
        height: 100%;
        object-fit: cover;
    }
    .y_advanced_push_three_xg_1 .avatar-uploader{
        width: 100%;
        height: 100%;
    }
    .y_advanced_push_three_xg_1 .el-upload--text{
        width: 100%;
        height: 100%;
    }
    /* 添加 */
    /* .y_external_select_1{
        background: #fff;
        width: 100%;
        padding-top: 0.2rem;
    }
    .y_external_select_2{
        display: flex;
        justify-content: space-between;
        align-content: center;
        height: 0.5rem;
        margin: 0rem 0.35rem;
    }
    .y_external_select_3 p{
        font-size: 0.3rem;
        color: #333;
        font-weight: 600;
    }
    .y_external_select_5{

    }
    .y_external_select_4 .el-switch{
        height: 0.5rem;
        --el-switch-height: 0.5rem;
    }
    .y_external_select_4 /deep/ .el-switch--default .el-switch__action{
        left: 0.4rem !important;
        height: 0.4rem !important;
        width: 0.4rem !important;
        top: -0.02rem !important;
        transform: translate(-50%, 0%);
        position: absolute;
        margin-left: 0rem !important;
    }
    .y_external_select_4 /deep/ .is-checked .el-switch__action{
        height: 0.4rem !important;
        width: 0.4rem !important;
        top: -0.02rem !important;
        left: 0.2rem !important;
        transform: translate(-50%, 0%);
        position: absolute;
        margin-left: 0rem !important;
    } */
    .y_external_select_xg_1{
        font-size: 0.3rem;
        height: 1rem;
        background: #fff;
        margin-bottom: 0.2rem;
    }
    .y_external_select_xg_2{
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin: 0rem 0.35rem;
        height: 1rem;
    }
    .y_external_select_xg_3{
        font-size: 0.3rem;
        line-height: 1rem;
        font-weight: 600;
    }
    .y_external_select_xg_4{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .y_external_select_xg_4 /deep/ input{
        font-size: 0.3rem;
        border-radius: 0rem;
        border: none;
        text-align: right;
    }
    .y_external_select_xg_6 /deep/ input{
        font-size: 0.3rem;
        margin: 0rem 0.35rem;
        height: 1rem;
        line-height: 1rem;
        padding-left: 0rem;
        border-radius: 0rem;
        border: none;
    }
</style>
<script>
import kefu from '../../components/kefu/kefu'
import push_charges from '../../components/push_charges/push_charges'
import axios from 'axios'   // 接口
import {edit_adspace} from '../../api/request.js'   // 添加和编辑广告位，高级推广
import {adspace_info} from '../../api/request.js'   // 广告位详情，高级推广
export default {
    data(){
        return {
            shade_value:'0',  // 公共 阴影
            textarea: '',    // 输入框
            img:'',         // banner 单图
            fileList_list:[],   // 多图
            advanced_list:'',  // 传 列表
            external_value: '',  // 传 跳转方式
            options: [{
                value: 'none',
                label: '无跳转'
            }, {
                value: 'detail',
                label: '详情页跳转'
            }, {
                value: 'url',
                label: '外链跳转'
            }],
            url:'',  // 外链跳转连接


            adspace_id:'0',       // 广告位列表，高级推广 进入传id
            promotion_value:'0',  // 进入传value  0代表正常进入，1为待推送进入
        }
    },
    created(){
        // 获取页面传参 推送类型
        if(this.$route.query.adspace_id !== undefined){
            this.adspace_id = this.$route.query.adspace_id
            this.promotion_value = this.$route.query.promotion_value
        }
    },
    mounted(){
        let token = this.$cookies.get('token')
        if(token == null){
            this.$message({
                message: '请先登录！',
                duration:'2000'
            })
            var that = this;
            setTimeout(function () {
                that.$router.push({path:'/login'}) 
            },2500);
        }
        this.token = token
        let adspace_id = this.adspace_id
        let promotion_value = this.promotion_value
        if(promotion_value == '1'){
            adspace_info({token:token,adspace_id:adspace_id}).then((res) =>{ 
                console.log(res,'广告位详情，高级推广')
                this.img = res.adspace.img

                this.external_value = res.adspace.redirect_type
                this.textarea = res.adspace.content
                this.url = res.adspace.url
                // 多图
                let fileList = res.adspace.gallery        
                for(let i=0;i<fileList.length;i++){
                    fileList[i] = {url:''+fileList[i]}
                }
                console.log(fileList)
                this.fileList = fileList
                this.fileList_list = fileList
                // 多图
            })
        } 


        let advanced_list = JSON.parse(sessionStorage.getItem('advanced_list'));
        this.advanced_list = advanced_list
        console.log(advanced_list)
    },
    components:{
        kefu,
        push_charges,
    },
    methods: {
        // banner 图片上传成功
        banner_success(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            console.log(res,file)
            let img = res.img
            this.img = img
        },
        // banner 图片上传设置
        banner_settings(file) {
            console.log(file)
            // const isJPG = file.type === 'image/jpeg';
            // const isLt2M = file.size / 1024 / 1024 < 2;
            // if (!isJPG) {
            //   this.$message.error('上传头像图片只能是 JPG 格式!');
            // }
            // if (!isLt2M) {
            //   this.$message.error('上传头像图片大小不能超过 2MB!');
            // }
            // return isJPG && isLt2M;
        },

        // 图片上传
        // 删除图片
        handleRemove(file, fileList) {
          console.log(file, fileList);
          let promotion_value = this.promotion_value
          if(promotion_value == '0'){
            let fileList_list = []
            for(let i=0;i<fileList.length;i++){
              fileList_list.push(fileList[i].response.img)
            }
            this.fileList_list = fileList_list 
            console.log(this.fileList_list,'删除图片1')
          }else if(promotion_value == '1'){
            let fileList_list = []
            for(let i=0;i<fileList.length;i++){
                fileList_list.push(fileList[i])
            }
            this.fileList_list = fileList_list 
            console.log(this.fileList_list,'删除图片2')
          }
        },
        // 上传成功
        hand_success(file,fileList){
          console.log(file,fileList)
          let promotion_value = this.promotion_value
          if(promotion_value == '0'){
            let fileList_list = this.fileList_list
            fileList_list.push(fileList.response.img)
            this.fileList_list = fileList_list
            console.log(this.fileList_list,'上传成功1')
          }else if(promotion_value == '1'){
            console.log(file,fileList)
            let fileList_list = this.fileList_list
            fileList_list.push({url:''+file.img})
            this.fileList_list = fileList_list
            console.log(this.fileList_list,'上传成功2')
          }
        },
        handlePreview(file,fileList) {
            console.log(file,fileList);
        },
        // 上一步
        pop_step(){
            this.$router.go(-1)
        },
        // 跳转方式
        external_select(){
            let external_value = this.external_value
            console.log(external_value)
        },
        // 提交
        pop_up(){
            let token = this.token
            let advanced_list = this.advanced_list
            let adspace_id = this.adspace_id // 广告位id，可以为0
            let adspace_type = advanced_list.adspace_type  // 广告位类型
            let img = this.img  // banner 图片
            let gallery = this.fileList_list  // 多图
            let content = this.textarea  // 内容
            let price_type = advanced_list.price_type  // 推送价格类型
            let push_price_id = advanced_list.push_price_id  // 推送价格id
            let push_starttime = advanced_list.push_starttime  // 推送开始时间
            let push_endtime = advanced_list.push_endtime // 推送结束时间
            let school_json = JSON.stringify(advanced_list.school_json) // 学校信息
            let city_id = advanced_list.city_id // 城市id
            let external_value = this.external_value  // 传 跳转方式
            let url = this.url
            if(external_value == ''){
                this.$message({
                    message: '请选择跳转方式！',
                    duration:'2000'
                })
                return
            }

            let promotion_value = this.promotion_value
            if( promotion_value == '0'){
                let img_list = ''
                for(let i=0;i<gallery.length;i++){
                    img_list = img_list +','+ gallery[i]
                }
                gallery = img_list.substr(1,img_list.length)
                edit_adspace({token:token,adspace_id:adspace_id,adspace_type:adspace_type,img:img,gallery:gallery,content:content,price_type:price_type,push_price_id:push_price_id,push_starttime:push_starttime,push_endtime:push_endtime,school_json:school_json,city_id:city_id,redirect_type:external_value,url:url}).then((res)=>{
                    if(res.error_code == '0'){
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                        var that = this;
                        setTimeout(function () {
                            that.$router.push({path:'/'}) 
                        },1000);
                        console.log(res,'提交')
                    }else{
                        console.log('111')
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            }else if(promotion_value == '1'){
                let business_gallery = this.fileList
                let business_gallery2 = this.fileList_list
                let img_list = ''
                var reg1 = new RegExp("")
                console.log(business_gallery,business_gallery2,img_list)
                for(let i=0;i<business_gallery2.length;i++){
                    img_list = img_list +','+ business_gallery2[i].url.replace(reg1,"");
                }
                business_gallery2 = img_list.substr(1,img_list.length)
                console.log(business_gallery,business_gallery2)
                edit_adspace({token:token,adspace_id:adspace_id,adspace_type:adspace_type,img:img,gallery:business_gallery2,content:content,price_type:price_type,push_price_id:push_price_id,push_starttime:push_starttime,push_endtime:push_endtime,school_json:school_json,city_id:city_id,redirect_type:external_value,url:url}).then((res)=>{
                    if(res.error_code == '0'){
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                        var that = this;
                        setTimeout(function () {
                            that.$router.push({path:'/'}) 
                        },1000);
                        console.log(res,'提交')
                    }else{
                        console.log('111')
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            }
            
           
            console.log(advanced_list)
        },
    },
}
</script>
