<template>
    <div class="y_push_date_tg_1">
        <div class="y_push_date_tg_2">
            <div class="y_push_date_tg_3">
                <p>余额<span>{{ available_balance }}</span>元</p>
            </div>
            <div class="y_push_date_tg_4">
                <p @click="showDialog(true)">充值余额</p>
            </div>
        </div>
        <!-- 高级推送 轮播 -->
        <div class="y_push_date_gj_lb_1">
            <img src="../../../images/img_3.png" alt="">
        </div>
        <div class="y_push_date_gj_lb_4">
            <div class="y_push_date_gj_lb_2 y_push_date_gj_hf_1">
                <!-- <div class="y_push_date_gj_hf_2">
                    <p>给每人最多推送 3次</p>
                </div> -->
                <div class="y_push_date_gj_hf_2">
                    <!-- <p>共有<span>{{adspace_list.view_number}}</span>人浏览，人均浏览次数<span>N</span>次</p> -->
                    <p>共有<span>{{adspace_list.view_number_business}}</span>人浏览，人均浏览次数<span>{{(((adspace_list.view_number_total_business*1)/(adspace_list.view_number_business*1)).toFixed(0)) == 'NaN'?'0':''}}</span>次</p>

                </div>
                <div class="y_push_date_gj_hf_2">
                    <p>原设推送时间:{{adspace_list.push_starttime}}~{{adspace_list.push_endtime}}</p>
                </div>
                <div class="y_push_date_gj_lb_5 y_push_date_gj_hf_3">
                    <div class="y_push_date_gj_lb_6">
                        <h2>下线时间：</h2>
                        <p>{{adspace_list.offline_time}}</p>
                    </div>
                    <!-- <h3>+时</h3> -->
                </div>
                <div class="y_push_date_gj_hf_2">
                    <p>共扣费/总预算：<span>{{(cost_adspace_list.click_total_fee-cost_adspace_list.left_click_fee).toFixed(2)}}元 / {{cost_adspace_list.click_total_fee}}元</span></p>
                </div>
                <div class="y_push_date_gj_lb_5 y_push_date_gj_hf_3">
                    <div class="y_push_date_gj_lb_6">
                        <h2>共完成/预设总曝光量：</h2>
                        <p><span>{{(cost_adspace_list.exposure_total_fee-cost_adspace_list.left_exposure_fee).toFixed(2)}} / {{cost_adspace_list.exposure_total_fee}}</span></p>
                    </div>
                    <!-- <h3>+曝光量</h3> -->
                </div>
                <div class="y_push_date_gj_hf_2">
                    <p>总点击人数：<span>{{cost_adspace_list.click_number-cost_adspace_list.left_click_number}}</span></p>
                </div>
            </div>

            <div class="y_push_date_gj_lb_9">
                <div class="y_push_date_gj_lb_10" :class="undergraduate_display == '0'?'':'y_display_none'">
                    <div class="y_push_date_gj_lb_11">
                        <h2>本科学校</h2>
                        <div class="y_push_date_gj_lb_12">
                            <div class="y_push_date_gj_lb_13">
                                <h2>每天每人曝光</h2>
                                <p>{{cost_adspace_list.undergraduate_exposure_price*1}}元</p>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                            <div class="y_push_date_gj_lb_13">
                                <h2>每人点击</h2>
                                <p>{{cost_adspace_list.undergraduate_click_price*1}}元</p>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="y_push_date_gj_lb_14" :class="item.school_type_text == 'undergraduate' ?'':'y_display_none'" v-for="(item,index) in school_list" :key="index">
                        <div class="y_push_date_gj_lb_15">
                            <h2>{{item.school_title}}</h2>
                            <div class="y_push_date_gj_lb_16">
                                <h2>推送给</h2>
                                <p>{{item.grade_title}}</p>
                            </div>
                        </div>
                        <div class="y_push_date_gj_lb_17">
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>曝光人次</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <p>{{item.exposure_number - item.left_exposure_number}}</p>
                            </div>
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">  
                                    <h2>点击人数</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <p>{{item.click_number-item.left_click_number}}</p>
                            </div>
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>已扣费</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <p>{{(item.click_total_fee-item.left_click_fee+item.exposure_total_fee-item.left_exposure_fee).toFixed(2)}}</p>
                            </div>
                        </div>
                        <!-- <div class="y_push_date_gj_lb_17">
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>预计新增曝光人次</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <p>N</p>
                            </div>
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>预计新增点击量</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <p>N</p>
                            </div>
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>预算新增</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <p>N</p>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="y_push_date_gj_lb_9">
                <div class="y_push_date_gj_lb_10" :class="specialty_display == '0'?'':'y_display_none'">
                    <div class="y_push_date_gj_lb_11">
                        <h2>专科学校</h2>
                        <div class="y_push_date_gj_lb_12">
                            <div class="y_push_date_gj_lb_13">
                                <h2>每天每人曝光</h2>
                                <p>{{cost_adspace_list.specialty_exposure_price*1}}元</p>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                            <div class="y_push_date_gj_lb_13">
                                <h2>每人点击</h2>
                                <p>{{cost_adspace_list.specialty_click_price*1}}元</p>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="y_push_date_gj_lb_14" :class="item.school_type_text == 'specialty' ?'':'y_display_none'" v-for="(item,index) in school_list" :key="index">
                        <div class="y_push_date_gj_lb_15">
                            <h2>{{item.school_title}}</h2>
                            <div class="y_push_date_gj_lb_16">
                                <h2>推送给</h2>
                                <p>{{item.grade_title}}</p>
                            </div>
                        </div>
                        <div class="y_push_date_gj_lb_17">
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>曝光人次</h2>
                                    <!-- <img src="../../../images/icon_21.png" alt=""> -->
                                </div>
                                <p>{{item.exposure_number - item.left_exposure_number}}</p>
                            </div>
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>点击人数</h2>
                                    <!-- <img src="../../../images/icon_21.png" alt=""> -->
                                </div>
                                <p>{{item.click_number-item.left_click_number}}</p>
                            </div>
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>已扣费</h2>
                                    <!-- <img src="../../../images/icon_21.png" alt=""> -->
                                </div>
                                <p>{{(item.click_total_fee-item.left_click_fee+item.exposure_total_fee-item.left_exposure_fee).toFixed(2)}}</p>
                            </div>
                        </div>
                        <!-- <div class="y_push_date_gj_lb_17">
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>预计新增曝光人次</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <p>N</p>
                            </div>
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>预计新增点击量</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <p>N</p>
                            </div>
                            <div class="y_push_date_gj_lb_18">
                                <div class="y_push_date_gj_lb_19">
                                    <h2>预算新增</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <p>N</p>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <kefu></kefu>
    <recharge v-show="dialog_visible" :dialog_visible="dialog_visible" @dialogVisibleEvent="showDialog"></recharge>
</template>

<style scoped>
    .y_push_date_tg_1{
        width: 100%;
        min-height: 100vh;
        background: #F4F5F7;
    }
    .y_push_date_tg_2{
        padding: 0rem 0.45rem;
        height: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_push_date_tg_3 p{
        font-size: 0.28rem;
        color: #333;
    }
    .y_push_date_tg_3 p span{
        color:#FECE0A;
        font-size: .28rem;
    }
    .y_push_date_tg_4 p{
        font-size: 0.28rem;
        color: #333;
        text-decoration:underline
    }
    .y_push_date_tg_5{
        padding: 0.28rem 0.25rem;
        background: #fff;
    }
    .y_push_date_tg_6{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.15rem;
    }
    .y_push_date_tg_7{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_date_tg_7 p{
        font-size: 0.24rem;
        color: #999;
    }
    .y_push_date_tg_7 h2{
        margin-left: 0.15rem;
        color: #333;
        font-weight: 600;
        font-size: 0.28rem;
    }
    .y_push_date_tg_7 h2 span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_8{
        display: inline-block;
        background: #FECE0A;
        padding: 0rem 0.15rem;
        height: 0.46rem;
        border-radius: 0.1rem;
    }
    .y_push_date_tg_8 p{
        line-height: 0.46rem;
        color: #333;
        font-size: 0.26rem;
    }
    .y_push_date_tg_9{
        width: 100%;
        margin-bottom: 0.15rem;
    }
    .y_push_date_tg_9 p{
        color: #999;
        font-size: 0.24rem;
        line-height: 0.3rem;
    }
    .y_push_date_tg_9 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_10{
        width: 100%;
    }
    .y_push_date_tg_11{
        padding: 0rem 0.35rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.8rem;
    }
    .y_push_date_tg_11 p{
        color:#333;
        font-size: 0.28rem;
    }
    .y_push_date_tg_11 h2{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_12{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.8rem;
    }
    .y_push_date_tg_12 p{
        flex: 1;
        text-align: center;
        color: #999;
        font-size: 0.26rem;
    }
    .y_push_date_tg_12 p span{
        color: #FECE0A;
        font-size: 0.26rem;
    }
    .y_push_date_tg_13{
        width: 100%;
        background: #fff;
        padding-bottom: 0.4rem;
    }
    .y_push_date_tg_14{
        display: flex;
        justify-content: flex-start;
    }
    .y_push_date_tg_15{
        flex:1;
    }
    .y_push_date_tg_16{
        width: 100%;
    }
    .y_push_date_tg_16 h2{
        width: 100%;
        line-height: 1rem;
        text-align: center;
        color: #333;
        font-size: 0.28rem;    
    }
    .y_push_date_tg_16_color h2{
        color: #FECE0A;
    } 
    .y_push_date_tg_17{
        width: 100%;
    }
    .y_push_date_tg_17>p{
        font-size: 0.28rem;
        color: #333;
        width: 100%;
        text-align: center;
    }
    .y_push_date_tg_18{
        width: 100%;
        text-align: center;
        margin-top: 0.25rem;
    }
    .y_push_date_tg_18 /deep/ input{
        width: 1.6rem;
        height: 0.8rem;
        border-radius: 0.1rem;
        background: #F7F8FA;
        overflow: hidden;
        padding: 0rem;
        line-height: 0.8rem;
        margin: 0 auto;
        font-size: 0.28rem;
        color: #ccc;
        text-align: center;
        border: none;
    }
    .y_push_date_tg_18 p{
        color: #FECE0A;
        font-size: 0.28rem;
        line-height: 0.8rem;
    }
    .y_push_date_tg_19{
        width: 100%;
        height: 5rem;
        background: transparent;
    }
    .y_push_date_tg_20{
        width: 100%;
        height: 2.7rem;
        background: #fff;
        position: fixed;
        left: 0rem;
        bottom: 0rem;
    }
    .y_push_date_tg_21{
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        padding: 0rem 0.25rem;
        height: 100%;
    }
    .y_push_date_tg_22{
        width: 100%;
        text-align: center;
    }
    .y_push_date_tg_22 p{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_date_tg_22 p span{
        color:#FECE0A;
        font-size: 0.28rem;
    }
    .font-s{
        display: flex;
        margin-top: 0.2rem;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .font-s p{
        color: #333;
        font-size: 0.28rem;
    }
    .font-s p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .font-s h2{
        color: #FECE0A;
        font-size: 0.28rem;
        text-decoration:underline;
    }
    .y_push_date_tg_24{
        width: 100%;
        margin-top: 0.3rem;
    }
    .y_push_date_tg_24 p{
        height: 0.8rem;
        background: #FECE0A;
        line-height: 0.8rem;
        width: 100%;
        border-radius: 0.1rem;
        text-align: center;
        font-size:0.32rem;
        color: #333;
        font-weight: 600;
    }

    /* 高级推送 轮播 */
    .y_push_date_gj_lb_1{
        width: 100%;
        height: 2rem;
    }
    .y_push_date_gj_lb_1 img{
        width: 100%;
        object-fit: cover;
    }
    .y_push_date_gj_lb_4{
        width: 100%;
        background: #fff;
    }
    .y_push_date_gj_lb_2{
        padding: 0rem 0.35rem;
        border-bottom: 0.01rem solid #EEEEEE;
    }
    .y_push_date_gj_lb_3{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1rem;
    }
    .y_push_date_gj_lb_3 h2{
        color: #999;
        font-size: 0.28rem;
        margin-right: 0.25rem;
    }
    .y_push_date_gj_lb_3 p{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_date_gj_lb_5{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 0.3rem;
    }
    .y_push_date_gj_lb_6{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_date_gj_lb_6 h2{
        color: #999;
        font-size: 0.28rem;
        margin-right: 0.2rem;
    }
    .y_push_date_gj_lb_6 p{
        color: #333333;
        font-size: 0.28rem;
    }
    .y_push_date_gj_lb_6 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_gj_lb_5 h3{
        line-height: 0.45rem;
        background: #FECE0A;
        display: inline-block;
        border-radius: 0.1rem;
        padding: 0rem 0.3rem;
        color: #333;
        font-size: 0.26rem;
    }
    .y_push_date_gj_lb_7{
        width: 100%;
        height: 1.5rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_date_gj_lb_8{
        flex: 1;
        display: flex;
        height: 100%;
        align-content: center;
        flex-wrap: wrap;
    }
    .y_push_date_gj_lb_8 h2{
        width: 100%;
        text-align: center;
        color: #999;
        font-size: 0.28rem;
    }
    .y_push_date_gj_lb_8 p{
        width: 100%;
        text-align: center;
        margin-top: 0.2rem;
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_gj_lb_9{
        padding: 0rem 0.25rem 0.3rem;
    }
    .y_push_date_gj_lb_10{
        padding: 0rem 0.2rem;
        background: #FECE0A;
        border-radius: 0.1rem;
        padding-bottom: 0.5rem;
    }
    .y_push_date_gj_lb_11{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1rem;
    }
    .y_push_date_gj_lb_11>h2{
        font-size: 0.32rem;
        color: #333;
        font-weight: 600;
        width: 1.5rem;
    }
    .y_push_date_gj_lb_12{
        display: flex;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        background: rgba(255,255,255,0.8);
        border-radius: 0.1rem;
        height: 0.6rem;
    }
    .y_push_date_gj_lb_13{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 0.2rem;
    }
    .y_push_date_gj_lb_13 h2{
        color: #999;
        font-size: 0.24rem;
    }
    .y_push_date_gj_lb_13 p{
        margin-right: 0.1rem;
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_gj_lb_13 img{
        width: 0.2rem;
        height: 0.2rem;
        margin-left: 0.05rem;
    }
    .y_push_date_gj_lb_14{
        border-radius: 0.1rem;
        overflow: hidden;
        background: #fff;
        margin-bottom: 0.2rem;
    }
    .y_push_date_gj_lb_15{
        height: 0.9rem;
        background: #FFFBED;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_date_gj_lb_15>h2{
        margin-left: 0.3rem;
        color: #333;
        font-size: 0.28rem;
        min-width: 2rem;
        font-weight: 600;
    }
    .y_push_date_gj_lb_16{
        display: flex;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        margin-left: 0.1rem;
    }
    .y_push_date_gj_lb_16 h2{
        font-size: 0.28rem;
        color: #999;

    }
    .y_push_date_gj_lb_16 p{
        color: #FECE0A;
        font-size: 0.28rem;
        margin-left: 0.1rem;
    }
    .y_push_date_gj_lb_17{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1.5rem;
    }
    .y_push_date_gj_lb_18{
        flex:1;
        flex-wrap: wrap;
        display: flex;
        height: 100%;
        align-content: center;
    }
    .y_push_date_gj_lb_19{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .y_push_date_gj_lb_19>h2{
        color: #333333;
        font-size: 0.25rem;
    }
    .y_push_date_gj_lb_19 img{
        width: 0.2rem;
        height: 0.2rem;
        /* margin-left: 0.1rem; */
    }
    .y_push_date_gj_lb_18 p{
        width: 100%;
        text-align: center;
        color: #FECE0A;
        font-size: 0.36rem;
        margin-top: 0.2rem;
    }
    /* 高级推送 横幅 */
    .y_push_date_gj_hf_1{
        padding-top: 0.3rem;
        border-bottom: none;
    }
    .y_push_date_gj_hf_2{
        width: 100%;
        margin-bottom: 0.25rem;
    }
    .y_push_date_gj_hf_2 p{
        color: #999;
        font-size: 0.28rem;
    }
    .y_push_date_gj_hf_2 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_gj_hf_3{
        padding-bottom: 0.15rem;
    }
    .y_push_date_gj_hf_4{
        width: 100%;
        text-align: center;
        /* margin-top: 0.15rem; */
    }
    .y_push_date_gj_hf_4 p{
        color: #333;
        font-size: 0.28rem;
        line-height: 0.4rem;
    }
    .y_push_date_gj_hf_4 /deep/ input{
        width: 1.6rem;
        height: 0.6rem;
        padding: 0rem;
        text-align: center;
        line-height: 0.6rem;
        color: #333;
        font-size: 0.28rem;
        background: #F7F8FA;
        border-radius: 0rem;
        border: none;
        margin: 0rem auto;
    }
    .y_push_date_gj_hf_4 /deep/ input::placeholder{
        font-size: 0.24rem;
        color: #ccc;
    } 
    .y_display_none{
        display: none;
    }
</style>

<script>
    import kefu from '../../components/kefu/kefu'
    import recharge from '../../components/recharge/recharge'
    import {user_info} from '../../api/request.js' // 用户信息
    import {adspace_info} from '../../api/request.js' // 广告位详情，高级推广
    export default {
        data(){
            return {
                input: '',
                //公共 钱包充值
                dialog_visible: false,
                available_balance:'',

                recruit_id:'',
                push_status:'',
                adspace_list:'',
                cost_adspace_list:'',
                school_list:'',

                undergraduate_display:'0',  // 没有年级时隐藏整个年级学校
                specialty_display:'0',      // 没有年级时隐藏整个年级学校
            }
        },  
        components:{
            kefu,
            recharge,
        },
        created(){
            // 获取页面传参 推送类型
            if(this.$route.query.recruit_id !== undefined){
                this.recruit_id = this.$route.query.recruit_id
                this.push_status = this.$route.query.push_status
            }
        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            let recruit_id = this.recruit_id
            user_info({token:token}).then((res)=>{
                console.log(res,'用户信息')
                let balance = res.data.balance  // 	余额
                let bonus_fee = res.data.bonus_fee // 奖励金
                let frozen_fee = res.data.frozen_fee  // 冻结金额
                let deposit_fee = res.data.deposit_fee  // 押金金额
                let available_balance = ''      // 可用余额

                this.balance = balance
                this.bonus_fee = bonus_fee
                this.frozen_fee = frozen_fee
                this.deposit_fee = deposit_fee
                this.available_balance = balance*1 + bonus_fee*1
            })
            adspace_info({token:token,adspace_id:recruit_id}).then((res)=>{
                console.log(res,'推送中-高级推广,横幅 ')
                this.adspace_list = res.adspace
                this.cost_adspace_list = res.cost_adspace

                let school_list = res.cost_adspace_school
                let undergraduate_num = '0'
                let specialty_num = '0'
                for(let i=0;i<school_list.length;i++){
                    school_list[i].grade_title = []
                    for(let p=0;p<school_list[i].grade_list.length;p++){
                        school_list[i].grade_title.push(school_list[i].grade_list[p].title)
                    }
                    if(school_list[i].school_type_text == "undergraduate"){
                        undergraduate_num++
                    }
                    if(school_list[i].school_type_text == "specialty"){
                        specialty_num++
                    }
                }
                if(undergraduate_num == '0'){
                    this.undergraduate_display = '1'
                }
                if(specialty_num == '0'){
                    this.specialty_display = '1'
                }
                this.school_list = school_list
            })
        },
        methods: {
            // 充值
            showDialog(visible) {
                this.dialog_visible = visible;
            },
        },
    }
</script>