import { createRouter, createWebHashHistory  } from 'vue-router';


import index from '../miaojum/index/index.vue'
import about from '../miaojum/about/index.vue'
import recruit_index from '../miaojum/recruit_index/index.vue'
import recruit_index_two from '../miaojum/recruit_index_two/index.vue'
import push_settings from '../miaojum/push_settings/index.vue'
import promotion from '../miaojum/promotion/index.vue'
import topics_push from '../miaojum/topics_push/index.vue'
import topics_select_two from '../miaojum/topics_select_two/index.vue'
import topics_select_three from '../miaojum/topics_select_three/index.vue'
import advanced_push from '../miaojum/advanced_push/index.vue'
import advanced_push_two from '../miaojum/advanced_push_two/index.vue'
import advanced_push_three from '../miaojum/advanced_push_three/index.vue'
import sweepstakes_settings from '../miaojum/sweepstakes_settings/index.vue'
import sweepstakes_settings_two from '../miaojum/sweepstakes_settings_two/index.vue'
import sweepstakes_settings_lxr from '../miaojum/sweepstakes_settings_lxr/index.vue'
import sweepstakes_settings_lxr_two from '../miaojum/sweepstakes_settings_lxr_two/index.vue'
import sw_user_detail from '../miaojum/sw_user_detail/index.vue'
import message_private from '../miaojum/message_private/index.vue'
import message_comments from '../miaojum/message_comments/index.vue'
import message_push_progress from '../miaojum/message_push_progress/index.vue'
import message_to_pushed from '../miaojum/message_to_pushed/index.vue'
import message_already_offline from '../miaojum/message_already_offline/index.vue'
import push_date_tg from '../miaojum/push_date_tg/index.vue'
import push_date_zp from '../miaojum/push_date_zp/index.vue'
import push_date_ht from '../miaojum/push_date_ht/index.vue'
import push_date_ht_zp from '../miaojum/push_date_ht_zp/index.vue'
import push_date_gj_lb from '../miaojum/push_date_gj_lb/index.vue'
import push_date_gj_hf from '../miaojum/push_date_gj_hf/index.vue'
import push_date_cj from '../miaojum/push_date_cj/index.vue'
import offline_date_tg from '../miaojum/offline_date_tg/index.vue'
import offline_date_ht from '../miaojum/offline_date_ht/index.vue'
import offline_date_cj from '../miaojum/offline_date_cj/index.vue'
import offline_date_zp from '../miaojum/offline_date_zp/index.vue'
import offline_date_gj_lb from '../miaojum/offline_date_gj_lb/index.vue'
import offline_date_gj_hf from '../miaojum/offline_date_gj_hf/index.vue'
import my_index from '../miaojum/my_index/index.vue'
import my_edit_profile from '../miaojum/my_edit_profile/index.vue'
import my_settings from '../miaojum/my_settings/index.vue'
import my_change_phone_one from '../miaojum/my_change_phone_one/index.vue'
import my_change_phone_two from '../miaojum/my_change_phone_two/index.vue'
import my_change_password from '../miaojum/my_change_password/index.vue'
import my_invite_new_prize from '../miaojum/my_invite_new_prize/index.vue'
import my_earnings from '../miaojum/my_earnings/index.vue'
import my_withdrawal_page from '../miaojum/my_withdrawal_page/index.vue'
import my_certification from '../miaojum/my_certification/index.vue'
import my_personal_certification_one from '../miaojum/my_personal_certification_one/index.vue'
import my_personal_certification_two from '../miaojum/my_personal_certification_two/index.vue'
import my_advanced_certification_one from '../miaojum/my_advanced_certification_one/index.vue'
import my_advanced_certification_two from '../miaojum/my_advanced_certification_two/index.vue'
import login from '../miaojum/login/index.vue'
import authorized_login from '../miaojum/authorized_login/index.vue'
import richtext from '../miaojum/richtext/index.vue'
import user_comm_jump from '../miaojum/user_comm_jump/index.vue'
import user_comm_detail from '../miaojum/user_comm_detail/index.vue'
import usage_tips from '../miaojum/usage_tips/index.vue'
import usage_tips_xq from '../miaojum/usage_tips_xq/index.vue'
import private_message_details from '../miaojum/private_message_details/index.vue'
import qrcodestream from '../miaojum/qrcodestream/index.vue'
import write_offs_list from '../miaojum/write_offs_list/index.vue'
import write_offs_date from '../miaojum/write_offs_date/index.vue'

import ceshi from '../miaojum/ceshi/index.vue'
import ceshi_two from '../miaojum/ceshi_two/index.vue'
const routes = [
  // 首页
  {
    path: '/',
    name: 'index',
    component: index
  },
  // 关于我们
  {
    path: '/about',
    name: 'about',
    component: about
  },
  // 招聘 第一个页面
  {
    path: '/recruit_index',
    name: 'recruit_index',
    component: recruit_index,
    meta: {
      keepAlive:true  
    },
  },
  // 招聘 第二个页面
  {
    path: '/recruit_index_two',
    name: 'recruit_index_two',
    component: recruit_index_two,
    meta: {
      keepAlive:true  
    },
  },
  // 推送设置
  {
    path: '/push_settings',
    name: 'push_settings',
    component: push_settings,
    meta: {
      keepAlive:true  
    },
  },
  // 推广 
  {
    path: '/promotion',
    name: 'promotion',
    component: promotion,
    meta: {
      keepAlive:true  
    },
  },
  // 话题推送 
  {
    path: '/topics_push',
    name: 'topics_push',
    component: topics_push,
    meta: {
      keepAlive:true  
    },
  },
  // 话题推送2 (推广，招生，招聘)选择 
  {
    path: '/topics_select_two',
    name: 'topics_select_two',
    component: topics_select_two,
    meta: {
      keepAlive:true  
    },
  },
  // 话题推送3 (推广，招生，招聘)选择 
  {
    path: '/topics_select_three',
    name: 'topics_select_three',
    component: topics_select_three,
    meta: {
      keepAlive:true  
    },
  },
  // 高级推送
  {
    path: '/advanced_push',
    name: 'advanced_push',
    component: advanced_push,
    meta: {
      keepAlive:true  
    },
  },
  // 高级推送 推广推送设置 2
  {
    path: '/advanced_push_two',
    name: 'advanced_push_two',
    component: advanced_push_two,
    meta: {
      keepAlive:true  
    },
  },
  // 高级推送 推广推送设置 3
  {
    path: '/advanced_push_three',
    name: 'advanced_push_three',
    component: advanced_push_three,
    meta: {
      keepAlive:true  
    },
  },
  // 抽奖设置
  {
    path: '/sweepstakes_settings',
    name: 'sweepstakes_settings',
    component: sweepstakes_settings,
    meta: {
      keepAlive:true  
    },
  },
  // 抽奖设置 2
  {
    path: '/sweepstakes_settings_two',
    name: 'sweepstakes_settings_two',
    component: sweepstakes_settings_two,
    meta: {
      keepAlive:true  
    },
  },
  // 抽奖设置 联系人
  {
    path: '/sweepstakes_settings_lxr',
    name: 'sweepstakes_settings_lxr',
    component: sweepstakes_settings_lxr,
    meta: {
      keepAlive:true  
    },
  },
  // 抽奖设置 联系人 2 设置 
  {
    path: '/sweepstakes_settings_lxr_two',
    name: 'sweepstakes_settings_lxr_two',
    component: sweepstakes_settings_lxr_two,
  },
  // 商务 用户详情 
  {
    path: '/sw_user_detail',
    name: 'sw_user_detail',
    component: sw_user_detail
  },
  // 消息 - 私信
  {
    path: '/message_private',
    name: 'message_private',
    component: message_private
  },
  // 消息 - 评论
  {
    path: '/message_comments',
    name: 'message_comments',
    component: message_comments
  },
  // 消息 - 推送中
  {
    path: '/message_push_progress',
    name: 'message_push_progress',
    component: message_push_progress
  },
  // 消息 - 待推送
  {
    path: '/message_to_pushed',
    name: 'message_to_pushed',
    component: message_to_pushed
  },
  // 消息 - 已下线
  {
    path: '/message_already_offline',
    name: 'message_already_offline',
    component: message_already_offline
  },
  // 推送详情 - 推广 or 招生 的详细
  {
    path: '/push_date_tg',
    name: 'push_date_tg',
    component: push_date_tg
  },
  // 推送详情 - 招聘 的详细
  {
    path: '/push_date_zp',
    name: 'push_date_zp',
    component: push_date_zp
  },
  // 推送详情 - 话题，小组 活动推送 帖子
  {
    path: '/push_date_ht',
    name: 'push_date_ht',
    component: push_date_ht
  },
    // 推送详情 - 话题，小组 活动推送 招聘
    {
      path: '/push_date_ht_zp',
      name: 'push_date_ht_zp',
      component: push_date_ht_zp
    },
  // 推送详情 - 高级推送 轮播
  {
    path: '/push_date_gj_lb',
    name: 'push_date_gj_lb',
    component: push_date_gj_lb
  },
  // 推送详情 - 高级推送 横幅
  {
    path: '/push_date_gj_hf',
    name: 'push_date_gj_hf',
    component: push_date_gj_hf
  },
  // 推送详情 - 抽奖 详情
  {
    path: '/push_date_cj',
    name: 'push_date_cj',
    component: push_date_cj
  },
  // 已下线 - 推广 or 招生 详情
  {
    path: '/offline_date_tg',
    name: 'offline_date_tg',
    component: offline_date_tg
  },
  // 已下线 - 话题 详情
  {
    path: '/offline_date_ht',
    name: 'offline_date_ht',
    component: offline_date_ht
  },
  // 已下线 - 抽奖 详情
  {
    path: '/offline_date_cj',
    name: 'offline_date_cj',
    component: offline_date_cj
  },
  // 已下线 - 招聘 详情
  {
    path: '/offline_date_zp',
    name: 'offline_date_zp',
    component: offline_date_zp
  },
  // 已下线 - 高级推送 轮播 详情
  {
    path: '/offline_date_gj_lb',
    name: 'offline_date_gj_lb',
    component: offline_date_gj_lb
  },
  // 已下线 - 高级推送 横幅 详情
  {
    path: '/offline_date_gj_hf',
    name: 'offline_date_gj_hf',
    component: offline_date_gj_hf
  },
  // 我的 - 首页
  {
    path: '/my_index',
    name: 'my_index',
    component: my_index
  },
  // 我的 - 编辑资料
  {
    path: '/my_edit_profile',
    name: 'my_edit_profile',
    component: my_edit_profile
  },
  // 我的 - 设置
  {
    path: '/my_settings',
    name: 'my_settings',
    component: my_settings
  },
  // 我的 - 换绑手机 1
  {
    path: '/my_change_phone_one',
    name: 'my_change_phone_one',
    component: my_change_phone_one
  },
  // 我的 - 换绑手机 2
  {
    path: '/my_change_phone_two',
    name: 'my_change_phone_two',
    component: my_change_phone_two
  },
  // 我的 - 修改密码
  {
    path: '/my_change_password',
    name: 'my_change_password',
    component: my_change_password
  },
  // 我的 - 邀新有奖
  {
    path: '/my_invite_new_prize',
    name: 'my_invite_new_prize',
    component: my_invite_new_prize
  },
  // 我的 - 我的收益
  {
    path: '/my_earnings',
    name: 'my_earnings',
    component: my_earnings
  },
  // 我的 - 提现页面
  {
    path: '/my_withdrawal_page',
    name: 'my_withdrawal_page',
    component: my_withdrawal_page
  },
  // 我的 - 认证
  {
    path: '/my_certification',
    name: 'my_certification',
    component: my_certification
  },
  // 我的 - 认证 个人认证 1
  {
    path: '/my_personal_certification_one',
    name: 'my_personal_certification_one',
    component: my_personal_certification_one,
    meta: {
      keepAlive:true  
    },
  },
  // 我的 - 认证 个人认证 2
  {
    path: '/my_personal_certification_two',
    name: 'my_personal_certification_two',
    component: my_personal_certification_two,
    meta: {
      keepAlive:true  
    },
  },
  // 我的 - 认证 高级认证 1
  {
    path: '/my_advanced_certification_one',
    name: 'my_advanced_certification_one',
    component: my_advanced_certification_one
  },
  // 我的 - 认证 高级认证 2
  {
    path: '/my_advanced_certification_two',
    name: 'my_advanced_certification_two',
    component: my_advanced_certification_two
  },
  // 图文编辑器
  {
    path: '/richtext',
    name: 'richtext',
    component: richtext,
    meta: {
      keepAlive:true  
    },
  },
  // 登录
  {
    path: '/login',
    name: 'login',
    component: login
  },
  // 授权登录
  {
    path: '/authorized_login',
    name: 'authorized_login',
    component: authorized_login
  },
  // 用户页 公共 跳转展示页
  {
    path: '/user_comm_jump',
    name: 'user_comm_jump',
    component: user_comm_jump
  },
  // 用户页 公共 跳转详情
  {
    path: '/user_comm_detail',
    name: 'user_comm_detail',
    component: user_comm_detail
  },
  // 使用攻略 二级列表
  {
    path: '/usage_tips',
    name: 'usage_tips',
    component: usage_tips
  },
  // 使用攻略 三级详情
  {
    path: '/usage_tips_xq',
    name: 'usage_tips_xq',
    component: usage_tips_xq
  },
  // 私信详情
  {
    path: '/private_message_details',
    name: 'private_message_details',
    component: private_message_details
  },
  // 扫一扫
  {
    path: '/qrcodestream',
    name: 'qrcodestream',
    component: qrcodestream
  },
  // 抽奖核销 列表
  {
    path: '/write_offs_list',
    name: 'write_offs_list',
    component: write_offs_list
  },
  // 抽奖核销 详情
  {
    path: '/write_offs_date',
    name: 'write_offs_date',
    component: write_offs_date
  },
  // 测试
  {
    path: '/ceshi',
    name: 'ceshi',
    component: ceshi
  },
  // 测试
  {
    path: '/ceshi_two',
    name: 'ceshi_two',
    component: ceshi_two
  },
]

const router = createRouter({
	//history: createWebHistory(process.env.BASE_URL),//默认时
	history: createWebHashHistory(process.env.BASE_URL),//修改后
	routes
})
export default router;

