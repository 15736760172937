
<template>
    <div class="y_my_edit_profile_1">
        <div class="y_my_settings_1">
            <router-link to='/my_change_phone_one' class="y_my_settings_2">
                <div class="y_my_settings_3">
                    <h2>换绑手机号</h2>
                </div>
                <div class="y_my_settings_4">
                    <!-- <div class="y_my_settings_5">
                        <el-input v-model="input" placeholder="请输入内容"></el-input>
                    </div> -->
                    <div class="y_my_settings_6">
                        <img src="../../../images/icon_12.png" alt="">
                    </div>
                </div>
            </router-link>
            <!-- <div class="y_my_settings_2">
                <div class="y_my_settings_3">
                    <h2>绑定微信</h2>
                </div>
                <div class="y_my_settings_4">
                    <div class="y_my_settings_6">
                        <img src="../../../images/icon_12.png" alt="">
                    </div>
                </div>
            </div> -->
            <!-- <router-link to='/my_change_password'  class="y_my_settings_2">
                <div class="y_my_settings_3">
                    <h2>登录密码</h2>
                </div>
                <div class="y_my_settings_4">
                    <div class="y_my_settings_6">
                        <img src="../../../images/icon_12.png" alt="">
                    </div>
                </div>
            </router-link> -->
        </div>
    </div>
</template>
<style scoped>
    .y_my_edit_profile_1{
        min-height: 100vh;
        width: 100%;
        background: #F4F5F7;
    }
    .y_my_settings_1{
        width: 100%;
        background: #fff;
    }
    .y_my_settings_2{
        padding: 0rem 0.3rem;
        height: 1.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.01rem solid #EDEDED;
    }
    .y_my_settings_3 h2{
        font-size: 0.3rem;
        color: #999;
    }
    .y_my_settings_4{
        display: flex;
        justify-content: flex-end;
        margin-left: 0.2rem;
        align-items: center;
        height: 100%;
    }
    .y_my_settings_5{
        flex: 1;
    }
    .y_my_settings_5 /deep/ input{
        width: 100%;
        height: 1.2rem;
        line-height: 1.2rem;
        color: #333;
        font-size: 0.3rem;
        text-align: right;
        padding: 0rem;
        background: transparent;
        border: none;
        border-radius: 0rem;
    }
    .y_my_settings_6{
        width: 0.14rem;
        margin-left: 0.2rem;
    }
    .y_my_settings_6 img{
        width: 100%;
        object-fit: cover;
    }

</style>
<script>

    export default {
        data(){
            return {
                input: '',
            }
        },  
        components:{

        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
        },
        methods: {
           
        },
    }

</script>