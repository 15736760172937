
<template>
    <div class="y_user_comm_1">
        <p v-html="tips_content"></p>
    </div>
</template>
<style scoped>
    .y_user_comm_1{
        padding: 0.2rem;
    }
    .y_user_comm_1 p{
        color: #333;
        font-size: 0.28rem;
        line-height: 0.4rem;
    }
</style>
<script>
    import axios from 'axios'   // 接口
    import {strategy_detail} from '../../api/request.js' // 攻略详情

    export default {
        data(){
            return {
                tips_two:'', // 传详情 id
                tips_content:'', // 内容
            }
        },  
        components:{

        },
        created(){
            // 获取页面传参 推送类型
            this.tips_two = this.$route.query.tips_two;
            let tips_two = this.$route.query.tips_two;
            strategy_detail({strategy_id:tips_two}).then((res) =>{
                console.log(res,'攻略详情')
                this.tips_content = res.data.content
            })
        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            let tips_two = this.$route.query.tips_two;
            strategy_detail({strategy_id:tips_two}).then((res) =>{
                console.log(res,'攻略详情')
                this.tips_content = res.data.content
            })
        },
        methods: {
            
        },
    }
</script>