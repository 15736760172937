<template>
   <div class="richtextdiv">
        <div class="listall" ref="scrollDiv" v-if="current_text == '0'?'':true">
            <div class="listshow" v-for="item,index in list" :key="item.type">
                <div class="listshowhead">
                    <img src="../../../images/icon_text.png" alt="" v-if="item.type == '1'">
                    <img src="../../../images/icon_person.png" alt="" v-if="item.type == '2'">
                    <img src="../../../images/icon_copy.png" alt="" v-if="item.type == '3'">
                    <img src="../../../images/icon_img.png" alt="" v-if="item.type == '4'">
                    <p :style="index != 0?'':'color:#999'" style="margin-left: auto;" @click="index != 0?moveup(index):''">上移</p>
                    <p @click="movedown(index)">下移</p>
                    <p :style="index != 0?'':'color:#999'" @click="movetop(index)">置顶</p>
                    <p @click="movedelete(index)">删除</p>
                </div>
                <div class="listshowtext" v-if="item.type == '1'">
                    <el-input placeholder="请输入文字" type="textarea" v-model="item.concent" :autosize="{ minRows: 2, maxRows: 99}"></el-input>
                </div>
                <div class="listshowperson" v-if="item.type == '2'" @click="contact_jump_two(index)">
                    <div class="listshowpersonshow">
                        <p>{{item.phone_text == ''?'联系人':item.phone_text}}</p>
                        <p>加好友</p>
                        <img src="../../../images/icon_perright.png" alt="">
                    </div>
                </div>
                <div class="listshowcopy" v-if="item.type == '3'">
                    <input v-model="item.oneconcent" class="listshowcopyinput" type="text" placeholder="点此填写引导文案" />
                    <input v-model="item.copycontent" class="listshowcopyinput1" type="text" placeholder="点此填写需要被复制的内容" />
                    <button class="listshowcopybtn" @click="copytext(item.copycontent)">点此一键复制</button>
                </div>
                <div class="listshowimage" v-if="item.type == '4'" @click="changeimg(index)">
                    <!-- <img src="../../../images/icon_14.png" alt=""> -->
                    <el-upload class="avatar-uploader" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :show-file-list="false" :on-success="banner_success" :before-upload="banner_settings">
                        <img src="../../../images/icon_14.png" v-if="item.image == ''? true : false " alt="">
                        <img :src="''+item.image"  v-if="item.image == ''? false:true"  alt="">
                    </el-upload>
                </div>
            </div>
            <!-- <div class="listshow">
                <div class="listshowhead">
                    <img src="../../../images/icon_img.png" alt="" v-if="true">
                    <img src="../../../images/icon_text.png" alt="" v-if="false">
                    <img src="../../../images/icon_person.png" alt="" v-if="false">
                    <img src="../../../images/icon_copy.png" alt="" v-if="false">
                    <p style="margin-left: auto;">上移</p>
                    <p>下移</p>
                    <p>置顶</p>
                    <p>删除</p>
                </div>
                <div class="listshowperson">
                    <div class="listshowpersonshow">
                        <p>联系人1</p>
                        <p>去了解</p>
                        <img src="../../../images/icon_perright.png" alt="">
                    </div>
                </div>
                <div class="listshowcopy" v-if="false"></div>
                <div class="listshowimage"></div>
            </div>
            <div class="listshow">
                <div class="listshowhead">
                    <img src="../../../images/icon_img.png" alt="" v-if="true">
                    <img src="../../../images/icon_text.png" alt="" v-if="false">
                    <img src="../../../images/icon_person.png" alt="" v-if="false">
                    <img src="../../../images/icon_copy.png" alt="" v-if="false">
                    <p style="margin-left: auto;">上移</p>
                    <p>下移</p>
                    <p>置顶</p>
                    <p>删除</p>
                </div>
                <div class="listshowperson">
                    
                </div>
                <div class="listshowcopy">
                    <input class="listshowcopyinput" type="text" placeholder="点此填写引导文案" />
                    <input class="listshowcopyinput1" type="text" placeholder="点此填写引导文案" />
                    <button class="listshowcopybtn">点此一键复制</button>
                </div>
                <div class="listshowimage"></div>
            </div>

            <div class="listshow">
                <div class="listshowhead">
                    <img src="../../../images/icon_img.png" alt="" v-if="true">
                    <img src="../../../images/icon_text.png" alt="" v-if="false">
                    <img src="../../../images/icon_person.png" alt="" v-if="false">
                    <img src="../../../images/icon_copy.png" alt="" v-if="false">
                    <p style="margin-left: auto;">上移</p>
                    <p>下移</p>
                    <p>置顶</p>
                    <p>删除</p>
                </div>
                <div class="listshowperson">
                    
                </div>
                
                <div class="listshowimage">
                    <img src="../../../images/icon_14.png" alt="">
                </div>
            </div> -->
        </div>
        <div class="y_listall_1" v-if="current_text == '1'?'':true">
            <p>点击底部按钮添加内容</p>
        </div>
        <div class="footer">
            <div class="footershow" @click="addcontent(4)">
                <img src="../../../images/icon_img.png" alt="">
                <p>添加图片</p>
            </div>
            <div class="footershow" @click="addcontent(1)">
                <img src="../../../images/icon_text.png" alt="">
                <p>添加文字</p>
            </div>
            <div class="footershow" @click="addcontent(2)">
                <img src="../../../images/icon_person.png" alt="">
                <p>联系方式</p>
            </div>
            <div class="footershow" @click="addcontent(3)">
                <img src="../../../images/icon_copy.png" alt="">
                <p>一键复制框</p>
            </div>
            <div class="footershow" @click="subform">
                <img src="../../../images/icon_sub.png" alt="">
                <p>保存</p>
            </div>
        </div>
   </div>
</template>

<script>
import { Dialog } from "vant";
import useClipboard from "vue-clipboard3";

import axios from 'axios'   // 接口
import {award_info} from '../../api/request.js'   // 抽奖详情

const { toClipboard } = useClipboard();
    export default {
        data(){
            return {
                list:[
                    {
                        type:'1',//类型
                        concent:'',
                        phone:'',
                        oneconcent:'',
                        copycontent:'',
                        image:'',
                        phone_text:'',
                    },
                    {
                        type:'2',//类型
                        concent:'',
                        phone:'',
                        oneconcent:'',
                        copycontent:'',
                        image:'',
                        phone_text:'',
                    },
                    {
                        type:'3',//类型
                        concent:'',
                        phone:'',
                        oneconcent:'',
                        copycontent:'',
                        image:'',
                        phone_text:'',
                    },
                    {
                        type:'4',//类型
                        concent:'',
                        phone:'',
                        oneconcent:'',
                        copycontent:'',
                        image:'',
                        phone_text:'',
                    }
                ],

                addjson:{
                    type:'1',//类型
                    concent:'',
                    phone:'',
                    oneconcent:'',
                    copycontent:'',
                    image:'',
                    phone_text:'',
                },
                imgindex:'',

                contact_jump_index:'',  // 当前联系人的 index
                contact_list_id:'',     // 当前联系人的 id
                contact_list_phone:'',  // 当前联系人的 phone
                current_text:'0',       // 当前内容是否为空

                adspace_id:'0',       // 广告位列表，高级推广 进入传id
                promotion_value:'0',  // 进入传value  0代表正常进入，1为待推送进入

                advanced_push_value:'0', // 0代表正常进入，1为高级推送
            }
        },  
        created(){
            // 获取页面传参 推送类型
            this.contact_list_phone = this.$route.query.contact_list_phone
            this.contact_jump_index = this.$route.query.contact_jump_index
            this.contact_list_id = this.$route.query.contact_list_id

            if(this.$route.query.adspace_id !== undefined){
                this.adspace_id = this.$route.query.adspace_id
                this.promotion_value = this.$route.query.promotion_value
            }
            if(this.$route.query.advanced_push_value !== undefined){
                this.advanced_push_value = this.$route.query.advanced_push_value
            }
        },
        watch:{
          '$route'(val,from){
            this.contact_jump_index = val.query.contact_jump_index
            this.contact_list_phone = val.query.contact_list_phone
            this.contact_list_id = val.query.contact_list_id

            let contact_jump_index = this.contact_jump_index
            let contact_list_phone = this.contact_list_phone
            let contact_list_id = this.contact_list_id
            let list = JSON.parse(JSON.stringify(this.list))
            if(contact_list_phone && contact_jump_index && contact_list_id !== ''){
                list[contact_jump_index].phone_text = contact_list_phone
                list[contact_jump_index].phone = contact_list_id
            }
            
            this.list = list
          }
        },
        mounted(){
            let promotion_value = this.promotion_value
            if(promotion_value == '1'){
                let uplist_list = JSON.parse(sessionStorage.getItem('uplist_list'));
                let uplist = []
                for(let i=0;i<uplist_list.length;i++){
                    if(uplist_list[i].type == 'text'){
                        uplist.push({type:'1',concent: uplist_list[i].content})
                    }
                    if(uplist_list[i].type == 'award_contract_id'){
                        uplist.push({type:'2',phone_text: ''})
                    } 
                    if(uplist_list[i].type == 'text_copy'){
                        uplist.push({type:'3',oneconcent: uplist_list[i].content,copycontent:uplist_list[i].content_copy})
                    }
                    if(uplist_list[i].type == 'img'){
                        uplist.push({type:'4',image: uplist_list[i].content})
                    }
                }
                this.current_text = '1'
                this.list = uplist
                console.log(this.list)
            }else{
                this.list = []
            }
            let contact_jump_index = this.contact_jump_index
            let contact_list_phone = this.contact_list_phone
            let contact_list_id = this.contact_list_id
        },
        methods: {
            addcontent(type){
                console.log(type)
                let addjson = {
                    type:'1',
                    concent:'',
                    phone:'',
                    oneconcent:'',
                    copycontent:'',
                    image:'',
                    phone_text:'',
                }
                addjson.type = type
                this.list.push(addjson)

                this.current_text = '1'

                let scrollElem = this.$refs.scrollDiv; // 对应ref的命名
                console.log(scrollElem,scrollElem.scrollHeight)
                setTimeout(function(){
                  scrollElem.scrollTo({ top:scrollElem.scrollHeight , behavior: 'smooth' });
                },100)
            },

            moveup(index){
                let list = this.list
                let addjosn = JSON.parse(JSON.stringify(list[index]));
                this.list.splice(index,1)
                this.list.splice(index - 1,0,addjosn)
            },

            movedown(index){
                let list = this.list
                let addjosn = JSON.parse(JSON.stringify(list[index]));
                this.list.splice(index,1)
                this.list.splice(index + 1,0,addjosn)
            },

            movetop(index){
                let list = this.list
                let addjosn = JSON.parse(JSON.stringify(list[index]));
                this.list.splice(index,1)
                this.list.splice(0,0,addjosn)
            },

            movedelete(index){
                this.list.splice(index,1)

                if(this.list.length == 0 ){
                    this.current_text = '0'
                }
            },

            subform(){
                if(this.list.length !== 0){
                    Dialog.confirm({
                        message:'确定保存？',
                    })
                    .then(() => {
                        console.log(this.list)
                        let list = this.list
                        let uplist = []
                        let uplist_chun = []
                        for(let i in list){
                            if(list[i].type == '1'){
                                list[i].concent = list[i].concent.replace(/\n/g, '<br/>')
                                list[i].concent = list[i].concent.replace(new RegExp(' ', 'gm'), '&nbsp;')

                                uplist.push({type:'text',content: list[i].concent})
                                uplist_chun.push({type:'text',content: list[i].concent})
                            }
                            if(list[i].type == '2'){
                                uplist.push({type:'award_contract_id',content:'1'})
                                uplist_chun.push({type:'award_contract_id',content:'1'})
                            }
                            if(list[i].type == '3'){
                                uplist.push({type:'text_copy',content_copy: list[i].copycontent,content:list[i].oneconcent})
                                uplist_chun.push({type:'text_copy',content_copy: list[i].copycontent,content:list[i].oneconcent})
                            }
                            if(list[i].type == '4'){
                                uplist.push({type:'img',content: list[i].image})
                                uplist_chun.push({type:'img',content: list[i].image})
                            }
                        }
                        console.log(uplist)

                        let dd = JSON.stringify(uplist)
                        let dd_chun = JSON.stringify(uplist_chun)
                        sessionStorage.setItem("uplist_list",dd)
                        sessionStorage.setItem("uplist_list_chun",dd_chun)

                        let adspace_id = this.adspace_id
                        let promotion_value = this.promotion_value

                        let advanced_push_value = this.advanced_push_value // 高级推判断
                   
                        if(advanced_push_value == '1'){
                            this.$router.push({
                                path:'/advanced_push',
                                    query: {
                                    rich_text:'1',
                                }
                            })
                        }else if(advanced_push_value == '2'){
                            this.$router.push({
                                path:'/advanced_push',
                                    query: {
                                    adspace_id:adspace_id,
                                    promotion_value:promotion_value,
                                    rich_text:'2',
                                }
                            })
                        }else{
                            if(promotion_value == '1'){
                                this.$router.push({
                                    path:'/sweepstakes_settings',
                                        query: {
                                        rich_text:'1',
                                        adspace_id:adspace_id,
                                        promotion_value:promotion_value,
                                    }
                                })
                            }else{
                                this.$router.push({
                                    path:'/sweepstakes_settings',
                                        query: {
                                        rich_text:'1',
                                    }
                                })
                            }
                        }
                    })
                    .catch(() => {
                    })
                }else{
                    this.$message({
                        message:'请添加内容',
                        duration:'2000'
                    })
                }
                
                
            },

            changeimg(index){
                //console.log(index)
                this.imgindex = index
            },

            banner_success(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
                console.log(res,file)
                this.list[this.imgindex].image = res.img 
                // let img = res.img
                // this.img = img
            },
            copytext(context) {
                // //console.log(context)
                // let oInput = document.createElement('input');
                // //赋值给文本框
                // oInput.value = context;
                // console.log(oInput)
                // let e =document.getElementById("awardQqQun1")
                // console.log(e.value)
                // document.body.appendChild(oInput);
                // // 选择对象;
                // e.select(); 
                // // 执行浏览器复制命令
                // document.execCommand("Copy"); 
                // //复制完成删除掉输入框
                // oInput.remove()
                toClipboard(context);
                console.log('复制成功',context)
                this.$message({
                    message:'复制成功！',
                    duration:'2000'
                })
            },
            // 跳转联系人
            contact_jump_two(index){
                return
                let adspace_id = this.adspace_id
                let promotion_value = this.promotion_value

                if(promotion_value == '1'){
                    this.$router.push({
                        path:'/sweepstakes_settings_lxr',
                        query: {
                            contact_jump_index:index,
                            contact_jump_judgment:'1',
                            adspace_id:adspace_id,
                            promotion_value:promotion_value,
                        }
                    })
                }else{
                    this.$router.push({
                        path:'/sweepstakes_settings_lxr',
                        query: {
                            contact_jump_index:index,
                            contact_jump_judgment:'1',
                        }
                    })
                }
                
            },
        },
    }
</script>

<style scoped>
    .footer{
        display: flex;
        height: 1.2rem;
        border-top: 0.01rem solid #F3F3F3;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #ffffff;
    }

    .footer>div{
        flex: 1;
    }

    .footer>div img{
        width: 0.36rem;
        height: 0.36rem;
        margin: 0 auto;
        display: block;
        object-fit: contain;
        padding-top: 0.23rem;
    }

    .footer>div p{
        text-align: center;
        font-size: 0.24rem;
        color: #7F7F7F;
        line-height: 0.6rem;
        display: block;
    }

    .richtextdiv{
        /* min-height: 100vh; */
        padding: 0 0.3rem;
        padding-bottom: 1.3rem;
        padding-top: 0.2rem;
    }

    .listshow{
        padding: 0.2rem 0;
    }

    .listshowhead{
        display: flex;
        align-items: center;
        height: 0.5rem;
        margin-bottom: 0.3rem;
    }

    .listshowhead>img{
        widows: 0.42rem;
        height: 0.42rem;
        object-fit: contain;
    }

    .listshowhead>p{
        font-size: 0.28rem;
        color: #333333;
        border: 0.02rem solid #EEEEEE;
        box-sizing: border-box;
        padding: 0 0.16rem;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 0.5rem;
        border-radius: 0.1rem;
        margin-left: 0.3rem;
    }

    .listshowtext /deep/ textarea{
        width: 100%;
        /* height: 2rem; */
        border-radius: 0.1rem;
        background: #F7F8FA;
        border: 0;
        resize: none;
        padding: 0.3rem;
        line-height: 0.36rem;
        font-size: 0.28rem;
        box-sizing: border-box;
    }
    .listshowpersonshow{
        height: 1rem;
        border-radius: 0.1rem;
        background: #FFFBED;
        padding: 0 0.3rem;
        display: flex;
        align-items: center;
    }

    .listshowpersonshow p{
        font-size: 0.28rem;
        color: #FECE0A;
    }

    .listshowpersonshow p:nth-child(2){
        margin-left: auto;
        margin-right: 0.11rem;
    }

    .listshowcopy{
        width: 100%;
        border: 0.02rem solid #FECE0A;
        border-radius: 0.1rem;
        padding: 0.48rem;
        padding-top: 0;
        box-sizing: border-box;
    }

    .listshowcopyinput{
        height: 1.35rem;
        line-height: 1.35rem;
        text-align: center;
        font-size: 0.3rem;
        width: 100%;
        border: 0;
    }

    .listshowcopyinput::placeholder{
        color:#FECE0A
    }

    .listshowcopyinput1{
        width: 100%;
        height: 1.1rem;
        background: #FFFBED;
        line-height: 1.1rem;
        text-align: center;
        font-size: 0.3rem;
        width: 100%;
        border: 0;
        border-radius: 0.1rem;
    }

    .listshowcopyinput1::placeholder{
        color:#FECE0A
    }

    .listshowcopybtn{
        width: 3.72rem;
        height: 0.86rem;
        background: #FECE0A;
        border-radius: 0.4rem;
        margin: 0 auto;
        margin-top: 0.4rem;
        border: 0;
        display: block;
        text-align: center;
        font-size: 0.3rem;
        color: #333333;
    }

    .listshowimage img{
        width: 100%;
        /* height: 4.2rem; */
        height: auto;
        object-fit: cover;
    }

    .listshowimage /deep/ .el-upload{
        width: 100%;
    }
    .listall{
        width: 100%;
        height: calc(100vh - 1.3rem);
        overflow: scroll;
    }
    .y_listall_1{
        height: 100vh;
        position: absolute;
        width: 100%;
        top: 0px;
        left: 0px;
    }
    .y_listall_1 p{
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100%;
        transform: translate(-50%,-50%);
        text-align: center;
        color: #999;
        font-size: 0.3rem;
    }
</style>