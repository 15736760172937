
<template>
    <div class="y_my_edit_profile_1">
        <div class="y_my_change_phone_one_1">
            <div class="y_my_change_phone_one_3 y_my_change_phone_one_8">
                <div class="y_my_change_phone_one_4">
                    <el-input v-model="input_one" placeholder="请输入新手机号"></el-input>
                </div>
            </div>
            <div class="y_my_change_phone_one_3">
                <div class="y_my_change_phone_one_4">
                    <el-input v-model="input_two" placeholder="请输入短信验证码"></el-input>
                </div>
                <div class="y_my_change_phone_one_5">
                    <div  v-if="show == 0" @click="getcode">获取验证码</div>
                    <div  v-if="show == 1">已发送{{count}}秒</div>
                    <div  v-if="show == 2" @click="getcode">重新获取</div>
                </div>
            </div>
        </div>
        <div class="y_my_change_phone_one_6"></div>
        <div class="y_my_change_phone_one_7" @click="phone_save">
            <p>保存</p>
        </div>
    </div>
</template>
<style scoped>
    .y_my_edit_profile_1{
        min-height: 100vh;
        width: 100%;
        background: #F4F5F7;
    }
    .y_my_change_phone_one_1{
        width: 100%;
        background: #fff;
    }
    .y_my_change_phone_one_2{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1.2rem;
        border-bottom: 0.01rem solid #EDEDED;
        padding: 0rem 0.3rem;
    }
    .y_my_change_phone_one_2 h2{
        font-size: 0.3rem;
        color:#333;
        margin-right: 0.35rem;
    }
    .y_my_change_phone_one_2 p{
        font-size: 0.3rem;
        color: #000;
    }
    .y_my_change_phone_one_3{
        padding: 0rem 0.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.2rem;
    }
    .y_my_change_phone_one_4{
        flex: 1;
    }
    .y_my_change_phone_one_4 /deep/ input{
        background: transparent;
        padding: 0rem;
        height: 1.2rem;
        line-height: 1.2rem;
        color:#333;
        font-size: 0.3rem;
        border-radius: 0rem;
        border: none;
    }
    .y_my_change_phone_one_5{
        width: 2rem;
        text-align: center;
    }
    .y_my_change_phone_one_5 div{
        font-size: 0.3rem;
        color: #FECE0A;
    }
    .y_my_change_phone_one_6{
        width: 100%;
        height: 2.2rem;
        background: transparent;
    }
    .y_my_change_phone_one_7{
        position: fixed;
        bottom: 0.6rem;
        width: calc(100% - 0.5rem);
        left: 0.25rem;
        background: #FECE0A;
        text-align: center;
        height: 1rem;
        line-height: 1rem;
        border-radius: 0.1rem;
        overflow: hidden;
        font-size: 0.32rem;
        color: #333;
    }
    .y_my_change_phone_one_8{
        border-bottom: 0.01rem solid #EDEDED;
    }
</style>
<script>
    import axios from 'axios'   // 接口
    import {ajax_code} from '../../api/request.js'   // 发送验证码
    import {edit_phone} from '../../api/request.js' // 更换手机号

    export default {
        data(){
            return {
                token:'',
                input_one: '',
                input_two:'',

                show: 0,
			    count: '',
			    timer: null,
  
            }
        },  
        components:{

        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
        },
        methods: {
            // 发送验证码 倒计时时间
            getcode(){
                let input_one = this.input_one
                if(input_one.length !== 11){
                    this.$message({
                        message: '请输入正确手机号',
                        duration:'2000'
                    })
                    return
                }
                // 发送验证码
                ajax_code({phone:input_one}).then((res) =>{
                    console.log(res,'发送验证码')
                    if(res.error_code == '0'){
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                        const times = 60; 
	                        if (!this.timer) {
	                        	this.count = times;
	                        	this.show = 1;
	                        	this.timer = setInterval(() => {
	                        		if (this.count > 0 && this.count <= times) {
	                        			this.count--;
	                        		} else {
	                        			this.show = 2;
	                        			clearInterval(this.timer);
	                        			this.timer = null;
	                        		}
	                        }, 1000)
	                    }
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
	        	
	        },
            // 更换手机号
            phone_save(){
                let token = this.token
                let input_one = this.input_one
                let input_two = this.input_two

                if(input_one == '' || input_two == ''){
                    this.$message({
                        message: '请输入',
                        duration:'2000'
                    })
                    return
                }
                edit_phone({token:token,phone:input_one,code:input_two}).then((res) =>{
                    console.log(res,'更换手机号')
                    if(res.error_code == '0'){
                        this.$message({
                            message: '保存成功！',
                            duration:'2000'
                        })
                        var that = this;
                        setTimeout(function () {
                            that.$router.push({path:'/'}) 
                        },1000);
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            }
        },
    }

</script>