
<template>
    <div class="y_user_comm_1">
        <div class="y_user_one_1">
            <div class="y_user_one_2" v-for="(item,index) in tips_two_list" :key="index" @click="user_two_click(item.id)">
                <h2>{{item.title}}</h2>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .y_user_comm_1{
        padding: 0.2rem;
    }
    .y_user_one_1{
        width: 100%;
    }
    .y_user_one_2{
        width: 100%;
        height: 1rem;
        border-bottom: 0.01rem solid #eee;
        margin-bottom: 0.2rem;
    }
    .y_user_one_2 h2{
        font-size: 0.3rem;
        color: #333;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
</style>
<script>
    import axios from 'axios'   // 接口
    import {strategy_list} from '../../api/request.js' // 使用攻略列表

    export default {
        data(){
            return {
                tips:'', // 传id
                tips_two_list:'',  // 列表
            }
        },  
        components:{

        },
        created(){
            // 获取页面传参 推送类型
            this.tips = this.$route.query.tips;
            let tips = this.$route.query.tips;
            // 使用攻略详情
            strategy_list({}).then((res) =>{
                console.log(res,'使用攻略列表')
                let tips_two_list = res.data
                for(let i=0;i<tips_two_list.length;i++){
                    if(tips_two_list[i].id == tips){
                        this.tips_two_list = tips_two_list[i].strategy
                    }
                }
            })

        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
        },
        methods: {
            // 跳使用攻略 详情
            user_two_click(value){
                let tips_two_list = this.tips_two_list
                for(let i=0;i<tips_two_list.length;i++){
                    if(tips_two_list[i].id == value){
                        if(tips_two_list[i].url == ''){
                            this.$router.push({
                                path:'/usage_tips_xq',
                                query: {
                                    tips_two:value
                                }
                            })
                        }else{
                            window.location.href= tips_two_list[i].url
                        }
                        
                    }
                }
            }
        },
    }
</script>