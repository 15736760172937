
<template>
    <div class="y_my_edit_profile_1">
        <div class="y_my_personal_one_1">
            <div class="y_my_personal_one_2">
                <h2>【个人认证】</h2>
                <p>提示语提示语提示语提示语提示语提示语提示语</p>
            </div>
        </div>
        <div class="y_my_personal_one_3"> 
            <div class="y_my_personal_two_1">
                <div class="y_my_personal_two_2">
                    <div class="y_my_personal_two_3">
                        <el-upload class="avatar-uploader" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :show-file-list="false" :on-success="portrait_success" :before-upload="portrait_settings">
                            <img src="../../../images/icon_96.png" v-if="portrait_img == ''? true : false " alt="">
                            <img :src="''+portrait_img"  v-if="portrait_img == ''? false:true"  alt="">
                        </el-upload>
                    </div>
                    <h2>身份证人像面</h2>
                </div>
                <div class="y_my_personal_two_2">
                    <div class="y_my_personal_two_3">
                        <el-upload class="avatar-uploader" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :show-file-list="false" :on-success="national_success" :before-upload="national_settings">
                            <img src="../../../images/icon_97.png" v-if="national_img == ''? true : false " alt="">
                            <img :src="''+national_img"  v-if="national_img == ''? false:true"  alt="">
                        </el-upload>
                    </div>
                    <h2>身份证国徽面</h2>
                </div>
            </div>
        </div>

        <div class="y_my_change_phone_one_6"></div>
        <div class="y_my_change_phone_one_7" @click="submit_certification">
            <p>提交认证</p>
        </div>
    </div>
</template>

<style scoped>
    .y_my_edit_profile_1{
        min-height: 100vh;
        width: 100%;
        background: #F4F5F7;
    }
    .y_my_personal_one_1{
        padding: 0.4rem 0.25rem 0rem;
    }
    .y_my_personal_one_2{
        min-height: 2.2rem;
        background: #fff;
        border-radius: 0.1rem;
        overflow: hidden;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        padding: 0rem 0.4rem;
        box-shadow: 0 0 0.2rem 0.05rem #ccc;
    }
    .y_my_personal_one_2 h2{
        color: #333;
        font-size: 0.32rem;
        font-weight: 600;
        width: 100%;
    }
    .y_my_personal_one_2 p{
        width: 100%;
        margin-top: 0.2rem;
        color: #999;
        font-size: 0.28rem;
    }
    .y_my_personal_one_3{
        width: 100%;
        margin-top: 0.4rem;
        background: #fff;
    }
    .y_my_personal_one_4{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.2rem;
        padding: 0rem 0.3rem;
        border-bottom: 0.01rem solid #EDEDED;
    }
    .y_my_personal_one_5{
        width: 2rem;
    }
    .y_my_personal_one_5 h2{
        font-size: 0.3rem;
        color: #999;
    }
    .y_my_personal_one_6{
        flex:1;
    }
    .y_my_personal_one_6 /deep/ input{
        width: 100%;
        height: 1.2rem;
        border-radius: 0rem;
        border:none;
        background: transparent;
        line-height: 1.2rem;
        font-size: 0.3rem;
        color: #333;
        text-align: right;
    }

    .y_my_change_phone_one_6{
        width: 100%;
        height: 2.2rem;
        background: transparent;
    }
    .y_my_change_phone_one_7{
        position: fixed;
        bottom: 0.6rem;
        width: calc(100% - 0.5rem);
        left: 0.25rem;
        background: #FECE0A;
        text-align: center;
        height: 1rem;
        line-height: 1rem;
        border-radius: 0.1rem; 
        overflow: hidden;
        font-size: 0.32rem;
        color: #333;
    }

    /* 【个人认证】 2 */
    .y_my_personal_two_1{
        padding: 0.5rem 0.8rem 0rem;
    }
    .y_my_personal_two_2{
        width: 100%;
        padding-bottom: 0.8rem;
    }
    .y_my_personal_two_3{
        width: 100%;
        height: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }
    .y_my_personal_two_3 img{
        width: 100%;
        object-fit: cover;
        height: 100%;
    }
    .y_my_personal_two_3 /deep/ img{
        width: 100%;
        object-fit: cover;

    }
    .y_my_personal_two_2 h2{
        margin-top: 0.3rem;
        width: 100%;
        text-align: center;
        color:#999;
        font-size: 0.28rem;
    }
</style>
<script>
    import axios from 'axios'   // 接口
    import {edit_auth} from '../../api/request.js'   // 申请认证
    
    export default {
        data(){
            return {
                token:'',
                name:'',
                id_card:'',
                portrait_img:'',
                national_img:'',
            }
        },  
        components:{

        },
        created(){
            // 获取页面传参 推送类型
            this.name = this.$route.query.name;
            this.id_card = this.$route.query.id_card
        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
        },
        methods: {
           //  人像 上传成功
           portrait_success(res, file) {
                console.log(res,file)
                let portrait_img = res.img
                this.portrait_img = portrait_img
            },
            //  人像 上传设置
            portrait_settings(file) {
                console.log(file)
            },
            //  国徽 上传成功
            national_success(res, file) {
                console.log(res,file)
                let national_img = res.img
                this.national_img = national_img
            },
            //  国徽 上传设置
            national_settings(file) {
                console.log(file)
            },

            // 提交认证
            submit_certification(){
                let token = this.token
                let business_auth_type = 'person'
                let realname = this.name
                let sfz = this.id_card
                let sfz_img_1 = this.portrait_img
                let sfz_img_2 = this.national_img

                if(sfz_img_1 == '' || sfz_img_2 == ''){
                    this.$message({
                        message:'请上传图片',
                        duration:'1000'
                    })
                    return
                }
                edit_auth({token:token,business_auth_type:business_auth_type,realname:realname,sfz:sfz,sfz_img_1:sfz_img_1,sfz_img_2:sfz_img_2}).then((res) =>{
                    console.log(res,'申请认证')
                    if(res.error_code == '0'){
                        this.$message({
                            message:'提交成功',
                            duration:'1000'
                        })
                        var that = this;
                        setTimeout(function () {
                            that.$router.push({path:'/'}) 
                        },1000);
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'1000'
                        })
                    }
                })
            },
        },
    }

</script>