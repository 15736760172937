<template>
    <div class="y_index">
          <!-- <div class='y_index_1'>
            <router-link class='y_index_2' to='/'>如何推送？</router-link>
            <router-link class='y_index_2' to='/'>如何收费？</router-link>
        </div> -->
        <push_charges></push_charges>
        <div class="y_recruit_1">
          <img src="../../../images/img_3.png" alt="">
        </div>
    </div>
    
    <div class="y_user_detail_1">
        <div class="y_user_detail_2">
            <div class="y_user_detail_3">
                <div class="y_user_detail_4">
                    <div class="y_user_detail_5">
                        <h2>本科学校</h2>
                    </div>
                    <div class="y_user_detail_6">
                        <h2>用户总数</h2>
                        <p>{{undergraduate_total}}</p>
                    </div>
                </div>
                <div class="y_user_detail_7" v-for="(undergraduate_list,index) in undergraduate_lists" :key="index">
                    <div class="y_user_detail_8">
                        <div class="y_user_detail_5">
                            <h2>{{undergraduate_list.title}}</h2>
                        </div>
                        <div class="y_user_detail_9">
                            <h2>用户总数</h2>
                            <p>{{undergraduate_list.school_user_number}}</p>
                        </div>
                    </div>
                    <div class="y_user_detail_10">
                        <div class="y_user_detail_11" v-for="(ele,i) in undergraduate_list.grade" :key="'a'+i">
                            <h2>{{ele.grade_title}}</h2>
                            <p>{{ele.grade_user_number}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="y_user_detail_3">
                <div class="y_user_detail_4">
                    <div class="y_user_detail_5">
                        <h2>专科学校</h2>
                    </div>
                    <div class="y_user_detail_6">
                        <h2>用户总数</h2>
                        <p>{{specialty_total}}</p>
                    </div>
                </div>
                <div class="y_user_detail_7" v-for="(specialty_list,index) in specialty_lists" :key="index">
                    <div class="y_user_detail_8">
                        <div class="y_user_detail_5">
                            <h2>{{specialty_list.title}}</h2>
                        </div>
                        <div class="y_user_detail_9">
                            <h2>用户总数</h2>
                            <p>{{specialty_list.school_user_number}}</p>
                        </div>
                    </div>
                    <div class="y_user_detail_10">
                        <div class="y_user_detail_11 y_user_detail_12" v-for="(ele,i) in specialty_list.grade" :key="'a'+i">
                            <h2>{{ele.grade_title}}</h2>
                            <p>{{ele.grade_user_number}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <kefu></kefu>
</template>
<style scoped>
    .y_user_detail_1{
        width: 100%;
        background: #fff;
    }
    .y_user_detail_2{
        padding: 0.3rem 0.25rem;
    }
    .y_user_detail_3{
        padding: 0rem 0.2rem 0.1rem;
        background: #FECE0A;
        border-radius: 0.1rem;
        margin-bottom: 0.3rem;
    }
    .y_user_detail_4{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1rem;
    }
    .y_user_detail_5 h2{
        font-size: 0.32rem;
        color: #333;
        font-weight: 600;
    }
    .y_user_detail_6{
        width: 1.8rem;
        height: 0.6rem;
        border-radius: 0.1rem;
        background: #FFFBED;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .y_user_detail_6 h2{
        font-size: 0.24rem;
        color: #999;
    }
    .y_user_detail_6 p{
        color: #FECE0A;
        font-size: 0.28rem;
        margin-left: 0.1rem;
    }
    .y_user_detail_7{
        width: 100%;
        border-radius: 0.1rem;
        background: #fff;
        overflow: hidden;
        margin-bottom: 0.2rem;
    }
    .y_user_detail_8{
        padding: 0rem 0.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #FFFBED;
        height: 0.9rem;
    }
    .y_user_detail_9{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .y_user_detail_9 h2{
        font-size: 0.28rem;
        color: #999;
    }
    .y_user_detail_9 p{
        color: #FECE0A;
        font-size: 0.28rem;
        margin-left: 0.13rem;
    }
    .y_user_detail_10{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

    }
    .y_user_detail_11{
        width: 25%;
        height: 1.4rem;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
    }
    .y_user_detail_11 h2{
        width: 100%;
        text-align: center;
        font-size: 0.26rem;
        color: #333;
    }
    .y_user_detail_11 p{
        color:#FECE0A;
        font-size: 0.36rem;
        width: 100%;
        text-align: center;
    }
    .y_user_detail_12{
        flex: 1;
    }
</style>
<script>
    import kefu from '../../components/kefu/kefu'
    import push_charges from '../../components/push_charges/push_charges'

    import axios from 'axios'   // 接口
    import {user_number} from '../../api/request.js'   // 各个学校用户数量
    
    export default {
        data(){
            return {
                undergraduate_lists:'',  // 本科学校
                specialty_lists:'',      // 专科学校
                undergraduate_total:'', // 本科总数
                specialty_total:'',     // 专科总数

            }
        },  
        components:{
            kefu,
            push_charges,
        },
        mounted(){
            let token = this.$cookies.get('token')
            let is_city = sessionStorage.getItem("city_id_chun",is_city)

            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token

            user_number({city_id:is_city}).then((res) =>{
                console.log(res,'各个学校用户数量')
                this.undergraduate_lists = res.school_undergraduate  // 本科学校
                this.specialty_lists = res.school_specialty      // 专科学校
                this.undergraduate_total = res.user_number_undergraduate // 本科总数
                this.specialty_total = res.user_number_specialty   // 专科总数
            })
        },
        methods: {
            
        },
    }
</script>