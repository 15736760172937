<template>

<van-popup position="bottom">
  <van-picker :columns="columns" @confirm="onConfirm_eight_point_block" @cancel="showPicker_eight_point_none"  />
</van-popup>
</template>
<style scoped>
    *{
        font-size: 0.3rem;
        padding: 0rem;
        margin: 0rem;
        line-height: 0.6rem;
    }
    .van-picker__cancel{
        font-size: 0.3rem !important;
    }
    .van-picker .van-haptics-feedback{
        background: #000;
    }
    .van-ellipsis{
        font-size: 0.3rem;
    }
</style>
<script>
  import { ref } from 'vue';
    export default {
        
     setup() {
    const result_point = ref('');
    const columns = ['杭州', '宁波', '温州', '嘉兴', '湖州'];
    const onConfirm_eight_point_block = (value) => {
      result_point.value = value;
      console.log(result_point.value)
    };
    const showPicker_eight_point_none = (value) => {
        console.log('quxiao')
    };
    return {
      result_point,
      columns,
      onConfirm_eight_point_block,
      showPicker_eight_point_none,
    };
  },
};
</script>
