<style>
    .el-input__suffix{ 
        display: none !important;
    }
    .el-icon svg{
        width: 0.3rem !important;
        height: 0.3rem !important;
    }
    .el-select-dropdown__list{
        margin: 0.1rem 0!important;
    }
    .el-select-dropdown__item{
        font-size: 0.26rem !important;
        line-height: 0.5rem !important;
        padding: 0rem 0.1rem !important;
        height: 0.5rem !important;
    }
    .el-select-dropdown__item span{
        font-size: 0.26rem !important;
        line-height: 0.4rem !important;
        padding: 0rem 0.1rem !important;
    }
    .el-input--suffix .el-input__inner{
        padding: 0rem !important;
        height: 0.8rem !important;
    }
</style>
<template>
    <div class="y_index">
        <!-- <div class='y_index_1'>
            <router-link class='y_index_2' to='/'>如何推送？</router-link>
            <router-link class='y_index_2' to='/'>如何收费？</router-link>
        </div> -->
        <push_charges></push_charges>
        <div class="y_recruit_1">
          <img src="../../../images/img_3.png" alt="">
        </div>
        
        <div class="y_topics_push_1">
            <div class="y_topics_push_2">
                <div class="y_topics_push_3">
                    <div class="y_topics_push_4">
                        <img src="../../../images/icon_18.png" alt="">
                    </div>
                    <div class="y_topics_push_5">
                        <el-input v-model="search_input" placeholder="请输入内容"></el-input>
                    </div>
                </div>
                <div class="y_topics_push_6" @click="search_dete">
                    <p>查找</p>
                </div>
            </div>
            <div class="y_topics_push_7">
                <div class="y_topics_push_8">
                    <el-select v-model="select_value_one" placeholder="全部高校" @change="school_select">
                        <el-option  label="全部高校" value="" ></el-option>
                        <el-option  v-for="item in select_options_1" :key="item.school_id" :label="item.title" :value="item.school_id" ></el-option>
                    </el-select>
                    <img src="../../../images/icon_30.png" alt="">
                </div>
                <div class="y_topics_push_8" @click="province_city_show()" v-if="index_index == '3'? false : true">
                    <p v-show="city_name_chun == ''? '':true">{{province_name_chun+'-'+city_name_chun}}</p>
                    <p v-show="city_name_chun == ''? true:''">选择{{ index_index == '1'?'话题':(index_index == '2'?'小组':(index_index == '3' ?'活动':''))}}</p>
                    <img src="../../../images/icon_30.png" alt="">
                </div>
                <div class="y_topics_push_8" >
                    <el-select v-model="select_value_three" placeholder="全部类型" @change="school_or_city" v-if="index_index == '1' ? true:false">
                        <!-- <el-option label="全部类型" value=""></el-option> -->
                        <el-option  v-for="item in select_options_3_1"  :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <el-select v-model="select_value_three" placeholder="全部类型" @change="school_or_city" v-if="index_index == '2' ? true:false">
                        <!-- <el-option label="全部类型" value=""></el-option> -->
                        <el-option  v-for="item in select_options_3_2"  :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <el-select v-model="select_value_three" placeholder="全部类型" @change="school_or_city" v-if="index_index == '3' ? true:false">
                        <!-- <el-option label="全部类型" value=""></el-option> -->
                        <el-option  v-for="item in select_options_3_3"  :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <img src="../../../images/icon_30.png" alt="">
                </div>
                <div class="y_topics_push_8 y_topics_push_8_hot">
                    <el-select v-model="select_value_four" placeholder="热门" @change="hot_day">
                        <el-option  v-for="item in select_options_4" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <img src="../../../images/icon_30.png" alt="">
                    <div class="y_hot_day_1">热门</div>
                </div>
            </div>
            <div class="y_topics_push_9" @click="select_all()">
                <div class="y_topics_push_10">
                    <div class="y_topics_push_11">
                        <h2>可多选</h2>
                    </div>
                    <div class="y_topics_push_12">
                        <p>已选<span>{{selected_num}}</span>个</p>
                    </div>
                </div>
                <div class="y_topics_push_13">
                    <div class="y_topics_push_14">
                        <p>全选</p>
                    </div>
                    <div class="y_topics_push_15" :class="is_all == '0' ? '':'y_topics_push_15_active'">
                        <img src="../../../images/icon_20.png" alt="">
                        <img src="../../../images/icon_19.png" alt="">
                    </div>
                </div>
            </div>
            <div class="y_topics_push_16">
                <div class="y_topics_push_17" v-for="item in list_select" :key="item.index" :data-index="item.index" @click="list_click(item.index)">
                    <div class="y_topics_push_18">
                        <div class="y_topics_push_19">
                            <div class="y_topics_push_21 y_topics_push_20">
                                <h2>{{item.school_title}}</h2>
                            </div>
                            <div class="y_topics_push_21">
                                <h2>{{item.topics}}{{ index_index == '1'?'话题':(index_index == '2'?'小组':(index_index == '3' ?'活动':''))}}</h2>
                            </div>
                        </div>
                        <div class="y_topics_push_22" :class="item.select == '0'? '':'y_topics_push_22_active'">
                            <img src="../../../images/icon_20.png" alt="">
                            <img src="../../../images/icon_19.png" alt="">
                        </div>
                    </div>
                    <div class="y_topics_push_23" :class="item.select == '0'? '':'y_topics_push_23_active'">
                        <div class="y_topics_push_24">
                            <div class="y_topics_push_25">
                                <img src="../../../images/icon_27.png" alt="">
                            </div>
                            <div class="y_topics_push_26">
                                <h2>{{item.title}}</h2>
                            </div>
                        </div>
                        <div class="y_topics_push_27">
                            <div class="y_topics_push_28">
                                <div class="y_topics_push_29">
                                    <p>{{item.brief}}</p>
                                </div>
                                <div class="y_topics_push_30">
                                    <div class="y_topics_push_31">
                                        <img src="../../../images/icon_29.png" alt="">
                                        <p v-if="sort_by_value == 'hot_number'? true:''">{{item.hot_number}}</p>
                                        <p v-if="sort_by_value == 'hot_number_3_days'? true:''">{{item.hot_number_3_days}}</p>
                                        <p v-if="sort_by_value == 'hot_number_7_days'? true:''">{{item.hot_number_7_days}}</p>
                                        <p v-if="sort_by_value == 'hot_number_24_hours'? true:''">{{item.hot_number_24_hours}}</p>
                                        <p v-if="sort_by_value == 'hot_number_30_days'? true:''">{{item.hot_number_30_days}}</p>
                                    </div>
                                    <div class="y_topics_push_32">
                                        <p v-if="index_index == '1' ? true:false">{{item.discuss_user_number}}人在讨论</p>
                                        <p v-if="index_index == '2' ? true:false">组员{{item.joiner_number}}人</p>
                                        <p v-if="index_index == '3' ? true:false">{{item.joiner_number}}人参与</p>
                                    </div>
                                    <div class="y_topics_push_32">
                                        <p v-if="index_index == '1' ? true:false">{{item.view_number}}阅读</p>
                                        <p v-if="index_index == '2' ? true:false">{{item.blog_number}}个帖子</p>
                                        <p v-if="index_index == '3' ? true:false">浏览量{{item.view_number}}</p>
                                    </div>
                                </div>
                                <div class="y_topics_push_30_xg_1"  v-if="index_index == '3' ? true:false">
                                    <div class="y_topics_push_30_xg_2">
                                        <img src="../../../images/icon_103.png" alt="">
                                    </div>
                                    <div class="y_topics_push_30_xg_3">
                                        <p>{{item.starttime}}~{{item.endtime}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="y_topics_push_33" v-if="item.img == undefined ? false : true">
                                <img :src="''+item.img" alt="">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="y_page_1">
                    <el-pagination background :pager-count="10" :current-page="page" layout="prev, pager, next" @current-change="handleCurrentChange" :hide-on-single-page="true" :total="count"></el-pagination>
                </div>
            </div>

            <div class="y_topics_dian"></div>
            <div class='y_recruit_foot_1'>
                <div class='y_recruit_foot_2'>
                    <div class='y_recruit_foot_3' @click="previous_step">
                        <p>返回</p>
                    </div>
                    <div class="y_topics_push_12" @click="pop_show()">
                        <p>共选<span>{{selected_num}}</span>个</p>
                    </div>
                </div>
                <div class='y_recruit_foot_7' @click="next_step">
                    <p>下一步(1/3)</p>
                </div>
            </div>

        </div>

        <!-- 省市选择 弹窗 -->
        <!-- <div class="y_province_city_1" :class="province_city_value == '0'?'':'y_masking_block'">
            <div class="y_province_city_6">
                <div class="y_province_city_2">
                    <div class="y_province_city_3" @click="province_city_hide()">
                        <img src="../../../images/icon_16.png" alt="">
                    </div>
                    <div class="y_province_city_4">
                        <h2>选择话题</h2>
                    </div>
                    <div class="y_province_city_5" @click="province_city_dete()">
                        <p>确定</p>
                    </div>
                </div>
            </div>
            <div class="y_province_city_7">
                <div class="y_province_city_9">
                    <div class="y_province_city_8" :class="province.is == '0'?'':'y_province_city_8_active'" v-for="(province,index) in province_list" :key="index" @click="province_data(province.general_type_id)">
                        <p>{{province.title}}</p>
                    </div>
                </div>
                <div class="y_province_city_9">
                    <div class="y_province_city_8" :class="city_list == ''?'y_display_none':(city.is == '0'?'':'y_province_city_8_active')" v-for="(city,index) in city_list" :key="index" @click="city_data(city.general_type_id)">
                        <p>{{city.title}}</p>
                    </div>
                </div>
            </div>
        </div> -->

        <div class='y_pop_three_1' :class="province_city_value == '0'?'':'y_masking_block'">
          <div class='y_pop_six_2'>
            <div class='y_pop_six_3' @click="province_city_hide()">
              <img src="../../../images/icon_16.png" alt="">
            </div>
            <div class='y_pop_six_4'>
              <h2>全部类型</h2>
            </div>
            <div class='y_pop_six_5' @click="province_city_dete()">
              <p>确定</p>
            </div>
          </div>
          <div class='y_pop_three_2'>
            <div class='y_pop_three_6'>
                <p :class="province_list_all == '0'?'':'y_pop_three_left_active'" @click="province_data_all()">全部类型</p>
                <p :class='province.is == "0"? "":"y_pop_three_left_active"' v-for="(province,index) in province_list" :key="index" @click="province_data(province.general_type_id)">{{province.title}}</p>
            </div>
            <div class='y_pop_three_7'>
              <p :class="city_list == ''?'y_display_none':(city.is == '0'?'':'y_province_city_8_active')" v-for="(city,index) in city_list" :key="index" @click="city_data(city.general_type_id)">{{city.title}}</p>
            </div>
          </div>
        </div>

        <!-- 公共弹窗   遮罩层 -->
        <div class='y_pop_masking' :class='pop_masking == 0 ? "":"y_masking_block"'></div>
        <!-- 展示弹窗 -->
        <div class="y_topics_pop_1" :class="pop_display == '0'? 'y_display_none':''">
            <div class="y_topics_pop_2">
                <div class="y_topics_push_12">
                    <p>共选<span>{{selected_num}}</span>个</p>
                </div>
                <div class="y_topics_pop_3" @click="pop_hide()">
                    <p>返回</p>
                </div>
            </div>
            <div class="y_topics_pop_4" v-if="type_name == 'topic' ?true:false">
                <div class="y_topics_push_17" :class="item.select == '0'?'y_display_none':''" v-for="item in selected_list_id" :key="item.index" :data-index="item.index" @click="select_list_click(item.topic_id)">
                    <div class="y_topics_push_18">
                        <div class="y_topics_push_19">
                            <div class="y_topics_push_21 y_topics_push_20">
                                <h2>{{item.school_title}}</h2>
                            </div>
                            <div class="y_topics_push_21">
                                <h2>{{item.topics}}{{ index_index == '1'?'话题':(index_index == '2'?'小组':(index_index == '3' ?'活动':''))}}</h2>
                            </div>
                        </div>
                        <div class="y_topics_push_22" :class="item.select == '0'? '':'y_topics_push_22_active'">
                            <img src="../../../images/icon_20.png" alt="">
                            <img src="../../../images/icon_19.png" alt="">
                        </div>
                    </div>
                    <div class="y_topics_push_23" :class="item.select == '0'? '':'y_topics_push_23_active'">
                        <div class="y_topics_push_24">
                            <div class="y_topics_push_25">
                                <img src="../../../images/icon_27.png" alt="">
                            </div>
                            <div class="y_topics_push_26">
                                <h2>{{item.title}}</h2>
                            </div>
                        </div>
                        <div class="y_topics_push_27">
                            <div class="y_topics_push_28">
                                <div class="y_topics_push_29">
                                    <p>{{item.brief}}</p>
                                </div>
                                <div class="y_topics_push_30">
                                    <div class="y_topics_push_31">
                                        <img src="../../../images/icon_29.png" alt="">
                                        <p v-if="sort_by_value == 'hot_number'? true:''">{{item.hot_number}}</p>
                                        <p v-if="sort_by_value == 'hot_number_3_days'? true:''">{{item.hot_number_3_days}}</p>
                                        <p v-if="sort_by_value == 'hot_number_7_days'? true:''">{{item.hot_number_7_days}}</p>
                                        <p v-if="sort_by_value == 'hot_number_24_hours'? true:''">{{item.hot_number_24_hours}}</p>
                                        <p v-if="sort_by_value == 'hot_number_30_days'? true:''">{{item.hot_number_30_days}}</p>
                                    </div>
                                    <div class="y_topics_push_32">
                                        <p v-if="index_index == '1' ? true:false">{{item.discuss_user_number}}人在讨论</p>
                                        <p v-if="index_index == '2' ? true:false">组员{{item.joiner_number}}人</p>
                                        <p v-if="index_index == '3' ? true:false">{{item.joiner_number}}人参与</p>
                                    </div>
                                    <div class="y_topics_push_32">
                                        <p v-if="index_index == '1' ? true:false">{{item.view_number}}阅读</p>
                                        <p v-if="index_index == '2' ? true:false">{{item.blog_number}}个帖子</p>
                                        <p v-if="index_index == '3' ? true:false">浏览量{{item.view_number}}</p>
                                    </div>
                                </div>
                                <div class="y_topics_push_30_xg_1"  v-if="index_index == '3' ? true:false">
                                    <div class="y_topics_push_30_xg_2">
                                        <img src="../../../images/icon_103.png" alt="">
                                    </div>
                                    <div class="y_topics_push_30_xg_3">
                                        <p>{{item.starttime}}~{{item.endtime}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="y_topics_push_33" v-if="item.img == ''? false : true">
                                <img :src="''+item.img" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="y_topics_pop_4" v-if="type_name == 'group' ?true:false">
                <div class="y_topics_push_17" :class="item.select == '0'?'y_display_none':''" v-for="item in selected_list_id" :key="item.index" :data-index="item.index" @click="select_list_click(item.group_id)">
                    <div class="y_topics_push_18">
                        <div class="y_topics_push_19">
                            <div class="y_topics_push_21 y_topics_push_20">
                                <h2>{{item.school_title}}</h2>
                            </div>
                            <div class="y_topics_push_21">
                                <h2>{{item.topics}}{{ index_index == '1'?'话题':(index_index == '2'?'小组':(index_index == '3' ?'活动':''))}}</h2>
                            </div>
                        </div>
                        <div class="y_topics_push_22" :class="item.select == '0'? '':'y_topics_push_22_active'">
                            <img src="../../../images/icon_20.png" alt="">
                            <img src="../../../images/icon_19.png" alt="">
                        </div>
                    </div>
                    <div class="y_topics_push_23" :class="item.select == '0'? '':'y_topics_push_23_active'">
                        <div class="y_topics_push_24">
                            <div class="y_topics_push_25">
                                <img src="../../../images/icon_27.png" alt="">
                            </div>
                            <div class="y_topics_push_26">
                                <h2>{{item.title}}</h2>
                            </div>
                        </div>
                        <div class="y_topics_push_27">
                            <div class="y_topics_push_28">
                                <div class="y_topics_push_29">
                                    <p>{{item.brief}}</p>
                                </div>
                                <div class="y_topics_push_30">
                                    <div class="y_topics_push_31">
                                        <img src="../../../images/icon_29.png" alt="">
                                        <p v-if="sort_by_value == 'hot_number'? true:''">{{item.hot_number}}</p>
                                        <p v-if="sort_by_value == 'hot_number_3_days'? true:''">{{item.hot_number_3_days}}</p>
                                        <p v-if="sort_by_value == 'hot_number_7_days'? true:''">{{item.hot_number_7_days}}</p>
                                        <p v-if="sort_by_value == 'hot_number_24_hours'? true:''">{{item.hot_number_24_hours}}</p>
                                        <p v-if="sort_by_value == 'hot_number_30_days'? true:''">{{item.hot_number_30_days}}</p>
                                    </div>
                                    <div class="y_topics_push_32">
                                        <p v-if="index_index == '1' ? true:false">{{item.discuss_user_number}}人在讨论</p>
                                        <p v-if="index_index == '2' ? true:false">组员{{item.joiner_number}}人</p>
                                        <p v-if="index_index == '3' ? true:false">{{item.joiner_number}}人参与</p>
                                    </div>
                                    <div class="y_topics_push_32">
                                        <p v-if="index_index == '1' ? true:false">{{item.view_number}}阅读</p>
                                        <p v-if="index_index == '2' ? true:false">{{item.blog_number}}个帖子</p>
                                        <p v-if="index_index == '3' ? true:false">浏览量{{item.view_number}}</p>
                                    </div>
                                </div>
                                <div class="y_topics_push_30_xg_1"  v-if="index_index == '3' ? true:false">
                                    <div class="y_topics_push_30_xg_2">
                                        <img src="../../../images/icon_103.png" alt="">
                                    </div>
                                    <div class="y_topics_push_30_xg_3">
                                        <p>{{item.starttime}}~{{item.endtime}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="y_topics_push_33" v-if="item.img == ''? false : true">
                                <img :src="''+item.img" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="y_topics_pop_4" v-if="type_name == 'activity' ?true:false">
                <div class="y_topics_push_17" :class="item.select == '0'?'y_display_none':''" v-for="item in selected_list_id" :key="item.index" :data-index="item.index" @click="select_list_click(item.activity_id)">
                    <div class="y_topics_push_18">
                        <div class="y_topics_push_19">
                            <div class="y_topics_push_21 y_topics_push_20">
                                <h2>{{item.school_title}}</h2>
                            </div>
                            <div class="y_topics_push_21">
                                <h2>{{item.topics}}{{ index_index == '1'?'话题':(index_index == '2'?'小组':(index_index == '3' ?'活动':''))}}</h2>
                            </div>
                        </div>
                        <div class="y_topics_push_22" :class="item.select == '0'? '':'y_topics_push_22_active'">
                            <img src="../../../images/icon_20.png" alt="">
                            <img src="../../../images/icon_19.png" alt="">
                        </div>
                    </div>
                    <div class="y_topics_push_23" :class="item.select == '0'? '':'y_topics_push_23_active'">
                        <div class="y_topics_push_24">
                            <div class="y_topics_push_25">
                                <img src="../../../images/icon_27.png" alt="">
                            </div>
                            <div class="y_topics_push_26">
                                <h2>{{item.title}}</h2>
                            </div>
                        </div>
                        <div class="y_topics_push_27">
                            <div class="y_topics_push_28">
                                <div class="y_topics_push_29">
                                    <p>{{item.brief}}</p>
                                </div>
                                <div class="y_topics_push_30">
                                    <div class="y_topics_push_31">
                                        <img src="../../../images/icon_29.png" alt="">
                                        <p v-if="sort_by_value == 'hot_number'? true:''">{{item.hot_number}}</p>
                                        <p v-if="sort_by_value == 'hot_number_3_days'? true:''">{{item.hot_number_3_days}}</p>
                                        <p v-if="sort_by_value == 'hot_number_7_days'? true:''">{{item.hot_number_7_days}}</p>
                                        <p v-if="sort_by_value == 'hot_number_24_hours'? true:''">{{item.hot_number_24_hours}}</p>
                                        <p v-if="sort_by_value == 'hot_number_30_days'? true:''">{{item.hot_number_30_days}}</p>
                                    </div>
                                    <div class="y_topics_push_32">
                                        <p v-if="index_index == '1' ? true:false">{{item.discuss_user_number}}人在讨论</p>
                                        <p v-if="index_index == '2' ? true:false">组员{{item.joiner_number}}人</p>
                                        <p v-if="index_index == '3' ? true:false">{{item.joiner_number}}人参与</p>
                                    </div>
                                    <div class="y_topics_push_32">
                                        <p v-if="index_index == '1' ? true:false">{{item.view_number}}阅读</p>
                                        <p v-if="index_index == '2' ? true:false">{{item.blog_number}}个帖子</p>
                                        <p v-if="index_index == '3' ? true:false">浏览量{{item.view_number}}</p>
                                    </div>
                                </div>
                                <div class="y_topics_push_30_xg_1"  v-if="index_index == '3' ? true:false">
                                    <div class="y_topics_push_30_xg_2">
                                        <img src="../../../images/icon_103.png" alt="">
                                    </div>
                                    <div class="y_topics_push_30_xg_3">
                                        <p>{{item.starttime}}~{{item.endtime}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="y_topics_push_33" v-if="item.img == ''? false : true">
                                <img :src="''+item.img" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            
    </div>
    
    <kefu></kefu>
</template>
<style scoped>
    @import url(./topics_push_style.css);
    .y_province_city_1{
        width: 100%;
        height: 7.3rem;
        background: #fff;
        position: fixed;
        bottom: 0rem;
        left: 0rem;
        z-index: 20;
        border-top-left-radius: 0.1rem;
        border-top-right-radius: 0.1rem;
        display: none;
    }
    .y_province_city_2{
        width: 100%;
        height: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_province_city_3{
        width: 0.35rem;
        height: 0.35rem;
    }
    .y_province_city_3 img{
        width: 100%;
        object-fit: cover;
    }
    .y_province_city_4 h2{
        font-weight: 600;
        font-size: 0.36rem;
        color: #000;
    }
    .y_province_city_5{
        width: 1.2rem;
        border-radius: 0.1rem;
        background: #FECE0A;
        border-radius: 0.1rem;
        line-height: 0.6rem;
        text-align: center;
        color: #333;
        font-size: 0.3rem;
    }
    .y_province_city_6{
        padding: 0rem 0.25rem;
    }
    .y_province_city_7{
        width: 100%;
        height: 5.5rem;
        display: flex;
        justify-content: flex-start;
    }
    .y_province_city_9{
        flex: 1;
        height: 100%;
        overflow-y: scroll;
    }
    .y_province_city_8{
        width: 100%;
        display: flex;
        align-items: center;
        height: 1rem;
    }
    .y_province_city_8 p {
        font-size: 0.32rem;
        width: 100%;
        text-align: center;
        color: #aaa;
        position: relative;
        line-height: 0.75rem;
        transition: ease 0.5s all;
    }
    .y_province_city_8_active p{
        background: #F0F0F0;
        transition: ease 0.5s all;
    }
    .y_pop_three_1{
        display: none;
    }
    .y_masking_block{
        display: block;
    }
    .y_pop_three_1{
        height: 7.3rem;
    }
    .y_pop_three_2{
        height: calc(7.3rem - 1.2rem);
    }
    .y_province_city_8_active{
        background: #E3E3E3;
        transition: ease 0.5s all;
    }
    .y_topics_push_8{
        position: relative;
    }
    .y_hot_day_1{
        position: absolute;
        top: 0rem;
        left: 0rem;
        width: 70%;
        background: #fff;
        z-index: 0;
        height: 100%;
        text-align: center;
        font-size: 0.26rem;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events:none; 
    }
</style>

<script>
    import { ref } from 'vue';

    import kefu from '../../components/kefu/kefu'
    import push_charges from '../../components/push_charges/push_charges'

    import axios from 'axios'   // 接口
    import {topic_list} from '../../api/request.js'   // 话题列表
    import {group_list} from '../../api/request.js'   // 小组列表
    import {activity_list} from '../../api/request.js'   // 小组列表
    
    import {school_list} from '../../api/request.js'   // 学校列表
    import {general_type_list} from '../../api/request.js'   // 通用分类列表
    

   export default { 
    data(){
      return {
            pop_masking:'0',   // 公共弹窗 遮罩层
            search_input: '',   // 搜索 input
            selected_num:'0',    // 已选的数量
            is_all:'0',         // 是否全选
            pop_display:'0',    // 弹窗是否展示
            list_select:[{
                index:'0',
                university:'宁波大学',
                topics:'同城话题',
                name:'华为签约全球最大储能项目意味着什么…',
                text:'2021全球数字能源峰会在迪拜召开，会上，华为数字能源技术有限公司与山东电力资源…',
                hot:'18',
                people:'4030',
                reading:'2952.2',
                select:'0'

            },{
                index:'1',
                university:'宁波大学2',
                topics:'同市话题',
                name:'2华为签约全球最大储能项目意味着什么…',
                text:'2021全球数字能源峰会在迪拜召开，会上，华为数字能源技术有限公司与山东电力资源…',
                hot:'28',
                people:'3030',
                reading:'3952.2',
                select:'0'

            },{
                index:'2',
                university:'宁波大学3',
                topics:'同市话题',
                name:'3华为签约全球最大储能项目意味着什么…',
                text:'2021全球数字能源峰会在迪拜召开，会上，华为数字能源技术有限公司与山东电力资源…',
                hot:'38',
                people:'5030',
                reading:'8952.2',
                select:'0'
            }],
            select_options_1: [{
                  value: '选项1',
                  label: '全部高校全部高校'
                }, {
                  value: '选项2',
                  label: '双皮奶'
                }, {
                  value: '选项3',
                  label: '双皮奶3'
                },{
                    value: "宁波卫生职业技术学院",
                    label: "330200"
                }
            ],
            select_value_one: '',
            select_options_2: [{
                  value: '选项1',
                  label: '全部高校全部高校'
                }, {
                  value: '选项2',
                  label: '双皮奶'
                }
            ],
            select_value_two: '',
            select_options_3_1: [{
                  value: '0',
                  label: '全部话题'
                },{
                  value: '1',
                  label: '同校话题'
                }, {
                  value: '2',
                  label: '广场话题'
                }
            ],
            select_options_3_2: [{
                  value: '0',
                  label: '全部小组'
                },{
                  value: '1',
                  label: '同校小组'
                }, {
                  value: '2',
                  label: '广场小组'
                }
            ],
            select_options_3_3: [{
                  value: '0',
                  label: '全部活动'
                },{
                  value: '1',
                  label: '同校活动'
                }, {
                  value: '2',
                  label: '广场活动'
                }
            ],
            select_value_three: '',
            select_options_4: [{
                  value: '1',
                  label: '24小时'
                }, {
                  value: '2',
                  label: '3天'
                }, {
                  value: '3',
                  label: '7天'
                }, {
                  value: '4',
                  label: '30天'
                }
            ],
            select_value_four: '2',
            school_id:'',  // 学校 id
            city_id_chun:'', // 存城市 id
            school_list:'',  // 学校列表
            province_city_value:'0', // 选择类型
            province_list:'',  // 话题 左侧
            province_list_all:'0', // 全部类型
            city_list:'',   // 话题 右侧
            topics_left_id:'', // 话题左侧 id
            topics_right_id:'',  // 话题右侧 id
            province_name_chun:'',  // 话题左侧 title
            city_name_chun:'',      // 话题右侧 title
            // same_school_value:'', // 是否同校 
            // same_city_value:'', // 是否同城
            local_type:'',  // 默认全部学校
            sort_by_value:'hot_number_3_days',  // 排序字段  默认3天热度
            page:'1',   // 当前页数
            pages:'',  // 总页数
            count:'', // 总个数
            type_name:'', // 推送类型
            index_index:'',  // 话题是1,小组是2,活动是3

            selected_list_id:[],  // 选中列表id


            adspace_id:'0',       // 编辑 进入传id
            promotion_value:'0',  // 进入传value  0代表正常进入，1为待推送进入
            info_ids:'', 
            from_table:'',        // 帖子还是招聘
            bj_news:'0', // 编辑新推送 0为正常进入 1是编辑新推送
        }
    },
    created(){
        // 获取页面传参 推送类型
        this.type_name = this.$route.query.name;
        this.index_index = this.$route.query.index_index 
        console.log(this.type_name,this.index_index)
        // 获取页面传参 推送类型
        if(this.$route.query.adspace_id !== undefined){
            this.adspace_id = this.$route.query.adspace_id
            this.promotion_value = this.$route.query.promotion_value
            this.info_ids = this.$route.query.info_ids
            this.type_name = this.$route.query.type_name
            this.index_index = this.$route.query.index_index
            this.from_table = this.$route.query.from_table
            this.bj_news = this.$route.query.bj_news
        }
    },
    mounted(){
        let token = this.$cookies.get('token')
        if(token == null){
            this.$message({
                message: '请先登录！',
                duration:'2000'
            })
            var that = this;
            setTimeout(function () {
                that.$router.push({path:'/login'}) 
            },2500);
        }
        this.token = token
        let city_id_chun = sessionStorage.getItem('city_id_chun');
        this.city_id_chun = city_id_chun
        let type_name = this.type_name
        // 编辑进入
        let adspace_id = this.adspace_id
        let promotion_value = this.promotion_value
        let info_ids = this.info_ids
        let index_index = this.index_index
        let from_table = this.from_table
        if(promotion_value == '1'){
            if(type_name == 'topic'){
                if(from_table == 'blog'){
                    topic_list({keyword:'',city_id:city_id_chun,page:'1',order_field:'hot_number_3_days'}).then((res) =>{
                        console.log(res,'话题列表-帖子')
                        if(res.error_code == '0'){
                            let list_select = res.data
                            for(let i=0;i<list_select.length;i++){
                                list_select[i].index = i
                                list_select[i].select = '0'
                                if(list_select[i].is_local_city == '1'){
                                    list_select[i].topics = '广场'
                                }
                                if(list_select[i].is_local_school == '1'){
                                    list_select[i].topics = '同校'
                                }
                            }
                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                            this.list_select = list_select
                            topic_list({keyword:'',city_id:city_id_chun,topic_ids:info_ids,order_field:'hot_number_3_days'}).then((res) =>{
                                console.log(res,'编辑,话题')
                                if(res.error_code == '0'){
                                    let selected_list_id = res.data
                                    for(let i=0;i<selected_list_id.length;i++){
                                        selected_list_id[i].index = i
                                        selected_list_id[i].select = '1'
                                        if(selected_list_id[i].is_local_city == '1'){
                                            selected_list_id[i].topics = '广场'
                                        }
                                        if(selected_list_id[i].is_local_school == '1'){
                                            selected_list_id[i].topics = '同校'
                                        }
                                    }
                                    this.selected_list_id = selected_list_id
                                    this.data_comparison_list_select()
                                    console.log(selected_list_id)
                                }else{
                                    this.$message({
                                        message: res.msg,
                                        duration:'2000'
                                    })
                                }
                            })
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else if(from_table == 'recruit'){
                    topic_list({keyword:'',city_id:city_id_chun,page:'1',order_field:'hot_number_3_days'}).then((res) =>{
                        console.log(res,'话题列表-招聘')
                        if(res.error_code == '0'){
                            let list_select = res.data
                            for(let i=0;i<list_select.length;i++){
                                list_select[i].index = i
                                list_select[i].select = '0'
                                if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                            }
                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                            this.list_select = list_select
                            topic_list({keyword:'',city_id:city_id_chun,topic_ids:info_ids,order_field:'hot_number_3_days'}).then((res) =>{
                                console.log(res,'编辑,话题')
                                if(res.error_code == '0'){
                                    let selected_list_id = res.data
                                    for(let i=0;i<selected_list_id.length;i++){
                                        selected_list_id[i].index = i
                                        selected_list_id[i].select = '1'
                                        if(selected_list_id[i].is_local_city == '1'){
                                            selected_list_id[i].topics = '广场'
                                        }
                                        if(selected_list_id[i].is_local_school == '1'){
                                            selected_list_id[i].topics = '同校'
                                        }
                                    }
                                    this.selected_list_id = selected_list_id
                                    this.data_comparison_list_select()
                                    console.log(selected_list_id)
                                }else{
                                    this.$message({
                                        message: res.msg,
                                        duration:'2000'
                                    })
                                }
                            })
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }
            }else if(type_name == 'group'){
                if(from_table == 'blog'){
                    group_list({keyword:'',city_id:city_id_chun,page:'1',order_field:'hot_number_3_days'}).then((res) =>{
                        console.log(res,'小组列表-帖子')
                        if(res.error_code == '0'){
                            let list_select = res.data
                            for(let i=0;i<list_select.length;i++){
                                list_select[i].index = i
                                list_select[i].select = '0'
                                if(list_select[i].is_local_city == '1'){
                                    list_select[i].topics = '广场'
                                }
                                if(list_select[i].is_local_school == '1'){
                                    list_select[i].topics = '同校'
                                }
                            }
                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                            this.list_select = list_select
                            group_list({keyword:'',city_id:city_id_chun,group_ids:info_ids,order_field:'hot_number_3_days'}).then((res) =>{
                                console.log(res,'编辑,话题')
                                if(res.error_code == '0'){
                                    let selected_list_id = res.data
                                    for(let i=0;i<selected_list_id.length;i++){
                                        selected_list_id[i].index = i
                                        selected_list_id[i].select = '1'
                                        if(selected_list_id[i].is_local_city == '1'){
                                            selected_list_id[i].topics = '广场'
                                        }
                                        if(selected_list_id[i].is_local_school == '1'){
                                            selected_list_id[i].topics = '同校'
                                        }
                                    }
                                    this.selected_list_id = selected_list_id
                                    this.data_comparison_list_select()
                                    console.log(selected_list_id)
                                }else{
                                    this.$message({
                                        message: res.msg,
                                        duration:'2000'
                                    })
                                }
                            })
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else if(from_table == 'recruit'){
                    group_list({keyword:'',city_id:city_id_chun,page:'1',order_field:'hot_number_3_days'}).then((res) =>{
                        console.log(res,'小组列表-招聘')
                        if(res.error_code == '0'){
                            let list_select = res.data
                            for(let i=0;i<list_select.length;i++){
                                list_select[i].index = i
                                list_select[i].select = '0'
                                if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                            }
                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                            this.list_select = list_select
                            group_list({keyword:'',city_id:city_id_chun,group_ids:info_ids,order_field:'hot_number_3_days'}).then((res) =>{
                                console.log(res,'编辑,话题')
                                if(res.error_code == '0'){
                                    let selected_list_id = res.data
                                    for(let i=0;i<selected_list_id.length;i++){
                                        selected_list_id[i].index = i
                                        selected_list_id[i].select = '1'
                                        if(selected_list_id[i].is_local_city == '1'){
                                            selected_list_id[i].topics = '广场'
                                        }
                                        if(selected_list_id[i].is_local_school == '1'){
                                            selected_list_id[i].topics = '同校'
                                        }
                                    }
                                    this.selected_list_id = selected_list_id
                                    this.data_comparison_list_select()
                                    console.log(selected_list_id)
                                }else{
                                    this.$message({
                                        message: res.msg,
                                        duration:'2000'
                                    })
                                }
                            })
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }
            }else if(type_name == 'activity'){
                if(from_table == 'blog'){
                    activity_list({keyword:'',city_id:city_id_chun,page:'1',order_field:'hot_number_3_days'}).then((res) =>{
                        console.log(res,'活动列表-帖子')
                        if(res.error_code == '0'){
                            let list_select = res.data
                            for(let i=0;i<list_select.length;i++){
                                list_select[i].index = i
                                list_select[i].select = '0'
                                if(list_select[i].is_local_city == '1'){
                                    list_select[i].topics = '广场'
                                }
                                if(list_select[i].is_local_school == '1'){
                                    list_select[i].topics = '同校'
                                }
                            }
                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                            this.list_select = list_select
                            activity_list({keyword:'',city_id:city_id_chun,activity_ids:info_ids,order_field:'hot_number_3_days'}).then((res) =>{
                                console.log(res,'编辑,话题')
                                if(res.error_code == '0'){
                                    let selected_list_id = res.data
                                    for(let i=0;i<selected_list_id.length;i++){
                                        selected_list_id[i].index = i
                                        selected_list_id[i].select = '1'
                                        if(selected_list_id[i].is_local_city == '1'){
                                            selected_list_id[i].topics = '广场'
                                        }
                                        if(selected_list_id[i].is_local_school == '1'){
                                            selected_list_id[i].topics = '同校'
                                        }
                                    }
                                    this.selected_list_id = selected_list_id
                                    this.data_comparison_list_select()
                                    console.log(selected_list_id)
                                }else{
                                    this.$message({
                                        message: res.msg,
                                        duration:'2000'
                                    })
                                }
                            })
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else if(from_table == 'recruit'){
                    activity_list({keyword:'',city_id:city_id_chun,page:'1',order_field:'hot_number_3_days'}).then((res) =>{
                        console.log(res,'活动列表-招聘')
                        if(res.error_code == '0'){
                            let list_select = res.data
                            for(let i=0;i<list_select.length;i++){
                                list_select[i].index = i
                                list_select[i].select = '0'
                                if(list_select[i].is_local_city == '1'){
                                    list_select[i].topics = '广场'
                                }
                                if(list_select[i].is_local_school == '1'){
                                    list_select[i].topics = '同校'
                                }
                            }
                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                            this.list_select = list_select
                            activity_list({keyword:'',city_id:city_id_chun,activity_ids:info_ids,order_field:'hot_number_3_days'}).then((res) =>{
                                console.log(res,'编辑,话题')
                                if(res.error_code == '0'){
                                    let selected_list_id = res.data
                                    for(let i=0;i<selected_list_id.length;i++){
                                        selected_list_id[i].index = i
                                        selected_list_id[i].select = '1'
                                        if(selected_list_id[i].is_local_city == '1'){
                                            selected_list_id[i].topics = '广场'
                                        }
                                        if(selected_list_id[i].is_local_school == '1'){
                                            selected_list_id[i].topics = '同校'
                                        }
                                    }
                                    this.selected_list_id = selected_list_id
                                    this.data_comparison_list_select()
                                    console.log(selected_list_id)
                                }else{
                                    this.$message({
                                        message: res.msg,
                                        duration:'2000'
                                    })
                                }
                            })
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }
            }
        }

        // 话题列表
        console.log(this.type_name,'类型')
        if(promotion_value == '0'){
            if(type_name == 'topic'){
                topic_list({keyword:'',city_id:city_id_chun,page:'1',order_field:'hot_number_3_days'}).then((res) =>{
                    console.log(res,'话题列表')
                    if(res.error_code == '0'){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.list_select = list_select
                        console.log(list_select)
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            }else if(type_name == 'group'){
                group_list({keyword:'',city_id:city_id_chun,page:'1',order_field:'hot_number_3_days'}).then((res) =>{
                    console.log(res,'话题列表')
                    if(res.error_code == '0'){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.list_select = list_select
                        console.log(list_select)
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            }else if(type_name == 'activity'){
                activity_list({keyword:'',city_id:city_id_chun,page:'1',order_field:'hot_number_3_days'}).then((res) =>{
                    console.log(res,'活动推广ss')
                    if(res.error_code == '0'){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.list_select = list_select
                        console.log(list_select)
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            }
        }
        
        // 学校列表
        school_list({city_id:city_id_chun,is_show_cost_common:'1'}).then((res) =>{
            console.log(res,'学校列表')
            if(res.error_code == '0'){
                this.select_options_1 = res.data
                console.log(this.select_options_1)
            }else{
                this.$message({
                    message: res.msg,
                    duration:'2000'
                })
            }
        })
        // 通用分类列表
        general_type_list({token:token,pid:''}).then((res) =>{
            let province_list = res.data
            for(let i=0;i<province_list.length;i++){
                province_list[i].is = '0'
            }
            this.province_list = province_list
            console.log(res,'通用分类列表')
        })

        // that.handleCurrentChange(0)
    },
    watch:{
      '$route'(val,from){
        // 帖子传参
        let index_index = val.query.index_index
        this.index_index = index_index
      }
    },
    components:{
        kefu,
        push_charges,
    },
    methods: {
        // 全选
        select_all(){
            let is_val = this.is_all
            let list = this.list_select
            let selected_list_id = this.selected_list_id
            let type_name = this.type_name
            if(type_name == 'topic'){
                if( is_val == 0){
                    this.is_all = '1'
                    for(let i=0;i<list.length;i++){
                        list[i].select = '1' 
                        for(let p=0;p<selected_list_id.length;p++){
                            if(selected_list_id[p].topic_id == list[i].topic_id){
                                selected_list_id.splice(p,1)
                            }
                        }
                        selected_list_id.push({topic_id:list[i].topic_id,brief:list[i].brief,discuss_user_number:list[i].discuss_user_number,hot_number:list[i].hot_number,hot_number_7_days:list[i].hot_number_7_days,img:list[i].img,index:list[i].index,is_local_city:list[i].is_local_city,is_local_school:list[i].is_local_school,school_id:list[i].school_id,school_title:list[i].school_title,select:list[i].select,title:list[i].title,topics:list[i].topics,view_number:list[i].view_number})
                    }
                    console.log(selected_list_id)
                    this.selected_list_id =selected_list_id
                    this.selected_num = selected_list_id.length
                    // this.selected_num = (this.list_select.length)*1
                }else{
                    this.is_all = '0'
                    for(let i=0;i<list.length;i++){
                        this.data_comparison_list_dele()
                        list[i].select = '0' 
                    }
                    this.selected_num = '0'
                }
            }else if(type_name == 'group'){
                if( is_val == 0){
                    this.is_all = '1'
                    for(let i=0;i<list.length;i++){
                        list[i].select = '1' 
                        for(let p=0;p<selected_list_id.length;p++){
                            if(selected_list_id[p].group_id == list[i].group_id){
                                selected_list_id.splice(p,1)
                            }
                        }
                        selected_list_id.push({group_id:list[i].group_id,brief:list[i].brief,discuss_user_number:list[i].discuss_user_number,hot_number:list[i].hot_number,hot_number_7_days:list[i].hot_number_7_days,img:list[i].img,index:list[i].index,is_local_city:list[i].is_local_city,is_local_school:list[i].is_local_school,school_id:list[i].school_id,school_title:list[i].school_title,select:list[i].select,title:list[i].title,topics:list[i].topics,view_number:list[i].view_number})
                    }
                    console.log(selected_list_id)
                    this.selected_list_id =selected_list_id
                    this.selected_num = selected_list_id.length
                    // this.selected_num = (this.list_select.length)*1
                }else{
                    this.is_all = '0'
                    for(let i=0;i<list.length;i++){
                        this.data_comparison_list_dele()
                        list[i].select = '0' 
                    }
                    this.selected_num = '0'
                }
            }else if(type_name == 'activity'){
                if( is_val == 0){
                    this.is_all = '1'
                    for(let i=0;i<list.length;i++){
                        list[i].select = '1' 
                        for(let p=0;p<selected_list_id.length;p++){
                            if(selected_list_id[p].activity_id == list[i].activity_id){
                                selected_list_id.splice(p,1)
                            }
                        }
                        selected_list_id.push({activity_id:list[i].activity_id,brief:list[i].brief,discuss_user_number:list[i].discuss_user_number,hot_number:list[i].hot_number,hot_number_7_days:list[i].hot_number_7_days,img:list[i].img,index:list[i].index,is_local_city:list[i].is_local_city,is_local_school:list[i].is_local_school,school_id:list[i].school_id,school_title:list[i].school_title,select:list[i].select,title:list[i].title,topics:list[i].topics,view_number:list[i].view_number})
                    }
                    console.log(selected_list_id)
                    this.selected_list_id =selected_list_id
                    this.selected_num = selected_list_id.length
                    // this.selected_num = (this.list_select.length)*1
                }else{
                    this.is_all = '0'
                    for(let i=0;i<list.length;i++){
                        this.data_comparison_list_dele()
                        list[i].select = '0' 
                    }
                    this.selected_num = '0'
                }
            }
        },
        // 列表数组与选中的对比 对比重复删除重复
        data_comparison_list_dele(){
            let list_select = this.list_select
            let selected_list_id = this.selected_list_id
            let type_name = this.type_name
            if(type_name == 'topic'){
                for(let i=0;i<selected_list_id.length;i++){
                    for(let p=0;p<list_select.length;p++){
                        if(selected_list_id[i].topic_id == list_select[p].topic_id){
                            selected_list_id.splice(i,1)
                        }
                    }
                }
            }else if(type_name == 'group'){
                for(let i=0;i<selected_list_id.length;i++){
                    for(let p=0;p<list_select.length;p++){
                        if(selected_list_id[i].group_id == list_select[p].group_id){
                            selected_list_id.splice(i,1)
                        }
                    }
                }
            }else if(type_name == 'activity'){
                for(let i=0;i<selected_list_id.length;i++){
                    for(let p=0;p<list_select.length;p++){
                        if(selected_list_id[i].activity_id == list_select[p].activity_id){
                            selected_list_id.splice(i,1)
                        }
                    }
                }
            }
            
            this.selected_list_id = selected_list_id
        },
        // 列表数组与选中的对比 给列表选中
        data_comparison_list_select(){
            let list_select = this.list_select
            let selected_list_id = this.selected_list_id
            let type_name = this.type_name

            if(type_name == 'topic'){
                for(let i=0;i<selected_list_id.length;i++){
                    for(let p=0;p<list_select.length;p++){
                        if(selected_list_id[i].topic_id == list_select[p].topic_id){
                            list_select[p].select = '1'
                        }
                    }
                }
            }else if(type_name == 'group'){
                for(let i=0;i<selected_list_id.length;i++){
                    for(let p=0;p<list_select.length;p++){
                        if(selected_list_id[i].group_id == list_select[p].group_id){
                            list_select[p].select = '1'
                        }
                    }
                }
            }else if(type_name == 'activity'){
                for(let i=0;i<selected_list_id.length;i++){
                    for(let p=0;p<list_select.length;p++){
                        if(selected_list_id[i].activity_id == list_select[p].activity_id){
                            list_select[p].select = '1'
                        }
                    }
                }
            }
            
            this.selected_num = selected_list_id.length
            this.list_select = list_select
        },
        // 选中列表 点击删除
        select_list_click(id){
            let list_select = this.list_select
            let selected_list_id = this.selected_list_id
            let type_name = this.type_name
            console.log(list_select,selected_list_id)
            if(type_name == 'topic'){
                for(let i=0;i<list_select.length;i++){
                    if(list_select[i].topic_id == id){
                        list_select[i].select = '0'
                    }
                }
                for(let i=0;i<selected_list_id.length;i++){
                    if(selected_list_id[i].topic_id == id){
                        selected_list_id.splice(i,1)
                    }
                }
            }else if(type_name == 'group'){
                for(let i=0;i<list_select.length;i++){
                    if(list_select[i].group_id == id){
                        list_select[i].select = '0'
                    }
                }
                for(let i=0;i<selected_list_id.length;i++){
                    if(selected_list_id[i].group_id == id){
                        selected_list_id.splice(i,1)
                    }
                }
            }else if(type_name == 'activity'){
                for(let i=0;i<list_select.length;i++){
                    if(list_select[i].activity_id == id){
                        list_select[i].select = '0'
                    }
                }
                for(let i=0;i<selected_list_id.length;i++){
                    if(selected_list_id[i].activity_id == id){
                        selected_list_id.splice(i,1)
                    }
                }
            }
            this.list_select = list_select
            this.selected_list_id = selected_list_id
            this.selected_num = selected_list_id.length
        },

        // 列表 选中
        list_click(e){
            let list = this.list_select
            let selected_list_id = this.selected_list_id
            let type_name = this.type_name
            if(type_name == 'topic'){
                if(list[e].select == '0'){
                    list[e].select = '1'
                    this.selected_list_id.push({topic_id:list[e].topic_id,brief:list[e].brief,discuss_user_number:list[e].discuss_user_number,hot_number:list[e].hot_number,hot_number_7_days:list[e].hot_number_7_days,img:list[e].img,index:list[e].index,is_local_city:list[e].is_local_city,is_local_school:list[e].is_local_school,school_id:list[e].school_id,school_title:list[e].school_title,select:list[e].select,title:list[e].title,topics:list[e].topics,view_number:list[e].view_number})
                }else{
                    list[e].select = '0'
                    for(let i=0;i<selected_list_id.length;i++){
                        if(selected_list_id[i].topic_id == list[e].topic_id){
                            selected_list_id.splice(i,1)
                        }
                    }
                    this.selected_list_id = selected_list_id
                }
            }else if(type_name == 'group'){
                if(list[e].select == '0'){
                    list[e].select = '1'
                    this.selected_list_id.push({group_id:list[e].group_id,brief:list[e].brief,discuss_user_number:list[e].discuss_user_number,hot_number:list[e].hot_number,hot_number_7_days:list[e].hot_number_7_days,img:list[e].img,index:list[e].index,is_local_city:list[e].is_local_city,is_local_school:list[e].is_local_school,school_id:list[e].school_id,school_title:list[e].school_title,select:list[e].select,title:list[e].title,topics:list[e].topics,view_number:list[e].view_number,joiner_number:list[e].joiner_number,blog_number:list[e].blog_number})
                }else{
                    list[e].select = '0'
                    for(let i=0;i<selected_list_id.length;i++){
                        if(selected_list_id[i].group_id == list[e].group_id){
                            selected_list_id.splice(i,1)
                        }
                    }
                    this.selected_list_id = selected_list_id
                }
            }else if(type_name == 'activity'){
                if(list[e].select == '0'){
                    list[e].select = '1'
                    this.selected_list_id.push({activity_id:list[e].activity_id,brief:list[e].brief,discuss_user_number:list[e].discuss_user_number,hot_number:list[e].hot_number,hot_number_7_days:list[e].hot_number_7_days,img:list[e].img,index:list[e].index,is_local_city:list[e].is_local_city,is_local_school:list[e].is_local_school,school_id:list[e].school_id,school_title:list[e].school_title,select:list[e].select,title:list[e].title,topics:list[e].topics,view_number:list[e].view_number,joiner_number:list[e].joiner_number,starttime:list[e].starttime,endtime:list[e].endtime})
                }else{
                    list[e].select = '0'
                    for(let i=0;i<selected_list_id.length;i++){
                        if(selected_list_id[i].activity_id == list[e].activity_id){
                            selected_list_id.splice(i,1)
                        }
                    }
                    this.selected_list_id = selected_list_id
                }
            }
            
            this.selected_num = selected_list_id.length
            this.list_select = list
        },
        // 展示弹窗
        pop_show(){
            this.pop_masking = '1'
            this.pop_display = '1'
        },
        // 关闭弹窗
        pop_hide(){
            this.pop_masking = '0'
            this.pop_display = '0'
        },
        // 高校选择
        school_select(e){
            let general_type_id_1 = this.topics_left_id
            let general_type_id_2 = this.topics_right_id
            let local_type= this.local_type
            let sort_by_value = this.sort_by_value
            let type_name = this.type_name
            if(type_name == 'topic'){
                topic_list({keyword:'',school_id:e,city_id:this.city_id_chun,general_type_id_1:general_type_id_1,general_type_id_2:general_type_id_2,local_type:local_type,order_field:sort_by_value}).then((res) =>{
                    console.log(res,'话题列表',e)
                    if(res.error_code == 0 ){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        this.list_select = list_select
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.data_comparison_list_select()
                        console.log(list_select)
                    }else{
                        this.list_select = ''
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                        this.page = '1'     // 当前页数
                        this.pages = '1'   // 总页数
                        this.count = '1'   // 总个数
                    }
                    this.school_id = e
                    // this.selected_num = '0' 
                    this.is_all = '0'
                })
            }else if(type_name == 'group'){
                group_list({keyword:'',school_id:e,city_id:this.city_id_chun,general_type_id_1:general_type_id_1,general_type_id_2:general_type_id_2,local_type:local_type,order_field:sort_by_value}).then((res) =>{
                    console.log(res,'话题列表',e)
                    if(res.error_code == 0 ){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        this.list_select = list_select
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.data_comparison_list_select()
                        console.log(list_select)
                    }else{
                        this.list_select = ''
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                        this.page = '1'     // 当前页数
                        this.pages = '1'   // 总页数
                        this.count = '1'   // 总个数
                    }
                    this.school_id = e
                    // this.selected_num = '0' 
                    this.is_all = '0'
                })
            }else if(type_name == 'activity'){
                activity_list({keyword:'',school_id:e,city_id:this.city_id_chun,general_type_id_1:general_type_id_1,general_type_id_2:general_type_id_2,local_type:local_type,order_field:sort_by_value}).then((res) =>{
                    console.log(res,'话题列表',e)
                    if(res.error_code == 0 ){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        this.list_select = list_select
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.data_comparison_list_select()
                        console.log(list_select)
                    }else{
                        this.list_select = ''
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                        this.page = '1'     // 当前页数
                        this.pages = '1'   // 总页数
                        this.count = '1'   // 总个数
                    }
                    this.school_id = e
                    // this.selected_num = '0' 
                    this.is_all = '0'
                })
            }
            
        },
        // 选择城市 弹窗 显示 隐藏
        province_city_show(){
            this.pop_masking = '1'
            this.province_city_value = '1'
        },
        province_city_hide(){
            this.pop_masking = '0'
            this.province_city_value = '0'
        },
        // 选择类型 一级
        province_data(e){
            console.log(e)
            let that = this
            let token = that.token
            let province_list = that.province_list
            let province_name_chun = this.province_name_chun
            for(let i=0;i<province_list.length;i++){
                province_list[i].is = '0' 
                if(province_list[i].general_type_id == e){
                    province_list[i].is = '1'
                    province_name_chun = province_list[i].title
                }
            }
            that.province_name_chun = province_name_chun
            that.topics_left_id = e
            that.topics_right_id = '0'
            that.province_list = province_list
            that.province_list_all = '0'
            
            general_type_list({token:token,pid:e}).then((res) =>{
                let city_list = res.data
                for(let i=0;i<city_list.length;i++){
                    city_list[i].is = '0'
                }
                this.city_list = city_list
            })
        },
        province_data_all(){
            let that = this
            let token = that.token
            let province_list = that.province_list
            let province_name_chun = this.province_name_chun
            for(let i=0;i<province_list.length;i++){
                province_list[i].is = '0' 
            }
            that.topics_left_id = ''
            that.topics_right_id = ''
            that.province_list = province_list
            that.city_list = ''
            that.province_list_all = '1'
            that.province_name_chun = '全部类型'
            that.city_name_chun = ''
        },
        // 选择类型 二级
        city_data(e){
            let that = this
            let city_list = that.city_list
            let city_name_chun = that.city_name_chun
            for(let i=0;i<city_list.length;i++){
                city_list[i].is = '0' 
                if(city_list[i].general_type_id == e){
                    city_list[i].is = '1'
                    city_name_chun = city_list[i].title
                }
            }
            that.city_list = city_list
            that.city_name_chun = city_name_chun
            that.topics_right_id = e
        },
        // 话题选择 确定
        province_city_dete(){
            let school_id = this.school_id
            let topics_left_id = this.topics_left_id
            let topics_right_id = this.topics_right_id
            let local_type= this.local_type
            let sort_by_value = this.sort_by_value
            let city_id = this.city_id_chun
            let type_name = this.type_name
            if(type_name == 'topic'){
                topic_list({keyword:"",school_id:school_id,general_type_id_1:topics_left_id,general_type_id_2:topics_right_id,local_type:local_type,order_field:sort_by_value,city_id:city_id}).then((res) =>{
                    console.log(res,'话题选择')
                    if(res.error_code == '0'){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        console.log(this.city_name_chun)
                        this.list_select = list_select
                        this.pop_masking = '0'
                        this.province_city_value = '0'
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.data_comparison_list_select()
                        console.log(list_select)
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                    // this.selected_num = '0' 
                    this.is_all = '0'
                })
            }else if(type_name == 'group'){
                group_list({keyword:"",school_id:school_id,general_type_id_1:topics_left_id,general_type_id_2:topics_right_id,local_type:local_type,order_field:sort_by_value,city_id:city_id}).then((res) =>{
                    console.log(res,'话题选择')
                    if(res.error_code == '0'){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        console.log(this.city_name_chun)
                        this.list_select = list_select
                        this.pop_masking = '0'
                        this.province_city_value = '0'
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.data_comparison_list_select()
                        console.log(list_select)
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                    // this.selected_num = '0' 
                    this.is_all = '0'
                })
            }else if(type_name == 'activity'){
                activity_list({keyword:"",school_id:school_id,general_type_id_1:topics_left_id,general_type_id_2:topics_right_id,local_type:local_type,order_field:sort_by_value,city_id:city_id}).then((res) =>{
                    console.log(res,'话题选择')
                    if(res.error_code == '0'){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        console.log(this.city_name_chun)
                        this.list_select = list_select
                        this.pop_masking = '0'
                        this.province_city_value = '0'
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.data_comparison_list_select()
                        console.log(list_select)
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                    // this.selected_num = '0' 
                    this.is_all = '0'
                })
            }
            
        },
        // 类型选择
        school_or_city(e){
            if(e == '0'){
                this.local_type = ''
            }else if(e == '1'){
                // this.same_school_value = '1'
                // this.same_city_value = ''
                this.local_type = 'all_school'
            }else if(e == '2'){
                // this.same_school_value = ''
                // this.same_city_value = '1'
                this.local_type = 'city'
            }
            let school_id = this.school_id
            let topics_left_id = this.topics_left_id
            let topics_right_id = this.topics_right_id
            let local_type= this.local_type
            let sort_by_value = this.sort_by_value
            let city_id = this.city_id_chun
            let type_name = this.type_name
            if(type_name == 'topic'){
                topic_list({keyword:"",school_id:school_id,general_type_id_1:topics_left_id,general_type_id_2:topics_right_id,local_type:local_type,order_field:sort_by_value,city_id:city_id}).then((res) =>{
                    if(res.error_code == '0'){
                        let list_select = res.data
                        console.log(res,'1````')
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        this.list_select = list_select
                        this.pop_masking = '0'
                        this.province_city_value = '0'
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.data_comparison_list_select()
                        console.log(list_select)
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                    // this.selected_num = '0' 
                    this.is_all = '0'
                })
            }else if(type_name == 'group'){
                group_list({keyword:"",school_id:school_id,general_type_id_1:topics_left_id,general_type_id_2:topics_right_id,local_type:local_type,order_field:sort_by_value,city_id:city_id}).then((res) =>{
                    if(res.error_code == '0'){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        this.list_select = list_select
                        this.pop_masking = '0'
                        this.province_city_value = '0'
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.data_comparison_list_select()
                        console.log(list_select)
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                    // this.selected_num = '0' 
                    this.is_all = '0'
                })
            }else if(type_name == 'activity'){
                activity_list({keyword:"",school_id:school_id,general_type_id_1:topics_left_id,general_type_id_2:topics_right_id,local_type:local_type,order_field:sort_by_value,city_id:city_id}).then((res) =>{
                    if(res.error_code == '0'){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        this.list_select = list_select
                        this.pop_masking = '0'
                        this.province_city_value = '0'
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.data_comparison_list_select()
                        console.log(list_select)
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                    // this.selected_num = '0' 
                    this.is_all = '0'
                })
            }
            
        },
        // 热门
        hot_day(e){
            if(e == '1'){
                this.sort_by_value = 'hot_number_24_hours'
            }else if(e == '2'){
                this.sort_by_value = 'hot_number_3_days'
            }else if(e == '3'){
                this.sort_by_value = 'hot_number_7_days'
            }else if(e == '4'){
                this.sort_by_value = 'hot_number_30_days'
            }

            let school_id = this.school_id
            let topics_left_id = this.topics_left_id
            let topics_right_id = this.topics_right_id
            let local_type= this.local_type
            let sort_by_value = this.sort_by_value
            let city_id = this.city_id_chun
            let type_name = this.type_name
            if(type_name == 'topic'){
                topic_list({keyword:"",school_id:school_id,general_type_id_1:topics_left_id,general_type_id_2:topics_right_id,local_type:local_type,order_field:sort_by_value,city_id:city_id}).then((res) =>{
                    console.log(res,'话题选择')
                    if(res.error_code == '0'){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        this.list_select = list_select
                        this.pop_masking = '0'
                        this.province_city_value = '0'
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.data_comparison_list_select()
                        console.log(list_select)
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            }else if(type_name == 'group'){
                group_list({keyword:"",school_id:school_id,general_type_id_1:topics_left_id,general_type_id_2:topics_right_id,local_type:local_type,order_field:sort_by_value,city_id:city_id}).then((res) =>{
                    console.log(res,'话题选择')
                    if(res.error_code == '0'){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        this.list_select = list_select
                        this.pop_masking = '0'
                        this.province_city_value = '0'
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.data_comparison_list_select()
                        console.log(list_select)
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            }else if(type_name == 'activity'){
                activity_list({keyword:"",school_id:school_id,general_type_id_1:topics_left_id,general_type_id_2:topics_right_id,local_type:local_type,order_field:sort_by_value,city_id:city_id}).then((res) =>{
                    console.log(res,'话题选择')
                    if(res.error_code == '0'){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        this.list_select = list_select
                        this.pop_masking = '0'
                        this.province_city_value = '0'
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.data_comparison_list_select()
                        console.log(list_select)
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            }
            
        },
        // 搜索
        search_dete(){
            let search_input = this.search_input
            let school_id = this.school_id
            let topics_left_id = this.topics_left_id
            let topics_right_id = this.topics_right_id
            let local_type= this.local_type
            let sort_by_value = this.sort_by_value
            let city_id = this.city_id_chun
            let type_name = this.type_name
            if(type_name == 'topic'){
                topic_list({keyword:search_input,school_id:school_id,general_type_id_1:topics_left_id,general_type_id_2:topics_right_id,local_type:local_type,order_field:sort_by_value,city_id:city_id}).then((res) =>{
                    if(res.error_code == '0'){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        this.list_select = list_select
                        this.pop_masking = '0'
                        this.province_city_value = '0'
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.data_comparison_list_select()
                        console.log(list_select)
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                    // this.selected_num = '0' 
                    this.is_all = '0'
                })
            }else if(type_name == 'group'){
                group_list({keyword:search_input,school_id:school_id,general_type_id_1:topics_left_id,general_type_id_2:topics_right_id,local_type:local_type,order_field:sort_by_value,city_id:city_id}).then((res) =>{
                    if(res.error_code == '0'){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        this.list_select = list_select
                        this.pop_masking = '0'
                        this.province_city_value = '0'
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.data_comparison_list_select()
                        console.log(list_select)
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                    // this.selected_num = '0' 
                    this.is_all = '0'
                })
            }else if(type_name == 'activity'){
                activity_list({keyword:search_input,school_id:school_id,general_type_id_1:topics_left_id,general_type_id_2:topics_right_id,local_type:local_type,order_field:sort_by_value,city_id:city_id}).then((res) =>{
                    if(res.error_code == '0'){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        this.list_select = list_select
                        this.pop_masking = '0'
                        this.province_city_value = '0'
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.data_comparison_list_select()
                        console.log(list_select)
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                    // this.selected_num = '0' 
                    this.is_all = '0'
                })
            }
            
        },
        // 分页
        handleCurrentChange (currentPage) {
            
            console.log(currentPage,'currentPage');
            this.currentPage = currentPage;
            console.log(this.currentPage); //点击第几页

            let search_input = this.search_input
            let school_id = this.school_id
            let topics_left_id = this.topics_left_id
            let topics_right_id = this.topics_right_id
            let local_type= this.local_type
            let sort_by_value = this.sort_by_value
            let city_id = this.city_id_chun
            let type_name = this.type_name
            if(type_name == 'topic'){
                topic_list({keyword:search_input,school_id:school_id,general_type_id_1:topics_left_id,general_type_id_2:topics_right_id,local_type:local_type,order_field:sort_by_value,city_id:city_id,page:currentPage}).then((res) =>{
                    if(res.error_code == '0'){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        this.list_select = list_select
                        this.pop_masking = '0'
                        this.province_city_value = '0'
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.data_comparison_list_select()
                        console.log(list_select,res)
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                    // this.selected_num = '0' 
                    this.is_all = '0'
                })
            }else if(type_name == 'group'){
                group_list({keyword:search_input,school_id:school_id,general_type_id_1:topics_left_id,general_type_id_2:topics_right_id,local_type:local_type,order_field:sort_by_value,city_id:city_id,page:currentPage}).then((res) =>{
                    if(res.error_code == '0'){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        this.list_select = list_select
                        this.pop_masking = '0'
                        this.province_city_value = '0'
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.data_comparison_list_select()
                        console.log(list_select)
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                    // this.selected_num = '0' 
                    this.is_all = '0'
                })
            }else if(type_name == 'activity'){
                activity_list({keyword:search_input,school_id:school_id,general_type_id_1:topics_left_id,general_type_id_2:topics_right_id,local_type:local_type,order_field:sort_by_value,city_id:city_id,page:currentPage}).then((res) =>{
                    if(res.error_code == '0'){
                        let list_select = res.data
                        for(let i=0;i<list_select.length;i++){
                            list_select[i].index = i
                            list_select[i].select = '0'
                            if(list_select[i].is_local_city == '1'){
                                list_select[i].topics = '广场'
                            }
                            if(list_select[i].is_local_school == '1'){
                                list_select[i].topics = '同校'
                            }
                        }
                        this.list_select = list_select
                        this.pop_masking = '0'
                        this.province_city_value = '0'
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                        this.data_comparison_list_select()
                        console.log(list_select)
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                    // this.selected_num = '0' 
                    this.is_all = '0'
                })
            }
            
        },
        // 上一步
        previous_step(){
            this.$router.push({
                path:'/',
            })
        },
        // 下一步
        next_step(){
            // let list_select = this.list_select
            let selected_list_id = this.selected_list_id
            let type_name = this.type_name
            let arr = []
            if(type_name == 'topic'){
                for(let i=0;i<selected_list_id.length;i++){
                    arr.push(selected_list_id[i].topic_id)
                }
            }else if(type_name == 'group'){
                for(let i=0;i<selected_list_id.length;i++){
                    arr.push(selected_list_id[i].group_id)
                }
            }else if(type_name == 'activity'){
                for(let i=0;i<selected_list_id.length;i++){
                    arr.push(selected_list_id[i].activity_id)
                }
            }

            let info_ids_one = JSON.stringify(arr)
            let info_ids = info_ids_one.replace(/\[|]|"/g,"")
            
            let selected_num = this.selected_num
            let adspace_id = this.adspace_id
            let promotion_value = this.promotion_value
            
            let from_table = this.from_table
            let bj_news = this.bj_news


            if(selected_num > 10){
                this.$message({
                    message: '最多选10个',
                    duration:'2000'
                })
                return
            }
            if(promotion_value == '0'){
                if(selected_num == '0'){
                    this.$message({
                        message: '请至少选择一个',
                        duration:'2000'
                    })
                }else{
                    let dd = JSON.stringify(selected_list_id)
                    console.log(dd,'下一步1')
                    sessionStorage.setItem("list_select",dd)
                    this.$router.push({
                        path:'/topics_select_two',
                        query: {
                          name: this.type_name,
                          id_index:this.index_index
                        }
                    })
                }
            }else if(promotion_value == '1'){
                if(selected_num == '0'){
                    this.$message({
                        message: '请至少选择一个',
                        duration:'2000'
                    })
                }else{
                    let dd = JSON.stringify(selected_list_id)
                    sessionStorage.setItem("list_select",dd)
                    console.log(dd,'下一步2')
                    if(from_table == 'blog'){
                        this.$router.push({
                            path:'/topics_select_two',
                            query: {
                                name: this.type_name,
                                id_index:this.index_index,
                                adspace_id:adspace_id,
                                promotion_value:promotion_value,
                                info_ids:info_ids,
                                from_table:from_table,
                                bj_news:bj_news,
                            }
                        })
                    }else if(from_table == 'recruit'){
                        this.$router.push({
                            path:'/recruit_index',
                            query: {
                                name: this.type_name,
                                id_index:this.index_index,
                                adspace_id:adspace_id,
                                promotion_value:promotion_value,
                                info_ids:info_ids,
                                from_table:from_table,
                                bj_news:bj_news,
                            }
                        })
                    }
                    
                }
            }
        },
    },
    
    
}
</script>