<template>
    <div class="y_message_private_1">
        <div class="y_message_private_2">
            <router-link to='/message_push_progress' class="y_message_private_3">
                <div class="y_message_private_4">
                    <img src="../../../images/icon_44.png" alt="">
                    <img src="../../../images/icon_52.png" alt="">
                    <div class="y_message_private_6" :class="unread_three_val == '0' ?'y_display_none':''">
                        <h2>5</h2>
                    </div>
                </div>
                <div class="y_message_private_5">
                    <h2>推送中</h2>
                </div>
            </router-link>
            <router-link to='/message_to_pushed' class="y_message_private_3">
                <div class="y_message_private_4">
                    <img src="../../../images/icon_45.png" alt="">
                    <img src="../../../images/icon_53.png" alt="">
                    <div class="y_message_private_6" :class="unread_four_val == '0' ?'y_display_none':''">
                        <h2>5</h2>
                    </div>
                </div>
                <div class="y_message_private_5">
                    <h2>待推送</h2>
                </div>
            </router-link>
            <router-link to='/message_already_offline' class="y_message_private_3">
                <div class="y_message_private_4">
                    <img src="../../../images/icon_46.png" alt="">
                    <img src="../../../images/icon_54.png" alt="">
                    <div class="y_message_private_6" :class="unread_five_val == '0' ?'y_display_none':''">
                        <h2>5</h2>
                    </div>
                </div>
                <div class="y_message_private_5">
                    <h2>已下线</h2>
                </div>
            </router-link>
            <router-link to='/message_private' class="y_message_private_3 y_message_private_3_active">
                <div class="y_message_private_4">
                    <img src="../../../images/icon_42.png" alt="">
                    <img src="../../../images/icon_50.png" alt="">
                    <div class="y_message_private_6" :class="unread_one_val == '0' ?'y_display_none':''">
                        <h2>{{business_not_read_chat_number}}</h2>
                    </div>
                </div>
                <div class="y_message_private_5">
                    <h2>私信</h2>
                </div>
            </router-link>
            <router-link to='/message_comments' class="y_message_private_3">
                <div class="y_message_private_4">
                    <img src="../../../images/icon_43.png" alt="">
                    <img src="../../../images/icon_51.png" alt="">
                    <div class="y_message_private_6" :class="unread_two_val == '0' ?'y_display_none':''">
                        <h2>{{business_not_read_comment_number}}</h2>
                    </div>
                </div>
                <div class="y_message_private_5">
                    <h2>评论</h2>
                </div>
            </router-link>
        </div>

        <div class="y_message_private_7">
            <div class="y_message_private_8" v-for="(item,index) in private_message_list" :key="index" @click="private_message_deta(item.accept_uid)">
                <div class="y_message_private_9">
                    <div class="y_message_private_9_img" :class="item.head_portrait == null ? 'y_message_private_9_img_none':''">
                        <img :src="''+item.head_portrait" alt="">
                        <img src="../../../images/icon_112.png" alt="">
                    </div>
                    <div class="y_message_private_6 y_message_private_15" v-if="item.not_read_number == '0'?false:true">
                        <h2>{{item.not_read_number}}</h2>
                    </div>
                </div>
                <div class="y_message_private_10">
                    <div class="y_message_private_11">
                        <div class="y_message_private_12">
                            <h2>{{item.nicname}}</h2>
                            <p>{{item.school_title}}</p>
                        </div>
                        <div class="y_message_private_13">
                            <p>{{ item.updated_string }}</p> 
                        </div>
                    </div>
                    <div class="y_message_private_14">
                        <p>{{item.content}}</p>
                    </div>
                </div>
            </div>

        </div>

        <div class="y_page_1">
            <el-pagination background :pager-count="10" :current-page="currentPage" layout="prev, pager, next" @current-change="handleCurrentChange" :hide-on-single-page="true" :total="count"></el-pagination>
        </div>
    </div>

    <kefu></kefu>
    <bottom></bottom>
</template>

<style>
    .y_message_private_1{
        width: 100%;
    }
    .y_message_private_2{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1.6rem;
        padding: 0rem 0.25rem;
        border-bottom: 0.2rem solid #F4F5F7;
        background: #fff;
    }
    .y_message_private_3{
        width: 1.3rem;
        height: 1.3rem;
        margin-right: 0.16rem;
        background: #F3F3F3;
        border-radius: 0.1rem;
        display: flex;
        position: relative;
        flex-wrap: wrap;
        align-content: center;
        overflow: hidden;
    }
    .y_message_private_2 .y_message_private_3:nth-last-child(1){
        margin-right: 0rem;
    }
    .y_message_private_3_active{
        background: #FECE0A;
    }
    .y_message_private_3_active .y_message_private_4 img:nth-child(1){
        display: none;
    }
    .y_message_private_3_active .y_message_private_4 img:nth-child(2){
        display: block;
    }
    .y_message_private_4{
        width: 100%;
        position: relative;
        height: 0.32rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .y_message_private_4 img{
        max-height: 100%;
    }
    .y_message_private_4 img:nth-child(2){
        display: none;
    }
    .y_message_private_5{
        width: 100%;
    }
    .y_message_private_5 h2{
        width: 100%;
        text-align: center;
        color: #222;
        font-size: 0.24rem;
        margin-top: 0.1rem;
    }
    .y_message_private_6{
        position: absolute;
        display: inline-block;
        background: #F82C2C;
        height: 0.3rem;
        padding: 0rem 0.07rem;
        border-radius: 1rem;
        top: 0%;
        left: 50%;
        transform: translate(25%,-50%);
    }
    .y_message_private_6 h2{
        font-size: 0.18rem;
        color: #fff;
        line-height: 0.32rem;
    }

    .y_message_private_7{
        width: 100%;
    }
    .y_message_private_8{
        padding: 0rem 0.25rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1.6rem;
    }
    .y_message_private_9{
        width: 0.9rem;
        height: 0.9rem;
        position: relative;
    }
    .y_message_private_9 img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1rem;
    }
    .y_message_private_9_img{
        width: 0.9rem;
        height: 0.9rem;
        position: relative;
    }
    .y_message_private_9_img img:nth-child(2){
        display: none;
    }
    .y_message_private_9_img_none img:nth-child(1){
        display: none;
    }
    .y_message_private_9_img_none img:nth-child(2){
        display: block;
    }

    .y_message_private_10{
        margin-left: 0.15rem;
        height: 100%;
        /* height: 200vh; */
        border-bottom: 0.01rem solid #EEEEEE;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        flex: 1;
        max-width: calc(100% - 1.2rem);
    }
    .y_message_private_11{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_message_private_12{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_message_private_12 h2{
        font-size: 0.26rem;
        color: #333;
    }
    .y_message_private_12 p{
        font-size: 0.2rem;
        width: 1.3rem;
        height: 0.34rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #333;
        background: #FECE0A;
        margin-left: 0.1rem;
        line-height: 0.34rem;
        padding: 0rem 0.1rem;
        border-radius: 0.05rem;
        text-align: center;
    }
    .y_message_private_13 p{
        font-size: 0.22rem;
        color: #888;
    }
    .y_message_private_14{
        width: 100%;
        margin-top: 0.15rem;
    }
    .y_message_private_14 p{
        font-size: 0.24rem;
        color: #999;
        line-height: 0.3rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }


    .y_message_private_15{
        top: 0%;
        left: 50%;
        transform: translate(50%,0%);
    }
    .y_display_none{
        display: none;
    }
</style>

<script>
    import axios from 'axios'   // 接口
    import {chat_user_list} from '../../api/request.js'   // 正在聊天的好友列表
    import {user_info} from '../../api/request.js'   // 用户信息
    import {change_read} from '../../api/request.js'   // 改变状态为已读
    
    import kefu from '../../components/kefu/kefu'
    import bottom from '../../components/bottom/bottom'
    export default {
        data(){
            return {
                unread_one_val: '0',
                unread_two_val: '0',
                unread_three_val: '0',
                unread_four_val: '0',
                unread_five_val: '0',

                token:'',
                private_message_list:'',  // 私信列表
                // pull_down_value:'0',  // 下拉判断
                current_page:'1', // 当前页数

                business_not_read_chat_number:'', // 私信未读数量
                business_not_read_comment_number:'', // 评论未读数量

                page:'1',   // 当前页数
                pages:'',  // 总页数
                count:'', // 总个数
                currentPage:'',
            }
        },  
        components:{
            kefu,
            bottom,
        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token 
            
            let current_page = this.current_page
            chat_user_list({token:token,page:current_page,is_business:'1'}).then((res) =>{
                console.log(res,'正在聊天的好友列表')
                this.private_message_list = res.data
                this.page = res.page     // 当前页数
                this.pages = res.pages   // 总页数
                this.count = res.count*1   // 总个数
            })
            // 私信 评论未读
            user_info({token:token}).then((res)=>{
                console.log(res,'用户信息')
                if(res.error_code == '0'){
                    if(res.data.business_not_read_chat_number > 0){
                        this.unread_one_val = '1'
                        this.business_not_read_chat_number = res.data.business_not_read_chat_number
                    }
                    if(res.data.business_not_read_comment_number > 0){
                        this.unread_two_val = '1'
                        this.business_not_read_comment_number = res.data.business_not_read_comment_number
                    }
                    
                }
            })

            // 监听
            window.addEventListener('scroll', this.onScroll)
        },
        destroyed() {
            // 销毁监听
            window.removeEventListener('scroll', this.onScroll)
        },
        methods: {
            // 私信详情
            private_message_deta(id){
                let token = this.token
                let accept_uid = id
                change_read({token,accept_uid,is_business:'1'}).then((res)=>{
                    console.log(res,'改变状态为已读')
                })
                this.$router.push({ 
                    path:'/private_message_details',
                    query: {
                        message_id:id,
                    }
                })
            },
            // 分页
            handleCurrentChange (currentPage) {
                let token = this.token
                console.log(currentPage,'currentPage');
                this.currentPage = currentPage;
                chat_user_list({token:token,page:currentPage,is_business:'1'}).then((res) =>{
                    console.log(res,'消息列表')
                    if(res.error_code == '0'){
                        this.private_message_list = res.data
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            },
            // onScroll(){
            //     // 滚动条在Y轴上的滚动距离
            //     const scrollTop = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset
            //     // 浏览器视口的高度
            //     const clientHeight = document.body.clientHeight || document.documentElement.clientHeight
            //     // 文档的总高度
            //     const scrollHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight)

            //     let pull_down_value = this.pull_down_value
            //     if(pull_down_value == '0'){
            //         if ((scrollTop*1 + 750*1) > scrollHeight*1) {
            //             console.log(scrollTop,clientHeight,scrollHeight)
            //             let token = this.token
            //             let current_page = this.current_page*1+1*1
            //             let private_message_list = this.private_message_list
            //             this.current_page = current_page

            //             chat_user_list({token:token,page:current_page}).then((res) =>{
            //                 if(res.error_code == '0'){
            //                     console.log(res,'正在聊天的好友列表(下拉加载)')
            //                     let private_message_list_new = res.data
            //                     this.private_message_list = private_message_list.push(private_message_list_new)
            //                 }else{
            //                     this.$message({
            //                         message: res.msg,
            //                         duration:'2000'
            //                     })
            //                     this.pull_down_value = '1'
            //                 }
            //             })

            //         }  
            //     }
                   
            // },
        },
    }
</script>