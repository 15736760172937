
<template>
    <div class="y_my_edit_profile_1">
        <div class="y_my_change_phone_one_1">
            <div class="y_my_change_phone_one_2">
                <h2>旧手机号</h2>
                <p>{{phone}}</p>
            </div>
            <div class="y_my_change_phone_one_3">
                <div class="y_my_change_phone_one_4">
                    <el-input v-model="input" placeholder="请输入短信验证码"></el-input>
                </div>
                <div class="y_my_change_phone_one_5">
                    <div  v-if="show == 0" @click="getcode">获取验证码</div>
                    <div  v-if="show == 1">已发送{{count}}秒</div>
                    <div  v-if="show == 2" @click="getcode">重新获取</div>
                </div>
            </div>
        </div>
        <div class="y_my_change_phone_one_6"></div>
        <div class="y_my_change_phone_one_7">
            <p @click="page_tz()">下一步</p>
        </div>
    </div>
</template>
<style scoped>
    .y_my_edit_profile_1{
        min-height: 100vh;
        width: 100%;
        background: #F4F5F7;
    }
    .y_my_change_phone_one_1{
        width: 100%;
        background: #fff;
    }
    .y_my_change_phone_one_2{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1.2rem;
        border-bottom: 0.01rem solid #EDEDED;
        padding: 0rem 0.3rem;
    }
    .y_my_change_phone_one_2 h2{
        font-size: 0.3rem;
        color:#333;
        margin-right: 0.35rem;
    }
    .y_my_change_phone_one_2 p{
        font-size: 0.3rem;
        color: #000;
    }
    .y_my_change_phone_one_3{
        padding: 0rem 0.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.2rem;
    }
    .y_my_change_phone_one_4{
        flex: 1;
    }
    .y_my_change_phone_one_4 /deep/ input{
        background: transparent;
        padding: 0rem;
        height: 1.2rem;
        line-height: 1.2rem;
        color:#333;
        font-size: 0.3rem;
        border-radius: 0rem;
        border: none;
    }
    .y_my_change_phone_one_5{
        width: 2rem;
        text-align: center;
    }
    .y_my_change_phone_one_5 div{
        font-size: 0.3rem;
        color: #FECE0A;
    }
    .y_my_change_phone_one_6{
        width: 100%;
        height: 2.2rem;
        background: transparent;
    }
    .y_my_change_phone_one_7{
        position: fixed;
        bottom: 0.6rem;
        width: calc(100% - 0.5rem);
        left: 0.25rem;
        background: #FECE0A;
        text-align: center;
        height: 1rem;
        line-height: 1rem;
        border-radius: 0.1rem;
        overflow: hidden;
        font-size: 0.32rem;
        color: #333;
    }
</style>
<script>
    import axios from 'axios'   // 接口
    import {info} from '../../api/request.js' // 用户信息
    import {check_code} from '../../api/request.js'   // 验证手机验证码
    import {ajax_code} from '../../api/request.js'   // 发送验证码
    export default {
        data(){
            return {
                token:'', // token
  
                show: 0,
			    count: '',
			    timer: null,
                phone:'',    // 手机号
                input: '',  // 验证码
            }
        },  
        components:{

        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            // 用户信息
            info({token:token}).then((res) =>{
                console.log(res,'用户信息')
                this.phone = res.data.phone
            })
        },
        methods: {
            getcode(){

                let phone = this.phone

                ajax_code({phone:phone}).then((res) =>{
                    console.log(res,'发送验证码')
                    if(res.error_code == '0'){
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                        const times = 60; // 倒计时时间
	                     if (!this.timer) {
	                     	this.count = times;
	                    	this.show = 1;
	                    	this.timer = setInterval(() => {
	                    		if (this.count > 0 && this.count <= times) {
	                    			this.count--;
	                    		} else {
	                    			this.show = 2;
	                    			clearInterval(this.timer);
	                    			this.timer = null;
	                    		}
	                    	}, 1000)
	                    }
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
	        	
	        },
            // 验证手机验证码
            page_tz(){
                let phone = this.phone
                let input = this.input
                check_code({phone:phone,code:input}).then((res)=>{
                    console.log(res,'验证手机验证码')
                    if(res.error_code == '0'){
                        this.$router.replace('/my_change_phone_two') //跳转的页面
                    }else{
                        this.$message({
                            message: '请输入正确验证码',
                            duration:'2000'
                        })
                    }
                })
                
            }
        },
    }

</script>