<template>
    <div class="y_sweep_settings_lxr_1">
        <div class="y_sweep_settings_lxr_two_9">
            <div class="y_sweep_settings_lxr_two_10" @click="select_school">
                <span>*</span>
                <p>选择学校</p>
            </div>
            <div class="y_sweep_settings_lxr_two_11">
                <p>领奖联系↓</p>
            </div>
        </div>
        <div class="y_sweep_settings_lxr_two_1">
            <h2>联系人名称</h2>
        </div>
        <div class="y_sweep_settings_lxr_two_2">
            <el-input v-model="input" placeholder="请输入名称"></el-input>
        </div>
        <div class="y_sweep_settings_lxr_two_1">
            <h2>联系人微信二维码</h2>
        </div>
        <div class="y_sweep_settings_lxr_two_3">
            <div class="y_sweep_settings_lxr_two_4">
                <div class="y_advanced_push_three_xg_1">
                    <el-upload class="avatar-uploader" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :show-file-list="false" :on-success="banner_success" :before-upload="banner_settings">
                        <img src="../../../images/img_9.png" v-if="img == ''? true : false " alt="">
                        <img :src="''+img"  v-if="img == ''? false:true"  alt="">
                    </el-upload>
                </div>
            </div>
        </div>
        <div class="y_sweep_settings_lxr_two_7"></div>
        <div class="y_sweep_settings_lxr_two_8" @click="contact_save">
            <h2>完成</h2>
        </div>
    </div>

    <!-- 公共弹窗 阴影-->
    <div class='y_comm_shadows' :class="comm_shadows_value == '1'?'y_comm_shadows_block':''"></div>
    <!-- 选择学校 -->
    <div class="y_select_school_1" :class="school_pop_val == '1'?'y_display_block':'y_display_none'">
        <div class="y_select_school_2">
            <div class="y_select_school_3" @click="select_all">
                <div class="y_select_school_4" :class="select_all_is == '0'?'':'y_select_school_4_active'">
                    <div class="y_select_school_5">
                        <p></p>
                    </div>
                    <div class="y_select_school_6">
                        <img src="../../../images/icon_101.png" alt="">
                    </div>
                </div>
                <div class="y_select_school_7">
                    <h2>全选</h2>
                </div>
            </div>
            <div class="y_select_school_8" @click="dete_school">
                <p>确定</p>
            </div>
            <div class="y_select_school_9">
                <h2>选择学校</h2>
            </div>
        </div>
        <div class="y_select_school_10">
            <div class="y_select_school_11" v-for="(item,index) in school_list" :key="index" @click="pick_shcool(index)">
                <div class="y_select_school_12" :class="item.pick == '0'?'':'y_select_school_12_active'">
                    <h2>{{item.title}}</h2>
                </div>
                <div class="y_select_school_13">
                    <div class="y_select_school_4 y_select_school_14" :class="item.pick == '0'?(item.is == '0'?'':'y_select_school_4_active'):''">
                        <div class="y_select_school_5">
                            <p></p>
                        </div>
                        <div class="y_select_school_6">
                            <img src="../../../images/icon_101.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .y_sweep_settings_lxr_1{
        width: 100%;
        min-height: 100vh;
        background: #F4F5F7;
    }
    .y_sweep_settings_lxr_two_1{
        width: 100%;
        height: 1rem;
    }
    .y_sweep_settings_lxr_two_1 h2{
        padding-left: 0.3rem;
        color: #999;
        font-size: 0.3rem;
        line-height: 1rem;
    }
    .y_sweep_settings_lxr_two_2{
        width: 100%;
        background: #fff;
        height: 1.2rem;
    }
    .y_sweep_settings_lxr_two_2 /deep/ input{
        width: 100%;
        padding: 0rem 0rem 0rem 0.3rem;
        height: 1.2rem;
        line-height: 1.2rem;
        color: #333;
        font-size: 0.3rem;
        border-radius: 0rem;
        border: none;
        background: transparent;
    }
    .y_sweep_settings_lxr_two_2 /deep/ input::placeholder{
        color: #ccc;
    }
    .y_sweep_settings_lxr_two_3{
        width: 100%;
        background: #fff;
    }
    .y_sweep_settings_lxr_two_4{
        padding: 0.3rem;
    }
    .y_sweep_settings_lxr_two_5{
        width: 100%;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        height: 2.4rem;
        background: #F6F6F6;
    }
    .y_sweep_settings_lxr_two_5 h2{
        width: 100%;
        text-align: center;
        font-size: 0.28rem;
        color: #999;
        margin-top: 0.15rem;
    }
    .y_sweep_settings_lxr_two_6{
        width: 100%;
        height: 0.76rem;
        text-align: center;
    }
    .y_sweep_settings_lxr_two_6 img{
        width: 0.76rem;
        height: 0.76rem;
        margin: 0rem auto;
    }
    .y_sweep_settings_lxr_two_7{
        width: 100%;
        height: 1.5rem;
        background: transparent;
    }
    .y_sweep_settings_lxr_two_8{
        width: 100%;
        position: fixed;
        bottom: 0rem;
        left: 0rem;
        height: 1.3rem;
        background: #fff;
        display: flex;
        align-items: center;
    }
    .y_sweep_settings_lxr_two_8 h2{
        max-width: 6.9rem;
        width: 100%;
        height: 0.9rem;
        background: #FECE0A;
        color: #333;
        font-size: 0.32rem;
        text-align: center;
        line-height: 0.9rem;
        margin: 0rem auto;
        border-radius: 0.1rem;
    }

    .y_advanced_push_three_xg_1{
        width: 100%;
        /* height: 2rem; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .y_advanced_push_three_xg_1 /deep/ img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .y_sweep_settings_lxr_two_9{
        padding: 0rem 0.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.2rem;
        background: #fff;
        border-top: 0.01rem solid #F4F5F7;
    }
    .y_sweep_settings_lxr_two_10{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_sweep_settings_lxr_two_10 span{
        color: #FF0000;
        font-size: 0.3rem;
        display: inline-block;
        margin-right: 0.15rem;
    }
    .y_sweep_settings_lxr_two_10 p{
        line-height: 0.6rem;
        height: 0.6rem;
        padding: 0rem 0.32rem;
        border-radius: 0.1rem;
        background: #FECE0A;
        color: #333333;
        font-size: 0.3rem;
    }
    .y_sweep_settings_lxr_two_11 p{
        color: #999999;
        font-size: 0.3rem;
    }

    .y_select_school_1{
        width: calc(100% - 0.5rem);
        padding: 0rem 0.25rem;
        height: 60vh;
        position: fixed;
        bottom: 0rem;
        left: 0rem;
        background: #fff;
        border-top-right-radius: 0.2rem;
        border-top-left-radius: 0.2rem;
        overflow: hidden;
        z-index: 20;
    }
    .y_select_school_2{
        height: 1rem;
        display:flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
    }
    .y_select_school_3{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_select_school_4{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_select_school_5 p{
        width: 0.22rem;
        height: 0.22rem;
        background: #EDECE6;
        border-radius: 0.02rem;
    }
    .y_select_school_6 img{
        width: 0.22rem;
        height: 0.22rem;
    }
    .y_select_school_4 .y_select_school_6{
        display: none;
    }
    .y_select_school_4_active .y_select_school_5{
        display: none;
    }
    .y_select_school_4_active .y_select_school_6{
        display: block;
    }
    .y_select_school_12_active h2{
        color: #ccc !important;
    }
    .y_select_school_7{
        margin-left: 0.15rem;
    }
    .y_select_school_7 h2{
        display: inline-block;
        font-size: 0.3rem;
        color: #333;
    }
    .y_select_school_8 p{
        font-size: 0.3rem;
        color: #333;
        padding: 0rem 0.3rem;
        background: #FECE0A;
        line-height: 0.6rem;
        height: 0.6rem;
        border-radius: 0.1rem;
    }
    .y_select_school_9{
        position:absolute;
        left: 50%;
        top: 50%;
        height: 100%;
        transform: translate(-50%,-50%);
    }
    .y_select_school_9 h2{
        color: #000;
        font-size: 0.36rem;
        line-height: 1rem;
        font-weight: 600;
    }
    .y_select_school_10{
        width: 100%;
        height: 100%;
        overflow: scroll;
    }
    .y_select_school_11{
        height: 1rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.01rem solid #F0F0F0;
    }
    .y_select_school_12 h2{
        color: #333;
        font-size: 0.3rem;
    }
</style>

<script>
import axios from 'axios'   // 接口
import {edit_award_contact} from '../../api/request.js'   // 联系人保存
import {award_contact_detail} from '../../api/request.js'   // 联系人详情
import {school_list} from '../../api/request.js'   // 学校列表

export default {
        data(){
            return {
                input: '',
                img:'',  // 图片上传
                contact_list_id:'',  // 传来id 用来判断是新增还是编辑

                comm_shadows_value:'0',
                school_pop_val:'0',
                school_id:'',     // 选中学校
                school_list:'',   // 全部学校列表
                city_id_chun:'',  // 城市id
                school_ids_other_contact:'', // 已选中学校id
                select_all_is:'0',  // 全选 0为没有选

                school_list_pick:'',  // 已经选中的学校列表 编辑
            }
        },
        components:{

        },
        created(){
            // 获取页面传参 推送类型
            this.contact_list_id = this.$route.query.id;
        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            let city_id_chun = sessionStorage.getItem('city_id_chun')
            this.city_id_chun = city_id_chun
            let contact_list_id = this.contact_list_id
            console.log(contact_list_id)
            if(contact_list_id == undefined ){
                award_contact_detail({token:token,award_contact_id:'0'}).then((res) =>{
                    let school_ids_other_contact = res.school_ids_other_contact
                    this.school_ids_other_contact = school_ids_other_contact
                })
            }else{
                award_contact_detail({token:token,award_contact_id:contact_list_id}).then((res) =>{
                    console.log(res,'联系人详情')
                    this.input = res.data.phone
                    this.img = res.data.qrcode_img
                })
            }
            // 学校列表
            school_list({city_id:city_id_chun}).then((res) =>{
                console.log(res,'学校列表')
                let school_list = res.data
                for(let i=0;i<school_list.length;i++){
                    school_list[i].is = '0'
                    school_list[i].pick = '0'
                }
                if(contact_list_id == undefined ){
                    award_contact_detail({token:token,award_contact_id:'0'}).then((res_res) =>{
                        console.log(res_res,'联系人详情')
                        if(res_res.school_ids_other_contact !== ''){
                            let school_ids_other_contact = res_res.school_ids_other_contact
                            for(let i=0;i<school_list.length;i++){
                                for(let k=0;k<school_ids_other_contact.length;k++){
                                    if(school_list[i].school_id == school_ids_other_contact[k]){
                                        school_list[i].pick = '1'
                                    }
                                }
                            }
                            console.log(school_list)
                            this.school_list = school_list
                        }
                    })
                }else{
                    award_contact_detail({token:token,award_contact_id:contact_list_id}).then((res_res) =>{
                        let school_list_pick = res_res.school_list
                        for(let i=0;i<school_list.length;i++){
                            for(let k=0;k<school_list_pick.length;k++){
                                if(school_list[i].school_id == school_list_pick[k].id){
                                    school_list[i].is = '1'
                                }
                            }
                        }
                        this.school_list = school_list
                    })
                }
                


            })
        },
        methods: {
            // banner 图片上传成功
            banner_success(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
                console.log(res,file)
                let img = res.img
                this.img = img
            },
            // banner 图片上传设置
            banner_settings(file) {
                console.log(file)
            },
            // 联系人保存
            contact_save(){
                let token = this.token
                let award_contact_id = this.contact_list_id
                let phone = this.input
                let qrcode_img = this.img
                let school_ids = this.school_id
                if(phone == ''){
                    this.$message({
                        message: '请填写联系人名称',
                        duration:'2000'
                    }) 
                    return
                }
                if(school_ids == ''){
                    this.$message({
                        message: '请选择学校',
                        duration:'2000'
                    }) 
                    return
                }
                if(qrcode_img == ''){
                    this.$message({
                        message: '请上传图片',
                        duration:'2000'
                    }) 
                    return
                }
                edit_award_contact({token:token,award_contact_id:award_contact_id,phone:phone,qrcode_img:qrcode_img,school_ids:school_ids}).then((res) =>{
                    console.log(res,'联系人列表')
                    if(res.error_code == '0'){
                        this.$message({
                            message: '保存成功!',
                            duration:'2000'
                        }) 
                        var that = this;
                        setTimeout(function () {
                            that.$router.go(-1)
                        },1000);
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        }) 
                    }
                })
            },
            // 选择学校-显示
            select_school(){
                this.comm_shadows_value = '1'
                this.school_pop_val = '1'
            },
            // 选择学校
            pick_shcool(index){
                let school_list = this.school_list
                for(let i=0;i<school_list.length;i++){
                    if(i == index){
                        if(school_list[i].pick == '0'){
                            if(school_list[i].is == '0'){
                                school_list[i].is = '1'
                            }else{
                                school_list[i].is = '0'
                            }
                        }
                    }
                }
                this.school_list = school_list
            },
            // 学校确定
            dete_school(){
                let school_list = this.school_list
                let school_id = []
                for(let i=0;i<school_list.length;i++){
                    if(school_list[i].is == '1'){
                        school_id.push(school_list[i].school_id)
                    }
                }
                let str = JSON.stringify(school_id);
                let name = str.replace(/\[|]|"/g, "");
                this.school_id = name
                console.log(name)
                this.comm_shadows_value = '0'
                this.school_pop_val = '0'
            },  
            // 全选
            select_all(){
                let select_all_is = this.select_all_is
                let school_list = this.school_list
                if(select_all_is == '0'){
                    for(let i=0;i<school_list.length;i++){
                        if(school_list[i].pick !== '1'){
                            school_list[i].is = '1'
                        }
                    }
                    this.school_list = school_list
                    this.select_all_is = '1'
                }else{
                    for(let i=0;i<school_list.length;i++){
                        if(school_list[i].pick !== '1'){
                            school_list[i].is = '0'
                        }
                    }
                    this.school_list = school_list
                    this.select_all_is = '0'
                }
            }
        },
    }
</script>