<template>
    <div class="y_index">
         <!-- <div class='y_index_1'>
            <router-link class='y_index_2' to='/'>关于喵君</router-link>
            <router-link class='y_index_2' to='/'>使用攻略</router-link>
        </div> -->
        <push_charges></push_charges>
        <div class="y_recruit_1">
          <img src="../../../images/img_3.png" alt="">
        </div>

        <div class="y_sweep_settings_two_1">
            <div class="y_sweep_settings_two_2" v-for="(item,index) in sweepstakes_list.award_prize_json" :key="index">
                <div class="y_sweep_settings_two_3">
                    <h2>奖品名称:{{item.title}}</h2>
                    <p>{{item.number}}份</p>
                </div>
                <div class="y_sweep_settings_two_4">
                    <p>估值</p>
                    <h2>{{item.deposit_fee*1}}</h2>
                    <p>元</p>
                </div>
            </div>
            <div class="y_sweep_settings_two_5">
                <h2>所有奖品总估值</h2>
                <p>{{total_valuation}}</p>
                <h2>元，需缴纳保证金</h2>
                <p>{{total_valuation}}</p>
                <h2>元</h2>
                <h3 @click="showDialog(true)">点击缴纳</h3>
                <img src="../../../images/icon_21.png" alt="" @click="comm_pop_text(1)">
            </div>
            <div class="y_sweep_settings_two_6">
                <div class="y_sweep_settings_two_7">
                    <div class="y_sweep_settings_two_8">
                        <div class="y_sweep_settings_two_9" @click="bachelor_show">
                            <div class="y_sweep_settings_two_10">
                                <h2>本科学校中推送给</h2>
                                <p>{{bachelor_dete_list}}</p>
                            </div>
                            <img src="../../../images/icon_24.png" alt="">
                        </div>
                        <div class="school_search_1">
                            <div class="school_search_2">
                                <img src="../../../images/icon_18.png" alt="">
                            </div>
                            <div class="school_search_3">
                                <el-input v-model="school_val_b" placeholder="请输入"></el-input>
                            </div>
                            <div class="school_search_4" @click="school_click(1)">
                                <p>搜索</p>
                            </div>
                        </div>
                        <div class="y_sweep_settings_two_11">
                            <div class="y_sweep_settings_two_12" @click="comm_pop_text(2)">
                                <h2>每人点击</h2>
                                <p>{{undergraduate_unit_price}}</p>
                                <h2>元</h2>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                            <div class="y_sweep_settings_two_12" @click="comm_pop_text(3)">
                                <h2>点击人数至多</h2>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                            <div class="y_sweep_settings_two_12" @click="comm_pop_text(4)">
                                <h2>预算</h2>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="y_sweep_settings_two_13">
                        <div class="y_sweep_settings_two_14" v-for="(item,index) in school_undergraduate_list" :key="index" @click="undergraduate_school_select(index)" v-show="item.is == '1'?true:false">
                            <div class="y_sweep_settings_two_15">
                                <div class="y_sweep_settings_two_16" :class="item.is == '0' ?'y_sweep_settings_two_16_1':'y_sweep_settings_two_16_2'">
                                    <img src="../../../images/icon_20.png" alt="">
                                    <img src="../../../images/icon_19.png" alt="">
                                </div>
                                <h2>{{item.title}}</h2>
                            </div>
                            <div class="y_sweep_settings_two_15">
                                <h2>{{item.num}}</h2>
                            </div>
                            <div class="y_sweep_settings_two_15">
                                <p>{{item.price}}元</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="y_sweep_settings_two_7">
                    <div class="y_sweep_settings_two_8">
                        <div class="y_sweep_settings_two_9"  @click="specialty_show">
                            <div class="y_sweep_settings_two_10">
                                <h2>专科学校中推送给</h2>
                                <p>{{specialty_dete_list}}</p>
                            </div>
                            <img src="../../../images/icon_24.png" alt="">
                        </div>
                        <div class="school_search_1">
                            <div class="school_search_2">
                                <img src="../../../images/icon_18.png" alt="">
                            </div>
                            <div class="school_search_3">
                                <el-input v-model="school_val_d" placeholder="请输入"></el-input>
                            </div>
                            <div class="school_search_4" @click="school_click(2)">
                                <p>搜索</p>
                            </div>
                        </div>
                        <div class="y_sweep_settings_two_11">
                            <div class="y_sweep_settings_two_12" @click="comm_pop_text(2)">
                                <h2>每人点击</h2>
                                <p>{{specialty_unit_price}}</p>
                                <h2>元</h2>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                            <div class="y_sweep_settings_two_12" @click="comm_pop_text(3)">
                                <h2>点击人数至多</h2>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                            <div class="y_sweep_settings_two_12" @click="comm_pop_text(4)">
                                <h2>预算</h2>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="y_sweep_settings_two_13">
                        <div class="y_sweep_settings_two_14" v-for="(item,index) in school_specialty_list" :key="index" @click="specialty_school_select(index)" v-show="item.is == '1'?true:false">
                            <div class="y_sweep_settings_two_15">
                                <div class="y_sweep_settings_two_16" :class="item.is == '0' ?'y_sweep_settings_two_16_1':'y_sweep_settings_two_16_2'">
                                    <img src="../../../images/icon_20.png" alt="">
                                    <img src="../../../images/icon_19.png" alt="">
                                </div>
                                <h2>{{item.title}}</h2>
                            </div>
                            <div class="y_sweep_settings_two_15">
                                <h2>{{item.num}}</h2>
                            </div>
                            <div class="y_sweep_settings_two_15">
                                <p>{{item.price}}元</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- 公共弹窗 阴影-->
    <div class='y_comm_shadows' :class="comm_shadows_value == '1'?'y_comm_shadows_block':''"></div>
    <!-- 本科年级 -->
    <div class='y_bachelor_school_1' :class="bachelor_school_value == '0'?'':'y_bachelor_school_active'">
        <div class='y_bachelor_school_2'>
            <h2>选择推送给哪些年级</h2>
            <p>单次曝光<span>{{undergraduate_money}}</span>元</p>
        </div>
        <div class='y_bachelor_school_3'>
            <p v-for="bachelor_school in bachelor_schools" :key="bachelor_school.grade_level_text" @click="bachelor_dete(bachelor_school.grade_level_text)" :class='bachelor_school.is == "0"?"":"y_bachelor_school_3_active"'>{{bachelor_school.title}}</p>
        </div>
        <div class='y_bachelor_school_4'>
            <p class='y_bachelor_school_5' @click="bachelor_hide">返回</p>
            <p class='y_bachelor_school_6' @click="bachelor_determine">确定</p>
        </div>
    </div>

    <!-- 专科年级 -->
    <div class='y_bachelor_school_1' :class="specialty_school_value == '0'?'':'y_bachelor_school_active'">
        <div class='y_bachelor_school_2'>
            <h2>选择推送给哪些年级</h2>
            <p>单次曝光<span>{{specialty_money}}</span>元</p>
        </div>
        <div class='y_bachelor_school_3'>
            <p v-for="specialty_school in specialty_schools" :key="specialty_school.grade_level_text" @click="specialty_dete(specialty_school.grade_level_text)" :class='specialty_school.is == "0"?"":"y_bachelor_school_3_active"'>{{specialty_school.title}}</p>
        </div>
        <div class='y_bachelor_school_4'>
            <p class='y_bachelor_school_5' @click="specialty_hide">返回</p>
            <p class='y_bachelor_school_6' @click="specialty_determine">确定</p>
        </div>
    </div>


    <!-- 底部垫高 -->
    <div class='y_recruit_two_dian'></div>
    <div class='y_recruit_two_foot_1'>
        <div class='y_recruit_two_foot_2'>
            <div class='y_recruit_two_foot_3'>
                <p>点击总人数至多<span>{{total_number_people}}</span>，预算总计<span>{{total_price}}</span>元</p>
                
                <p v-if="total_price>principal_amount">余额不足<span @click="showDialog(true)">点击充值</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;未缴纳保证金，<span @click="showDialog(true)">点击缴纳</span>
                </p>
            </div>
            <div class='y_recruit_two_foot_4'>
                <p class='y_recruit_two_foot_5' @click="return_to">上一步</p>
                <p class='y_recruit_two_foot_6' @click="pop_up">发布</p>
            </div>
        </div>
    </div>

    <!-- 余额不足 弹出 -->
    <div class="y_balance_not_pop_1" :class="balance_not_is == '0'?'y_display_none':'y_display_block'">
        <div class="y_balance_not_pop_2">
            <div class="y_balance_not_pop_3">
                <p>预算总计<span>{{total_price}}</span>元，当前余额<span>{{principal_amount}}</span>元 余额不足，请先充足余额</p>
            </div>
            <div class="y_balance_not_pop_4">
                <p class="y_balance_not_pop_5" @click="balance_not_back">返回</p>
                <p class="y_balance_not_pop_6" @click="balance_not_recharge(true)">充值余额</p>
            </div>
        </div>
    </div>

    <!-- 学校搜索 -->
    <div class="school_search_pop_1" v-if="school_show == '0'?false:true">
        <div class="school_search_pop_2" v-if="school_select == '1'?true:false">
            <div class="y_sweep_settings_two_14" v-for="(item,index) in school_undergraduate_list" :key="index" @click="undergraduate_school_select(index)" v-show="item.show == '1'?true:false">
                <div class="y_sweep_settings_two_15">
                    <div class="y_sweep_settings_two_16" :class="item.is == '0' ?'y_sweep_settings_two_16_1':'y_sweep_settings_two_16_2'">
                        <img src="../../../images/icon_20.png" alt="">
                        <img src="../../../images/icon_19.png" alt="">
                    </div>
                    <h2>{{item.title}}</h2>
                </div>
                <div class="y_sweep_settings_two_15">
                    <h2>{{item.num}}</h2>
                </div>
                <div class="y_sweep_settings_two_15">
                    <p>{{item.price}}元</p>
                </div>
            </div>
        </div>
        <div class="school_search_pop_2" v-if="school_select == '2'?true:false">
            <div class="y_sweep_settings_two_14" v-for="(item,index) in school_specialty_list" :key="index" @click="specialty_school_select(index)" v-show="item.show == '1'?true:false">
                <div class="y_sweep_settings_two_15">
                    <div class="y_sweep_settings_two_16" :class="item.is == '0' ?'y_sweep_settings_two_16_1':'y_sweep_settings_two_16_2'">
                        <img src="../../../images/icon_20.png" alt="">
                        <img src="../../../images/icon_19.png" alt="">
                    </div>
                    <h2>{{item.title}}</h2>
                </div>
                <div class="y_sweep_settings_two_15">
                    <h2>{{item.num}}</h2>
                </div>
                <div class="y_sweep_settings_two_15">
                    <p>{{item.price}}元</p>
                </div>
            </div>
        </div>
        <div class="school_search_pop_3" @click="school_hide()">
            <p>确定</p>
        </div>
    </div>

    <!-- 公共文字 -->
    <div class="comm_text_1" v-if="pzzd_pop_is == '1'?true:false">
        <div class="comm_text_2">
            <h2>{{ pzzd_details }}</h2>
        </div>
        <div class="comm_text_3" @click="pzzd_hide()">
            <img src="../../../images/icon_117.png" alt="">
        </div>
    </div>

    <!-- 提交审核 -->
    <div class="y_submit_review_pop_1" :class="submit_review_is == '0'?'y_display_none':'y_display_block'">
        <div class="y_submit_review_pop_2">
            <div class="y_submit_review_pop_3">
                <p>喵君将尽快审核完毕，<br>审核通过后自动发布</p>
            </div>
            <div class="y_submit_review_pop_4">
                <p class="y_submit_review_pop_5" @click="submit_review_back">返回</p>
                <p class="y_submit_review_pop_6" @click="submit_review_recharge">提交审核</p>
            </div>
        </div>
    </div>
    <kefu></kefu>
    <recharge v-show="dialog_visible" :dialog_visible="dialog_visible" @dialogVisibleEvent="showDialog"></recharge>
</template>

<style scoped>
    .y_sweep_settings_two_1{
        width: 100%;
        background: #fff;
    }
    .y_sweep_settings_two_2{
        padding: 0rem 0.35rem;
        height: 1.4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.01rem solid #F3F3F3;
    }
    .y_sweep_settings_two_3{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }
    .y_sweep_settings_two_3 h2{
        font-size: 0.28rem;
        color: #999;
        margin-right: 0.35rem;
    }
    .y_sweep_settings_two_3 p{
        font-size: 0.28rem;
        color:#333;
    }
    .y_sweep_settings_two_4{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
    }
    .y_sweep_settings_two_4 h2{
        font-size: 0.28rem;
        color:#333;
    }
    .y_sweep_settings_two_4 p{
        font-size: 0.28rem;
        color: #333;
        white-space:nowrap;
    }
    .y_sweep_settings_two_4 /deep/ input{
        background: #F7F8FA;
        height: 0.8rem;
        width: 1.6rem;
        padding: 0rem 0rem 0rem 0.2rem ;
        font-size: 0.28rem;
        color: #333;
        border:none;
        border-radius: 0rem;
        margin: 0rem 0.1rem;
    }
    .y_sweep_settings_two_4 /deep/ input::placeholder{
        color: #aaa;
    }
    .y_sweep_settings_two_5{
        margin: 0rem 0.35rem;
        display: flex;
        height: 1.2rem;
        justify-items: flex-start;
        align-items: center;
    }
    .y_sweep_settings_two_5 h2{
        font-size: 0.28rem;
        color: #999;
    }
    .y_sweep_settings_two_5 p{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_sweep_settings_two_5 h3{
        border-bottom: 0.01rem solid #FECE0A;
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_sweep_settings_two_5 img{
        margin-left: 0.1rem;
        width: 0.2rem;
        height: 0.2rem;
    }
    .y_sweep_settings_two_6{
        width: 100%;
    }
    .y_sweep_settings_two_7{
        width: 100%;
    }
    .y_sweep_settings_two_8{
        background: #F7F8FA;
        padding: 0rem 0.35rem;
    }
    .y_sweep_settings_two_9{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0.8rem;
    }
    .y_sweep_settings_two_9 img{
        width: 0.14rem;
        object-fit: cover;
    }
    .y_sweep_settings_two_10{
        display: flex;
        justify-content: flex-start;
        align-items:center;
        flex: 1;
    }
    .y_sweep_settings_two_10 h2{
        color:#333;
        font-size: 0.28rem;
        margin-right: 0.15rem;
    }
    .y_sweep_settings_two_10 p{
        color: #FECE0A;
        font-size: 0.28rem;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 4rem;
    }
    .y_sweep_settings_two_11{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.8rem;
    }
    .y_sweep_settings_two_12{
        flex: 1;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .y_sweep_settings_two_12 h2{
        font-size: 0.28rem;
        color: #999;
    }
    .y_sweep_settings_two_12 p{
        font-size: 0.28rem;
        color: #FECE0A;
    }
    .y_sweep_settings_two_12 img{
        width: 0.2rem;
        height: 0.2rem;
        margin-left: 0.1rem;
    }
    .y_sweep_settings_two_13{
        padding: 0rem 0.35rem;
        background: #fff;
    }
    .y_sweep_settings_two_14{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1.2rem;
    }
    .y_sweep_settings_two_15{
        flex: 1;
        justify-content: center;
        align-items: center;
        height: 100%;
        display: flex;
    }
    .y_sweep_settings_two_15 h2{
        color: #333;
        font-size: 0.28rem;
        flex: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-align: center;
    }
    .y_sweep_settings_two_15 p{
        color:#FECE0A;
        font-size: 0.28rem;
    }
    .y_sweep_settings_two_16{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 0.3rem;
        height: 100%;
        margin-right: 0.05rem;
    }
    .y_sweep_settings_two_16 img{
        width: 0.3rem;
        display: none;
    }
    .y_sweep_settings_two_16_1 img:nth-child(1){
        display: block;
    }
    .y_sweep_settings_two_16_2 img:nth-child(2){
        display: block;
    }

    /* 余额不足 弹出 */
    .y_balance_not_pop_1{
        width: 5.2rem;
        height: 3.5rem;
        background: #fff;
        border-radius: 0.1rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 20;
        overflow: hidden;
    }
    .y_balance_not_pop_2{
        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        height: 100%;
    }
    .y_balance_not_pop_3{
        width: 100%;
        text-align: center;
    }    
    .y_balance_not_pop_3 p{
        color: #333;
        font-size: 0.3rem;
    }
    .y_balance_not_pop_3 p span{
        color: #FECE0A;
        font-size: 0.3rem;
    }
    .y_balance_not_pop_4{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
    }
    .y_balance_not_pop_4 p{
        width: 2rem;
        height: 0.7rem;
        border-radius: 1rem;
        text-align: center;
        font-size: 0.3rem;
        line-height: 0.7rem;
    }
    .y_balance_not_pop_5{
        color: #666;
        background: #ECECEC;
        margin-right: 0.1rem;
    }
    .y_balance_not_pop_6 {
        color: #333;
        background: #FECE0A;
    }
    /* 余额不足 弹出 */

    /* 提交审核 */
    .y_submit_review_pop_1{
        width: 5.2rem;
        height: 3.5rem;
        background: #fff;
        border-radius: 0.1rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 20;
        overflow: hidden;
    }
    .y_submit_review_pop_2{
        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        height: 100%;
    }
    .y_submit_review_pop_3{
        width: 100%;
        text-align: center;
    }    
    .y_submit_review_pop_3 p{
        color: #333;
        font-size: 0.3rem;
    }
    .y_submit_review_pop_4{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
    }
    .y_submit_review_pop_4 p{
        width: 2rem;
        height: 0.7rem;
        border-radius: 1rem;
        text-align: center;
        font-size: 0.3rem;
        line-height: 0.7rem;
    }
    .y_submit_review_pop_5{
        color: #666;
        background: #ECECEC;
        margin-right: 0.1rem;
    }
    .y_submit_review_   pop_6 {
        color: #333;
        background: #FECE0A;
    }
    /* 提交审核 */
    .comm_text_1{
        width: 90%;
        padding: 0.1rem;
        border-radius: 0.1rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background: #fff;
        z-index: 99;
    }
    .comm_text_2{
        text-align: left;
        padding: 0.2rem;
        height: 4rem;
        overflow-y: scroll;
    }
    .comm_text_2 h2{
        font-size: 0.3rem;
        color: #333;
        line-height: 0.4rem;
    }
    .comm_text_3{
        position: absolute;
        left: 50%;
        bottom: -0.8rem;
        width: 0.5rem;
        height: 0.5rem;
        transform: translate(-50%,0%);
    }   
    .comm_text_3 img{
        width: 100%;
        object-fit: contain;
    }
    .school_search_1{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.7rem;
        position: relative;
    }
    .school_search_2{
        width: 0.3rem;
        height: 0.3rem;
        position: absolute;
        left: 0.2rem;
        top: 50%;
        transform: translate(0%,-50%);
        z-index: 2;
    }
    .school_search_2 img{
        width: 100%;
        object-fit: contain;
    }
    .school_search_3{
        flex: 1;
        height: 0.6rem;
    }
    .school_search_3 /deep/ .el-input__inner{
        line-height: 0.6rem;
        font-size: 0.24rem;
        height: 0.6rem;
        border: none;
        border-radius: 0.1rem;
    }
    .school_search_4{
        margin-left: 0.15rem;
        width: 1rem;
        text-align: center;
        height: 0.6rem;
        background: #FECE0A;
        border-radius: 0.1rem;
    }
    .school_search_4 p{
        color: #fff;
        font-size: 0.24rem;
        line-height: 0.6rem;
    }



    .school_search_pop_1{
        width: 100%;
        position: fixed;
        top: 0px;
        left: 0px;
        background: rgba(0,0,0,0.7);
        height: 100vh;
        z-index: 9;
    }
    .school_search_pop_2{
        margin: 0.15rem;
        height: 90vh;
        background: #fff;
        overflow-y: scroll;
        border-radius: 0.2rem;
        padding: 0rem 0.2rem;
    }
    .school_search_pop_3{
        margin: 2.5vh 0.15rem;
        height: 5vh;
        background: #FECE0A;
        text-align: center;
        border-radius: 0.1rem;
    }
    .school_search_pop_3 p{
        color: #fff;
        font-size: 0.32rem;
        line-height: 5vh;
    }
</style>

<script>
import kefu from '../../components/kefu/kefu'
import recharge from '../../components/recharge/recharge'
import push_charges from '../../components/push_charges/push_charges'

import axios from 'axios'   // 接口
import {user_info} from '../../api/request.js' // 用户信息
import {school_grade_list} from '../../api/request.js' // 所有学校和年级列表
import {configuration_fields} from '../../api/request.js' // 配置字段的信息
import {edit_award} from '../../api/request.js' // 添加和编辑抽奖
import {award_info} from '../../api/request.js'   // 抽奖详情
import {submit_check_award} from '../../api/request.js'   // 抽奖提交审核

export default {
        data(){
            return {
                comm_shadows_value:'0',  // 公共 阴影
                dialog_visible: false,
                input: '',

                sweepstakes_list:'',  // 抽奖列表
                total_valuation:'',   // 总估值
                bachelor_school_value:'0',     // 本科显示
                specialty_school_value:'0',    // 专科显示

                bachelor_schools:'',   // 本科年级列表
                school_undergraduate_list:[{unit_price:'',price:'',num:'',title:'',is:'',school_id:''}],   // 本科学校
                bachelor_dete_list:'',  // 本科年级 确定列表
                bachelor_dete_id:'',    // 本科年级 确定id
                undergraduate_unit_price:'',  // 本科单价

                specialty_schools:'',       // 专科年级列表
                school_specialty_list:'',      // 专科学校
                specialty_unit_price:'',    // 专科单价
                specialty_dete_id:'',       // 专科年级 确定id

                total_number_people:'0',    // 总人数
                total_price:'0',           // 总价格

                adspace_id:'0',       // 广告位列表，高级推广 进入传id
                promotion_value:'0',  // 进入传value  0代表正常进入，1为待推送进入

                principal_amount:'',  // 总 本金金额
                balance_not_is:'0',  // 余额不足
                submit_review_is:'0', // 提交审核
                back_award_id:'',       // 提交审核之后返回列表id
                user:'',  // 用户金额信息
                bj_news:'0', // 编辑新推送 0为正常进入 1是编辑新推送
                content_one:'', // 当内容为空时
                pzzd:'',   // 配置字段
                pzzd_pop_is:'0',
                pzzd_details:'',
                school_val_b:'',  // 本科搜索学校的值
                school_val_d:'',  // 大专搜索学校的值
                school_show:'0',
                school_select:'1', // 搜索学校的类型本科还是专科
                balance_value:'0',  // 余额不足时显示
            }
        },
        components:{
            kefu, 
            recharge,
            push_charges,
        },
        created(){
            if(this.$route.query.adspace_id !== undefined){
                this.adspace_id = this.$route.query.adspace_id
                this.promotion_value = this.$route.query.promotion_value
                this.bj_news = this.$route.query.bj_news
            }
        },
        activated(){
            let sweepstakes_list = JSON.parse(sessionStorage.getItem('sweepstakes_list'));
            this.sweepstakes_list = sweepstakes_list 
        },
        mounted(){
            let that = this;
            let token = this.$cookies.get('token')
            let user = JSON.parse(sessionStorage.getItem('user'))
            that.user = user
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            let adspace_id = this.adspace_id
            let promotion_value = this.promotion_value 
            // 钱包金额
            user_info({token:token}).then((res)=>{
                this.principal_amount = res.data.balance*1 + res.data.bonus_fee*1
            })
            // that.principal_amount = that.user.balance*1 + that.user.bonus_fee*1
            // 抽奖详情
            if(promotion_value == '1'){
                award_info({token:token,award_id:adspace_id}).then((res_one) =>{ 
                    console.log(res_one,'抽奖详情')
                    let school_comm = res_one.award_school
                    // 所有学校和年级列表
                    school_grade_list({}).then((res) =>{
                        console.log(res,'所有学校和年级列表')
                        let grade_undergraduate_list = res.grade_undergraduate  // 本科年级
                        let grade_specialty_list = res.grade_specialty  //  专科年级

                        let school_undergraduate_list = res.school_undergraduate
                        let school_specialty_list = res.school_specialty

                        let bachelor_dete_list = []
                        let specialty_dete_list = []
                        // 本科 年级
                        let grade_ids_undergraduate = res_one.award.grade_level_texts_undergraduate
                        for(let i=0;i<grade_undergraduate_list.length;i++){
                            grade_undergraduate_list[i].is = '0'
                            for(let k=0;k<grade_ids_undergraduate.length;k++){
                                if(grade_undergraduate_list[i].grade_level_text == grade_ids_undergraduate[k]){
                                    grade_undergraduate_list[i].is = '1'
                                    grade_undergraduate_list[i].show = '0'
                                    bachelor_dete_list.push(grade_undergraduate_list[i].title)
                                }
                            }
                        }
                
                        // this.bachelor_dete_list = bachelor_dete_list
                        let bachelor_dete_list_one = JSON.stringify(bachelor_dete_list)
                        this.bachelor_dete_list = bachelor_dete_list_one.replace(/\[|]|"/g,"")
                        // 专科 年级    
                        let grade_ids_specialty = res_one.award.grade_level_texts_specialty
                        for(let i=0;i<grade_specialty_list.length;i++){
                            grade_specialty_list[i].is = '0'
                            for(let k=0;k<grade_ids_specialty.length;k++){
                                if(grade_specialty_list[i].grade_level_text == grade_ids_specialty[k]){
                                    grade_specialty_list[i].is = '1'
                                    grade_specialty_list[i].show = '0'
                                    specialty_dete_list.push(grade_specialty_list[i].title)
                                }
                            }
                        }
                        // this.specialty_dete_list = specialty_dete_list
                        let specialty_dete_list_one = JSON.stringify(specialty_dete_list)
                        this.specialty_dete_list = specialty_dete_list_one.replace(/\[|]|"/g,"")

                        let bachelor_schools = res_one.award.grade_level_texts_undergraduate
                        let bachelor_dete_id = ''
                        for(let i=0;i<bachelor_schools.length;i++){
                            bachelor_dete_id = bachelor_dete_id +','+ bachelor_schools[i]
                        }
                        this.bachelor_dete_id = JSON.stringify(bachelor_dete_id.substr(1,bachelor_dete_id.length))

                        let specialty_schools = res_one.award.grade_level_texts_specialty
                        let specialty_dete_id = ''
                        for(let i=0;i<specialty_schools.length;i++){
                            specialty_dete_id = specialty_dete_id +','+ specialty_schools[i]
                        }
                        this.specialty_dete_id = JSON.stringify(specialty_dete_id.substr(1,specialty_dete_id.length))

                        configuration_fields({}).then((pzzd) =>{
                            console.log(pzzd,'配置字段的信息')
                            // 本科
                            for(let i=0;i<school_undergraduate_list.length;i++){
                                school_undergraduate_list[i].is = '0'
                                school_undergraduate_list[i].show = '0'
                                school_undergraduate_list[i].num =  school_undergraduate_list[i].expact_click_number_award  // 目前没传,暂时自己写
                                school_undergraduate_list[i].unit_price = pzzd.data.award_undergraduate_click_price 
                                school_undergraduate_list[i].price = school_undergraduate_list[i].num*1*pzzd.data.award_undergraduate_click_price*1
                            }
                            // 专科
                            for(let i=0;i<school_specialty_list.length;i++){
                                school_specialty_list[i].is = '0'
                                school_specialty_list[i].show = '0'
                                school_specialty_list[i].num = school_specialty_list[i].expact_click_number_award   // 目前没传,暂时自己写
                                school_specialty_list[i].unit_price = pzzd.data.award_specialty_click_price  
                                school_specialty_list[i].price = school_specialty_list[i].num*1*pzzd.data.award_specialty_click_price*1
                            }
                            for(let p=0;p<school_comm.length;p++){
                                if(school_comm[p].school_type_text == 'undergraduate'){
                                    for(let i=0;i<school_undergraduate_list.length;i++){
                                        if(school_comm[p].school_id == school_undergraduate_list[i].school_id){
                                            school_undergraduate_list[i].is = '1'
                                            school_undergraduate_list[i].show = '1'
                                            school_undergraduate_list[i].num = school_comm[p].click_number
                                            school_undergraduate_list[i].unit_price = school_comm[p].click_price
                                            school_undergraduate_list[i].price = school_comm[p].click_total_fee
                                        }
                                    }
                                }
                                if(school_comm[p].school_type_text == 'specialty'){
                                    for(let i=0;i<school_specialty_list.length;i++){
                                        if(school_comm[p].school_id == school_specialty_list[i].school_id){
                                            school_specialty_list[i].is = '1'
                                            school_specialty_list[i].show = '1'
                                            school_specialty_list[i].num = school_comm[p].click_number
                                            school_specialty_list[i].unit_price = school_comm[p].click_price
                                            school_specialty_list[i].price = school_comm[p].click_total_fee
                                        }
                                    }
                                }
                            }
                            this.school_undergraduate_list = school_undergraduate_list
                            
                            this.school_specialty_list = school_specialty_list

                            this.undergraduate_unit_price = pzzd.data.award_undergraduate_click_price 
                            this.specialty_unit_price = pzzd.data.award_specialty_click_price  
                            this.pzzd = pzzd.data
                        })
                        // let bachelor_schools = res.grade_undergraduate
                        // for(let i=0;i<bachelor_schools.length;i++){
                        //     bachelor_schools[i].is = '0'
                        // }
                        // this.bachelor_schools = bachelor_schools
                        // let specialty_schools = res.grade_specialty
                        // for(let i=0;i<specialty_schools.length;i++){
                        //     specialty_schools[i].is = '0'
                        // }
                        // this.specialty_schools = specialty_schools
                        this.bachelor_schools = grade_undergraduate_list   // 赋值 本科年级
                        this.specialty_schools = grade_specialty_list   // 赋值 专科年级
                    })
                    let total_number_people = ''
                    let total_price = ''
                    for(let i=0;i<school_comm.length;i++){
                        total_number_people = total_number_people*1 + school_comm[i].click_number*1
                        total_price = total_price*1 + school_comm[i].click_total_fee*1
                    }
                    this.total_number_people = total_number_people
                    this.total_price = total_price
                    console.log(total_number_people,total_price)
                })

            }
            let sweepstakes_list = JSON.parse(sessionStorage.getItem('sweepstakes_list'));
            this.sweepstakes_list = sweepstakes_list 
            let award_prize_json = sweepstakes_list.award_prize_json;
            let total_valuation = ''
            for(let i=0;i<award_prize_json.length;i++){
                total_valuation = total_valuation*1 + award_prize_json[i].deposit_fee*1
            }
            this.total_valuation = total_valuation
            console.log(total_valuation,'总估值')
            if(promotion_value !== '1'){
            // 所有学校和年级列表
                school_grade_list({}).then((res) =>{
                    console.log(res,'所有学校和年级列表')

                    let school_undergraduate_list = res.school_undergraduate
                    let school_specialty_list = res.school_specialty

                    configuration_fields({}).then((pzzd) =>{
                        console.log(pzzd,'配置字段的信息')
                        // 本科
                        for(let i=0;i<school_undergraduate_list.length;i++){
                            school_undergraduate_list[i].is = '0'
                            school_undergraduate_list[i].show = '0'
                            school_undergraduate_list[i].num = school_undergraduate_list[i].expact_click_number_award  // 目前没传,暂时自己写
                            school_undergraduate_list[i].unit_price = pzzd.data.award_undergraduate_click_price 
                            school_undergraduate_list[i].price = school_undergraduate_list[i].num*1*pzzd.data.award_undergraduate_click_price*1
                        }
                        this.school_undergraduate_list = school_undergraduate_list
                        // 专科
                        for(let i=0;i<school_specialty_list.length;i++){
                            school_specialty_list[i].is = '0'
                            school_specialty_list[i].show = '0'
                            school_specialty_list[i].num = school_specialty_list[i].expact_click_number_award   // 目前没传,暂时自己写
                            school_specialty_list[i].unit_price = pzzd.data.award_specialty_click_price  
                            school_specialty_list[i].price = school_specialty_list[i].num*1*pzzd.data.award_specialty_click_price*1

                        }
                        this.school_specialty_list = school_specialty_list

                        this.undergraduate_unit_price = pzzd.data.award_undergraduate_click_price 
                        this.specialty_unit_price = pzzd.data.award_specialty_click_price  
                        this.pzzd = pzzd.data
                    })
                    let bachelor_schools = res.grade_undergraduate
                    for(let i=0;i<bachelor_schools.length;i++){
                        bachelor_schools[i].is = '0'
                    }
                    this.bachelor_schools = bachelor_schools
                    let specialty_schools = res.grade_specialty
                    for(let i=0;i<specialty_schools.length;i++){
                        specialty_schools[i].is = '0'
                    }
                    this.specialty_schools = specialty_schools
                
                })
            }
            // 各个学校用户数量
            // user_number({}).then((res) =>{
            //     console.log(res,'各个学校用户数量')
            // })
        },
        methods: {
            // 充值
            showDialog(visible) {
              this.dialog_visible = visible;
            },
            // 本科 弹窗 显示
            bachelor_show(e){
                this.comm_shadows_value = '1'
                this.bachelor_school_value = '1'
            },
            // 本科年级 选择
            bachelor_dete(grade_id){
                let bachelor_schools_list = this.bachelor_schools
                for(let i=0;i<bachelor_schools_list.length;i++){
                    if(bachelor_schools_list[i].grade_level_text == grade_id){
                        if(bachelor_schools_list[i].is == '0'){
                            bachelor_schools_list[i].is = '1'
                        }else{
                            bachelor_schools_list[i].is = '0'
                        }
                    }
                }
                this.bachelor_schools = bachelor_schools_list
            },
            // 本科 弹窗 隐藏
            bachelor_hide(e){
                this.comm_shadows_value = '0'
                this.bachelor_school_value = '0'
            },
            // 本科年级 确定
            bachelor_determine(){
                let bachelor_schools = this.bachelor_schools
                let bachelor_dete_list = []
                let bachelor_dete_id = []
                for(let i=0;i<bachelor_schools.length;i++){
                    if(bachelor_schools[i].is == '1'){
                        bachelor_dete_list.push(bachelor_schools[i].title)
                        // bachelor_dete_id.push(bachelor_schools[i].grade_id)
                        bachelor_dete_id = bachelor_dete_id +','+ bachelor_schools[i].grade_level_text
                    }
                }
                // this.bachelor_dete_list = JSON.stringify(bachelor_dete_list)
                let bachelor_dete_list_one = JSON.stringify(bachelor_dete_list)
                this.bachelor_dete_list = bachelor_dete_list_one.replace(/\[|]|"/g,"")
                
                // this.bachelor_dete_id = JSON.stringify(bachelor_dete_id)
                this.bachelor_dete_id = JSON.stringify(bachelor_dete_id.substr(1,bachelor_dete_id.length))
                console.log(this.bachelor_dete_list,this.bachelor_dete_id)
                this.comm_shadows_value = '0'
                this.bachelor_school_value = '0'
            },
            // 本科学校选择
            undergraduate_school_select(index){
                let school_undergraduate_list = this.school_undergraduate_list
                let total_number_people = this.total_number_people
                let total_price = this.total_price
                for(let i=0;i<school_undergraduate_list.length;i++){
                    if(i == index){
                        if(school_undergraduate_list[i].is == '0'){
                            school_undergraduate_list[i].is = '1'
                            total_number_people = total_number_people*1 + school_undergraduate_list[i].num*1
                            total_price = total_price*1 + school_undergraduate_list[i].price
                        }else{
                            school_undergraduate_list[i].is = '0'
                            total_number_people = total_number_people*1 - school_undergraduate_list[i].num*1
                            total_price = total_price*1 - school_undergraduate_list[i].price
                        }
                    }

                }
                this.total_number_people = total_number_people
                this.total_price = total_price
                this.school_undergraduate_list = school_undergraduate_list
            },


            // 专科 弹窗 显示
            specialty_show(e){
                this.comm_shadows_value = '1'
                this.specialty_school_value = '1'
            },
            // 专科 弹窗 隐藏
            specialty_hide(e){
                this.comm_shadows_value = '0'
                this.specialty_school_value = '0'
            },
            // 专科年级 选择
            specialty_dete(grade_id){
                let specialty_schools_list = this.specialty_schools
                for(let i=0;i<specialty_schools_list.length;i++){
                    if(specialty_schools_list[i].grade_level_text == grade_id){
                        if(specialty_schools_list[i].is == '0'){
                            specialty_schools_list[i].is = '1'
                        }else{
                            specialty_schools_list[i].is = '0'
                        }
                    }
                }
                this.specialty_schools = specialty_schools_list
            },
            // 专科年级 确定
            specialty_determine(){
                let specialty_schools = this.specialty_schools
                let specialty_dete_list = []
                let specialty_dete_id = []
                for(let i=0;i<specialty_schools.length;i++){
                    if(specialty_schools[i].is == '1'){
                        specialty_dete_list.push(specialty_schools[i].title)
                        // specialty_dete_id.push(specialty_schools[i].grade_id)
                        specialty_dete_id = specialty_dete_id +','+ specialty_schools[i].grade_level_text
                    }
                }
                // this.specialty_dete_list = JSON.stringify(specialty_dete_list)
                let specialty_dete_list_one = JSON.stringify(specialty_dete_list)
                this.specialty_dete_list = specialty_dete_list_one.replace(/\[|]|"/g,"")
                // this.specialty_dete_id = JSON.stringify(specialty_dete_id)
                this.specialty_dete_id = JSON.stringify(specialty_dete_id.substr(1,specialty_dete_id.length))
                console.log(this.specialty_dete_list,this.specialty_dete_id)
                this.comm_shadows_value = '0'
                this.specialty_school_value = '0'
            }, 
            // 专科学校选择
            specialty_school_select(index){
                let school_specialty_list = this.school_specialty_list
                let total_number_people = this.total_number_people
                let total_price = this.total_price
                for(let i=0;i<school_specialty_list.length;i++){
                    if(i == index){
                        if(school_specialty_list[i].is == '0'){
                            school_specialty_list[i].is = '1'
                            total_number_people = total_number_people*1 + school_specialty_list[i].num*1
                            total_price = total_price*1 + school_specialty_list[i].price
                        }else{
                            school_specialty_list[i].is = '0'
                            total_number_people = total_number_people*1 - school_specialty_list[i].num*1
                            total_price = total_price*1 - school_specialty_list[i].price
                        }
                    }

                }
                this.total_number_people = total_number_people
                this.total_price = total_price
                this.school_specialty_list = school_specialty_list
            },

            // 学校搜索 1是本科 2是专科
            school_click(val){
                let that = this
                let school_undergraduate_list = that.school_undergraduate_list
                let school_specialty_list = that.school_specialty_list
                let school_val_b = that.school_val_b
                let school_val_d = that.school_val_d
                if(val == '1'){
                    for(let i=0;i<school_undergraduate_list.length;i++){
                        if(school_undergraduate_list[i].title.search(school_val_b) != '-1'){
                            school_undergraduate_list[i].show = '1'
                        }
                    }
                }else{
                    for(let i=0;i<school_specialty_list.length;i++){
                        if(school_specialty_list[i].title.search(school_val_d) != '-1'){
                            school_specialty_list[i].show = '1'
                        }
                    }
                }
                that.school_undergraduate_list = school_undergraduate_list
                that.school_specialty_list = school_specialty_list
                that.school_select = val
                that.school_show = '1'
                console.log(that.school_select)
            },
            school_hide(){
                let that = this
                let school_undergraduate_list = that.school_undergraduate_list
                let school_specialty_list = that.school_specialty_list
                let school_select = that.school_select
                if(school_select == '1'){
                    for(let i=0;i<school_undergraduate_list.length;i++){
                        school_undergraduate_list[i].show = '0'
                    }
                }else{
                    for(let i=0;i<school_specialty_list.length;i++){
                        school_specialty_list[i].show = '0'
                    }
                }
                that.school_undergraduate_list = school_undergraduate_list
                that.school_specialty_list = school_specialty_list
                that.school_show = '0'
            },
            // 余额不足弹出 返回
            balance_not_back(){
                this.comm_shadows_value = '0'
                this.balance_not_is = '0'
            },  
            // 余额不足弹出 充值
            balance_not_recharge(visible){
                this.dialog_visible = visible;
                this.comm_shadows_value = '0'
                this.balance_not_is = '0'
            },  
            // 返回上一级
            return_to(){
                this.$router.go(-1)
            },
            // 发布
            pop_up(){
                let total_price = this.total_price  // 总价
                let principal_amount = this.principal_amount  // 本金金额
                if(principal_amount*1 < total_price*1){
                    this.comm_shadows_value = '1'
                    this.balance_not_is = '1'
                    return
                }
                let sweepstakes_list = this.sweepstakes_list
                console.log(sweepstakes_list)
                let token = this.token                         // 用户token
                let award_id = sweepstakes_list.award_id       // 抽奖id
                let award_prize_json = sweepstakes_list.award_prize_json  // 奖项信息
                let school_json_list = []     // 学校信息
                let school_undergraduate_list = this.school_undergraduate_list // 本科学校
                let school_specialty_list = this.school_specialty_list   // 专科学校

                let school_num = '0'
                for(let i=0;i<school_undergraduate_list.length;i++){
                    if(school_undergraduate_list[i].is == '1'){
                        if(this.bachelor_dete_id.length<3){
                            this.$message({
                                message:'请选择本科年级',
                                duration:'2000'
                            })
                            return
                        }
                        school_json_list.push({school_id:school_undergraduate_list[i].school_id,click_number:school_undergraduate_list[i].num,school_type_text:'undergraduate'})
                    }else{
                        school_num++
                    }
                }
                for(let i=0;i<school_specialty_list.length;i++){
                    if(school_specialty_list[i].is == '1'){
                        if(this.specialty_dete_id.length<3){
                            this.$message({
                                message:'请选择专科年级',
                                duration:'2000'
                            })
                            return
                        }
                        school_json_list.push({school_id:school_specialty_list[i].school_id,click_number:school_specialty_list[i].num,school_type_text:'specialty'})
                    }else{
                        school_num++
                    }
                }
                if((school_undergraduate_list.length*1+school_specialty_list.length*1) == school_num){
                    this.$message({
                        message:'至少选择一所学校',
                        duration:'2000'
                    })
                    return
                }
                let school_json = school_json_list  // 传 学校信息
                let open_type = sweepstakes_list.open_type   // 开奖方式
                let open_time = sweepstakes_list.open_time   // 开奖时间
                let open_people_number = sweepstakes_list.open_people_number  // 自动开奖人数
                let now_max_people_number  = sweepstakes_list.now_max_people_number   // 即抽人数上限
                let now_endtime = sweepstakes_list.now_endtime  // 即抽截止时间
                let award_contact_ids = sweepstakes_list.award_contact_ids  // 领奖联系人
                let info = sweepstakes_list.info   // 抽奖说明
                let content = sweepstakes_list.content // 内容
                if(sweepstakes_list.content == ''){
                    this.content_one = ''
                }else if(sweepstakes_list.content == null){
                    this.content_one = ''
                }else{
                    this.content_one = JSON.stringify(content)
                }
                let notice = sweepstakes_list.notice  // 抽奖须知
                let get_prize_info = sweepstakes_list.get_prize_info  // 领奖说明
                let is_help = sweepstakes_list.is_help  // 是否可邀请助力
                let is_group = sweepstakes_list.is_group  // 是否可组队
                let group_max_people_number = sweepstakes_list.group_max_people_number  //  队伍人数
                let grade_ids_undergraduate = this.bachelor_dete_id // 本科年级 确定id
                let grade_ids_specialty = this.specialty_dete_id    // 专科年级 确定id
                console.log(grade_ids_undergraduate)
                let bj_news = this.bj_news
                if(bj_news == '1'){
                    edit_award({token:token,award_id:'0',award_prize_json:JSON.stringify(award_prize_json),school_json:JSON.stringify(school_json),open_type:open_type,open_time:open_time,open_people_number:open_people_number,now_max_people_number:now_max_people_number,now_endtime:now_endtime,award_contact_ids:award_contact_ids,info:info,content:this.content_one,notice:notice,get_prize_info:get_prize_info,is_help:is_help,is_group:is_group,group_max_people_number:group_max_people_number,grade_level_texts_undergraduate:grade_ids_undergraduate.substring(1,grade_ids_undergraduate.length-1),grade_level_texts_specialty:grade_ids_specialty.substring(1,grade_ids_specialty.length-1)}).then((res) =>{
                        console.log(res,'添加和编辑抽奖')
                        if(res.error_code == '0'){
                            this.comm_shadows_value = '1'
                            this.submit_review_is = '1'
                            this.back_award_id = res.award_id
                        }else{
                            this.$message({
                                message:res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else{
                    edit_award({token:token,award_id:award_id,award_prize_json:JSON.stringify(award_prize_json),school_json:JSON.stringify(school_json),open_type:open_type,open_time:open_time,open_people_number:open_people_number,now_max_people_number:now_max_people_number,now_endtime:now_endtime,award_contact_ids:award_contact_ids,info:info,content:this.content_one,notice:notice,get_prize_info:get_prize_info,is_help:is_help,is_group:is_group,group_max_people_number:group_max_people_number,grade_level_texts_undergraduate:grade_ids_undergraduate.substring(1,grade_ids_undergraduate.length-1),grade_level_texts_specialty:grade_ids_specialty.substring(1,grade_ids_specialty.length-1)}).then((res) =>{
                        console.log(res,'添加和编辑抽奖')
                        if(res.error_code == '0'){
                            this.comm_shadows_value = '1'
                            this.submit_review_is = '1'
                            this.back_award_id = res.award_id
                        }else{
                            this.$message({
                                message:res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }
            },
            // 提交审核 返回
            submit_review_back(){
                this.comm_shadows_value = '0'
                this.submit_review_is = '0'
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/'}) 
                },1000);
            },
            // 提交审核 确定
            submit_review_recharge(){
                let back_award_id = this.back_award_id
                let token = this.token
                submit_check_award({token,award_id:back_award_id}).then((res) =>{
                    console.log(res,'提交审核')
                    if(res.error_code == '0'){
                        this.$message({
                            message:res.msg,
                            duration:'2000'
                        })
                        var that = this;
                        setTimeout(function () {
                            that.$router.push({path:'/'}) 
                        },1000);
                    }else{
                        this.$message({
                            message:res.msg,
                            duration:'2000'
                        })
                    }
                })
            },

            // 公共文字弹窗 显示
            comm_pop_text(num){
                let that = this
                let pzzd = that.pzzd
                that.comm_shadows_value = '1'
                that.pzzd_pop_is = '1'
                if(num == '1'){
                    that.pzzd_details = pzzd.margin_statement
                }else if(num == '2'){
                    that.pzzd_details = pzzd.raffle_each_person_clicks
                }else if(num == '3'){
                    that.pzzd_details = pzzd.raffle_greatest_number
                }else if(num == '4'){
                    that.pzzd_details = pzzd.raffle_budget_description
                }
            },
            // 公共文字弹窗 关闭
            pzzd_hide(){
                let that = this
                that.comm_shadows_value = '0'
                that.pzzd_pop_is = '0'
            }

        },
    }
</script>
