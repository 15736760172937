
<template>
    <div class="y_my_edit_profile_1">
        <div class="y_my_personal_one_1">
            <div class="y_my_personal_one_2">
                <h2>【高级认证】</h2>
                <p>{{gj_accreditation}}</p>
            </div>
        </div>
       
        <div class="y_my_certification_4 y_my_advanced_one_1">
            <div class="y_my_certification_5" @click="advanced_certification(1)">
                <p>企业认证</p>
            </div>
            <div class="y_my_certification_5" @click="advanced_certification(2)">
                <p>个体工商户认证</p>
            </div>
            <div class="y_my_certification_5" @click="advanced_certification(3)">
                <p>民办非企业单位认证</p>
            </div>
            <div class="y_my_certification_5" @click="advanced_certification(4)">
                <p>社会团体认证</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .y_my_edit_profile_1{
        min-height: 100vh;
        width: 100%;
        background: #F4F5F7;
    }
    .y_my_personal_one_1{
        padding: 0.4rem 0.25rem 0rem;
    }
    .y_my_personal_one_2{
        min-height: 2.2rem;
        background: #fff;
        border-radius: 0.1rem;
        overflow: hidden;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        padding: 0rem 0.4rem;
        box-shadow: 0 0 0.2rem 0.05rem #ccc;
    }
    .y_my_personal_one_2 h2{
        color: #333;
        font-size: 0.32rem;
        font-weight: 600;
        width: 100%;
    }
    .y_my_personal_one_2 p{
        width: 100%;
        margin-top: 0.2rem;
        color: #999;
        font-size: 0.28rem;
    }
    .y_my_personal_one_3{
        width: 100%;
        margin-top: 0.4rem;
        background: #fff;
    }
    .y_my_personal_one_4{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.2rem;
        padding: 0rem 0.3rem;
        border-bottom: 0.01rem solid #EDEDED;
    }
    .y_my_personal_one_5{
        width: 2rem;
    }
    .y_my_personal_one_5 h2{
        font-size: 0.3rem;
        color: #999;
    }
    .y_my_personal_one_6{
        flex:1;
    }
    .y_my_personal_one_6 /deep/ input{
        width: 100%;
        height: 1.2rem;
        border-radius: 0rem;
        border:none;
        background: transparent;
        line-height: 1.2rem;
        font-size: 0.3rem;
        color: #333;
        text-align: right;
    }

    .y_my_change_phone_one_6{
        width: 100%;
        height: 2.2rem;
        background: transparent;
    }
    .y_my_change_phone_one_7{
        position: fixed;
        bottom: 0.6rem;
        width: calc(100% - 0.5rem);
        left: 0.25rem;
        background: #FECE0A;
        text-align: center;
        height: 1rem;
        line-height: 1rem;
        border-radius: 0.1rem;
        overflow: hidden;
        font-size: 0.32rem;
        color: #333;
    }

    /* 高级认证 */
    .y_my_certification_4{
        width: 100%;
        margin-top: 0.9rem;
    }
    .y_my_certification_5{
        width: 100%;
        height: 1rem;
        border-radius: 0.1rem;
        overflow: hidden;
        margin-bottom: 0.4rem;
        display: block;
    }
    .y_my_certification_5 p{
        width: 100%;
        height: 1rem;
        line-height: 1rem;
        background: #FECE0A;
        text-align: center;
        color: #333;
        font-size: 0.32rem;
        font-weight: 600;
    }

    .y_my_advanced_one_1{
        padding: 0rem 0.25rem;
        width: auto;
    }
</style>
<script>
    import {configuration_fields} from '../../api/request.js' // 配置字段的信息
    export default {
        data(){
            return {
                gj_accreditation:'',
            }
        },  
        components:{

        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            // 配置字段的信息
            configuration_fields({}).then((res)=>{
                console.log(res,'配置字段的信息')
                this.gj_accreditation = res.data.advanced_certification
            })
        },
        methods: {
            //高级认证 跳转
            advanced_certification(type){
                this.$router.push({
                    path:'/my_advanced_certification_two',
                    query: {
                        value:type,
                    }
                })
            },    
        },
    }

</script>