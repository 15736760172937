<template>
    <div class="y_push_date_tg_1">
        <div class="y_push_date_tg_2">
            <div class="y_push_date_tg_3">
                <p>余额<span>{{ available_balance }}</span>元</p>
            </div>
            <div class="y_push_date_tg_4">
                <p @click="showDialog(true)">充值余额</p>
            </div>
        </div>
        <div class="y_push_date_tg_5">
            <div class="y_push_date_tg_6">
                <div class="y_push_date_tg_7">
                    <p>给每人最多推送 {{blog_list.max_push_number}}次</p>
                    <!-- <h2>3次</h2> -->
                </div>
                <!-- <div class="y_push_date_tg_8">
                    <p>+次数</p>
                </div> -->
            </div>
            <div class="y_push_date_tg_9">
                <p>共有<span>{{blog_list.view_number_business}}</span>人浏览，人均浏览次数<span>{{(((blog_list.view_number_total_business*1)/(blog_list.view_number_business*1)).toFixed(0)) == 'NaN'?'0':(((blog_list.view_number_total_business*1)/(blog_list.view_number_business*1)).toFixed(0))}}</span>次</p>
                
            </div>
            <div class="y_push_date_tg_9">
                <p>原设推送时间:{{blog_list.push_starttime}} ~ {{blog_list.push_endtime}}</p>
            </div>
            <div class="y_push_date_tg_6">
                <div class="y_push_date_tg_7">
                    <p>下线时间：</p>
                    <h2>{{blog_list.offline_time}}</h2>
                </div>
                <!-- <div class="y_push_date_tg_8">
                    <p>+时</p>
                </div> -->
            </div>
            <div class="y_push_date_tg_9">
                <p>共扣费/总预算：<span>{{cost_common_list.total_fee - cost_common_list.left_fee}}元 / {{cost_common_list.total_fee}}元</span></p>
            </div>
            <div class="y_push_date_tg_6">
                <div class="y_push_date_tg_7">
                    <p>共完成/预设总曝光量：</p>
                    <h2><span>{{cost_common_list.exposure_number - cost_common_list.left_exposure_number}} / {{cost_common_list.exposure_number}}</span></h2>
                </div>
                <!-- <div class="y_push_date_tg_8">
                    <p>+曝光量</p>
                </div> -->
            </div>
        </div>

        <div class="y_push_date_tg_10" :class="undergraduate_display == '0'?'':'y_display_none'">
            <div class="y_push_date_tg_11">
                <p>本科学校中推送给</p>
                <h2>{{grade_list_undergraduate}}</h2>
            </div>
            <div class="y_push_date_tg_12">
                <p>单次曝光<span>{{cost_common_list.common_push_undergraduate_exposure_price}}</span>元</p>
                <p>共完成/预设总曝光量</p>
                <p>共扣费/预算</p>
            </div>
        </div>
        <div class="y_push_date_tg_13" :class="undergraduate_display == '0'?'':'y_display_none'">
            <div class="y_push_date_tg_14" :class="item.school_type_text == 'undergraduate' ?'':'y_display_none'" v-for="(item,index) in school_list" :key="index">
                <div class="y_push_date_tg_15">
                    <div class="y_push_date_tg_16">
                        <h2>{{item.school_title}}</h2>
                    </div>
                </div>
                <div class="y_push_date_tg_15">
                    <div class="y_push_date_tg_16">
                        <h2>{{item.exposure_number-item.left_exposure_number}}/{{item.exposure_number}}</h2>
                    </div>
                </div>
                <div class="y_push_date_tg_15">
                    <div class="y_push_date_tg_16 y_push_date_tg_16_color">
                        <h2>{{item.total_fee-item.left_fee}}元/{{(item.total_fee*1).toFixed(0)}}元</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="y_push_date_tg_10" :class="specialty_display == '0'?'':'y_display_none'">
            <div class="y_push_date_tg_11">
                <p>专科学校中推送给</p>
                <h2>{{grade_list_specialty}}</h2>
            </div>
            <div class="y_push_date_tg_12">
                <p>单次曝光<span>{{cost_common_list.common_push_specialty_exposure_price}}</span>元</p>
                <p>共完成/预设总曝光量</p>
                <p>共扣费/预算</p>
            </div>
        </div>
        <div class="y_push_date_tg_13" :class="specialty_display == '0'?'':'y_display_none'">
            <div class="y_push_date_tg_14" :class="item.school_type_text == 'specialty' ?'':'y_display_none'" v-for="(item,index) in school_list" :key="index">
                <div class="y_push_date_tg_15">
                    <div class="y_push_date_tg_16">
                        <h2>{{item.school_title}}</h2>
                    </div>
                </div>
                <div class="y_push_date_tg_15">
                    <div class="y_push_date_tg_16">
                        <h2>{{item.exposure_number-item.left_exposure_number}}/{{item.exposure_number}}</h2>
                    </div>
                </div>
                <div class="y_push_date_tg_15">
                    <div class="y_push_date_tg_16 y_push_date_tg_16_color">
                        <h2>{{item.total_fee-item.left_fee}}元/{{(item.total_fee*1).toFixed(0)}}元</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <kefu></kefu>
    <recharge v-show="dialog_visible" :dialog_visible="dialog_visible" @dialogVisibleEvent="showDialog"></recharge>
</template>

<style scoped>
    .y_push_date_tg_1{
        width: 100%;
        min-height: 100vh;
        background: #F4F5F7;
    }
    .y_push_date_tg_2{
        padding: 0rem 0.45rem;
        height: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_push_date_tg_3 p{
        font-size: 0.28rem;
        color: #333;
    }
    .y_push_date_tg_3 p span{
        color:#FECE0A;
        font-size: .28rem;
    }
    .y_push_date_tg_4 p{
        font-size: 0.28rem;
        color: #333;
        text-decoration:underline
    }
    .y_push_date_tg_5{
        padding: 0.28rem 0.25rem;
        background: #fff;
    }
    .y_push_date_tg_6{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.15rem;
    }
    .y_push_date_tg_7{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_date_tg_7 p{
        font-size: 0.24rem;
        color: #999;
    }
    .y_push_date_tg_7 h2{
        margin-left: 0.15rem;
        color: #333;
        /* font-weight: 600; */
        font-size: 0.28rem;
    }
    .y_push_date_tg_7 h2 span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_8{
        display: inline-block;
        background: #FECE0A;
        padding: 0rem 0.15rem;
        height: 0.46rem;
        border-radius: 0.1rem;
    }
    .y_push_date_tg_8 p{
        line-height: 0.46rem;
        color: #333;
        font-size: 0.26rem;
    }
    .y_push_date_tg_9{
        width: 100%;
        margin-bottom: 0.15rem;
    }
    .y_push_date_tg_9 p{
        color: #999;
        font-size: 0.24rem;
        line-height: 0.46rem;
    }
    .y_push_date_tg_9 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_10{
        width: 100%;
    }
    .y_push_date_tg_11{
        padding: 0rem 0.35rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.8rem;
    }
    .y_push_date_tg_11 p{
        color:#333;
        font-size: 0.28rem;
    }
    .y_push_date_tg_11 h2{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_12{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.8rem;
    }
    .y_push_date_tg_12 p{
        flex: 1;
        text-align: center;
        color: #999;
        font-size: 0.26rem;
    }
    .y_push_date_tg_12 p span{
        color: #FECE0A;
        font-size: 0.26rem;
    }
    .y_push_date_tg_13{
        width: 100%;
        background: #fff;
        /* padding-bottom: 0.4rem; */
    }
    .y_push_date_tg_14{
        display: flex;
        justify-content: flex-start;
    }
    .y_push_date_tg_15{
        flex:1;
    }
    .y_push_date_tg_16{
        width: 100%;
    }
    .y_push_date_tg_16 h2{
        width: 100%;
        line-height: 1rem;
        text-align: center;
        color: #333;
        font-size: 0.28rem;    
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
    .y_push_date_tg_16_color h2{
        color: #FECE0A;
    } 
    .y_push_date_tg_17{
        width: 100%;
    }
    .y_push_date_tg_17>p{
        font-size: 0.28rem;
        color: #333;
        width: 100%;
        text-align: center;
    }
    .y_push_date_tg_18{
        width: 100%;
        text-align: center;
        margin-top: 0.25rem;
    }
    .y_push_date_tg_18 /deep/ input{
        width: 1.6rem;
        height: 0.8rem;
        border-radius: 0.1rem;
        background: #F7F8FA;
        overflow: hidden;
        padding: 0rem;
        line-height: 0.8rem;
        margin: 0 auto;
        font-size: 0.28rem;
        color: #ccc;
        text-align: center;
        border: none;
    }
    .y_push_date_tg_18 p{
        color: #FECE0A;
        font-size: 0.28rem;
        line-height: 0.8rem;
    }
    .y_push_date_tg_19{
        width: 100%;
        height: 5rem;
        background: transparent;
    }
    .y_push_date_tg_20{
        width: 100%;
        height: 2.7rem;
        background: #fff;
        position: fixed;
        left: 0rem;
        bottom: 0rem;
    }
    .y_push_date_tg_21{
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        padding: 0rem 0.25rem;
        height: 100%;
    }
    .y_push_date_tg_22{
        width: 100%;
        text-align: center;
    }
    .y_push_date_tg_22 p{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_date_tg_22 p span{
        color:#FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_23{
        display: flex;
        margin-top: 0.2rem;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .y_push_date_tg_23 p{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_date_tg_23 h2{
        color: #FECE0A;
        font-size: 0.28rem;
        text-decoration:underline;
    }
    .y_push_date_tg_24{
        width: 100%;
        margin-top: 0.3rem;
    }
    .y_push_date_tg_24 p{
        height: 0.8rem;
        background: #FECE0A;
        line-height: 0.8rem;
        width: 100%;
        border-radius: 0.1rem;
        text-align: center;
        font-size:0.32rem;
        color: #333;
        font-weight: 600;
    }
    .y_display_none{
        display: none;
    }
</style>

<script>
    import kefu from '../../components/kefu/kefu'
    import recharge from '../../components/recharge/recharge'
    import {user_info} from '../../api/request.js' // 用户信息
    import {blog_common_info} from '../../api/request.js' // 帖子详情,普通推广
    export default {
        data(){
            return {
                input: '',
                //公共 钱包充值
                dialog_visible: false,
                available_balance:'',

                recruit_id:'',
                push_status:'',
                blog_list:'',
                cost_common_list:'',
                push_endtime_stamptime:'',

                school_list:'',    // 学校列表
                undergraduate_display:'0',  // 没有年级时隐藏整个年级学校
                specialty_display:'0',      // 没有年级时隐藏整个年级学校
            }
        },  
        components:{
            kefu,
            recharge,
        },
        created(){
            // 获取页面传参 推送类型
            if(this.$route.query.recruit_id !== undefined){
                this.recruit_id = this.$route.query.recruit_id
                this.push_status = this.$route.query.push_status
            }
        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            let recruit_id = this.recruit_id
            // 帖子详情,普通推广
            blog_common_info({token:token,blog_id:recruit_id}).then((res)=>{
                console.log(res,'帖子详情,普通推广')
                this.blog_list = res.blog
                this.cost_common_list = res.cost_common
                this.push_endtime_stamptime = res.blog.push_endtime_stamptime

                let grade_list_undergraduate = []
                let res_grade_list_undergraduate = res.cost_common.grade_undergraduate
                for(let i=0;i<res_grade_list_undergraduate.length;i++){
                    grade_list_undergraduate.push(res_grade_list_undergraduate[i].title)
                }
                this.grade_list_undergraduate = JSON.stringify(grade_list_undergraduate).replace(/\[|]|"/g,"")

                let grade_list_specialty = []
                let res_grade_list_specialty = res.cost_common.grade_specialty
                for(let i=0;i<res_grade_list_specialty.length;i++){
                    grade_list_specialty.push(res_grade_list_specialty[i].title)
                }
                this.grade_list_specialty = JSON.stringify(grade_list_specialty).replace(/\[|]|"/g,"")

                let school_list = res.cost_common_school
                let undergraduate_num = '0'
                let specialty_num = '0'
                for(let i=0;i<school_list.length;i++){
                    school_list[i].news_num = '0'
                    if(school_list[i].school_type_text == "undergraduate"){
                        undergraduate_num++
                    }
                    if(school_list[i].school_type_text == "specialty"){
                        specialty_num++
                    }
                }
                if(undergraduate_num == '0'){
                    this.undergraduate_display = '1'
                }
                if(specialty_num == '0'){
                    this.specialty_display = '1'
                }
                this.school_list = school_list
                this.recruit_date_new = res.blog.push_endtime
            })
            user_info({token:token}).then((res)=>{
                console.log(res,'用户信息')
                let balance = res.data.balance  // 	余额
                let bonus_fee = res.data.bonus_fee // 奖励金
                let frozen_fee = res.data.frozen_fee  // 冻结金额
                let deposit_fee = res.data.deposit_fee  // 押金金额
                let available_balance = ''      // 可用余额

                this.balance = balance
                this.bonus_fee = bonus_fee
                this.frozen_fee = frozen_fee
                this.deposit_fee = deposit_fee
                this.available_balance = balance*1 + bonus_fee*1
            })
        },

        methods: {
            // 充值
            showDialog(visible) {
                this.dialog_visible = visible;
            },
        },
    }
</script>