<style scoped>
.y_recharge_1{
    width: 100%;
    height: 100vh;
    background: #F4F5F7;
    position: fixed;
    z-index: 11;
    top: 0rem;
    left: 0rem;
    overflow: scroll;
}
.y_recharge_2{
    width: 100%;
    height: 5.8rem;
    background: #FECE0A;
    position: relative;
}
.y_recharge_3{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0.68rem;
}
.y_recharge_3 p{
    font-size: 0.26rem;
    color: #333;
    margin-right: 0.1rem;
}
.y_recharge_3 img{
    width: 0.2rem;
    height: 0.2rem;
}
.y_recharge_4{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.2rem;
}
.y_recharge_4 h2{
    font-size: 0.48rem;
    color: #040000;
    font-weight: 600;
    margin-right: 0.1rem;
}
.y_recharge_4 h3{
    font-size: 0.22rem;
    color: #040000;
}
.y_recharge_5{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 0.4rem;
    padding: 0rem 0.25rem;
}
.y_recharge_6{
    width: 3.4rem;
    margin-right: 0.2rem;
    background: #fff;
    height: 1.5rem;
    border-radius: 0.2rem;
    margin-bottom: 0.2rem;
    box-shadow: 0 0 0.3rem #eee;
}
.y_recharge_5 .y_recharge_6:nth-child(2n){
    margin-right: 0rem;
}
.y_recharge_7{
    display: flex;
    justify-content: flex-start;
    padding: 0.2rem 0.2rem 0rem 0.3rem;
}
.y_recharge_8{
    display: flex;
    justify-content: flex-start;
    flex: 1;
    align-content: center;
    flex-wrap: wrap;
}
.y_recharge_9{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.y_recharge_9 p{
    font-size: 0.26rem;
    color: #333;
    margin-right: 0.1rem;
    line-height: 0.5rem;
}
.y_recharge_9 img{
    width: 0.2rem;
    height: 0.2rem;
}
.y_recharge_10{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.1rem;
}
.y_recharge_10 h2{
    color: #040000;
    font-size: 0.36rem;
    margin-right: 0.1rem;
}
.y_recharge_10 h3{
    color: #040000;
    font-size: 0.22rem;
}
.y_recharge_11{
    width: 1.2rem;
}
.y_recharge_11 p{
    width: 100%;
    line-height: 0.5rem;
    border-radius: 0.5rem;
    background: #FECE0A;
    color: #333;
    font-size: 0.24rem;
    text-align: center;
}
.y_recharge_12{
    width: calc(100% - 0.5rem);
    padding: 0.1rem 0.25rem 0.55rem;
    background: #fff;
}
.y_recharge_13{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding-left: 0.3rem;
    height: 1.2rem;
}
.y_recharge_13 h2{
    font-size: 0.32rem;
    color: #333;
    margin-right: 0.35rem;
}
.y_recharge_13 p{
    color: #999;
    font-size: 0.24rem;
}
.y_recharge_13 h2::after{
    position: absolute;
    content: '';
    width: 0.1rem;
    height: 0.32rem;
    background: #FECE0A;
    border-radius: 0.5rem;
    left: 0rem;
    top: 50%;
    transform: translate(0%,-50%);
}
.y_recharge_14{
    width: 100%;
    margin-top: 0.35rem;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.y_recharge_15{
    width: 2.16rem;
    margin-right: 0.2rem;
    background: #fff;
    border-radius: 0.2rem;
    border: 0.05rem solid #FECE0A;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    position: relative;
    transition: ease 0.5s all;
    margin-bottom: 0.2rem;
}
.y_recharge_14 .y_recharge_15:nth-child(3n){
    margin-right: 0rem;
}
.y_recharge_15 p{
    width: 100%;
    text-align: center;
    font-size: 0.22rem;
    color: #000;
}
.y_recharge_15 p span{
    font-size: 0.36rem;
    color: #000;
    display: inline-block;
    padding: 0rem 0.05rem;
}
.y_recharge_15 h2{
    margin-top: 0.15rem;
    display: inline-block;
    background: #FECE0A;
    color: #000;
    font-size: 0.24rem;
    border-radius: 0.2rem;
    padding: 0rem 0.15rem;
    line-height: 0.45rem;
    height: 0.4rem;
    transition: ease 0.5s all;
}
.y_recharge_16{
    position: absolute;
    width: 0.3rem;
    height: 0.3rem;
    top: 0.1rem;
    right: 0.1rem;
    opacity: 0;
    transition: ease 0.5s all;
}
.y_recharge_16 img{
    width: 100%;
}

.y_recharge_15_active{
    background: #FECE0A;
    transition: ease 0.5s all;
}
.y_recharge_15_active h2{
    background: #000;
    color: #FECE0A;
    transition: ease 0.5s all;
}
.y_recharge_15_active .y_recharge_16{
    transition: ease 0.5s all;
    opacity: 1;
}
.y_recharge_17{
    margin-top: 0.45rem;
    width: 100%;
}
.y_recharge_17 p{
    width: 100%;
    text-align: center;
    background: #FECE0A;
    line-height: 1rem;
    border-radius: 0.2rem;
    color: #333;
    font-size: 0.32rem;
}
.y_recharge_none{
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
    display: inline-block;
}
.y_recharge_none p{
    font-size: 0.3rem;
    line-height: 0.5rem;
    padding: 0rem 0.25rem;
    color: #000;
    background: #eee;
    border-radius: 0.1rem;
}

.y_recharge_gx1{
    flex-wrap: wrap;
}
.y_recharge_gx2{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: initial;
}
.y_recharge_gx2 .y_recharge_11{
    width: 1.4rem;
}
.y_recharge_gx2 .y_recharge_11 p{
    line-height: 0.52rem;
}
</style>
<template v-show='recharge_value'>
    <div class='y_recharge_1'>
        <div class='y_recharge_2'>
            <div class='y_recharge_3'>
                <p>可用余额</p>
                <img src="../../../images/icon_23.png" alt="">
            </div>
            <div class='y_recharge_4'>
                <h2>{{available_balance}}</h2>
                <h3>元</h3>
            </div>
            <div class='y_recharge_5'>
                <div class='y_recharge_6'>
                    <div class='y_recharge_7 y_recharge_gx1'>
                        <div class='y_recharge_8 y_recharge_gx2'>
                            <div class='y_recharge_9'>
                                <p>本金余额</p>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                            <div class='y_recharge_11'>
                                <p>退余额</p>
                            </div>
                        </div>
                        <div class='y_recharge_10'>
                            <h2>{{balance}}</h2>
                            <h3>元</h3>
                        </div>
                    </div>
                </div>
                <div class='y_recharge_6'>
                    <div class='y_recharge_7'>
                        <div class='y_recharge_8'>
                            <div class='y_recharge_9'>
                                <p>获赠余额</p>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                            <div class='y_recharge_10'>
                                <h2>{{bonus_fee}}</h2>
                                <h3>元</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='y_recharge_6'>
                    <div class='y_recharge_7'>
                        <div class='y_recharge_8'>
                            <div class='y_recharge_9'>
                                <p>待扣余额</p>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                            <div class='y_recharge_10'>
                                <h2>{{frozen_fee}}</h2>
                                <h3>元</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='y_recharge_6'>
                    <div class='y_recharge_7'>
                        <div class='y_recharge_8'>
                            <div class='y_recharge_9'>
                                <p>押金</p>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                            <div class='y_recharge_10'>
                                <h2>{{deposit_fee}}</h2>
                                <h3>元</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='y_recharge_none' @click="recharge_none">
                <p>返回</p>
            </div>
        </div>
        <div class='y_recharge_12'>
            <div class='y_recharge_13'>
                <h2>充值金额</h2>
                <p>获赠金额仅限消费使用不可退</p>
            </div>
            <div class='y_recharge_14'>
                <div class='y_recharge_15' v-for="recharge_list in recharge_lists" :key="recharge_list.id" @click="recharge_dele(recharge_list.id)" :class="recharge_list.is == '0'?'':'y_recharge_15_active'">
                    <p>充<span>{{recharge_list.balance}}</span>元</p>
                    <h2>送{{recharge_list.bonus_fee}}元</h2>
                    <div class='y_recharge_16'>
                        <img src="../../../images/icon_22.png" alt="">
                    </div>
                </div>
            </div>
            <div class='y_recharge_17' @click="recharge_button()">
                <p>充值</p>
            </div>
            <div class='y_recharge_17' @click="recharge_none">
                <p>返回</p>
            </div>
        </div>
    </div>

</template>

<script>
// import './style.css'
import axios from 'axios'   // 接口
import {user_info} from '../../api/request.js' // 用户信息
import {add_recharge} from '../../api/request.js' // 添加充值
import {recharge_template_list} from '../../api/request.js' // 充值模板

export default {

    data(){
      return {
        visible: this.dialog_visible,
        recharge_lists:[
            {index:'1',full:'500',send:'100',is:'1'},
            {index:'2',full:'1000',send:'300',is:'0'},
            {index:'3',full:'2000',send:'800',is:'0'},
        ],
        balance:'',      // 余额
        bonus_fee:'',    // 奖励金
        frozen_fee:'',   // 冻结金额
        deposit_fee:'',  // 押金金额
        available_balance:'', // 可用余额
        recharge_template_id:'',  // 充值模板id
        token:'',
      }
    },
    components:{
        axios,
    },
    // 进入页面开始执行
    mounted(){
        // 用户信息
        let token = this.$cookies.get('token')
        if(token == null){
            this.$message({
                message: '请先登录！',
                duration:'2000'
            })
            var that = this;
            setTimeout(function () {
                that.$router.push({path:'/login'}) 
            },2500);
        }
        this.token = token

        user_info({token:token}).then((res)=>{
            console.log(res,'用户信息')
            sessionStorage.setItem("user",JSON.stringify(res.data))
            let balance = res.data.balance  // 	余额
            let bonus_fee = res.data.bonus_fee // 奖励金
            let frozen_fee = res.data.frozen_fee  // 冻结金额
            let deposit_fee = res.data.deposit_fee  // 押金金额
            let available_balance = ''      // 可用余额

            this.balance = balance
            this.bonus_fee = bonus_fee
            this.frozen_fee = frozen_fee
            this.deposit_fee = deposit_fee
            this.available_balance = balance*1 + bonus_fee*1
        })

        // 充值模板
        recharge_template_list({token:token}).then((res)=>{
            console.log(res,'充值模板')
            let recharge_lists = res.data
            for(let i=0;i<recharge_lists.length;i++){
                recharge_lists[i].is = '0'
            }
            this.recharge_lists = recharge_lists
        })
    },
    methods: {
        // 选择充值金额
        recharge_dele(id){
           console.log(id)
           let recharge_lists_list = this.recharge_lists
           let recharge_template_id = this.recharge_template_id
           for(let i=0;i<recharge_lists_list.length;i++){
               recharge_lists_list[i].is = '0'
               if(recharge_lists_list[i].id == id){
                   recharge_lists_list[i].is = '1'
                   this.recharge_template_id = recharge_lists_list[i].id
               }
           }
        },
        // 添加充值  调起微信支付最后做
        recharge_button(){
            let token = this.token
            let recharge_template_id = this.recharge_template_id
            if(recharge_template_id == ''){
                this.$message({
                    message:'请选择充值金额',
                    duration:'2000'
                })
            }else{
                add_recharge({token:token,recharge_template_id:recharge_template_id}).then((res)=>{
                    console.log(res,'添加充值')
                    if(res.error_code == '0'){
                        function onBridgeReady(){
                            WeixinJSBridge.invoke(
                                'getBrandWCPayRequest', {
                                   "appId":res.data.appid,     //公众号ID，由商户传入     
                                   "timeStamp":res.data.time,         //时间戳，自1970年以来的秒数     
                                   "nonceStr":res.data.nonce_str, //随机串     
                                   "package":'prepay_id='+res.data.prepay_id,     
                                   "signType":'MD5',         //微信签名方式：     
                                   "paySign":res.data.paySign //微信签名 
                                },
                            function(res){
                                if(res.err_msg == "get_brand_wcpay_request:ok" ){
                                    // 使用以上方式判断前端返回,微信团队郑重提示：
                                    //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                                    this.$message({
                                        message:'支付成功',
                                        duration:'2000'
                                    })
                                }else{
                                    this.$message({
                                        message:'支付取消',
                                        duration:'2000'
                                    })
                                }
                           }); 
                        }
                        if (typeof WeixinJSBridge == "undefined"){
                            if( document.addEventListener ){
                                document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                            }else if (document.attachEvent){
                                document.attachEvent('WeixinJSBridgeReady', onBridgeReady); 
                                document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
                            }
                            }else{
                                onBridgeReady();
                        }
                        
                    }else{
                        this.$message({
                            message:res.msg,
                            duration:'2000'
                        })
                    }
                })
            }
        },
        recharge_none() {
          // 触发父组件中的dialogVisibleEvent事件，并传递参数
          this.$emit('dialogVisibleEvent', false);
        },

    },
}
</script>