<template>
    <div class="y_push_date_tg_1">
        <div class="y_push_date_tg_2">
            <div class="y_push_date_tg_3">
                <p>余额<span>{{ available_balance }}</span>元</p>
            </div>
            <div class="y_push_date_tg_4" >
                <p @click="showDialog(true)">充值余额</p>
            </div>
        </div>
        <div class="y_push_date_tg_5 y_push_date_zp_1" >
            <div class="y_push_date_tg_9">
                <p>已有<span>{{res_list_recruit.view_number_business}}</span>人浏览到此条招聘信息</p>
            </div>
            <div class="y_push_date_tg_6">
                <div class="y_push_date_tg_7">
                    <p>已有<span>{{res_list_recruit_consulting.people_number-res_list_recruit_consulting.left_people_number}}</span>人咨询，剩余<span>{{res_list_recruit_consulting.left_people_number}}</span>个名额</p>
                </div>
                <div class="y_push_date_tg_8" @click="publish_pop()">
                    <p>+名额</p>
                </div>
            </div>
            <div class="y_push_date_tg_6">
                <div class="y_push_date_tg_7">
                    <p>招聘截止倒计时：</p>
                    <h2><span>{{date_day}}</span>天<span>{{date_hour}}</span>时<span>{{date_minute}}</span>分<span>{{date_second}}</span>秒</h2>
                </div>
                <div class="y_push_date_tg_8">
                    <p @click="djs_show(1)">+时</p>
                </div>
            </div>
            <div class="y_push_date_tg_9">
                <p>已扣费/总预算：<span>{{res_list_recruit_consulting.total_fee - res_list_recruit_consulting.left_fee}}元 / {{res_list_recruit_consulting.total_fee}}元</span></p>
            </div>

            <div class="y_push_date_zp_4" v-if="new_places_show == '0'?false:true">
                <div class="y_push_date_zp_5">
                    <div class="y_push_date_zp_6">
                        <h2>增加咨询名额</h2>
                    </div>
                    <div class="y_push_date_zp_7">
                        <p>每人咨询扣费<span>{{res_list_recruit_consulting.consulting_price}}</span>元</p>
                    </div>
                </div>
                <div class="y_push_date_zp_8">
                    <div class="y_push_date_zp_9">
                        <el-input type="number" v-model="news_zx_input" placeholder="请输入" @input="zx_exposure"></el-input>
                        <p class="y_push_date_zp_10">人</p>
                    </div>
                    <div class="y_push_date_zp_7">
                        <p>新增预算<span>{{news_zx_input*res_list_recruit_consulting.consulting_price}}</span>元</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="y_push_date_tg_5" v-if="res_list_recruit.push_starttime == ''?false:true">
            <div class="y_push_date_tg_6">
                <div class="y_push_date_tg_7">
                    <p>给每人最多推送</p>
                    <h2>{{res_list_recruit.max_push_number}}次</h2>
                </div>
                <div class="y_push_date_tg_8">
                    <p @click="cs_pop_show()">+次数</p>
                </div>
            </div>
            <div class="y_push_date_tg_9">
                <p>已有<span>{{res_list_recruit.view_number_business}}</span>人浏览，人均浏览次数<span>{{res_list_recruit.view_number_total_business == '0'?'0':(res_list_recruit.view_number_total_business*1/res_list_recruit.view_number_business*1).toFixed(1)}}</span>次</p>
            </div>
            <div class="y_push_date_tg_9">
                <p>推送时间:{{res_list_recruit.push_starttime}} ~ {{res_list_recruit.push_endtime}}</p>
            </div>
            <div class="y_push_date_tg_6">
                <div class="y_push_date_tg_7">
                    <p>推送倒计时：</p>
                    <h2><span>{{ts_date_day}}</span>天<span>{{ ts_date_hour }}</span>时<span>{{ts_date_minute}}</span>分<span>{{ ts_date_second }}</span>秒</h2>
                </div>
                <div class="y_push_date_tg_8">
                    <p @click="djs_show(2)">+时</p>
                </div>
            </div>
            <div class="y_push_date_tg_9">
                <p>已扣费/总预算：<span>{{res_list_cost_common.total_fee - res_list_cost_common.left_fee}}元 / {{res_list_cost_common.total_fee}}元</span></p>
            </div>
            <div class="y_push_date_tg_6">
                <div class="y_push_date_tg_7">
                    <p>已完成/预设总曝光量：</p>
                    <h2><span>{{res_list_cost_common.exposure_number-res_list_cost_common.left_exposure_number}} / {{res_list_cost_common.exposure_number}}</span></h2>
                </div>
                <div class="y_push_date_tg_8" @click="new_publish_pop()">
                    <p>+曝光量</p>
                </div>
            </div>
        </div>

        <div class="y_push_date_tg_10" :class="undergraduate_display == '0'?'':'y_display_none'">
            <div class="y_push_date_tg_11">
                <p>本科学校中推送给</p>
                <h2>{{grade_list_undergraduate}}</h2>
            </div>
            <div class="y_push_date_tg_12">
                <p>单次曝光<span>{{res_list_cost_common.common_push_undergraduate_exposure_price}}</span>元</p>
                <p>已完成/预设总曝光量</p>
                <p>已扣费/预算</p>
            </div>
        </div>
        <div class="y_push_date_tg_13" :class="undergraduate_display == '0'?'':'y_display_none'">
            <!-- 显示本科学校 -->
            <div class="y_push_date_tg_14" :class="item.school_type_text == 'undergraduate' ?'':'y_display_none'" v-for="(item,index) in school_list" :key="index" >
                <div class="y_push_date_tg_15">
                    <div class="y_push_date_tg_16">
                        <h2>{{item.school_title}}</h2>
                    </div>
                </div>
                <div class="y_push_date_tg_15">
                    <div class="y_push_date_tg_16">
                        <h2>{{item.exposure_number-item.left_exposure_number}}/{{item.exposure_number}}</h2>
                    </div>
                    <div class="y_push_date_tg_17" :class="new_exposure_num_val == '0'?'y_display_none':''">
                        <p>增加曝光量</p>
                        <div class="y_push_date_tg_18">
                            <el-input v-model="item.news_num" placeholder="请输入" @input="school_exposure"></el-input>
                        </div>
                    </div>
                </div>
                <div class="y_push_date_tg_15">
                    <div class="y_push_date_tg_16 y_push_date_tg_16_color">
                        <h2>{{item.total_fee-item.left_fee}}元/{{item.total_fee}}元</h2>
                    </div>
                    <div class="y_push_date_tg_17" :class="new_exposure_num_val == '0'?'y_display_none':''">
                        <p>新增预算</p>
                        <div class="y_push_date_tg_18">
                            <p>{{(item.news_num*item.exposure_price).toFixed(2)}}元</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="y_push_date_tg_10" :class="specialty_display == '0'?'':'y_display_none'">
            <div class="y_push_date_tg_11">
                <p>专科学校中推送给</p>
                <h2>{{grade_list_specialty}}</h2>
            </div>
            <div class="y_push_date_tg_12">
                <p>单次曝光<span>{{res_list_cost_common.common_push_specialty_exposure_price}}</span>元</p>
                <p>已完成/预设总曝光量</p>
                <p>已扣费/预算</p>
            </div>
        </div>
        <div class="y_push_date_tg_13" :class="specialty_display == '0'?'':'y_display_none'">
            <!-- 显示专科学校 -->
            <div class="y_push_date_tg_14" :class="item.school_type_text == 'specialty' ?'':'y_display_none'" v-for="(item,index) in school_list" :key="index" >
                <div class="y_push_date_tg_15">
                    <div class="y_push_date_tg_16">
                        <h2>{{item.school_title}}</h2>
                    </div>
                </div>
                <div class="y_push_date_tg_15">
                    <div class="y_push_date_tg_16">
                        <h2>{{item.exposure_number-item.left_exposure_number}}/{{item.exposure_number}}</h2>
                    </div>
                    <div class="y_push_date_tg_17" :class="new_exposure_num_val == '0'?'y_display_none':''">
                        <p>增加曝光量</p>
                        <div class="y_push_date_tg_18">
                            <el-input v-model="item.news_num" placeholder="请输入" @input="school_exposure"></el-input>
                        </div>
                    </div>
                </div>
                <div class="y_push_date_tg_15">
                    <div class="y_push_date_tg_16 y_push_date_tg_16_color">
                        <h2>{{item.total_fee-item.left_fee}}元/{{item.total_fee}}元</h2>
                    </div>
                    <div class="y_push_date_tg_17" :class="new_exposure_num_val == '0'?'y_display_none':''">
                        <p>增加曝光量</p>
                        <div class="y_push_date_tg_18">
                            <p>{{(item.news_num*item.exposure_price).toFixed(2)}}元</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="y_push_date_tg_19 y_push_date_zp_3"></div>
        <div class="y_push_date_tg_20 y_push_date_zp_2" v-show="hideshow">
            <div class="y_push_date_tg_21">
                <div class="y_push_date_tg_22">
                    <p>新增预算总计<span>{{news_total_price}}</span>元</p>
                    <p :class="balance_value == '0'?'y_display_none':'y_display_block'">，余额不足，<span @click="showDialog(true)">点击充值</span></p>
                </div>
                <div class="y_push_date_tg_24 y_number_places_4">
                    <h2 class="y_number_places_3" @click="back_to">返回</h2>
                    <p class="y_number_places_5" @click="submit">确定</p>
                </div>
            </div>
        </div>

        <!-- 公共弹窗 阴影-->
        <div class='y_comm_shadows' :class="comm_shadows_value == '1'?'y_comm_shadows_block':''"></div>
        <!-- 名额 弹窗 -->
        <div class='y_publish_pop_1 y_number_places_0' :class="publish_pop_value == '0'?'':'y_publish_pop_1_active'">
            <div class='y_publish_pop_2'>
                <div class='y_publish_pop_3 y_number_places_1'>
                    <h2>新增名额</h2>
                     <!-- <h2>给每人最多推送<p>N</p></h2> -->
                </div>
                <div class='y_publish_pop_4 y_number_places_2'>
                    <el-input v-model="number_places_num" type="text" placeholder="请填写人数"></el-input>
                </div>
                <!-- <div class='y_publish_pop_5'>
                    <p>单次曝光<span>N</span>元,预算<span>M</span>元</p>
                </div> -->
                <div class='y_publish_pop_6'>
                    <p class='y_publish_pop_7' @click="publish_no()">返回</p>
                    <p class='y_publish_pop_8' @click="publish_yes()">确定</p>
                </div>
            </div>
        </div>
        <!-- <div class='y_publish_pop_1' :class="publish_pop_value == '0'?'':'y_publish_pop_1_active'">
            <div class='y_publish_pop_2'>
                <div class='y_publish_pop_3'>
                    <h2>您选择推送给大一、大二、大三的用户总数为<p>N</p></h2>
                     <h2>给每人最多推送<p>N</p></h2>
                </div>
                <div class='y_publish_pop_4'>
                    <input type="text" placeholder="请设置曝光量">
                </div>
                <div class='y_publish_pop_5'>
                    <p>单次曝光<span>N</span>元,预算<span>M</span>元</p>
                </div>
                <div class='y_publish_pop_6'>
                    <p class='y_publish_pop_7' @click="publish_no()">返回</p>
                    <p class='y_publish_pop_8'>确定</p>
                </div>
            </div>
        </div> -->
        <!-- 倒计时 -->
        <div class="y_push_date_zp_11" :class="djs_pop_value == '0'?'':'y_push_date_zp_11_active'">
            <div class="y_push_date_zp_12">
                <h2 :class="class_id == '1' ? '':'y_display_none'">招聘截止时间延时到</h2>
                <h2 :class="class_id == '2' ? '':'y_display_none'">推送截止时间延时到</h2>
            </div>
            <div class="y_push_date_zp_13">
                <div class="y_push_date_zp_14 y_push_date_zp_14_1">
                    <el-input type="number" v-model="tc_date_year" :placeholder="class_id == '1'?recruit_endtime.year:push_endtime.year" @input="date_input('year')"></el-input>
                </div>
                <div class="y_push_date_zp_15">
                    <p>年</p>
                </div>
                <div class="y_push_date_zp_14 y_push_date_zp_14_2">
                    <el-input type="number" v-model="tc_date_month" :placeholder="class_id == '1'?recruit_endtime.month:push_endtime.month" @input="date_input('month')"></el-input>
                </div>
                <div class="y_push_date_zp_15">
                    <p>月</p>
                </div>
                <div class="y_push_date_zp_14 y_push_date_zp_14_2">
                    <el-input type="number" v-model="tc_date_day" :placeholder="class_id == '1'?recruit_endtime.day:push_endtime.day" @input="date_input('day')"></el-input>
                </div>
                <div class="y_push_date_zp_15">
                    <p>日</p>
                </div>
                <div class="y_push_date_zp_14 y_push_date_zp_14_2">
                    <el-input type="number" v-model="tc_date_time" :placeholder="class_id == '1'?recruit_endtime.time:push_endtime.time" @input="date_input('time')"></el-input>
                </div>
                <div class="y_push_date_zp_15">
                    <p>时</p>
                </div>
                <div class="y_push_date_zp_14 y_push_date_zp_14_2">
                    <el-input type="number" v-model="tc_date_minute" :placeholder="class_id == '1'?recruit_endtime.minute:push_endtime.minute" @input="date_input('minute')"></el-input>
                </div>
                <div class="y_push_date_zp_15">
                    <p>分</p>
                </div>
            </div>
            <div class="y_push_date_zp_16">
                <p class="y_push_date_zp_17" @click="djs_hide()">返回</p>
                <p class="y_push_date_zp_18" @click="djs_determine()">确定</p>
            </div>
        </div>
        <!-- 次数 -->
        <div class="y_push_date_zp_19" :class="cs_pop_value == '0'?'':'y_push_date_zp_19_active'">
            <div class="y_push_date_zp_20">
                <h2>给每人最多推送 </h2>
                <!-- <p>{{res_list_recruit.max_push_number}}次</p> -->
            </div>
            <div class="y_push_date_zp_21">
                <!-- <p>+</p> -->
                <el-input v-model="max_push_number_input" placeholder=""></el-input>
                <!-- <p>次</p> -->
            </div>
            <div class="y_push_date_zp_20">
                <h2>给同一人一天内最多推送  </h2>
                <!-- <p>{{res_list_recruit.max_push_number_day}}次</p> -->
            </div>
            <!-- <div class="y_push_date_zp_21">
                <p>+</p>
                <div class="y_push_date_zp_22">
                    <p class="y_push_date_zp_22_active">1</p>
                    <p>2</p>
                </div>
            </div> -->
            <div class="y_push_date_zp_21 y_push_date_zp_21_p">
                <!-- <p>+</p> -->
                <p v-for="oneday_onepeople in oneday_onepeoples" :key="oneday_onepeople.index" :class='oneday_onepeople.is == "0"?"":"y_person_push_4_active"' @click="oneday_onepeople_dete(oneday_onepeople.index)">{{oneday_onepeople.text}}</p>
                <!-- <el-input v-model="max_push_number_day_input" placeholder=""></el-input> -->
                <!-- <p>次</p> -->
            </div>
            <div class='y_publish_pop_6'>
                <p class='y_publish_pop_7' @click="cs_pop_no()">返回</p>
                <p class='y_publish_pop_8' @click="cs_pop_yes()">确定</p>
            </div>
        </div>
    </div>
    <kefu></kefu>
    <recharge v-show="dialog_visible" :dialog_visible="dialog_visible" @dialogVisibleEvent="showDialog"></recharge>
</template>

<style scoped>
    .y_push_date_tg_1{
        width: 100%;
        min-height: 100vh;
        background: #F4F5F7;
    }
    .y_push_date_tg_2{
        padding: 0rem 0.45rem;
        height: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_push_date_tg_3 p{
        font-size: 0.28rem;
        color: #333;
    }
    .y_push_date_tg_3 p span{
        color:#FECE0A;
        font-size: .28rem;
    }
    .y_push_date_tg_4 p{
        font-size: 0.28rem;
        color: #333;
        text-decoration:underline
    }
    .y_push_date_tg_5{
        padding: 0.28rem 0.25rem;
        background: #fff;
    }
    .y_push_date_tg_6{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.15rem;
    }
    .y_push_date_tg_7{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_date_tg_7 p{
        font-size: 0.24rem;
        color: #999;
    }
    .y_push_date_tg_7 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_7 h2{
        margin-left: 0.15rem;
        color: #333;
        font-weight: 600;
        font-size: 0.28rem;
    }
    .y_push_date_tg_7 h2 span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_8{
        display: inline-block;
        background: #FECE0A;
        padding: 0rem 0.15rem;
        height: 0.46rem;
        border-radius: 0.1rem;
    }
    .y_push_date_tg_8 p{
        line-height: 0.46rem;
        color: #333;
        font-size: 0.26rem;
    }
    .y_push_date_tg_9{
        width: 100%;
        margin-bottom: 0.15rem;
    }
    .y_push_date_tg_9 p{
        color: #999;
        font-size: 0.24rem;
        line-height: 0.46rem;
    }
    .y_push_date_tg_9 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_10{
        width: 100%;
        padding-top: 0.1rem;
    }
    .y_push_date_tg_11{
        padding: 0rem 0.35rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.8rem;
    }
    .y_push_date_tg_11 p{
        color:#333;
        font-size: 0.28rem;
    }
    .y_push_date_tg_11 h2{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_12{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.8rem;
    }
    .y_push_date_tg_12 p{
        flex: 1;
        text-align: center;
        color: #999;
        font-size: 0.26rem;
    }
    .y_push_date_tg_12 p span{
        color: #FECE0A;
        font-size: 0.26rem;
    }
    .y_push_date_tg_13{
        width: 100%;
        background: #fff;
        padding-bottom: 0.4rem;
    }
    .y_push_date_tg_14{
        display: flex;
        justify-content: flex-start;
    }
    .y_push_date_tg_15{
        flex:1;
    }
    .y_push_date_tg_16{
        width: 100%;
    }
    .y_push_date_tg_16 h2{
        width: 100%;
        line-height: 1rem;
        text-align: center;
        color: #333;
        font-size: 0.28rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;   
    }
    .y_push_date_tg_16_color h2{
        color: #FECE0A;
    } 
    .y_push_date_tg_17{
        width: 100%;
    }
    .y_push_date_tg_17>p{
        font-size: 0.28rem;
        color: #333;
        width: 100%;
        text-align: center;
    }
    .y_push_date_tg_18{
        width: 100%;
        text-align: center;
        margin-top: 0.25rem;
    }
    .y_push_date_tg_18 input{
        width: 1.6rem;
        height: 0.8rem;
        border-radius: 0.1rem;
        background: #F7F8FA;
        overflow: hidden;
        padding: 0rem;
        line-height: 0.8rem;
        margin: 0 auto;
        font-size: 0.28rem;
        color: #ccc;
        text-align: center;
        border: none;
    }
    
    .y_push_date_tg_18 /deep/ input{
        width: 1.6rem;
        height: 0.8rem;
        border-radius: 0.1rem;
        background: #F7F8FA;
        overflow: hidden;
        padding: 0rem;
        line-height: 0.8rem;
        margin: 0 auto;
        font-size: 0.28rem;
        color: #ccc;
        text-align: center;
        border: none;
    }
    .y_push_date_tg_18 p{
        color: #FECE0A;
        font-size: 0.28rem;
        line-height: 0.8rem;
    }
    .y_push_date_tg_19{
        width: 100%;
        height: 5rem;
        background: transparent;
    }
    .y_push_date_tg_20{
        width: 100%;
        height: 2.7rem;
        background: #fff;
        position: fixed ;
        left: 0rem;
        bottom: 0rem;
    }
    .y_push_date_tg_21{
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        padding: 0rem 0.25rem;
        height: 100%;
    }
    .y_push_date_tg_22{
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .y_push_date_tg_22 p{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_date_tg_22 p span{
        color:#FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_23{
        display: flex;
        margin-top: 0.2rem;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .y_push_date_tg_23 p{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_date_tg_23 h2{
        color: #FECE0A;
        font-size: 0.28rem;
        text-decoration:underline;
    }
    .y_push_date_tg_24{
        width: 100%;
        margin-top: 0.3rem;
    }
    .y_push_date_tg_24 p{
        height: 0.8rem;
        background: #FECE0A;
        line-height: 0.8rem;
        width: 100%;
        border-radius: 0.1rem;
        text-align: center;
        font-size:0.32rem;
        color: #333;
        font-weight: 600;
    }


    /* 招聘 */
    .y_push_date_zp_1{
        margin-bottom: 0.2rem;
    }
    .y_push_date_zp_2{
        height: 2.2rem;
    }
    .y_push_date_zp_3{
        height: 4rem;
    }
    .y_push_date_zp_4{
        border-top: 0.01rem solid #F3F3F3;
        width: 100%;
        margin-top: 0.4rem;
        padding-bottom: 0.1rem;
    }
    .y_push_date_zp_5{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1rem;
    }
    .y_push_date_zp_6 h2{
        font-size: 0.3rem;
        color: #333;
        font-weight: 600;
    }
    .y_push_date_zp_7 p{
        color: #999;
        font-size: 0.28rem;
    }
    .y_push_date_zp_7 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_zp_8{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0.8rem;
    }
    .y_push_date_zp_9{
        width: 2.8rem;
        background: #F7F8FA;
        position: relative;
        height: 100%;
    }
    .y_push_date_zp_9 input{
        width: 2rem;
        height: 0.8rem;
        font-size: 0.28rem;
        color: #333;
        background: transparent;
        border: none;
        line-height: 0.8rem;
        padding: 0rem 0rem 0rem 0.2rem;
    }
    .y_push_date_zp_9 /deep/ input{
        width: 2rem;
        height: 0.8rem;
        font-size: 0.28rem;
        color: #333;
        background: transparent;
        border: none;
        line-height: 0.8rem;
        padding: 0rem 0rem 0rem 0.2rem;
    }
    .y_push_date_zp_10{
        position: absolute;
        top: 50%;
        display: inline-block;
        color: #333;
        font-size: 0.28rem;
        right: 0.3rem;
        transform: translate(0%,-50%);
    }
    .y_push_date_zp_11{
        width: 6.4rem;
        height: 3.6rem;
        background: #fff;
        border-radius: 0.1rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 20;
        display: none;
        flex-wrap: wrap;
        align-content: center;
    }
    .y_push_date_zp_11_active{
        display: flex;
    }
    .y_push_date_zp_12{
        width: 100%;
        text-align: center;
    }
    .y_push_date_zp_12 h2{
        color:#999;
        font-size: 0.3rem;
    }
    .y_push_date_zp_13{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 0.3rem;
    }
    .y_push_date_zp_14{
        background: #F7F8FA;
        height: 0.8rem;
        border-radius: 0.1rem;
        overflow: hidden;
    }
    .y_push_date_zp_14 input{
        height: 0.8rem;
        padding: 0rem 0.1rem 0rem 0.15rem;
        background: transparent;
        width: 100%;
        border: none;
        font-size: 0.28rem;
        color: #FECE0A;
    }
    .y_push_date_zp_14 /deep/ input{
        height: 0.8rem;
        padding: 0rem 0.1rem 0rem 0.15rem;
        background: transparent;
        width: 100%;
        border: none;
        font-size: 0.28rem;
        color: #FECE0A;
    }
    .y_push_date_zp_14_1{
        width: 1rem;
    }
    .y_push_date_zp_14_2{
        width: 0.6rem;
    }
    .y_push_date_zp_15{
        display: inline-block;
        margin: 0rem 0.1rem;
    }
    .y_push_date_zp_15 p{
        font-size: 0.28rem;
        color: #333;
    }
    .y_push_date_zp_16{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.35rem;
        width: 100%;
    }
    .y_push_date_zp_16 p{
        width: 2rem;
        height: 0.7rem;
        border-radius: 1rem;
        text-align: center;
        line-height: 0.7rem;
        font-size: 0.3rem;
    }
    .y_push_date_zp_17{
        background: #ECECEC;
        color: #666;
        margin-right: 0.1rem;
    }
    .y_push_date_zp_18{
        color: #333;
        background: #FECE0A;
    }
    .y_push_date_zp_19{
        width: 5.2rem;
        height: 5.6rem;
        background: #fff;
        border-radius: 0.1rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        position: fixed;
        display: none;
        align-content: center;
        flex-wrap: wrap;
        z-index: 20;
    }
    .y_push_date_zp_19_active{
        display: flex;
    }
    .y_push_date_zp_20{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.3rem;
    }
    .y_push_date_zp_20 h2{
        font-size: 0.3rem;
        color: #999;
    }
    .y_push_date_zp_20 p{
        font-size: 0.3rem;
        color: #333;
        margin-left: 0.1rem;
    }
    .y_push_date_zp_21{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.3rem;
    }
    .y_push_date_zp_21 p{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_date_zp_21 .el-input{
        width: 1.3rem;
        margin: 0rem 0.1rem;
        padding:0rem;
        overflow: hidden;
    }
    .y_push_date_zp_21 input{
        width: 1.3rem;
        height: 0.8rem;
        line-height: 0.8rem;
        background: #F7F8FA;
        text-align: center;
        border-radius: 0rem;
        border: none;
        color: #333;
        font-size: 0.28rem;
        padding:0rem 0.1rem;
    }
    .y_push_date_zp_21 /deep/ input{
        width: 1.3rem;
        height: 0.8rem;
        line-height: 0.8rem;
        background: #F7F8FA;
        text-align: center;
        border-radius: 0rem;
        border: none;
        color: #333;
        font-size: 0.28rem;
        padding:0rem 0.1rem;
    }
    .y_push_date_zp_22{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: 0.5rem;
    }
    .y_push_date_zp_22 p{
        width: 1.3rem;
        height: 0.7rem;
        border-radius: 0.1rem;
        text-align: center;
        font-size: 0.3rem;
        background: #ECECEC;
        line-height: 0.7rem;
        margin-left: 0.2rem;
        transition: ease 0.5s all;
    }
    .y_push_date_zp_22 .y_push_date_zp_22_active{
        transition: ease 0.5s all;
        background: #FECE0A;
    }   
    .y_publish_pop_6{
        width: 100%;
    }
    .y_display_none{
        display: none;
    }
    .y_number_places_0{
        min-height: 3.2rem;
    }
    .y_number_places_1 h2{
        font-size: 0.34rem;
        font-weight: 600;
    }
    .y_number_places_2{
        margin: 0.2rem 0rem;
    }
    .y_number_places_2 input{
        height: 0.8rem;
        font-size: 0.28rem;
        color: #333;
        padding: 0rem 0.1rem;
    }
    .y_number_places_2 /deep/ input{
        height: 0.8rem;
        font-size: 0.28rem;
        color: #333;
        padding: 0rem 0.1rem;
    }
    .y_number_places_3 {
        flex: 1;
        height: 0.8rem;
        background: #ccc;
        line-height: 0.8rem;
        width: 100%;
        border-radius: 0.1rem;
        text-align: center;
        font-size: 0.32rem;
        color: #333;
        font-weight: 600;
        margin-right: 0.2rem;
    }
    .y_number_places_4{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_number_places_5{
        flex: 1;
    }
    .y_push_date_zp_21_p{
        transition: ease 0.5s all;
    }
    .y_push_date_zp_21_p p{
        width: 1.3rem;
        background: #F7F8FA;
        line-height: 0.8rem;
        text-align: center;
        border-radius: 0.2rem;
        color: #333;
        font-size: 0.28rem;
        margin-right: 0.2rem;
        transition: ease 0.5s all;
    }
    .y_push_date_zp_21_p .y_person_push_4_active{
        background: #FECE0A;
        transition: ease 0.5s all;
    }

</style>
<script>
    import kefu from '../../components/kefu/kefu'
    import recharge from '../../components/recharge/recharge'
    import axios from 'axios'   // 接口
    import {recruit_common_info} from '../../api/request.js'   // 招聘详情,普通推广
    import {renew_recruit_common} from '../../api/request.js'   // 招聘的续费和修改
    
    import {user_info} from '../../api/request.js' // 用户信息
    export default {
        data(){
            return {
                docmHeight: document.documentElement.clientHeight ||document.body.clientHeight,
                showHeight: document.documentElement.clientHeight ||document.body.clientHeight,
                hideshow:true,  //显示或者隐藏footer

                shade_value:'0',
                comm_shadows_value:'',
                input: '',
                publish_pop_value:'0',
                djs_pop_value:'0',
                cs_pop_value:'0',

                //公共 钱包充值
                dialog_visible: false,
                res_list:'',    // 总列表
                res_list_recruit:'',
                res_list_recruit_consulting:'',
                res_list_cost_common:'',
                recruit_id:'0', // 待推送 进入传id
                push_status:'', // 进入传值  1代表推送中，2代表已结束
                date_day:'',     // 剩余 天 招聘
                date_hour:'',    // 剩余 时 招聘
                date_minute:'',  // 剩余 分 招聘
                date_second:'',  // 剩余 秒 招聘
                ts_date_day:'',  //     剩余 天  推送  
                ts_date_hour:'',  //    剩余 时  推送   
                ts_date_minute:'',  //  剩余 分  推送   
                ts_date_second:'',  //  剩余 秒  推送   
       
                news_zx_input:'', // 新增资讯人数 
                grade_list_undergraduate:'',   // 年级数组 本科
                grade_list_specialty:'',    // 年级数组 大专
                school_list:'',    // 学校列表
                news_total_price:'0',  // 新曝光总价
                number_places_num:'',  // 新增名额
                number_places_is:'0',
                number_places_val:'',
                max_push_number_input:'',
                max_push_number_day_input:'1',
                max_push_number_is:'0',
                max_push_number_input_val:'',
                max_push_number_day_input_val:'1',

                tc_date_year:'',  // 弹窗时间 年
                tc_date_month:'', // 弹窗时间 月
                tc_date_day:'', // 弹窗时间 日
                tc_date_time:'', // 弹窗时间 时
                tc_date_minute:'', // 弹窗时间 分
                class_id:'',    // 1为招聘时 2为推送时
                recruit_endtime_stamptime:'',  // 招聘截止时间
                push_endtime_stamptime:'',  // 推送截止时间
                recruit_date_new:'',  // 新的 招聘时间
                push_date_new:'',  // 新的 推送时间


                tc_date_year_one:'',  // 弹窗时间 年  招聘
                tc_date_month_one:'', // 弹窗时间 月  招聘
                tc_date_day_one:'', // 弹窗时间 日  招聘
                tc_date_time_one:'', // 弹窗时间 时  招聘
                tc_date_minute_one:'', // 弹窗时间 分  招聘
                tc_date_year_two:'',  // 弹窗时间 年  推送
                tc_date_month_two:'', // 弹窗时间 月  推送
                tc_date_day_two:'', // 弹窗时间 日  推送
                tc_date_time_two:'', // 弹窗时间 时  推送
                tc_date_minute_two:'', // 弹窗时间 分  推送
                available_balance:'',
                new_places_show:'0',   // 增加名额 判断
                new_exposure_num_val:'0', // 判断曝光量是否显示
                recruit_endtime:'',   // placeholder 招聘
                push_endtime:'',      // placeholder 推送
                oneday_onepeoples:[
                    {index:'1',text:'1',is:'1'},
                    {index:'2',text:'2',is:'0'},
                    {index:'3',text:'3',is:'0'},
                ],
                undergraduate_display:'0',  // 没有年级时隐藏整个年级学校
                specialty_display:'0',      // 没有年级时隐藏整个年级学校
                balance_value:'0',  // 余额不足时显示
                total_Balance:'',
            }
        },  
        components:{
            kefu,
            recharge,
            axios,
        },
        created(){
            // 获取页面传参 推送类型
            if(this.$route.query.recruit_id !== undefined){
                this.recruit_id = this.$route.query.recruit_id
                this.push_status = this.$route.query.push_status
            }
        },

        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token

            //监听事件
            window.onresize = ()=>{
                return(()=>{
                    this.showHeight = document.documentElement.clientHeight || document.body.clientHeight;
                })()
            }

            let recruit_id = this.recruit_id
            let push_status = this.push_status
            if(push_status == '1'){
                recruit_common_info({token:token,recruit_id:recruit_id}).then((res) =>{
                    console.log(res,'推送中-招聘详情,信息类')
                    this.res_list_recruit = res.recruit
                    this.res_list_recruit_consulting = res.recruit_consulting
                    this.res_list_cost_common = res.cost_common
                    this.timestampToTime(res.recruit.recruit_endtime_stamptime*1)
                    this.ts_timestampToTime(res.recruit.push_endtime_stamptime*1)
                    this.recruit_endtime_stamptime = res.recruit.recruit_endtime_stamptime
                    this.push_endtime_stamptime = res.recruit.push_endtime_stamptime

                    this.recruit_endtime = {year:res.recruit.recruit_endtime.slice(0,4),month:res.recruit.recruit_endtime.slice(5,7),day:res.recruit.recruit_endtime.slice(8,10),time:res.recruit.recruit_endtime.slice(11,13),minute:res.recruit.recruit_endtime.slice(14,16)}
                    this.push_endtime = {year:res.recruit.push_endtime.slice(0,4),month:res.recruit.push_endtime.slice(5,7),day:res.recruit.push_endtime.slice(8,10),time:res.recruit.push_endtime.slice(11,13),minute:res.recruit.push_endtime.slice(14,16)}
                    let grade_list_undergraduate = []
                    let res_grade_list_undergraduate = res.cost_common.grade_undergraduate
                    for(let i=0;i<res_grade_list_undergraduate.length;i++){
                        grade_list_undergraduate.push(res_grade_list_undergraduate[i].title)
                    }
                    this.grade_list_undergraduate = JSON.stringify(grade_list_undergraduate).replace(/\[|]|"/g,"")

                    let grade_list_specialty = []
                    let res_grade_list_specialty = res.cost_common.grade_specialty
                    for(let i=0;i<res_grade_list_specialty.length;i++){
                        grade_list_specialty.push(res_grade_list_specialty[i].title)
                    }
                    this.grade_list_specialty = JSON.stringify(grade_list_specialty).replace(/\[|]|"/g,"")

                    let school_list = res.cost_common_school
                    let undergraduate_num = '0'
                    let specialty_num = '0'
                    for(let i=0;i<school_list.length;i++){
                        school_list[i].news_num = ''
                        if(school_list[i].school_type_text == "undergraduate"){
                            undergraduate_num++
                        }
                        if(school_list[i].school_type_text == "specialty"){
                            specialty_num++
                        }
                    } 
                    if(undergraduate_num == '0'){
                    this.undergraduate_display = '1'
                    }
                    if(specialty_num == '0'){
                        this.specialty_display = '1'
                    }
                    this.school_list = school_list
                    this.max_push_number_input = res.recruit.max_push_number
                    this.max_push_number_day_input = res.recruit.max_push_number_day
                    this.recruit_date_new = res.recruit.recruit_endtime
                    this.push_date_new = res.recruit.push_endtime  
                    this.max_push_number_is = '1'
                    this.max_push_number_input_val = res.recruit.max_push_number
                    this.max_push_number_day_input_val = res.recruit.max_push_number_day

                    let oneday_onepeoples = this.oneday_onepeoples
                    for(let i=0;i<oneday_onepeoples.length;i++){
                        oneday_onepeoples[i].is = '0'
                        if(oneday_onepeoples[i].index == res.recruit.max_push_number_day){
                            oneday_onepeoples[i].is = '1'
                        }
                    }
                    this.oneday_onepeoples = oneday_onepeoples
                })
            }

            user_info({token:token}).then((res)=>{
                console.log(res,'用户信息')
                let balance = res.data.balance  // 	余额
                let bonus_fee = res.data.bonus_fee // 奖励金
                let frozen_fee = res.data.frozen_fee  // 冻结金额
                let deposit_fee = res.data.deposit_fee  // 押金金额
                let available_balance = ''      // 可用余额

                this.balance = balance
                this.bonus_fee = bonus_fee
                this.frozen_fee = frozen_fee
                this.deposit_fee = deposit_fee
                this.available_balance = balance*1 + bonus_fee*1
                this.total_Balance = (res.data.balance*1) + (res.data.bonus_fee*1)  // 总共 余额
            })
        },
        watch: {
            //监听显示高度
            showHeight:function() {
                if(this.docmHeight > this.showHeight){
                    //隐藏
                    this.hideshow=false
                }else{
                    //显示
                     this.hideshow=true
                }
            }
        },
        methods: {
            // 充值
            showDialog(visible) {
                this.dialog_visible = visible;
            },
            // 曝光量 弹窗
            publish_pop(e){
                // this.comm_shadows_value = '1'
                // this.publish_pop_value = '1'
                this.new_places_show = '1'
            },
            // 曝光量 弹窗 返回
            publish_no(e){
                let number_places_is = this.number_places_is
                if(number_places_is == '1'){
                    this.comm_shadows_value = '0'
                    this.publish_pop_value = '0'
                    this.number_places_num = this.number_places_val
                }else{
                    this.number_places_num = ''
                    this.comm_shadows_value = '0'
                    this.publish_pop_value = '0'
                    
                }
            },
            // 给同一人一天内最多推送
            oneday_onepeople_dete(index){
                let oneday_onepeoples_list = this.oneday_onepeoples
                for(let i=0;i<oneday_onepeoples_list.length;i++){
                    oneday_onepeoples_list[i].is = '0'
                    if(oneday_onepeoples_list[i].index == index){
                        oneday_onepeoples_list[i].is = '1'
                        this.max_push_number_day_input = index
                        this.max_push_number_day_input_val = index
                    }
                }
                this.oneday_onepeoples = oneday_onepeoples_list
            },
            // 曝光量 弹窗
            new_publish_pop(e){
                this.new_exposure_num_val = '1'
            },
            // 曝光量 弹窗 确定
            publish_yes(e){
                this.number_places_is = '1'
                this.number_places_val = this.number_places_num
                this.comm_shadows_value = '0'
                this.publish_pop_value = '0'
            },
            // 倒计时 弹窗 显示
            djs_show(class_id){
                this.comm_shadows_value = '1'
                this.djs_pop_value = '1'
                if(class_id == '1'){
                    this.class_id = class_id
                    this.tc_date_year = this.tc_date_year_one
                    this.tc_date_month = this.tc_date_month_one
                    this.tc_date_day = this.tc_date_day_one
                    this.tc_date_time = this.tc_date_time_one
                    this.tc_date_minute = this.tc_date_minute_one
                }else if(class_id == '2'){
                    this.class_id = class_id
                    this.tc_date_year = this.tc_date_year_two
                    this.tc_date_month = this.tc_date_month_two
                    this.tc_date_day = this.tc_date_day_two
                    this.tc_date_time = this.tc_date_time_two
                    this.tc_date_minute = this.tc_date_minute_two
                }
            },
            // 倒计时弹窗 输入
            date_input(val){
                console.log(val)
            },
            // 倒计时 弹窗 关闭
            djs_hide(){
                this.tc_date_year = ''
                this.tc_date_month = ''
                this.tc_date_day = ''
                this.tc_date_time = ''
                this.tc_date_minute = ''
                this.comm_shadows_value = '0'
                this.djs_pop_value = '0'
            },
            // 倒计时确定
            djs_determine(){
                let class_id = this.class_id
                let tc_date_year = this.tc_date_year
                let tc_date_month = this.tc_date_month
                let tc_date_day = this.tc_date_day
                let tc_date_time = this.tc_date_time
                let tc_date_minute = this.tc_date_minute
                let recruit_endtime_stamptime = this.recruit_endtime_stamptime
                let push_endtime_stamptime = this.push_endtime_stamptime
                let date = `${tc_date_year}-${tc_date_month}-${tc_date_day} ${tc_date_time}:${tc_date_minute}:00`
                let time1 = Date.parse(date)
                console.log(date,time1)

                if(tc_date_month>12 || tc_date_month == ''){
                    this.$message({
                        message: '请输入正确月份',
                        duration:'2000'
                    })
                    return
                }
                if(tc_date_day>31 || tc_date_day == ''){
                    this.$message({
                        message: '请输入正确日期',
                        duration:'2000'
                    })
                    return
                }
                if(tc_date_time>23 || tc_date_time == ''){
                    this.$message({
                        message: '请输入正确小时',
                        duration:'2000'
                    })
                    return
                }
                if(tc_date_minute>59 || tc_date_minute == ''){
                    this.$message({
                        message: '请输入正确分钟',
                        duration:'2000'
                    })
                    return
                }
                if(class_id == '1'){
                    if(recruit_endtime_stamptime*1000>time1*1){
                        this.$message({
                            message: '请输入正确时间',
                            duration:'2000'
                        })
                        return
                    }else{
                        this.recruit_date_new = date
                        this.tc_date_year_one = tc_date_year
                        this.tc_date_month_one = tc_date_month
                        this.tc_date_day_one = tc_date_day
                        this.tc_date_time_one = tc_date_time
                        this.tc_date_minute_one = tc_date_minute

                    }
                }else if(class_id == '2'){
                    if(push_endtime_stamptime*1000>time1*1){
                        this.$message({
                            message: '请输入正确时间',
                            duration:'2000'
                        })
                        return
                    }else{
                        this.push_date_new = date
                        this.tc_date_year_two = tc_date_year
                        this.tc_date_month_two = tc_date_month
                        this.tc_date_day_two = tc_date_day
                        this.tc_date_time_two = tc_date_time
                        this.tc_date_minute_two = tc_date_minute
                    }
                }
                this.tc_date_year = ''
                this.tc_date_month = ''
                this.tc_date_day = ''
                this.tc_date_time = ''
                this.tc_date_minute = ''

                this.comm_shadows_value = '0'
                this.djs_pop_value = '0'
                console.log(time1)
            },
            // 次数 弹窗 显示
            cs_pop_show(){
                this.comm_shadows_value = '1'
                this.cs_pop_value = '1'
            },
            // 次数 弹窗 关闭
            cs_pop_no(){
                let max_push_number_is = this.max_push_number_is
                console.log(max_push_number_is)
                if(max_push_number_is == '1'){
                    this.comm_shadows_value = '0'
                    this.cs_pop_value = '0'
                    this.max_push_number_input = this.max_push_number_input_val
                    this.max_push_number_day_input = this.max_push_number_day_input_val
                }else{
                    this.max_push_number_input = ''
                    this.max_push_number_day_input = ''
                    this.comm_shadows_value = '0'
                    this.cs_pop_value = '0'
                }
            },
            // 次数 弹窗 确定
            cs_pop_yes(){
                this.max_push_number_is = '1'
                this.max_push_number_input_val = this.max_push_number_input
                this.max_push_number_day_input_val = this.max_push_number_day_input
                this.comm_shadows_value = '0'
                this.cs_pop_value = '0'
            },
            // 新增咨询名额 费用计算
            zx_exposure(){
                let school_list = this.school_list
                let news_total_price = ''
                let total_Balance = this.total_Balance
                for(let i=0;i<school_list.length;i++){
                    news_total_price = news_total_price*1 + school_list[i].exposure_price*school_list[i].news_num
                }
                let news_zx_input = this.news_zx_input* this.res_list_recruit_consulting.consulting_price
                this.news_total_price = news_total_price*1 + news_zx_input*1
                if(total_Balance<this.news_total_price){
                    this.balance_value = '1'
                }

            },
            // 学校曝光量 总金额
            school_exposure(){
                let school_list = this.school_list
                let news_total_price = ''
                let total_Balance = this.total_Balance
                console.log(school_list)
                for(let i=0;i<school_list.length;i++){
                    news_total_price = news_total_price*1 + school_list[i].exposure_price*school_list[i].news_num
                }
                let news_zx_input = this.news_zx_input* this.res_list_recruit_consulting.consulting_price
                this.news_total_price = news_total_price*1 + news_zx_input*1
                if(total_Balance<this.news_total_price){
                    this.balance_value = '1'
                }
            },  
            // 招聘倒计时
            timestampToTime (timestamp) {
                setInterval(()=>{
                    let time = timestamp*1 - parseInt(new Date().getTime() / 1000);
                    let day = parseInt(time / (60*60*24))
                    let hour = parseInt((time - day*60*60*24 ) / (60*60))
                    let minute = parseInt((time - day *60*60*24 - hour *60*60) / 60)
                    let second = parseInt((time - day *60*60*24 - hour *60*60 - minute*60))
                    if(timestamp*1 < parseInt(new Date().getTime()/ 1000)){
                        this.date_day = '0'
                        this.date_hour = '0'
                        this.date_minute = '0'
                        this.date_second = '0'
                    }{
                        this.date_day = day
                        this.date_hour = hour
                        this.date_minute = minute
                        this.date_second = second
                    }
                    
                },1000)
            },
            // 推送倒计时
            ts_timestampToTime (timestamp) {
                setInterval(()=>{
                    let time = timestamp*1 - parseInt(new Date().getTime() / 1000);
                    let day = parseInt(time / (60*60*24))
                    let hour = parseInt((time - day*60*60*24 ) / (60*60))
                    let minute = parseInt((time - day *60*60*24 - hour *60*60) / 60)
                    let second = parseInt((time - day *60*60*24 - hour *60*60 - minute*60))
                    if(timestamp*1 < parseInt(new Date().getTime()/ 1000)){
                        this.ts_date_day = '0'
                        this.ts_date_hour = '0'
                        this.ts_date_minute = '0'
                        this.ts_date_second = '0'
                    }else{
                        this.ts_date_day = day
                        this.ts_date_hour = hour
                        this.ts_date_minute = minute
                        this.ts_date_second = second
                    }
                },1000)
            },
            // 返回
            back_to(){
                this.$router.go(-1)
            },
            // 提交
            submit(){
                let token = this.token
                let recruit_id = this.recruit_id
                // let consulting_people_number = this.number_places_num
                let consulting_people_number = this.news_zx_input
                let max_push_number = this.max_push_number_input
                let max_push_number_day = this.max_push_number_day_input
                let recruit_endtime = this.recruit_date_new
                let push_endtime = this.push_date_new
                let school_list = this.school_list
                let school_json = []
                for(let i=0;i<school_list.length;i++){
                    school_json.push({school_id:school_list[i].school_id,exposure_number:school_list[i].news_num,school_type_text:school_list[i].school_type_text})
                }

                renew_recruit_common({token:token,recruit_id,consulting_people_number,max_push_number,max_push_number_day,recruit_endtime,push_endtime,school_json:JSON.stringify(school_json)}).then((res)=>{
                    console.log(res)
                    if(res.error_code == '0'){
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                        var that = this;
                        setTimeout(function () {
                            that.$router.push({path:'/'}) 
                        },1000);
                    }else{
                        console.log('111')
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            },

        },
       
    }
</script>