
<template>
    <div class="y_my_edit_profile_1">
        <div class="y_my_personal_one_1">
            <div class="y_my_personal_one_2">
                <h2>【高级认证】</h2>
                <p>{{gj_accreditation}}</p>
            </div>
        </div>
        
        <div class="y_advanced_two_1">
            <div class="y_advanced_two_2">
                <h2>上传照片</h2>
                <p>最多可上传4张</p>
            </div>
            <div class="y_advanced_two_3">
                <!-- <div class="y_advanced_two_4">
                    <img src="../../../images/icon_14.png" alt="">
                </div> -->
                <div class='y_recruit_13'>
                    <el-upload class="upload-demo" :limit="4" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :on-preview="handlePreview" :on-success="hand_success" :on-remove="handleRemove" :file-list="fileList" list-type="picture">
                        <div class='y_recruit_14'>
                            <img src="../../../images/icon_14.png" alt="">
                        </div>
                    </el-upload>
                </div>
            </div>
        </div>

        <div class="y_my_change_phone_one_6"></div>
        <div class="y_my_change_phone_one_7" @click="submit_certification">
            <p>提交认证</p>
        </div>
    </div>
</template>

<style scoped>
    .y_my_edit_profile_1{
        min-height: 100vh;
        width: 100%;
        background: #F4F5F7;
    }
    .y_my_personal_one_1{
        padding: 0.4rem 0.25rem 0rem;
    }
    .y_my_personal_one_2{
        min-height: 2.2rem;
        background: #fff;
        border-radius: 0.1rem;
        overflow: hidden;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        padding: 0rem 0.4rem;
        box-shadow: 0 0 0.2rem 0.05rem #ccc;
    }
    .y_my_personal_one_2 h2{
        color: #333;
        font-size: 0.32rem;
        font-weight: 600;
        width: 100%;
    }
    .y_my_personal_one_2 p{
        width: 100%;
        margin-top: 0.2rem;
        color: #999;
        font-size: 0.28rem;
    }
    .y_my_personal_one_3{
        width: 100%;
        margin-top: 0.4rem;
        background: #fff;
    }
    .y_my_personal_one_4{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.2rem;
        padding: 0rem 0.3rem;
        border-bottom: 0.01rem solid #EDEDED;
    }
    .y_my_personal_one_5{
        width: 2rem;
    }
    .y_my_personal_one_5 h2{
        font-size: 0.3rem;
        color: #999;
    }
    .y_my_personal_one_6{
        flex:1;
    }
    .y_my_personal_one_6 /deep/ input{
        width: 100%;
        height: 1.2rem;
        border-radius: 0rem;
        border:none;
        background: transparent;
        line-height: 1.2rem;
        font-size: 0.3rem;
        color: #333;
        text-align: right;
    }

    .y_my_change_phone_one_6{
        width: 100%;
        height: 2.2rem;
        background: transparent;
    }
    .y_my_change_phone_one_7{
        position: fixed;
        bottom: 0.6rem;
        width: calc(100% - 0.5rem);
        left: 0.25rem;
        background: #FECE0A;
        text-align: center;
        height: 1rem;
        line-height: 1rem;
        border-radius: 0.1rem;
        overflow: hidden;
        font-size: 0.32rem;
        color: #333;
    }

    /* 【高级认证】 2 */
    .y_advanced_two_1{
        width: 100%;
        background: #fff;
        margin-top: 0.4rem;
        padding-bottom: 0.55rem;
    }
    .y_advanced_two_2{
        position: relative;
        padding: 0rem 0.25rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1.25rem;
    }
    .y_advanced_two_2 h2{
        position: relative;
        color: #333;
        font-size: 0.32rem;
        margin-left: 0.3rem;
    }
    .y_advanced_two_2 h2::after{
        width: 0.1rem;
        height: 0.32rem;
        left: -25%;
        transform: translate(50%,-50%);
        top: 50%;
        content: '';
        position: absolute;
        background: #FECE0A;
        border-radius: 0.2rem;
    }
    .y_advanced_two_2 p{
        font-size: 0.28rem;
        color: #999;
        margin-left: 0.35rem;
    }
    .y_advanced_two_3{
        padding: 0rem 0.25rem;
    }
    .y_advanced_two_4{
        width: 2.2rem;
        height: 2.2rem;
    }
    .y_advanced_two_4 img{
        width: 100%;
        object-fit: cover;
    }
</style>
<script>
    import axios from 'axios'   // 接口
    import {edit_auth} from '../../api/request.js'   // 申请认证
    import {configuration_fields} from '../../api/request.js' // 配置字段的信息
    export default {
        data(){
            return {
                token:'',
                input: '',
                certification_value:'',  // 传 高级认证类型 1.enterprise 企业 2.self_employed 个体工商户 3.private_non_enterprise 民办非企业 4.social_groups 社会团体
                fileList_list:[],
                gj_accreditation:'',
            }
        },  
        created(){
            // 获取页面传参 推送类型
            let certification_value = this.$route.query.value;
            if(certification_value == '1'){
                this.certification_value = 'enterprise'
            }else if(certification_value == '2'){
                this.certification_value = 'self_employed'
            }else if(certification_value == '3'){
                this.certification_value = 'private_non_enterprise'
            }else if(certification_value == '4'){
                this.certification_value = 'social_groups'
            }
        },
        components:{

        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            // 配置字段的信息
            configuration_fields({}).then((res)=>{
                console.log(res,'配置字段的信息')
                if(this.certification_value == 'enterprise'){
                    this.gj_accreditation = res.data.advanced_certification_corporations
                }else if(this.certification_value == 'self_employed'){
                    this.gj_accreditation = res.data.advanced_certification_private_firm
                }else if(this.certification_value == 'private_non_enterprise'){
                    this.gj_accreditation = res.data.advanced_certification_privately_operated
                }else if(this.certification_value == 'social_groups'){
                    this.gj_accreditation = res.data.advanced_certification_social_group
                }
            })
        },
        methods: {
            // 图片上传
            // 删除图片
            handleRemove(file, fileList) {
              console.log(file, fileList);
              let fileList_list = []
              for(let i=0;i<fileList.length;i++){
                fileList_list.push(fileList[i].response.img)
              }
              this.fileList_list = fileList_list 
              console.log(this.fileList_list,'删除图片')
            },
            // 上传成功
            hand_success(file,fileList){
              console.log(file,fileList)
              let fileList_list = this.fileList_list
            
              fileList_list.push(fileList.response.img)
              this.fileList_list = fileList_list
              console.log(this.fileList_list,'上传成功')
            },

            handlePreview(file,fileList) {
              console.log(file,fileList);
            },

            // 
            submit_certification(){
                let token = this.token
                let business_auth_type = this.senior
                let senior_auth_type = this.certification_value
                let fileList = this.fileList_list
                console.log(fileList)
                if(fileList.length < 1){
                    this.$message({
                        message:'请至少上传一张',
                        duration:'1000'
                    })
                    return
                }
                let img_list = ''
                for(let i=0;i<fileList.length;i++){
                    img_list = img_list +','+ fileList[i]
                }
                let senior_auth_gallery = img_list.substr(1,img_list.length)
                edit_auth({token:token,business_auth_type:business_auth_type,senior_auth_type:senior_auth_type,senior_auth_gallery:senior_auth_gallery}).then((res) =>{
                    console.log(res,'申请认证')
                    if(res.error_code == '0'){
                        this.$message({
                            message:'提交成功',
                            duration:'1000'
                        })
                        var that = this;
                        setTimeout(function () {
                            that.$router.push({path:'/'}) 
                        },1000);
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'1000'
                        })
                    }
                })
            },
        },
    }

</script>