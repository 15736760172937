// 导入axios实例
import httpRequest from '@/request/index'
import  qs  from 'qs'
// 获取用户信息
// 首页
export function index(data) { //index 是封装好的接口名
    return httpRequest({
        url: '/api/index.php/business-index/index.html',
        method: 'post',
        data:qs.stringify(data),
    })
}
// 配置字段的信息
export function configuration_fields(data) {
    return httpRequest({
        url: '/api/index.php/base/config-info.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 省市
export function province_city(data) {
    return httpRequest({
        url: '/api/index.php/business-index/area-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 工位岗位列表
export function job_list(data) {
    return httpRequest({
        url: '/api/index.php/business-index/job-type-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 工位岗位 搜索
export function job_search_list(data) {
    return httpRequest({
        url: '/api/index.php/business-index/job-type-search.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 学历列表
export function education_list(data) {
    return httpRequest({
        url: '/api/index.php/business-index/education-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 福利列表
export function welfare_list(data) {
    return httpRequest({
        url: '/api/index.php/business-index/welfare-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 福利列表 添加
export function welfare_list_add(data) {
    return httpRequest({
        url: '/api/index.php/business-index/edit-welfare.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 福利列表 删除
export function welfare_list_remove(data) {
    return httpRequest({
        url: '/api/index.php/business-index/delete-welfare.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 图片上传
export function file_list(data) {
    return httpRequest({
        url: '/api/index.php/index/file_add.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 学校列表
export function school_list(data) {
    return httpRequest({
        url: '/api/index.php/base/school-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 所有学校和年级列表
export function school_grade_list(data) {
    return httpRequest({
        url: '/api/index.php/business-index/school-grade-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 商务端所有价格的信息
export function all_prices(data) {
    return httpRequest({
        url: 'api/index.php/business-index/price-info.html',
        method: 'post',
        data:qs.stringify(data)
    })
}

// 用户信息
export function user_info(data) {
    return httpRequest({
        url: 'api/index.php/business-user/info.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 添加充值
export function add_recharge(data) {
    return httpRequest({
        url: 'api/index.php/business-user/add-recharge.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 充值模板
export function recharge_template_list(data) {
    return httpRequest({
        url: 'api/index.php/business-user/recharge-template-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 添加和编辑招聘，普通推广
export function edit_recruit_common(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-common/edit-recruit-common.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 添加和编辑广告位，高级推广
export function edit_adspace(data) {
    return httpRequest({
        url: 'api/index.php/business-adspace/edit-adspace.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 广告位文章列表
export function adspace_article_list(data) {
    return httpRequest({
        url: 'api/index.php/business-index/adspace-article-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 话题列表
export function topic_list(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-info/topic-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 小组列表
export function group_list(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-info/group-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 活动列表
export function activity_list(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-info/activity-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 招聘和帖子列表,信息类推广
export function recruit_blog_info_list(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-info/recruit-blog-info-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 帖子详情,信息类推广
export function blog_info_info(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-info/blog-info-info.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 招聘详情,信息类推广
export function recruit_info_info(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-info/recruit-info-info.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 添加和编辑招聘，信息类推广
export function edit_recruit_info(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-info/edit-recruit-info.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 通用分类列表
export function general_type_list(data) {
    return httpRequest({
        url: 'api/index.php/base/general-type-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 添加和编辑帖子，信息类推广
export function edit_blog_info_list(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-info/edit-blog-info.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 添加和编辑帖子，普通推广
export function edit_blog_common(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-common/edit-blog-common.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 各个学校用户数量
export function user_number(data) {
    return httpRequest({
        url: 'api/index.php/business-index/user-number.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 添加和编辑抽奖
export function edit_award(data) {
    return httpRequest({
        url: 'api/index.php/business-award/edit-award.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 奖品图片列表
export function award_picture_list(data) {
    return httpRequest({
        url: 'api/index.php/business-award/award-picture-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 联系人列表
export function award_contact_list(data) {
    return httpRequest({
        url: 'api/index.php/business-award/award-contact-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 联系人详情
export function award_contact_detail(data) {
    return httpRequest({
        url: 'api/index.php/business-award/award-contact-detail.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 添加和编辑联系人
export function edit_award_contact(data) {
    return httpRequest({
        url: 'api/index.php/business-award/edit-award-contact.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 删除联系人
export function delete_award_contact(data) {
    return httpRequest({
        url: 'api/index.php/business-award/delete-award-contact.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 用户信息
export function info(data) {
    return httpRequest({
        url: 'api/index.php/business-user/info.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 使用攻略列表
export function strategy_list(data) {
    return httpRequest({
        url: 'api/index.php/business-index/strategy-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 使用攻略详情
export function strategy_detail(data) {
    return httpRequest({
        url: 'api/index.php/business-index/strategy-detail.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 添加意见反馈
export function add_feedback(data) {
    return httpRequest({
        url: 'api/index.php/index/add-feedback.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 编辑用户资料
export function edit_user(data) {
    return httpRequest({
        url: 'api/index.php/business-user/edit-user.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 返利用户列表
export function rebate_user_list(data) {
    return httpRequest({
        url: 'api/index.php/business-user/rebate-user-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 我的收益详情
export function rebate_detail(data) {
    return httpRequest({
        url: 'api/index.php/business-user/rebate-detail.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 添加提现
export function add_withdraw(data) {
    return httpRequest({
        url: 'api/index.php/business-user/add-withdraw.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 下级用户列表
export function down_user_list(data) {
    return httpRequest({
        url: 'api/index.php/business-user/down-user-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 登录
export function login(data) {
    return httpRequest({
        url: 'api/index.php/business-index/login.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 验证手机验证码
export function check_code(data) {
    return httpRequest({
        url: 'api/index.php/business-user/check-code.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 发送验证码
export function ajax_code(data) {
    return httpRequest({
        url: 'api/index.php/business-index/ajax-code.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 更换手机号
export function edit_phone(data) {
    return httpRequest({
        url: 'api/index.php/business-user/edit-phone.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 申请认证
export function edit_auth(data) {
    return httpRequest({
        url: 'api/index.php/business-user/edit-auth.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 正在聊天的好友列表
export function chat_user_list(data) {
    return httpRequest({
        url: 'api/index.php/chat-record/chat-user-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 招聘列表,普通推广
export function recruit_common_list(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-common/recruit-common-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 招聘详情,普通推广
export function recruit_common_info(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-common/recruit-common-info.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 帖子列表,普通推广
export function blog_common_list(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-common/blog-common-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 帖子详情,普通推广
export function blog_common_info(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-common/blog-common-info.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 广告位列表，高级推广
export function adspace_list(data) {
    return httpRequest({
        url: 'api/index.php/business-adspace/adspace-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
} 
// 广告位详情，高级推广
export function adspace_info(data) {
    return httpRequest({
        url: 'api/index.php/business-adspace/adspace-info.html',
        method: 'post',
        data:qs.stringify(data)
    })
} 
// 抽奖列表
export function award_list(data) {
    return httpRequest({
        url: 'api/index.php/business-award/award-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
} 
// 抽奖详情
export function award_info(data) {
    return httpRequest({
        url: 'api/index.php/business-award/award-info.html',
        method: 'post',
        data:qs.stringify(data)
    })
} 
// 消息列表
export function message_list(data) {
    return httpRequest({
        url: 'api/index.php/business-user/message-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 招聘提交审核
export function submit_check_recruit_common(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-common/submit-check-recruit-common.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 招聘的续费和修改
export function renew_recruit_common(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-common/renew-recruit-common.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 帖子提交审核
export function submit_check_blog_common(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-common/submit-check-blog-common.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 抽奖提交审核
export function submit_check_award(data) {
    return httpRequest({
        url: 'api/index.php/business-award/submit-check-award.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 帖子的续费和修改
export function renew_blog_common(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-common/renew-blog-common.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 广告位提交审核
export function submit_check_adspace(data) {
    return httpRequest({
        url: 'api/index.php/business-adspace/submit-check-adspace.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 广告位的续费和修改
export function renew_adspace(data) {
    return httpRequest({
        url: 'api/index.php/business-adspace/renew-adspace.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 信息类 帖子提交审核
export function submit_check_blog_info(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-info/submit-check-blog-info.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 信息类 招聘提交审核
export function submit_check_recruit_info(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-info/submit-check-recruit-info.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 信息类 帖子的续费和修改
export function renew_blog_info(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-info/renew-blog-info.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 信息类 招聘的续费和修改
export function renew_recruit_info(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-info/renew-recruit-info.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 修改默认城市
export function edit_default_city(data) {
    return httpRequest({
        url: 'api/index.php/business-user/edit-default-city.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 清空未读评论数量
export function clear_not_read_comment_number(data) {
    return httpRequest({
        url: 'api/index.php/business-user/clear-not-read-comment-number.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 获取一对一聊天的记录
export function chat_list(data) {
    return httpRequest({
        url: 'api/index.php/chat-record/chat-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 发送聊天内容
export function send_message(data) {
    return httpRequest({
        url: 'api/index.php/chat-record/send-message.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 私信 修改用户备注
export function edit_user_follow(data) {
    return httpRequest({
        url: 'api/index.php/user/edit-user-follow.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 私信 改变状态为已读
export function change_read(data) {
    return httpRequest({
        url: 'api/index.php/chat-record/change-read.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 根据学校和各个年级，统计出学生人数
export function school_grade_user_number(data) {
    return httpRequest({
        url: 'api/index.php/business-index/school-grade-user-number.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 最新一条招聘的联系方式
export function last_recruit_info(data) {
    return httpRequest({
        url: 'api/index.php/business-user/last-recruit-info.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 高级推广 广告位删除
export function adspace_delete(data) {
    return httpRequest({
        url: 'api/index.php/business-adspace/adspace-delete.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 普通推广 帖子删除
export function blog_delete(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-common/blog-delete.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 普通招聘 招聘删除
export function recruit_delete(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-common/recruit-delete.html',
        method: 'post',
        data:qs.stringify(data)
    })
}

// 信息类 招聘删除
export function recruit_delete_info(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-info/recruit-delete.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 信息类 帖子删除
export function blog_delete_info(data) {
    return httpRequest({
        url: 'api/index.php/business-cost-info/blog-delete.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 抽奖 删除
export function award_delete(data) {
    return httpRequest({
        url: 'api/index.php/business-award/award-delete.html',
        method: 'post',
        data:qs.stringify(data)
    })
}

// 学校列表，分页版
export function school_list_page(data) {
    return httpRequest({
        url: 'api/index.php/base/school-list-page.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 省市列表
export function province_list(data) {
    return httpRequest({
        url: 'api/index.php/base/province-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 我的核销列表
export function award_check_list(data) {
    return httpRequest({
        url: 'api/index.php/business-award/award-check-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 我的核销详情
export function award_check_detail(data) {
    return httpRequest({
        url: 'api/index.php/business-award/award-check-detail.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 核销中奖记录
export function award_check(data) {
    return httpRequest({
        url: 'api/index.php/business-award/award-check.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 添加评论-商务端
export function add_comment(data) {
    return httpRequest({
        url: 'api/index.php/business-user/add-blog-comment.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 参与者列表
export function award_joiner_list(data) {
    return httpRequest({
        url: 'api/index.php/business-award/award-joiner-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 抽奖列表-核销用
export function award_list_check(data) {
    return httpRequest({
        url: 'api/index.php/business-award/award-list-check.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 核销者列表
export function checker_list(data) {
    return httpRequest({
        url: 'api/index.php/business-award/checker-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 公告列表
export function gonggao_list(data) {
    return httpRequest({
        url: 'api/index.php/business-index/gonggao-list.html',
        method: 'post',
        data:qs.stringify(data)
    })
}
// 公告详情
export function gonggao_detail(data) {
    return httpRequest({
        url: 'api/index.php/business-index/gonggao-detail.html',
        method: 'post',
        data:qs.stringify(data)
    })
}