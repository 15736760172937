import { Button, List, Cell, CellGroup,Swipe,SwipeItem,DropdownMenu,DropdownItem,Calendar,Form,Field,DatetimePicker,Picker,Overlay,Uploader,Toast} from 'vant';

export function vant(app) {
    app.use(Button);
    app.use(List)
    app.use(Cell);
    app.use(CellGroup);
    app.use(Swipe);
    app.use(SwipeItem);
    app.use(DropdownMenu);
    app.use(DropdownItem);
    app.use(Calendar);
    app.use(Form);
    app.use(Field);
    app.use(DatetimePicker);
    app.use(Picker);
    app.use(Overlay);
    app.use(Uploader);
    app.use(Toast);

}
