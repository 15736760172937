
<template>
    <div class="y_my_edit_profile_1">
        <div class="y_my_change_phone_one_1">
            <div class="y_my_change_phone_one_3 y_my_change_phone_one_8">
                <div class="y_my_change_phone_one_4">
                    <el-input v-model="input" placeholder="请输入当前密码"></el-input>
                </div>
            </div>
            <div class="y_my_change_phone_one_3 y_my_change_phone_one_8">
                <div class="y_my_change_phone_one_4">
                    <el-input v-model="input" placeholder="请输入新密码"></el-input>
                </div>
            </div>
            <div class="y_my_change_phone_one_3 y_my_change_phone_one_8">
                <div class="y_my_change_phone_one_4">
                    <el-input v-model="input" placeholder="请确认新密码"></el-input>
                </div>
            </div>
        </div>
        <div class="y_my_change_phone_one_6"></div>
        <div class="y_my_change_phone_one_7">
            <p>提交</p>
        </div>
    </div>
</template>
<style scoped>
    .y_my_edit_profile_1{
        min-height: 100vh;
        width: 100%;
        background: #F4F5F7;
    }
    .y_my_change_phone_one_1{
        width: 100%;
        background: #fff;
    }
    .y_my_change_phone_one_2{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1.2rem;
        border-bottom: 0.01rem solid #EDEDED;
        padding: 0rem 0.3rem;
    }
    .y_my_change_phone_one_2 h2{
        font-size: 0.3rem;
        color:#333;
        margin-right: 0.35rem;
    }
    .y_my_change_phone_one_2 p{
        font-size: 0.3rem;
        color: #000;
    }
    .y_my_change_phone_one_3{
        padding: 0rem 0.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.2rem;
    }
    .y_my_change_phone_one_4{
        flex: 1;
    }
    .y_my_change_phone_one_4 /deep/ input{
        background: transparent;
        padding: 0rem;
        height: 1.2rem;
        line-height: 1.2rem;
        color:#333;
        font-size: 0.3rem;
        border-radius: 0rem;
        border: none;
    }
    .y_my_change_phone_one_5{
        width: 2rem;
        text-align: center;
    }
    .y_my_change_phone_one_5 div{
        font-size: 0.3rem;
        color: #FECE0A;
    }
    .y_my_change_phone_one_6{
        width: 100%;
        height: 2.2rem;
        background: transparent;
    }
    .y_my_change_phone_one_7{
        position: fixed;
        bottom: 0.6rem;
        width: calc(100% - 0.5rem);
        left: 0.25rem;
        background: #FECE0A;
        text-align: center;
        height: 1rem;
        line-height: 1rem;
        border-radius: 0.1rem;
        overflow: hidden;
        font-size: 0.32rem;
        color: #333;
    }
    .y_my_change_phone_one_8{
        border-bottom: 0.01rem solid #EDEDED;
    }
</style>
<script>

    export default {
        data(){
            return {
                input: '',
            }
        },  
        components:{

        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
        },
        methods: {
           
        },
    }

</script>