<template>
  <div class="y_add_search_0">
    <div>
      <div class="y_add_search_1">
        <el-select v-model="keywords" filterable remote reserve-keyword placeholder="请输入关键词" :remote-method="remoteMethod" :loading="loading" :clearable="true" size="mini" @change="currentSelect" ref="select" @focus="clear" @hook:mounted="clear" @visible-change="clear">
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item" class="one-text">
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.district }}</span>
          </el-option>
        </el-select>
      </div>
      <div id="container" class="container y_add_search_2"></div>
    </div>
  </div>
</template>
 
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
  // 安全密钥
  securityJsCode: "6379b3cce15f6de8d9a891f8b0033fd8",
};
export default {
  name: "",
  data() {
    return {
      // 地图实例
      map: null,
      // 标记点
      marker: "",
      // 地址逆解析
      geoCoder: null,
      // 搜索提示
      AutoComplete: null,
      // 搜索关键字
      keywords: "",
      // 位置信息
      form: {
        lng: "",
        lat: "",
        address: "",
        adcode: "", //地区编码
      },
      // 搜索节流阀
      loading: false,
      // 搜索提示信息
      options: [],
    };
  },
  methods: {
    clear(async) {
      console.log(async)
            this.$nextTick(() => {
            // ios 手机有延迟问题
            setTimeout(() => {
              const { select } = this.$refs
              const input = select.$el.querySelector('.el-input__inner')
              input.removeAttribute('readonly')
            }, 400)
      })
    },
    
    initMap() {
      AMapLoader.load({
        // 你申请的Key
        key: "f8a1bbd8ef1b14c4e71a5b027835e6f3",
        version: "2.0",
        // 需要用到的插件
        plugins: ["AMap.Geocoder", "AMap.AutoComplete"],
      })
        .then((AMap) => {
          this.map = new AMap.Map("container", {
            viewMode: "3D", //是否为3D地图模式
            zoom: 5, //初始化地图级别
            center: [105.602725, 37.076636], //初始化地图中心点位置
          });
          //地址逆解析插件
          this.geoCoder = new AMap.Geocoder({
            city: "010", //城市设为北京，默认：“全国”
            radius: 1000, //范围，默认：500
          });
          // 搜索提示插件
          this.AutoComplete = new AMap.AutoComplete({ city: "全国" });
          //点击获取经纬度;
          this.map.on("click", (e) => {
            // 获取经纬度
            this.form.lng = e.lnglat.lng;
            this.form.lat = e.lnglat.lat;
            // 清除点
            this.removeMarker();
            // 标记点
            this.setMapMarker();
          });
        })
        .catch((err) => {
          // 错误
          console.log(err);
        });
    },
    // 标记点
    setMapMarker() {
      // 自动适应显示想显示的范围区域
      this.map.setFitView();
      this.marker = new AMap.Marker({
        map: this.map,
        position: [this.form.lng, this.form.lat],
      });

      // 逆解析地址
      this.toGeoCoder();
      this.map.setFitView();
      this.map.add(this.marker);


    },
    // 清除点
    removeMarker() {
      if (this.marker) {
        this.map.remove(this.marker);
      }
    },
    // 逆解析地址
    toGeoCoder() {
      let lnglat = [this.form.lng, this.form.lat];
      this.geoCoder.getAddress(lnglat, (status, result) => {
        if (status === "complete" && result.regeocode) {
          this.form.address = result.regeocode.formattedAddress;
        }
      });

    },
    // 搜索
    remoteMethod(query) {
      console.log(query);
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.AutoComplete.search(query, (status, result) => {
            this.options = result.tips;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    // 选中提示
    currentSelect(val) {
      console.log(val)
      // 清空时不执行后面代码
      if (!val) {
        return;
      }
      this.form = {
        lng: val.location.lng,
        lat: val.location.lat,
        address: val.district + val.address,
        adcode: val.adcode,
      };
      this.keywords = val.name;

      // 清除点
      this.removeMarker();
      // 标记点
      this.setMapMarker();
    },
    clear(async) {
      this.$nextTick(() => {
            if (!async) {
              // ios 手机有延迟问题
              setTimeout(() => {
                const { select } = this.$refs
                const input = select.$el.querySelector('.el-input__inner')
                input.removeAttribute('readonly')
              }, 200)
          }
        })
    }

  },
  mounted() {
    setTimeout(() => {
      const { select } = this.$refs
      const input = select.$el.querySelector('.el-input__inner')
      input.removeAttribute('readonly')
    }, 300)
    this.initMap();
  },
};
</script>
 
<style>
.container {
  width: 100%;
  height: 300px;
}
.y_add_search_0{
  width: 100%;
}
.y_add_search_1{
  height: 1rem;
  background: transparent;
  width: 100%;
  position: fixed;
  z-index: 10;
  top: 0.5rem;
  left: 50%;
  transform: translate(-50%,0%);
}
.y_add_search_1 .el-select--mini{
  width: 100% !important;
}
.y_add_search_1 .el-select .el-input__inner{
    width: 90%;
    margin: 0 auto;
    font-size: 0.3rem;
    border: none;
    border-radius: 0.1rem;
    padding-left: 0.15rem !important;
    line-height: 0.8rem;
}
.el-popper{
  width: 90% !important;
  left: 50% !important;
  transform: translate(-50%,0%) !important;
}
.el-select-dropdown{
  min-width: auto !important;
  width: 100%;
}
.el-select-dropdown__item{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-select-dropdown__item span:nth-child(1){
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  width: 100%;
}
.el-select-dropdown__item span:nth-child(2){
  flex: 1;
}
.y_add_search_2{
  position: fixed;
  z-index: 5;
  top: 0rem;
  left: 0rem;
}
</style>