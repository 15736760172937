
<template>
    <div class="y_user_comm_1">

        <div v-if="user_value == '1'? true:false">
            <div class="y_user_one_1" v-for="(item,index) in user_one_list" :key="index">
                <div class="y_user_one_2" :class="item.user_one == '0'?'y_user_one_2_none':(item.user_one == '1'?'':'y_user_one_2_active')">
                    <div class="y_user_one_3" @click="user_one_click(item.id)">
                        <div class="y_user_one_4">
                            <h2>{{item.title}}</h2>
                        </div>
                        <div class="y_user_one_5">
                            <img src="../../../images/icon_114.png" alt="">
                            <img src="../../../images/icon_113.png" alt="">
                        </div>
                    </div>
                    <div class="y_user_one_6">
                        <p v-for="(sub_item,index) in item.strategy" :key="index" @click="user_one_url_click(sub_item.url)">{{sub_item.title}}</p>
                    </div>
                </div>
                <!-- <div class="y_user_one_2" v-for="(item,index) in user_one_list" :key="index" @click="user_one_click(item.id)">
                    <h2>{{item.title}}</h2>
                </div> -->
            </div>
        </div>
        <p v-if="user_value == '2'?true:false" v-html="user_two"></p>
        <div v-if="user_value == '3'?true:false">
            <div class="y_user_three_1">
                <div class="y_user_three_2">
                    <div class="y_user_three_3">
                        <h2><span>*</span>问题与反馈</h2>
                    </div>
                    <div class="y_user_three_4">
                        <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea"></el-input>
                    </div>
                </div>
                <!-- <div class="y_user_three_2">
                    <div class="y_user_three_3">
                        <h2>上传手机号</h2>
                    </div>
                    <div class="y_user_three_4">
                        <el-input v-model="input" placeholder="请输入内容"></el-input>
                    </div>
                </div> -->
                <div class="y_user_three_2">
                    <div class="y_user_three_3">
                        <h2>添加图片（选填）</h2>
                    </div>
                    <div class='y_recruit_13'>
                        <el-upload class="upload-demo" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :on-preview="handlePreview" :on-success="hand_success" :on-remove="handleRemove" :file-list="fileList" list-type="picture">
                            <div class='y_recruit_14'>
                                <img src="../../../images/icon_14.png" alt="">
                            </div>
                        </el-upload>
                    </div>
                </div>
                <div class="y_user_three_5" >
                    <p class="y_user_three_6" @click="user_three_click">提交</p>
                </div>
            </div>
        </div>
        <div v-if="user_value == '4'?true:false">
            <p v-for="(item,index) in six_list" class="y_user_4" :key="index" @click="six_click(item.redirect_type,item.id,item.url)">{{ item.title }}</p>
        </div>
        <p v-if="user_value == '5'?true:false" v-html="user_five"></p>
        <p v-if="user_value == '6'?true:false" v-html="user_six"></p>
        <p v-if="user_value == '7'?true:false" v-html="user_seven"></p>
        <p v-if="user_value == '8'?true:false" v-html="user_eight"></p>
        
    </div>
</template>
<style scoped>
    /* 使用攻略 */
    .y_user_comm_1{
        padding: 0rem;
        margin: 0.1rem;
    }
    .y_user_comm_1 p{
        font-size: 0.28rem;
        color: #333;
        line-height: 0.4rem;
    }
    .y_user_one_1{
        width: 100%;
    }
    .y_user_one_2{
        display: flex;
        justify-content: flex-start;
        border-bottom: 0.01rem solid #EDEDED;
        height: 2rem;
        overflow: hidden;
        transition: ease 0.5s all;
        align-items: baseline;
    }
    .y_user_one_3{
        width: 1.9rem;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: baseline;
        overflow: hidden;
    }
    .y_user_one_4{
        width: 100%;
        text-align: center;
    }
    .y_user_one_4 h2{
        font-size: 0.28rem;
        color: #333;
        font-weight: 600;
    }
    .y_user_one_5{
        text-align: center;
        width: 100%;
        margin-top: 0.25rem;
    }
    .y_user_one_5 img{
        width: 0.26rem;
        height: 0.14rem;
    }
    .y_user_one_5 img:nth-child(2){
        display: none;
    }
    .y_user_one_6{
        flex: 1;
        border-left: 0.01rem solid #EDEDED;
    }
    .y_user_one_6 p{
        width: calc(100% - 0.6rem);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        padding: 0rem 0.3rem;
        border-bottom: 0.01rem solid #EDEDED;
        line-height: 1rem;
    }
    .y_user_one_6 p:nth-last-child(1){
        border-bottom: none;
    }
    .y_user_one_6 p:nth-child(1){
        border-bottom: 0.01rem solid #EDEDED;
    }
    .y_user_one_2_active{
        height: auto;
        transition: ease 0.5s all;
    }
    .y_user_one_2_active .y_user_one_5 img:nth-child(1){
        display: none;
    }
    .y_user_one_2_active .y_user_one_5 img:nth-child(2){
        display: inline-block;
    }
    .y_user_one_2_none .y_user_one_5{
        display: none;
    }
    /* .y_user_one_2{
        width: 100%;
        height: 1rem;
        border-bottom: 0.01rem solid #eee;
        margin-bottom: 0.2rem;
    }
    .y_user_one_2 h2{
        font-size: 0.3rem;
        color: #333;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    } */

    /* 意见反馈 */
    .y_user_three_1{
        width: 100%;
    }
    .y_user_three_2{
        width: 100%;
        margin-bottom: 0.5rem;
    }
    .y_user_three_3{
        width: 100%;
    }
    .y_user_three_3 h2{
        font-size: 0.32rem;
        color: #ccc;
        line-height: 0.7rem;
    }
    .y_user_three_3 h2 span{
        color: red;
    }
    .y_user_three_4{
        width: 100%;
        margin-top: 0.2rem;
    }
    .y_user_three_4 /deep/ textarea{
        border: none;
        background: #fff;
        height: 2.5rem;
        padding: 0.2rem;
        font-size: 0.28rem;
        color: #333;
        border:0.02rem solid #ccc
    }
    .y_user_three_4 /deep/ input{
        border: none;
        background: #fff;
        padding: 0rem 0.2rem;
        font-size: 0.28rem;
        color: #333;
        height: 1rem;
        border-radius: 0.1rem;
    }
    .y_user_three_5{
        width: 100%;
        margin-top: 1rem;
    }
    .y_user_three_5 .y_user_three_6{
        width: 100%;
        background: #FECE0A;
        color: #fff;
        text-align: center;
        font-size: 0.3rem;
        height: 0.8rem;
        line-height: 0.8rem;
        border-radius: 0.1rem;
    }
    .y_user_comm_1 .y_user_4{
        line-height: 0.8rem;
    }

</style>
<script>
    import axios from 'axios'   // 接口
    import {configuration_fields} from '../../api/request.js' // 用户信息
    import {strategy_list} from '../../api/request.js' // 使用攻略列表
    import {add_feedback} from '../../api/request.js' // 添加意见反馈
    import {gonggao_list} from '../../api/request.js' // 添加意见反馈
    export default {
        data(){
            return {
                user_value:'', // 判断入口是那个
                content:'',   // 显示内容
                user_one:'',    // 第一个入口
                user_two:'',    // 第二个入口
                user_three:'',  // 第三个入口
                user_four:'',   // 第四个入口
                user_five:'',   // 第五个入口
                user_six:'',    // 第六个入口
                user_seven:'',  // 第七个入口
                user_eight:'',  // 第八个入口

                user_one_list:'', // 如何发帖 列表
                textarea:'',  // 反馈意见 内容
                input:'',    // 反馈意见 手机号
                fileList_list:[],
                six_list:'',
            }
        },  
        components:{

        },
        created(){
            // 获取页面传参 推送类型
            this.user_value = this.$route.query.user_value;
            let user_value = this.$route.query.user_value
            console.log(user_value)
            configuration_fields({}).then((res) =>{
                console.log(res,'配置字段的信息')
                if(user_value == '1'){
                    strategy_list({}).then((res)=>{
                        console.log(res,'使用攻略列表')
                        let data = res.data
                        for(let i=0;i<data.length;i++){
                            if(data[i].strategy.length > 2){
                                data[i].user_one = '1'
                            }else{
                                data[i].user_one = '0'
                            }
                        }
                        this.user_one_list = data
                        console.log(this.user_one_list,data)
                    })
                }else if(user_value == '2'){
                    this.user_two = res.data.contact
                }else if(user_value == '3'){
                    
                }else if(user_value == '4'){
                    gonggao_list({}).then((res_res) =>{
                        console.log(res_res)
                        this.six_list = res_res.data
                    })
                    // this.user_four = res.data.
                }else if(user_value == '5'){
                    this.user_five = res.data.xieyi
                }else if(user_value == '6'){
                    this.user_six = res.data.about
                }else if(user_value == '7'){
                    this.user_seven = res.data.corp
                }else if(user_value == '8'){
                    this.user_eight = res.data.join
                }
            })
        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
        },
        methods: {
            // 使用攻略
            user_one_click(value){
                console.log(value)
                let user_one_list = this.user_one_list
                for(let i=0;i<user_one_list.length;i++){
                    if(user_one_list[i].user_one !== '0'){
                        if(user_one_list[i].id == value){
                            if(user_one_list[i].user_one == '1'){
                                user_one_list[i].user_one = '2'
                            }else{
                                user_one_list[i].user_one = '1'
                            }
                        }
                    }
                }
                this.user_one_list = user_one_list
                // this.$router.push({
                //     path:'/usage_tips',
                //     query: {
                //         tips:value
                //     }
                // })
                // let length = value.strategy.length
                
                // if(length == '1'){
                //     window.location.href= value.strategy[0].url
                // }else if(length == '0'){
                //     this.$router.push({
                //         path:'/usage_tips',
                //         query: {
                //             tips:value.id
                //         }
                //     })
                // }
            },
            // 使用攻略 二级跳转
            user_one_url_click(value){
                window.location.href= value
            },
            // 图片上传
            // 删除图片
            handleRemove(file, fileList) {
                console.log(file, fileList);
                    let fileList_list = []
                    for(let i=0;i<fileList.length;i++){
                    fileList_list.push(fileList[i].response.img)
                }
                this.fileList_list = fileList_list 
                console.log(this.fileList_list,'删除图片')
            },
            // 上传成功
            hand_success(file,fileList){
                console.log(file,fileList)
                let fileList_list = this.fileList_list
                
                fileList_list.push(fileList.response.img)
                this.fileList_list = fileList_list
                console.log(this.fileList_list,'上传成功')
            },
            handlePreview(file,fileList) {
                console.log(file,fileList);
            },

            // 意见反馈
            user_three_click(){
                let token = this.token
                let content = this.textarea
                let gallery = this.fileList_list
                let img_list = ''
                for(let i=0;i<gallery.length;i++){
                    img_list = img_list +','+ gallery[i]
                }
                gallery = img_list.substr(1,img_list.length)
                let phone = this.input
                if(content == ''){
                    this.$message({
                        message:'请输入内容',
                        duration:'2000'
                    })
                    return
                }
                add_feedback({token:token,content:content,gallery:gallery,phone:phone}).then((res)=>{
                    console.log(res)
                    if(res.error_code == 0){
                        this.$message({
                            message:'提交成功！',
                            duration:'1000'
                        })
                        var that = this;
                        setTimeout(function () {
                            that.$router.push({path:'/'}) 
                        },1000);
                    }else{
                        this.$message({
                            message:res.msg,
                            duration:'1000'
                        })
                    }
                })
            },
            // 公告跳转
            six_click(type,id,url){
                if(type == 'detail'){
                    this.$router.push({
                        path:'/user_comm_detail',
                        query: {
                            id:id
                        }
                    })
                }else if(type == 'url'){
                    window.location.href= url
                }
            }
        },

    }
</script>