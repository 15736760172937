import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import './utils/rem'


import { vant } from '@/config/vant.config.js'

import 'vant/lib/index.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// const app = createApp(App)
// app.use(ElementPlus)
// app.mount('#app')
createApp(App).use(store).use(router).use(ElementPlus).use(vant).use(VueCookies).mount('#app')
