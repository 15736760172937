<style scoped>
.y_com_bottom_1{
    width: 100%;
    height: 1.2rem;
    background: transparent;
}
.y_com_bottom_2{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 1.2rem;
    position: fixed;
    left: 0rem;
    bottom: 0rem;
    width: 100%;
    border-top: 0.01rem solid #EFEFEF;
    z-index: 2;
}
.y_com_bottom_3{
    flex: 1;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    height: 100%;
    flex-wrap: wrap;
    background: #FFFFFF;
}
.y_com_bottom_5{
    width: 100%;
    text-align: center;
    color: #333333;
    font-size: 0.22rem;
}
.y_com_bottom_4{
    width: 100%;
    position: relative;
}
.y_com_bottom_4 img:nth-child(1){
    display: none;
}
.y_com_bottom_4_num_1{
    position: absolute;
    display: inline-block;
    background: #F82C2C;
    height: 0.3rem;
    padding: 0rem 0.07rem;
    border-radius: 1rem;
    top: 0%;
    left: 50%;
    transform: translate(25%,-50%);
}
.y_com_bottom_4_num_1 p{
    font-size: 0.18rem;
    color: #fff;
    line-height: 0.32rem;
}
.y_display_none{
    display: none;
}
</style>
<template>
    <div class='y_com_bottom_1'></div>
    <div class='y_com_bottom_2'>

        <router-link to='/' class='y_com_bottom_3'>
            <div class='y_com_bottom_4'>
                <img src="../../../images/icon_bottom_1.png" alt="">
                <img src="../../../images/icon_bottom_2.png" alt="">
            </div>
            <div class='y_com_bottom_5'>首页</div>
        </router-link>

        <router-link to='/message_private' class='y_com_bottom_3'>
            <div class='y_com_bottom_4'>
                <img src="../../../images/icon_bottom_3.png" alt="">
                <img src="../../../images/icon_bottom_4.png" alt="">
                <div class="y_com_bottom_4_num_1" :class="not_shown_num == '0'?'y_display_none':''">
                    <p>{{not_shown_num}}</p>
                </div>
            </div>
            <div class='y_com_bottom_5'>消息</div> 
        </router-link>
        
        <router-link to='/my_index' class='y_com_bottom_3'>
            <div class='y_com_bottom_4'>
                <img src="../../../images/icon_bottom_5.png" alt="">
                <img src="../../../images/icon_bottom_6.png" alt="">
            </div>
            <div class='y_com_bottom_5'>我的</div>
        </router-link>
    </div>
</template>

<script>
    import {user_info} from '../../api/request.js'   // 用户信息
    // import './style.css'
    export default {
        data() {
            return {
                msg: "",
                token:'',
                not_shown_num:'0',  // 未显示数量
            }
        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token 

            // 私信 评论未读
            user_info({token:token}).then((res)=>{
                console.log(res,'用户信息')
                if(res.error_code == '0'){
                    this.not_shown_num = res.data.business_not_read_chat_number*1 + res.data.business_not_read_comment_number*1
                }
            })
        },
    }
</script>
