<template>
  <div id="app">
    <router-view v-slot="{ Component }" v-if="$route.meta.keepAlive">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <router-view v-slot="{ Component }" v-if="!$route.meta.keepAlive">
      <component :is="Component" />
    </router-view>
  </div>
</template>
