<template>
    <div class="y_message_private_1">
        <div class="y_message_private_2">
            <router-link to='/message_push_progress' class="y_message_private_3 ">
                <div class="y_message_private_4">
                    <img src="../../../images/icon_44.png" alt="">
                    <img src="../../../images/icon_52.png" alt="">
                    <div class="y_message_private_6" :class="unread_three_val == '0' ?'y_display_none':''">
                        <h2>5</h2>
                    </div>
                </div>
                <div class="y_message_private_5">
                    <h2>推送中</h2>
                </div>
            </router-link>
            <router-link to='/message_to_pushed' class="y_message_private_3 y_message_private_3_active">
                <div class="y_message_private_4">
                    <img src="../../../images/icon_45.png" alt="">
                    <img src="../../../images/icon_53.png" alt="">
                    <div class="y_message_private_6" :class="unread_four_val == '0' ?'y_display_none':''">
                        <h2>5</h2>
                    </div>
                </div>
                <div class="y_message_private_5">
                    <h2>待推送</h2>
                </div>
            </router-link>
            <router-link to='/message_already_offline' class="y_message_private_3">
                <div class="y_message_private_4">
                    <img src="../../../images/icon_46.png" alt="">
                    <img src="../../../images/icon_54.png" alt="">
                    <div class="y_message_private_6" :class="unread_five_val == '0' ?'y_display_none':''">
                        <h2>5</h2>
                    </div>
                </div>
                <div class="y_message_private_5">
                    <h2>已下线</h2>
                </div> 
            </router-link>
            <router-link to='/message_private' class="y_message_private_3 ">
                <div class="y_message_private_4">
                    <img src="../../../images/icon_42.png" alt="">
                    <img src="../../../images/icon_50.png" alt="">
                    <div class="y_message_private_6" :class="unread_one_val == '0' ?'y_display_none':''">
                        <h2>{{business_not_read_chat_number}}</h2>
                    </div>
                </div>
                <div class="y_message_private_5">
                    <h2>私信</h2>
                </div> 
            </router-link> 
            <router-link to='/message_comments' class="y_message_private_3 ">
                <div class="y_message_private_4">
                    <img src="../../../images/icon_43.png" alt="">
                    <img src="../../../images/icon_51.png" alt="">
                    <div class="y_message_private_6" :class="unread_two_val == '0' ?'y_display_none':''">
                        <h2>{{business_not_read_comment_number}}</h2>
                    </div>
                </div>
                <div class="y_message_private_5">
                    <h2>评论</h2>
                </div>
            </router-link>
        </div>
        <div class="y_push_progress_1" >
            <p :class="item.is == '0'?'':'y_push_progress_1_active'" v-for="(item,index) in top_title" :key="index" @click="top_title_pop(index)">{{item.title}}</p>
        </div>
        <div class="y_push_progress_2">
            <!-- <h2>数据每分钟更新一次</h2> -->
        </div>

        <!-- 高级推广 -->
        <div class="y_push_progress_4" v-for="(item,index) in advanced_list" :key="index">
            <div class="y_to_be_pushed_1" v-if="top_id == '0'? true : (top_id == '4'? true :false)">
                <div class="y_to_be_pushed_2 y_to_be_pushed_2_banner" v-if="item.adspace_type == 'banner'?true:false">
                    <img :src="''+item.img"  alt="">
                </div>
                <div class="y_to_be_pushed_2 y_to_be_pushed_2_streamer" v-if="item.adspace_type == 'streamer'?true:false">
                    <img :src="''+item.img"  alt="">
                </div>
                <div class="y_to_be_pushed_2 y_to_be_pushed_2_open_screen" v-if="item.adspace_type == 'open_screen'?true:false">
                    <img :src="''+item.img"  alt="">
                </div>
                <div class="y_to_be_pushed_3">
                    <div class="y_message_pushed_1">
                        <div class="y_to_be_pushed_7" @click="advanced_dele(item.adspace_id)">
                            <img src="../../../images/icon_34.png" alt="">
                            <p>删除</p>
                        </div>
                    </div>
                    <div class="y_to_be_pushed_4" @click="advanced_date(item.adspace_id)">
                        <img src="../../../images/icon_69.png" alt="">
                        <p>编辑</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- 推广-->
        <div class="y_push_progress_4" v-if="top_id == '0'? true : (top_id == '2'? true :false)">
            <div class="y_push_progress_9" v-for="(item,index) in promotion_list" :key="index">
                <div class="y_push_progress_10">
                    <div class="y_push_progress_11">
                        <div class="y_push_progress_12">
                            <div class="y_push_progress_13">
                                <img :src="''+recruitment_user.business_head_portrait" alt="">
                            </div>
                            <div class="y_push_progress_14">
                                <div class="y_to_be_pushed_9">
                                    <h2>{{recruitment_user.business_nicname}}</h2>
                                    <div class="y_to_be_pushed_6">
                                        <div class="y_to_be_pushed_7" @click="promotion_tg_dele(item.blog_id)">
                                            <img src="../../../images/icon_34.png" alt="">
                                            <p>删除</p>
                                        </div>
                                        <div class="y_to_be_pushed_7" @click="promotion_page_tg(item.blog_id)">
                                            <img src="../../../images/icon_48.png" alt="">
                                            <p>编辑</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="y_push_progress_45">
                                    <p>{{item.append_string}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="y_push_progress_18" :class="item.is == '0'?'':'y_push_progress_18_active'">
                        <div class="y_push_progress_19">
                        </div>
                        <span>{{item.content}}</span>
                        <div class="y_push_progress_22" v-if="item.is_show == '0'?false:true">
                            <div class="y_push_progress_23" v-if="item.is == '0'?true:false" @click="promotion_toggle(item.blog_id)"> 
                                <p>展开</p>
                                <img src="../../../images/icon_57.png" alt="">
                            </div>
                            <div class="y_push_progress_23" v-if="item.is == '0'?false:true" @click="promotion_toggle(item.blog_id)"> 
                                <p>收起</p>
                                <img src="../../../images/icon_58.png" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="y_push_progress_24">
                        <div class="y_push_progress_25" v-for="(img,index) in item.gallery" :key="index">
                            <img :src="''+img"  alt="">
                            <div class="y_push_progress_26" v-if="item.img_num == '0'?false:(index == 8?true:false)">
                                <p>+{{ item.img_num }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="y_push_progress_27">
                        <div class="y_push_progress_28">
                            <div class="y_push_progress_29">
                                <p>{{item.append_string}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 招生-->
        <div class="y_push_progress_4" v-if="top_id == '0'? true : (top_id == '3'? true :false)">
            <!-- <div class="y_push_progress_3">
                <p @click="push_date()">查看详情</p>
            </div>
            <div class="y_push_progress_8">
                <div class="y_push_progress_5">
                    <h2>推送倒计时</h2>
                    <div class="y_push_progress_6">
                        <h2>N</h2>
                        <p>天</p>
                        <h2>N</h2>
                        <p>时</p>
                        <h2>N</h2>
                        <p>分</p>
                    </div>
                    <div class="y_push_progress_7">
                        <p>+时</p>
                    </div>
                </div>
                <div class="y_push_progress_5">
                    <h2>已曝光/曝光量</h2>
                    <div class="y_push_progress_6">
                        <h2>200 / 2000</h2>
                    </div>
                    <div class="y_push_progress_7">
                        <p>+曝光量</p>
                    </div>
                </div>
                <div class="y_push_progress_5">
                    <h2>已扣费/预算</h2>
                    <div class="y_push_progress_6">
                        <h2>20元 / 200元</h2>
                    </div>
                    <div class="y_push_progress_7 y_push_progress_7_active">
                        <p></p>
                    </div>
                </div>
            </div> -->

            <div class="y_push_progress_9" v-for="(item,index) in admissions_list" :key="index">
                <div class="y_push_progress_10">
                    <div class="y_push_progress_11">
                        <div class="y_push_progress_12">
                            <div class="y_push_progress_13">
                                <img :src="''+recruitment_user.business_head_portrait" alt="">
                            </div>
                            <div class="y_push_progress_14">
                                <div class="y_to_be_pushed_9">
                                    <h2>{{recruitment_user.business_nicname}}</h2>
                                    <div class="y_to_be_pushed_6">
                                        <div class="y_to_be_pushed_7" @click="promotion_zs_dele(item.blog_id)">
                                            <img src="../../../images/icon_34.png" alt="">
                                            <p>删除</p>
                                        </div>
                                        <div class="y_to_be_pushed_7" @click="promotion_page_zs(item.blog_id)">
                                            <img src="../../../images/icon_48.png" alt="">
                                            <p>编辑</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="y_push_progress_45">
                                    <p>{{item.append_string}}</p>
                                    <!-- <div class="y_push_progress_46">
                                        <img src="../../../images/icon_25.png" alt="">
                                        <p>鄞州万达广场</p>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="y_push_progress_18" :class="item.is == '0'?'':'y_push_progress_18_active'">
                        <div class="y_push_progress_19">
                            <!-- <div class="y_push_progress_20">
                                <img src="../../../images/icon_56.png" alt="">
                                <p>交友/美食</p>
                            </div> -->
                        </div>
                        <span>{{item.content}}</span>
                    
                        <div class="y_push_progress_22" v-if="item.is_show == '0'?false:true">
                            <div class="y_push_progress_23" v-if="item.is == '0'?true:false" @click="promotion_toggle(item.blog_id)"> 
                                <p>展开</p>
                                <img src="../../../images/icon_57.png" alt="">
                            </div>
                            <div class="y_push_progress_23" v-if="item.is == '0'?false:true" @click="promotion_toggle(item.blog_id)"> 
                                <p>收起</p>
                                <img src="../../../images/icon_58.png" alt="">
                            </div>
                        </div>
                    </div>

                    <div class="y_push_progress_24">
                        <div class="y_push_progress_25" v-for="(img,index) in item.gallery" :key="index">
                            <img :src="''+img"  alt="">
                            <div class="y_push_progress_26" v-if="item.img_num == '0'?false:(index == 8?true:false)">
                                <p>+{{ item.img_num }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="y_push_progress_27">
                        <div class="y_push_progress_28">
                            <div class="y_push_progress_29">
                                <p>{{item.append_string}}</p>
                            </div>
                            <!-- <div class="y_push_progress_30">
                                <img src="../../../images/icon_25.png" alt="">
                                <p>鄞州万达广场</p>
                            </div> -->
                        </div>
                        <!-- <div class="y_push_progress_31">
                            <img src="../../../images/icon_48.png" alt="">
                            <p>评论</p>
                        </div> -->
                    </div>
                    <!-- <div class="y_message_pushed_1">
                        <div class="y_to_be_pushed_7" @click="submit_review_tg(item.blog_id)">
                            <img src="../../../images/icon_48.png" alt="">
                            <p>提交审核</p>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- 招聘 -->
        <div class="y_push_progress_4" v-if="top_id == '0'? true : (top_id == '1'? true :false)">
            <div class="y_push_progress_9">
                <div class="y_push_progress_10" v-for="(item,index) in recruitment_list" :key="index">
                    <div class="y_push_progress_39">
                        <div class="y_push_progress_40">
                            <div class="y_push_progress_41">
                                <div class="y_push_progress_42">
                                    <img :src="''+recruitment_user.business_head_portrait" alt="">
                                </div>
                                <div class="y_push_progress_43">
                                    <div class="y_push_progress_44">
                                        <h2>{{recruitment_user.business_nicname}}</h2>
                                        <!-- <p>浙江趋势科…</p> -->
                                    </div>
                                    <div class="y_to_be_pushed_8">
                                        <!-- <div class="y_push_progress_45">
                                            <p>{{item.distance}}</p>
                                            <div class="y_push_progress_46">
                                                <img src="../../../images/icon_25.png" alt="">
                                                <p>{{item.work_place_title}}</p>
                                            </div>
                                        </div> -->
                                        <div class="y_to_be_pushed_6">
                                            <div class="y_to_be_pushed_7" @click="editor_dele(item.recruit_id)">
                                                <img src="../../../images/icon_34.png" alt="">
                                                <p>删除</p>
                                            </div>
                                            <div class="y_to_be_pushed_7" @click="editor_page(item.recruit_id)">
                                                <img src="../../../images/icon_48.png" alt="">
                                                <p>编辑</p>
                                            </div>
                                        </div>
                                    </div>
                                    

                                </div>
                            </div>
                        </div>
                        <!-- <div class="y_push_progress_47">
                            <p>编辑新推送</p>
                        </div> -->
                    </div>
                    
                    <div class="y_push_progress_48">
                        <div class="y_push_progress_49">
                            <h2>{{item.title}}</h2>
                        </div>
                        <div class="y_push_progress_50">
                            <h2>【薪&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资】</h2>

                            <p v-if="item.salary_type == 'range'?true:false">{{item.min_salary_fee}} - {{ item.max_salary_fee }}  元/{{item.settle_type == 'hour'?'小时':(item.settle_type == 'day'?'天':(item.settle_type == 'week'?'周':(item.settle_type == 'month'?'月':(item.settle_type == 'times'?'次':''))))}}</p>
                            <p v-if="item.salary_type == 'fixed'?true:false">{{item.salary_fee}}  元/{{item.settle_type == 'hour'?'小时':(item.settle_type == 'day'?'天':(item.settle_type == 'week'?'周':(item.settle_type == 'month'?'月':(item.settle_type == 'times'?'次':''))))}}</p>
                            <p v-if="item.salary_type == 'self'?true:false">{{ item.salary_self }}</p>
                        </div>
                        <div class="y_push_progress_50">
                            <h2>【福&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;利】</h2>
                            <div class="y_push_progress_51">
                                <p v-for="(fl,index) in item.welfare_arr" :key="index">{{fl}}</p>
                            </div>
                        </div>
                        <div class="y_push_progress_50">
                            <p><span>【工作内容】</span>{{item.job_content}}</p>
                        </div>
                        <div class="y_push_progress_50">
                            <p><span>【工作时间】</span>{{item.work_time}}</p>
                        </div>
                        <div class="y_push_progress_50">
                            <p><span>【工作要求】</span>{{item.other_demand}}</p>
                        </div>
                        <div class="y_push_progress_50">
                            <p><span>【招聘人数】</span>{{item.people_number}}人</p>
                        </div>
                    </div>
                    <div class="y_push_progress_52">
                        <div class="y_push_progress_53">
                            <div class="y_push_progress_54">
                                <img src="../../../images/icon_67.png" alt="">
                                <h2>{{item.work_place_title}}</h2>
                            </div>
                            <div class="y_push_progress_55">
                                <p>距我 {{item.distance}}  
                                    <!-- |  公交30分钟 -->
                                </p>
                            </div>
                        </div>
                        <div class="y_push_progress_56">
                            <img src="../../../images/icon_12.png" alt="">
                        </div>
                    </div>
                    <div class="y_push_progress_24 y_push_progress_57 y_push_progress_57_zp">
                        <div class="y_push_progress_25" v-for="(img,index) in item.gallery" :key="index">
                            <img :src="''+img"  alt="">
                            <div class="y_push_progress_26" v-if="item.img_num == '0'?false:(index == 2?true:false)">
                                <p>+{{ item.img_num }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="y_message_pushed_1">
                        <div class="y_to_be_pushed_7" @click="submit_review_recruitment(item.recruit_id)">
                            <img src="../../../images/icon_48.png" alt="">
                            <p>提交审核</p>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

        <!-- 话题推送 -->
        <div class="y_push_progress_4" v-if="top_id == '0'? true : (top_id == '5'? true :false)">
            <div v-for="(item,index) in recruit_topic_list" :key="index">
                <div class="y_push_progress_9" v-if="item.from_table == 'blog' ? true:false">
                    <div class="y_push_progress_10">
                        <div class="y_push_progress_11">
                            <div class="y_push_progress_12">
                                <div class="y_push_progress_13">
                                    <img :src="''+recruitment_user.business_head_portrait" alt="">
                                </div>
                                <div class="y_push_progress_14">
                                    <div class="y_to_be_pushed_9">
                                        <h2>{{recruitment_user.business_nicname}}</h2>
                                        <div class="y_to_be_pushed_6">
                                            <div class="y_to_be_pushed_7" @click="recruit_topic_dele(item.id,item.from_table)">
                                                <img src="../../../images/icon_34.png" alt="">
                                                <p>删除</p>
                                            </div>
                                            <div class="y_to_be_pushed_7" @click="recruit_topic_list_pop(item.id,item.info_ids,item.from_table)">
                                                <img src="../../../images/icon_48.png" alt="">
                                                <p>编辑</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="y_push_progress_45">
                                        <p>{{item.append_string}}</p>
                                        <div class="y_push_progress_46">
                                            <img src="../../../images/icon_25.png" alt="">
                                            <p>{{item.place_title}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="y_push_progress_18" :class="item.is == '0'?'':'y_push_progress_18_active'">
                            <span>{{item.content}}</span>
                            <div class="y_push_progress_22" v-if="item.is_show == '0'?false:true">
                                <div class="y_push_progress_23" v-if="item.is == '0'?true:false" @click="promotion_toggle(item.id)"> 
                                    <p>展开</p>
                                    <img src="../../../images/icon_57.png" alt="">
                                </div>
                                <div class="y_push_progress_23" v-if="item.is == '0'?false:true" @click="promotion_toggle(item.id)"> 
                                    <p>收起</p>
                                    <img src="../../../images/icon_58.png" alt="">
                                </div>
                            </div>
                        </div>

                        <div class="y_push_progress_24">
                            <div class="y_push_progress_25" v-for="(img,index) in item.gallery" :key="index">
                                <img :src="''+img"  alt="">
                                <div class="y_push_progress_26" v-if="item.img_num == '0'?false:(index == 8?true:false)">
                                    <p>+{{ item.img_num }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="y_to_be_pushed_3">
                            <div class="y_message_pushed_1">
                                <div class="y_to_be_pushed_7" @click="information_topics_pop(item.id,'blog')">
                                    <img src="../../../images/icon_48.png" alt="">
                                    <p>提交审核</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="y_push_progress_10" v-if="item.from_table == 'recruit' ? true:false">
                    <div class="y_push_progress_39">
                        <div class="y_push_progress_40">
                            <div class="y_push_progress_41">
                                <div class="y_push_progress_42">
                                    <img :src="''+recruitment_user.business_head_portrait" alt="">
                                </div>
                                <div class="y_push_progress_43">
                                    <div class="y_push_progress_44">
                                        <h2>{{recruitment_user.business_nicname}}</h2>
                                    </div>
                                    <div class="y_to_be_pushed_8">
                                        <div class="y_to_be_pushed_6">
                                            <div class="y_to_be_pushed_7" @click="recruit_topic_dele(item.id,item.from_table)">
                                                <img src="../../../images/icon_34.png" alt="">
                                                <p>删除</p>
                                            </div>
                                            <div class="y_to_be_pushed_7" @click="recruit_topic_list_pop(item.id,item.info_ids,item.from_table)">
                                                <img src="../../../images/icon_48.png" alt="">
                                                <p>编辑</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="y_push_progress_48">
                        <div class="y_push_progress_49">
                            <h2>{{item.title}}</h2>
                        </div>
                        <div class="y_push_progress_50">
                            <h2>【薪&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资】</h2>
                            <p v-if="item.salary_type == 'range'?true:false">{{item.min_salary_fee}} - {{ item.max_salary_fee }}  元/{{item.settle_type == 'hour'?'小时':(item.settle_type == 'day'?'天':(item.settle_type == 'week'?'周':(item.settle_type == 'month'?'月':(item.settle_type == 'times'?'次':''))))}}</p>
                            <p v-if="item.salary_type == 'fixed'?true:false">{{item.salary_fee}}  元/{{item.settle_type == 'hour'?'小时':(item.settle_type == 'day'?'天':(item.settle_type == 'week'?'周':(item.settle_type == 'month'?'月':(item.settle_type == 'times'?'次':''))))}}</p>
                            <p v-if="item.salary_type == 'self'?true:false">{{ item.salary_self }}</p>
                        </div>
                        <div class="y_push_progress_50">
                            <h2>【福&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;利】</h2>
                            <div class="y_push_progress_51">
                                <p v-for="(fl,index) in item.welfare_arr" :key="index">{{fl.title}}</p>
                            </div>
                        </div>
                        <div class="y_push_progress_50">
                            <p><span>【工作内容】</span>{{item.job_content}}</p>
                        </div>
                        <div class="y_push_progress_50">
                            <p><span>【工作时间】</span>{{item.work_time}}</p>
                        </div>
                        <div class="y_push_progress_50">
                            <p><span>【工作要求】</span>{{item.other_demand}}</p>
                        </div>
                        <div class="y_push_progress_50">
                            <p><span>【招聘人数】</span>{{item.people_number}}人</p>
                        </div>
                    </div>
                    <div class="y_push_progress_52">
                        <div class="y_push_progress_53">
                            <div class="y_push_progress_54">
                                <img src="../../../images/icon_67.png" alt="">
                                <h2>{{item.work_place_title}}</h2>
                            </div>
                            <div class="y_push_progress_55">
                                <p>距我 {{item.distance}}  
                                </p>
                            </div>
                        </div>
                        <div class="y_push_progress_56">
                            <img src="../../../images/icon_12.png" alt="">
                        </div>
                    </div>
                    <div class="y_push_progress_24 y_push_progress_57 y_push_progress_57_zp">
                        <div class="y_push_progress_25" v-for="(img,index) in item.gallery" :key="index">
                            <img :src="''+img"  alt="">
                            <div class="y_push_progress_26" v-if="item.img_num == '0'?false:(index == 2?true:false)">
                                <p>+{{ item.img_num }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="y_to_be_pushed_3">
                        <div class="y_message_pushed_1">
                            <div class="y_to_be_pushed_7" @click="information_topics_pop(item.id,'recruit')">
                                <img src="../../../images/icon_48.png" alt="">
                                <p>提交审核</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- 小组推送 -->
        <div class="y_push_progress_4" v-if="top_id == '0'? true : (top_id == '6'? true :false)">
            <div v-for="(item,index) in panel_topic_list" :key="index">
                <div class="y_push_progress_9" v-if="item.from_table == 'blog' ? true:false">
                    <div class="y_push_progress_10">
                        <div class="y_push_progress_11">
                            <div class="y_push_progress_12">
                                <div class="y_push_progress_13">
                                    <img :src="''+recruitment_user.business_head_portrait" alt="">
                                </div>
                                <div class="y_push_progress_14">
                                    <div class="y_to_be_pushed_9">
                                        <h2>{{recruitment_user.business_nicname}}</h2>
                                        <div class="y_to_be_pushed_6">
                                            <div class="y_to_be_pushed_7" @click="panel_topic_dele(item.id,item.from_table)">
                                                <img src="../../../images/icon_34.png" alt="">
                                                <p>删除</p>
                                            </div>
                                            <div class="y_to_be_pushed_7" @click="panel_topic_list_pop(item.id,item.info_ids,item.from_table)">
                                                <img src="../../../images/icon_48.png" alt="">
                                                <p>编辑</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="y_push_progress_45">
                                        <p>{{item.append_string}}</p>
                                        <div class="y_push_progress_46">
                                            <img src="../../../images/icon_25.png" alt="">
                                            <p>{{item.place_title}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="y_push_progress_18" :class="item.is == '0'?'':'y_push_progress_18_active'">
                            <span>{{item.content}}</span>
                            <div class="y_push_progress_22" v-if="item.is_show == '0'?false:true">
                                <div class="y_push_progress_23" v-if="item.is == '0'?true:false" @click="promotion_toggle(item.id)"> 
                                    <p>展开</p>
                                    <img src="../../../images/icon_57.png" alt="">
                                </div>
                                <div class="y_push_progress_23" v-if="item.is == '0'?false:true" @click="promotion_toggle(item.id)"> 
                                    <p>收起</p>
                                    <img src="../../../images/icon_58.png" alt="">
                                </div>
                            </div>
                        </div>

                        <div class="y_push_progress_24">
                            <div class="y_push_progress_25" v-for="(img,index) in item.gallery" :key="index">
                                <img :src="''+img"  alt="">
                                <div class="y_push_progress_26" v-if="item.img_num == '0'?false:(index == 8?true:false)">
                                    <p>+{{ item.img_num }}</p>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="y_push_progress_27">
                            <div class="y_push_progress_28">
 
                            </div>
                            <div class="y_push_progress_31">
                                <img src="../../../images/icon_48.png" alt="">
                                <p>评论</p>
                            </div>
                        </div> -->

                        <!-- <div class="y_to_be_pushed_3">
                            <div class="y_message_pushed_1">
                                <div class="y_to_be_pushed_7" @click="information_panel_pop(item.id,'blog')">
                                    <img src="../../../images/icon_48.png" alt="">
                                    <p>提交审核</p>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="y_push_progress_10" v-if="item.from_table == 'recruit' ? true:false">
                    <div class="y_push_progress_39">
                        <div class="y_push_progress_40">
                            <div class="y_push_progress_41">
                                <div class="y_push_progress_42">
                                    <img :src="''+recruitment_user.business_head_portrait" alt="">
                                </div>
                                <div class="y_push_progress_43">
                                    <div class="y_push_progress_44">
                                        <h2>{{recruitment_user.business_nicname}}</h2>
                                        <!-- <p>浙江趋势科…</p> -->
                                    </div>
                                    <div class="y_to_be_pushed_8">
                                        <!-- <div class="y_push_progress_45">
                                            <p>{{item.distance}}</p>
                                            <div class="y_push_progress_46">
                                                <img src="../../../images/icon_25.png" alt="">
                                                <p>{{item.work_place_title}}</p>
                                            </div>
                                        </div> -->
                                        <div class="y_to_be_pushed_6">
                                            <div class="y_to_be_pushed_7" @click="panel_topic_dele(item.id,item.from_table)">
                                                <img src="../../../images/icon_34.png" alt="">
                                                <p>删除</p>
                                            </div>
                                            <div class="y_to_be_pushed_7" @click="panel_topic_list_pop(item.id,item.info_ids,item.from_table)">
                                                <img src="../../../images/icon_48.png" alt="">
                                                <p>编辑</p>
                                            </div>
                                        </div>
                                    </div>
                                    

                                </div>
                            </div>
                        </div>
                        <!-- <div class="y_push_progress_47">
                            <p>编辑新推送</p>
                        </div> -->
                    </div>
                    
                    <div class="y_push_progress_48">
                        <div class="y_push_progress_49">
                            <h2>{{item.title}}</h2>
                        </div>
                        <div class="y_push_progress_50">
                            <h2>【薪&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资】</h2>

                            <p v-if="item.salary_type == 'range'?true:false">{{item.min_salary_fee}} - {{ item.max_salary_fee }}  元/{{item.settle_type == 'hour'?'小时':(item.settle_type == 'day'?'天':(item.settle_type == 'week'?'周':(item.settle_type == 'month'?'月':(item.settle_type == 'times'?'次':''))))}}</p>
                            <p v-if="item.salary_type == 'fixed'?true:false">{{item.salary_fee}}  元/{{item.settle_type == 'hour'?'小时':(item.settle_type == 'day'?'天':(item.settle_type == 'week'?'周':(item.settle_type == 'month'?'月':(item.settle_type == 'times'?'次':''))))}}</p>
                            <p v-if="item.salary_type == 'self'?true:false">{{ item.salary_self }}</p>
                        </div>
                        <div class="y_push_progress_50">
                            <h2>【福&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;利】</h2>
                            <div class="y_push_progress_51">
                                <p v-for="(fl,index) in item.welfare_arr" :key="index">{{fl.title}}</p>
                            </div>
                        </div>
                        <!-- <div class="y_push_progress_50">
                            <p>日常演讲，拍摄宣传片、宣传照等宣传物料，配合参与学校举办的各项公益宣传活动。</p>
                        </div> -->
                        <div class="y_push_progress_50">
                            <p><span>【工作内容】</span>{{item.job_content}}</p>
                        </div>
                        <div class="y_push_progress_50">
                            <p><span>【工作时间】</span>{{item.work_time}}</p>
                        </div>
                        <div class="y_push_progress_50">
                            <p><span>【工作要求】</span>{{item.other_demand}}</p>
                        </div>
                        <div class="y_push_progress_50">
                            <p><span>【招聘人数】</span>{{item.people_number}}人</p>
                        </div>
                    </div>
                    <div class="y_push_progress_52">
                        <div class="y_push_progress_53">
                            <div class="y_push_progress_54">
                                <img src="../../../images/icon_67.png" alt="">
                                <h2>{{item.work_place_title}}</h2>
                            </div>
                            <div class="y_push_progress_55">
                                <p>距我 {{item.distance}}  
                                    <!-- |  公交30分钟 -->
                                </p>
                            </div>
                        </div>
                        <div class="y_push_progress_56">
                            <img src="../../../images/icon_12.png" alt="">
                        </div>
                    </div>
                    <div class="y_push_progress_24 y_push_progress_57 y_push_progress_57_zp">
                        <div class="y_push_progress_25" v-for="(img,index) in item.gallery" :key="index">
                            <img :src="''+img"  alt="">
                            <div class="y_push_progress_26" v-if="item.img_num == '0'?false:(index == 2?true:false)">
                                <p>+{{ item.img_num }}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="y_to_be_pushed_3">
                        <div class="y_message_pushed_1">
                            <div class="y_to_be_pushed_7" @click="information_panel_pop(item.id,'recruit')">
                                <img src="../../../images/icon_48.png" alt="">
                                <p>提交审核</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

        <!-- 活动推送 -->
        <div class="y_push_progress_4" v-if="top_id == '0'? true : (top_id == '7'? true :false)">
            <div v-for="(item,index) in activity_topic_list" :key="index">
                <!-- <div class="y_push_progress_3">
                    <p>查看详情</p>
                </div> -->
                <!-- <div class="y_push_progress_8">
                    <div class="y_push_progress_5">
                        <h2>推送倒计时</h2>
                        <div class="y_push_progress_6">
                            <h2>N</h2>
                            <p>天</p>
                            <h2>N</h2>
                            <p>时</p>
                            <h2>N</h2>
                            <p>分</p>
                        </div>
                        <div class="y_push_progress_7">
                            <p>+时</p>
                        </div>
                    </div>
                    <div class="y_push_progress_5">
                        <h2>已曝光/曝光量</h2>
                        <div class="y_push_progress_6">
                            <h2>200 / 2000</h2>
                        </div>
                        <div class="y_push_progress_7">
                            <p>+曝光量</p>
                        </div>
                    </div>
                    <div class="y_push_progress_5">
                        <h2>已扣费/预算</h2>
                        <div class="y_push_progress_6">
                            <h2>20元 / 200元</h2>
                        </div>
                        <div class="y_push_progress_7 y_push_progress_7_active">
                            <p></p>
                        </div>
                    </div>
                </div> -->
                <div class="y_push_progress_9" v-if="item.from_table == 'blog' ? true:false">
                    <div class="y_push_progress_10">
                        <div class="y_push_progress_11">
                            <div class="y_push_progress_12">
                                <div class="y_push_progress_13">
                                    <img :src="''+recruitment_user.business_head_portrait" alt="">
                                </div>
                                <div class="y_push_progress_14">
                                    <div class="y_to_be_pushed_9">
                                        <h2>{{recruitment_user.business_nicname}}</h2>
                                        <div class="y_to_be_pushed_6">
                                            <div class="y_to_be_pushed_7" @click="activity_topic_dele(item.id,item.from_table)">
                                                <img src="../../../images/icon_34.png" alt="">
                                                <p>删除</p>
                                            </div>
                                            <div class="y_to_be_pushed_7" @click="activity_topic_list_pop(item.id,item.info_ids,item.from_table)">
                                                <img src="../../../images/icon_48.png" alt="">
                                                <p>编辑</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="y_push_progress_45">
                                        <p>{{item.append_string}}</p>
                                        <div class="y_push_progress_46">
                                            <img src="../../../images/icon_25.png" alt="">
                                            <p>{{item.place_title}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="y_push_progress_18" :class="item.is == '0'?'':'y_push_progress_18_active'">
                            <!-- <div class="y_push_progress_19">
                                <div class="y_push_progress_20">
                                    <img src="../../../images/icon_56.png" alt="">
                                    <p>交友/美食</p>
                                </div>
                            </div> -->
                            <span>{{item.content}}</span>
                        
                            <div class="y_push_progress_22" v-if="item.is_show == '0'?false:true">
                                <div class="y_push_progress_23" v-if="item.is == '0'?true:false" @click="promotion_toggle(item.id)"> 
                                    <p>展开</p>
                                    <img src="../../../images/icon_57.png" alt="">
                                </div>
                                <div class="y_push_progress_23" v-if="item.is == '0'?false:true" @click="promotion_toggle(item.id)"> 
                                    <p>收起</p>
                                    <img src="../../../images/icon_58.png" alt="">
                                </div>
                            </div>
                        </div>

                        <div class="y_push_progress_24">
                            <!-- <div class="y_push_progress_25">
                                <div class="demo-image__preview">
                                    <el-image :src="url"  :preview-src-list="srcList"></el-image>
                                </div>
                            </div> -->

                            <div class="y_push_progress_25" v-for="(img,index) in item.gallery" :key="index">
                                <img :src="''+img"  alt="">
                                <div class="y_push_progress_26" v-if="item.img_num == '0'?false:(index == 8?true:false)">
                                    <p>+{{ item.img_num }}</p>
                                </div>
                            </div>

                            <!-- <div class="y_push_progress_25">
                                <img src="../../../images/icon_66.png" alt="">
                                <div class="y_push_progress_26">
                                    <p>+3</p>
                                </div>
                            </div> -->
                        </div>

                        <!-- <div class="y_push_progress_27">
                            <div class="y_push_progress_28">
 
                            </div>
                            <div class="y_push_progress_31">
                                <img src="../../../images/icon_48.png" alt="">
                                <p>评论</p>
                            </div>
                        </div> -->
                        <!-- <div class="y_to_be_pushed_3">
                            <div class="y_message_pushed_1">
                                <div class="y_to_be_pushed_7" @click="information_events_pop(item.id,'blog')">
                                    <img src="../../../images/icon_48.png" alt="">
                                    <p>提交审核</p>
                                </div>
                            </div>
                        </div> -->

                    </div>
                </div>
                <div class="y_push_progress_10" v-if="item.from_table == 'recruit' ? true:false">
                    <div class="y_push_progress_39">
                        <div class="y_push_progress_40">
                            <div class="y_push_progress_41">
                                <div class="y_push_progress_42">
                                    <img :src="''+recruitment_user.business_head_portrait" alt="">
                                </div>
                                <div class="y_push_progress_43">
                                    <div class="y_push_progress_44">
                                        <h2>{{recruitment_user.business_nicname}}</h2>
                                        <!-- <p>浙江趋势科…</p> -->
                                    </div>
                                    <div class="y_to_be_pushed_8">
                                        <!-- <div class="y_push_progress_45">
                                            <p>{{item.distance}}</p>
                                            <div class="y_push_progress_46">
                                                <img src="../../../images/icon_25.png" alt="">
                                                <p>{{item.work_place_title}}</p>
                                            </div>
                                        </div> -->
                                        <div class="y_to_be_pushed_6">
                                            <div class="y_to_be_pushed_7" @click="activity_topic_dele(item.id,item.from_table)">
                                                <img src="../../../images/icon_34.png" alt="">
                                                <p>删除</p>
                                            </div>
                                            <div class="y_to_be_pushed_7" @click="activity_topic_list_pop(item.id,item.info_ids,item.from_table)">
                                                <img src="../../../images/icon_48.png" alt="">
                                                <p>编辑</p>
                                            </div>
                                        </div>
                                    </div>
                                    

                                </div>
                            </div>
                        </div>
                        <!-- <div class="y_push_progress_47">
                            <p>编辑新推送</p>
                        </div> -->
                    </div>
                    
                    <div class="y_push_progress_48">
                        <div class="y_push_progress_49">
                            <h2>{{item.title}}</h2>
                        </div>
                        <div class="y_push_progress_50">
                            <h2>【薪&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资】</h2>

                            <p v-if="item.salary_type == 'range'?true:false">{{item.min_salary_fee}} - {{ item.max_salary_fee }}  元/{{item.settle_type == 'hour'?'小时':(item.settle_type == 'day'?'天':(item.settle_type == 'week'?'周':(item.settle_type == 'month'?'月':(item.settle_type == 'times'?'次':''))))}}</p>
                            <p v-if="item.salary_type == 'fixed'?true:false">{{item.salary_fee}}  元/{{item.settle_type == 'hour'?'小时':(item.settle_type == 'day'?'天':(item.settle_type == 'week'?'周':(item.settle_type == 'month'?'月':(item.settle_type == 'times'?'次':''))))}}</p>
                            <p v-if="item.salary_type == 'self'?true:false">{{ item.salary_self }}</p>
                        </div>
                        <div class="y_push_progress_50">
                            <h2>【福&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;利】</h2>
                            <div class="y_push_progress_51">
                                <p v-for="(fl,index) in item.welfare_arr" :key="index">{{fl.title}}</p>
                            </div>
                        </div>
                        <!-- <div class="y_push_progress_50">
                            <p>日常演讲，拍摄宣传片、宣传照等宣传物料，配合参与学校举办的各项公益宣传活动。</p>
                        </div> -->
                        <div class="y_push_progress_50">
                            <p><span>【工作内容】</span>{{item.job_content}}</p>
                        </div>
                        <div class="y_push_progress_50">
                            <p><span>【工作时间】</span>{{item.work_time}}</p>
                        </div>
                        <div class="y_push_progress_50">
                            <p><span>【工作要求】</span>{{item.other_demand}}</p>
                        </div>
                        <div class="y_push_progress_50">
                            <p><span>【招聘人数】</span>{{item.people_number}}人</p>
                        </div>
                    </div>
                    <div class="y_push_progress_52">
                        <div class="y_push_progress_53">
                            <div class="y_push_progress_54">
                                <img src="../../../images/icon_67.png" alt="">
                                <h2>{{item.work_place_title}}</h2>
                            </div>
                            <div class="y_push_progress_55">
                                <p>距我 {{item.distance}}  
                                    <!-- |  公交30分钟 -->
                                </p>
                            </div>
                        </div>
                        <div class="y_push_progress_56">
                            <img src="../../../images/icon_12.png" alt="">
                        </div>
                    </div>
                    <div class="y_push_progress_24 y_push_progress_57 y_push_progress_57_zp">
                        <div class="y_push_progress_25" v-for="(img,index) in item.gallery" :key="index">
                            <img :src="''+img"  alt="">
                            <div class="y_push_progress_26" v-if="item.img_num == '0'?false:(index == 2?true:false)">
                                <p>+{{ item.img_num }}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="y_to_be_pushed_3">
                        <div class="y_message_pushed_1">
                            <div class="y_to_be_pushed_7" @click="information_events_pop(item.id,'recruit')">
                                <img src="../../../images/icon_48.png" alt="">
                                <p>提交审核</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <!-- 发起抽奖 -->
        <div class="y_push_progress_4" v-if="top_id == '0'? true : (top_id == '8'? true :false)">
            <!-- <div class="y_push_progress_3">
                <p>查看详情</p>
            </div>
            <div class="y_push_progress_71">
                <div class="y_push_progress_72">
                    <h2>已点击浏览人数</h2>
                    <p>N</p>
                </div>
                <div class="y_push_progress_72">
                    <h2>已扣费/预算</h2>
                    <p>20元 / 200元</p>
                </div>
            </div> -->
            <div class="y_push_progress_73" v-for="(item,index) in award_list" :key="index">
                <div class="y_push_progress_74">
                    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#FECE0A" >
                        <van-swipe-item v-for='(img,index) in item.award_prize' :key="index">
                            <img :src="''+img.img"  alt="">
                            <div class="y_push_progress_76">
                                <h2>{{ img.title }}</h2>
                            </div>
                        </van-swipe-item>
                    </van-swipe>
                </div>
                <div class="y_push_progress_75 y_to_be_pushed_5">
                    <div class="y_to_be_pushed_6">
                        <!-- <div class="y_message_pushed_1">
                            <div class="y_to_be_pushed_7" @click="submit_luck_draw(item.award_id)">
                                <img src="../../../images/icon_48.png" alt="">
                                <p>提交审核</p>
                            </div>
                        </div> -->
                        <div class="y_to_be_pushed_7" @click="award_dele(item.award_id)">
                            <img src="../../../images/icon_34.png" alt="">
                            <p>删除</p>
                        </div>
                        <div class="y_to_be_pushed_7" @click="award_page(item.award_id)">
                            <img src="../../../images/icon_48.png" alt="">
                            <p>编辑</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 字符140 -->
        <div class="y_page_1" v-if="top_id !== '0'? true : false">
            <el-pagination background :pager-count="10" :current-page="currentPage" layout="prev, pager, next" @current-change="handleCurrentChange" :hide-on-single-page="true" :total="count"></el-pagination>
        </div>
    </div>

    <kefu></kefu>
    <bottom></bottom>
</template>

<style scoped>
    .y_message_private_1{
        background: #F4F5F7;
    }
    .y_message_private_2{
        border-bottom: 0rem;
        background: #fff;
    }
    .y_push_progress_1{
        width: calc(100% - 0.4rem);
        background: #fff;
        height: 0.8rem;
        padding-left: 0.4rem;
        overflow-x: scroll;
        display: -webkit-box;
    }
    .y_push_progress_1 p{
        font-size: 0.28rem;
        color: #666;
        display: flex;
        height: 100%;
        margin-right: 0.45rem;
        align-items: center;
        transition: ease 0.5s all;
    }
    .y_push_progress_1 p:nth-child(1){
        font-weight: 600;
        color: #333;
    }
    .y_push_progress_1 .y_push_progress_1_active{
        position: relative;
        color: #FECE0A !important;
        transition: ease 0.5s all;
    }
    .y_push_progress_1 .y_push_progress_1_active::after{
        position: absolute;
        bottom: 0rem;
        left: 0rem;
        width: 100%;
        height: 0.06rem;
        border-radius: 0.1rem;
        background: #FECE0A;
        content: '';
    }
    .y_push_progress_2{
        margin: 0rem 0.25rem;
        /* height: 0.7rem; */
        height: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    .y_push_progress_2 h2{
        color: #999;
        font-size: 0.24rem;
    }
    .y_push_progress_3{
        height: 0.6rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }
    .y_push_progress_3 p{
        font-size: 0.24rem;
        color: #222;
        text-decoration:underline
    }
    .y_push_progress_4{
        margin: 0rem 0.25rem;
    }
    .y_push_progress_8{
        height: 2rem;
        display: flex;
        justify-items: center;
        align-items: center;
        border-radius: 0.1rem;
        background: #fff;
        box-shadow: 0 0 6px 1px #ccc;
    }
    .y_push_progress_5{
        flex: 1;
        display: flex;
        flex-wrap: wrap;
    }
    .y_push_progress_5>h2{
        width: 100%;
        text-align: center;
        font-size: 0.24rem;
        color:#999;
    }
    .y_push_progress_6{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-top: 0.1rem;
    }
    .y_push_progress_6 h2{
        color:#FECE0A;
        font-size: 0.28rem;
    }
    .y_push_progress_6 p{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_progress_7{
        width: 100%;
        margin-top: 0.1rem;
        text-align: center;
    }
    .y_push_progress_7 p{
        background: #FECE0A;
        display: inline-block;
        height: 0.45rem;
        color: #333;
        font-size: 0.26rem;
        padding: 0rem 0.3rem;
        border-radius: 0.1rem;
        line-height: 0.45rem;
    }
    .y_push_progress_7_active p{
        background: transparent;
    }
    .y_push_progress_9{
        margin: 0.25rem 0rem 0rem;
    }
    .y_push_progress_10{
        padding: 0rem 0.25rem 0.2rem;
        border-radius: 0.1rem;
        background: #fff;
        box-shadow: 0 0 6px 1px #ccc;
        margin-bottom: 0.3rem;
    }
    .y_push_progress_11{
        height: 1.45rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_push_progress_12{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }
    .y_push_progress_13{
        width: 0.9rem;
        height: 0.9rem;
        border-radius: 1rem;
        overflow: hidden;
        margin-right: 0.13rem;
    }
    .y_push_progress_13 img{
        width: 100%;
        object-fit: cover;
        height: 100%;
    }
    .y_push_progress_14{
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        flex:1;
    }
    .y_push_progress_14>h2{
        font-size: 0.28rem;
        color: #333;
        width: 100%;
    }
    .y_push_progress_15{
        display: inline-block;
        margin-top: 0.1rem;
    }
    .y_push_progress_21{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.3rem;
        background: #FECE0A;
        border-radius: 0.1rem;
        padding: 0rem 0.1rem;
    }
    .y_push_progress_15 img{
        width: 0.18rem;
        height: 0.18rem;
    }
    .y_push_progress_15 p{
        font-size: 0.2rem;
        color: #333;
        margin-left: 0.15rem;
    }
    .y_push_progress_16{
        border-radius: 1rem;
        border: 0.01rem solid #FECE0A;
        height: 0.38rem;
        min-width: 1.5rem;
        text-align: center;
    }
    .y_push_progress_17{
        line-height: 0.38rem;
        color: #FECE0A;
        font-size: 0.22rem;
    }
    .y_push_progress_18{
        width: 100%;
        position: relative;
        max-height: 2.35rem;
        overflow: hidden;
        min-height: 0.4rem;
    }
    .y_push_progress_18 span{
        font-size: 0.28rem;
        color: #333;
    }
    .y_push_progress_18_active{
        max-height: 100%;
    }
    .y_push_progress_19{
        display: inline-block;
    }
    .y_push_progress_20{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #FBF5DC;
        padding: 0rem 0.1rem;
        height: 0.38rem;
        border-radius: 0.1rem;
    }
    .y_push_progress_20 img{
        width: 0.23rem;
        height: 0.23rem;
    }
    .y_push_progress_20 p{
        font-size: 0.24rem;
        color: #E9BE0E;
    }
    .y_push_progress_22{
        position: absolute;
        bottom: 0.1rem;
        right: 0rem;
    }
    .y_push_progress_23{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 1rem;
        height: 0.3rem;
        background: #fff;
    }
    .y_push_progress_23 p{
        color: #E9BE0E;
        font-size: 0.28rem;
    }
    .y_push_progress_23 img{
        width: 0.14rem;
        height: 0.08rem;
        margin-left: 0.1rem;
    }
    /* .y_push_progress_22 .y_push_progress_23:nth-child(1){
        display: flex;
    }
    .y_push_progress_22 .y_push_progress_23:nth-child(2){
        display: none;
    }
    .y_push_progress_22_active .y_push_progress_23:nth-child(1){
        display: none;
    }
    .y_push_progress_22_active .y_push_progress_23:nth-child(2){
        display: flex;
    } */
    .y_push_progress_24{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0.3rem 0rem 0.1rem;
        max-height: 6.45rem;
        overflow: hidden;
    }
    .y_push_progress_25{
        width: 2.18rem;
        margin-right: 0.05rem;
        height: 2.1rem;
        margin-bottom: 0.05rem;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .y_push_progress_25 img{
        /* width: 100%; */
        height: 100%;
        object-fit: cover;
    }
    .y_push_progress_24 .y_push_progress_25:nth-child(3n){
        margin-right: 0rem;
    }
    .y_push_progress_24 .y_push_progress_25:nth-child(9)::after{
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        background: rgba(0,0,0,0.7);
        top:0rem;
        left: 0rem;
    } 
    .y_push_progress_26{
        position: absolute;
        top: 0rem;
        left: 0rem;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0,0,0,0.7);
    }
    .y_push_progress_26 p{
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 0.36rem;
    }
    .y_push_progress_27{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1rem;
    }
    .y_push_progress_28{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }
    .y_push_progress_29 p{
        font-size: 0.22rem;
        color: #888;
    } 
    .y_push_progress_30{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 0.23rem;
    }
    .y_push_progress_30 img{
        width: 0.2rem;
        height: 0.24rem;
    }
    .y_push_progress_30 p{
        font-size: 0.22rem;
        color:#888;
        margin-left: 0.1rem;
    } 
    .y_push_progress_31{
        width: 1.1rem;
        height: 0.48rem;
        border-radius: 1rem;
        border: 0.01rem solid #ccc;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .y_push_progress_31 img{
        width: 0.23rem;
        height: 0.22rem;
    }
    .y_push_progress_31 p{
        font-size: 0.24rem;
        color: #333;
        margin-left: 0.1rem;
    }
    .y_push_progress_32{
        background: #fff;
        border-radius: 0.1rem;
        padding: 0.4rem 0.3rem 0.5rem 0.4rem;
        box-shadow:0 0 0.375rem 0.0625rem #ccc;
    }
    .y_push_progress_33{
        width: 100%;
        margin-bottom: 0.1rem;
    }
    .y_push_progress_33 p{
        font-size: 0.24rem;
        color: #999;
    }
    .y_push_progress_33 p span{
        color:#FECE0A;
        font-size: 0.28rem;
    }
    .y_push_progress_34{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0.46rem;
        margin-bottom: 0.1rem;
    }
    .y_push_progress_35{
        flex: 1;
        margin-right: 0.2rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_progress_35 p{
        font-size: 0.24rem;
        color: #999;
    }
    .y_push_progress_35 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_progress_36{
        padding: 0rem 0.15rem;
        display: inline-block;
        background: #FECE0A;
        border-radius: 0.1rem;
        height: 100%;
    }
    .y_push_progress_36 h2{
        line-height: 0.46rem;
        color: #333;
        font-size: 0.26rem;
    }
    .y_push_progress_37{
        width: 100%;
        padding-bottom: 0.3rem;
        border-bottom: 0.01rem solid #eee;
    }
    .y_push_progress_38{
        margin-top: 0.2rem;
    }
    .y_push_progress_39{
        height: 1.45rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_push_progress_40{
        flex: 1;
    }
    .y_push_progress_41{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }
    .y_push_progress_42{
        width: 0.9rem;
        height: 0.9rem;
        margin-right: 0.13rem;
        overflow: hidden;
        border-radius: 1rem;
    }
    .y_push_progress_42 img{
        width: 100%;
        object-fit: cover;
        height: 100%;
    }
    .y_push_progress_43{
        flex: 1;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
    }
    .y_push_progress_44{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }
    .y_push_progress_44 h2{
        font-size: 0.28rem;
        color: #333;
    }
    .y_push_progress_44 p{
        width: 1.3rem;
        height: 0.3rem;
        margin-left: 0.1rem;
        background: #FECE0A;
        padding: 0rem 0.1rem;
        font-size: 0.2rem;
        color: #333;
        line-height: 0.3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-radius: 0.05rem;
    } 
    .y_push_progress_45{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-top: 0.1rem;
    }
    .y_push_progress_45 p{
        font-size: 0.22rem;
        color: #888;
        min-width: 1.8rem;
    }
    .y_push_progress_46{
        display: flex;
        margin-left: 0.3rem;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_progress_46 img{
        width: 0.2rem;
        height: 0.24rem;
        margin-right: 0.1rem;
    }
    .y_push_progress_47{
        width: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .y_push_progress_47 p{
        width: 100%;
        border-radius: 1rem;
        height: 0.38rem;
        line-height: 0.38rem;
        border: 0.01rem solid #FECE0A;
        color: #FECE0A;
        font-size: 0.22rem;
        text-align: center;
    }
    .y_push_progress_48{
        width: 100%;
    }
    .y_push_progress_49{
        width: 100%;
        text-align: center;
    }
    .y_push_progress_49 h2{
        font-size: 0.36rem;
        color: #333;
        margin-bottom: 0.35rem;
        line-height: 0.34rem;
        font-weight: 600;
    }
    .y_push_progress_50{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0.2rem;
    }
    .y_push_progress_50 h2{
        font-size: 0.28rem;
        color: #333;
        margin-right: 0.1rem;
        min-width: 1.35rem;
    }
    .y_push_progress_50 p{
        font-size: 0.28rem;
        color: #333;
    }
    .y_push_progress_51{
        flex: 1;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .y_push_progress_51 p{
        color: #666;
        font-size: 0.22rem;
        display: inline-block;
        padding: 0rem 0.15rem;
        line-height: 0.38rem;
        background: #F3F3F3;
        margin-right: 0.1rem;
        margin-top: 0.1rem;
        margin-bottom: 0.1rem;
    }
    .y_push_progress_52{
        padding: 0rem 0.2rem 0rem 0.3rem;
        background: #f3f3f3;
        display: flex;
        height: 1.4rem;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.1rem;
    }
    .y_push_progress_53{
        flex: 1;
        margin-right: 0.3rem;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
    }
    .y_push_progress_54{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_progress_54 img{
        width: 0.3rem;
        height: 0.38rem;
        margin-right: 0.1rem;
    }
    .y_push_progress_54 h2{
        color: #333;
        font-size: 0.3rem;
        line-height: 0.38rem;
    }
    .y_push_progress_55{
        width: 100%;
        margin-top: 0.15rem;
        display: none;
    }
    .y_push_progress_55 p{
        font-size: 0.22rem;
        color: #888;
    }
    .y_push_progress_56 img{
        width: 0.12rem;
        object-fit: cover;
    }
    /* .y_push_progress_57{
        padding-bottom: 0.5rem;
    } */
    .y_push_progress_57 .y_push_progress_25:nth-child(3)::after{
        position: absolute;
        width: 100%;
        height: 100%;
        content: '';
        background: rgba(0,0,0,0.7);
        top: 0rem;
        left: 0rem;
    }
    .y_push_progress_58{
        background: #fff;
        border-radius: 0.1rem;
        box-shadow:0 0 0.375rem 0.0625rem #ccc;
        margin-bottom: 0.3rem;
    }
    .y_push_progress_59{
        height: 0.9rem;
        border-bottom: 0.01rem solid #eee;
        padding: 0rem 0.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_push_progress_60{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_progress_60 h2{
        font-size: 0.24rem;
        color:#999;
        margin-right: 0.2rem;
    }
    .y_push_progress_60 p{
        font-size: 0.28rem;
        color: #333;
    }
    .y_push_progress_60 p span{
        color:#FECE0A;
        font-size: 0.28rem;
    }
    .y_push_progress_61{
        display: inline-block;
        padding: 0rem 0.3rem;
        height: 0.46rem;
        background: #FECE0A;
        border-radius: 0.1rem;
    }
    .y_push_progress_61 p{
        color: #333;
        font-size: 0.26rem;
        line-height: 0.46rem;
    }
    .y_push_progress_62{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1.3rem;
    }
    .y_push_progress_63{
        height: 100%;
        flex: 1;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
    }
    .y_push_progress_63 h2{
        width: 100%;
        text-align: center;
        font-size: 0.24rem;
        color: #999;
    }
    .y_push_progress_63 p{
        width: 100%;
        margin-top: 0.15rem;
        text-align: center;
        color:#FECE0A;
        font-size: 0.28rem;
    }
    .y_push_progress_64{
        padding: 0.25rem 0.3rem 0.35rem 0.4rem;
        background: #fff;
        border-radius: 0.1rem;
        box-shadow:0 0 0.375rem 0.0625rem #ccc;
        margin-bottom: 0.3rem;
    }
    .y_push_progress_65{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0.46rem;
        margin-bottom: 0.1rem;
    }
    .y_push_progress_66{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_progress_66 h2{
        color: #999;
        font-size: 0.24rem;
        margin-right: 0.2rem;
    }
    .y_push_progress_66 p{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_progress_66 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_progress_67{
        padding: 0rem 0.3rem;
        background: #FECE0A;
        border-radius: 0.1rem;
        display: inline-block;
        height: 0.46rem;
    }
    .y_push_progress_67 p{
        line-height: 0.46rem;
        font-size: 0.26rem;
        color: #333;
        height: 0.46rem;
    }
    .y_push_progress_68{
        background: #fff;
        border-radius: 0.1rem;
        box-shadow:0 0 0.375rem 0.0625rem #ccc;
        padding: 0rem 0.2rem 0.2rem;
    }
    .y_push_progress_69{
        width: 100%;
        height: 0.8rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .y_push_progress_69 p{
        display: inline-flex;
        color: #FECE0A;
        font-size: 0.22rem;
        line-height: 0.38rem;
        height: 0.38rem;
        padding: 0rem 0.2rem;
        border-radius: 1rem;
        border: 0.01px solid #FECE0A;
    }
    .y_push_progress_70{
        width: 100%;
        background: #FECE0A;
        height: 1.76rem;
    }
    .y_push_progress_71{
        width: 100%;
        background: #fff;
        border-radius: 0.1rem;
        height: 1.3rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-shadow:0 0 0.375rem 0.0625rem #ccc;
        margin-bottom: 0.3rem;
    }
    .y_push_progress_72{
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
    }
    .y_push_progress_72 h2{
        width: 100%;
        text-align: center;
        font-size: 0.24rem;
        color:#999;
    }
    .y_push_progress_72 p{
        width: 100%;
        text-align: center;
        margin-top: 0.15rem;
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_progress_73{
        box-shadow:0 0 0.375rem 0.0625rem #ccc;
        margin-bottom: 0.3rem;
        width: 100%;
        background: #fff;
        border-radius: 0.1rem;
    }
    .y_push_progress_74{
        padding: 0.1rem 0.1rem 0.2rem 0.1rem;
    }
    .y_push_progress_75{
        padding:0.1rem 0.3rem 0.1rem;
    }
    .y_push_progress_76{
        width: 100%;
        height: 0.8rem;
        border-top: 0.01rem solid #EEEEEE;
        margin-top: 0rem;
    }
    .y_push_progress_76 h2{
        color: #333;
        font-size: 0.36rem;
        line-height: 0.8rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
    .y_push_progress_77{
        width: 100%;
    }
    .y_push_progress_78{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_progress_78 p{
        color: #999;
        font-size: 0.24rem;
    }
    .y_push_progress_78 p span{
        color: #FECE0A;
        font-size: 0.24rem;
    }
    .y_push_progress_79{
        width: 100%;
        margin-top: 0.15rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_push_progress_80{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_progress_80 p{
        font-size: 0.3rem;
        color: #333;
    }
    .y_push_progress_80 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_progress_80 h2{
        color: #FECE0A;
        font-size: 0.24rem;
        margin-left: 0.2rem;
    }
    .y_push_progress_81{
        border-radius: 1rem;
        color: #FECE0A;
        border:0.01rem solid #FECE0A;
        line-height: 0.38rem;
        padding: 0rem 0.2rem;
        font-size: 0.22rem;
    }

    
    
    /* 图片 放大 */
    .y_push_progress_25 /deep/ .el-image img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .y_push_progress_25 /deep/ .el-image-viewer__actions{
        width: 90%;
        height: 1rem;
        bottom: 1rem;
    }
    .y_push_progress_25 /deep/ .el-image-viewer__close{
        width: 0.7rem;
        height: 0.7rem;
        top: 0.5rem;
        right: 0.5rem;
    }
    .y_push_progress_25 /deep/ .el-image-viewer__prev{
        width: 0.7rem;
        height: 0.7rem;
        left: 0.5rem;
    }
    .y_push_progress_25 /deep/ .el-image-viewer__next{
        width: 0.7rem;
        height: 0.7rem;
        right: 0.5rem;
    }
    .y_push_progress_74 /deep/ .my-swipe .van-swipe-item{
        height: 8rem;
    }
    .y_push_progress_74 /deep/ .van-swipe-item img{
        max-height: 7.3rem;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    .y_push_progress_74 /deep/ .van-swipe__indicators{
        transform: translate(-50%,0rem);
        bottom: 1rem;
    }
    .y_push_progress_74 /deep/ .van-swipe__indicator {
        width: 0.15rem;
        height: 0.15rem;
        background: #000;
        margin-right: 0.15rem;
    }

    /* 待推送 */
    .y_to_be_pushed_1{
        padding: 0.2rem 0.2rem 0rem 0.2rem;
        background: #fff;
        border-radius: 0.1rem;
        overflow: hidden;
        margin-bottom: 0.3rem;
    }
    .y_to_be_pushed_2{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .y_to_be_pushed_2 img{
        width: 100%;
    }
    .y_to_be_pushed_2 p{
        width: 100%;
        background: #FECE0A;
        /* height: 3rem; */
    }
    .y_to_be_pushed_2_banner p{
        /* width: 100%;
        background: #FECE0A; */
        height: 3rem;
    }
    .y_to_be_pushed_2_streamer P{
        height: 1.7rem;
        /* width: 100%;
        background: #FECE0A; */
    }
    .y_to_be_pushed_2_open_screen p{
        font-size: 0.3rem;
        color: #333;
    }
    .y_to_be_pushed_3{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 1rem;
    }
    .y_to_be_pushed_4{
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 1.1rem;
        height: 0.4rem;
        border: 0.01rem solid #FECE0A;
        border-radius: 1rem;
        margin-left: 0.1rem;
    }
    .y_to_be_pushed_4 img{
        width: 0.21rem;
        height: 0.23rem;
        margin-right: 0.08rem;
    }
    .y_to_be_pushed_4 p{
        font-size: 0.22rem;
        color: #FECE0A;
        line-height: 0.4rem;
    }
    .y_to_be_pushed_5{
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        height: 0.6rem;
    }
    .y_to_be_pushed_6{
        display: flex;
        width: 100%;
        justify-content: flex-end;
        align-items: center;
    }
    .y_to_be_pushed_7{
        display: flex;
        /* width: 1.1rem; */
        margin-left: 0.1rem;
        border: 0.01rem solid #FECE0A;
        border-radius: 1rem;
        height: 0.4rem;
        justify-content: center;
        align-items: center;
        padding: 0rem 0.15rem;
    }
    .y_to_be_pushed_7 img{
        width: 0.21rem;
        object-fit: cover;
        margin-right: 0.08rem;
    }
    .y_to_be_pushed_7 p{
        font-size: 0.22rem;
        color: #FECE0A;
    }
    .y_to_be_pushed_8{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.1rem;
    }
    .y_to_be_pushed_8 .y_push_progress_45{
        margin-top: 0rem;
    }
    .y_to_be_pushed_9{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_to_be_pushed_9>h2{
        font-size: 0.28rem;
        color: #333;
    }
    .y_to_be_pushed_9 .y_to_be_pushed_6{
        flex: 1;
    }

    .y_push_progress_57_zp .y_push_progress_25:nth-child(n+4){
        display: none;
    }

    .y_message_pushed_1{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .y_message_pushed_1>img{
        display: none;
    }
    /* .y_message_pushed_1 .y_to_be_pushed_7{
        width: 1.5rem;
    } */

    .y_push_progress_24 .y_push_progress_25:nth-child(9)::after{
        display: none;
    }
    .y_push_progress_57 .y_push_progress_25:nth-child(3)::after{
        display: none; 
    }
</style>
 
<script>
    import kefu from '../../components/kefu/kefu'
    import bottom from '../../components/bottom/bottom'

    import axios from 'axios'   // 接口
    import {recruit_common_list} from '../../api/request.js'   // 招聘列表,普通推广
    import {blog_common_list} from '../../api/request.js'   // 帖子列表,普通推广
    import {adspace_list} from '../../api/request.js'   // 广告位列表，高级推广
    import {award_list} from '../../api/request.js'   // 抽奖列表
    import {recruit_blog_info_list} from '../../api/request.js'   // 招聘和帖子列表,信息类推广

    import {submit_check_recruit_common} from '../../api/request.js'   // 招聘提交审核
    import {submit_check_blog_common} from '../../api/request.js'   // 帖子提交审核
    import {submit_check_adspace} from '../../api/request.js'   // 广告位提交审核
    import {submit_check_award} from '../../api/request.js'   // 抽奖提交审核
    import {submit_check_blog_info} from '../../api/request.js'   // 信息类 帖子提交审核
    import {submit_check_recruit_info} from '../../api/request.js'   // 信息类 招聘提交审核
    import {user_info} from '../../api/request.js'   // 用户信息

    import {adspace_delete} from '../../api/request.js'   // 高级推广 删除
    import {blog_delete} from '../../api/request.js'   // 普通帖子 删除
    import {recruit_delete} from '../../api/request.js'   // 普通招聘 删除
    import {recruit_delete_info} from '../../api/request.js'   // 信息类招聘 删除
    import {blog_delete_info} from '../../api/request.js'   // 信息类帖子 删除
    import {award_delete} from '../../api/request.js'   // 抽奖 删除
    
    import { ref } from 'vue';
    import { Dialog } from "vant"
    
    export default {
        data(){
            return {
                unread_one_val: '0',
                unread_two_val: '0',
                unread_three_val: '0',
                unread_four_val: '0',
                unread_five_val: '0',

                url: 'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
                srcList: [
                  'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
                  'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
                ],
                banner_img:[
                    {img:'../../index/images/icon_68.png'},
                    {img:'../images/icon_68.png'}
                ],
                top_title:[
                    {is:'1',title:'全部',index:'0'},
                    {is:'0',title:'招聘',index:'1'},
                    {is:'0',title:'推广',index:'2'},
                    {is:'0',title:'招生',index:'3'},
                    {is:'0',title:'高级推送',index:'4'},
                    {is:'0',title:'话题推送',index:'5'},
                    {is:'0',title:'小组推送',index:'6'},
                    {is:'0',title:'活动推送',index:'7'},
                    {is:'0',title:'发起抽奖',index:'8'},
                ],
                top_id:'0',
                token:'',

                welfare_list:'',   // 福利列表
                recruitment_list:'',  // 招聘列表
                recruitment_user:'', // 招聘用户

                promotion_list:'', // 推广帖子 列表
                admissions_list:'', // 招生帖子 列表
                advanced_list:'',  // 广告位列表，高级推广
                award_list:'',     // 抽奖列表
                recruit_topic_list:'',     // 话题列表
                panel_topic_list:'',       // 小组列表
                activity_topic_list:'',    // 活动列表

                business_not_read_chat_number:'', // 私信未读数量
                business_not_read_comment_number:'', // 评论未读数量

                page:'1',   // 当前页数
                pages:'',  // 总页数
                count:'', // 总个数
                currentPage:'',
            }
        },  
        components:{
            kefu,
            bottom,
        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            // 私信 评论未读
            user_info({token:token}).then((res)=>{
                console.log(res,'用户信息')
                if(res.error_code == '0'){
                    if(res.data.business_not_read_chat_number > 0){
                        this.unread_one_val = '1'
                        this.business_not_read_chat_number = res.data.business_not_read_chat_number
                    }
                    if(res.data.business_not_read_comment_number > 0){
                        this.unread_two_val = '1'
                        this.business_not_read_comment_number = res.data.business_not_read_comment_number
                    }
                    
                }
            })
            // 招聘列表,普通推广   // 待推送 招聘
            recruit_common_list({token:token,push_status_text:'not_start'}).then((res) =>{
                console.log(res,'招聘列表,普通推广')
                if(res.error_code == '0'){
                    let recruitment_list = res.data
                    for(let i=0;i<recruitment_list.length;i++){
                        recruitment_list[i].img_num = '0'
                        if(recruitment_list[i].gallery.length > 3){
                            recruitment_list[i].img_num = recruitment_list[i].gallery.length*1 - 3*1
                        }
                    }
                    this.recruitment_list = recruitment_list
                    this.recruitment_user = res.user
                }
            })
            // 帖子列表,普通推广  //待推送 推广
            blog_common_list({token:token,push_status_text:'not_start',price_type:'push'}).then((res) =>{
                console.log(res,'帖子列表,普通推广')
                if(res.error_code == '0'){
                    let promotion_list = res.data
                    let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                    for(let i=0;i<promotion_list.length;i++){
                        promotion_list[i].is = '0'
                        promotion_list[i].is_show = '0'
                        let mat = promotion_list[i].content.match(cnReg);
                        if(mat == null){
                            if((promotion_list[i].content.length)/2 > 138){
                                promotion_list[i].is_show = '1'
                            }
                        }else{
                            if(((promotion_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                promotion_list[i].is_show = '1'
                            }
                        }
                        if(promotion_list[i].gallery.length >9){
                            promotion_list[i].img_num = (promotion_list[i].gallery.length - 9)
                        }else{
                            promotion_list[i].img_num = '0'
                        }
                    }
                    this.promotion_list = promotion_list 
                }
                
                
            })
            // 帖子列表,普通招生  //待推送 招生
            blog_common_list({token:token,push_status_text:'not_start',price_type:'student'}).then((res) =>{
                console.log(res,'帖子列表,普通招生')
                if(res.error_code == '0'){
                    let admissions_list = res.data
                    let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                    for(let i=0;i<admissions_list.length;i++){
                        admissions_list[i].is = '0'
                        admissions_list[i].is_show = '0'
                        let mat = admissions_list[i].content.match(cnReg);
                        if(mat == null){
                            if((admissions_list[i].content.length)/2 > 138){
                                admissions_list[i].is_show = '1'
                            }
                        }else{
                            if(((admissions_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                admissions_list[i].is_show = '1'
                            }
                        }
                        if(admissions_list[i].gallery.length >9){
                            admissions_list[i].img_num = (admissions_list[i].gallery.length - 9)
                        }else{
                            admissions_list[i].img_num = '0'
                        }
                    }
                    this.admissions_list = admissions_list
                } 
            })
            // 广告位列表，高级推广 待推送
            adspace_list({token:token,push_status_text:'not_start'}).then((res) =>{
                console.log(res,'广告位列表，高级推广')
                let advanced_list = res.data
                this.advanced_list = advanced_list
            })
            // 抽奖列表 待推送
            award_list({token:token,push_status_text:'not_start'}).then((res) =>{
                console.log(res,'抽奖列表')
                let award_list = res.data
                this.award_list = award_list
            })
            // 招聘和帖子列表,信息类推广 话题列表 待推送
            recruit_blog_info_list({token:token,push_status_text:'not_start',push_type_text:'topic'}).then((res) =>{
                console.log(res,'话题列表')
                if(res.error_code == '0'){
                    let recruit_topic_list = res.data
                    let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                    for(let i=0;i<recruit_topic_list.length;i++){
                        recruit_topic_list[i].is = '0'
                        recruit_topic_list[i].is_show = '0'
                        let mat = recruit_topic_list[i].content.match(cnReg);
                        if(recruit_topic_list[i].from_table == 'recruit'){
                            recruit_topic_list[i].img_num = '0'
                            if(recruit_topic_list[i].gallery.length > 3){
                                recruit_topic_list[i].img_num = recruit_topic_list[i].gallery.length*1 - 3*1
                            }
                        }
                        if(recruit_topic_list[i].from_table == 'blog'){
                            if(mat == null){
                                if((recruit_topic_list[i].content.length)/2 > 138){
                                    recruit_topic_list[i].is_show = '1'
                                }
                            }else{
                                if(((recruit_topic_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                    recruit_topic_list[i].is_show = '1'
                                }
                            }
                            recruit_topic_list[i].img_num = '0'
                            if(recruit_topic_list[i].gallery.length > 9){
                                recruit_topic_list[i].img_num = recruit_topic_list[i].gallery.length*1 - 9*1
                            }
                        }
                    }
                this.recruit_topic_list = recruit_topic_list
                }
            })
            // 招聘和帖子列表,信息类推广 小组列表 待推送
            recruit_blog_info_list({token:token,push_status_text:'not_start',push_type_text:'group'}).then((res) =>{
                console.log(res,'小组列表')
                if(res.error_code == '0'){
                    let panel_topic_list = res.data
                    let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                    for(let i=0;i<panel_topic_list.length;i++){
                        panel_topic_list[i].is = '0'
                        panel_topic_list[i].is_show = '0'
                        let mat = panel_topic_list[i].content.match(cnReg);
                        if(panel_topic_list[i].from_table == 'recruit'){
                            panel_topic_list[i].img_num = '0'
                            if(panel_topic_list[i].gallery.length > 3){
                                panel_topic_list[i].img_num = panel_topic_list[i].gallery.length*1 - 3*1
                            }
                        }
                        if(panel_topic_list[i].from_table == 'blog'){
                            if(mat == null){
                                if((panel_topic_list[i].content.length)/2 > 138){
                                    panel_topic_list[i].is_show = '1'
                                }
                            }else{
                                if(((panel_topic_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                    panel_topic_list[i].is_show = '1'
                                }
                            }
                            panel_topic_list[i].img_num = '0'
                            if(panel_topic_list[i].gallery.length > 9){
                                panel_topic_list[i].img_num = panel_topic_list[i].gallery.length*1 - 9*1
                            }
                        }
                    }
                    this.panel_topic_list = panel_topic_list
                }
            })
            // 招聘和帖子列表,信息类推广 活动列表 待推送
            recruit_blog_info_list({token:token,push_status_text:'not_start',push_type_text:'activity'}).then((res) =>{
                console.log(res,'活动列表')
                if(res.error_code == '0'){
                    let activity_topic_list = res.data
                    let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                    for(let i=0;i<activity_topic_list.length;i++){
                        activity_topic_list[i].is = '0'
                        activity_topic_list[i].is_show = '0'
                        let mat = activity_topic_list[i].content.match(cnReg);
                        if(activity_topic_list[i].from_table == 'recruit'){
                            activity_topic_list[i].img_num = '0'
                            if(activity_topic_list[i].gallery.length > 3){
                                activity_topic_list[i].img_num = activity_topic_list[i].gallery.length*1 - 3*1
                            }
                        }
                        if(activity_topic_list[i].from_table == 'blog'){
                            if(mat == null){
                                if((activity_topic_list[i].content.length)/2 > 138){
                                    activity_topic_list[i].is_show = '1'
                                }
                            }else{
                                if(((activity_topic_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                    activity_topic_list[i].is_show = '1'
                                }
                            }
                            activity_topic_list[i].img_num = '0'
                            if(activity_topic_list[i].gallery.length > 9){
                                activity_topic_list[i].img_num = activity_topic_list[i].gallery.length*1 - 9*1
                            }
                        }
                    }
                    this.activity_topic_list = activity_topic_list
                }
            })
        },  
        methods: {
            push_date(){
                this.$router.push("/push_date_tg")
            },
            // 分页
            handleCurrentChange (currentPage) {
                console.log(currentPage,'currentPage');
                this.currentPage = currentPage;

                let token = this.token
                let top_id = this.top_id
                if(top_id == '1'){
                    recruit_common_list({token:token,push_status_text:'not_start',page:currentPage}).then((res) =>{
                        console.log(res)
                        if(res.error_code == '0'){
                            let recruitment_list = res.data
                            for(let i=0;i<recruitment_list.length;i++){
                                recruitment_list[i].img_num = '0'
                                if(recruitment_list[i].gallery.length > 3){
                                    recruitment_list[i].img_num = recruitment_list[i].gallery.length*1 - 3*1
                                }
                            }
                            this.recruitment_list = recruitment_list
                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else if(top_id == '2'){
                    blog_common_list({token:token,push_status_text:'not_start',price_type:'push',page:currentPage}).then((res) =>{
                        console.log(res,'帖子列表,普通推广')
                        if(res.error_code == '0'){
                            let promotion_list = res.data
                            let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                            for(let i=0;i<promotion_list.length;i++){
                                promotion_list[i].is = '0'
                                promotion_list[i].is_show = '0'
                                let mat = promotion_list[i].content.match(cnReg);
                                if(mat == null){
                                    if((promotion_list[i].content.length)/2 > 138){
                                        promotion_list[i].is_show = '1'
                                    }
                                }else{
                                    if(((promotion_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                        promotion_list[i].is_show = '1'
                                    }
                                }
                                if(promotion_list[i].gallery.length >9){
                                    promotion_list[i].img_num = (promotion_list[i].gallery.length - 9)
                                }else{
                                    promotion_list[i].img_num = '0'
                                }
                            }
                            this.promotion_list = promotion_list 

                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else if(top_id == '3'){
                    blog_common_list({token:token,push_status_text:'not_start',price_type:'student',page:currentPage}).then((res) =>{
                        console.log(res,'帖子列表,普通招生')
                        if(res.error_code == '0'){
                            let admissions_list = res.data
                            let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                            for(let i=0;i<admissions_list.length;i++){
                                admissions_list[i].is = '0'
                                admissions_list[i].is_show = '0'
                                let mat = admissions_list[i].content.match(cnReg);
                                if(mat == null){
                                    if((admissions_list[i].content.length)/2 > 138){
                                        admissions_list[i].is_show = '1'
                                    }
                                }else{
                                    if(((admissions_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                        admissions_list[i].is_show = '1'
                                    }
                                }
                                if(admissions_list[i].gallery.length >9){
                                    admissions_list[i].img_num = (admissions_list[i].gallery.length - 9)
                                }else{
                                    admissions_list[i].img_num = '0'
                                }
                            }
                            this.admissions_list = admissions_list
                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else if(top_id == '4'){
                    adspace_list({token:token,push_status_text:'not_start',page:currentPage}).then((res) =>{
                        console.log(res,'广告位列表，高级推广')
                        if(res.error_code == '0'){
                            let advanced_list = res.data
                            this.advanced_list = advanced_list

                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else if(top_id == '5'){
                    recruit_blog_info_list({token:token,push_status_text:'not_start',push_type_text:'topic',page:currentPage}).then((res) =>{
                        console.log(res,'话题列表')
                        if(res.error_code == '0'){
                            let recruit_topic_list = res.data
                            let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                            for(let i=0;i<recruit_topic_list.length;i++){
                                recruit_topic_list[i].is = '0'
                                recruit_topic_list[i].is_show = '0'
                                let mat = recruit_topic_list[i].content.match(cnReg);
                                if(recruit_topic_list[i].from_table == 'recruit'){
                                    recruit_topic_list[i].img_num = '0'
                                    if(recruit_topic_list[i].gallery.length > 3){
                                        recruit_topic_list[i].img_num = recruit_topic_list[i].gallery.length*1 - 3*1
                                    }
                                }
                                if(recruit_topic_list[i].from_table == 'blog'){
                                    if(mat == null){
                                        if((recruit_topic_list[i].content.length)/2 > 138){
                                            recruit_topic_list[i].is_show = '1'
                                        }
                                    }else{
                                        if(((recruit_topic_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                            recruit_topic_list[i].is_show = '1'
                                        }
                                    }
                                    recruit_topic_list[i].img_num = '0'
                                    if(recruit_topic_list[i].gallery.length > 9){
                                        recruit_topic_list[i].img_num = recruit_topic_list[i].gallery.length*1 - 9*1
                                    }
                                }
                            }
                            this.recruit_topic_list = recruit_topic_list
                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else if(top_id == '6'){
                    recruit_blog_info_list({token:token,push_status_text:'not_start',push_type_text:'group',page:currentPage}).then((res) =>{
                        console.log(res,'小组列表')
                        if(res.error_code == '0'){
                            let panel_topic_list = res.data
                            let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                            for(let i=0;i<panel_topic_list.length;i++){
                                panel_topic_list[i].is = '0'
                                panel_topic_list[i].is_show = '0'
                                let mat = panel_topic_list[i].content.match(cnReg);
                                if(panel_topic_list[i].from_table == 'recruit'){
                                    panel_topic_list[i].img_num = '0'
                                    if(panel_topic_list[i].gallery.length > 3){
                                        panel_topic_list[i].img_num = panel_topic_list[i].gallery.length*1 - 3*1
                                    }
                                }
                                if(panel_topic_list[i].from_table == 'blog'){
                                    if(mat == null){
                                        if((panel_topic_list[i].content.length)/2 > 138){
                                            panel_topic_list[i].is_show = '1'
                                        }
                                    }else{
                                        if(((panel_topic_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                            panel_topic_list[i].is_show = '1'
                                        }
                                    }
                                    panel_topic_list[i].img_num = '0'
                                    if(panel_topic_list[i].gallery.length > 9){
                                        panel_topic_list[i].img_num = panel_topic_list[i].gallery.length*1 - 9*1
                                    }
                                }
                            }
                            this.panel_topic_list = panel_topic_list
                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else if(top_id == '7'){
                    recruit_blog_info_list({token:token,push_status_text:'not_start',push_type_text:'activity',page:currentPage}).then((res) =>{
                        console.log(res,'活动列表')
                        if(res.error_code == '0'){
                            let activity_topic_list = res.data
                            let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                            for(let i=0;i<activity_topic_list.length;i++){
                                activity_topic_list[i].is = '0'
                                activity_topic_list[i].is_show = '0'
                                let mat = activity_topic_list[i].content.match(cnReg);
                                if(activity_topic_list[i].from_table == 'recruit'){
                                    activity_topic_list[i].img_num = '0'
                                    if(activity_topic_list[i].gallery.length > 3){
                                        activity_topic_list[i].img_num = activity_topic_list[i].gallery.length*1 - 3*1
                                    }
                                }
                                if(activity_topic_list[i].from_table == 'blog'){
                                    if(mat == null){
                                        if((activity_topic_list[i].content.length)/2 > 138){
                                            activity_topic_list[i].is_show = '1'
                                        }
                                    }else{
                                        if(((activity_topic_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                            activity_topic_list[i].is_show = '1'
                                        }
                                    }
                                    activity_topic_list[i].img_num = '0'
                                    if(activity_topic_list[i].gallery.length > 9){
                                        activity_topic_list[i].img_num = activity_topic_list[i].gallery.length*1 - 9*1
                                    }
                                }
                            }
                            this.activity_topic_list = activity_topic_list
                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else if(top_id == '8'){
                    award_list({token:token,push_status_text:'not_start',page:currentPage}).then((res) =>{
                        console.log(res,'抽奖列表')
                        if(res.error_code == '0'){
                            let award_list = res.data
                            this.award_list = award_list

                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }
            },
            // 顶部导航选择
            top_title_pop(index){
                console.log(index)
                let top_title = this.top_title
                for(let i=0;i<top_title.length;i++){
                    top_title[i].is = '0'
                    if(top_title[i].index == index){
                        top_title[i].is = '1'
                        this.top_id = i
                    }
                }
                this.top_title = top_title
                let token = this.token
                if(index == '1'){
                    recruit_common_list({token:token,push_status_text:'not_start',page:'1'}).then((res) =>{
                        console.log(res,'招聘列表,普通推广')
                        if(res.error_code == '0'){
                            let recruitment_list = res.data
                            for(let i=0;i<recruitment_list.length;i++){
                                recruitment_list[i].img_num = '0'
                                if(recruitment_list[i].gallery.length > 3){
                                    recruitment_list[i].img_num = recruitment_list[i].gallery.length*1 - 3*1
                                }
                            }
                            this.recruitment_list = recruitment_list
                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                            this.currentPage = 1
                        }else{
                            this.count = '0'
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else if(index == '2'){
                    blog_common_list({token:token,push_status_text:'not_start',price_type:'push',page:'1'}).then((res) =>{
                        console.log(res,'帖子列表,普通推广')
                        if(res.error_code == '0'){
                            let promotion_list = res.data
                            let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                            for(let i=0;i<promotion_list.length;i++){
                                promotion_list[i].is = '0'
                                promotion_list[i].is_show = '0'
                                let mat = promotion_list[i].content.match(cnReg);
                                if(mat == null){
                                    if((promotion_list[i].content.length)/2 > 138){
                                        promotion_list[i].is_show = '1'
                                    }
                                }else{
                                    if(((promotion_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                        promotion_list[i].is_show = '1'
                                    }
                                }
                                if(promotion_list[i].gallery.length >9){
                                    promotion_list[i].img_num = (promotion_list[i].gallery.length - 9)
                                }else{
                                    promotion_list[i].img_num = '0'
                                }
                            }
                            this.promotion_list = promotion_list 
                            console.log(this.promotion_list)
                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                            this.currentPage = 1
                        }else{
                            this.count = '0'
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else if(index == '3'){
                    blog_common_list({token:token,push_status_text:'not_start',price_type:'student',page:'1'}).then((res) =>{
                        console.log(res,'帖子列表,普通招生')
                        if(res.error_code == '0'){
                            let admissions_list = res.data
                            let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                            for(let i=0;i<admissions_list.length;i++){
                                admissions_list[i].is = '0'
                                admissions_list[i].is_show = '0'
                                let mat = admissions_list[i].content.match(cnReg);
                                if(mat == null){
                                    if((admissions_list[i].content.length)/2 > 138){
                                        admissions_list[i].is_show = '1'
                                    }
                                }else{
                                    if(((admissions_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                        admissions_list[i].is_show = '1'
                                    }
                                }
                                if(admissions_list[i].gallery.length >9){
                                    admissions_list[i].img_num = (admissions_list[i].gallery.length - 9)
                                }else{
                                    admissions_list[i].img_num = '0'
                                }
                            }
                            this.admissions_list = admissions_list
                            
                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                            this.currentPage = 1
                        }else{
                            this.count = '0'
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else if(index == '4'){
                    adspace_list({token:token,push_status_text:'not_start',page:'1'}).then((res) =>{
                        console.log(res,'广告位列表，高级推广')
                        if(res.error_code == '0'){
                            let advanced_list = res.data
                            this.advanced_list = advanced_list

                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                            this.currentPage = 1
                        }else{
                            this.count = '0'
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else if(index == '5'){
                    recruit_blog_info_list({token:token,push_status_text:'not_start',push_type_text:'topic',page:'1'}).then((res) =>{
                        console.log(res,'话题列表')
                        if(res.error_code == '0'){
                            let recruit_topic_list = res.data
                            let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                            for(let i=0;i<recruit_topic_list.length;i++){
                                recruit_topic_list[i].is = '0'
                                recruit_topic_list[i].is_show = '0'
                                let mat = recruit_topic_list[i].content.match(cnReg);
                                if(recruit_topic_list[i].from_table == 'recruit'){
                                    recruit_topic_list[i].img_num = '0'
                                    if(recruit_topic_list[i].gallery.length > 3){
                                        recruit_topic_list[i].img_num = recruit_topic_list[i].gallery.length*1 - 3*1
                                    }
                                }
                                if(recruit_topic_list[i].from_table == 'blog'){
                                    if(mat == null){
                                        if((recruit_topic_list[i].content.length)/2 > 138){
                                            recruit_topic_list[i].is_show = '1'
                                        }
                                    }else{
                                        if(((recruit_topic_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                            recruit_topic_list[i].is_show = '1'
                                        }
                                    }
                                    recruit_topic_list[i].img_num = '0'
                                    if(recruit_topic_list[i].gallery.length > 9){
                                        recruit_topic_list[i].img_num = recruit_topic_list[i].gallery.length*1 - 9*1
                                    }
                                }
                            }
                            this.recruit_topic_list = recruit_topic_list

                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                            this.currentPage = 1
                        }else{
                            this.count = '0'
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else if(index == '6'){
                    recruit_blog_info_list({token:token,push_status_text:'not_start',push_type_text:'group',page:'1'}).then((res) =>{
                        console.log(res,'小组列表')
                        if(res.error_code == '0'){
                            let panel_topic_list = res.data
                            let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                            for(let i=0;i<panel_topic_list.length;i++){
                                panel_topic_list[i].is = '0'
                                panel_topic_list[i].is_show = '0'
                                let mat = panel_topic_list[i].content.match(cnReg);
                                if(panel_topic_list[i].from_table == 'recruit'){
                                    panel_topic_list[i].img_num = '0'
                                    if(panel_topic_list[i].gallery.length > 3){
                                        panel_topic_list[i].img_num = panel_topic_list[i].gallery.length*1 - 3*1
                                    }
                                }
                                if(panel_topic_list[i].from_table == 'blog'){
                                    if(mat == null){
                                        if((panel_topic_list[i].content.length)/2 > 138){
                                            panel_topic_list[i].is_show = '1'
                                        }
                                    }else{
                                        if(((panel_topic_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                            panel_topic_list[i].is_show = '1'
                                        }
                                    }
                                    panel_topic_list[i].img_num = '0'
                                    if(panel_topic_list[i].gallery.length > 9){
                                        panel_topic_list[i].img_num = panel_topic_list[i].gallery.length*1 - 9*1
                                    }
                                }
                            }
                            this.panel_topic_list = panel_topic_list
                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                            this.currentPage = 1
                        }else{
                            this.count = '0'
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else if(index == '7'){
                    recruit_blog_info_list({token:token,push_status_text:'not_start',push_type_text:'activity',page:'1'}).then((res) =>{
                        console.log(res,'活动列表')
                        if(res.error_code == '0'){
                            let activity_topic_list = res.data
                            let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                            for(let i=0;i<activity_topic_list.length;i++){
                                activity_topic_list[i].is = '0'
                                activity_topic_list[i].is_show = '0'
                                let mat = activity_topic_list[i].content.match(cnReg);
                                if(activity_topic_list[i].from_table == 'recruit'){
                                    activity_topic_list[i].img_num = '0'
                                    if(activity_topic_list[i].gallery.length > 3){
                                        activity_topic_list[i].img_num = activity_topic_list[i].gallery.length*1 - 3*1
                                    }
                                }
                                if(activity_topic_list[i].from_table == 'blog'){
                                    if(mat == null){
                                        if((activity_topic_list[i].content.length)/2 > 138){
                                            activity_topic_list[i].is_show = '1'
                                        }
                                    }else{
                                        if(((activity_topic_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                            activity_topic_list[i].is_show = '1'
                                        }
                                    }
                                    activity_topic_list[i].img_num = '0'
                                    if(activity_topic_list[i].gallery.length > 9){
                                        activity_topic_list[i].img_num = activity_topic_list[i].gallery.length*1 - 9*1
                                    }
                                }
                            }
                            this.activity_topic_list = activity_topic_list
                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                            this.currentPage = 1
                        }else{
                            this.count = '0'
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else if(index == '8'){
                    award_list({token:token,push_status_text:'not_start',page:'1'}).then((res) =>{
                        console.log(res,'抽奖列表')
                        if(res.error_code == '0'){
                            let award_list = res.data
                            this.award_list = award_list

                            this.page = res.page     // 当前页数
                            this.pages = res.pages   // 总页数
                            this.count = res.count*1   // 总个数
                            this.currentPage = 1
                        }else{
                            this.count = '0'
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }
     
            },
            // 招聘编辑 
            editor_page(id){
                console.log(id)
                this.$router.push({
                    path:'/recruit_index',
                    query: {
                        recruit_id:id,
                        recruitment_value:'1',
                    }
                })
            },
            // 推广 展开隐藏
            promotion_toggle(id){
                let top_id = this.top_id
                console.log(top_id)
                if(top_id == 2){
                    let promotion_list = this.promotion_list
                    for(let i=0;i<promotion_list.length;i++){
                        if(promotion_list[i].blog_id == id){
                            if(promotion_list[i].is == '0'){
                                promotion_list[i].is = '1'
                            }else{ 
                                promotion_list[i].is = '0'
                            }
                        
                        }
                    }
                    this.promotion_list = promotion_list
                }
                if(top_id == 3){
                    let admissions_list = this.admissions_list
                    for(let i=0;i<admissions_list.length;i++){
                        if(admissions_list[i].blog_id == id){
                            if(admissions_list[i].is == '0'){
                                admissions_list[i].is = '1'
                            }else{
                                admissions_list[i].is = '0'
                            }
                        
                        }
                    }
                    this.admissions_list = admissions_list
                }
                if(top_id == 5){
                    let recruit_topic_list = this.recruit_topic_list
                    for(let i=0;i<recruit_topic_list.length;i++){
                        if(recruit_topic_list[i].id == id){
                            if(recruit_topic_list[i].is == '0'){
                                recruit_topic_list[i].is = '1'
                            }else{
                                recruit_topic_list[i].is = '0'
                            }
                        
                        }
                    }
                    this.recruit_topic_list = recruit_topic_list
                }
                if(top_id == 6){
                    let panel_topic_list = this.panel_topic_list
                    for(let i=0;i<panel_topic_list.length;i++){
                        if(panel_topic_list[i].id == id){
                            if(panel_topic_list[i].is == '0'){
                                panel_topic_list[i].is = '1'
                            }else{
                                panel_topic_list[i].is = '0'
                            }
                        
                        }
                    }
                    this.panel_topic_list = panel_topic_list
                }
                if(top_id == 7){
                    let activity_topic_list = this.activity_topic_list
                    for(let i=0;i<activity_topic_list.length;i++){
                        if(activity_topic_list[i].id == id){
                            if(activity_topic_list[i].is == '0'){
                                activity_topic_list[i].is = '1'
                            }else{
                                activity_topic_list[i].is = '0'
                            }
                        
                        }
                    }
                    this.activity_topic_list = activity_topic_list
                }
            },
            // 推广
            promotion_page_tg(id){
                console.log(id)
                this.$router.push({
                    path:'/promotion',
                    query: {
                        name:'push',
                        index_index:'1',
                        blog_id:id,
                        promotion_value:'1',
                    }
                })
            },
            // 招生
            promotion_page_zs(id){
                console.log(id)
                this.$router.push({
                    path:'/promotion',
                    query: {
                        name:'student',
                        index_index:'2',
                        blog_id:id,
                        promotion_value:'1',
                    }
                })
            },
            // 广告位列表，高级推广
            advanced_date(id){
                console.log(id)
                this.$router.push({
                    path:'/advanced_push',
                    query: {
                        adspace_id:id,
                        promotion_value:'1',
                    }
                })
            },
            // 抽奖列表
            award_page(id){
                this.$router.push({
                    path:'/sweepstakes_settings',
                    query: {
                        adspace_id:id,
                        promotion_value:'1',
                    }
                })
            },
            // 话题推广 详情
            recruit_topic_list_pop(id,info_ids,from_table){
                console.log(id,info_ids)
                this.$router.push({
                    path:'/topics_push',
                    query: {
                        adspace_id:id,       // 选中id
                        info_ids:info_ids,   // 话题id多个
                        promotion_value:'1',  // 编辑传1
                        type_name:'topic',   // 推送类型
                        index_index:'1',  // 话题传1
                        from_table:from_table  // 传帖子还是招聘
                    }
                })
            },
            // 小组推广 详情
            panel_topic_list_pop(id,info_ids,from_table){
                console.log(id,info_ids) 
                this.$router.push({
                    path:'/topics_push',
                    query: {
                        adspace_id:id,       // 选中id
                        info_ids:info_ids,   // 话题id多个
                        promotion_value:'1',  // 编辑传1
                        type_name:'group',   // 推送类型
                        index_index:'2',  // 话题传1
                        from_table:from_table  // 传帖子还是招聘
                    }
                })
            },
            // 活动推广 详情
            activity_topic_list_pop(id,info_ids,from_table){
                console.log(id,info_ids)
                this.$router.push({
                    path:'/topics_push',
                    query: {
                        adspace_id:id,       // 选中id
                        info_ids:info_ids,   // 话题id多个
                        promotion_value:'1',  // 编辑传1
                        type_name:'activity',   // 推送类型
                        index_index:'3',  // 话题传1
                        from_table:from_table  // 传帖子还是招聘
                    }
                })
            },

            // 招聘提交 提交审核
            submit_review_recruitment(id){
                console.log(id)
                let token = this.token
                submit_check_recruit_common({token:token,recruit_id:id}).then((res) =>{
                    if(res.error_code == '0'){
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            },
            // 推广/招生 提交 提交审核
            submit_review_tg(id){
                console.log(id)
                let token = this.token
                submit_check_blog_common({token:token,blog_id:id}).then((res) =>{
                    if(res.error_code == '0'){
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            },
            // 高级推广提交 提交审核
            submit_review_gjtg(id){
                console.log(id)
                let token = this.token
                submit_check_adspace({token:token,adspace_id:id}).then((res) =>{
                    if(res.error_code == '0'){
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            },
            // 抽奖提交 抽奖提交审核
            submit_luck_draw(id){
                console.log(id)
                let token = this.token
                submit_check_award({token:token,blog_id:id}).then((res) =>{
                    if(res.error_code == '0'){
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            },
            // 话题推送
            information_topics_pop(id,category){
                console.log(id)
                let token = this.token
                console.log(category)
                // 帖子类
                if(category == 'blog'){
                    submit_check_blog_info({token:token,blog_id:id}).then((res) =>{
                        if(res.error_code == '0'){
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                // 招聘类
                }else if(category == 'recruit'){
                    submit_check_recruit_info({token:token,recruit_id:id}).then((res) =>{
                        if(res.error_code == '0'){
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }
            },
            // 小组推送
            information_panel_pop(id,category){
                console.log(id)
                let token = this.token
                console.log(category)
                // 帖子类
                if(category == 'blog'){
                    submit_check_blog_info({token:token,blog_id:id}).then((res) =>{
                        if(res.error_code == '0'){
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                // 招聘类
                }else if(category == 'recruit'){
                    submit_check_recruit_info({token:token,recruit_id:id}).then((res) =>{
                        if(res.error_code == '0'){
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }
            },
            // 活动推送
            information_events_pop(id,category){
                console.log(id)
                let token = this.token
                console.log(category)
                // 帖子类
                if(category == 'blog'){
                    submit_check_blog_info({token:token,blog_id:id}).then((res) =>{
                        if(res.error_code == '0'){
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                // 招聘类
                }else if(category == 'recruit'){
                    submit_check_recruit_info({token:token,recruit_id:id}).then((res) =>{
                        if(res.error_code == '0'){
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }
            },


            // 高级推广 删除
            advanced_dele(id){
                let token = this.token
                Dialog.confirm({
                    title: '',
                    message:'确定删除吗？',
                })
                .then(() => {
                    adspace_delete({token:token,adspace_id:id}).then((res_one)=>{
                        if(res_one.error_code == '0'){
                            this.$message({
                                message: res_one.msg,
                                duration:'2000'
                            })
                            adspace_list({token:token,push_status_text:'not_start',page:'1'}).then((res) =>{
                                console.log(res,'广告位列表，高级推广')
                                if(res.error_code == '0'){
                                    let advanced_list = res.data
                                    this.advanced_list = advanced_list
                                    this.page = res.page     // 当前页数
                                    this.pages = res.pages   // 总页数
                                    this.count = res.count*1   // 总个数
                                    this.currentPage = 1
                                }else{
                                    this.count = '0'
                                    this.$message({
                                        message: res.msg,
                                        duration:'2000'
                                    })
                                }
                            })
                        }else{
                            this.$message({
                                message: res_one.msg,
                                duration:'2000'
                            })
                        }
                    })

                })
                .catch(() => {
                    console.log('取消')
                })
            },
            // 推广 删除
            promotion_tg_dele(id){
                let token = this.token
                Dialog.confirm({
                    title: '',
                    message:'确定删除吗？',
                })
                .then(() => {
                    blog_delete({token:token,blog_id:id}).then((res_one)=>{
                        if(res_one.error_code == '0'){
                            this.$message({
                                message: res_one.msg,
                                duration:'2000'
                            })
                            blog_common_list({token:token,push_status_text:'not_start',price_type:'push',page:'1'}).then((res) =>{
                                console.log(res,'帖子列表,普通推广')
                                if(res.error_code == '0'){
                                    let promotion_list = res.data
                                    let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                                    for(let i=0;i<promotion_list.length;i++){
                                        promotion_list[i].is = '0'
                                        promotion_list[i].is_show = '0'
                                        let mat = promotion_list[i].content.match(cnReg);
                                        if(mat == null){
                                            if((promotion_list[i].content.length)/2 > 138){
                                                promotion_list[i].is_show = '1'
                                            }
                                        }else{
                                            if(((promotion_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                                promotion_list[i].is_show = '1'
                                            }
                                        }
                                        if(promotion_list[i].gallery.length >9){
                                            promotion_list[i].img_num = (promotion_list[i].gallery.length - 9)
                                        }else{
                                            promotion_list[i].img_num = '0'
                                        }
                                    }
                                    this.promotion_list = promotion_list 
                                    console.log(this.promotion_list)
                                    this.page = res.page     // 当前页数
                                    this.pages = res.pages   // 总页数
                                    this.count = res.count*1   // 总个数
                                    this.currentPage = 1
                                }else{
                                    this.count = '0'
                                    this.$message({
                                        message: res.msg,
                                        duration:'2000'
                                    })
                                }
                            })
                        }else{
                            this.$message({
                                message: res_one.msg,
                                duration:'2000'
                            })
                        }
                    })

                })
                .catch(() => {
                    console.log('取消')
                })
            },
            // 招生 删除
            promotion_zs_dele(id){
                let token = this.token
                Dialog.confirm({
                    title: '',
                    message:'确定删除吗？',
                })
                .then(() => {
                    blog_delete({token:token,blog_id:id}).then((res_one)=>{
                        if(res_one.error_code == '0'){
                            this.$message({
                                message: res_one.msg,
                                duration:'2000'
                            })
                            blog_common_list({token:token,push_status_text:'not_start',price_type:'student',page:'1'}).then((res) =>{
                                console.log(res,'帖子列表,普通招生')
                                if(res.error_code == '0'){
                                    let admissions_list = res.data
                                    let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                                    for(let i=0;i<admissions_list.length;i++){
                                        admissions_list[i].is = '0'
                                        admissions_list[i].is_show = '0'
                                        let mat = admissions_list[i].content.match(cnReg);
                                        if(mat == null){
                                            if((admissions_list[i].content.length)/2 > 138){
                                                admissions_list[i].is_show = '1'
                                            }
                                        }else{
                                            if(((admissions_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                                admissions_list[i].is_show = '1'
                                            }
                                        }
                                        if(admissions_list[i].gallery.length >9){
                                            admissions_list[i].img_num = (admissions_list[i].gallery.length - 9)
                                        }else{
                                            admissions_list[i].img_num = '0'
                                        }
                                    }
                                    this.admissions_list = admissions_list

                                    this.page = res.page     // 当前页数
                                    this.pages = res.pages   // 总页数
                                    this.count = res.count*1   // 总个数
                                    this.currentPage = 1
                                }else{
                                    this.count = '0'
                                    this.$message({
                                        message: res.msg,
                                        duration:'2000'
                                    })
                                }
                            })
                        }else{
                            this.$message({
                                message: res_one.msg,
                                duration:'2000'
                            })
                        }
                    })

                })
                .catch(() => {
                    console.log('取消')
                })
            },
            // 招聘 删除
            editor_dele(id){
                let token = this.token
                Dialog.confirm({
                    title: '',
                    message:'确定删除吗？',
                })
                .then(() => {
                    recruit_delete({token:token,recruit_id:id}).then((res_one)=>{
                        if(res_one.error_code == '0'){
                            this.$message({
                                message: res_one.msg,
                                duration:'2000'
                            })
                            recruit_common_list({token:token,push_status_text:'not_start',page:'1'}).then((res) =>{
                                console.log(res,'招聘列表,普通推广')
                                if(res.error_code == '0'){
                                    let recruitment_list = res.data
                                    for(let i=0;i<recruitment_list.length;i++){
                                        recruitment_list[i].img_num = '0'
                                        if(recruitment_list[i].gallery.length > 3){
                                            recruitment_list[i].img_num = recruitment_list[i].gallery.length*1 - 3*1
                                        }
                                    }
                                    this.recruitment_list = recruitment_list
                                    this.page = res.page     // 当前页数
                                    this.pages = res.pages   // 总页数
                                    this.count = res.count*1   // 总个数
                                    this.currentPage = 1
                                }else{
                                    this.count = '0'
                                    this.$message({
                                        message: res.msg,
                                        duration:'2000'
                                    })
                                }
                            })
                        }else{
                            this.$message({
                                message: res_one.msg,
                                duration:'2000'
                            })
                        }
                    })
                })
                .catch(() => {
                    console.log('取消')
                })

            },
            // 话题推送 招聘/帖子 删除
            recruit_topic_dele(id,from_table){
                let token = this.token
                Dialog.confirm({
                    title: '',
                    message:'确定删除吗？',
                })
                .then(() => {
                    if(from_table == 'recruit'){
                        recruit_delete_info({token:token,recruit_id:id}).then((res_one)=>{
                            if(res_one.error_code == '0'){
                                this.$message({
                                    message: res_one.msg,
                                    duration:'2000'
                                })
                                recruit_blog_info_list({token:token,push_status_text:'not_start',push_type_text:'topic',page:'1'}).then((res) =>{
                                    console.log(res,'话题列表')
                                    if(res.error_code == '0'){
                                        let recruit_topic_list = res.data
                                        let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                                        for(let i=0;i<recruit_topic_list.length;i++){
                                            recruit_topic_list[i].is = '0'
                                            recruit_topic_list[i].is_show = '0'
                                            let mat = recruit_topic_list[i].content.match(cnReg);
                                            if(recruit_topic_list[i].from_table == 'recruit'){
                                                recruit_topic_list[i].img_num = '0'
                                                if(recruit_topic_list[i].gallery.length > 3){
                                                    recruit_topic_list[i].img_num = recruit_topic_list[i].gallery.length*1 - 3*1
                                                }
                                            }
                                            if(recruit_topic_list[i].from_table == 'blog'){
                                                if(mat == null){
                                                    if((recruit_topic_list[i].content.length)/2 > 138){
                                                        recruit_topic_list[i].is_show = '1'
                                                    }
                                                }else{
                                                    if(((recruit_topic_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                                        recruit_topic_list[i].is_show = '1'
                                                    }
                                                }
                                                recruit_topic_list[i].img_num = '0'
                                                if(recruit_topic_list[i].gallery.length > 9){
                                                    recruit_topic_list[i].img_num = recruit_topic_list[i].gallery.length*1 - 9*1
                                                }
                                            }
                                        }
                                        this.recruit_topic_list = recruit_topic_list

                                        this.page = res.page     // 当前页数
                                        this.pages = res.pages   // 总页数
                                        this.count = res.count*1   // 总个数
                                        this.currentPage = 1
                                    }else{
                                        this.count = '0'
                                        this.$message({
                                            message: res.msg,
                                            duration:'2000'
                                        })
                                    }
                                })
                            }else{
                                this.$message({
                                    message: res_one.msg,
                                    duration:'2000'
                                })
                            }
                        })
                    }else if(from_table == 'blog'){
                        blog_delete_info({token:token,blog_id:id}).then((res_one)=>{
                            if(res_one.error_code == '0'){
                                this.$message({
                                    message: res_one.msg,
                                    duration:'2000'
                                })
                                recruit_blog_info_list({token:token,push_status_text:'not_start',push_type_text:'topic',page:'1'}).then((res) =>{
                                    console.log(res,'话题列表')
                                    if(res.error_code == '0'){
                                        let recruit_topic_list = res.data
                                        let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                                        for(let i=0;i<recruit_topic_list.length;i++){
                                            recruit_topic_list[i].is = '0'
                                            recruit_topic_list[i].is_show = '0'
                                            let mat = recruit_topic_list[i].content.match(cnReg);
                                            if(recruit_topic_list[i].from_table == 'recruit'){
                                                recruit_topic_list[i].img_num = '0'
                                                if(recruit_topic_list[i].gallery.length > 3){
                                                    recruit_topic_list[i].img_num = recruit_topic_list[i].gallery.length*1 - 3*1
                                                }
                                            }
                                            if(recruit_topic_list[i].from_table == 'blog'){
                                                if(mat == null){
                                                    if((recruit_topic_list[i].content.length)/2 > 138){
                                                        recruit_topic_list[i].is_show = '1'
                                                    }
                                                }else{
                                                    if(((recruit_topic_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                                        recruit_topic_list[i].is_show = '1'
                                                    }
                                                }
                                                recruit_topic_list[i].img_num = '0'
                                                if(recruit_topic_list[i].gallery.length > 9){
                                                    recruit_topic_list[i].img_num = recruit_topic_list[i].gallery.length*1 - 9*1
                                                }
                                            }
                                        }
                                        this.recruit_topic_list = recruit_topic_list

                                        this.page = res.page     // 当前页数
                                        this.pages = res.pages   // 总页数
                                        this.count = res.count*1   // 总个数
                                        this.currentPage = 1
                                    }else{
                                        this.count = '0'
                                        this.$message({
                                            message: res.msg,
                                            duration:'2000'
                                        })
                                    }
                                })
                            }else{
                                this.$message({
                                    message: res_one.msg,
                                    duration:'2000'
                                })
                            }
                        })
                    }
                })
                .catch(() => {
                    console.log('取消')
                })
            },
            // 小组推送 招聘/帖子 删除
            panel_topic_dele(id,from_table){
                let token = this.token
                Dialog.confirm({
                    title: '',
                    message:'确定删除吗？',
                })
                .then(() => {
                    if(from_table == 'recruit'){
                        recruit_delete_info({token:token,recruit_id:id}).then((res_one)=>{
                            if(res_one.error_code == '0'){
                                this.$message({
                                    message: res_one.msg,
                                    duration:'2000'
                                })
                                recruit_blog_info_list({token:token,push_status_text:'not_start',push_type_text:'group',page:'1'}).then((res) =>{
                                    console.log(res,'小组列表')
                                    if(res.error_code == '0'){
                                        let panel_topic_list = res.data
                                        let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                                        for(let i=0;i<panel_topic_list.length;i++){
                                            panel_topic_list[i].is = '0'
                                            panel_topic_list[i].is_show = '0'
                                            let mat = panel_topic_list[i].content.match(cnReg);
                                            if(panel_topic_list[i].from_table == 'recruit'){
                                                panel_topic_list[i].img_num = '0'
                                                if(panel_topic_list[i].gallery.length > 3){
                                                    panel_topic_list[i].img_num = panel_topic_list[i].gallery.length*1 - 3*1
                                                }
                                            }
                                            if(panel_topic_list[i].from_table == 'blog'){
                                                if(mat == null){
                                                    if((panel_topic_list[i].content.length)/2 > 138){
                                                        panel_topic_list[i].is_show = '1'
                                                    }
                                                }else{
                                                    if(((panel_topic_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                                        panel_topic_list[i].is_show = '1'
                                                    }
                                                }
                                                panel_topic_list[i].img_num = '0'
                                                if(panel_topic_list[i].gallery.length > 9){
                                                    panel_topic_list[i].img_num = panel_topic_list[i].gallery.length*1 - 9*1
                                                }
                                            }
                                        }
                                        this.panel_topic_list = panel_topic_list
                                        this.page = res.page     // 当前页数
                                        this.pages = res.pages   // 总页数
                                        this.count = res.count*1   // 总个数
                                        this.currentPage = 1
                                    }else{
                                        this.count = '0'
                                        this.$message({
                                            message: res.msg,
                                            duration:'2000'
                                        })
                                    }
                                })
                            }else{
                                this.$message({
                                    message: res_one.msg,
                                    duration:'2000'
                                })
                            }
                        })
                    }else if(from_table == 'blog'){
                        blog_delete_info({token:token,blog_id:id}).then((res_one)=>{
                            if(res_one.error_code == '0'){
                                this.$message({
                                    message: res_one.msg,
                                    duration:'2000'
                                })
                                recruit_blog_info_list({token:token,push_status_text:'not_start',push_type_text:'group',page:'1'}).then((res) =>{
                                    console.log(res,'小组列表')
                                    if(res.error_code == '0'){
                                        let panel_topic_list = res.data
                                        let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                                        for(let i=0;i<panel_topic_list.length;i++){
                                            panel_topic_list[i].is = '0'
                                            panel_topic_list[i].is_show = '0'
                                            let mat = panel_topic_list[i].content.match(cnReg);
                                            if(panel_topic_list[i].from_table == 'recruit'){
                                                panel_topic_list[i].img_num = '0'
                                                if(panel_topic_list[i].gallery.length > 3){
                                                    panel_topic_list[i].img_num = panel_topic_list[i].gallery.length*1 - 3*1
                                                }
                                            }
                                            if(panel_topic_list[i].from_table == 'blog'){
                                                if(mat == null){
                                                    if((panel_topic_list[i].content.length)/2 > 138){
                                                        panel_topic_list[i].is_show = '1'
                                                    }
                                                }else{
                                                    if(((panel_topic_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                                        panel_topic_list[i].is_show = '1'
                                                    }
                                                }
                                                panel_topic_list[i].img_num = '0'
                                                if(panel_topic_list[i].gallery.length > 9){
                                                    panel_topic_list[i].img_num = panel_topic_list[i].gallery.length*1 - 9*1
                                                }
                                            }
                                        }
                                        this.panel_topic_list = panel_topic_list
                                        this.page = res.page     // 当前页数
                                        this.pages = res.pages   // 总页数
                                        this.count = res.count*1   // 总个数
                                        this.currentPage = 1
                                    }else{
                                        this.count = '0'
                                        this.$message({
                                            message: res.msg,
                                            duration:'2000'
                                        })
                                    }
                                })
                            }else{
                                this.$message({
                                    message: res_one.msg,
                                    duration:'2000'
                                })
                            }
                        })
                    }
                })
                .catch(() => {
                    console.log('取消')
                })
            },
            // 活动推送 招聘/帖子 删除
            activity_topic_dele(id,from_table){
                let token = this.token
                Dialog.confirm({
                    title: '',
                    message:'确定删除吗？',
                })
                .then(() => {
                    if(from_table == 'recruit'){
                        recruit_delete_info({token:token,recruit_id:id}).then((res_one)=>{
                            if(res_one.error_code == '0'){
                                this.$message({
                                    message: res_one.msg,
                                    duration:'2000'
                                })
                                recruit_blog_info_list({token:token,push_status_text:'not_start',push_type_text:'activity',page:'1'}).then((res) =>{
                                    console.log(res,'活动列表')
                                    if(res.error_code == '0'){
                                        let activity_topic_list = res.data
                                        let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                                        for(let i=0;i<activity_topic_list.length;i++){
                                            activity_topic_list[i].is = '0'
                                            activity_topic_list[i].is_show = '0'
                                            let mat = activity_topic_list[i].content.match(cnReg);
                                            if(activity_topic_list[i].from_table == 'recruit'){
                                                activity_topic_list[i].img_num = '0'
                                                if(activity_topic_list[i].gallery.length > 3){
                                                    activity_topic_list[i].img_num = activity_topic_list[i].gallery.length*1 - 3*1
                                                }
                                            }
                                            if(activity_topic_list[i].from_table == 'blog'){
                                                if(mat == null){
                                                    if((activity_topic_list[i].content.length)/2 > 138){
                                                        activity_topic_list[i].is_show = '1'
                                                    }
                                                }else{
                                                    if(((activity_topic_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                                        activity_topic_list[i].is_show = '1'
                                                    }
                                                }
                                                activity_topic_list[i].img_num = '0'
                                                if(activity_topic_list[i].gallery.length > 9){
                                                    activity_topic_list[i].img_num = activity_topic_list[i].gallery.length*1 - 9*1
                                                }
                                            }
                                        }
                                        this.activity_topic_list = activity_topic_list
                                        this.page = res.page     // 当前页数
                                        this.pages = res.pages   // 总页数
                                        this.count = res.count*1   // 总个数
                                        this.currentPage = 1
                                    }else{
                                        this.count = '0'
                                        this.$message({
                                            message: res.msg,
                                            duration:'2000'
                                        })
                                    }
                                })
                            }else{
                                this.$message({
                                    message: res_one.msg,
                                    duration:'2000'
                                })
                            }
                        })
                    }else if(from_table == 'blog'){
                        blog_delete_info({token:token,blog_id:id}).then((res_one)=>{
                            if(res_one.error_code == '0'){
                                this.$message({
                                    message: res_one.msg,
                                    duration:'2000'
                                })
                                recruit_blog_info_list({token:token,push_status_text:'not_start',push_type_text:'activity',page:'1'}).then((res) =>{
                                    console.log(res,'活动列表')
                                    if(res.error_code == '0'){
                                        let activity_topic_list = res.data
                                        let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g;
                                        for(let i=0;i<activity_topic_list.length;i++){
                                            activity_topic_list[i].is = '0'
                                            activity_topic_list[i].is_show = '0'
                                            let mat = activity_topic_list[i].content.match(cnReg);
                                            if(activity_topic_list[i].from_table == 'recruit'){
                                                activity_topic_list[i].img_num = '0'
                                                if(activity_topic_list[i].gallery.length > 3){
                                                    activity_topic_list[i].img_num = activity_topic_list[i].gallery.length*1 - 3*1
                                                }
                                            }
                                            if(activity_topic_list[i].from_table == 'blog'){
                                                if(mat == null){
                                                    if((activity_topic_list[i].content.length)/2 > 138){
                                                        activity_topic_list[i].is_show = '1'
                                                    }
                                                }else{
                                                    if(((activity_topic_list[i].content.length - mat.length)/2 + mat.length) > 136){
                                                        activity_topic_list[i].is_show = '1'
                                                    }
                                                }
                                                activity_topic_list[i].img_num = '0'
                                                if(activity_topic_list[i].gallery.length > 9){
                                                    activity_topic_list[i].img_num = activity_topic_list[i].gallery.length*1 - 9*1
                                                }
                                            }
                                        }
                                        this.activity_topic_list = activity_topic_list
                                        this.page = res.page     // 当前页数
                                        this.pages = res.pages   // 总页数
                                        this.count = res.count*1   // 总个数
                                        this.currentPage = 1
                                    }else{
                                        this.count = '0'
                                        this.$message({
                                            message: res.msg,
                                            duration:'2000'
                                        })
                                    }
                                })
                            }else{
                                this.$message({
                                    message: res_one.msg,
                                    duration:'2000'
                                })
                            }
                        })
                    }
                })
                .catch(() => {
                    console.log('取消')
                })
            },
            // 抽奖删除
            award_dele(id){
                let token = this.token
                Dialog.confirm({
                    title: '',
                    message:'确定删除吗？',
                })
                .then(() => {
                    award_delete({token:token,award_id:id}).then((res_one)=>{
                        if(res_one.error_code == '0'){
                            this.$message({
                                message: res_one.msg,
                                duration:'2000'
                            })
                            award_list({token:token,push_status_text:'not_start',page:'1'}).then((res) =>{
                                console.log(res,'抽奖列表')
                                if(res.error_code == '0'){
                                    let award_list = res.data
                                    this.award_list = award_list

                                    this.page = res.page     // 当前页数
                                    this.pages = res.pages   // 总页数
                                    this.count = res.count*1   // 总个数
                                    this.currentPage = 1
                                }else{
                                    this.count = '0'
                                    this.$message({
                                        message: res.msg,
                                        duration:'2000'
                                    })
                                }
                            })
                        }else{
                            this.$message({
                                message: res_one.msg,
                                duration:'2000'
                            })
                        }
                    })
                })
                .catch(() => {
                    console.log('取消')
                })

            },
        },
    }
</script>