<template>
    <div class="y_emoji_1">
        <div class="y_emoji_2">
            <div class="y_emoji_3">
                <div class="y_emoji_4" :class="user_accept.head_portrait == null ?'y_emoji_15_img_1':''">
                    <img :src="'' + user_accept.head_portrait" alt="">
                    <img src="../../../images/icon_112.png" alt="">
                </div>
                <div class="y_emoji_5">
                    <h2>{{user_accept.nicname}}</h2>
                </div>
                <div class="y_emoji_6">
                    <p>{{user_accept.school_title}}</p>
                </div>
            </div>
            <div class="y_emoji_7" @click="name_show()">
                <div class="y_emoji_8">
                    <img src="../../../images/icon_104.png" alt="">
                </div>
                <div class="y_emoji_9">
                    <h2>{{user_accept.remark_name}}</h2>
                </div>
            </div>
        </div>
        <div class="y_emoji_10" :class="add_switch == '0'?'':'y_emoji_10_active'" @scroll="handleScroll" ref="scrollContainer">
            <div class="y_emoji_11" >
                <div class="y_emoji_12" v-for="(item,index) in chat_transcript" :key="index">
                    <!-- 时间 -->
                    <div class="y_emoji_13" v-if="item.is_time_message == '1' ?true:false">
                        <p>{{ item.time }}</p>
                    </div>
                    <!-- 对方内容 -->
                    <div class="y_emoji_14" v-if="item.uid !== user.id ? (item.type == 'content' ?true:false):false">
                        <div class="y_emoji_15" :class="user_accept.head_portrait == null?'y_emoji_15_img_1':''">
                            <img :src="'' + user_accept.head_portrait" alt="">
                            <img src="../../../images/icon_112.png" alt="">
                        </div>
                        <div class="y_emoji_16 y_emoji_16_1">
                            <p>{{item.content}}</p>
                        </div>
                    </div>
                    <!-- 对方招聘 -->
                    <div class="y_emoji_14" v-if="item.uid !== user.id ? (item.type == 'recruit' ?true:false) :false">
                        <div class="y_emoji_15" :class="user_accept.head_portrait == null?'y_emoji_15_img_1':''">
                            <img :src="'' + user_accept.head_portrait" alt="">
                            <img src="../../../images/icon_112.png" alt="">
                        </div>
                        
                        <div class="y_emoji_16 y_emoji_16_zp">
                            <div class="y_push_progress_48" v-if="item.recruit_info">
                                <div class="y_push_progress_49">
                                    <h2>{{ item.recruit_info.title }}</h2>
                                </div>
                                <div class="y_push_progress_50">
                                    <h2>【薪&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资】</h2>
                                
                                    <p v-if="item.recruit_info.salary_type == 'range'?true:false">{{item.recruit_info.min_salary_fee}} - {{ item.recruit_info.max_salary_fee }}  元/{{item.recruit_info.settle_type == 'hour'?'小时':(item.recruit_info.settle_type == 'day'?'天':(item.recruit_info.settle_type == 'week'?'周':(item.recruit_info.settle_type == 'month'?'月':(item.recruit_info.settle_type == 'times'?'次':''))))}}</p>
                                    <p v-if="item.recruit_info.salary_type == 'fixed'?true:false">{{item.recruit_info.salary_fee}}  元/{{item.recruit_info.settle_type == 'hour'?'小时':(item.recruit_info.settle_type == 'day'?'天':(item.recruit_info.settle_type == 'week'?'周':(item.recruit_info.settle_type == 'month'?'月':(item.recruit_info.settle_type == 'times'?'次':''))))}}</p>
                                </div>
                                <div class="y_push_progress_50">
                                    <h2>【福&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;利】</h2>
                                    <div class="y_push_progress_51">
                                        <p v-for="(fl,index) in item.recruit_info.welfare" :key="index">{{fl}}</p>
                                    </div>
                                </div>
                                <div class="y_push_progress_50">
                                    <p><span>【工作内容】</span>{{item.recruit_info.job_content}}</p>
                                </div>
                                <div class="y_push_progress_50">
                                    <p><span>【工作时间】</span>{{item.recruit_info.work_time}}</p>
                                </div>
                                <div class="y_push_progress_50">
                                    <p><span>【工作要求】</span>{{item.recruit_info.other_demand}}</p>
                                </div>
                                <div class="y_push_progress_50">
                                    <p><span>【招聘人数】</span>{{item.recruit_info.people_number}}人</p>
                                </div>
                            </div>
                            <div class="y_push_progress_52" v-if="item.recruit_info">
                                <div class="y_push_progress_53">
                                    <div class="y_push_progress_54">
                                        <img src="../../../images/icon_67.png" alt="">
                                        <h2>{{item.recruit_info.work_place_title}}</h2>
                                    </div>
                                    <!-- <div class="y_push_progress_55">
                                        <p>距我 {{item.recruit_info.distance}}</p>
                                    </div> -->
                                </div>
                                <div class="y_push_progress_56">
                                    <img src="../../../images/icon_12.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 对方图片 -->
                    <div class="y_emoji_14"  v-if="item.uid !== user.id ? (item.type == 'img' ?true:false) :false">
                        <div class="y_emoji_15" :class="user_accept.head_portrait == null?'y_emoji_15_img_1':''">
                            <img :src="'' + user_accept.head_portrait" alt="">
                            <img src="../../../images/icon_112.png" alt="">
                        </div>
                        <div class="y_emoji_16 y_emoji_16_2" @click="zoom_up(index)">
                            <img :src="'' + item.content" alt="">
                        </div>
                    </div>
                    <!-- 对方地图 -->
                    <div class="y_emoji_14"  v-if="item.uid !== user.id ? (item.type == 'location' ?true:false) :false">
                        <div class="y_emoji_15" :class="user_accept.head_portrait == null?'y_emoji_15_img_1':''">
                            <img :src="'' + user_accept.head_portrait" alt="">
                            <img src="../../../images/icon_112.png" alt="">
                        </div>
                        <div class="y_emoji_16 y_emoji_16_2 y_emoji_16_map" @click="map_show(item.id)">
                            <img src="../../../images/icon_109.png" alt="">
                            <p>{{ item.content[0].place_title }}</p>
                        </div>
                    </div>
                    <!-- 对方文件 -->
                    <div class="y_emoji_14"  v-if="item.uid !== user.id ? (item.type == 'file' ?true:false) :false">
                        <div class="y_emoji_15" :class="user_accept.head_portrait == null?'y_emoji_15_img_1':''">
                            <img :src="'' + user_accept.head_portrait" alt="">
                            <img src="../../../images/icon_112.png" alt="">
                        </div>
                        <a :href="'' + item.content[0].file_url" class="y_emoji_16 y_emoji_16_2 y_emoji_16_file">
                            <img src="../../../images/icon_110.png" alt="">
                        </a>
                    </div>

                    <!-- 己方内容 -->
                    <div class="y_emoji_14 y_emoji_14_2" v-if="item.uid == user.id ? (item.type == 'content' ?true:false):false">
                        <div class="y_emoji_15" :class="user.business_head_portrait == ''?'y_emoji_15_img_1':''">
                            <img :src="'' + user.business_head_portrait" alt="">
                            <img src="../../../images/icon_112.png" alt="">
                        </div>
                        <div class="y_emoji_16 y_emoji_16_1">
                            <p>{{item.content}}</p>
                        </div>
                    </div>
                    <!-- 己方照片 -->
                    <div class="y_emoji_14 y_emoji_14_2" v-if="item.uid == user.id ? (item.type == 'img' ?true:false) :false">
                        <div class="y_emoji_15" :class="user.business_head_portrait == ''?'y_emoji_15_img_1':''">
                            <img :src="'' + user.business_head_portrait" alt="">
                            <img src="../../../images/icon_112.png" alt="">
                        </div>
                        <div class="y_emoji_16 y_emoji_16_2" @click="zoom_up(index)">
                            <img :src="'' + item.content" alt="">
                        </div>
                    </div>
                    <!-- 己方文件 -->
                    <div class="y_emoji_14 y_emoji_14_2"  v-if="item.uid == user.id ? (item.type == 'file' ?true:false) :false">
                        <div class="y_emoji_15" :class="user.business_head_portrait == ''?'y_emoji_15_img_1':''">
                            <img :src="'' + user.business_head_portrait" alt="">
                            <img src="../../../images/icon_112.png" alt="">
                        </div>
                        <div v-if="item.content">
                            <a :href="'' + item.content[0].file_url" class="y_emoji_16 y_emoji_16_2 y_emoji_16_file">
                                <img src="../../../images/icon_110.png" alt="" />
                                <p>{{ item.content[0].name }}</p>
                            </a>
                        </div>
                    </div>
                    <!-- 己方地图 -->
                    <div class="y_emoji_14 y_emoji_14_2"  v-if="item.uid == user.id ? (item.type == 'location' ?true:false) :false">
                        <div class="y_emoji_15" :class="user.business_head_portrait == ''?'y_emoji_15_img_1':''">
                            <img :src="'' + user.business_head_portrait" alt="">
                            <img src="../../../images/icon_112.png" alt="">
                        </div>
                        <div class="y_emoji_16 y_emoji_16_2 y_emoji_16_map" @click="map_show(item.id)">
                            <img src="../../../images/icon_109.png" alt="">
                            <p>{{ item.content[0].place_title }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="y_emoji_17" :class="add_switch == '0'?'':'y_emoji_17_active'">
            <div class="y_emoji_18">
                <div class="y_emoji_19" @click="bottom_hide()">
                    <input type="text" v-model="input_contents" @blur="handleInputBlur" @keyup.enter="handleEnter">
                </div>
                <div class="y_emoji_20">
                    <div class="y_emoji_21" @click="emoji_show()">
                        <img src="../../../images/icon_105.png" alt="">
                    </div>
                    <div class="y_emoji_21" @click="add_show()">
                        <img src="../../../images/icon_106.png" alt="">
                    </div>
                </div>
            </div>
            <div class="y_emoji_22" :class="add_val == '0'?'y_display_none':''">
                <div class="y_emoji_23">
                    <el-upload class="upload-demo" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :on-success="hand_picture" list-type="picture">
                        <div class="y_emoji_24">
                            <img src="../../../images/icon_107.png" alt="">
                        </div>
                        <div class="y_emoji_25">
                            <p>相册</p>
                        </div>
                    </el-upload>
                </div>

                <div class="y_emoji_23">
                    <el-upload class="upload-demo" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :on-success="hand_picture" :accept="'image/*'" list-type="picture">
                        <div class="y_emoji_24">
                            <img src="../../../images/icon_108.png" alt="">
                        </div>
                        <div class="y_emoji_25">
                            <p>拍摄</p>
                        </div>
                    </el-upload>
                </div>

                <div class="y_emoji_23" @click="send_location">
                    <div class="y_emoji_24">
                        <img src="../../../images/icon_109.png" alt="">
                    </div>
                    <div class="y_emoji_25">
                        <p>位置</p>
                    </div>
                </div>
                <div class="y_emoji_23">
                    <el-upload class="upload-demo" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :on-success="hand_text" list-type="text">
                        <div class="y_emoji_24">
                            <img src="../../../images/icon_110.png" alt="">
                        </div>
                        <div class="y_emoji_25">
                            <p>文件</p>
                        </div>
                    </el-upload>
                </div>
            </div>
            <div class="y_emoji_26" :class="emoji_val == '0'?'y_display_none':''">
                <div class="y_emoji_0">
                    <p v-for="item in emoji" :key="item.index" @click="enoji_date(item.index)">{{ item.icon }}</p>
                </div>
            </div>
        </div>
        
    </div>

    <div class="y_map_pop_1" :class='map_pop == 0?"y_display_opacity":""'>
        <div class="y_add_search_0">
            <div>
                <div class="y_add_search_1">
                    <el-select v-model="keywords" filterable remote reserve-keyword placeholder="请输入关键词" :remote-method="remoteMethod" :loading="loading" :clearable="true" size="mini" @change="currentSelect" ref="select" @focus="clear" @hook:mounted="clear" @visible-change="clear">
                        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item" class="one-text">
                            <span style="float: left">{{ item.name }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.district }}</span>
                        </el-option>
                    </el-select>
                </div>
                <div id="container" class="container y_add_search_2"></div>
            </div>
        </div>
        <div class="y_map_pop_2">
            <p class="y_map_pop_3" @click="map_cancel">取消</p>
            <p class="y_map_pop_4" @click="map_determine" :class="user_accept_none == '0'?'y_display_none':''">确定</p>
        </div>
    </div>

    <!-- 公共弹窗   遮罩层 -->
    <div class='y_pop_masking' :class='pop_masking == 0 ? "":"y_masking_block"'></div>
    <div class="y_name_change_1" :class="name_change_value == '0'?'y_display_none':''">
        <div class="y_name_change_2">
            <h2>昵称修改</h2>
        </div>
        <div class="y_name_change_3">
            <input type="text" placeholder="请输入" v-model="input_name">
        </div>
        <div class="y_name_change_4">
            <p class="y_name_change_5" @click="name_hide()">取消</p>
            <p class="y_name_change_6" @click="name_date()">确定</p>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'

import { ImagePreview } from 'vant';
import {chat_list} from '../../api/request.js' // 获取一对一聊天的记录
import {send_message} from '../../api/request.js'  // 发送聊天内容
import {edit_user_follow} from '../../api/request.js'  // 发送聊天内容

import AMapLoader from "@amap/amap-jsapi-loader";
window._AMapSecurityConfig = {
    // 安全密钥
    securityJsCode: "6379b3cce15f6de8d9a891f8b0033fd8",
};
export default {
        data(){
            return {
               emoji:[
                {index:'0',icon:'😀',text:'&#128512;'},
                {index:'1',icon:'😁',text:'&#128513;'},
                {index:'2',icon:'😂',text:'&#128514;'},
                {index:'3',icon:'😃',text:'&#128515;'},
                {index:'4',icon:'😄',text:'&#128516;'},
                {index:'5',icon:'😅',text:'&#128517;'},
                {index:'6',icon:'😆',text:'&#128518;'},
                {index:'7',icon:'😇',text:'&#128519;'},
                {index:'8',icon:'😈',text:'&#128520;'},
                {index:'9',icon:'😉',text:'&#128521;'},
                {index:'10',icon:'😊',text:'&#128522;'},
                {index:'11',icon:'😋',text:'&#128523;'},
                {index:'12',icon:'😌',text:'&#128524;'},
                {index:'13',icon:'😍',text:'&#128525;'},
                {index:'14',icon:'😎',text:'&#128526;'},
                {index:'15',icon:'😏',text:'&#128527;'},
                {index:'16',icon:'😐',text:'&#128528;'},
                {index:'17',icon:'😑',text:'&#128529;'},
                {index:'18',icon:'😒',text:'&#128530;'},
                {index:'19',icon:'😓',text:'&#128531;'},
                {index:'20',icon:'😔',text:'&#128532;'},
                {index:'21',icon:'😕',text:'&#128533;'},
                {index:'22',icon:'😖',text:'&#128534;'},
                {index:'23',icon:'😗',text:'&#128535;'},
                {index:'24',icon:'😘',text:'&#128536;'},
                {index:'25',icon:'😙',text:'&#128537;'},
                {index:'26',icon:'😚',text:'&#128538;'},
                {index:'27',icon:'😛',text:'&#128539;'},
                {index:'28',icon:'😜',text:'&#128540;'},
                {index:'29',icon:'😝',text:'&#128541;'},
                {index:'30',icon:'😞',text:'&#128542;'},
                {index:'31',icon:'😟',text:'&#128543;'},
                {index:'32',icon:'😠',text:'&#128544;'},
                {index:'33',icon:'😡',text:'&#128545;'},
                {index:'34',icon:'😢',text:'&#128546;'},
                {index:'35',icon:'😣',text:'&#128547;'},
                {index:'36',icon:'😤',text:'&#128548;'},
                {index:'37',icon:'😥',text:'&#128549;'},
                {index:'38',icon:'😦',text:'&#128550;'},
                {index:'39',icon:'😧',text:'&#128551;'},
                {index:'40',icon:'😨',text:'&#128552;'},
                {index:'41',icon:'😩',text:'&#128553;'},
                {index:'42',icon:'😪',text:'&#128554;'},
                {index:'43',icon:'😫',text:'&#128555;'},
                {index:'44',icon:'😬',text:'&#128556;'},
                {index:'45',icon:'😭',text:'&#128557;'},
                {index:'46',icon:'😮',text:'&#128558;'},
                {index:'47',icon:'😯',text:'&#128559;'},
                {index:'48',icon:'😰',text:'&#128560;'},
                {index:'49',icon:'😱',text:'&#128561;'},
                {index:'50',icon:'😲',text:'&#128562;'},
                {index:'51',icon:'😳',text:'&#128563;'},
                {index:'52',icon:'😴',text:'&#128564;'},
                {index:'53',icon:'😵',text:'&#128565;'},
                {index:'54',icon:'😶',text:'&#128566;'},
                {index:'55',icon:'😷',text:'&#128567;'},
                {index:'56',icon:'🙁',text:'&#128577;'},
                {index:'57',icon:'🙂',text:'&#128578;'},
                {index:'58',icon:'🙃',text:'&#128579;'},
                {index:'59',icon:'🙄',text:'&#128580;'},
                {index:'60',icon:'🤐',text:'&#129296;'},
                {index:'61',icon:'🤑',text:'&#129297;'},
                {index:'62',icon:'🤒',text:'&#129298;'},
                {index:'63',icon:'🤓',text:'&#129299;'},
                {index:'64',icon:'🤔',text:'&#129300;'},
                {index:'65',icon:'🤕',text:'&#129301;'},
                {index:'66',icon:'🤠',text:'&#129312;'},
                {index:'67',icon:'🤡',text:'&#129313;'},
                {index:'68',icon:'🤢',text:'&#129314;'},
                {index:'69',icon:'🤣',text:'&#129315;'},
                {index:'70',icon:'🤤',text:'&#129316;'},
                {index:'71',icon:'🤥',text:'&#129317;'},
                {index:'72',icon:'🤧',text:'&#129319;'},
                {index:'73',icon:'🤨',text:'&#129320;'},
                {index:'74',icon:'🤩',text:'&#129321;'},
                {index:'75',icon:'🤪',text:'&#129322;'},
                {index:'76',icon:'🤫',text:'&#129323;'},
                {index:'77',icon:'🤬',text:'&#129324;'},
                {index:'78',icon:'🤭',text:'&#129325;'},
                {index:'79',icon:'🤮',text:'&#129326;'},
                {index:'80',icon:'🤯',text:'&#129327;'},
                {index:'81',icon:'🧐',text:'&#129488;'},
               ],
               token:'',
               accept_uid:'',  // uid
               add_switch:'0',  // +开关
               input_contents:'', // input 内容
               cursorIndex:'',
               add_val:'0',
               emoji_val:'0',
               chat_transcript:'', // 聊天记录
               user:'',  // 自己的数据
               user_accept:'',  // 对方的数据

                // 地图实例
                map: null,
                // 标记点
                marker: "",
                // 地址逆解析
                geoCoder: null,
                // 搜索提示
                AutoComplete: null,
                // 搜索关键字
                keywords: "",
                // 位置信息
                form: {
                  lng: "",
                  lat: "",
                  address: "",
                  adcode: "", //地区编码
                },
                // 搜索节流阀
                loading: false,
                // 搜索提示信息
                options: [],

                user_accept_ing:'121.549792',
                user_accept_lat:'29.868388',
                user_accept_none:'1',
                map_pop:'0',
                page:'1', // 分页码
                pop_masking:'0',   // 公共弹窗 遮罩层
                name_change_value:'0', // 默认0 隐藏
                input_name:'', // 昵称修改 input

                fullscreenLoading: false, // 发送中阴影
            }
        },  
        components:{

        },
        created(){
            // 获取页面传参 推送类型
            if(this.$route.query.message_id !== undefined){
                this.accept_uid = this.$route.query.message_id
            }
        },
        scrollToTop() {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            console.log(scrollTop)
        },
        mounted(){
            let token = this.$cookies.get('token')
            let that = this;
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token

            let ws = new WebSocket("wss://workerman.mjxyq.com");
            // 服务端主动推送消息时会触发这里的onmessage
            ws.onmessage = function(e){
                console.log(e);
                // json数据转换成js对象
                var data = eval("("+e.data+")");
                var gateway_type = data.type || '';
                console.log(data,gateway_type,'111```');
                if(data.gateway_type == 'init'){
                    $.post('https://xcx.mjxyq.com/api/index.php/chat-record/single-init.html', {token:token,accept_uid:that.accept_uid,client_id: data.client_id,is_business:'1',is_business_accept:'0'}, function(data){
                        console.log(data);
                        chat_list({token:token,accept_uid:that.accept_uid,is_business:'1',page:1,is_business_accept:'0'}).then((res)=>{
                            console.log(res,'聊天历史记录')
                            that.chat_transcript = res.data
                            that.user = res.user
                            that.user_accept = res.user_accept

                            that.$nextTick(() => {
                                let scrollEl = that.$refs.scrollContainer;
                                scrollEl.scrollTo({ top:(scrollEl.scrollHeight*1+500*1), behavior: 'smooth' });
                            });
                        })
                    },'json');
                }else{
                    let chat_transcript = that.chat_transcript
                    chat_list({token:token,accept_uid:that.accept_uid,is_business:'1',page:1,is_business_accept:'0'}).then((res)=>{
                        console.log(res,'聊天历史记录')
                        let data = res.data.slice(-1);
                        that.chat_transcript = that.chat_transcript.concat(data);
                        that.$nextTick(() => {
                            let scrollEl = that.$refs.scrollContainer;
                            scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' });
                        });
                    })
                }
            }; 
            this.initMap();
            window.addEventListener('scroll', this.scrollToTop)
        },
        methods: {
            // +开关
            add_show(){
                let add_val = this.add_val
                if(add_val == '0'){
                    this.add_switch = '1'
                    this.add_val = '1'
                    this.emoji_val = '0'
                }else{
                    this.add_switch = '0'
                    this.add_val = '0'
                }
            },
            // emoji 开关
            emoji_show(){
                let emoji_val = this.emoji_val
                if(emoji_val == '0'){
                    this.add_switch = '1'
                    this.emoji_val = '1'
                    this.add_val = '0'
                }else{
                    this.add_switch = '0'
                    this.emoji_val = '0'
                }
            },
            bottom_hide(){
                this.add_val = '0'
                this.emoji_val = '0'
                this.add_switch = '0'
            }, 
            enoji_date(index){
                let emoji = this.emoji
                let input_contents = this.input_contents
                let cursorIndex = this.cursorIndex
                console.log(input_contents,input_contents.length,cursorIndex)
                if(input_contents.length == cursorIndex){
                    for(let i=0;i<emoji.length;i++){
                        if(emoji[i].index == index){
                            let input_contents = this.input_contents
                            this.input_contents = `${input_contents}${emoji[i].icon}`
                        }
                    }
                }else{
                    console.log(input_contents)
                    let s1 = input_contents.substring(0, cursorIndex)
                    let s2 = input_contents.substring(cursorIndex, input_contents.length)
                    for(let i=0;i<emoji.length;i++){
                        if(emoji[i].index == index){
                            this.input_contents = `${s1}${emoji[i].icon}${s2}`
                        }
                    } 
                }
                
            },
            // 记录input光标位置
            handleInputBlur(e) {
                this.cursorIndex = e.srcElement.selectionStart; 
            },
            // 回车键 发送
            handleEnter(){
                let input_contents = this.input_contents
                let token = this.token
                let accept_uid = this.accept_uid
                let chat_transcript = this.chat_transcript
                const loading = this.$loading({
                    lock: true,
                    text: '发送中ing',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                if(input_contents !== ''){
                    send_message({token,accept_uid,content:input_contents,type:'content',is_business:'1',accept_user_type:'student'}).then((res)=>{
                        console.log(res,'发送的内容')
                        if(res.error_code == '0'){
                            this.input_contents = ''
                            chat_list({token:token,accept_uid,is_business:'1',page:'1',is_business_accept:'0'}).then((res)=>{
                                console.log(res,'聊天历史记录')
                                let data = res.data.slice(-1);
                                this.chat_transcript = this.chat_transcript.concat(data);
                                this.$nextTick(() => {
                                    let scrollEl = this.$refs.scrollContainer;
                                    scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' });
                                });
                                loading.close();
                            })
                        }else{
                            this.$message({
                                message:res.msg,
                                duration:'2000'
                            }) 
                        }
                    })
                }
                console.log(this.chat_transcript)

            },
            // 相册上传
            hand_picture(file){
                console.log(file)
                let token = this.token
                let accept_uid = this.accept_uid
                let chat_transcript = this.chat_transcript
                const loading = this.$loading({
                    lock: true,
                    text: '发送中ing',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                if(file.img !== ''){
                    send_message({token,accept_uid,content:file.img,type:'img',is_business:'1'}).then((res)=>{
                        console.log(res,'发送的图片')
                        if(res.error_code == '0'){
                            chat_list({token:token,accept_uid,is_business:'1',page:'1',is_business_accept:'0'}).then((res)=>{
                                console.log(res,'聊天历史记录')
                                let data = res.data.slice(-1);
                                this.chat_transcript = this.chat_transcript.concat(data);
                                this.$nextTick(() => {
                                    let scrollEl = this.$refs.scrollContainer;
                                    scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' });
                                });
                                loading.close();
                            })
                        }else{
                            this.$message({
                                message:res.msg,
                                duration:'2000'
                            }) 
                        }
                    })
                }
            },
            // 照片上传
            hand_picture_card(file){
                console.log(file)
                let token = this.token
                let accept_uid = this.accept_uid
                let chat_transcript = this.chat_transcript
                const loading = this.$loading({
                    lock: true,
                    text: '发送中ing',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                if(file.img !== ''){
                    send_message({token,accept_uid,content:file.img,type:'img',is_business:'1'}).then((res)=>{
                        console.log(res,'发送的图片')
                        if(res.error_code == '0'){
                            chat_list({token:token,accept_uid,is_business:'1',page:'1',is_business_accept:'0'}).then((res)=>{
                                console.log(res,'聊天历史记录')
                                let data = res.data.slice(-1);
                                this.chat_transcript = this.chat_transcript.concat(data);
                                this.$nextTick(() => {
                                    let scrollEl = this.$refs.scrollContainer;
                                    scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' });
                                });
                                loading.close();
                            })
                        }else{
                            this.$message({
                                message:res.msg,
                                duration:'2000'
                            }) 
                        }
                    })
                }
            },
            // 文件上传
            hand_text(file){
                console.log(file)
                let token = this.token
                let accept_uid = this.accept_uid
                let chat_transcript = this.chat_transcript
                const loading = this.$loading({
                    lock: true,
                    text: '发送中ing',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                if(file.img !== ''){
                    let contact_val = [{name:file.name,suffix:file.suffix,file_url:file.img}]
                    send_message({token,accept_uid,content:JSON.stringify(contact_val),type:'file',is_business:'1'}).then((res)=>{
                        console.log(res,'发送的文件')
                        if(res.error_code == '0'){
                            chat_list({token:token,accept_uid,is_business:'1',page:'1',is_business_accept:'0'}).then((res)=>{
                                console.log(res,'聊天历史记录')
                                let data = res.data.slice(-1);
                                this.chat_transcript = this.chat_transcript.concat(data);
                                this.$nextTick(() => {
                                    let scrollEl = this.$refs.scrollContainer;
                                    scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' });
                                });
                                loading.close();
                            })
                        }else{
                            this.$message({
                                message:res.msg,
                                duration:'2000'
                            }) 
                        }
                    })
                }
            },
            // 发送位置 显示
            send_location(){
                $('.amap-marker').hide()
                this.map_pop = '1'
                this.user_accept_none = '1'
            },
            // 发送位置
            map_determine(){
                let form = this.form
                let token = this.token
                let accept_uid = this.accept_uid
                let chat_transcript = this.chat_transcript
                const loading = this.$loading({
                    lock: true,
                    text: '发送中ing',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                console.log(form)
                if(form.lat == '' || form.lng == ''){
                    this.$message({
                        message:'请选择定位',
                        duration:'2000'
                    })
                }else{
                    let contact_val = [{place_title:form.address,longitude:form.lng,latitude:form.lat}]
                    send_message({token,accept_uid,content:JSON.stringify(contact_val),type:'location',is_business:'1'}).then((res)=>{
                        console.log(res,'发送的地址')
                        if(res.error_code == '0'){
                            chat_list({token:token,accept_uid,is_business:'1',page:'1',is_business_accept:'0'}).then((res)=>{
                                console.log(res,'聊天历史记录')
                                let data = res.data.slice(-1);
                                this.chat_transcript = this.chat_transcript.concat(data);
                                this.$nextTick(() => {
                                    let scrollEl = this.$refs.scrollContainer;
                                    scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' });
                                });
                                this.map_pop = '0'
                                this.user_accept_none = '0'
                                loading.close();
                            })
                        }else{
                            this.$message({
                                message:res.msg,
                                duration:'2000'
                            }) 
                        }
                    })
                }
                
            },
            // 地图
            initMap() {
                AMapLoader.load({
                    // 你申请的Key
                    key: "f8a1bbd8ef1b14c4e71a5b027835e6f3",
                    version: "2.0",
                    // 需要用到的插件
                    plugins: ["AMap.Geocoder", "AMap.AutoComplete"],
                })
                .then((AMap) => {
                    this.map = new AMap.Map("container", {
                        viewMode: "3D", //是否为3D地图模式
                        zoom: 15, //初始化地图级别
                        center: [this.user_accept_ing, this.user_accept_lat], //初始化地图中心点位置
                    });
                    //地址逆解析插件
                    this.geoCoder = new AMap.Geocoder({
                        city: "010", //城市设为北京，默认：“全国”
                        radius: 500, //范围，默认：500
                    });
                    // 搜索提示插件
                    this.AutoComplete = new AMap.AutoComplete({ city:"全国"});
                    //点击获取经纬度;
                    this.map.on("click", (e) => {
                        // 获取经纬度
                        this.form.lng = e.lnglat.lng;
                        this.form.lat = e.lnglat.lat;
                        // 清除点
                        this.removeMarker();
                        // 标记点
                        this.setMapMarker();
                    });

                    this.map.setFitView();
                    this.marker = new AMap.Marker({
                        map: this.map,
                        position: [this.user_accept_ing, this.user_accept_lat],
                    });
                })
                .catch((err) => {
                  // 错误
                  console.log(err);
                });
            },
            // 标记点
            setMapMarker() {
                // 自动适应显示想显示的范围区域
                this.map.setFitView();
                this.marker = new AMap.Marker({
                  map: this.map,
                  position: [this.form.lng, this.form.lat],
                });
                // 逆解析地址
                this.toGeoCoder();
                this.map.setFitView();
                this.map.add(this.marker);
                console.log(this.form)
            },
            // 清除点
            removeMarker() {
                if (this.marker) {
                    this.map.remove(this.marker);
                }
            },
            // 逆解析地址
            toGeoCoder() {
                let lnglat = [this.form.lng, this.form.lat];
                this.geoCoder.getAddress(lnglat, (status, result) => {
                    if (status === "complete" && result.regeocode) {
                        this.form.address = result.regeocode.formattedAddress;
                    }
                });
            },
            // 搜索
            remoteMethod(query) {
                console.log(query);
                if (query !== "") {
                    this.loading = true;
                    setTimeout(() => {
                      this.loading = false;
                      this.AutoComplete.search(query, (status, result) => {
                        this.options = result.tips;
                      });
                    }, 200);
                } else {
                    this.options = [];
                }
            },
            // 选中提示
            currentSelect(val) {
                console.log(val)
                // 清空时不执行后面代码
                if (!val) {
                    return;
                }
                this.form = {
                    lng: val.location.lng,
                    lat: val.location.lat,
                    address: val.district + val.address,
                    adcode: val.adcode,
                };
                this.keywords = val.name;
                // 清除点
                this.removeMarker();
                // 标记点
                this.setMapMarker();
            },
            // 地图显示
            map_show(id){
                let chat_transcript = this.chat_transcript
                for(let i=0;i<chat_transcript.length;i++){
                    if(chat_transcript[i].id == id){
                        this.user_accept_ing = chat_transcript[i].content[0].longitude
                        this.user_accept_lat = chat_transcript[i].content[0].latitude
                    }
                }
                this.map_pop = '1'
                this.user_accept_none = '0'
                this.initMap();
            },
            // 地图取消
            map_cancel(){
              this.map_pop = '0'
            },
            // 滚动监听
            handleScroll () {
            	let scrolled = this.$refs.scrollContainer.scrollTop;
                let token = this.token
                let accept_uid = this.accept_uid
                let chat_transcript = this.chat_transcript
                console.log(scrolled)
                if(scrolled == '0'){
                    let page = this.page*1 + 1
                    chat_list({token:token,accept_uid:accept_uid,is_business:'1',page,is_business_accept:'0'}).then((res)=>{
                        console.log(res,'聊天历史记录')
                        if(res.error_code == '0'){
                            let data = res.data
                            for(let i=0;i<data.length;i++){
                                chat_transcript.unshift(data[i])
                            }
                            this.chat_transcript = chat_transcript
                            this.page = page
                        }else{
                            this.$message({
                                message:'没有更多了',
                                duration:'2000'
                            })
                        }
                    })

                }
            },
            // 修改昵称 显示弹窗
            name_show(){
                this.pop_masking = '1'
                this.name_change_value = '1'
            },
            // 修改昵称 取消弹窗
            name_hide(){
                this.pop_masking = '0'
                this.name_change_value = '0'
            },
            // 修改昵称 确定弹窗
            name_date(){
                let input_name = this.input_name
                let token = this.token
                let remark_uid = this.accept_uid
                if(input_name == ''){
                    this.$message({
                        message:'请填写昵称',
                        duration:'2000'
                    }) 
                }else{
                    edit_user_follow({token,remark_uid,remark_name:input_name}).then((res)=>{
                        console.log(res,'修改昵称')
                        if(res.error_code == '0'){
                            this.pop_masking = '0'
                            this.name_change_value = '0'
                            this.user_accept.remark_name = input_name
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })    
                        }
                    })
                }

            },
            // 多图放大
            zoom_up(index){
                let chat_transcript = this.chat_transcript
                let url = []
                for(let i=0;i<chat_transcript.length;i++){
                    if(i == index*1){
                        url = '' + chat_transcript[i].content
                    }
                }
                console.log(url)

                ImagePreview({
                    images:[url],
                });
            }
        }
    }
</script>
  
<style>
    .van-image-preview__index{
        display: none !important;
    }
    .circular{
        background: url(../../../images/icon_115.png) no-repeat;
        background-size: 100% 100%;
        width: 0.8rem !important;
        height: 0.8rem !important;
    }
    .el-loading-text{
        font-size: 0.3rem !important;
        color: #FECE0A !important;
    }
</style>
<style scoped>
    .y_emoji_0{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        overflow: hidden;
        /* height: 100%; */
    }
    .y_emoji_0 p{
        width: 0.7rem;
        height: 0.9rem;
        font-size: 0.5rem;
    }

    .y_emoji_1{
        width: 100%;
        height: 100vh;
        background: #F4F5F7;
        position: relative;
    }
    .y_emoji_2{
        height: 1rem;
        background: #fff;
        padding: 0rem 0.25rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_emoji_3{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_emoji_4{
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 1rem;
        overflow: hidden;
    }
    .y_emoji_4 img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .y_emoji_5{
        margin-left: 0.2rem;
        max-width: 2rem;
    }
    .y_emoji_5 h2{
        color: #333;
        font-size: 0.3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
    }
    .y_emoji_6{
        margin-left: 0.15rem;
        max-width: 1.5rem;
    }
    .y_emoji_6 p{
        background: #FECE0A;
        line-height: 0.3rem;
        height: 0.3rem;
        color: #333;
        font-size: 0.2rem;
        padding: 0rem 0.1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        text-align: center;
    }
    .y_emoji_7{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 0.35rem;
        height: 100%;
        padding: 0rem 0.2rem;
    }
    .y_emoji_8{
        width: 0.22rem;
        height: 0.22rem;
    }
    .y_emoji_8 img{
        width: 100%;
        object-fit: cover;
    }
    .y_emoji_9{
        margin-left: 0.1rem;
        max-width: 1.5rem;
    }
    .y_emoji_9 h2{
        font-size: .24rem;
        color: #999;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        width: 100%;
    }
    .y_emoji_10{
        width: 100%;
        height: calc(100vh - 2.2rem);
        position: relative;
        overflow: scroll;
        transition: ease 0.5s all;
    }
    .y_emoji_10_active{
        height: calc(100vh - 5.7rem);
        transition: ease 0.5s all;
    }
    .y_emoji_11{
        padding: 0.2rem;
    }
    .y_emoji_12{
        width: 100%;
    }
    .y_emoji_13{
        margin: 0.5rem 0rem 0.4rem;
        text-align: center;
    }
    .y_emoji_13 p{
        color: #aaa;
        font-size: 0.18rem;
    }
    .y_emoji_14{
        display: flex;
        justify-content: baseline;
        margin-bottom: 0.25rem;
    }
    .y_emoji_15{
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 1rem;
        overflow: hidden;
    }
    .y_emoji_15 img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .y_emoji_15_img_1 img:nth-child(1){
        display: none;
    }
    .y_emoji_16{
        position: relative;
        display: inline-block;
        background: #fff;
        padding: 0.16rem 0.24rem 0.16rem 0.15rem;
        border-radius: 0.1rem;
        margin-left: 0.15rem;
        max-width: calc(100% - 1rem);
    }
    .y_emoji_16::after{
        position: absolute;
        left: -0.1rem;
        top: 0.2rem;
        border-top: 0.11rem solid transparent;
        border-bottom: 0.11rem solid transparent;
        border-right: 0.11rem solid #fff;
        content: '';
    }
    .y_emoji_16_1 p{
        font-size: 0.28rem;
        color: #0B1809;
    }
    .y_emoji_16_2{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .y_emoji_16_2 img{
        width: 2.5rem;
        object-fit: cover;
    }
    .y_emoji_16_map{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_emoji_16_map img{
        width: 0.5rem;
        height: 0.5rem;
    }
    .y_emoji_16_map p{
        font-size: 0.26rem;
        color: #333;
        font-weight: 600;
        line-height: 0.3rem;
        margin-left: 0.1rem;
    }
    .y_emoji_14_2{
        flex-direction: row-reverse;
    }
    .y_emoji_14_2 .y_emoji_16{
        margin-left: 0rem;
        margin-right: 0.15rem;
        background: #FECE0A;
        padding: 0.16rem 0.15rem 0.16rem 0.24rem;
        max-width: calc(100% - 1rem);
    }
    .y_emoji_14_2 .y_emoji_16::after{
        position: absolute;
        left: inherit;
        right: -0.18rem;
        top: 0.2rem;
        border-top: 0.11rem solid transparent;
        border-bottom: 0.11rem solid transparent;
        border-left: 0.11rem solid #FECE0A;
        content: '';
    }
    .y_emoji_17{
        width: 100%;
        background: #fff;
        height: 1.2rem;
        background: #F2F2F2;
        border-top: 0.01rem solid #ddd;
        overflow: hidden;
        transition: ease 0.5s all;
        position: absolute;
        left: 0rem;
        bottom: 0rem;
    }
    .y_emoji_18{
        height: 1.2rem;
        padding: 0rem 0.3rem 0rem 0.25rem;
        display: flex;
        justify-content: flex-start;
        align-content: center;
    }
    .y_emoji_19{
        width: 5.8rem;
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 0.2rem;
    }
    .y_emoji_19 input{
        width: 100%;
        border-radius: 0.1rem;
        background: #fff;
        height: 0.55rem;
        line-height: 0.55rem;
        border: none;
        font-size: 0.26rem;
        padding-left: 0.15rem;
    }
    .y_emoji_20{
        flex: 1;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        display: flex;
    }
    .y_emoji_21{
        width: 0.36rem;
        height: 0.36rem;
    }
    .y_emoji_21 img{
        width: 100%;
        object-fit: cover;
    }
    .y_emoji_22{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        overflow: hidden;
    }
    .y_emoji_17 .y_emoji_22{
        height: 0rem;
        transition: ease 0.5s all;
    }
    .y_emoji_17_active .y_emoji_22{
        height: 3.5rem;
        transition: ease 0.5s all;
    }
    .y_emoji_17_active{
        height: 4.7rem;
    }
    .y_emoji_24{
        width: 1rem;
        height: 1rem;
        background: #fff;
        border-radius: 0.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .y_emoji_24 img{
        max-width: 0.45rem;
    }
    .y_emoji_25{
        text-align: center;
        margin-top: 0.15rem;
    }
    .y_emoji_25 p{
        font-size: 0.24rem;
        color: #333;
    }
    .y_emoji_26 {
        overflow: scroll;
    }
    .y_emoji_17 .y_emoji_26{
        transition: ease 0.5s all;
        height: 0rem;
        padding: 0.2rem;
    }
    .y_emoji_17_active .y_emoji_26{
        height: calc(3.5rem - 0.4rem);
        transition: ease 0.5s all;
    }

    /* 招聘样式 */
    .y_push_progress_48{
        width: 100%;
    }
    .y_push_progress_49{
        width: 100%;
        text-align: center;
    }
    .y_push_progress_49 h2{
        font-size: 0.36rem;
        color: #333;
        margin-bottom: 0.35rem;
        line-height: 0.34rem;
        font-weight: 600;
    }
    .y_push_progress_50{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0.2rem;
    }
    .y_push_progress_50 h2{
        font-size: 0.28rem;
        color: #333;
        margin-right: 0.1rem;
        min-width: 1.35rem;
    }
    .y_push_progress_50 p{
        font-size: 0.28rem;
        color: #333;
    }
    .y_push_progress_51{
        flex: 1;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .y_push_progress_51 p{
        color: #666;
        font-size: 0.22rem;
        display: inline-block;
        padding: 0rem 0.15rem;
        line-height: 0.38rem;
        background: #F3F3F3;
        margin-right: 0.1rem;
    }
    .y_push_progress_52{
        padding: 0rem 0.2rem 0rem 0.3rem;
        background: #f3f3f3;
        display: flex;
        height: 1.4rem;
        justify-content: space-between;
        align-items: center;
        margin-top: 0.1rem;
    }
    .y_push_progress_53{
        flex: 1;
        margin-right: 0.3rem;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
    }
    .y_push_progress_54{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_progress_54 img{
        width: 0.3rem;
        height: 0.38rem;
        margin-right: 0.1rem;
    }
    .y_push_progress_54 h2{
        color: #333;
        font-size: 0.3rem;
        line-height: 0.38rem;
    }
    .y_push_progress_55{
        width: 100%;
        margin-top: 0.15rem;
    }
    .y_push_progress_55 p{
        font-size: 0.22rem;
        color: #888;
    }
    .y_push_progress_56 img{
        width: 0.12rem;
        object-fit: cover;
    }
    /* 招聘样式 */
    .y_emoji_16_zp{
        flex: 1;
        padding: 0.3rem 0.24rem 0.3rem 0.15rem;
    }
    .y_emoji_23 /deep/ .upload-demo .el-upload-list{
        display: none;
    }
    .y_name_change_1{
        width: 80%;
        height: 3rem;
        background: #fff;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 10;
        border-radius: 0.2rem;
    }
    .y_name_change_2{
        width: 100%;
        text-align: center;
        margin-top: 0.2rem;
    }
    .y_name_change_2 h2{
        font-size: 0.36rem;
        color: #333;
        font-weight: 600;
    }
    .y_name_change_3{
        width: 90%;
        margin: 0 auto;
        padding-top: 0.2rem;
        text-align: center;
    }
    .y_name_change_3 input{
        line-height: 0.8rem;
        font-size: 0.28rem;
        color: #333;
        border-radius: 0.1rem;
        width: 90%;
        border: 0.01rem solid #ccc;
        padding-left: 0.15rem;
        margin: 0 auto;
        display: inline-block;
    }
    .y_name_change_4{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.3rem;
    }
    .y_name_change_4 p{
        font-size: 0.26rem;
        color: #333;
        border-radius: 1rem;
        width: 2rem;
        text-align: center;
        line-height: 0.7rem;
        margin: 0rem 0.25rem;
    }
    .y_name_change_4 .y_name_change_5{
        background: #ccc;
    }
    .y_name_change_4 .y_name_change_6{
        background: #FECE0A;
        color: #fff;
    }

    .y_emoji_16_file{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .y_emoji_16_file img{
        width: 0.5rem;
        object-fit: contain;
        margin-right: 0.2rem;
    }
    .y_emoji_16_file p{
        font-size: 0.24rem;
        color: #333;
        /* white-space:nowrap; */
    }









    .y_display_opacity {
        z-index: -1;
        opacity: 0;
    }
    .y_display_none{
        display: none;
    }

</style>