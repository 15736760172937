<style scoped>

</style>
<template>
    <div class='y_index_1'>
        <div class='y_index_2' @click="tuisong_show()">如何推送？</div>
        <div class='y_index_2' @click="shoufei_show()">如何收费？</div>
    </div>
    <!-- 如何推送？ -->
    <div class="y_tuisong_1" :class="pop_tuisong == '0' ? 'y_display_none':'y_display_block'">
        <div class="y_tuisong_2">
          <div class="y_tuisong_3">
            <h2>如何推送？</h2>
          </div>
          <p v-html="business_how_to_push" class="y_tuisong_4"></p>
          <div class="y_tuisong_5">
            <p @click="tuisong_hide()">知道了</p>
          </div>
        </div>
    </div>
    <!-- 如何收费？ -->
    <div class="y_tuisong_1" :class="pop_shoufei == '0' ? 'y_display_none':'y_display_block'">
        <div class="y_tuisong_2">
          <div class="y_tuisong_3">
            <h2>如何收费？</h2>
          </div>
          <p v-html="business_how_to_charge" class="y_tuisong_4"></p>
          <div class="y_tuisong_5">
            <p @click="shoufei_hide()">知道了</p>
          </div>
        </div>
    </div>
    <!-- 公共弹窗   遮罩层 -->
    <div class='y_pop_masking' :class='pop_masking == 0 ? "":"y_masking_block"'></div>

</template>

<script>
import axios from 'axios'   // 接口
import {edit_adspace} from '../../api/request.js' // 商务端所有价格的信息
import {configuration_fields} from '../../api/request.js'   // 配置字段

    export default {
        data() {
            return {
                pop_tuisong:'0',
                pop_shoufei:'0',
                // 如何收费
                business_how_to_charge:'',
                // 如何推送
                business_how_to_push:'',
                pop_masking:'0',
            }
        },
        mounted(){
            // 配置字段
            configuration_fields({}).then((res)=>{
              console.log(res,'配置字段')
              let business_how_to_charge = res.data.business_how_to_charge    // 如何收费
              let business_how_to_push = res.data.business_how_to_push     // 如何推送
              this.business_how_to_charge = business_how_to_charge
              this.business_how_to_push = business_how_to_push
            })
        },
        methods: {
            // 如何推送
            tuisong_show(){
              this.pop_masking = '1'
              this.pop_tuisong = '1'
            },
            tuisong_hide(){
              this.pop_masking = '0'
              this.pop_tuisong = '0'
            },

            // 如何收费
            shoufei_show(){
              this.pop_masking = '1'
              this.pop_shoufei = '1'
            },
            shoufei_hide(){
              this.pop_masking = '0'
              this.pop_shoufei= '0'
            },
        },
    }
</script>
