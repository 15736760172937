<template>
  <div class="y_index">
      <!-- <div class='y_index_1'>
          <router-link class='y_index_2' to='/'>如何推送？</router-link>
          <router-link class='y_index_2' to='/'>如何收费？</router-link>
      </div> -->
      <push_charges></push_charges>
      <div class="y_recruit_1">
        <img src="../../../images/img_3.png" alt="">
      </div>

      <div class="y_topics_select_two_1">
          <div class="y_topics_select_two_2" :class="item.active == '0'? '':'y_topics_select_two_2_active'" v-for="item in top_list" :key="item.index" @click="topics_pop(item.index)">
              {{item.name}}
          </div>
      </div> 

      <!-- 推广 -->
      <div class='y_topics_switch_1'>
          <div class='y_promotion_1' :class="top_list[2].active == '1'? 'y_display_none':'y_display_block'">
              <div class='y_promotion_2'>
                  <div class="y_promotion_3" @click="pop_right_point_block_one">
                      <p>{{result_point_one}}</p>
                      <img src="../../../images/icon_13.png" alt="">
                  </div>
                  <div class="y_promotion_4"> 
                      <el-input type="textarea" :rows="2" :placeholder="top_list[0].active == '1'?'请输入推广内容':(top_list[1].active == '1'?'请输入招生内容':'')" v-model="textarea_type" :change="textarea_text()"></el-input>
                  </div>
                  <div class='y_recruit_12'>
                      <div class='y_recruit_13'>
                          <!-- <el-upload class="upload-demo" action="https://jsonplaceholder.typicode.com/posts/" :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileList" list-type="picture">
                              <div class='y_recruit_14'>
                                  <img src="../../../images/icon_14.png" alt="">
                              </div>
                          </el-upload> -->
                          <el-upload class="upload-demo" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :on-preview="handlePreview" :on-success="hand_success" :on-remove="handleRemove" :file-list="fileList" list-type="picture">
                              <div class='y_recruit_14'>
                                  <img src="../../../images/icon_14.png" alt="">
                              </div>
                          </el-upload>
                      </div>
                  </div>
              </div>
          </div> 
          <div :class="top_list[2].active == '0'? 'y_display_none':'y_display_block'">
             
          </div>
      </div>

      <!-- 添加投票 -->
      <div class="y_vote_pop_up_1" :class="vote_pop_up == '0'?'y_display_none':''">
        <div class="y_vote_pop_up_2">
          <h2>投票设置</h2> 
        </div>
        <div class="y_vote_pop_up_5" @click="vote_pop_up_hide">
          <img src="../../../images/icon_35.png" alt="">
        </div>
        <!-- <div class="y_vote_pop_up_3">
          <div class="y_vote_pop_up_6">
            <p>投票标题</p>
          </div>
          <el-input v-model="vote_input" placeholder="请输入"></el-input>
        </div> -->
        <div class="y_vote_pop_up_4">
          <div class="y_vote_pop_up_6">
            <p>投票选项</p>
          </div>
          <div class="y_vote_pop_up_7">
            <el-radio v-model="vote_radio" label="radio">单选</el-radio>
            <el-radio el-radio v-model="vote_radio" label="checkbox">多选</el-radio>
          </div>
        </div>
        <div class="y_vote_pop_up_8" ref="scrollDiv">
          <div class="y_vote_pop_up_9" :class="vote_option_list.is == '0'?'y_display_none':''" v-for="vote_option_list in vote_option_lists" :key="vote_option_list.index" >
            <div class="y_vote_pop_up_10" @click="vote_dele(vote_option_list.index)">
              <img src="../../../images/icon_99.png" alt="">
            </div>
            <div class="y_vote_pop_up_11">
              <h2>{{vote_option_list.name}}</h2>
              <el-input v-model="vote_option_list.input" placeholder="请输入"></el-input>
            </div>
          </div>
        </div>
        <div class="y_vote_pop_up_12" @click="vote_date">
          <div class="y_vote_pop_up_13">
            <img src="../../../images/icon_100.png" alt="">
          </div>
          <div class="y_vote_pop_up_14">
            <h2>添加选项</h2>
          </div>
        </div>
        <div class="y_vote_pop_up_15" @click="vote_pop_up_date">
          <p>确定</p>
        </div>
      </div>

      

      <!-- 公共弹窗   遮罩层 -->
      <div class='y_pop_masking' :class='pop_masking == 0 ? "":"y_masking_block"'></div>
      <!-- 地图弹窗 -->
      <div class="y_map_pop_1" :class='map_pop == 0?"y_display_opacity":""'>
        <div class="y_add_search_0">
          <div>
            <div class="y_add_search_1">
              <el-select v-model="keywords" filterable remote reserve-keyword placeholder="请输入关键词" :remote-method="remoteMethod" :loading="loading" :clearable="true" size="mini" @change="currentSelect" ref="select" @focus="clear" @hook:mounted="clear" @visible-change="clear">
                <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item" class="one-text">
                  <span style="float: left">{{ item.name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.district }}</span>
                </el-option>
              </el-select>
            </div>
            <div id="container" class="container y_add_search_2"></div>
          </div>
        </div>
        <div class="y_map_pop_2">
          <p class="y_map_pop_3" @click="map_cancel">取消</p>
          <p class="y_map_pop_4" @click="map_determine">确定</p>
        </div>
      </div>


      <!-- 顶部选择 类型弹窗 -->
      <div class='y_pop_eight_point' :class='pop_eight_danjia_one == "0"? "y_pop_eight_point_none" : "y_pop_eight_point_block"'>
        <van-popup v-model:show="showPicker" position="bottom">
          <van-picker :columns="columns_one" @confirm="onConfirm_eight_point_block_one" @cancel="showPicker_eight_point_none_one"  />
        </van-popup>
      </div>
      <!-- 底部垫高 -->
      <div class='y_recruit_two_dian'></div>
      <div class="y_promotion_foot_0" v-show="hideshow">
          <div class="y_promotion_foot_1">
              <div class="y_promotion_foot_2" @click="map_show">
                  <img src="../../../images/icon_102.png" :class="vote_add == '0'?'':'y_display_none'"  alt="">
                  <img src="../../../images/icon_25.png" :class="vote_add == '0'?'y_display_none':''" alt="">
                  <p>所在位置</p>
              </div>
              <div class="y_promotion_foot_2" @click="vote_pop_up_show()">
                  <img src="../../../images/icon_98.png" :class="vote_select == '0'?'':'y_display_none'" alt="">
                  <img src="../../../images/icon_26.png" :class="vote_select == '0'?'y_display_none':''" alt="">
                  <p>添加投票</p>
              </div>
              <div class="y_promotion_foot_2 y_promotion_5" @click="students_val()">
                  <div class="y_promotion_6" :class="students_is == 0? '':'y_promotion_6_active'">
                      <img src="../../../images/icon_20.png" alt="">
                      <img src="../../../images/icon_19.png" alt="">
                  </div>
                  <p>允许学生评论</p>
              </div>
          </div> 
          <div class='y_recruit_foot_1'>
              <div class='y_recruit_foot_2'>
                  <div class='y_recruit_foot_3' @click="previous_step">
                      <p>返回</p>
                  </div>
                  <!-- <div class='y_recruit_foot_4'>
                      <div class='y_recruit_foot_5'>
                          <img src="../../../images/icon_15.png" alt="">
                      </div>
                      <div class='y_recruit_foot_6'>预览</div>
                  </div> -->
              </div>
              <div class='y_recruit_foot_7' @click="select_three()">
                  <p>下一步(2/3)</p>
              </div>
          </div>
      </div>
      
  </div>
  
  <kefu></kefu>
</template>
<style scoped>
  /* @import url(./topics_select_two_style.css); */
  .y_topics_select_two_1{
      height: 1.2rem;
      border-bottom: 0.01rem solid #F3F3F3;
      padding: 0rem 0.25rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;
  }
  .y_topics_select_two_2{
      flex: 1;
      margin-right: 0.2rem;
      background: #F6F6F6;
      border-radius: 0.1rem;
      line-height: 0.7rem;
      text-align: center;
      font-size: 0.3rem;
      color: #333;
      height: 0.7rem;
      transition: ease 0.5s all;
  }
  .y_topics_select_two_2_active{
      background: #FECE0A;
      transition: ease 0.5s all;
  }
  .y_topics_select_two_1 .y_topics_select_two_2:nth-last-child(1){
      margin-right: 0rem;
  }
  
  .y_recruit_12{
    padding: 0.3rem 0rem 0.2rem;
  }
  
  /* 添加投票 */
  .y_vote_pop_up_1{
    width: 80%;
    height: 7.5rem;
    background: #fff;
    border-radius: 0.1rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 11;
    position: fixed;
    overflow: hidden;
    padding: 0rem 0.3rem;
  }
  .y_vote_pop_up_2{
    height: 0.5rem;
    width: 100%;
    text-align: center;
    margin-top: 0.3rem;
  }
  .y_vote_pop_up_2 h2{
    font-size: 0.35rem;
    font-weight: 600;
    width: 100%;
    text-align: center;
    line-height: 0.5rem;
  }
  .y_vote_pop_up_5{
    width: 0.4rem;
    height: 0.4rem;
    position: absolute;
    top: 0.3rem;
    right: 0.3rem;
  }
  .y_vote_pop_up_3{
    margin-top: 0.2rem;
    height: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .y_vote_pop_up_3 /deep/ input{
    font-size: 0.28rem;
    height: 1rem;
    line-height: 1rem;
    border-radius: 0rem;
    border: none;
    text-align: right;
    padding: 0rem;
  }
  .y_vote_pop_up_5 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .y_vote_pop_up_6{
    min-width: 1.5rem;
  }
  .y_vote_pop_up_6 p{
    font-size: 0.28rem;
  }
  .y_vote_pop_up_4{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 1rem;
    overflow: hidden;
    position: relative;
  }
  .y_vote_pop_up_7{
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    display: flex;
  } 
  .y_vote_pop_up_7 /deep/ .el-radio__inner{
    width: 0.35rem;
    height: 0.35rem;
  }
  .y_vote_pop_up_7 /deep/ .el-radio{
    margin-right: 0.2rem;
  }
  .y_vote_pop_up_7 /deep/ .el-radio__label{
    font-size: 0.28rem;
    padding-left: 0.1rem;
  }
  .y_vote_pop_up_7 /deep/ .el-radio__input.is-checked .el-radio__inner{
    border-color: #FECE0A;
    background: #FECE0A;
  }
  .y_vote_pop_up_7 /deep/ .el-radio__input.is-checked+.el-radio__label{
    color: #FECE0A;
  }
  .y_vote_pop_up_8{
    width: 100%;
    max-height: 3rem;
    overflow: scroll;
  }
  .y_vote_pop_up_9{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 1rem;
  }
  .y_vote_pop_up_10 {
    width: 0.7rem;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .y_vote_pop_up_10 img{
    width: 0.4rem;
    height: 0.4rem;
    object-fit: cover;
  }
  .y_vote_pop_up_11{
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
  }
  .y_vote_pop_up_11 h2{
    font-size: 0.3rem;
    color:#333;
    line-height: 1rem;
  }
  .y_vote_pop_up_11 p{
    font-size: 0.3rem;
    color: #aaa;
    line-height: 1rem;
    margin-left: 0.15rem;
    border-bottom: 0.01rem solid #EEEEEE;
    flex: 1;
  }
  .y_vote_pop_up_11 /deep/ input{
    flex: 1;
    border:none;
    border-radius: 0rem;
    padding: 0rem 0rem 0rem 0.2rem;
    height: 1rem;
    line-height: 1rem;
    color: #aaa;
    font-size: 0.3rem;
    border-bottom: 0.01rem solid #EEEEEE;
  }
  .y_vote_pop_up_12{
    height: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .y_vote_pop_up_13{
    width: 0.7rem;
    
  }
  .y_vote_pop_up_13 img{
    width: 0.4rem;
    height: 0.4rem;
    object-fit: cover;
  }
  .y_vote_pop_up_14{
    flex:1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .y_vote_pop_up_14 h2{
    color:#FECE0A;
    font-size: 0.3rem;
    width: 100%;
    padding-left: 0.2rem;
  }
  .y_vote_pop_up_15{
    width: 100%;
    height: 1rem;
    margin-top: 0.2rem;
  }
  .y_vote_pop_up_15 p{
    width: 100%;
    text-align: center;
    background: #FECE0A;
    color:#fff;
    font-size: 0.36rem;
    height: 1rem;
    line-height: 1rem;
  }

  /* 添加投票 */
  .y_display_none{
    display: none;
  }
</style>

<script>
  import { Dialog } from "vant";
  import { ref } from 'vue';
  import kefu from '../../components/kefu/kefu'
  import push_charges from '../../components/push_charges/push_charges'

  import axios from 'axios'   // 接口
  import {all_prices} from '../../api/request.js'   // 商务端所有价格的信息

  import {blog_info_info} from '../../api/request.js'   // 帖子详情,信息类推广
  import AMapLoader from "@amap/amap-jsapi-loader";
  window._AMapSecurityConfig = {
    // 安全密钥
    securityJsCode: "6379b3cce15f6de8d9a891f8b0033fd8",
  };

 export default {
  data(){
    return {
      docmHeight: document.documentElement.clientHeight ||document.body.clientHeight,
      showHeight: document.documentElement.clientHeight ||document.body.clientHeight,
      hideshow:true,  //显示或者隐藏footer

      token:'',
      pop_masking:'0',   // 公共弹窗 遮罩层
      pop_eight_danjia:'0',
      result_point_one:'选取推广类型',
      textarea_type: '',    // 多行输入框
      students_is:'0', // 允许学生评论 是否显示
      top_list:[
          {
              index:'0',
              name:'推广',
              active:'1',
          },
          {
              index:'1',
              name:'招生',
              active:'0',
          },
          {
              index:'2',
              name:'招聘',
              active:'0',
          },
      ],
      top_select_value:"0",  // 顶部选择值

      list_select:'',  // 话题推送选择列表
      list_select_id_list:'', // 推送选择列表 id数组
      push_price:'',   // 类型列表
      result_point_one_id:'', // 类型选中id
      fileList: [],
      fileList_list:[],
      vote_input:'',   // 添加投票input
      vote_radio:'',   // 单选
      vote_option_lists:[{index:1,name:'A',text:'选项',is:'1',input:''},
                        {index:2,name:'B',text:'选项',is:'1',input:''},
                        {index:3,name:'C',text:'选项',is:'0',input:''},
                        {index:4,name:'D',text:'选项',is:'0',input:''},
                        {index:5,name:'E',text:'选项',is:'0',input:''},
                        {index:6,name:'F',text:'选项',is:'0',input:''},
                        {index:7,name:'G',text:'选项',is:'0',input:''},
                        {index:8,name:'H',text:'选项',is:'0',input:''},
                        {index:9,name:'I',text:'选项',is:'0',input:''},
                        {index:10,name:'J',text:'选项',is:'0',input:''},
                        {index:11,name:'K',text:'选项',is:'0',input:''},
                        {index:12,name:'L',text:'选项',is:'0',input:''},
                        {index:13,name:'M',text:'选项',is:'0',input:''},
                        {index:14,name:'N',text:'选项',is:'0',input:''},
                        {index:15,name:'O',text:'选项',is:'0',input:''},
                        {index:16,name:'P',text:'选项',is:'0',input:''},
                        {index:17,name:'Q',text:'选项',is:'0',input:''},
                        {index:18,name:'R',text:'选项',is:'0',input:''},
                        {index:19,name:'S',text:'选项',is:'0',input:''},
                        {index:20,name:'T',text:'选项',is:'0',input:''},
                        {index:21,name:'U',text:'选项',is:'0',input:''},
                        {index:22,name:'V',text:'选项',is:'0',input:''},
                        {index:23,name:'W',text:'选项',is:'0',input:''},
                        {index:24,name:'X',text:'选项',is:'0',input:''},
                        {index:25,name:'Y',text:'选项',is:'0',input:''},
                        {index:26,name:'Z',text:'选项',is:'0',input:''},
      ],
      vote_option_num:'2',
      vote_pop_up:'0', // 判断弹窗
      vote_input_value_list:'', // 投票选项标题 数组
      vote_select:'0', // 投票是否选中
      vote_add:'0',    // 地址是否选中
      type_name:'',  // 推送类型

      index_index:'', // 帖子跳转招聘选择 index
      id_index:'',  // 判断话题，小组，活动 1 = 话题, 2 = 小组, 3 = 活动,

      adspace_id:'0',       // 编辑 进入传id
      promotion_value:'0',  // 进入传value  0代表正常进入，1为待推送进入
      info_ids:'', 
      from_table:'',    // 帖子还是招聘 帖子=blog，招聘=recruit

      // 地图实例
      map: null,
      // 标记点
      marker: "",
      // 地址逆解析
      geoCoder: null,
      // 搜索提示
      AutoComplete: null,
      // 搜索关键字
      keywords: "",
      // 位置信息
      form: {
        lng: "",
        lat: "",
        address: "",
        adcode: "", //地区编码
      },
      // 搜索节流阀
      loading: false,
      // 搜索提示信息
      options: [],

      map_pop:'0', // 高德地图显示与隐藏 0隐藏 1显示
      // 经度
      longitude_value:'',
      // 纬度
      latitude_value:'',
      bj_news:'0', // 编辑新推送 0为正常进入 1是编辑新推送
    }
  },
  components:{
      kefu,
      push_charges,
  },
  created(){
      // 获取页面传参 推送类型
      this.type_name = this.$route.query.name;
      this.index_index = this.$route.query.index_index
      this.id_index = this.$route.query.id_index
      // 获取页面传参 推送类型
      if(this.$route.query.adspace_id !== undefined){
          this.adspace_id = this.$route.query.adspace_id
          this.promotion_value = this.$route.query.promotion_value
          this.info_ids = this.$route.query.info_ids
          this.type_name = this.$route.query.name
          this.id_index = this.$route.query.id_index
          this.from_table = this.$route.query.from_table
          this.bj_news = this.$route.query.bj_news
      }
  },
  watch:{
    '$route'(val,from){
      // 帖子传参
      let top_list = this.top_list
      let index_index = val.query.index_index
      let id_index = val.query.id_index
      for(let i=0;i<top_list.length;i++){
        top_list[i].active = '0'
        if(top_list[i].index == index_index){
          top_list[i].active = '1'
        }
      }

      let list_select = JSON.parse(sessionStorage.getItem('list_select'));
      let list_select_id_list = []
      this.list_select = list_select

      if(id_index == '1'){
        for(let i=0;i<list_select.length;i++){
          if(list_select[i].select == '1'){
            list_select_id_list.push(list_select[i].topic_id)
          }
        }
      }else if(id_index == '2'){
        for(let i=0;i<list_select.length;i++){
          if(list_select[i].select == '1'){
            list_select_id_list.push(list_select[i].group_id)
          }
        }
      }else if(id_index == '3'){
        for(let i=0;i<list_select.length;i++){
          if(list_select[i].select == '1'){
            list_select_id_list.push(list_select[i].activity_id)
          }
        }
      }
      this.list_select_id_list = list_select_id_list  // 推送选择列表 id数组
      console.log(this.list_select_id_list,list_select_id_list,'------')
      this.top_list = top_list
      this.index_index = index_index
      this.id_index = id_index
      console.log(this.type_name,this.top_list,index_index,val)
    }
  },
  mounted(){
    let token = this.$cookies.get('token')
        if(token == null){
            this.$message({
                message: '请先登录！',
                duration:'2000'
            })
            var that = this;
            setTimeout(function () {
                that.$router.push({path:'/login'}) 
            },2500);
        }
      this.token = token
      this.initMap();
      setTimeout(() => {
        const { select } = this.$refs
        const input = select.$el.querySelector('.el-input__inner')
        input.removeAttribute('readonly')
      }, 300)
      //监听事件
      window.onresize = ()=>{
        return(()=>{
            this.showHeight = document.documentElement.clientHeight || document.body.clientHeight;
        })()
      }

      let list_select = JSON.parse(sessionStorage.getItem('list_select'));
      let list_select_id_list = []
      this.list_select = list_select

      let id_index = this.id_index

      if(id_index == '1'){
        for(let i=0;i<list_select.length;i++){
          if(list_select[i].select == '1'){
            list_select_id_list.push(list_select[i].topic_id)
          }
        }
      }else if(id_index == '2'){
        for(let i=0;i<list_select.length;i++){
          if(list_select[i].select == '1'){
            list_select_id_list.push(list_select[i].group_id)
          }
        }
      }else if(id_index == '3'){
        for(let i=0;i<list_select.length;i++){
          if(list_select[i].select == '1'){
            list_select_id_list.push(list_select[i].activity_id)
          }
        }
      }
      this.list_select_id_list = list_select_id_list.toString(list_select_id_list)  // 推送选择列表 id数组
      console.log(this.list_select_id_list,list_select,id_index,'-----')
      // 编辑进入
      let adspace_id = this.adspace_id
      let promotion_value = this.promotion_value
      let info_ids = this.info_ids
      let index_index = this.index_index
      if(promotion_value == '1'){
        blog_info_info({token:token,blog_id:adspace_id}).then((res) =>{
          console.log(res,'id选中')
          this.result_point_one_id = res.blog.push_price_id
          this.result_point_one = res.blog.push_price_title
          this.textarea_type = res.blog.content
          this.longitude_value = res.blog.longitude
          this.latitude_value = res.blog.latitude
          this.vote_add = '1'
          // 图片
          if( res.blog.gallery !== false){
            let fileList = res.blog.gallery
            for(let i=0;i<fileList.length;i++){
                fileList[i] = {url:''+fileList[i]}
            }
            console.log(fileList)
            this.fileList = fileList
            this.fileList_list = fileList
          }

          this.students_is = res.blog.is_allow_comment
          let vote_option_lists = this.vote_option_lists // 原数组
          let blog_vote_option = res.blog_vote_option
          if(blog_vote_option !== ''){
            this.vote_select = '1'
          }
          let vote_input_value_list = []
          for(let i=0;i<blog_vote_option.length;i++){
            vote_option_lists[i].is = '1'
            vote_option_lists[i].input = blog_vote_option[i].title
            vote_input_value_list.push(vote_option_lists[i].input)
          }
          this.vote_option_num = blog_vote_option.length
          this.vote_option_lists = vote_option_lists
          if(res.blog_vote !== null){
            this.vote_radio = res.blog_vote.vote_type_text
          }
          this.vote_input_value_list = vote_input_value_list
          // this.list_select_id_list = res.blog.push_relative_ids


          let columns_one = []
          let price_type = res.blog.price_type
   
          if(price_type == 'push'){
            this.top_select_value = '0'
            this.top_list[0].active = '1'
          }else if(price_type == 'student'){
            this.top_select_value = '1'
            this.top_list[0].active = '0'
            this.top_list[1].active = '1'
          }else if(price_type == 'recruit'){
            this.top_select_value = '2'
            this.top_list[0].active = '0'
            this.top_list[2].active = '1'
          }

          console.log(this.top_select_value)
          all_prices({price_type:price_type}).then((res) =>{
              console.log(res,'选取推广类型1')
              let push_price = res.push_price
              for(let i=0;i<push_price.length;i++){
                  columns_one.push(push_price[i].title)
              }
              this.push_price = push_price
              this.columns_one = columns_one
              console.log(this.columns_one)
          })
        })
      }else{
        let columns_one = []
        all_prices({price_type:'push'}).then((res) =>{
            console.log(res,'选取推广类型2')
            let push_price = res.push_price
            for(let i=0;i<push_price.length;i++){
                columns_one.push(push_price[i].title)
            }
            this.push_price = push_price
            this.columns_one = columns_one
            console.log(this.columns_one)
        })
      }

  },
  methods: {
      clear(async) {
        console.log(async)
              this.$nextTick(() => {
              // ios 手机有延迟问题
              setTimeout(() => {
                const { select } = this.$refs
                const input = select.$el.querySelector('.el-input__inner')
                input.removeAttribute('readonly')
              }, 400)
        })
      },
      
      initMap() {
        AMapLoader.load({
          // 你申请的Key
          key: "f8a1bbd8ef1b14c4e71a5b027835e6f3",
          version: "2.0",
          // 需要用到的插件
          plugins: ["AMap.Geocoder", "AMap.AutoComplete"],
        })
          .then((AMap) => {
            this.map = new AMap.Map("container", {
              viewMode: "3D", //是否为3D地图模式
              zoom: 9, //初始化地图级别
              center: [121.549792, 29.868388], //初始化地图中心点位置
            });
            //地址逆解析插件
            this.geoCoder = new AMap.Geocoder({
              city: "010", //城市设为北京，默认：“全国”
              radius: 500, //范围，默认：500
            });
            // 搜索提示插件
            this.AutoComplete = new AMap.AutoComplete({ city: "全国" });
            //点击获取经纬度;
            this.map.on("click", (e) => {
              // 获取经纬度
              this.form.lng = e.lnglat.lng;
              this.form.lat = e.lnglat.lat;
              // 清除点
              this.removeMarker();
              // 标记点
              this.setMapMarker();
            });
          })
          .catch((err) => {
            // 错误
            console.log(err);
          });
      },
      // 标记点
      setMapMarker() {
        // 自动适应显示想显示的范围区域
        this.map.setFitView();
        this.marker = new AMap.Marker({
          map: this.map,
          position: [this.form.lng, this.form.lat],
        });
      
        // 逆解析地址
        this.toGeoCoder();
        this.map.setFitView();
        this.map.add(this.marker);
      
      
      },
      // 清除点
      removeMarker() {
        if (this.marker) {
          this.map.remove(this.marker);
        }
      },
      // 逆解析地址
      toGeoCoder() {
        let lnglat = [this.form.lng, this.form.lat];
        this.geoCoder.getAddress(lnglat, (status, result) => {
          if (status === "complete" && result.regeocode) {
            this.form.address = result.regeocode.formattedAddress;
          }
        });
      
      },
      // 搜索
      remoteMethod(query) {
        console.log(query);
        if (query !== "") {
          this.loading = true;
          setTimeout(() => {
            this.loading = false;
            this.AutoComplete.search(query, (status, result) => {
              this.options = result.tips;
            });
          }, 200);
        } else {
          this.options = [];
        }
      },
      // 选中提示
      currentSelect(val) {
        console.log(val)
        // 清空时不执行后面代码
        if (!val) {
          return;
        }
        this.form = {
          lng: val.location.lng,
          lat: val.location.lat,
          address: val.district + val.address,
          adcode: val.adcode,
        };
        this.keywords = val.name;
      
        // 清除点
        this.removeMarker();
        // 标记点
        this.setMapMarker();
      },
      clear(async) {
        this.$nextTick(() => {
              if (!async) {
                // ios 手机有延迟问题
                setTimeout(() => {
                  const { select } = this.$refs
                  const input = select.$el.querySelector('.el-input__inner')
                  input.removeAttribute('readonly')
                }, 200)
            }
          })
      },
      // 地图显示
      map_show(){
        this.map_pop = '1'
        // this.initMap();
      },
      // 地图取消
      map_cancel(){
        this.map_pop = '0'
      },
      // 地图确定
      map_determine(){
        let lng = this.form.lng
        let lat = this.form.lat
        if(lng == ''){
          this.$message({
            message:'请选择坐标点',
            duration:'2000'
          })
        }else{
          this.vote_add = '1'
          this.longitude_value = lng
          this.latitude_value = lat
          this.map_pop = '0'
          console.log(this.longitude_value,this.latitude_value)
        }
        
      },
      // 选取推广类型
      pop_right_point_block_one(e){
          this.pop_eight_danjia_one = '1'
          this.pop_masking = '1'
      },
      // 选取推广类型
      pop_right_point_block(e){
          this.pop_eight_danjia = '1'
          this.pop_masking = '1'
      },
      // textarea 获取输入的值
      textarea_text(){

      },

      // 图片上传
      // 删除图片
      handleRemove(file, fileList) {
        console.log(file, fileList);
        let promotion_value = this.promotion_value
        if(promotion_value == '0'){
          let fileList_list = []
          for(let i=0;i<fileList.length;i++){
            fileList_list.push(fileList[i].response.img)
          }
          this.fileList_list = fileList_list 
          console.log(this.fileList_list,'删除图片')
        }else if(promotion_value == '1'){
          let fileList_list = []
          for(let i=0;i<fileList.length;i++){
              fileList_list.push(fileList[i])
          }
          this.fileList_list = fileList_list 
          console.log(this.fileList_list,'删除图片')
        }
      },
      // 上传成功
      hand_success(file,fileList){
        console.log(file,fileList)
        let promotion_value = this.promotion_value
        console.log(promotion_value)
        if(promotion_value == '0'){
          let fileList_list = this.fileList_list
          fileList_list.push(fileList.response.img)
          this.fileList_list = fileList_list
          console.log(this.fileList_list,'上传成功')
        }else if(promotion_value == '1'){
          console.log(file,fileList)
          let fileList_list = this.fileList_list
          fileList_list.push({url:''+file.img})
          this.fileList_list = fileList_list
          console.log(this.fileList_list,'上传成功')
        }

      },

      handlePreview(file,fileList) {
        console.log(file,fileList);
      },
      // 允许学生评论
      students_val(e){
          let stu_is = this.students_is
          if(stu_is == '0'){
              this.students_is = '1'
          }else{
              this.students_is = '0'
          }
      },
      
      // (推广，招生，招聘)选择
      topics_pop(e){
          let list = this.top_list
          console.log(e)
          for(let i=0;i<list.length;i++){
              list[i].active = '0'
              if(list[i].index == e){
                  list[i].active = '1'
              }
          }
          this.top_select_value = e
          this.top_list = list

          let result_point_one = this.result_point_one
          if(e == '0'){
            this.result_point_one = '选取推广类型'
            let columns_one = []
            all_prices({price_type:'push'}).then((res) =>{
              console.log(res,'选取推广类型')
              let push_price = res.push_price
              for(let i=0;i<push_price.length;i++){
                columns_one.push(push_price[i].title)
              }
              this.push_price = push_price
              this.columns_one = columns_one
              console.log(this.columns_one)
            })
          }else if(e == '1'){
            this.result_point_one = '选取招生类型'
            let columns_one = []
            all_prices({price_type:'student'}).then((res) =>{
              console.log(res,'选取招生类型')
              let push_price = res.push_price
              for(let i=0;i<push_price.length;i++){
                columns_one.push(push_price[i].title)
              }
              this.push_price = push_price
              this.columns_one = columns_one
              console.log(this.columns_one)
            })
          }else if(e == '2'){
            this.$router.push({
                path:'/recruit_index',
                query: {
                  name: this.type_name,
                  promotion_value:'1',
                }
            })
          }
          this.result_point_one_id = ''
      },
    // 投票添加选项
    vote_date(){
      let vote_option_num = this.vote_option_num*1 + 1*1
      let vote_option_lists = this.vote_option_lists

      if(vote_option_num > 26){
        this.$message({
            message: '以达到添加上线！',
            duration:'2000'
        })

        return
      }
      for(let i=0;i<vote_option_lists.length;i++){
        if(vote_option_lists[i].index <= vote_option_num){
          vote_option_lists[i].is = '1'
        }
      }
      this.vote_option_num = vote_option_num
      this.vote_option_lists = vote_option_lists
      console.log(vote_option_num,vote_option_lists,this.vote_option_lists)

      let scrollElem = this.$refs.scrollDiv; // 对应ref的命名
      setTimeout(function(){
        scrollElem.scrollTo({ top:scrollElem.scrollHeight , behavior: 'smooth' });
      },100)
    },
    //投票删除
    vote_dele(index){
      Dialog.confirm({
        message:
          '确定删除？',
      })
      
      .then(() => {
        let that = this
        let vote_option_lists = that.vote_option_lists
        let vote_option_num = that.vote_option_num*1 - 1*1

        let vote_dele_list = []
        for(let i=0;i<vote_option_lists.length;i++){
          vote_option_lists[i].is = '0'

          if(vote_option_lists[i].index !== index){
            vote_dele_list.push(vote_option_lists[i].input)
          }
          if(vote_option_lists[i].index <= vote_option_num){
            vote_option_lists[i].is = '1'
          }
          if(vote_option_lists[i].index == '26'){
            vote_option_lists[i].input = ''
          }
        }
        that.vote_dele_list = vote_dele_list
        let vote_dele_list_arr = that.vote_dele_list
        console.log(vote_dele_list_arr,that.vote_dele_list)
        for(let i=0;i<vote_dele_list_arr.length;i++){
          vote_option_lists[i].input = vote_dele_list_arr[i]
        }

        that.vote_option_num = vote_option_num
        that.vote_option_lists = vote_option_lists
        console.log(that.vote_option_num,that.vote_option_lists)
      })
      .catch(() => {
        
      });
    },
    // 投票添加 显示 
    vote_pop_up_show(){
      this.vote_pop_up = '1'
      this.pop_masking = '1'
    },
    // 投票添加 隐藏
    vote_pop_up_hide(){
      this.vote_pop_up = '0'
      this.pop_masking = '0'
    },
    // 投票添加 确定
    vote_pop_up_date(){
      let vote_radio = this.vote_radio
      let vote_option_lists = this.vote_option_lists
      let vote_input_value_list = []
      if(vote_radio == ''){
        this.$message({
            message: '请选择投票选项',
            duration:'2000'
        })
        return
      }
      for(let i=0;i<vote_option_lists.length;i++){
        if(vote_option_lists[0].is == '0'){
          this.$message({
              message: '请至少添加一个选项！',
              duration:'2000'
          })
          return
        }
        if(vote_option_lists[i].is == '1'){
          if(vote_option_lists[i].input == ''){
            vote_input_value_list = []
            this.$message({
                message: '请输入选项！',
                duration:'2000'
            })
            return
          }else{
            vote_input_value_list.push(vote_option_lists[i].input)
          }
        }
      }
      this.vote_input_value_list = vote_input_value_list
      this.vote_pop_up = '0'
      this.pop_masking = '0'
      this.vote_select = '1'
    },
    // 上一步
    previous_step(){
      this.$router.push({
          path:'/topics_push',
          query: {
              name: this.type_name,
              index_index:this.id_index
          }
      })
    },
    // 跳转 话题推送3
    select_three(){
      let top_select_value = this.top_select_value  // 顶部选择值 0=推广 1=招生 2=招聘 招聘现在跳到招聘页
      let price_type = '' // 顶部选择值 传
      let longitude_value = this.longitude_value    // 经度
      let latitude_value = this.latitude_value      // 纬度
      let bj_news = this.bj_news  // 新增编辑
      if(top_select_value == '0'){
        price_type = 'push'
      }else if(top_select_value == '1'){
        price_type = 'student'
      }

      let push_price = this.push_price  // 类型列表
      let result_point_one = this.result_point_one  // 类型选中的文字
      for(let i=0;i<push_price.length;i++){
        if(push_price[i].title == result_point_one){
          this.result_point_one_id = push_price[i].id
        }
      }
      let result_point_one_id = this.result_point_one_id // 类型选中id
      // if(result_point_one_id == ''){
      //   this.$message({
      //       message: '选择类型',
      //       duration:'2000'
      //   })
      //   return
      // }
      let textarea_type = this.textarea_type  // 帖子内容
      // if(textarea_type == ''){
      //   this.$message({
      //       message: '请输入帖子内容！',
      //       duration:'2000'
      //   })
      //   return
      // }
      // if(longitude_value == '' && latitude_value == ''){
      //   this.$message({
      //       message: '请选择位置！',
      //       duration:'2000'
      //   })
      //   return
      // }
      let students_is = this.students_is  // 允许学生评论 是否显示
      let vote_select = this.vote_select  // 投票是否选中
      let vote_radio = this.vote_radio  // 投票类型
      let vote_input_value_list = this.vote_input_value_list  // 投票选项标题 数组

      let promotion_value = this.promotion_value
      

      let type_name = this.type_name // 推送类型
      let list_select_id_list = this.list_select_id_list // 推送选择列表 id数组
      if(promotion_value == '0'){
        let fileList_list = this.fileList_list  // 多图列表
        if(fileList_list == []){
          this.$message({
              message: '请添加图片',
              duration:'2000'
          })
          return
        }
        let posting_arr = {blog_id:'0',content:textarea_type,price_type:price_type,push_price_id:result_point_one_id,is_allow_comment:students_is,vote_type_text:vote_radio,vote_title:vote_input_value_list,gallery:fileList_list,push_type_text:type_name,info_ids:list_select_id_list,work_place_longitude:longitude_value,work_place_latitude:latitude_value}
        let dd = JSON.stringify(posting_arr)
        sessionStorage.setItem("posting_arr",dd)
        this.$router.push({
          path:'/topics_select_three',
          
        })
      }else if(promotion_value == '1'){
        var reg1 = new RegExp("")
        let img_list = ''
        let business_gallery2 = this.fileList_list
        for(let i=0;i<business_gallery2.length;i++){
          img_list = img_list +','+ business_gallery2[i].url.replace(reg1,"");
        }
        business_gallery2 = img_list.substr(1,img_list.length)
        let business_gallery3 = business_gallery2.split(',')
        let posting_arr = {blog_id:'0',content:textarea_type,price_type:price_type,push_price_id:result_point_one_id,is_allow_comment:students_is,vote_type_text:vote_radio,vote_title:vote_input_value_list,gallery:business_gallery3,push_type_text:type_name,info_ids:list_select_id_list,work_place_longitude:longitude_value,work_place_latitude:latitude_value}
        let dd = JSON.stringify(posting_arr)
        sessionStorage.setItem("posting_arr",dd)
        this.$router.push({
          path:'/topics_select_three',
          query: {
            name: this.type_name,
            id_index:this.index_index,
            adspace_id:this.adspace_id,
            promotion_value:this.promotion_value,
            info_ids:this.info_ids,
            from_table:this.from_table,
            bj_news:this.bj_news,
          }
        })
      }

      
    }


  },
  
  setup() {
      // 顶部选择 类型弹窗
      const result_point_one = ref('选取推广类型');
      const pop_eight_danjia_one = ref('0');
      const pop_masking = ref('0');
      const columns_one = ['选取推广类型', '选取招生类型'];
      const onConfirm_eight_point_block_one = (value) => {
          result_point_one.value = value;
          pop_eight_danjia_one.value = '0'
          pop_masking.value = '0'
          console.log(result_point_one.value,value)
      };
      const showPicker_eight_point_none_one = (value) => {
          console.log('quxiao')
          pop_masking.value = '0'
          pop_eight_danjia_one.value = '0'
      };

      return {
          result_point_one,
          columns_one,
          onConfirm_eight_point_block_one,
          showPicker_eight_point_none_one,
          pop_eight_danjia_one,
          pop_masking,

      };
  },
}
</script>