<template>
    <div class="y_push_date_tg_1">
        <div class="y_push_date_tg_2">
            <div class="y_push_date_tg_3">
                <p>余额<span>{{ available_balance }}</span>元</p>
            </div>
            <div class="y_push_date_tg_4">
                <p @click="showDialog(true)">充值余额</p>
            </div>
        </div>

        <div class="y_push_date_tg_5">
            <div class="y_push_date_tg_6">
                <div class="y_push_date_tg_7">
                    <p>给每人最多推送</p>
                    <h2>{{blog_list.max_push_number}}次</h2>
                </div>
                <div class="y_push_date_tg_8">
                    <p @click="cs_pop_show()">+次数</p>
                </div>
            </div>
            <div class="y_push_date_tg_9">
                <p>已有<span>{{adspace_list.view_number_business}}</span>人浏览，人均浏览次数<span>{{(adspace_list.view_number_total_business/adspace_list.view_number_business).toFixed(0)}}</span>次</p>
            </div>
            <div class="y_push_date_tg_9">
                <p>推送时间:{{blog_list.push_starttime}} ~ {{blog_list.push_endtime}}</p>
            </div>
            <div class="y_push_date_tg_6">
                <div class="y_push_date_tg_7">
                    <p>推送倒计时：</p>
                    <h2><span>{{date_day}}</span>天<span>{{date_hour}}</span>时<span>{{date_minute}}</span>分<span>{{date_second}}</span>秒</h2>
                </div>
                <div class="y_push_date_tg_8">
                    <p @click="djs_show()">+时</p>
                </div>
            </div>

        </div>
        <div class="y_push_date_tg_10">
            <div class="y_push_date_tg_12">
                <p>单次曝光</p>
                <p>已完成/预设总曝光量</p>
                <p>已扣费/预算</p>
            </div>
        </div>
        <div class="y_push_date_tg_13">
            <div class="y_push_date_tg_14">
                <div class="y_push_date_tg_15">
                    <div class="y_push_date_tg_16">
                        <h2>{{cost_info_list.exposure_price}}元</h2>
                    </div>
                </div>
                <div class="y_push_date_tg_15">
                    <div class="y_push_date_tg_16">
                        <h2>{{cost_info_list.exposure_number - cost_info_list.left_exposure_number}}/{{cost_info_list.exposure_number}}</h2>
                    </div>
                    <div class="y_push_date_tg_17">
                        <p>增加曝光量</p>
                        <div class="y_push_date_tg_18">
                            <el-input v-model="local_num" placeholder="请输入"></el-input>
                        </div>
                    </div>
                </div>
                <div class="y_push_date_tg_15">
                    <div class="y_push_date_tg_16 y_push_date_tg_16_color">
                        <h2>{{(cost_info_list.exposure_number - cost_info_list.left_exposure_number)*cost_info_list.exposure_price}}元/{{(cost_info_list.exposure_number*cost_info_list.exposure_price).toFixed(2)}}元</h2>
                    </div>
                    <div class="y_push_date_tg_17">
                        <p>新增预算</p>
                        <div class="y_push_date_tg_18">
                            <p>{{(cost_info_list.exposure_price*local_num*1).toFixed(2)}}元</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="y_push_date_tg_19 y_push_date_zp_3"></div>
        <div class="y_push_date_tg_20 y_push_date_zp_2">
            <div class="y_push_date_tg_21">
                <div class="y_push_date_tg_22">
                    <p>新增总曝光量<span>{{(cost_info_list.exposure_price*local_num*1).toFixed(2)}}</span>元，余额不足，<span @click="showDialog(true)">点击充值</span></p>
                </div>
                <div class="y_push_date_tg_24 y_number_places_4">
                    <h2 class="y_number_places_3" @click="back_to">返回</h2>
                    <p class="y_number_places_5" @click="submit">确定</p>
                </div>
            </div>
        </div>

        <!-- 公共弹窗 阴影-->
        <div class='y_comm_shadows' :class="comm_shadows_value == '1'?'y_comm_shadows_block':''"></div>
        <!-- 倒计时 -->
        <div class="y_push_date_zp_11" :class="djs_pop_value == '0'?'':'y_push_date_zp_11_active'">
            <div class="y_push_date_zp_12">
                <h2>推送截止时间延时到</h2>
            </div>
            <div class="y_push_date_zp_13">
                <div class="y_push_date_zp_14 y_push_date_zp_14_1">
                    <el-input type="number" v-model="tc_date_year" :placeholder="push_endtime.year" ></el-input>
                </div>
                <div class="y_push_date_zp_15">
                    <p>年</p>
                </div>
                <div class="y_push_date_zp_14 y_push_date_zp_14_2">
                    <el-input type="number" v-model="tc_date_month" :placeholder="push_endtime.month"></el-input>
                </div>
                <div class="y_push_date_zp_15">
                    <p>月</p>
                </div>
                <div class="y_push_date_zp_14 y_push_date_zp_14_2">
                    <el-input type="number" v-model="tc_date_day" :placeholder="push_endtime.day"></el-input>
                </div>
                <div class="y_push_date_zp_15">
                    <p>日</p>
                </div>
                <div class="y_push_date_zp_14 y_push_date_zp_14_2">
                    <el-input type="number" v-model="tc_date_time" :placeholder="push_endtime.time" ></el-input>
                </div>
                <div class="y_push_date_zp_15">
                    <p>时</p>
                </div>
                <div class="y_push_date_zp_14 y_push_date_zp_14_2">
                    <el-input type="number" v-model="tc_date_minute" :placeholder="push_endtime.minute"></el-input>
                </div>
                <div class="y_push_date_zp_15">
                    <p>分</p>
                </div>
            </div>
            <div class="y_push_date_zp_16">
                <p class="y_push_date_zp_17" @click="djs_hide()">返回</p>
                <p class="y_push_date_zp_18" @click="djs_determine()">确定</p>
            </div>
        </div>
        <!-- 次数 -->
        <div class="y_push_date_zp_19" :class="cs_pop_value == '0'?'':'y_push_date_zp_19_active'">
            <div class="y_push_date_zp_20">
                <h2>给每人最多推送 </h2>
                <!-- <p>{{blog_list.max_push_number}}次</p> -->
            </div>
            <div class="y_push_date_zp_21">
                <!-- <p>+</p> -->
                <el-input v-model="max_push_number_input" placeholder=""></el-input>
                <p>次</p>
            </div>
            <div class="y_push_date_zp_20">
                <h2>给同一人一天内最多推送 </h2>
                <!-- <p>{{blog_list.max_push_number_day}}次</p> -->
            </div>
            <div class="y_push_date_zp_21 y_push_date_zp_21_p">
                <!-- <p>+</p> -->
                <p v-for="oneday_onepeople in oneday_onepeoples" :key="oneday_onepeople.index" :class='oneday_onepeople.is == "0"?"":"y_person_push_4_active"' @click="oneday_onepeople_dete(oneday_onepeople.index)">{{oneday_onepeople.text}}</p>
                <!-- <el-input v-model="max_push_number_day_input" placeholder=""></el-input> -->
                <!-- <p>次</p> -->
            </div>
            <div class='y_publish_pop_6'>
                <p class='y_publish_pop_7' @click="cs_pop_hide()">返回</p>
                <p class='y_publish_pop_8' @click="cs_pop_determine()">确定</p>
            </div>
        </div>
    </div>
    <kefu></kefu>
    <recharge v-show="dialog_visible" :dialog_visible="dialog_visible" @dialogVisibleEvent="showDialog"></recharge>
</template>

<style scoped>
    .y_push_date_tg_1{
        width: 100%;
        min-height: 100vh;
        background: #F4F5F7;
    }
    .y_push_date_tg_2{
        padding: 0rem 0.45rem;
        height: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_push_date_tg_3 p{
        font-size: 0.28rem;
        color: #333;
    }
    .y_push_date_tg_3 p span{
        color:#FECE0A;
        font-size: .28rem;
    }
    .y_push_date_tg_4 p{
        font-size: 0.28rem;
        color: #333;
        text-decoration:underline
    }
    .y_push_date_tg_5{
        padding: 0.28rem 0.25rem;
        background: #fff;
    }
    .y_push_date_tg_6{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.15rem;
    }
    .y_push_date_tg_7{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_date_tg_7 p{
        font-size: 0.24rem;
        color: #999;
    }
    .y_push_date_tg_7 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_7 h2{
        margin-left: 0.15rem;
        color: #333;
        font-weight: 600;
        font-size: 0.28rem;
    }
    .y_push_date_tg_7 h2 span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_8{
        display: inline-block;
        background: #FECE0A;
        padding: 0rem 0.15rem;
        height: 0.46rem;
        border-radius: 0.1rem;
    }
    .y_push_date_tg_8 p{
        line-height: 0.46rem;
        color: #333;
        font-size: 0.26rem;
    }
    .y_push_date_tg_9{
        width: 100%;
        margin-bottom: 0.15rem;
    }
    .y_push_date_tg_9 p{
        color: #999;
        font-size: 0.24rem;
        line-height: 0.3rem;
    }
    .y_push_date_tg_9 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_10{
        width: 100%;
    }
    .y_push_date_tg_11{
        padding: 0rem 0.35rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.8rem;
    }
    .y_push_date_tg_11 p{
        color:#333;
        font-size: 0.28rem;
    }
    .y_push_date_tg_11 h2{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_12{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.8rem;
    }
    .y_push_date_tg_12 p{
        flex: 1;
        text-align: center;
        color: #999;
        font-size: 0.26rem;
    }
    .y_push_date_tg_12 p span{
        color: #FECE0A;
        font-size: 0.26rem;
    }
    .y_push_date_tg_13{
        width: 100%;
        background: #fff;
        padding-bottom: 0.4rem;
    }
    .y_push_date_tg_14{
        display: flex;
        justify-content: flex-start;
    }
    .y_push_date_tg_15{
        flex:1;
    }
    .y_push_date_tg_16{
        width: 100%;
    }
    .y_push_date_tg_16 h2{
        width: 100%;
        line-height: 1rem;
        text-align: center;
        color: #333;
        font-size: 0.28rem;    
    }
    .y_push_date_tg_16_color h2{
        color: #FECE0A;
    } 
    .y_push_date_tg_17{
        width: 100%;
    }
    .y_push_date_tg_17>p{
        font-size: 0.28rem;
        color: #333;
        width: 100%;
        text-align: center;
    }
    .y_push_date_tg_18{
        width: 100%;
        text-align: center;
        margin-top: 0.25rem;
    }
    .y_push_date_tg_18 /deep/ input{
        width: 1.6rem;
        height: 0.8rem;
        border-radius: 0.1rem;
        background: #F7F8FA;
        overflow: hidden;
        padding: 0rem;
        line-height: 0.8rem;
        margin: 0 auto;
        font-size: 0.28rem;
        color: #ccc;
        text-align: center;
        border: none;
    }
    .y_push_date_tg_18 p{
        color: #FECE0A;
        font-size: 0.28rem;
        line-height: 0.8rem;
    }
    .y_push_date_tg_19{
        width: 100%;
        height: 5rem;
        background: transparent;
    }
    .y_push_date_tg_20{
        width: 100%;
        height: 2.7rem;
        background: #fff;
        position: fixed ;
        left: 0rem;
        bottom: 0rem;
    }
    .y_push_date_tg_21{
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        padding: 0rem 0.25rem;
        height: 100%;
    }
    .y_push_date_tg_22{
        width: 100%;
        text-align: center;
    }
    .y_push_date_tg_22 p{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_date_tg_22 p span{
        color:#FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_23{
        display: flex;
        margin-top: 0.2rem;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .y_push_date_tg_23 p{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_date_tg_23 h2{
        color: #FECE0A;
        font-size: 0.28rem;
        text-decoration:underline;
    }
    .y_push_date_tg_24{
        width: 100%;
        margin-top: 0.3rem;
    }
    .y_push_date_tg_24 p{
        height: 0.8rem;
        background: #FECE0A;
        line-height: 0.8rem;
        width: 100%;
        border-radius: 0.1rem;
        text-align: center;
        font-size:0.32rem;
        color: #333;
        font-weight: 600;
    }


    /* 招聘 */
    .y_push_date_zp_1{
        margin-bottom: 0.2rem;
    }
    .y_push_date_zp_2{
        height: 2.2rem;
    }
    .y_push_date_zp_3{
        height: 4rem;
    }
    .y_push_date_zp_4{
        border-top: 0.01rem solid #F3F3F3;
        width: 100%;
        margin-top: 0.4rem;
        padding-bottom: 0.1rem;
    }
    .y_push_date_zp_5{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1rem;
    }
    .y_push_date_zp_6 h2{
        font-size: 0.3rem;
        color: #333;
        font-weight: 600;
    }
    .y_push_date_zp_7 p{
        color: #999;
        font-size: 0.28rem;
    }
    .y_push_date_zp_7 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_zp_8{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0.8rem;
    }
    .y_push_date_zp_9{
        width: 2.8rem;
        background: #F7F8FA;
        position: relative;
        height: 100%;
    }
    .y_push_date_zp_9 /deep/ input{
        width: 2rem;
        height: 0.8rem;
        font-size: 0.28rem;
        color: #333;
        background: transparent;
        border: none;
        line-height: 0.8rem;
        padding: 0rem 0rem 0rem 0.2rem;
    }
    .y_push_date_zp_10{
        position: absolute;
        top: 50%;
        display: inline-block;
        color: #333;
        font-size: 0.28rem;
        right: 0.3rem;
        transform: translate(0%,-50%);
    }
    .y_push_date_zp_11{
        width: 6.4rem;
        height: 3.6rem;
        background: #fff;
        border-radius: 0.1rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 20;
        display: none;
        flex-wrap: wrap;
        align-content: center;
    }
    .y_push_date_zp_11_active{
        display: flex;
    }
    .y_push_date_zp_12{
        width: 100%;
        text-align: center;
    }
    .y_push_date_zp_12 h2{
        color:#999;
        font-size: 0.3rem;
    }
    .y_push_date_zp_13{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 0.3rem;
    }
    .y_push_date_zp_14{
        background: #F7F8FA;
        height: 0.8rem;
        border-radius: 0.1rem;
        overflow: hidden;
    }
    .y_push_date_zp_14 /deep/ input{
        height: 0.8rem;
        padding: 0rem 0.1rem 0rem 0.15rem;
        background: transparent;
        width: 100%;
        border: none;
        font-size: 0.28rem;
        color: #FECE0A;
    }
    .y_push_date_zp_14_1{
        width: 1rem;
    }
    .y_push_date_zp_14_2{
        width: 0.6rem;
    }
    .y_push_date_zp_15{
        display: inline-block;
        margin: 0rem 0.1rem;
    }
    .y_push_date_zp_15 p{
        font-size: 0.28rem;
        color: #333;
    }
    .y_push_date_zp_16{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.35rem;
        width: 100%;
    }
    .y_push_date_zp_16 p{
        width: 2rem;
        height: 0.7rem;
        border-radius: 1rem;
        text-align: center;
        line-height: 0.7rem;
        font-size: 0.3rem;
    }
    .y_push_date_zp_17{
        background: #ECECEC;
        color: #666;
        margin-right: 0.1rem;
    }
    .y_push_date_zp_18{
        color: #333;
        background: #FECE0A;
    }
    .y_push_date_zp_19{
        width: 5.2rem;
        height: 5.6rem;
        background: #fff;
        border-radius: 0.1rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        position: fixed;
        display: none;
        align-content: center;
        flex-wrap: wrap;
        z-index: 20;
    }
    .y_push_date_zp_19_active{
        display: flex;
    }
    .y_push_date_zp_20{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.3rem;
    }
    .y_push_date_zp_20 h2{
        font-size: 0.3rem;
        color: #999;
    }
    .y_push_date_zp_20 p{
        font-size: 0.3rem;
        color: #333;
        margin-left: 0.1rem;
    }
    .y_push_date_zp_21{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.3rem;
    }
    .y_push_date_zp_21 p{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_date_zp_21 /deep/ .el-input{
        width: 1.3rem;
        margin: 0rem 0.1rem;
        padding:0rem;
        overflow: hidden;
    }
    .y_push_date_zp_21 /deep/ input{
        width: 1.3rem;
        height: 0.8rem;
        line-height: 0.8rem;
        background: #F7F8FA;
        text-align: center;
        border-radius: 0rem;
        border: none;
        color: #333;
        font-size: 0.28rem;
        padding:0rem 0.1rem;
    }
    .y_push_date_zp_22{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: 0.5rem; 
    }
    .y_push_date_zp_22 p{
        width: 1.3rem;
        height: 0.7rem;
        border-radius: 0.1rem;
        text-align: center;
        font-size: 0.3rem;
        background: #ECECEC;
        line-height: 0.7rem;
        margin-left: 0.2rem;
        transition: ease 0.5s all;
    }
    .y_push_date_zp_22 .y_push_date_zp_22_active{
        transition: ease 0.5s all;
        background: #FECE0A;
    }   
    .y_publish_pop_6{
        width: 100%;
    }
    .y_number_places_3 {
        flex: 1;
        height: 0.8rem;
        background: #ccc;
        line-height: 0.8rem;
        width: 100%;
        border-radius: 0.1rem;
        text-align: center;
        font-size: 0.32rem;
        color: #333;
        font-weight: 600;
        margin-right: 0.2rem;
    }
    .y_number_places_4{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_number_places_5{
        flex: 1;
    }
    .y_push_date_zp_21_p{
        transition: ease 0.5s all;
    }
    .y_push_date_zp_21_p p{
        width: 1.3rem;
        background: #F7F8FA;
        line-height: 0.8rem;
        text-align: center;
        border-radius: 0.2rem;
        color: #333;
        font-size: 0.28rem;
        margin-right: 0.2rem;
        transition: ease 0.5s all;
    }
    .y_push_date_zp_21_p .y_person_push_4_active{
        background: #FECE0A;
        transition: ease 0.5s all;
    }
</style>

<script>
    import kefu from '../../components/kefu/kefu'
    import recharge from '../../components/recharge/recharge'
    import axios from 'axios'   // 接口
    import {blog_info_info} from '../../api/request.js'   // 帖子详情,信息类推广
    import {user_info} from '../../api/request.js' // 用户信息
    import {renew_blog_info} from '../../api/request.js'   // 帖子的续费和修改
    export default {
        data(){
            return {
                shade_value:'0',
                comm_shadows_value:'',
                input: '',
                publish_pop_value:'0',
                djs_pop_value:'0',
                cs_pop_value:'0',
                //公共 钱包充值
                dialog_visible: false,
                available_balance:'',

                recruit_id:'',
                push_status:'',  // 1为帖子 2位招聘       
                class_type:'',   // 1为帖子 2位招聘

                blog_list:'',
                cost_info_list:'',
                date_day:'',     // 剩余 天
                date_hour:'',    // 剩余 时
                date_minute:'',  // 剩余 分
                date_second:'',  // 剩余 秒
                local_num:'',          //  本地计算数量
                max_push_number_input:'',        
                max_push_number_day_input:'1',    
                tc_date_year:'',  // 弹窗时间 年
                tc_date_month:'', // 弹窗时间 月
                tc_date_day:'', // 弹窗时间 日
                tc_date_time:'', // 弹窗时间 时
                tc_date_minute:'', // 弹窗时间 分
                push_endtime_stamptime:'',
                recruit_date_new:'',
                oneday_onepeoples:[
                    {index:'1',text:'1',is:'1'},
                    {index:'2',text:'2',is:'0'},
                    {index:'3',text:'3',is:'0'},
                ],
                push_endtime:'', // placeholder 推送
            }
        },  
        components:{
            kefu,
            recharge,
            axios,
        },
        created(){
            // 获取页面传参 推送类型
            if(this.$route.query.recruit_id !== undefined){
                this.recruit_id = this.$route.query.recruit_id
                this.push_status = this.$route.query.push_status
                this.class_type = this.$route.query.class_type
            }
        },

        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token

            let recruit_id = this.recruit_id
            let push_status = this.push_status
            if(push_status == '1'){
                blog_info_info({token:token,blog_id:recruit_id}).then((res) =>{
                    console.log(res,'帖子类详情')
                    this.blog_list = res.blog
                    this.timestampToTime(res.blog.push_endtime_stamptime*1)
                    this.cost_info_list = res.cost_info
                    this.push_endtime_stamptime = res.blog.push_endtime_stamptime
                    this.max_push_number_input = res.blog.max_push_number
                    this.max_push_number_day_input = res.blog.max_push_number_day
                    this.recruit_date_new = res.blog.push_endtime
                    
                    let oneday_onepeoples = this.oneday_onepeoples
                    for(let i=0;i<oneday_onepeoples.length;i++){
                        oneday_onepeoples[i].is = '0'
                        if(oneday_onepeoples[i].index == res.blog.max_push_number_day){
                            oneday_onepeoples[i].is = '1'
                        }
                    }
                    this.oneday_onepeoples = oneday_onepeoples
                    this.push_endtime = {year:res.blog.push_endtime.slice(0,4),month:res.blog.push_endtime.slice(5,7),day:res.blog.push_endtime.slice(8,10),time:res.blog.push_endtime.slice(11,13),minute:res.blog.push_endtime.slice(14,16)}
                })
            }

            user_info({token:token}).then((res)=>{
                console.log(res,'用户信息')
                let balance = res.data.balance  // 	余额
                let bonus_fee = res.data.bonus_fee // 奖励金
                let frozen_fee = res.data.frozen_fee  // 冻结金额
                let deposit_fee = res.data.deposit_fee  // 押金金额
                let available_balance = ''      // 可用余额

                this.balance = balance
                this.bonus_fee = bonus_fee
                this.frozen_fee = frozen_fee
                this.deposit_fee = deposit_fee
                this.available_balance = balance*1 + bonus_fee*1
            })
        },
        methods: {
            // 充值
            showDialog(visible) {
                this.dialog_visible = visible;
            },
            // 倒计时 弹窗 显示
            djs_show(){
                this.comm_shadows_value = '1'
                this.djs_pop_value = '1'
            },
            // 倒计时 弹窗 关闭
            djs_hide(){
                this.comm_shadows_value = '0'
                this.djs_pop_value = '0'
            },
            // 次数 弹窗 显示
            cs_pop_show(){
                this.comm_shadows_value = '1'
                this.cs_pop_value = '1'
            },
            // 给同一人一天内最多推送
            oneday_onepeople_dete(index){
                let oneday_onepeoples_list = this.oneday_onepeoples
                for(let i=0;i<oneday_onepeoples_list.length;i++){
                    oneday_onepeoples_list[i].is = '0'
                    if(oneday_onepeoples_list[i].index == index){
                        oneday_onepeoples_list[i].is = '1'
                        this.max_push_number_day_input = index
                    }
                }
                this.oneday_onepeoples = oneday_onepeoples_list
            },
            // 倒计时确定
            djs_determine(){
                let tc_date_year = this.tc_date_year
                let tc_date_month = this.tc_date_month
                let tc_date_day = this.tc_date_day
                let tc_date_time = this.tc_date_time
                let tc_date_minute = this.tc_date_minute
                let push_endtime_stamptime = this.push_endtime_stamptime
                let date = `${tc_date_year}-${tc_date_month}-${tc_date_day} ${tc_date_time}:${tc_date_minute}:00`
                let time1 = Date.parse(date)
                console.log(date,time1)
                if(tc_date_month>12 || tc_date_month == ''){
                    this.$message({
                        message: '请输入正确月份',
                        duration:'2000'
                    })
                    return
                }
                if(tc_date_day>31 || tc_date_day == ''){
                    this.$message({
                        message: '请输入正确日期',
                        duration:'2000'
                    })
                    return
                }
                if(tc_date_time>23 || tc_date_time == ''){
                    this.$message({
                        message: '请输入正确小时',
                        duration:'2000'
                    })
                    return
                }
                if(tc_date_minute>59 || tc_date_minute == ''){
                    this.$message({
                        message: '请输入正确分钟',
                        duration:'2000'
                    })
                    return
                }
                this.recruit_date_new = date
                this.comm_shadows_value = '0'
                this.djs_pop_value = '0'
            },
            // 次数 弹窗 关闭
            cs_pop_hide(){
                this.comm_shadows_value = '0'
                this.cs_pop_value = '0'
            },
            // 次数 弹窗 确定
            cs_pop_determine(){
                this.comm_shadows_value = '0'
                this.cs_pop_value = '0'
            },
            // 倒计时
            timestampToTime (timestamp) {
                setInterval(()=>{
                    let time = timestamp*1 - parseInt(new Date().getTime() / 1000);
                    let day = parseInt(time / (60*60*24))
                    let hour = parseInt((time - day*60*60*24 ) / (60*60))
                    let minute = parseInt((time - day *60*60*24 - hour *60*60) / 60)
                    let second = parseInt((time - day *60*60*24 - hour *60*60 - minute*60))
                    this.date_day = day
                    this.date_hour = hour
                    this.date_minute = minute
                    this.date_second = second
                },1000)
            },
            // 返回
            back_to(){
                this.$router.go(-1)
            },
            // 提交 
            submit(){
                let token = this.token
                let blog_id = this.recruit_id
                let recruit_id = this.recruit_id
                let max_push_number = this.max_push_number_input
                let max_push_number_day = this.max_push_number_day_input
                let push_endtime = this.recruit_date_new
                let exposure_number = this.local_num.toFixed(2)

                let push_status = this.push_status
                if(push_status == '1'){
                    renew_blog_info({token,blog_id,max_push_number,max_push_number_day,push_endtime,exposure_number}).then((res)=>{
                        console.log(res)
                        if(res.error_code == '0'){
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                            var that = this;
                            setTimeout(function () {
                                that.$router.push({path:'/'}) 
                            },1000);
                        }else{
                            console.log('111')
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }
            },
        },
    }
</script>