
<template>
    <div class="y_my_index_0">
        <div class="y_my_index_1">
            <div class="y_my_index_2">
                <img :src="''+business_homepage_bg_img" alt="">
            </div>
            <div class="y_my_index_3">
                <div class="y_my_index_4">
                    <div class="y_my_index_5">
                        <img src="../../../images/icon_71.png" alt="">
                    </div>
                    <div class="y_my_index_5" @click="go_swipe">
                        <img src="../../../images/icon_70.png" alt="">
                    </div>
                </div>
                <div class="y_my_index_6">
                    <div class="y_my_index_7">
                        <img :src="''+business_head_portrait" alt="">
                    </div>
                    <div class="y_my_index_8">
                        <div class="y_my_index_9">
                            <h2>{{business_nicname}}</h2>
                            <div class="y_my_index_10" @click="editor_jump">
                                <img src="../../../images/icon_74.png" alt="">
                                <p>编辑</p>
                            </div>
                        </div>
                        <div class="y_my_index_11" :class="user_list.senior_auth_type == 'enterprise'?(user_list.business_check_status == 'success'?'':'y_display_none'):'y_display_none'">
                            <div class="y_my_index_12">
                                <img src="../../../images/icon_73.png" alt="">
                                <p>{{company_name}}</p>
                            </div>
                        </div>
                        <div class="y_my_index_13">
                            <p>ID:{{ user_id }}</p>
                        </div>
                    </div>
                </div>
                <div class="y_my_index_14">
                    <div class="y_my_index_15">
                        <p>{{business_brief}}</p>
                    </div>
                </div>
                <div class="y_my_index_16">
                    <div class="y_my_index_17" v-for="(item,index) in business_gallery" :key="index">
                        <img :src="item" @click="zoom_up(index)" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="y_my_index_18">
            <div class="y_my_index_19">
                <div class="y_my_index_20" @click="showDialog(true)">
                    <div class="y_my_index_21">
                        <img src="../../../images/icon_78.png" alt="">
                        <h2>可用余额</h2>
                        <p>{{user_balance}}<span>元</span></p>
                    </div>
                    <div class="y_my_index_22">
                        <p>充{{balance}}送{{bonus_fee}}</p>
                    </div>
                </div>
                <router-link to='/my_invite_new_prize' class="y_my_index_20">
                    <div class="y_my_index_21">
                        <img src="../../../images/icon_79.png" alt="">
                        <h2>邀新有奖</h2>
                    </div>
                    <div class="y_my_index_22">
                        <p>邀新赚万元现金</p>
                    </div>
                </router-link>
            </div>
        </div>

        <div class="y_my_index_23">
            <div class="y_my_index_24">
                <div class="y_my_index_25" @click="jump_comm_one(1)">
                    <div class="y_my_index_26">
                        <img src="../../../images/icon_80.png" alt="">
                    </div>
                    <h2>使用攻略</h2>
                </div>
                <div class="y_my_index_25" @click="jump_comm_one(2)">
                    <div class="y_my_index_26">
                        <img src="../../../images/icon_81.png" alt="">
                    </div>
                    <h2>联系喵君</h2>
                </div>
                <div class="y_my_index_25" @click="jump_comm_one(3)">
                    <div class="y_my_index_26">
                        <img src="../../../images/icon_82.png" alt="">
                    </div>
                    <h2>意见反馈</h2>
                </div>
                <div class="y_my_index_25" @click="jump_comm_one(4)">
                    <div class="y_my_index_26">
                        <img src="../../../images/icon_83.png" alt="">
                    </div>
                    <h2>公告</h2>
                </div>
                <div class="y_my_index_25" @click="jump_comm_one(5)">
                    <div class="y_my_index_26">
                        <img src="../../../images/icon_84.png" alt="">
                    </div>
                    <h2>协议</h2>
                </div>
                <div class="y_my_index_25" @click="jump_comm_one(6)">
                    <div class="y_my_index_26">
                        <img src="../../../images/icon_85.png" alt="">
                    </div>
                    <h2>关于喵君</h2>
                </div>
                <div class="y_my_index_25" @click="jump_comm_one(7)">
                    <div class="y_my_index_26">
                        <img src="../../../images/icon_86.png" alt="">
                    </div>
                    <h2>商务合作</h2>
                </div>
                <div class="y_my_index_25" @click="jump_comm_one(8)">
                    <div class="y_my_index_26">
                        <img src="../../../images/icon_87.png" alt="">
                    </div>
                    <h2>加入喵君</h2>
                </div>
                <router-link to="/my_certification" class="y_my_index_25">
                    <div class="y_my_index_26">
                        <img src="../../../images/icon_88.png" alt="">
                    </div>
                    <h2>认证</h2>
                </router-link>
                <router-link to='/my_settings' class="y_my_index_25">
                    <div class="y_my_index_26">
                        <img src="../../../images/icon_89.png" alt="">
                    </div>
                    <h2>设置</h2>
                </router-link>
                <router-link to='/write_offs_list' class="y_my_index_25">
                    <div class="y_my_index_26">
                        <img src="../../../images/icon_116.png" alt="">
                    </div>
                    <h2>核销列表</h2>
                </router-link>
            </div>
        </div>
        <div class="y_my_index_23">
            <div class="y_my_index_27">
                <div class="y_my_index_28">
                    <p><span>{{rebates_user_count}}</span>名喵君商务端用户已邀新获得现金</p>
                    <h2>￥{{rebate_fee_total}}</h2>
                </div>
                <div class="y_my_index_29">
                    <div class="y_my_index_30 y_flex_1">
                        <h2>排名</h2>
                    </div>
                    <div class="y_my_index_30 y_flex_6">
                        <h2>用户</h2>
                    </div>
                    <div class="y_my_index_30 y_flex_2">
                        <h2>已获现金</h2>
                    </div>
                </div>
                <div class="y_my_index_31">
                    
                    <div class="y_my_index_32" v-for="(item,index) in rebates_list" :key="index">
                        <div class="y_my_index_33 y_flex_1" v-if="index == '0'?true:false">
                            <img src="../../../images/icon_90.png" alt="">
                        </div>
                        <div class="y_my_index_33 y_flex_1" v-if="index == '1'?true:false">
                            <img src="../../../images/icon_91.png" alt="">
                        </div>
                        <div class="y_my_index_33 y_flex_1" v-if="index == '2'?true:false">
                            <img src="../../../images/icon_92.png" alt="">
                        </div>
                        <div class="y_my_index_33 y_flex_1" v-if="index > '2'?true:false">
                            <p>{{index}}</p>
                        </div>
                        <div class="y_my_index_34 y_flex_6">
                            <h2>{{item.phone}}</h2>
                        </div>
                        <div class="y_my_index_35 y_flex_2">
                            <p>￥{{item.rebate_fee_total}}</p>
                        </div>
                    </div>

                </div>

                <router-link to='/my_invite_new_prize' class="y_my_index_36">
                    <p>查看收益</p>
                </router-link>
            </div>
        </div>
    </div>

    <bottom></bottom>
    <recharge v-show="dialog_visible" :dialog_visible="dialog_visible" @dialogVisibleEvent="showDialog"></recharge>
</template>
<style>
    .y_my_index_0{
        padding-bottom: 1rem;
    }
    .y_my_index_1{
        width: 100%;
        height: 6.5rem;
        position: relative;
    }
    .y_my_index_2{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
    .y_my_index_2 img{
        width: 100%;
        object-fit: cover;
        height: 100%;
    }
    .y_my_index_2::after{
        width: 100%;
        content: '';
        position: absolute;
        top: 0rem;
        left: 0rem;
        height: 100%;
        background: rgba(0,0,0,0.5);
        z-index: 1;
    }
    .y_my_index_3{
        position: relative;
        z-index: 2;
        padding: 0rem 0.25rem;
    }
    .y_my_index_4{
        height: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_my_index_5{
        width: 0.36rem;
        height: 0.36rem;
    }
    .y_my_index_5 img{
        width: 100%;
        object-fit: cover;
    }
    .y_my_index_6{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1.2rem;
    }
    .y_my_index_7{
        width: 1.2rem;
        height: 1.2rem;
        overflow: hidden;
        border-radius: 1rem;
        margin-right: 0.4rem;
    }
    .y_my_index_7 img{
        width: 100%;
        object-fit: cover;
        height: 100%;
    }
    .y_my_index_8{
        flex: 1;
        display: flex;
        height: 100%;
        flex-wrap: wrap;
        align-content: center;
    }
    .y_my_index_9{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_my_index_9 h2{
        font-size: 0.3rem;
        color: #fff;
        font-weight: 600;
    }
    .y_my_index_10{
        width: 1.1rem;
        height: 0.4rem;
        border-radius: 1rem;
        border: 0.01rem solid #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255,255,255,0.2);
    }
    .y_my_index_10 img{
        width: 0.21rem;
        height: 0.21rem;
    }
    .y_my_index_10 p{
        font-size: 0.22rem;
        color:#fff;
        margin-left: 0.1rem;
    }
    .y_my_index_11{
        display: inline-block;
        margin-top: 0.1rem;
    }
    .y_my_index_12{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.3rem;
        border-radius: 0.1rem;
        background: #FECE0A;
        padding: 0rem 0.1rem;
    }
    .y_my_index_12 img{
        width: 0.2rem;
        height: 0.2rem;
        margin-right: 0.1rem;
    }
    .y_my_index_12 p{
        font-size: 0.2rem;
        color: #333;
    }
    .y_my_index_13{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0.1rem;
    }
    .y_my_index_13 p{
        color: rgba(255,255,255,0.5);
        font-size: 0.22rem;
    }
    .y_my_index_14{
        width: 100%;
        margin-top: 0.4rem;
    }
    .y_my_index_15{
        max-width: 5rem;
    }
    .y_my_index_15 p{
        color: #fff;
        font-size: 0.24rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
    }
    .y_my_index_16{
        width: 100%;
        margin-top: 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 2.2rem;
        overflow-x: scroll;
        display: -webkit-box;
    }
    /* .y_my_index_16 .y_my_index_17:nth-child(n+4){
        display: none;
    } */
    /* .y_my_index_16 .y_my_index_17:nth-child(3){
        margin-right: 0rem;
    } */
    .y_my_index_17{
        width: 2.2rem;
        height: 2.2rem;
        margin-right: 0.3rem;
    }
    .y_my_index_17 img{
        width: 100%;
        object-fit: cover;
        height: 100%;
    }
    .y_my_index_18{
        margin-top: 0.3rem;
        padding: 0rem 0.25rem;
    }
    .y_my_index_19{
        box-shadow: 0 0 0.3rem 0.03rem #ccc;
        height: 1.7rem;
        border-radius: 0.1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
    }
    .y_my_index_19::after{
        content: '';
        position: absolute;
        width: 0.01rem;
        height: 0.9rem;
        background: #DDDDDD;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .y_my_index_20{
        flex: 1;
        padding-left: 0.4rem;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        flex-wrap: wrap;
    }
    .y_my_index_21{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_my_index_21 img{
        max-width: 0.33rem;
        object-fit: cover;
        margin-right: 0.15rem;
    }
    .y_my_index_21 h2{
        font-size: 0.24rem;
        color: #333;
        margin-right: 0.15rem;
        font-weight: 600;
    }
    .y_my_index_21 p{
        font-size: 0.3rem;
        color: #333;
        font-weight: 600;
    }
    .y_my_index_21 p span{
        color: #333;
        font-size: 0.22rem;
        font-weight: 600;
    }
    .y_my_index_22{
        margin-left: 0.48rem;
        display: inline-block;
        margin-top: 0.15rem;
        background: #FECE0A;
        padding: 0rem 0.15rem;
        border-radius: 0.1rem;
    }
    .y_my_index_22 p{
        font-size: 0.24rem;
        color:#333;
        line-height: 0.4rem;
    }
    .y_my_index_23{
        margin-top: 0.3rem;
        padding: 0rem 0.25rem;
    }
    .y_my_index_24{
        box-shadow: 0 0 0.3rem 0.03rem #ccc;
        height: 4.5rem;
        border-radius: 0.1rem;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .y_my_index_25{
        width: 20%;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
    }
    .y_my_index_26{
        width: 100%;
        text-align: center;
        height: 0.36rem;
    }
    .y_my_index_26 img{
        max-height: 0.36rem;
        object-fit: cover;
    }
    .y_my_index_25 h2{
        width: 100%;
        text-align: center;
        font-size: 0.24rem;
        color: #222;
        margin-top: 0.15rem;
    }
    .y_my_index_27{
        box-shadow: 0 0 0.3rem 0.03rem #ccc;
        border-radius: 0.1rem;
        padding: 0rem 0.35rem;
    }
    .y_my_index_28{
        padding-top: 0.5rem;
    }
    .y_my_index_28 p{
        width: 100%;
        text-align: center;
        font-size: 0.3rem;
        color:#222;
    }
    .y_my_index_28 p span{
        color: #FECE0A;
        font-size: 0.3rem;
    }
    .y_my_index_28 h2{
        width: 100%;
        text-align: center;
        margin-top: 0.25rem;
        color: #FECE0A;
        font-size: 0.3rem;
    }
    .y_my_index_29{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0.65rem;
    }
    .y_my_index_30 h2{
        font-size: 0.24rem;
        color: #333;
    }
    .y_flex_1{
        flex: 1;
        margin-right: 0.4rem;
    }
    .y_flex_6{
        flex: 6;
    }
    .y_flex_2{
        flex: 2;
        text-align: right;
    }
    .y_my_index_31{
        width: 100%;
        margin-top: 0.3rem;
    }
    .y_my_index_32{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1rem;
    }
    .y_my_index_31 .y_my_index_32:nth-child(n+11){
        display: none;
    }
    .y_my_index_33 img{
        width: 0.48rem;
        height: 0.41rem;
    }
    .y_my_index_33 p{
        width: 0.46rem;
        height: 0.46rem;
        background: #FDF6DA;
        border-radius: 1rem;
        overflow: hidden;
        text-align: center;
        line-height: 0.46rem;
        font-size: 0.26rem;
        color: #FECE0A;
    }
    .y_my_index_34{
        border-bottom: 0.01rem solid #EEEEEE;
        height: 100%;
    }
    .y_my_index_35{
        border-bottom: 0.01rem solid #EEEEEE;
        height: 100%;
    }
    .y_my_index_34 h2{
        color: #333;
        font-size: 0.26rem;
        font-weight: 600;
        line-height: 1rem;
    }
    .y_my_index_35 p{
        color: #FECE0A;
        font-size: 0.3rem;
        /* font-weight: 600; */
        line-height: 1rem;
    }
    .y_my_index_36{
        width: 100%;
        margin: 0.4rem 0rem 0rem;
        text-align: center;
        padding-bottom: 0.5rem;
        display: block;
    }
    .y_my_index_36 p{
        display: inline-block;
        margin: 0 auto;
        font-size: 0.32rem;
        color: #333;
        font-weight: 600;
        background: #FECE0A;
        border-radius: 1rem;
        padding: 0rem 1rem;
        line-height: 0.8rem;
    }

    .van-image-preview{
        width: 100% !important;
    }
</style>
<script>
    import axios from 'axios'   // 接口
    import { ImagePreview } from 'vant';


    import {info} from '../../api/request.js' // 用户信息
    import {recharge_template_list} from '../../api/request.js' // 充值模板
    import {rebate_user_list} from '../../api/request.js' // 返利用户列表
    
    import bottom from '../../components/bottom/bottom'
    import recharge from '../../components/recharge/recharge'

    export default {
        data(){
            return {
                dialog_visible: false,

                user_list:'',   // 用户列表
                user_id:'',     // 用户id
             
                user_balance:'',  // 余额
                business_head_portrait:'',  // 商务端头像
                business_nicname:'',   // 商务端昵称
                business_brief:'',    // 商务端简介
                business_homepage_bg_img:'', // 商务端主页图片
                business_gallery:'',  // 商务端多图
                zoom_business_gallery:'',
                company_name:'', // 商务端公司名称
                balance:'',  // 充 
                bonus_fee:'',  // 奖

                rebates_list:'', // 返利用户
                rebates_user_count:'', // 返利用户总数
                rebate_fee_total:'', // 全部用户 累计获得返利金额
            }
        },  
        components:{
            bottom,
            recharge,
        },
        mounted(){
            // 用户信息
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            info({token:token}).then((res) =>{
                console.log(res,'用户信息')
                this.user_list = res.data
                this.user_id = res.data.id
                this.user_balance = (res.data.balance*1)+(res.data.bonus_fee*1)
                this.business_head_portrait = res.data.business_head_portrait
                this.business_nicname = res.data.business_nicname
                this.business_brief = res.data.business_brief
                this.business_homepage_bg_img = res.data.business_homepage_bg_img
                this.business_gallery = res.data.business_gallery
                this.company_name = res.data.company_name

                
                let zoom_business_gallery = res.data.business_gallery
                for(let i=0;i<zoom_business_gallery.length;i++){
                    zoom_business_gallery[i] = '' + zoom_business_gallery[i]
                }
                this.zoom_business_gallery = zoom_business_gallery
            })
            // 充值模板
            recharge_template_list({token:token}).then((res) =>{
                console.log(res,'充值模板')
                this.balance = res.data[0].balance
                this.bonus_fee = res.data[0].bonus_fee
            })
            // 返利用户列表
            rebate_user_list({parent_uid:'0'}).then((res) =>{
                console.log(res,'返利用户列表')
                this.rebates_user_count = res.user_count
                this.rebates_list = res.data
                this.rebate_fee_total = res.rebate_fee_total
            })
            
        },
        methods: {
            // 用户页 跳转 
            // 使用攻略
            jump_comm_one(value){
                this.$router.push({
                    path:'/user_comm_jump',
                    query: {
                        user_value: value,
                    }
                })
            },
            // 跳转 扫一扫
            go_swipe(){
                this.$router.push({
                    path:'/qrcodestream',
                })
            },
            // 充值
            showDialog(visible) {
              this.dialog_visible = visible;
            },
            // 跳转编辑
            editor_jump(){
                this.$router.push({
                    path:'/my_edit_profile',
                })
            },
            // 多图放大
            zoom_up(index){
                ImagePreview({
                    images:this.zoom_business_gallery,
                    startPosition: index,
                });
            }
        },
    }

</script>