
<template>
    <div class="y_my_edit_profile_1">
        <div class="y_my_edit_profile_2">
            <h2>完善资料可以********</h2>
        </div>
        <div class="y_my_edit_profile_3">
            <div class="y_my_edit_profile_4">
                <div class="y_my_edit_profile_5">
                    <h2>头像</h2>
                </div>
                <!-- <div class="y_my_edit_profile_6" >
                    
                </div> -->
                <div class="y_my_edit_profile_6" >
                    <el-upload class="avatar-uploader" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :show-file-list="false" :on-success="tx_success" :before-upload="tx_settings">
                        <img :src="''+business_head_portrait" alt="" v-if="business_head_portrait == '' ? false:true">
                        <img src="../../../images/icon_93.png" alt="" v-if="business_head_portrait == '' ? true:false">
                    </el-upload>
                </div>
            </div>

            <div class="y_my_edit_profile_7">
                <div class="y_my_edit_profile_8">
                    <h2>名称</h2>
                </div>
                <div class="y_my_edit_profile_9">
                    <el-input v-model="business_nicname" placeholder="请输入内容"></el-input>
                </div>
            </div>
            <div class="y_my_edit_profile_7 y_my_edit_profile_10">
                <div class="y_my_edit_profile_8">
                    <h2>自我介绍</h2>
                </div>
                <div class="y_my_edit_profile_9">
                    <el-input type="textarea" autosize placeholder="请输入内容" v-model="business_brief"></el-input>
                </div>
            </div>
            <div class="y_my_edit_profile_4">
                <div class="y_my_edit_profile_5">
                    <h2>主页背景</h2>
                </div>
                <!-- <div class="y_my_edit_profile_6" >
                    
                </div> -->
                <div class="y_my_edit_profile_6 y_my_edit_profile_6_radius">
                    <el-upload class="avatar-uploader" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :show-file-list="false" :on-success="zybj_success" :before-upload="zybj_settings">
                        <img :src="''+business_homepage_bg_img" alt="" v-if="business_homepage_bg_img == '' ? false:true">
                        <img src="../../../images/icon_93.png" alt="" v-if="business_homepage_bg_img == '' ? true:false">
                    </el-upload>
                </div>
            </div>
            <div class="y_my_edit_profile_11">
                <div class="y_my_edit_profile_12">
                    <h2>精选图片</h2>
                </div>
                <div class="y_my_edit_profile_13">
                    <!-- <div class="y_my_edit_profile_14">
                        <img src="../../../images/icon_93.png" alt="">
                    </div> -->
                    <div class='y_recruit_13'>
                        <el-upload class="upload-demo" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :on-preview="handlePreview" :on-success="hand_success" :on-remove="handleRemove" :file-list="fileList" list-type="picture">
                            <div class='y_recruit_14' >
                                <img src="../../../images/icon_14.png" alt="">
                            </div>
                        </el-upload>
                    </div>
                </div>
            </div>
            <div class="y_my_edit_profile_15">
                <div class="y_my_edit_profile_16">
                    <div class="y_my_edit_profile_17">
                        <h2>高级认证</h2>
                    </div>
                    <div class="y_my_edit_profile_18">
                        <img src="../../../images/icon_94.png" alt="">
                        <p>{{company_name}}</p>
                    </div>
                </div>
                <div class="y_my_edit_profile_19">
                    <p>(高级认证信息如需修改，请前往<span class="y_my_edit_profile_20" @click="jump_advanced()">高级认证</span>提交新的认证信息)</p>
                </div>
                <div class="y_my_edit_profile_19" style="margin-top: 0.1rem;">
                    <p>温馨提示：审核通过才会更新显示</p>
                </div>
            </div>
        </div>

        <div class="y_my_edit_profile_21" >
            <p @click="submit_review">提交审核</p>
        </div>
    </div>
</template>
<style scoped>
    .y_my_edit_profile_1{
        min-height: 100vh;
        width: 100%;
        background: #F4F5F7;
    }
    .y_my_edit_profile_2{
        width: 100%;
        height: 1rem;
    }
    .y_my_edit_profile_2 h2{
        width: 100%;
        line-height: 1rem;
        text-align: center;
        font-size: 0.28rem;
        color: #aaa;
    }
    .y_my_edit_profile_3{
        padding: 0rem 0.25rem;
        background: #fff;
    }
    .y_my_edit_profile_4{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.6rem;
        border-bottom: 0.01rem solid #EDEDED;
    }
    .y_my_edit_profile_5 h2{
        font-size: 0.3rem;
        color: #999;
    }
    .y_my_edit_profile_6{
        width: 1rem;
        height: 1rem;
    }
    .y_my_edit_profile_6 img{
        width: 100%;
        object-fit: cover;
        height: 1rem;
        object-fit: cover;
        /* border-radius: 1rem; */
    }
    .y_my_edit_profile_6_radius img{
        border-radius: 0rem;
    }
    .y_my_edit_profile_7{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1.2rem;
        border-bottom: 0.01rem solid #EDEDED;
    }
    .y_my_edit_profile_8 h2{
        font-size: 0.3rem;
        color: #999;
        line-height: 1.2rem;
    }
    .y_my_edit_profile_9{
        flex: 1;
        height: 100%;
        margin-left: 1rem;
    }
    .y_my_edit_profile_9 /deep/ input{
        width: 100%;
        height: 1.2rem;
        text-align: right;
        padding: 0rem;
        line-height: 1.2rem;
        border-radius: 0rem;
        background: transparent;
        border: none;
        font-size: 0.3rem;
        color: #333;
    }
    .y_my_edit_profile_9 /deep/ textarea{
        margin: 0.35rem 0rem;
        padding: 0rem;
        border-radius: 0rem;
        border: none;
        background: transparent;
        font-size: 0.28rem;
        color: #333;
        text-align: right;
        line-height: 0.6rem;
    }
    .y_my_edit_profile_10{
        height: auto;
        align-items: flex-start;
        align-items: center;
    }
    .y_my_edit_profile_11{
        width: 100%;
        border-bottom: 0.01rem solid #EDEDED;
    }
    .y_my_edit_profile_12{
        width: 100%;
    }
    .y_my_edit_profile_12 h2{
        line-height: 1.1rem;
        color: #999;
        font-size: 0.3rem;
    }
    .y_my_edit_profile_13{
        width: 100%;
        padding-bottom: 0.5rem;
    }
    .y_my_edit_profile_14{
        width: 1rem;
        height: 1rem;
    }
    .y_my_edit_profile_14 img{
        width: 100%;
        object-fit: cover;
    }
    .y_my_edit_profile_15{
        width: 100%;
        height: 1.8rem;
        flex-wrap: wrap;
        display: flex;
        align-content: center;
    }
    .y_my_edit_profile_16{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .y_my_edit_profile_17 h2{
        font-size: 0.3rem;
        color: #999;
    }
    .y_my_edit_profile_18{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .y_my_edit_profile_18 img{
        width: 0.4rem;
        height: 0.4rem;
    }
    .y_my_edit_profile_18 p{
        margin-left: 0.2rem;
        font-size: 0.3rem;
        color: #333;
    }
    .y_my_edit_profile_19{
        width: 100%;
        margin-top: 0.3rem;
    }
    .y_my_edit_profile_19 p{
        font-size: 0.24rem;
        color: #666;
    }
    .y_my_edit_profile_19 .y_my_edit_profile_20{
        color: #FECE0A;
        font-size: 0.24rem;
    }
    .y_my_edit_profile_21{
        width: 100%;
        height: 2.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .y_my_edit_profile_21 p{
        margin: 0rem 0.25rem;
        line-height: 1rem;
        background: #FECE0A;
        border-radius: 0.1rem;
        text-align: center;
        color: #333;
        font-size: 0.32rem;
        display: block;
        width: calc(100% - 0.5rem);
        font-weight: 600;
    }
</style>
<script>
    import axios from 'axios'   // 接口
    import {info} from '../../api/request.js' // 用户信息
    import {edit_user} from '../../api/request.js' // 编辑用户资料
    
    export default {
        data(){
            return {
                token:'', // token
                business_head_portrait:'',  // 头像
                business_nicname: '',  // 名称
                business_brief:'' ,  // 自我介绍
                business_homepage_bg_img:'', // 主页图片
                // business_gallery:'' , 
                fileList: [],   // 多图
                fileList_list:[],
                company_name:'', // 公司名称
                // fileList_chuan:[],  // 传多图
            }
        },  
        components:{
            
        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            // 用户信息
            info({token:token}).then((res) =>{
                console.log(res,'用户信息')
                this.business_head_portrait = res.data.business_head_portrait
                this.business_nicname = res.data.business_nicname
                this.business_brief = res.data.business_brief
                this.business_homepage_bg_img = res.data.business_homepage_bg_img
                this.company_name = res.data.company_name
                let fileList = res.data.business_gallery
                if(fileList != false){
                    for(let i=0;i<fileList.length;i++){
                        fileList[i] = {url:''+fileList[i]}
                    }
                    this.fileList = fileList
                    this.fileList_list = fileList
                }
            })
        },
        methods: {
           // 头像上传成功
           tx_success(res, file) {
                console.log(res,file)
                let business_head_portrait = res.img
                this.business_head_portrait = business_head_portrait
            },
            // 头像上传设置
            tx_settings(file) {
                console.log(file)
            },
            // 主页背景 上传成功
            zybj_success(res, file) {
                console.log(res,file)
                let business_homepage_bg_img = res.img
                this.business_homepage_bg_img = business_homepage_bg_img
            },
            // 主页背景 上传设置
            zybj_settings(file) {
                console.log(file)
            },

            // 图片上传
            // 删除图片
            handleRemove(file, fileList) {
                console.log(file, fileList);
                let fileList_list = []
                for(let i=0;i<fileList.length;i++){
                    fileList_list.push(fileList[i])
                }
                this.fileList_list = fileList_list 
                console.log(this.fileList_list,'删除图片')
            },
            // 上传成功
            hand_success(file,fileList){
                let fileList_list = this.fileList_list
                fileList_list.push({url:''+file.img})
                this.fileList_list = fileList_list
                console.log(this.fileList_list,'上传成功')
            },
            handlePreview(file,fileList) {
                console.log(file,fileList);
            },
            // 跳转高级认证
            jump_advanced(){
                this.$router.push({path:'my_advanced_certification_one'}) 
            },
            // 提交审核
            submit_review(){
                let token = this.token
                let business_head_portrait = this.business_head_portrait
                let business_nicname = this.business_nicname
                let company_name = ''
                let business_brief = this.business_brief
                let business_homepage_bg_img = this.business_homepage_bg_img

                let business_gallery = this.fileList
                let business_gallery2 = this.fileList_list
                let img_list = ''
                var reg1 = new RegExp("")
                // business_gallery = business_gallery.replace('/')
                console.log(business_gallery,business_gallery2,img_list)
                for(let i=0;i<business_gallery2.length;i++){
                    img_list = img_list +','+ business_gallery2[i].url.replace(reg1,"");
                }
        
                business_gallery2 = img_list.substr(1,img_list.length)
                console.log(business_gallery,business_gallery2)
                // return
                edit_user({token:token,business_head_portrait:business_head_portrait,business_nicname:business_nicname,company_name:company_name,business_brief:business_brief,business_homepage_bg_img:business_homepage_bg_img,business_gallery:business_gallery2}).then((res) =>{
                    console.log(res,'提交审核')
                    if(res.error_code == '0'){
                        this.$message({
                            message:'提交成功！',
                            duration:'1000'
                        })
                        var that = this;
                        setTimeout(function () {
                            that.$router.push({path:'/'}) 
                        },1000);
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'1000'
                        })
                    }
                    
                })
            }
        },
    }

</script>