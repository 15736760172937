<template>
  <div class="y_index">
    <div class='y_index_1'>
        <div class='y_index_2' @click="tuisong_show()">如何推送？</div>
        <div class='y_index_2' @click="shoufei_show()">如何收费？</div>
    </div>
    <!-- 如何推送？ -->
    <div class="y_tuisong_1" :class="pop_tuisong == '0' ? 'y_display_none':'y_display_block'">
        <div class="y_tuisong_2">
          <div class="y_tuisong_3">
            <h2>如何推送？</h2>
          </div>
          <p v-html="business_how_to_push" class="y_tuisong_4"></p>
          <div class="y_tuisong_5">
            <p @click="tuisong_hide()">知道了</p>
          </div>
        </div>
    </div>
    <!-- 如何收费？ -->
    <div class="y_tuisong_1" :class="pop_shoufei == '0' ? 'y_display_none':'y_display_block'">
        <div class="y_tuisong_2">
          <div class="y_tuisong_3">
            <h2>如何收费？</h2>
          </div>
          <p v-html="business_how_to_charge" class="y_tuisong_4"></p>
          <div class="y_tuisong_5">
            <p @click="shoufei_hide()">知道了</p>
          </div>
        </div>
    </div>

    <div class="y_recruit_1">
      <img src="../../../images/img_3.png" alt="">
    </div>

    <!-- 判断是否是从帖子跳过来的 -->
    <div class="y_topics_select_two_1" :class="type_name == undefined ?'y_display_none':''">
        <div class="y_topics_select_two_2" :class="item.active == '0'? '':'y_topics_select_two_2_active'" v-for="item in top_list" :key="item.index" @click="topics_pop(item.index)">
            {{item.name}}
        </div>
    </div>
    <!-- 判断是否是从帖子跳过来的 -->
    <div class='y_recruit_2'> 
      <div class='y_recruit_3'>
        <div class='y_recruit_4'>
          <span>*</span>
          <h2>联系方式</h2>
        </div>
        <div class='y_recruit_5' @click="pop_up_one">
          <p>{{pop_one_is == '0'? '点击填写':'已填写'}}</p>
          <img src="../../../images/icon_12.png" alt="">
        </div>
      </div>
      <div class='y_recruit_3'>
        <div class='y_recruit_4'>
          <span>*</span>
          <h2>职位标题</h2>
        </div>
        <div class='y_recruit_5 y_recruit_6'>
          <!-- <input type="text" placeholder="- 请输入"> -->
          <el-input v-model="zhiwei_title_input" placeholder="请输入"></el-input>
        </div>
      </div>
      <div class='y_recruit_3'>
        <div class='y_recruit_4'>
          <span>*</span>
          <h2>工作性质</h2>
        </div>
        <div class='y_recruit_5' @click="pop_up_two">
          <p>{{ pop_two_is == '0'?'点击选择':pop_two_value}}</p>
          <img src="../../../images/icon_12.png" alt="">
        </div>
      </div>
      <div class='y_recruit_3'>
        <div class='y_recruit_4'>
          <span>*</span>
          <h2>岗位类型</h2>
        </div>
        <div class='y_recruit_5' @click="pop_three_block">
          <p>{{leixing_value}}{{ pop_three_rights_id.length > '1' ?'...':''}}</p>
          <img src="../../../images/icon_12.png" alt="">
        </div>
      </div>
      <div class='y_recruit_7'> 
        <h2 class='y_recruit_8'><span>*</span>工作内容</h2>
        <div class='y_recruit_9'>
          <!-- <textarea name="" id="" cols="30" rows="10" placeholder="请输入"></textarea> -->
          <el-input type="textarea" :rows="2" placeholder="请输入" v-model="textarea_value"></el-input>
        </div>
      </div>
      <div class='y_recruit_7'> 
        <h2 class='y_recruit_8'><span>*</span>工作时间</h2>
        <div class='y_recruit_9'>
          <!-- <textarea name="" id="" cols="30" rows="10" placeholder="请输入"></textarea> -->
          <el-input type="textarea" :rows="2" placeholder="请输入" v-model="textarea_value_two"></el-input>
        </div>
      </div>
      <!-- 展示隐藏 不要了 -->
      <!-- <div class='y_recruit_3'>
        <div class='y_recruit_4'>
          <span>*</span>
          <h2>工作时间</h2>
        </div>
        <div class='y_recruit_5' @click="pop_up_four">
          <p>点击设定</p>
          <img src="../../../images/icon_12.png" alt="">
        </div>
      </div> -->
      <div class='y_recruit_3'>
        <div class='y_recruit_4'>
          <span>*</span>
          <h2>工作地点</h2>
        </div>
        <div class='y_recruit_10'>
          <div class='y_recruit_11'>
            <van-dropdown-menu>
              <van-dropdown-item v-model="value1" :options="option1" class='y_recruit_12'/>
            </van-dropdown-menu>
          </div>
          <!-- <div class='y_recruit_5'>
            <p>点击设定</p>
            <img src="../../../images/icon_12.png" alt="">
          </div> -->
        </div>
      </div>
      <div class='y_recruit_3' v-show="value1 == '0'?false:true" @click="map_show">
        <div class='y_recruit_4'>
          <span>*</span>
          <h2>定位工作地址</h2>
        </div>
        <div class='y_recruit_5 y_add_search_3'>
          <p>{{form.address}}</p>
        </div>
      </div>
      <!-- 线下时显示 -->
      <div class="y_offline_1" v-show="value1 == '0'?false:true">
        <div class='y_recruit_7'> 
          <h2 class='y_recruit_8'>工作地址补充说明</h2>
          <div class='y_recruit_9'>
            <!-- <textarea name="" id="" cols="30" rows="10" placeholder="请输入"></textarea> -->
            <el-input type="textarea" :rows="2" placeholder="请输入" v-model="textarea_value_four"></el-input>
          </div>
        </div>
      </div>
      <!-- <div class='y_recruit_3' v-if="value1 == '0'?false:true">
        <div class='y_recruit_4'>
          <span>*</span>
          <h2>经度</h2>
        </div>
        <div class='y_recruit_5 y_recruit_6'>
          <p>{{longitude_value}}</p>
        </div>
      </div> -->
      <!-- <div class='y_recruit_3' v-if="value1 == '0'?false:true">
        <div class='y_recruit_4'>
          <span>*</span>
          <h2>纬度</h2>
        </div>
        <div class='y_recruit_5 y_recruit_6'>
          <p>{{latitude_value}}</p>
        </div>
      </div> -->

      <div class='y_recruit_3'>
        <div class='y_recruit_4'>
          <span></span>
          <h2>工作要求</h2>
        </div>
        <div class='y_recruit_5' @click="pop_up_six">
          <p>点击设定</p>
          <img src="../../../images/icon_12.png" alt="">
        </div>
      </div>
      <div class='y_recruit_3'>
        <div class='y_recruit_4'>
          <span>*</span>
          <h2>招聘人数</h2>
        </div>
        <div class='y_recruit_5 y_recruit_6'>
          <el-input type="number" placeholder="请输入" v-model="recruiting_number"></el-input>
        </div>
      </div>
      <div class='y_recruit_3'>
        <div class='y_recruit_4'>
          <span>*</span>
          <h2>薪资待遇</h2>
        </div>
        <div class='y_recruit_5' @click="pop_up_eight">
          <p>点击设定</p>
          <img src="../../../images/icon_12.png" alt="">
        </div>
      </div>
      <div class='y_recruit_12'>
        <div class='y_recruit_13'>
          <el-upload class="upload-demo" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :on-preview="handlePreview" :on-success="hand_success" :on-remove="handleRemove" :file-list="fileList" list-type="picture">
            <div class='y_recruit_14'>
              <img src="../../../images/icon_14.png" alt="">
            </div>
          </el-upload>
        </div>
      </div>
    </div>
  </div>

  <div class='y_recruit_foot_1' v-show="hideshow">
    <div class='y_recruit_foot_2'>
      <router-link to="/" class='y_recruit_foot_3'>
        <p>返回</p>
      </router-link>
      <!-- <div class='y_recruit_foot_4'>
        <div class='y_recruit_foot_5'>  
          <img src="../../../images/icon_15.png" alt="">
        </div>
        <div class='y_recruit_foot_6'>预览</div>
      </div> -->
    </div>
    <div class='y_recruit_foot_7' @click="pop_up_next">
      <p>下一步(1/2)</p>
    </div>
  </div>

  <!-- 公共弹窗   遮罩层 -->
  <div class='y_pop_masking' :class='pop_masking == 0 ? "":"y_masking_block"'></div>
  <!-- 地图弹窗 -->
  <div class="y_map_pop_1" :class='map_pop == 0?"y_display_opacity":""'>
    <div class="y_add_search_0">
      <div>
        <div class="y_add_search_1">
          <el-select v-model="keywords" filterable remote reserve-keyword placeholder="请输入关键词" :remote-method="remoteMethod" :loading="loading" :clearable="true" size="mini" @change="currentSelect" ref="select" @focus="clear" @hook:mounted="clear" @visible-change="clear">
            <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item" class="one-text">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.district }}</span>
            </el-option>
          </el-select>
        </div>
        <div id="container" class="container y_add_search_2"></div>
      </div>
    </div>
    <div class="y_map_pop_2">
      <p class="y_map_pop_3" @click="map_cancel">取消</p>
      <p class="y_map_pop_4" @click="map_determine">确定</p>
    </div>
  </div>

  <!-- 联系方式 弹窗 第一个 -->
  <div class='y_pop_one_1' :class='pop_one == 0 ? "y_display_none":"y_display_block"'>
    <div class='y_pop_one_2'>
      <div class='y_pop_one_3' @click="pop_one_none">
        <img src="../../../images/icon_16.png" alt="">
      </div>
      <div class='y_pop_one_4' @click='pop_one_dete'>
        <p>确定</p>
      </div>
    </div>
    <div class='y_pop_one_5'>
      <h2>请输入联系方式</h2>
      <p>必填一个，可留多个联系方式</p>
    </div>
    <div class='y_pop_one_6'>
      <div class='y_pop_one_7'>
        <h2>手机号</h2>
        <input type="number" placeholder="请输入" v-model="pop_one_tel_value">
      </div>
      <div class='y_pop_one_7'>
        <h2>微信号</h2>
        <input type="text" placeholder="请输入" v-model="pop_one_wechat_value">
      </div>
      <div class='y_pop_one_7'>
        <h2>固定电话</h2>
        <input type="number" placeholder="请输入" v-model="pop_one_phone_value">
      </div>
      <div class='y_pop_one_7'>
        <h2>QQ号</h2>
        <input type="number" placeholder="请输入" v-model="pop_one_qq_value">
      </div>
      <div class='y_pop_one_7'>
        <h2>邮箱</h2>
        <input type="text" placeholder="请输入" v-model="pop_one_email_value">
      </div>
    </div>
  </div>
  <!-- 工作性质 弹窗 第二个 -->
  <div class='y_pop_two_1' :class='pop_two == 0 ? "y_display_none":"y_display_block"'>
    <div class='y_pop_two_3' @click="pop_two_none">
      <img src="../../../images/icon_17.png" alt="">
    </div>
    <div class='y_pop_two_2'>
      <p v-for='pop_two in pop_twos' :key='pop_two.id' @click="pop_two_select(pop_two.id)" :value='pop_two.text'>{{pop_two.title}}</p>
    </div>
  </div>
   <!-- 岗位类型 弹窗 第3个 -->
  <div class='y_pop_three_1' :class="pop_three == 0 ? 'y_display_none':'y_display_block'">
    <div class='y_pop_six_2'>
      <div class='y_pop_six_3' @click="pop_three_none">
        <img src="../../../images/icon_16.png" alt="">
      </div>
      <div class='y_pop_six_4'>
        <h2>岗位类型</h2>
      </div>
      <div class='y_pop_six_5' @click='pop_three_determine'>
        <p>确定</p>
      </div>
    </div>
    <div class='y_pop_three_3' @click="pop_three_search_block">
      <div class='y_pop_three_4'>
        <img src="../../../images/icon_18.png" alt="">
      </div>
      <div class='y_pop_three_5'>
        <input type="text" placeholder="请输入想招聘的职位名称" value="">
      </div>
    </div>
    <div class='y_pop_three_2'>
      <div class='y_pop_three_6'>
        <p :class='pop_three_left.is == "0"? "":"y_pop_three_left_active"' v-for='pop_three_left in pop_three_lefts' :key="pop_three_left.id" @click="pop_three_left_click(pop_three_left.id)">{{pop_three_left.title}}</p>
      </div>
      <div class='y_pop_three_7'>
        <p :class='pop_three_right.is == "0"? "":"y_pop_three_right_active"' v-for="pop_three_right in pop_three_rights" :key="pop_three_right.id" @click="pop_three_right_click(pop_three_right.id)">{{pop_three_right.title}}</p>
      </div>
    </div>
  </div>
  <div class='y_pop_therr_search_1' :class='pop_three_search == "0"?"y_display_none":""'>
    <div class='y_pop_therr_search_2'>
      <div class='y_pop_therr_search_3'>
        <div class='y_pop_therr_search_4'>
          <img src="../../../images/icon_18.png" alt="">
        </div>
        <div class='y_pop_therr_search_5'>
          <!-- <input type="text" placeholder="请输入想招聘的职位名称"> -->
          <el-input v-model="zhaopin_input" placeholder="请输入想招聘的职位名称" @input="zhaopin_value"></el-input>
        </div>
      </div>
      <div class='y_pop_therr_search_6' @click="pop_three_search_none">
        <p>取消</p>
      </div>
    </div>
    <div class='y_pop_therr_search_7'>
      <div class='y_pop_therr_search_8' v-for="input_search in input_search_lish" :key="input_search.id" @click="pop_three_search_data(input_search.id)">
        <h2>{{input_search.parent_title}}</h2>
        <p>{{input_search.parent_title}}>{{input_search.title}}</p>
      </div>
    </div>
  </div>
  <!-- 工作时间 弹窗 第4个 -->
  <div class='y_pop_four_1' :class='pop_four == 0 ? "y_display_none":"y_display_block"'>
    <div class='y_pop_one_2'>
      <div class='y_pop_one_3' @click="pop_four_none">
        <img src="../../../images/icon_16.png" alt="">
      </div>
      <div class='y_pop_one_4' @click='pop_four_determine'>
        <p>确定</p>
      </div>
    </div>
    <div class='y_pop_four_1_1'>
      <div class='y_pop_four_1_2'>
        <p :class='pop_four_one_list.is == "0"?"":"y_pop_four_1_active"' @click="pop_four_one_select(pop_four_one_list.id)" v-for="pop_four_one_list in pop_four_one_lists" :key="pop_four_one_list.id">{{pop_four_one_list.text}}</p>
      </div>
      <div class='y_pop_four_1_3'>
        <div class='y_pop_four_1_left_list_1' :class='pop_four_one_value == "0" ? "y_display_flex":"y_display_none"'>
          <p :class='pop_four_1_left.is == "0"?"":"y_pop_four_1_left_active"' v-for="pop_four_1_left in pop_four_1_lefts" :key="pop_four_1_left.id" @click="pop_four_1_left_select(pop_four_1_left.id)">{{pop_four_1_left.text}}</p>
        </div>
        <div class='y_pop_four_1_right_list_1' :class='pop_four_one_value == "0" ? "y_display_none":"y_display_flex"'>
          <van-cell title=""  class='y_pop_four_1_right_list_2' :value="date_start" @click="show = true" />
          <van-calendar v-model:show="show" type="range" @confirm="onConfirm" color='#FECE0A'/>
          <div class='y_pop_four_1_right_list_3'>
            <p :value="date_start">{{date_start}}</p>
            <span></span>
            <p :value="date_end">{{date_end}}</p>
          </div>
        </div>

      </div>
      <div class='y_pop_four_1_2'>
        <p class="y_pop_four_1_active">工作时段</p>
      </div>
      <div class='y_pop_four_2_1'>
        <p v-for="pop_four_two_list in pop_four_two_lists" :class='pop_four_two_list.is == "0"? "":"y_pop_four_2_right_active"' :key="pop_four_two_list.id" @click='pop_four_two_select(pop_four_two_list.id)'>{{pop_four_two_list.text}}</p>
      </div>
      <div class='y_pop_four_2_2'>
        <div class='y_pop_four_2_3' :class='pop_four_two_value == "0" ? "y_display_flex":"y_display_none"'></div>
        <div class='y_pop_four_2_4' :class='pop_four_two_value == "0" ? "y_display_none":"y_display_flex"'>
            <p :value='pop_four_one_time' :data-value='result_four_one' @click="pop_four_time1">{{result_four_one}}</p>
            <span></span>
            <p :value='pop_four_two_time' :data-value='result_four_two' @click="pop_four_time2">{{result_four_two}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class='y_pop_four_2_one_1' :class='pop_four_one_time == "0"?"y_pop_four_2_one_1_hide":""'>
    <div class='y_pop_four_2_one_2'>
      <van-field v-model="result_four_one" is-link readonly name="datetimePicker" label="时间选择" placeholder="点击选择时间"/>
      <van-popup position="bottom">
        <van-datetime-picker type="time" @confirm="onConfirm_four_one" @cancel="showPicker_four_one"/>
      </van-popup>
    </div>
  </div>
   <div class='y_pop_four_2_one_1' :class='pop_four_two_time == "0"?"y_pop_four_2_one_1_hide":""'>
    <div class='y_pop_four_2_two_2'>
      <van-field v-model="result_four_two" is-link readonly name="datetimePicker" label="时间选择" placeholder="点击选择时间"/>
      <van-popup position="bottom">
        <van-datetime-picker type="time" min-hour='5' @confirm="onConfirm_four_two" @cancel="showPicker_four_two"/>
      </van-popup>
    </div>
  </div>
  
  <!-- 工作要求 弹窗 第6个 -->
  <div class='y_pop_six_1' :class='pop_six == 0 ? "y_display_none":"y_display_block"'>
    <div class='y_pop_six_2'>
      <div class='y_pop_six_3' @click="pop_six_none">
        <img src="../../../images/icon_16.png" alt="">
      </div>
      <div class='y_pop_six_4'>
        <h2>工作要求</h2>
      </div>
      <div class='y_pop_six_5' @click='pop_six_determine'>
        <p>确定</p>
      </div>
    </div>
    <div class='y_pop_six_6'>
      <div class='y_pop_six_7'>
        <h2>性别</h2>
        <div class='y_pop_six_8'>
          <p v-for="pop_six_sex in pop_six_sexs" :key="pop_six_sex.id" :class="pop_six_sex.is == '0'? '':'pop_six_on'" @click="pop_six_sex_select(pop_six_sex.id)" :value="pop_six_sex.id">{{pop_six_sex.text}}</p>
        </div>
      </div>
      <div class='y_pop_six_7'>
        <h2>学历(可多选)</h2>
        <div class='y_pop_six_8'>
          <p v-for="pop_six_education in pop_six_educations" :key="pop_six_education.id" :class="pop_six_education.is == '0'? '':'pop_six_on'" @click="pop_six_education_select(pop_six_education.id)" :value="pop_six_education.id">{{pop_six_education.title}}</p>
        </div>
      </div>
      <div class='y_pop_six_9'>
        <!-- <textarea name="" id="" cols="30" rows="10" :value='pop_six_text' placeholder="其他要求"></textarea> -->
        <el-input type="textarea" :rows="2" placeholder="其他要求" v-model="textarea_value_three"></el-input>
      </div>
    </div>
  </div>
  <!-- 薪资待遇 弹窗 第8个 -->
  <div class='y_pop_eight_1' :class="pop_eight == '0'?'y_display_none':'y_display_block'">
    <div class='y_pop_eight_5'>
      <div class='y_pop_eight_2'>
        <div class='y_pop_eight_3'>
          <p :class='eight_salary_select == "0"? "y_pop_eight_3_active":""' @click="eight_salary(0)">固定薪资</p>
          <p :class='eight_salary_select == "1"? "y_pop_eight_3_active":""' @click="eight_salary(1)">范围薪资</p>
          <p :class='eight_salary_select == "2"? "y_pop_eight_3_active":""' @click="eight_salary(2)">自定义薪资</p>
        </div>
        <div class='y_pop_eight_4' @click="pop_eight_block">
          <p>+有提成</p>
        </div>
      </div>
      <div class='y_pop_eight_6'>
        <div class='y_pop_eight_left_1' :class='eight_salary_select == "0"? "":"y_pop_eight_6_active"'>
          <div class='y_pop_eight_left_2'>
            <el-input type="number"  v-model="fixed_salary" name="" id="" placeholder="请输入薪资"></el-input>
          </div>
          <div class='y_pop_eight_left_3' @click="pop_right_point_block">
            <p>{{result_point}}</p>
            <div class='y_pop_eight_left_4'>
              <img src="../../../images/icon_13.png" alt="">
            </div>
          </div>
        </div>
        <div class='y_pop_eight_right_1' :class='eight_salary_select == "1"? "":"y_pop_eight_6_active"'>
          <div class='y_pop_eight_right_2'>
            <div class='y_pop_eight_right_3'>
              <el-input type="number" v-model="min_salary" placeholder="请输入最小薪资"></el-input>
            </div>
            <p>至</p>
            <div class='y_pop_eight_right_3'>
              <el-input type="number" v-model="max_salary" placeholder="请输入最大薪资"></el-input>
            </div>
          </div>
          <div class='y_pop_eight_left_3' @click="pop_right_point_block">
            <p>{{result_point}}</p>
            <div class='y_pop_eight_left_4'>
              <img src="../../../images/icon_13.png" alt="">
            </div>
          </div>
        </div>
        <div class='y_pop_eight_right_1' :class='eight_salary_select == "2"? "":"y_pop_eight_6_active"'>
          <div class='y_pop_eight_left_2' style='width: 100%;margin-right: 0rem;'>
            <el-input type="text" v-model="self_salary" name="" id="" placeholder="请输入自定义薪资说明"></el-input>
          </div>
        </div>
      </div>
      <div class='y_pop_eight_7'>
        <h2>结算方式</h2>
        <div class='y_pop_eight_8'>
          <p :class='pop_eight.is == "1"?"y_pop_eight_8_active":""' v-for="pop_eight in pop_eights" :key="pop_eight.id" @click="pop_eight_dele(pop_eight.id)">{{pop_eight.text}}</p>
        </div>
      </div>
      <div class='y_pop_eight_9'>
        <div class='y_pop_eight_10'>
          <div class='y_pop_eight_11'>
            <p>福利（可选，多选）</p>
          </div>
          <div class='y_pop_eight_12'>
            <div class='y_pop_eight_13' @click="pop_eight_management">
              <p>{{eight_management_text}}</p>
            </div>
            <div class='y_pop_eight_14' @click="pop_eight_benefits_block">
              <p>+自定义</p>
            </div>
          </div>
        </div>
        <div class='y_pop_eight_15'>
          <div class='y_pop_eight_16' v-for="eight_checkbox in eight_checkboxs" :key="eight_checkbox.id" @click="eight_checkbox_dele(eight_checkbox.id)">
            <div class='y_pop_eight_17' :class='eight_checkbox.is == "1"? "y_pop_eight_17_active":""'>
              <img src="../../../images/icon_19.png" alt="">
              <img src="../../../images/icon_20.png" alt="">
            </div>
            <div class='y_pop_eight_18' :class='eight_checkbox.box == "1"? "y_pop_eight_15_box":""'>
              <img src="../../../images/icon_16.png" alt="">
            </div>
            <p>{{eight_checkbox.title}}</p>
          </div>
        </div>
      </div>
      <div class='y_pop_eight_19' v-show="hideshow">
        <p class='y_pop_eight_19_1' @click="pop_eight_dete_none">取消</p>
        <p class='y_pop_eight_19_2' @click="pop_eight_dete">确定</p>
      </div>
    </div>
  </div>
  <!-- 第八弹窗 公共 阴影 -->
  <div class='y_eight_commission_yy' :class='pop_eight_yy == "0"?"y_pop_eight_yy":""'></div>
  <div class='y_eight_commission_yy' :class='pop_eight_danjia == "0"?"y_pop_eight_point_none":"y_pop_eight_point_block"'></div>
  <!-- 设置提成 -->
  <div class='y_eight_commission_pop_1' :class='pop_eight_shezhi == "0"?"y_pop_eight_yy":""'>
    <div class='y_pop_six_2'>
      <div class='y_pop_six_3' @click="pop_eight_none">
        <img src="../../../images/icon_16.png" alt="">
      </div>
      <div class='y_pop_six_4'>
        <h2>设置提成</h2>
      </div>
      <div class='y_pop_six_5' @click='pop_eight_determine'>
        <p>确定</p>
      </div>
    </div>
    <div class='y_eight_commission_pop_2'>
      <div class='y_eight_commission_pop_3'>
        <p @click="commission_dele(setup_commission.id)" v-for="setup_commission in setup_commissions" :key="setup_commission.id" :class="setup_commission.is == '1'?'y_eight_commission_pop_3_active':''">{{setup_commission.text}}</p>
      </div>
      <div class='y_eight_commission_pop_4'>
        <div class='y_eight_commission_pop_5' :class='setup_commissions[0].is == "1"?"y_eight_commission_pop_5_active":""'>
          <el-input type="text" placeholder="请输入提成百分比" v-model="propose_one" ></el-input>
          <p>%</p>
        </div>
        <div class='y_eight_commission_pop_5' :class='setup_commissions[1].is == "1"?"y_eight_commission_pop_5_active":""'>
          <el-input type="text" placeholder="请输入金额" v-model="propose_two" ></el-input>
          <p>元/件</p>
        </div>
        <div class='y_eight_commission_pop_5' :class='setup_commissions[2].is == "1"?"y_eight_commission_pop_5_active":""'>
          <el-input type="text" placeholder="例：销售额的10%" v-model="propose_three" ></el-input>
        </div>
      </div>
    </div>

  </div>
  <!-- 元/小时 弹窗 选择 -->
  <div class='y_pop_eight_point' :class='pop_eight_danjia == "0"? "" : "y_pop_eight_point_block"'>
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker :columns="columns" @confirm="onConfirm_eight_point_block" @cancel="showPicker_eight_point_none"  />
    </van-popup>
  </div>
  <!-- 自定义福利 弹窗 -->
  <div class='y_pop_eight_benefits' :class='pop_eight_benefits == "0"?"":"y_pop_eight_benefits_block"'>
    <div class='y_pop_six_2'>
      <div class='y_pop_six_3' @click="pop_eight_benefits_none">
        <img src="../../../images/icon_16.png" alt="">
      </div>
      <div class='y_pop_six_4'>
        <h2>自定义福利</h2>
      </div>
      <div class='y_pop_six_5' @click="eight_benefits_dete">
        <p>确定</p>
      </div>
    </div>
    <div class='y_pop_eight_benefits2'>
      <input type="text" placeholder="请输入自定义福利" v-model="input_eight" v-on:input="change_eight">
      <!-- <p>0/6</p> -->
    </div>
  </div>
  <!-- 下一步 是否保留此次编辑在“消息-待推送”中 -->
  <div class='y_pop_next_1' :class='pop_next == "0"?"y_display_none":"y_display_block"'>
    <div class='y_pop_next_6'>
      <div class='y_pop_next_2'>
        <p>是否保留此次编辑<br>在“消息-待推送”中</p>
      </div>
      <div class='y_pop_next_3'>
        <p class='y_pop_next_4' @click="pop_next_none">不保留</p>
        <p class='y_pop_next_5' @click="pop_next_none">保留</p>
      </div>
    </div>
  </div>
</template>
<script>
  import './style.css'
  import axios from 'axios'   // 接口
  import {job_list} from '../../api/request.js'
  import {job_search_list} from '../../api/request.js'
  import {education_list} from '../../api/request.js'
  import {welfare_list} from '../../api/request.js'       // 福利列表
  import {welfare_list_add} from '../../api/request.js'   // 福利 添加
  import {welfare_list_remove} from '../../api/request.js'   // 福利 删除
  import {file_add} from '../../api/request.js'   // 图片上传
  import {configuration_fields} from '../../api/request.js'   // 配置字段
  import {recruit_common_info} from '../../api/request.js'   // 招聘详情,普通推广
  import {recruit_info_info} from '../../api/request.js'   // 招聘详情,信息类推广
  import {last_recruit_info} from '../../api/request.js'   // 最新一条招聘的联系方式
  import AMapLoader from "@amap/amap-jsapi-loader";
  window._AMapSecurityConfig = {
    // 安全密钥
    securityJsCode: "6379b3cce15f6de8d9a891f8b0033fd8",
  };
  import { ref } from 'vue';
  import { Dialog } from "vant"
  export default {
    name: 'history',
    data(){
      return {
          docmHeight: document.documentElement.clientHeight ||document.body.clientHeight,
          showHeight: document.documentElement.clientHeight ||document.body.clientHeight,
          hideshow:true,  //显示或者隐藏footer


          pop_tuisong:'0',
          pop_shoufei:'0',
          // 如何收费
          business_how_to_charge:'',
          // 如何推送
          business_how_to_push:'',
          fileList: [
            // {name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, 
            // {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}
          ],
          top_list:[
              {
                  index:'0',
                  name:'推广',
                  active:'0',
              },
              {
                  index:'1',
                  name:'招生', 
                  active:'0',
              },
              {
                  index:'2',
                  name:'招聘',
                  active:'1',
              },
          ],
          fileList_list:[],
          pop_masking:'0',   // 公共弹窗 遮罩层
          pop_one:'0',      // 联系方式 弹窗
          pop_one_tel_value:'',
          pop_one_wechat_value:'',
          pop_one_phone_value:'',
          pop_one_qq_value:'',
          pop_one_email_value:'',
          pop_one_contact_list:'',
          pop_one_is:'0',
          // 联系方式 第二个弹窗
          pop_twos:[

          ],
          pop_twos_id:'',  // 第一级 id
          pop_threes_left_id:'', // 第二级 id
          pop_threes_right_id:'', // 第三级 id
          pop_two:'0',
          pop_two_is:'0',
          pop_two_value:'',
          // 岗位类型 第三弹窗
          pop_three_lefts:[

          ],
          pop_three_rights:[
       
          ],
          pop_three_rights_id:[],

          leixing_value:'点击选择',  // 岗位类型
          pop_three:'0',
          pop_three_search:'0',
          left_value:'',
          right_value:'',
          // 工作时间 弹窗 第4个 第一个
          pop_four:'0',
          pop_four_one_lists:[
            {id:'0',text:'长期兼职',is:'1'},
            {id:'1',text:'短期兼职',is:'0'},
          ],
          pop_four_1_lefts:[
            {id:'1',text:'任意日期',is:'1'},
            {id:'2',text:'周末节假日',is:'0'},
            {id:'3',text:'工作日',is:'0'},
            {id:'4',text:'依据个人时间安排',is:'0'},
          ],
          pop_four_one_value:'0',
          pop_four_start_time:'',
          pop_four_end_time:'',
          date_start:'',
          date_end:'',
          // 工作时间 弹窗 第4个 第二个
          pop_four_two_lists:[
            {id:'0',text:'不限',is:'1'},
            {id:'1',text:'自定义',is:'0'},
          ],
          pop_four_two_value:'0',
          pop_four_one_time:'0',
          result_four_one:'',

          pop_four_two_time:'0',
          result_four_two:'',
          // 工作要求 第6个 弹窗
          pop_six_sexs:[
            {id:'1',text:'不限',is:'1',value:'none'},
            {id:'2',text:'男',is:'0',value:'male'},
            {id:'3',text:'女',is:'0',value:'female'},
          ],
          pop_six_educations:[
            {id:'1',text:'不限',is:'1'},
            {id:'2',text:'专科',is:'0'},
            {id:'3',text:'本科',is:'0'},
            {id:'4',text:'研究生',is:'0'},
          ],
          pop_six_educations_id:'',
          pop_six_text:'',
          pop_six:'0',
          pop_six_id:'1',
          // 薪资待遇 弹窗 第8个
          eight_salary_select:'0',
          pop_eights:[
            {id:'1',text:'日结',is:'1',value:'day'},
            {id:'2',text:'周结',is:'0',value:'week'},
            {id:'3',text:'月结',is:'0',value:'month'},
            {id:'4',text:'完工结',is:'0',value:'finish'},
            {id:'5',text:'面议',is:'0',value:'face'},
          ],
          // 福利选择
          eight_checkboxs:[
            {id:'1',text:'包住宿',is:'0',box:'0'},
            {id:'2',text:'包工作餐',is:'0',box:'0'},
            {id:'3',text:'交通补助',is:'0',box:'0'},
            {id:'4',text:'加班补助',is:'0',box:'0'},
            {id:'5',text:'有提成',is:'0',box:'0'},
            {id:'6',text:'奖金奖励',is:'0',box:'0'},
            {id:'7',text:'转正机会',is:'0',box:'0'},
          ],
          eight_checkboxs_id:[],
          // 设置提成
          setup_commissions:[
            {id:'1',text:'业绩提成',is:'1'},
            {id:'2',text:'计件提成',is:'0'},
            {id:'3',text:'自定义',is:'0'},
          ],
          pop_eight_yy:'0',
          pop_eight_shezhi:'0',
          pop_eight_danjia:'0',
          result_point:'元/小时',
          input_eight: "",
          pop_eight_benefits:'0',
          eight_management_text:'管理',
          pop_eight:'0',
          // 下一步
          pop_next:'0',
          // 招聘 input输入值
          zhaopin_input:'',
          // 招聘 获取列表
          input_search_lish:'',
          // 职位标题 input输入值
          zhiwei_title_input:'',
          // 工作内容 textarea 输入框
          textarea_value:'',
          // 工作时间 textarea 输入框
          textarea_value_two:'',
          // 工作要求 textarea 输入框
          textarea_value_three:'',
          // 线下详细地址 textarea 输入框
          textarea_value_four:'',
          // 经度
          longitude_value:'',
          // 纬度
          latitude_value:'',
          work_place_type:'', // 传 线上还是下线
          sex_demand:'',    // 传 性别
          salary_type:'',  // 传 薪资方式
          fixed_salary:'',  // 固定薪资
          min_salary:'',  // 范围 最小薪资数值
          max_salary:'',  // 范围 最大薪资数值
          salary_unit:'元/小时', //  薪资单位
          self_salary:'',   // 自定义薪资说明
          pop_eights_value:'日结',   // 结算方式
          settle_type:'',  // 传  结算方式
          welfare_ids:'',  // 传  福利id
          propose_one:'',  // 业绩提成
          propose_two:'',  // 计件提成
          propose_three:'', // 自定义
          commission_type:'', // 提成方式
          recruiting_number:'', // 招聘人数
          type_name:'',  // 帖子传值

          recruit_id:'0', // 待推送 进入传id
          recruitment_value:'0', // 进入传value  0代表正常进入，1为待推送进入

          // 待推送 编辑 话题 招聘
          adspace_id:'0',       // 编辑 进入传id
          promotion_value:'0',  // 进入传value  0代表正常进入，1为待推送进入
          id_index:'',  // 判断话题，小组，活动 0 = 话题, 1 = 小组, 2 = 活动,
          info_ids:'', 
          from_table:'', // 帖子还是招聘

          // 地图实例
          map: null,
          // 标记点
          marker: "",
          // 地址逆解析
          geoCoder: null,
          // 搜索提示
          AutoComplete: null,
          // 搜索关键字
          keywords: "",
          // 位置信息
          form: {
            lng: "",
            lat: "",
            address: "",
            adcode: "", //地区编码
          },
          // 搜索节流阀
          loading: false,
          // 搜索提示信息
          options: [],

          map_pop:'0', // 高德地图显示与隐藏 0隐藏 1显示
          bj_news:'0', // 编辑新推送 0为正常进入 1是编辑新推送
          xxl_select:'', // 信息类 推送类型 topic话题，group小组，activity活动
      };
    },
    components:{
        axios,
    },
    activated() {
      if (!this.$route.meta.canKeep) {
      // 在这里发送请求，刷新数据
      }
    },
    watch: {
      //监听显示高度
      showHeight:function() {
        if(this.docmHeight > this.showHeight){
          //隐藏
          this.hideshow=false
        }else{
          //显示
          this.hideshow=true
        }
      }
    },
    created(){
        // 获取页面传参 推送类型
        this.type_name = this.$route.query.name;
        if(this.$route.query.recruit_id !== undefined){
          this.recruit_id = this.$route.query.recruit_id
          this.recruitment_value = this.$route.query.recruitment_value
          this.bj_news = this.$route.query.bj_news
        }
        // 获取页面传参 推送类型
        if(this.$route.query.adspace_id !== undefined){
            this.adspace_id = this.$route.query.adspace_id
            this.promotion_value = this.$route.query.promotion_value
            this.info_ids = this.$route.query.info_ids
            this.type_name = this.$route.query.name
            this.id_index = this.$route.query.index_index
            this.from_table = this.$route.query.from_table
            this.bj_news = this.$route.query.bj_news
        }
        if(this.$route.query.name !== undefined){
          this.xxl_select = this.$route.query.name
          this.promotion_value = this.$route.query.promotion_value
        }
    },
    mounted(){
      //监听事件
      window.onresize = ()=>{
          return(()=>{
              this.showHeight = document.documentElement.clientHeight || document.body.clientHeight;
          })()
      }

      setTimeout(() => {
        const { select } = this.$refs
        const input = select.$el.querySelector('.el-input__inner')
        input.removeAttribute('readonly')
      }, 300)
      let that = this
      this.initMap();

      let token = this.$cookies.get('token')
      if(token == null){
          this.$message({
              message: '请先登录！',
              duration:'2000'
          })
          setTimeout(function () {
              that.$router.push({path:'/login'}) 
          },2500);
      }
      that.token = token
      let recruit_id = this.recruit_id
      let recruitment_value = this.recruitment_value

      // 最新一条招聘的联系方式
      last_recruit_info({token:token}).then((res)=>{
        console.log(res,'最新一条招聘的联系方式')
        if(res.error_code == '0'){
          this.pop_one_tel_value = res.data.phone
          this.pop_one_wechat_value = res.data.weixin_number
          this.pop_one_phone_value = res.data.telephone
          this.pop_one_qq_value = res.data.qq
          this.pop_one_email_value = res.data.email
          this.pop_one_is = '1'
        }
      })
      if(recruitment_value == '1'){
        recruit_common_info({token:token,recruit_id:recruit_id}).then((res) =>{
          console.log(res,'招聘详情,普通推广')
          
          this.pop_one_tel_value = res.recruit.phone
          this.pop_one_wechat_value = res.recruit.weixin_number
          this.pop_one_phone_value = res.recruit.telephone
          this.pop_one_qq_value = res.recruit.qq
          this.pop_one_email_value = res.recruit.email
          this.pop_two_is = '1'
          this.form.address = res.recruit.work_place_title
          this.longitude_value = res.recruit.work_place_longitude
          this.latitude_value = res.recruit.work_place_latitude
          let pop_twos = this.pop_twos
          for(let i=0;i<pop_twos.length;i++){
            if(pop_twos[i].id == res.recruit.job_type_id_1){
              this.pop_two_value = this.pop_twos[i].title
            }
          }
          this.pop_twos_id = res.recruit.job_type_id_1
          this.pop_threes_left_id = res.recruit.job_type_id_2
          // this.pop_threes_right_id = res.recruit.job_type_id_3
          this.pop_three_rights_id = res.recruit.job_type_id_3
          let pop_three_lefts = this.pop_three_lefts
          job_list({pid:res.recruit.job_type_id_1}).then((res_one) => {
            if(res_one.error_code == '0'){
              pop_three_lefts = res_one.data
              for(let i=0;i<pop_three_lefts.length;i++){
                pop_three_lefts[i].is = '0'
                if(pop_three_lefts[i].id == this.pop_threes_left_id){
                  pop_three_lefts[i].is = '1'
                  this.left_value = pop_three_lefts[i].title
                }
              }
              this.pop_three_lefts = pop_three_lefts
              this.pop_three_rights = ''
              this.leixing_value = '点击选择'
            }else{
              this.pop_three_lefts = ''
              this.leixing_value = '点击选择'
              this.$message({
                message:res_one.msg,
                duration:'2000'
              })
            }
            
            let json_pop_threes_right_id = res.recruit.job_type_id_3
            job_list({pid:res.recruit.job_type_id_2}).then((res) => {
              if(res.error_code == '0'){
                let pop_three_rights = res.data
                for(let i=0;i<pop_three_rights.length;i++){
                  pop_three_rights[i].is = '0'
                  for(let k=0;k<json_pop_threes_right_id.length;k++){
                    if(pop_three_rights[i].id == json_pop_threes_right_id[k]){
                      pop_three_rights[i].is = '1'
                      this.right_value = pop_three_rights[i].title
                      this.leixing_value = (this.left_value+'-'+this.right_value)
                    }
                  }
                }
                this.pop_three_rights = pop_three_rights
              }else{
                this.pop_three_rights = ''
                this.leixing_value = '点击选择'
                this.$message({
                  message:res.msg,
                  duration:'2000'
                })
              }
            })
          })



          this.textarea_value_four = res.recruit.work_place_address
          this.zhiwei_title_input = res.recruit.title
          this.textarea_value = res.recruit.job_content
          this.textarea_value_two = res.recruit.work_time
          this.work_place_type = res.recruit.work_place_type
          if(res.recruit.work_place_type == 'online'){
            this.value1 = 0
          }else if(res.recruit.work_place_type == 'offline'){
            this.value1 = 1
          }
          let sex_demand = res.recruit.sex_demand
          let pop_six_sexs = this.pop_six_sexs
          for(let i=0;i<pop_six_sexs.length;i++){
            pop_six_sexs[i].is = '0'
            if(pop_six_sexs[i].value == sex_demand){
              pop_six_sexs[i].is = '1'
              this.pop_six_id = pop_six_sexs[i].id
            }
          }
          let education_ids = res.recruit.education_ids
          education_list({}).then((res) =>{
            let that = this
            let pop_six_education_list = res.data
            for(let i=0;i<pop_six_education_list.length;i++){
              pop_six_education_list[i].is = '0'
              for(let k=0;k<education_ids.length;k++){
                if(pop_six_education_list[i].id == education_ids[k]){
                  pop_six_education_list[i].is = '1'
                }
              }
            }
            that.pop_six_educations = pop_six_education_list
          })
          this.pop_six_educations_id = education_ids
          this.textarea_value_three = res.recruit.other_demand
          this.recruiting_number = res.recruit.people_number

          let salary_type = res.recruit.salary_type
          if(salary_type == 'fixed'){
            this.eight_salary_select = '0'
          }else if(salary_type == 'range'){
            this.eight_salary_select = '1'
          }else if(salary_type == 'self'){
            this.eight_salary_select = '2'
          }
          this.fixed_salary = res.recruit.salary_fee
          this.max_salary = res.recruit.max_salary_fee
          this.min_salary = res.recruit.min_salary_fee
          this.self_salary = res.recruit.salary_self
          let salary_unit = res.recruit.salary_unit
          if(salary_unit == 'hour'){
            this.result_point = '元/小时'
          }else if(salary_unit == 'day'){
            this.result_point = '元/天'
          }else if(salary_unit == 'week'){
            this.result_point = '元/周'
          }else if(salary_unit == 'month'){
            this.result_point = '元/月'
          }else if(salary_unit == 'times'){
            this.result_point = '元/次'
          }
          let pop_eights_value = res.recruit.settle_type
          if(pop_eights_value == 'day'){
            this.pop_eights_value = '日结'
          }else if(pop_eights_value == 'week'){
            this.pop_eights_value = '周结'
          }else if(pop_eights_value == 'month'){
            this.pop_eights_value = '月结'
          }else if(pop_eights_value == 'finish'){
            this.pop_eights_value = '完工结'
          }else if(pop_eights_value == 'face'){
            this.pop_eights_value = '面议'
          }

          let pop_eights = this.pop_eights
          for(let i=0;i<pop_eights.length;i++){
            pop_eights[i].is = '0'
            if(pop_eights[i].text == this.pop_eights_value){
              pop_eights[i].is = '1'
            }
          }
          this.pop_eights = pop_eights
          let welfare_ids = res.recruit.welfare_ids
          welfare_list({token:token}).then((res) =>{
            console.log(res,'福利')
            let that = this
            let eight_checkbox_list = res.data
            for(let i=0;i<eight_checkbox_list.length;i++){
              eight_checkbox_list[i].is ='0'
              eight_checkbox_list[i].box = '0'
              for(let k=0;k<welfare_ids.length;k++){
                if(eight_checkbox_list[i].id == welfare_ids[k]){
                  
                  eight_checkbox_list[i].is ='1'
                }
              }
            }
            that.eight_checkboxs = eight_checkbox_list
          })
          let setup_commissions = this.setup_commissions
          if(res.recruit.commission_type == 'achievement'){
            this.commission_type = '业绩提成'
          }else if(res.recruit.commission_type == 'piece'){
            this.commission_type = '计件提成'
          }else if(res.recruit.commission_type == 'self'){
            this.commission_type = '自定义'
          } 
          let commission_type = this.commission_type
          for(let i=0;i<setup_commissions.length;i++){
            setup_commissions[i].is = '0'
            if(setup_commissions[i].text == commission_type){
              setup_commissions[i].is = '1'
            }
          }
          this.setup_commissions = setup_commissions
          this.propose_one = res.recruit.achievement_percent
          this.propose_two = res.recruit.piece_money
          this.propose_three = res.recruit.commission_self


          let fileList = res.recruit.gallery
          if(fileList !== false){
            for(let i=0;i<fileList.length;i++){
              fileList[i] = {url:''+fileList[i]}
            }
            console.log(fileList)
            this.fileList = fileList
            this.fileList_list = fileList
          }
        })
      }

      // 编辑进入
      let adspace_id = this.adspace_id
      let promotion_value = this.promotion_value
      let info_ids = this.info_ids
      let index_index = this.index_index
      let from_table = this.from_table
      if(promotion_value == '1'){
        if(from_table == 'recruit'){
          recruit_info_info({token:token,recruit_id:adspace_id}).then((res) =>{
            console.log(res,'id选中-话题-招聘')
            this.pop_one_tel_value = res.recruit.phone
            this.pop_one_wechat_value = res.recruit.weixin_number
            this.pop_one_phone_value = res.recruit.telephone
            this.pop_one_qq_value = res.recruit.qq
            this.pop_one_email_value = res.recruit.email
            this.pop_two_is = '1'
            this.form.address = res.recruit.work_place_title
            this.longitude_value = res.recruit.work_place_longitude
            this.latitude_value = res.recruit.work_place_latitude
            let pop_twos = this.pop_twos
            for(let i=0;i<pop_twos.length;i++){
              if(pop_twos[i].id == res.recruit.job_type_id_1){
                this.pop_two_value = this.pop_twos[i].title
              }
            }
            
            this.pop_twos_id = res.recruit.job_type_id_1
            this.pop_threes_left_id = res.recruit.job_type_id_2
            // this.pop_threes_right_id = res.recruit.job_type_id_3
            this.pop_three_rights_id = res.recruit.job_type_id_3
            
            let pop_three_lefts = this.pop_three_lefts
            job_list({pid:res.recruit.job_type_id_1}).then((res_one) => {
              if(res_one.error_code == '0'){
                pop_three_lefts = res_one.data
                for(let i=0;i<pop_three_lefts.length;i++){
                  pop_three_lefts[i].is = '0'
                  if(pop_three_lefts[i].id == this.pop_threes_left_id){
                    pop_three_lefts[i].is = '1'
                    this.left_value = pop_three_lefts[i].title
                  }
                }
                this.pop_three_lefts = pop_three_lefts
                this.pop_three_rights = ''
                this.leixing_value = '点击选择'
              }else{
                this.pop_three_lefts = ''
                this.leixing_value = '点击选择'
                this.$message({
                  message:res_one.msg,
                  duration:'2000'
                })
              }
              let json_pop_threes_right_id = res.recruit.job_type_id_3
              job_list({pid:res.recruit.job_type_id_2}).then((res) => {
                if(res.error_code == '0'){
                  let pop_three_rights = res.data
                  for(let i=0;i<pop_three_rights.length;i++){
                    pop_three_rights[i].is = '0'
                    for(let k=0;k<json_pop_threes_right_id.length;k++){
                      if(pop_three_rights[i].id == json_pop_threes_right_id[k]){
                        pop_three_rights[i].is = '1'
                        this.right_value = pop_three_rights[i].title
                        this.leixing_value = (this.left_value+'-'+this.right_value)
                      }
                    }
                  }
                  this.pop_three_rights = pop_three_rights
                }else{
                  this.pop_three_rights = ''
                  this.leixing_value = '点击选择'
                  this.$message({
                    message:res.msg,
                    duration:'2000'
                  })
                }
              })
            })
            

            this.textarea_value_four = res.recruit.work_place_address
            this.zhiwei_title_input = res.recruit.title
            this.textarea_value = res.recruit.job_content
            this.textarea_value_two = res.recruit.work_time
            this.work_place_type = res.recruit.work_place_type
            if(res.recruit.work_place_type == 'online'){
              this.value1 = 0
            }else if(res.recruit.work_place_type == 'offline'){
              this.value1 = 1
            }
            let sex_demand = res.recruit.sex_demand
            let pop_six_sexs = this.pop_six_sexs
            for(let i=0;i<pop_six_sexs.length;i++){
              pop_six_sexs[i].is = '0'
              if(pop_six_sexs[i].value == sex_demand){
                pop_six_sexs[i].is = '1'
                this.pop_six_id = pop_six_sexs[i].id
              }
            }
            let education_ids = res.recruit.education_ids
            education_list({}).then((res) =>{
              let that = this
              let pop_six_education_list = res.data
              for(let i=0;i<pop_six_education_list.length;i++){
                pop_six_education_list[i].is = '0'
                for(let k=0;k<education_ids.length;k++){
                  if(pop_six_education_list[i].id == education_ids[k]){
                    pop_six_education_list[i].is = '1'
                  }
                }
              }
              that.pop_six_educations = pop_six_education_list
            })
            this.pop_six_educations_id = education_ids
            this.textarea_value_three = res.recruit.other_demand
            this.recruiting_number = res.recruit.people_number

            let salary_type = res.recruit.salary_type
            if(salary_type == 'fixed'){
              this.eight_salary_select = '0'
            }else if(salary_type == 'range'){
              this.eight_salary_select = '1'
            }else if(salary_type == 'self'){
              this.eight_salary_select = '2'
            }
            this.fixed_salary = res.recruit.salary_fee
            this.max_salary = res.recruit.max_salary_fee
            this.min_salary = res.recruit.min_salary_fee
            this.self_salary = res.recruit.salary_self
            let salary_unit = res.recruit.salary_unit
            if(salary_unit == 'hour'){
              this.result_point = '元/小时'
            }else if(salary_unit == 'day'){
              this.result_point = '元/天'
            }else if(salary_unit == 'week'){
              this.result_point = '元/周'
            }else if(salary_unit == 'month'){
              this.result_point = '元/月'
            }else if(salary_unit == 'times'){
              this.result_point = '元/次'
            }
            let pop_eights_value = res.recruit.settle_type
            if(pop_eights_value == 'day'){
              this.pop_eights_value = '日结'
            }else if(pop_eights_value == 'week'){
              this.pop_eights_value = '周结'
            }else if(pop_eights_value == 'month'){
              this.pop_eights_value = '月结'
            }else if(pop_eights_value == 'finish'){
              this.pop_eights_value = '完工结'
            }else if(pop_eights_value == 'face'){
              this.pop_eights_value = '面议'
            }

            let pop_eights = this.pop_eights
            for(let i=0;i<pop_eights.length;i++){
              pop_eights[i].is = '0'
              if(pop_eights[i].text == this.pop_eights_value){
                pop_eights[i].is = '1'
              }
            }
            this.pop_eights = pop_eights
            let welfare_ids = res.recruit.welfare_ids
            welfare_list({token:token}).then((res) =>{
              console.log(res,'福利')
              let that = this
              let eight_checkbox_list = res.data
              for(let i=0;i<eight_checkbox_list.length;i++){
                eight_checkbox_list[i].is ='0'
                eight_checkbox_list[i].box = '0'
                for(let k=0;k<welfare_ids.length;k++){
                  if(eight_checkbox_list[i].id == welfare_ids[k]){

                    eight_checkbox_list[i].is ='1'
                  }
                }
              }
              that.eight_checkboxs = eight_checkbox_list
            })
            let setup_commissions = this.setup_commissions
            if(res.recruit.commission_type == 'achievement'){
              this.commission_type = '业绩提成'
            }else if(res.recruit.commission_type == 'piece'){
              this.commission_type = '计件提成'
            }else if(res.recruit.commission_type == 'self'){
              this.commission_type = '自定义'
            } 
            let commission_type = this.commission_type
            for(let i=0;i<setup_commissions.length;i++){
              setup_commissions[i].is = '0'
              if(setup_commissions[i].text == commission_type){
                setup_commissions[i].is = '1'
              }
            }
            this.setup_commissions = setup_commissions
            this.propose_one = res.recruit.achievement_percent
            this.propose_two = res.recruit.piece_money
            this.propose_three = res.recruit.commission_self


            let fileList = res.recruit.gallery
            if(fileList !== false){
              for(let i=0;i<fileList.length;i++){
                fileList[i] = {url:''+fileList[i]}
              }
              console.log(fileList)
              this.fileList = fileList
              this.fileList_list = fileList
            }
            
          })
        }
      }


      // 工位岗位列表
      job_list({}).then((res) => {
        console.log(res)
        that.pop_twos = res.data
        let pop_twos = that.pop_twos
        for(let i=0;i<pop_twos.length;i++){
          pop_twos[i].is = '0'
        }
        that.pop_twos = pop_twos
      })
      if(recruitment_value == '0'){
        let token = this.token
        // 福利列表
        welfare_list({token:token}).then((res) =>{
          console.log(res,'福利')
          let that = this
          let eight_checkbox_list = res.data
          for(let i=0;i<eight_checkbox_list.length;i++){
            eight_checkbox_list[i].is ='0'
            eight_checkbox_list[i].box = '0'
            // eight_checkbox_list[i].index = i
          }
          that.eight_checkboxs = eight_checkbox_list
        })
        // 学历列表
        education_list({}).then((res) =>{
          console.log(res,'学历')
          let that = this
          let pop_six_education_list = res.data
          for(let i=0;i<pop_six_education_list.length;i++){
            if(i==0){
              pop_six_education_list[i].is = '1'
              this.pop_six_educations_id = pop_six_education_list[i].id
            }else{
              pop_six_education_list[i].is = '0'
            }
            
          }
          that.pop_six_educations = pop_six_education_list
          console.log(pop_six_education_list)
        })
      }
      // 配置字段
      configuration_fields({}).then((res)=>{
        console.log(res,'配置字段')
        let business_how_to_charge = res.data.business_how_to_charge    // 如何收费
        let business_how_to_push = res.data.business_how_to_push     // 如何推送
        this.business_how_to_charge = business_how_to_charge
        this.business_how_to_push = business_how_to_push
      })
    },
    methods: {
      clear(async) {
          console.log(async)
          this.$nextTick(() => {
          // ios 手机有延迟问题
          setTimeout(() => {
            const { select } = this.$refs
            const input = select.$el.querySelector('.el-input__inner')
            input.removeAttribute('readonly')
          }, 400)
        })
      },
      
      initMap() {
        AMapLoader.load({
          // 你申请的Key
          key: "f8a1bbd8ef1b14c4e71a5b027835e6f3",
          version: "2.0",
          // 需要用到的插件
          plugins: ["AMap.Geocoder", "AMap.AutoComplete"],
        })
          .then((AMap) => {
            this.map = new AMap.Map("container", {
              viewMode: "3D", //是否为3D地图模式
              zoom: 9, //初始化地图级别
              center: [121.549792, 29.868388], //初始化地图中心点位置
            });
            //地址逆解析插件
            this.geoCoder = new AMap.Geocoder({
              city: "010", //城市设为北京，默认：“全国”
              radius: 500, //范围，默认：500
            });
            // 搜索提示插件
            this.AutoComplete = new AMap.AutoComplete({ city: "全国" });
            //点击获取经纬度;
            this.map.on("click", (e) => {
              // 获取经纬度
              this.form.lng = e.lnglat.lng;
              this.form.lat = e.lnglat.lat;
              // 清除点
              this.removeMarker();
              // 标记点
              this.setMapMarker();
            });
          })
          .catch((err) => {
            // 错误
            console.log(err);
          });
      },
      // 标记点
      setMapMarker() {
        // 自动适应显示想显示的范围区域
        this.map.setFitView();
        this.marker = new AMap.Marker({
          map: this.map,
          position: [this.form.lng, this.form.lat],
        });
      
        // 逆解析地址
        this.toGeoCoder();
        this.map.setFitView();
        this.map.add(this.marker);
      
      
      },
      // 清除点
      removeMarker() {
        if (this.marker) {
          this.map.remove(this.marker);
        }
      },
      // 逆解析地址
      toGeoCoder() {
        let lnglat = [this.form.lng, this.form.lat];
        this.geoCoder.getAddress(lnglat, (status, result) => {
          if (status === "complete" && result.regeocode) {
            this.form.address = result.regeocode.formattedAddress;
          }
        });
      
      },
      // 搜索
      remoteMethod(query) {
        console.log(query);
        let that = this
        if (query !== "") {
          that.loading = true;
          setTimeout(() => {
            that.loading = false;
            that.AutoComplete.search(query, (status, result) => {
              this.options = result.tips;
            });
          }, 200);
        } else {
          this.options = [];
        }
      },
      // 选中提示
      currentSelect(val) {
        console.log(val)
        // 清空时不执行后面代码
        if (!val) {
          return;
        }
        this.form = {
          lng: val.location.lng,
          lat: val.location.lat,
          address: val.district + val.address,
          adcode: val.adcode,
        };
        this.keywords = val.name;
      
        // 清除点
        this.removeMarker();
        // 标记点
        this.setMapMarker();
      },
      clear(async) {
        this.$nextTick(() => {
              if (!async) {
                // ios 手机有延迟问题
                setTimeout(() => {
                  const { select } = this.$refs
                  const input = select.$el.querySelector('.el-input__inner')
                  input.removeAttribute('readonly')
                }, 200)
            }
          })
      },
      // 地图显示
      map_show(){
        this.map_pop = '1'
      },
      // 地图取消
      map_cancel(){
        this.map_pop = '0'
      },
      // 地图确定
      map_determine(){
        let lng = this.form.lng
        let lat = this.form.lat
        if(lng == ''){
          this.$message({
            message:'请选择坐标点',
            duration:'2000'
          })
        }else{
          this.longitude_value = lng
          this.latitude_value = lat
          this.map_pop = '0'
          console.log(this.longitude_value,this.latitude_value)
        }
        
      },
      // 帖子判断
      topics_pop(index){
          if(index == '0'){
            this.$router.push({
                path:'/topics_select_two',
                query: {
                  name: this.type_name,
                  index_index:'0'
                }
            })
          }else if(index == '1'){
            this.$router.push({
                path:'/topics_select_two',
                query: {
                  name: this.type_name,
                  index_index:'1'
                }
            })
          }else if(index == '2'){
            console.log('bubian')
          }
      },

      // 如何推送
      tuisong_show(){
        this.pop_masking = '1'
        this.pop_tuisong = '1'
      },
      tuisong_hide(){
        this.pop_masking = '0'
        this.pop_tuisong = '0'
      },

      // 如何收费
      shoufei_show(){
        this.pop_masking = '1'
        this.pop_shoufei = '1'
      },
      shoufei_hide(){
        this.pop_masking = '0'
        this.pop_shoufei= '0'
      },
      // 联系方式 弹窗 第一个
      pop_up_one(e){
        this.pop_masking = '1'
        this.pop_one = '1'
      },
      pop_one_dete(e){
        if(this.pop_one_tel_value == '' && this.pop_one_wechat_value == '' && this.pop_one_phone_value=='' && this.pop_one_qq_value=='' && this.pop_one_email_value==''){
          this.$message({
            message:'至少填写一个联系方式',
            duration:'2000'
          })
        }else{
          this.pop_one_contact_list = []
          this.pop_one_contact_list.push(this.pop_one_tel_value,this.pop_one_wechat_value,this.pop_one_phone_value,this.pop_one_qq_value,this.pop_one_email_value)
          this.pop_one_is = '1'
          this.pop_masking = '0'
          this.pop_one = '0'

          // contact_infor({})
        }
      },
      pop_one_none(e){
        this.pop_masking = '0'
        this.pop_one = '0'
        if(this.pop_one_tel_value == '' && this.pop_one_wechat_value == '' && this.pop_one_phone_value=='' && this.pop_one_qq_value=='' && this.pop_one_email_value==''){
          this.pop_one_is = '0'
        }
      },

      // 工作性质 弹窗 第二个
      pop_up_two(e){
        this.pop_masking = '1'
        this.pop_two = '1'
      },
      pop_two_none(e){
        this.pop_masking = '0'
        this.pop_two = '0'
      },
      pop_two_select(e){
        console.log(e)
        let pop_twos = this.pop_twos
        let pop_two_value = this.pop_two_value
        let pop_three_lefts = this.pop_three_lefts
        job_list({pid:e}).then((res) => {
          console.log(res)
          if(res.error_code == '0'){
            pop_three_lefts = res.data
            for(let i=0;i<pop_three_lefts.length;i++){
              pop_three_lefts[i].is = '0'
            }
            this.pop_three_lefts = pop_three_lefts
            this.pop_three_rights = ''
            this.leixing_value = '点击选择'
            console.log(this.pop_three_lefts)
          }else{
            this.pop_three_lefts = ''
            this.leixing_value = '点击选择'
            this.$message({
              message:res.msg,
              duration:'2000'
            })
          }
        })

        for(let i=0;i<pop_twos.length;i++){
          if(pop_twos[i].id == e){
            pop_two_value = pop_twos[i].title
          }
        }
        this.pop_two_is = '1'
        this.pop_two_value = pop_two_value
        this.pop_masking = '0'
        this.pop_two = '0'
        this.pop_twos_id = e
      },

      // 岗位类型 弹窗 第三个
      pop_three_right_click(id){
        console.log(id)
        this.pop_threes_right_id = id
        let pop_three_rights_list = this.pop_three_rights
        let pop_three_rights_id = []
        for(let i=0;i<pop_three_rights_list.length;i++){
          if(pop_three_rights_list[i].id == id){
            if(pop_three_rights_list[i].is == '1'){
              pop_three_rights_list[i].is = '0'
            }else{
              pop_three_rights_list[i].is = '1'
            }
          }
          if(pop_three_rights_list[i].is == '1'){
            pop_three_rights_id.push(pop_three_rights_list[i].id)
          }
        }
        this.pop_three_rights = pop_three_rights_list
        this.pop_three_rights_id = pop_three_rights_id
      },
      pop_three_left_click(id){
        console.log(id)
        this.pop_threes_left_id = id
        let pop_three_lefts_list = this.pop_three_lefts
        for(let i=0;i<pop_three_lefts_list.length;i++){
          pop_three_lefts_list[i].is = '0'
          if(pop_three_lefts_list[i].id == id){
            pop_three_lefts_list[i].is = '1'
          }
        }
        job_list({pid:id}).then((res) => {
          console.log(res)
          if(res.error_code == '0'){
            let pop_three_rights_list = res.data
            for(let i=0;i<pop_three_rights_list.length;i++){
              pop_three_rights_list[i].is = '0'
            }
            this.pop_three_rights = pop_three_rights_list
          }else{
            this.pop_three_rights = ''
            this.$message({
              message: res.msg,
              duration:'2000'
            })
          }
          
        })
        this.pop_three_lefts = pop_three_lefts_list
      },
      pop_three_block(e){
        let pop_twos_id = this.pop_twos_id
        if(pop_twos_id == ''){
          this.$message({
            message:'请先填写工作性质',
            duration:'2000'
          })
        }else{
          this.pop_masking = '1'
          this.pop_three = '1'
        }
        
      },
      pop_three_none(e){
        this.pop_masking = '0'
        this.pop_three = '0'
      },
      pop_three_determine(e){
        let pop_three_lefts = this.pop_three_lefts
        let pop_three_rights = this.pop_three_rights
        let left_value = ''
        let right_value = ''
        for(let i=0;i<pop_three_lefts.length;i++){
          if(pop_three_lefts[i].is == '1'){
            left_value = pop_three_lefts[i].title
          }
        }
        for(let i=0;i<pop_three_rights.length;i++){
          if(pop_three_rights[i].is == '1'){
            right_value = pop_three_rights[i].title
          }
        }
        if(right_value == ''){
          this.$message({
            message:'请至少选择一个职位',
            duration:'2000'
          })
          return
        }
        this.leixing_value = (left_value+'-'+right_value)

        this.pop_masking = '0'
        this.pop_three = '0'
      },
      pop_three_search_none(e){
        this.pop_three_search = '0'
      },
      // 岗位搜索弹窗 选择确定
      pop_three_search_data(e){
        let input_search_lish = this.input_search_lish
        this.pop_masking = '0'
        this.pop_three_search = '0'
        console.log(e)
        for(let i=0;i<input_search_lish.length;i++){
          if(input_search_lish[i].id == e){
            this.left_value = input_search_lish[i].parent_title
            this.right_value = input_search_lish[i].title
          }
        }
        this.leixing_value = (this.left_value+'-'+this.right_value)
      },
      pop_three_search_block(e){
         this.pop_three_search = '1'
         this.pop_three = '0'
      },
      // 工作时间 弹窗 第4个
      pop_four_1_left_select(id){
        let pop_four_1_lefts_list = this.pop_four_1_lefts
        for(let i=0;i<pop_four_1_lefts_list.length;i++){
          pop_four_1_lefts_list[i].is = '0'
          if(pop_four_1_lefts_list[i].id == id){
              pop_four_1_lefts_list[i].is = '1'
          }
        }
      },
      pop_four_one_select(id){
        console.log(id)
        if(id == 0){
          this.pop_four_one_value = 0
          this.pop_four_one_lists[0].is = '1'
          this.pop_four_one_lists[1].is = '0'
        }else{
          this.pop_four_one_value = 1
          this.pop_four_one_lists[0].is = '0'
          this.pop_four_one_lists[1].is = '1'
        }
      },
      pop_four_two_select(id){
        if(id == 0){
          this.pop_four_two_value = 0
          this.pop_four_two_lists[0].is = '1'
          this.pop_four_two_lists[1].is = '0'
        }else{
          this.pop_four_two_value = 1
          this.pop_four_two_lists[0].is = '0'
          this.pop_four_two_lists[1].is = '1'
        }
      },
      pop_four_time1(e){
        this.pop_four_one_time = '1'
      },
      pop_four_time2(e){
        this.pop_four_two_time = '1'
      },
      
      pop_four_determine(e){         // 确定
        this.pop_four = '0'
        this.pop_masking = '0'

      },
      pop_up_four(e){
        this.pop_four = '1'
        this.pop_masking = '1'
      },
      pop_four_none(e){
        this.pop_four = '0'
        this.pop_masking = '0'
      },
      // 工作要求 弹窗 第6个
      pop_six_sex_select(id){
        let pop_six_sexs_list = this.pop_six_sexs
        console.log(id)
        for(let i=0;i<pop_six_sexs_list.length;i++){
          pop_six_sexs_list[i].is = '0'
        }
        pop_six_sexs_list[(id-1)].is = '1'
        this.pop_six_id = id
        this.pop_six_sexs = pop_six_sexs_list
      },
      pop_six_education_select(id){
        let pop_six_educations_list = this.pop_six_educations
        let id_value = id
        this.pop_six_educations_id = []
        if(id == '7'){
          for(let i=0;i<pop_six_educations_list.length;i++){
            pop_six_educations_list[i].is = '0'
          }
          pop_six_educations_list[0].is = '1'
          this.pop_six_educations_id.push(id)
        }else{
          pop_six_educations_list[0].is = '0'
          for(let i=0;i<pop_six_educations_list.length;i++){
            if(pop_six_educations_list[i].id == id_value){
              if(pop_six_educations_list[i].is == '1'){
                pop_six_educations_list[i].is = '0'
              }else{
                pop_six_educations_list[i].is = '1'
              }
            }
            if(pop_six_educations_list[i].is == '1'){
              this.pop_six_educations_id.push(pop_six_educations_list[i].id)
            }
          }
          this.pop_six_educations = pop_six_educations_list
        }
      },
      pop_up_six(e){
        this.pop_masking = '1'
        this.pop_six = '1'
      },
      pop_six_none(e){
        this.pop_masking = '0'
        this.pop_six = '0'
      },
      pop_six_determine(e){
        let pop_six_educations_list = this.pop_six_educations
        let is_value = 0
        for(let i=0;i<pop_six_educations_list.length;i++){
          if(pop_six_educations_list[i].is == '1'){
            is_value++
          }
        }
        this.pop_masking = '0'
        this.pop_six = '0'
        // if(is_value == 0){
        //   this.$message({
        //     message:'学历至少勾选一个',
        //     duration:'2000'
        //   })
        // }else{
        //   this.pop_masking = '0'
        //   this.pop_six = '0'
        // }
      },

      // 薪资待遇 弹窗 第8个
      eight_salary(num){
        console.log(num)
        if(num == '0'){
          this.eight_salary_select = 0
        }else if(num == '1'){
          this.eight_salary_select = 1
        }else if(num == '2'){
          this.eight_salary_select = 2
        }
      },
      // 结算方式 选择
      pop_eight_dele(id){
        console.log(id)
        let pop_eights_list = this.pop_eights
        for(let i=0;i<pop_eights_list.length;i++){
          pop_eights_list[i].is = 0
          if(pop_eights_list[i].id == id){
              pop_eights_list[i].is = 1
              this.pop_eights_value = pop_eights_list[i].text
          }
          this.pop_eights = pop_eights_list
        }
      },
      // 福利选择
      eight_checkbox_dele(id){
        let token = this.token
        console.log(id)
        let eight_management_text = this.eight_management_text
        let eight_checkboxs_id = []
        if(eight_management_text == '管理'){
          let eight_checkbox_list = this.eight_checkboxs
          for(let i=0;i<eight_checkbox_list.length;i++){
            if(eight_checkbox_list[i].id == id){
              if(eight_checkbox_list[i].is == '1'){
                eight_checkbox_list[i].is = '0'
              }else{
                eight_checkbox_list[i].is = '1'
              }
            }
            if(eight_checkbox_list[i].is == '1'){
              eight_checkboxs_id.push(eight_checkbox_list[i].id)
            }
          }
          this.eight_checkbox_list = eight_checkbox_list
          this.eight_checkboxs_id = eight_checkboxs_id
        }else{
          let eight_checkbox_list = this.eight_checkboxs
          let eight_checkboxs_id = this.eight_checkboxs_id
          for(let i=0;i<eight_checkbox_list.length;i++){
            if(eight_checkbox_list[i].id == id){
              if(eight_checkbox_list[i].uid =='0'){
                this.$message({
                  message:'次选项不能删除',
                  duration:'2000'
                })
              }else{
                welfare_list_remove({token:token,welfare_id:id}).then((res) =>{
                  if(res.error_code == 0){
                    // this.$message({
                    //   message:res.msg,
                    //   duration:'2000'
                    // })
                    welfare_list({token:token}).then((res) =>{
                      console.log(res)
                      let eight_checkbox_list = res.data
                      for(let i=0;i<eight_checkbox_list.length;i++){
                        eight_checkbox_list[i].is ='0'
                        for(let k=0;k<eight_checkboxs_id.length;k++){
                          if(eight_checkbox_list[i].id == eight_checkboxs_id[k]){
                            eight_checkbox_list[i].is ='1'
                          }
                        }
                        eight_checkbox_list[i].box = 1
                      }
                      this.eight_checkboxs = eight_checkbox_list
                    })
                  }else{
                    this.$message({
                      message:res.msg,
                      duration:'2000'
                    })
                  }
                })
              }
            }
          }
        }
        
      },
      // 设置提成
      commission_dele(id){
        console.log(id)
        let setup_commissions_list = this.setup_commissions
        for(let i=0;i<setup_commissions_list.length;i++){
            setup_commissions_list[i].is = '0'
          if(setup_commissions_list[i].id == id){
            setup_commissions_list[i].is = '1'
          }
        }
      },
      pop_eight_none(e){
        this.pop_eight_yy = '0'
        this.pop_eight_shezhi = '0'
      },
      pop_eight_block(e){
        this.pop_eight_yy = '1'
        this.pop_eight_shezhi = '1'
      },
      pop_eight_determine(e){
        let that = this
        let pop_six_sexs = that.pop_six_sexs

        this.pop_eight_yy = '0'
        this.pop_eight_shezhi = '0'
      },
      pop_right_point_block(e){
         this.pop_eight_danjia = '1'
      },
      // 监听 自定义福利 输入数量和内容
      change_eight() {
        let value_length = (this.input_eight).length
        if(value_length >= 6){
          this.input_eight.substring(0,6)
          this.input_eight = this.input_eight.substring(0,6)
          this.$message({
            message:'最多可写6个字',
            duration:'2000'
          })
        }
      },
      pop_eight_benefits_block(e){
        this.pop_eight_benefits = '1'
        this.pop_eight_yy = '1'
      },
      pop_eight_benefits_none(e){
        this.pop_eight_benefits = '0'
        this.pop_eight_yy = '0'
      },
      eight_benefits_dete(e){
        let token = this.token
        if(this.input_eight == ''){
          this.$message({
            message:'请填写内容',
            duration:'2000'
          })
        }else{
          let eight_checkboxs_list = this.eight_checkboxs
          let eight_checkboxs_id = this.eight_checkboxs_id
          welfare_list_add({token:token,welfare_id:'0',title:this.input_eight}).then((res) =>{
            console.log(res)
            if(res.error_code == 0){
              this.$message({
                  message:res.msg,
                  duration:'2000'
              })
              welfare_list({token:token}).then((res) =>{
                console.log(res)
                let eight_checkbox_list = res.data
                let len = eight_checkbox_list.length
                for(let i=0;i<eight_checkbox_list.length;i++){
                  eight_checkbox_list[i].is ='0'
                  eight_checkbox_list[i].box = '0'
                  for(let k=0;k<eight_checkboxs_id.length;k++){
                    if(eight_checkbox_list[i].id == eight_checkboxs_id[k]){
                      eight_checkbox_list[i].is ='1'
                    }
                  }
                  if(i == len-1){
                    eight_checkbox_list[i].is ='1'
                  }
                }
                this.eight_checkboxs = eight_checkbox_list
              })
              
            }else{
              this.$message({
                  message:res.msg,
                  duration:'2000'
              })
            }
          })
          // let input_eight = {index:eight_checkboxs_list.length+1,title:this.input_eight,is:'0',box:'0'}
          // eight_checkboxs_list.push(input_eight)
          this.pop_eight_benefits = '0'
          this.pop_eight_yy = '0'

        }
      },
      // 管理 弹窗
      pop_eight_management(e){
        let eight_management_text = this.eight_management_text
        if(eight_management_text == '管理'){
          let eight_checkboxs_list = this.eight_checkboxs
          for(let i=0;i<eight_checkboxs_list.length;i++){
            eight_checkboxs_list[i].box = '1'
          }
          this.eight_management_text = '取消'
          this.eight_checkboxs = eight_checkboxs_list
        }else if(eight_management_text == '取消'){
          let eight_checkboxs_list = this.eight_checkboxs
          for(let i=0;i<eight_checkboxs_list.length;i++){
            eight_checkboxs_list[i].box = '0'
          }
          this.eight_management_text = '管理'
          this.eight_checkboxs = eight_checkboxs_list
        }
        console.log(this.eight_checkboxs)
      },
      pop_eight_dete_none(e){
        this.pop_eight = '0'
      },
      pop_eight_dete(e){
        this.pop_eight = '0'
      },
      pop_up_eight(e){
        this.pop_eight = '1'
      },
      // 下一步 弹窗
      pop_up_next(e){
        let recruit_date = []      // 存储的数组
        let token = this.token
        let recruit_id = this.recruit_id       // 新增岗位时 是'0' ,编辑为id
        let title = this.zhiwei_title_input  // 	招聘标题
        let city_id = ''           // 暂时为空
        let consulting_people_number = ''   // 咨询人数 下个页面传
        let max_push_number = ''  // 单个用户的最大推送次数  下个页面传
        let max_push_number_day = '' // 单个用户每日的最大推送次数  下个页面传
        let grade_ids_undergraduate = ''  // 本科年级id，多个用英文逗号隔开 下个页面传
        let grade_ids_specialty = ''  // 专科年级id，多个用英文逗号隔开  下个页面传
        let push_starttime = ''  // 推送开始时间  下个页面传
        let push_endtime = ''   // 推送结束时间   下个页面传
        let school_json = ''   // 学校信息数组   下个页面传
        let job_content = this.textarea_value  //  工作内容

        let xian_top_bottom = this.value1   // 线上是0 显示online 线下是1 显示offline 
        if(xian_top_bottom == '0'){
          let work_place_type = 'online'
          this.work_place_type = work_place_type
        }else{
          let work_place_type = 'offline'
          this.work_place_type = work_place_type
        }
        let work_place_type = this.work_place_type  // 传 线上还是线下
        let work_place_longitude = this.longitude_value  // 工作地点经度
        let work_place_latitude = this.latitude_value  // 	工作地点纬度
        let work_place_address = this.textarea_value_four  //  具体工作地点 
        let phone = this.pop_one_tel_value    // 手机
        let weixin_number = this.pop_one_wechat_value  // 	微信号
        let telephone = this.pop_one_phone_value  // 电话
        let qq = this.pop_one_qq_value   // 	qq号
        let email = this.pop_one_email_value  // email
        let job_type_id_1 = this.pop_twos_id  // 一级岗位id
        let job_type_id_2 = this.pop_threes_left_id  // 	二级岗位id
        let pop_three_rights_id = this.pop_three_rights_id
        if(Array.isArray(this.pop_three_rights_id) == true){
          pop_three_rights_id = this.pop_three_rights_id.toString(this.pop_three_rights_id)
        }
        // console.log(pop_three_rights_id)
        let job_type_id_3 = pop_three_rights_id  // 三级岗位id
        // let job_type_id_3 = this.pop_threes_right_id  // 三级岗位id

        let sex_list = this.pop_six_id  // 性别id 1 = none 不限  2 = male 男 3 = female 女
        if(sex_list == '1'){
          this.sex_demand = 'none'
        }else if(sex_list == '2'){
          this.sex_demand = 'male'
        }else if(sex_list == '3'){
          this.sex_demand = 'female'
        }else{
          console.log('接口不对')
        }
        let sex_demand = this.sex_demand  // 传  性别
        let education_ids = this.pop_six_educations_id.toString(this.pop_six_educations_id)  // 学历id 
        let other_demand = this.textarea_value_three  // 其他要求
        let work_time = this.textarea_value_two  // 工作时间
        let eight_salary_select = this.eight_salary_select
        if(eight_salary_select == '0'){
          this.salary_type = 'fixed'
        }else if(eight_salary_select == '1'){
          this.salary_type = 'range'
        }else if(eight_salary_select == '2'){
          this.salary_type = 'self'
        }
        let salary_type = this.salary_type   // 薪资方式
        let salary_fee = this.fixed_salary  // 固定薪资数值
        let min_salary_fee = this.min_salary  // 最小薪资数值
        let max_salary_fee = this.max_salary  // 最大薪资数值
        let salary_self = this.self_salary  // 自定义薪资说明
        let salary_unit_value = this.result_point  // 薪资单位
        if(salary_unit_value == '元/小时'){
          this.salary_unit = 'hour'
        }else if(salary_unit_value == '元/天'){
          this.salary_unit = 'day'
        }else if(salary_unit_value == '元/周'){
          this.salary_unit = 'week'
        }else if(salary_unit_value == '元/月'){
          this.salary_unit = 'month'
        }else if(salary_unit_value == '元/次'){
          this.salary_unit = 'times'
        }
        let salary_unit = this.salary_unit   // 传 薪资单位

        let settle_type_list = this.pop_eights_value  // 结算方式
        if(settle_type_list == '日结'){
          this.settle_type = 'day'
        }else if(settle_type_list == '周结'){
          this.settle_type = 'week'
        }else if(settle_type_list == '月结'){
          this.settle_type = 'month'
        }else if(settle_type_list == '完工结'){
          this.settle_type = 'finish'
        }else if(settle_type_list == '面议'){
          this.settle_type = 'face'
        }
        let settle_type = this.settle_type   // 传 结算方式
        let welfare_ids_list = this.eight_checkboxs // 福利id，多个用英文逗号隔开
        let welfare_ids_push = []
        for(let i=0;i<welfare_ids_list.length;i++){
          if(welfare_ids_list[i].is == '1'){
            welfare_ids_push.push(welfare_ids_list[i].id)
            this.welfare_ids = welfare_ids_push
          }
        }
        let welfare_ids = this.welfare_ids.toString(this.welfare_ids)  // 传 福利id
        let commission_type_list = this.setup_commissions  // 提成方式
        for(let i=0;i<commission_type_list.length;i++){
          if(commission_type_list[i].is == '1'){
            if(commission_type_list[i].id == '1'){
              this.commission_type = 'achievement'
            }else if(commission_type_list[i].id == '2'){
              this.commission_type = 'piece'
            }else if(commission_type_list[i].id == '3'){
              this.commission_type = 'self'
            }
          }
        }
        let commission_type = this.commission_type  //传 提成方式
        let achievement_percent = this.propose_one    // 业绩提成百分比
        let piece_money = this.propose_two  // 计件提成
        let commission_self = this.propose_three  // 自定义提成内容
        let recruit_endtime = '' //  招聘截止时间  下个页面传
        let people_number = this.recruiting_number  // 招聘人数
        let gallery = this.fileList_list  // 多图
        
        let recruitment_value = this.recruitment_value
        let promotion_value = this.promotion_value    // 进入传value  0代表正常进入，1为待推送进入
        // recruit_date = {token:token,recruit_id:recruit_id,title:title,city_id:city_id,consulting_people_number:consulting_people_number,max_push_number:max_push_number,max_push_number_day:max_push_number_day,grade_ids_undergraduate:grade_ids_undergraduate,grade_ids_specialty:grade_ids_specialty,push_starttime:push_starttime,push_endtime:push_endtime,school_json:school_json,job_content:job_content,work_place_type:work_place_type,work_place_longitude:work_place_longitude,work_place_latitude:work_place_latitude,work_place_address:work_place_address,phone:phone,weixin_number:weixin_number,telephone:telephone,qq:qq,email:email,job_type_id_1:job_type_id_1,job_type_id_2:job_type_id_2,job_type_id_3:job_type_id_3,sex_demand:sex_demand,education_ids:education_ids,other_demand:other_demand,work_time:work_time,salary_type:salary_type,salary_fee:salary_fee,min_salary_fee:min_salary_fee,max_salary_fee:max_salary_fee,salary_unit:salary_unit,settle_type:settle_type,welfare_ids:welfare_ids,commission_type:commission_type,achievement_percent:achievement_percent,piece_money:piece_money,commission_self:commission_self,recruit_endtime:recruit_endtime,people_number:people_number,gallery:gallery}
        if(recruitment_value == '0' && promotion_value == '0'){
          recruit_date = {token:token,recruit_id:recruit_id,title:title,city_id:city_id,job_content:job_content,work_place_type:work_place_type,work_place_longitude:work_place_longitude,work_place_latitude:work_place_latitude,work_place_address:work_place_address,phone:phone,weixin_number:weixin_number,telephone:telephone,qq:qq,email:email,job_type_id_1:job_type_id_1,job_type_id_2:job_type_id_2,job_type_id_3:job_type_id_3,sex_demand:sex_demand,education_ids:education_ids,other_demand:other_demand,work_time:work_time,salary_type:salary_type,salary_fee:salary_fee,min_salary_fee:min_salary_fee,max_salary_fee:max_salary_fee,salary_self:salary_self,salary_unit:salary_unit,settle_type:settle_type,welfare_ids:welfare_ids,commission_type:commission_type,achievement_percent:achievement_percent,piece_money:piece_money,commission_self:commission_self,people_number:people_number,gallery:gallery}
          let dd = JSON.stringify(recruit_date)
          sessionStorage.setItem("recruit_date_key",dd)
        }else if(recruitment_value == '1'){
          var reg1 = new RegExp("")
          let img_list = ''
          let business_gallery2 = this.fileList_list
          for(let i=0;i<business_gallery2.length;i++){
            img_list = img_list +','+ business_gallery2[i].url.replace(reg1,"");
          }
          business_gallery2 = img_list.substr(1,img_list.length)
          let business_gallery3 = business_gallery2.split(',')
          recruit_date = {token:token,recruit_id:recruit_id,title:title,city_id:city_id,job_content:job_content,work_place_type:work_place_type,work_place_longitude:work_place_longitude,work_place_latitude:work_place_latitude,work_place_address:work_place_address,phone:phone,weixin_number:weixin_number,telephone:telephone,qq:qq,email:email,job_type_id_1:job_type_id_1,job_type_id_2:job_type_id_2,job_type_id_3:job_type_id_3,sex_demand:sex_demand,education_ids:education_ids,other_demand:other_demand,work_time:work_time,salary_type:salary_type,salary_fee:salary_fee,min_salary_fee:min_salary_fee,max_salary_fee:max_salary_fee,salary_self:salary_self,salary_unit:salary_unit,settle_type:settle_type,welfare_ids:welfare_ids,commission_type:commission_type,achievement_percent:achievement_percent,piece_money:piece_money,commission_self:commission_self,people_number:people_number,gallery:business_gallery3}

          let dd = JSON.stringify(recruit_date)
          sessionStorage.setItem("recruit_date_key",dd)
        }else if(promotion_value == '1'){
          var reg1 = new RegExp("")
          let img_list = ''
          let business_gallery2 = this.fileList_list
          // console.log(business_gallery2,business_gallery2.url,business_gallery2[0].url,'-------')
          if(business_gallery2[0] !== undefined){
            if(business_gallery2[0].url == undefined ){
              for(let i=0;i<business_gallery2.length;i++){
                img_list = img_list +','+ business_gallery2[i].replace(reg1,"");
              }
            }else{
              for(let i=0;i<business_gallery2.length;i++){
                img_list = img_list +','+ business_gallery2[i].url.replace(reg1,"");
              }
            }
          }
          business_gallery2 = img_list.substr(1,img_list.length)
          let business_gallery3 = business_gallery2.split(',')
          recruit_date = {token:token,recruit_id:recruit_id,title:title,city_id:city_id,job_content:job_content,work_place_type:work_place_type,work_place_longitude:work_place_longitude,work_place_latitude:work_place_latitude,work_place_address:work_place_address,phone:phone,weixin_number:weixin_number,telephone:telephone,qq:qq,email:email,job_type_id_1:job_type_id_1,job_type_id_2:job_type_id_2,job_type_id_3:job_type_id_3,sex_demand:sex_demand,education_ids:education_ids,other_demand:other_demand,work_time:work_time,salary_type:salary_type,salary_fee:salary_fee,min_salary_fee:min_salary_fee,max_salary_fee:max_salary_fee,salary_self:salary_self,salary_unit:salary_unit,settle_type:settle_type,welfare_ids:welfare_ids,commission_type:commission_type,achievement_percent:achievement_percent,piece_money:piece_money,commission_self:commission_self,people_number:people_number,gallery:business_gallery3}

          let dd = JSON.stringify(recruit_date)
          sessionStorage.setItem("recruit_date_key",dd)
        }

        if(recruitment_value == '0' && promotion_value == '0'){
          let xxl_select = this.xxl_select
          if(xxl_select == ''){
            this.$router.push({path:'/recruit_index_two'})
          }else{
            this.$router.push({
              path:'/recruit_index_two',
              query: {
                xxl_select:xxl_select,
              }
            })
          }
          
        }else if(recruitment_value == '1'){
          let bj_news = this.bj_news
          if(bj_news == '1'){
            this.$router.push({
              path:'/push_settings',
              query: {
                recruit_id: recruit_id,
                recruitment_value:'1',
                bj_news:'1',
                page_up:'1',
              }
            })
          }else{
            this.$router.push({
              path:'/push_settings',
              query: {
                recruit_id: recruit_id,
                recruitment_value:'1',
                page_up:'1',
              }
            })
          }
          
        }else if(promotion_value == '1'){
          this.$router.push({
            path:'/topics_select_three',
            query: {
              adspace_id: this.adspace_id,
              promotion_value:promotion_value,
              info_ids:this.info_ids,
              from_table:'recruit',
              id_index:this.id_index,
              xxl_select:this.xxl_select,
              bj_news:this.bj_news
            }
          })
        }
  
      },
      pop_next_none(e){
        this.pop_masking = '0'
        this.pop_next = '0'
      },
      
      // 图片上传
      // 删除图片
      handleRemove(file, fileList) {
        console.log(file, fileList);
        let recruitment_value = this.recruitment_value
        if(recruitment_value == '0'){
          let fileList_list = []
          for(let i=0;i<fileList.length;i++){
            fileList_list.push(fileList[i].response.img)
          }
          this.fileList_list = fileList_list 
          console.log(this.fileList_list,'删除图片')
        }else if(recruitment_value == '1'){
          let fileList_list = []
          for(let i=0;i<fileList.length;i++){
              fileList_list.push(fileList[i])
          }
          this.fileList_list = fileList_list 
          console.log(this.fileList_list,'删除图片')
        }
      },
      // 上传成功
      hand_success(file,fileList){
        console.log(file,fileList)
        let recruitment_value = this.recruitment_value
        let promotion_value = this.promotion_value
        if(recruitment_value == '0'){
          if(promotion_value == '0'){
            let fileList_list = this.fileList_list
            fileList_list.push(fileList.response.img)
            this.fileList_list = fileList_list
            console.log(this.fileList_list,'上传成功')
          }else if(promotion_value == '1'){
            let fileList_list = this.fileList_list
            fileList_list.push({url:''+file.img})
            this.fileList_list = fileList_list
            console.log(this.fileList_list,'上传成功')
          } 
        }else if(recruitment_value == '1'){
          console.log(file,fileList)
          let fileList_list = this.fileList_list
          fileList_list.push({url:''+file.img})
          this.fileList_list = fileList_list
          console.log(this.fileList_list,'上传成功')
        }
        
      },
      handlePreview(file,fileList) {
        console.log(file,fileList);
      },


      // 监听招聘弹窗搜索
      zhaopin_value(e){
        let that = this
        if(e == ''){
          
        }else{
          job_search_list({keyword:e,job_type_id_1:this.pop_twos_id,page:'1'}).then((res) => {
            console.log(res)
            if(res.error_code == '0'){
              that.input_search_lish = res.data
            }else{
              this.$message({
                message: res.msg,
                duration:'2000'
              })
            }
          })
        }

      },
    },
    // 工作时间
    setup() {
      const value1 = ref(1);
      const option1 = [
        { text: '线上', value:0},
        { text: '线下', value:1},
      ];
    // 日期选择 工作时
      const date_start = ref('');
      const date_end = ref('');
      const show = ref(false);
      const formatDate = (date_start) => `${date_start.getYear() + 1900}-${date_start.getMonth() + 1}-${date_start.getDate()}`;
      const onConfirm = (values) => {
        const [start, end] = values;
        show.value = false;
        date_start.value = `${formatDate(start)}`;
        date_end.value = `${formatDate(end)}`
      };
    // 时间选择 工作时间
        const result_four_one = ref('');
        const result_four_two = ref('');
        const pop_four_one_time = ref('0');
        const pop_four_two_time = ref('0');
        const onConfirm_four_one = (value) => {
            result_four_one.value = value;
            console.log(result_four_one.value)
            pop_four_one_time.value = '0'
        };
        const showPicker_four_one = (value)=>{
            console.log('取消')
            pop_four_one_time.value = '0'
        };

        const onConfirm_four_two = (value) => {
            result_four_two.value = value;
            console.log(result_four_two.value)
            pop_four_two_time.value = '0'
        };
        const showPicker_four_two = (value)=>{
            console.log('取消')
            pop_four_two_time.value = '0'
        };

        // 单位选择
        const result_point = ref('元/小时');
        const pop_eight_danjia = ref('0');
        const columns = ['元/小时', '元/天', '元/周', '元/月', '元/次'];
        const onConfirm_eight_point_block = (value) => {
            result_point.value = value;
            pop_eight_danjia.value = '0'
            console.log(result_point.value,result_point)
            // this.salary_unit = result_point.value
        };
        const showPicker_eight_point_none = (value) => {
            console.log('quxiao')
            pop_eight_danjia.value = '0'
        };
        return {
          // 工作时间 选择
          value1,
          option1, 
          // 日期选择 工作时
          date_start,
          date_end,
          show,
          onConfirm,
          // 时间选择 工作时间
          result_four_one,
          result_four_two,
          onConfirm_four_one,
          onConfirm_four_two,
          showPicker_four_one,
          showPicker_four_two,
          pop_four_one_time,
          pop_four_two_time,
          // 
          result_point,
          columns,
          onConfirm_eight_point_block,
          showPicker_eight_point_none,
          pop_eight_danjia,
        };
    },
  }
</script>
<style>
  .y_add_search_0{
    width: 100%;
  }
  .y_add_search_1{
    height: 1rem;
    background: transparent;
    width: 100%;
    position: fixed;
    z-index: 10;
    top: 0.5rem;
    left: 50%;
    transform: translate(-50%,0%);
  }
  .y_add_search_1 .el-select--mini{
    width: 100% !important;
  }
  .y_add_search_1 .el-select .el-input__inner{
      width: 90%;
      margin: 0 auto;
      font-size: 0.3rem;
      border: none;
      border-radius: 0.1rem;
      padding-left: 0.15rem !important;
      line-height: 0.8rem;
  }
  .el-popper{
    width: 90% !important;
    left: 50% !important;
    transform: translate(-50%,0%) !important;
  }
  .el-select-dropdown{
    min-width: auto !important;
    width: 100%;
  }
  .el-select-dropdown__item{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-select-dropdown__item span:nth-child(1){
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    width: 100%;
  }
  .el-select-dropdown__item span:nth-child(2){
    flex: 1;
  }
  .y_add_search_2{
    position: fixed;
    z-index: 5;
    top: 0rem;
    left: 0rem;
  }
  .y_add_search_3 p{
    /* font-size: 0.24rem; */
    -webkit-line-clamp: 2;
    margin-left: 0.1rem;
  }
  .y_map_pop_2{
    z-index: 99;
    position: fixed;
    width: calc(100% - 0.5rem);
    left: 0rem;
    bottom: 0rem;
  }
  .y_display_opacity{
    z-index: -1;
    opacity: 0;
  }
  .y_pop_two_1{
    height: 50vh !important;
  }
</style>