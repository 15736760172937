<template>
    <div class="y_write_list_1">
        <div class="y_write_list_2">
            <div class="y_write_list_3">
                <div class="y_write_list_4">
                    <div class="y_write_list_5">
                        <img src="../../../images/icon_18.png" alt="">
                    </div>
                    <div class="y_write_list_6">
                        <el-input v-model="z_keyword" type="text" placeholder="搜参与抽奖的用户"></el-input>
                    </div>
                    <div class="y_write_list_7" @click="participant_Enquiry()">
                        <p>搜索</p>
                    </div>
                </div>
            </div>
            <div class="y_write_list_8">
                <div class="y_write_list_9">
                    <h2>抽奖：</h2>
                </div>
                <div class="y_write_list_10">
                    <div class="y_write_list_11">
                        <div class="y_write_list_12" @click="pop_show(1)">
                            <p>全部抽奖</p>
                            <img src="../../../images/icon_30.png" alt="">
                        </div>
                        <div class="y_write_list_13">
                            <p>{{all_cj_one?.award_prize?.[0]?.title ??''}}</p>
                        </div>
                    </div>
                    <div class="y_write_list_11">
                        <div class="y_write_list_12" @click="pop_show(2)">
                            <p>全部奖品</p>
                            <img src="../../../images/icon_30.png" alt="">
                        </div>
                        <div class="y_write_list_13">
                            <p>{{all_cj_two_value}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="y_write_list_14">
            <div class="y_write_list_15">
                <div class="y_write_list_16">
                    <h2>核销状态：</h2>
                </div>
                <div class="y_write_list_17">
                    <p :class="item.is == '1'?'y_write_list_17_active':''" v-for="(item,index) in write_type" :key="index" @click="write_type_click(item.index)">{{ item.text }}</p>
                </div>
            </div>
            <div class="y_write_list_15" v-if="write_type[0].is == '1'?true:false">
                <div class="y_write_list_16">
                    <h2>核销者：</h2>
                </div>
                <div class="y_write_list_10">
                    <div class="y_write_list_11" @click="pop_show(3)">
                        <div class="y_write_list_12 y_write_list_18" >
                            <p>{{hxz_title == ''?'查看全部':hxz_title}}</p>
                            <img src="../../../images/icon_30.png" alt="">
                        </div>
                        <div class="y_write_list_13" v-if="false">
                            <p>s的接口sad尽量快打dadas方式开了</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="y_write_list_15" v-if="write_type[0].is == '1'?true:false">
                <div class="y_write_list_16">
                    <h2>核销时间：</h2>
                </div>
                <div class="y_write_list_10">
                    <div class="y_write_list_11 y_write_list_19">
                        <div class='y_push_setting_7' @click="pop_time_one_block">
                            <p>{{time_one_Value == ''?'请选择时间':time_one_Value}}</p>
                        </div>
                        <p>~</p>
                        <div class='y_push_setting_7' @click="pop_time_two_block">
                            <p>{{time_two_Value == ''?'请选择时间':time_one_Value}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="y_write_list_14">
            <div class="y_write_list_15 y_write_list_20">
                <div class="y_write_list_16">
                    <h2>中奖者：</h2>
                </div>
                <div class="y_write_list_10 y_write_list_21">
                    <div class="y_write_list_22">
                        <div class="y_write_list_11" @click="weitr_school_show()">
                            <div class="y_write_list_12 y_write_list_18" v-if="weitr_school_title_is == '0'?true:false">
                                <p>全部学校</p>
                                <img src="../../../images/icon_30.png" alt="">
                            </div>
                            <div class="y_write_list_13" v-if="weitr_school_title_is == '1'?true:false">
                                <p>{{ weitr_school_title }}</p>
                            </div>
                        </div>
                        <div class="y_write_list_11" @click="grade_show()">
                            <div class="y_write_list_12 y_write_list_18" v-if="grade_value == ''?true:false">
                                <p>全部年级</p>
                                <img src="../../../images/icon_30.png" alt="">
                            </div>
                            <div class="y_write_list_13" v-if="grade_value == ''?false:true">
                                <p>{{grade_value}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="y_write_list_23" @click="participant_Enquiry()">
                        <p>查询</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="y_write_list_24">
            <div class="y_write_list_25">
                <h2>当前查询总人数：</h2>
                <p>{{total_people}}</p>
            </div>
        </div>

        <div class="y_write_list_26" @scroll="Scroll_all">
            <div class="y_write_list_27" v-for="(item,index) in write_list" :key="index" @click="page_write_date(item.id,item.status)">
                <div class="y_write_list_28">
                    <img :src="item.award_prize_img" alt="">
                </div>
                <div class="y_write_list_29">
                    <div class="y_write_list_30">
                        <div class="y_write_list_31">
                            <h2>ID:{{item.award_id}}</h2>
                        </div>
                        <div class="y_write_list_32">
                            <p>{{item.school_title}}</p>
                        </div>
                        <div class="y_write_list_33">
                            <p>{{(item.school_type_text == 'undergraduate'?'本科':'专科')}}</p>
                        </div>
                        <div class="y_write_list_34">
                            <p>{{item.start_school_year}}</p>
                        </div>
                        <div class="y_write_list_35">
                            <p>入学</p>
                        </div>
                    </div>
                    <div class="y_write_list_36">
                        <div class="y_write_list_37">
                            <h2>抽奖ID</h2>
                            <p>{{item.id}}</p>
                        </div>
                        <div class="y_write_list_37 y_write_list_38">
                            <h2>{{item.award_prize_level}}等奖</h2>
                            <p>{{item.award_prize_title}}</p>
                        </div>
                    </div>
                    <div class="y_write_list_39">
                        <div class="y_write_list_40" v-if="item.status == 'success'?true:false">
                            <h2>已核销</h2>
                        </div>
                        <div class="y_write_list_40" v-if="item.status == 'not_check'?true:false">
                            <h2>未核销</h2>
                        </div>
                        <div class="y_write_list_41 y_write_list_44">
                            <div class="y_write_list_42">
                                <h2>核销者 </h2>
                            </div>
                            <div class="y_write_list_43">
                                <p>{{item.business_nicname}}</p>
                            </div>
                        </div>
                        <div class="y_write_list_41">
                            <div class="y_write_list_43">
                                <p>{{item.check_time_string}}</p>
                            </div>
                            <div class="y_write_list_42">
                                <h2>核销</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        


        <!-- 公共弹窗 阴影-->
        <div class='y_comm_shadows' :class="comm_shadows_value == '1'?'y_comm_shadows_block':''"></div>
        <!-- 时间选择 最小值 -->
        <div class='y_pop_time_min' :class='pop_time_one_value == "0"?"":"y_pop_time_min_active"' v-show="hideshow">
            <van-datetime-picker v-model="currentDate_min" disabled="disabled" type="date" title=""  @confirm='pop_time_one_dete' @cancel='pop_time_one_cancel'/>
        </div>
        <!-- 时间选择 最大值 --> 
        <div class='y_pop_time_min' :class='pop_time_two_value == "0"?"":"y_pop_time_min_active"' v-show="hideshow">
            <van-datetime-picker v-model="currentDate_max" disabled="disabled" type="date" title=""  @confirm='pop_time_two_dete' @cancel='pop_time_two_cancel'/>
        </div>
        <!-- 全部抽奖 -->
        <div class="y_pop_cj_1" v-if="pop_value == '0'?false:(pop_value == '1'?'true':false)">
            <div class="y_pop_cj_2">
                <div class="y_pop_cj_3">
                    <div class="y_pop_cj_4" @click="pop_hide()">
                        <img src="../../../images/icon_16.png" alt="">
                    </div>
                    <div class="y_pop_cj_5">
                        <h2>全部抽奖</h2>
                    </div>
                    <div class="y_pop_cj_6" @click="pop_pick(1)">
                        <p>确定</p>
                    </div>
                </div>
                <div class="y_pop_cj_7">
                    <div class="y_pop_cj_8">
                        <div class="y_pop_cj_9">
                            <img src="../../../images/icon_18.png" alt="">
                        </div>
                        <div class="y_pop_cj_10">
                            <el-input v-model="all_cj_value" type="text" placeholder="输入抽奖ID"></el-input>
                        </div>
                        <div class="y_pop_cj_11" @click="all_cj_search()">
                            <p>查询</p>
                        </div>
                    </div>
                    <div class="y_pop_cj_12" @click="view_all(1)">
                        <p>查看全部</p>
                    </div>
                </div>
                <div class="y_pop_cj_13"  @scroll="Scroll_all_cj">
                    <div class="y_pop_cj_14" v-for="(item,index) in award_list" :key="index" @click="raffle_select(item.award_id)">
                        <div class="y_pop_cj_15">
                            <h2>{{item.award_prize[0].title}}</h2>
                        </div>
                        <div class="y_pop_cj_16">
                            <img src="../../../images/icon_20.png" v-if="item.award_prize[0].is == '0'" alt="">
                            <img src="../../../images/icon_19.png" v-else alt="">
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- 全部奖品 -->
        <div class="y_pop_cj_1" v-if="pop_value == '0'?false:(pop_value == '2'?'true':false)">
            <div class="y_pop_cj_2">
                <div class="y_pop_cj_3">
                    <div class="y_pop_cj_4" @click="pop_hide()">
                        <img src="../../../images/icon_16.png" alt="">
                    </div>
                    <div class="y_pop_cj_5">
                        <h2>全部奖品</h2>
                    </div>
                    <div class="y_pop_cj_6" @click="pop_pick(2)">
                        <p>确定</p>
                    </div>
                </div>
                <div class="y_pop_cj_7">
                    <!-- <div class="y_pop_cj_8">
                        <div class="y_pop_cj_9">
                            <img src="../../../images/icon_18.png" alt="">
                        </div>
                        <div class="y_pop_cj_10">
                            <input type="text" placeholder="输入抽奖ID">
                        </div>
                        <div class="y_pop_cj_11">
                            <p>查询</p>
                        </div>
                    </div>
                    <div class="y_pop_cj_12">
                        <p>查看全部</p>
                    </div> -->
                </div>
                <div class="y_pop_cj_13">
                    <div class="y_pop_cj_14" v-for="(item,index) in all_cj_one.award_prize" :key="index" @click="raffle_select_jp(item.award_prize_id)">
                        <div class="y_pop_cj_15">
                            <h2>{{item.title}}</h2>
                        </div>
                        <div class="y_pop_cj_16" >
                            <img src="../../../images/icon_20.png" v-if="item.is == '0'" alt="">
                            <img src="../../../images/icon_19.png" v-else alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 核销者 -->
        <div class="y_pop_cj_1" v-if="pop_value == '0'?false:(pop_value == '3'?'true':false)">
            <div class="y_pop_cj_2">
                <div class="y_pop_cj_3">
                    <div class="y_pop_cj_4" @click="pop_hide()">
                        <img src="../../../images/icon_16.png" alt="">
                    </div>
                    <div class="y_pop_cj_5">
                        <h2>全部核销者</h2>
                    </div>
                    <div class="y_pop_cj_6" @click="pop_pick(3)">
                        <p>确定</p>
                    </div>
                </div>
                <div class="y_pop_cj_7">
                    <div class="y_pop_cj_8">
                        <div class="y_pop_cj_9">
                            <img src="../../../images/icon_18.png" alt="">
                        </div>
                        <div class="y_pop_cj_10">
                            <el-input v-model="all_hxz_value" type="text" placeholder="输入抽奖ID"></el-input>
                        </div>
                        <div class="y_pop_cj_11" @click="all_hxz_search()">
                            <p>查询</p>
                        </div>
                    </div>
                    <div class="y_pop_cj_12" @click="view_all(3)">
                        <p>查看全部</p>
                    </div>
                </div>
                <div class="y_pop_cj_13" @scroll="Scroll_all_hxz">
                    <div class="y_pop_cj_14" v-for="(item,index) in write_offs_list" :key="index" @click="raffle_select_hxz(item.id)">
                        <div class="y_pop_cj_15">
                            <h2>{{item.nicname}}</h2>
                        </div>
                        <div class="y_pop_cj_16">
                            <img src="../../../images/icon_20.png" v-if="item.is == '0'" alt="">
                            <img src="../../../images/icon_19.png" v-else alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 全部学校 -->
        <div class="y_weitr_school_1" v-if="weitr_school_if == '0'?false:true" @scroll="handleScroll" ref="scrollDiv">
            <div class="y_weitr_school_2">
                <div class="y_weitr_school_3">
                    <div class="y_write_list_4">
                        <div class="y_write_list_5">
                            <img src="../../../images/icon_18.png" alt="">
                        </div>
                        <div class="y_write_list_6">
                            <input type="text" v-model="school_input_val" placeholder="搜索大学">
                        </div>
                        <div class="y_write_list_7" @click="school_input_data()">
                            <p>搜索</p>
                        </div>
                    </div>
                </div>
                <div class="y_weitr_school_4">
                    <div class="y_weitr_school_5" @click="school_select()">
                        <p>院校区域</p>
                        <img src="../../../images/icon_30.png" alt="" :class="school_shadow == '0'?'':'y_weitr_school_5_img'">
                    </div>
                    <div class="y_weitr_school_6" @click="school_data(-1)">
                        <p>全部学校</p>
                    </div>
                    <div class="y_weitr_school_6" style="margin-left: 0.1rem;" @click="school_data(0)">
                        <p>返回</p>
                    </div>
                </div>
            </div>
            <div class="y_weitr_school_7">
                <div class="y_weitr_school_8" v-for="(item,index) in shcool_list" :key="index" @click="school_data(item.school_id,item.title)">
                    <div class="y_weitr_school_9">
                        <img :src="item.school_badge" alt="">
                    </div>
                    <div class="y_weitr_school_10">
                        <h2>{{item.title}}</h2>
                    </div>
                </div>
            </div>

            <div class="y_weitr_school_11" :class="shcool_vla == '1'?'y_weitr_school_11_active':''">
                <div class="y_weitr_school_12">
                    <p @click="province_date(0)" :class="province_all == '1'?'y_weitr_school_12_active':''">全部</p>
                    <p :class="item.is == '1'?'y_weitr_school_12_active':''" v-for="(item,index) in pro_list" :key="index" @click="province_date(item.id)">{{ item.areaname }}</p>
                </div>
                <div class="y_weitr_school_13">
                    <p :class="item.is == '1'?'y_weitr_school_13_active':''" v-for="(item,index) in city_list" :key="index" @click="city_date(item.id)">{{ item.areaname }}</p>
                </div>
            </div>

            <div class="y_weitr_school_14" v-if="school_shadow == '1'?true:false" @click="school_shadow_hide()"></div>
        </div>
        <!-- 年级 选择 -->
        <div class='y_pop_eight_point' :class="grade_is == '1'?'y_display_block':''">
            <van-popup position="bottom">
                <van-picker :columns="grade_list" @confirm="onConfirm_eight_point_block" @cancel="showPicker_eight_point_none"  />
            </van-popup>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'   // 接口
    import { ref } from 'vue';
    import { createApp } from 'vue';
    import { Picker } from 'vant';
    import { Toast } from 'vant';

    import {award_check_list} from '../../api/request.js' // 我的核销列表
    import {school_list_page} from '../../api/request.js' // 学校列表，分页版
    import {province_list} from '../../api/request.js' // 省市列表
    import {award_joiner_list} from '../../api/request.js' // 参与者列表
    import {award_list_check} from '../../api/request.js' // 参与者列表
    import {checker_list} from '../../api/request.js' // 参与者列表
    
    
    const app = createApp();
    app.use(Toast);
    export default {
        data(){
            return {
                docmHeight: document.documentElement.clientHeight ||document.body.clientHeight,
                showHeight: document.documentElement.clientHeight ||document.body.clientHeight,
                hideshow:true,  //显示或者隐藏footer
                token:'',
                write_type:[
                    {text:'已核销',is:'1',index:'0',str:'success'},
                    {text:'未核销',is:'0',index:'1',str:'not_check'},
                    {text:'参与但未中奖',is:'0',index:'2',str:'none'}
                ],
                comm_shadows_value:'0',
                // 第一个时间选择
                time_one_Value:'',
                pop_time_one_value:'0',
                // 第二个时间选择
                time_two_Value:'',
                pop_time_two_value:'0',
                minDate:'',
                maxDate:'',
                write_list:[],
                shcool_list:[],
                pop_value:'0',
                page:'1',
                pages:'1',
                observer: null,
                pro_list:[],
                city_list:[],
                shcool_vla:'0',             // 院校区域下拉
                school_shadow:'0',
                school_id:'',
                province_all:'1',
                school_input_val:'',
                school_data_id:'',          // 学校确定 id
                weitr_school_if:'0',        // 全部学校 显示隐藏
                weitr_school_title:'',
                weitr_school_title_is:'0',
                grade_list:[
                    {
                        values:['大一','大二','大三','大四','研一','研二','研三','已毕业'],
                        defaultIndex: 0,
                        z_val:['college_1','college_2','college_3','college_4','postgraduate_1','postgraduate_2','postgraduate_3','graduated'],
                    },
                    {
                        values:[],
                        defaultIndex: 0,
                    },
                    {
                        values:['入学'],
                        defaultIndex: 0,
                    },
                ],
                grade_is:'0',
                grade_value:'',
                total_people:'0',
                is_status:'success',
                award_list:[],   // 全部抽奖列表
                write_offs_list:[],  // 核销者列表
                isLoading: false,
                pop_index:'',
                tc_page:'1',
                tc_pages:'1',
                all_cj_one:{},
                all_cj_value:'',
                all_hxz_value:'',
                hxz_title:'',
                hxz_title_cun:'',
                all_cj_two_value:'',
                c_award_id:'',
                c_award_prize_id:'',
                c_grade_list_index:'',
                c_check_uid:'',

                z_keyword:'',
                z_award_id:'',
                z_award_prize_id:'',
                z_status:'',
                z_check_uid:'',
                z_page:'',
                z_pages:'',
            }
        },  
        components:{
            axios,
            createApp,
            Picker,
            Toast,
        },
        watch:{
            //监听显示高度
            showHeight:function() {
                if(this.docmHeight > this.showHeight){
                    //隐藏
                    this.hideshow=false
                }else{
                    //显示
                    this.hideshow=true
                }
            }
        },
        mounted(){
            let token = this.$cookies.get('token')
            let city_id_chun = sessionStorage.getItem('city_id_chun')  // 城市
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            //监听事件
            window.onresize = ()=>{
              return(()=>{
                  this.showHeight = document.documentElement.clientHeight || document.body.clientHeight;
              })()
            },
            // 我的核销列表
            award_joiner_list({token:token,page:'1',status:'success'}).then((res) =>{
                console.log(res,'我的核销列表')
                let list = res.list
                for(let i=0;i<list.length;i++){
                    if(list[i].award_prize_level == '1'){
                        list[i].award_prize_level = '一'
                    }else if(list[i].award_prize_level == '2'){
                        list[i].award_prize_level = '二'
                    }else if(list[i].award_prize_level == '3'){
                        list[i].award_prize_level = '三'
                    }else if(list[i].award_prize_level == '4'){
                        list[i].award_prize_level = '四'
                    }else if(list[i].award_prize_level == '5'){
                        list[i].award_prize_level = '五'
                    }else if(list[i].award_prize_level == '6'){
                        list[i].award_prize_level = '六'
                    }else if(list[i].award_prize_level == '7'){
                        list[i].award_prize_level = '七'
                    }else if(list[i].award_prize_level == '8'){
                        list[i].award_prize_level = '八'
                    }else if(list[i].award_prize_level == '9'){
                        list[i].award_prize_level = '九'
                    }else if(list[i].award_prize_level == '10'){
                        list[i].award_prize_level = '十'
                    }
                }
                this.write_list = list
                this.total_people = res.count
                this.z_page = res.page
                this.z_pages = res.pages
            })
            // 学校列表，分页版
            school_list_page({is_show_cost_common:'1'}).then((res)=>{
                console.log(res,'学校列表')
                this.shcool_list = res.data
                this.page = res.page
                this.pages = res.pages
            })
            // 省市列表
            province_list({}).then((res) =>{
                console.log(res,'省列表')
                let list = res.data
                for(let i=0;i<list.length;i++){
                    list[i].is = '0'
                }
                this.pro_list = res.data
            })
            window.addEventListener('scroll', this.handleScroll)

            let year = new Date().getFullYear();
            let year_list = []
            let grade_list = this.grade_list
            for(let i=0;i<6;i++){
                let year_val = year*1 - i*1
                year_list.push(year_val)
            }
            this.grade_list[1].values = year_list

            let write_type = this.write_type
            let z_status = []
            for (const item of write_type) {
                if(item.is == '1'){
                    z_status.push(item.str)
                }
            }
            this.z_status = z_status
        },
        methods: {
            // 核销状态选择
            write_type_click(index){
                let that = this
                let write_type = that.write_type
                let z_status = []
                for(let i=0;i<write_type.length;i++){
                    if(write_type[i].index == index){
                        if(write_type[i].is == '1'){
                            write_type[i].is = '0'
                        }else{
                            write_type[i].is = '1'
                        }
                    }
                }
                for (const item of write_type) {
                    if(item.is == '1'){
                        z_status.push(item.str)
                    }
                }
                that.z_status = z_status
                that.write_type = write_type
            },
            // 全部抽奖  全部奖品  核销者
            pop_show(val){
                let that = this
                let token = that.token
                this.comm_shadows_value = '1'
                this.pop_value = val
                this.pop_index = val
                let all_cj_one = that.all_cj_one
                if(val == '1'){
                    // 抽奖列表-核销用
                    award_list_check({token:token,page:'1'}).then((res)=>{
                        let that = this
                        let award_list = res.data
                        for(let i=0;i<award_list.length;i++){
                            award_list[i].award_prize[0].is = '0'
                        }
                        that.award_list = award_list
                        that.tc_page = res.page
                        that.tc_pages = res.pages
                    })
                }else if(val == '2'){
                    for (const item of all_cj_one.award_prize) {
                        item.is = '0'
                    }
                    that.all_cj_one = all_cj_one
                }else if(val == '3'){
                    // 核销列表
                    checker_list({token:token,page:'1'}).then((res)=>{
                        let that = this
                        let write_offs_list = res.data
                        for(let i=0;i<write_offs_list.length;i++){
                            write_offs_list[i].is = '0'
                        }
                        that.write_offs_list = write_offs_list
                        that.tc_page = res.page
                        that.tc_pages = res.pages
                    })
                }
            },
            // 全部抽奖  全部奖品  核销者 关闭
            pop_hide(){
                this.comm_shadows_value = '0'
                this.pop_value = '0'
            },

            // 第一个时间选择 弹窗
            pop_time_one_block(e){
                this.comm_shadows_value = '1'
                this.pop_time_one_value = '1'
            },
            pop_time_one_dete(val){
                let year = val.getFullYear()
                let month = val.getMonth() + 1
                let day = val.getDate()
                if (month >= 1 && month <= 9) { month = `0${month}` }
                if (day >= 1 && day <= 9) { day = `0${day}` }
                let time_one_Value = `${year}-${month}-${day}`

                let timestamp_two = this.timestamp_two
                let time_one_Value_1 = time_one_Value.substring(0,19);    
                let time_one_Value_2 = time_one_Value_1.replace(/-/g,'/'); 
                let timestamp_one = new Date(time_one_Value_2).getTime();
                if(timestamp_two == ''){

                }else{
                    if(timestamp_one > timestamp_two){
                        this.$message({
                            message:'不能大于最大时间',
                            duration:'2000'
                        })
                        return
                    }
                }
                this.timestamp_one = timestamp_one

                this.time_one_Value = time_one_Value
                this.comm_shadows_value = '0'
                this.pop_time_one_value = '0'
                console.log(this.time_one_Value)
            },
            pop_time_one_cancel(e){
                this.comm_shadows_value = '0'
                this.pop_time_one_value = '0'
            },
            // 第二个时间选择 弹窗
            pop_time_two_block(e){
                this.comm_shadows_value = '1'
                this.pop_time_two_value = '1'
            },
            pop_time_two_dete(val){
                let year = val.getFullYear()
                let month = val.getMonth() + 1
                let day = val.getDate()
                if (month >= 1 && month <= 9) { month = `0${month}` }
                if (day >= 1 && day <= 9) { day = `0${day}` }
                let time_two_Value = `${year}-${month}-${day}`

                let timestamp_one = this.timestamp_one
                let time_one_Value_1 = time_two_Value.substring(0,19);    
                let time_one_Value_2 = time_one_Value_1.replace(/-/g,'/'); 
                let timestamp_two = new Date(time_one_Value_2).getTime();
                if(timestamp_one == ''){

                }else{
                    if(timestamp_one > timestamp_two){
                        this.$message({
                            message:'不能小于最小时间',
                            duration:'2000'
                        })
                        return
                    }
                }
                this.timestamp_two = timestamp_two

                this.time_two_Value = time_two_Value
                this.comm_shadows_value = '0'
                this.pop_time_two_value = '0'
                console.log(this.time_two_Value)
            },
            pop_time_two_cancel(e){
                this.comm_shadows_value = '0'
                this.pop_time_two_value = '0'
            },
            // 学校滚动触底监听
            handleScroll(event) {
                let token = this.token
                let page = this.page 
                let pages = this.pages 
                let shcool_list = this.shcool_list
                let school_id = this.school_id
                let school_input_val = this.school_input_val
                let el=event.target;
                if(el.scrollTop+el.clientHeight>=el.scrollHeight){
                    if(page<pages){
                        school_list_page({token:token,is_show_cost_common:'1',page:(page*1+1),city_id:school_id,keyword:school_input_val}).then((res)=>{
                            if(res.error_code == '0'){
                                const loading = this.$loading({
                                    lock: true,
                                    text: '加载中...',
                                    spinner: 'el-icon-loading',
                                    background: 'rgba(0, 0, 0, 0.7)'
                                });
                                let list = res.data
                                for(let i=0;i<list.length;i++){
                                    shcool_list.push(list[i])
                                }
                                this.shcool_list = shcool_list
                                this.page = res.page
                                this.pages = res.pages
                                loading.close();
                            }else{
                                this.$message({
                                    message: res.msg,
                                    duration:'2000'
                                })
                            }
                        })
                    }else{
                        this.$message({
                            message: '已经到底了',
                            duration:'2000'
                        })
                    }
                }
            },
            // 省 选择
            province_date(val){
                let pro_list = this.pro_list
                let city_list = this.city_list
                if(val == '0'){
                    for(let i=0;i<pro_list.length;i++){
                        pro_list[i].is = '0'
                    }
                    this.pro_list = pro_list
                    this.city_list = []
                    school_list_page({is_show_cost_common:'1'}).then((res)=>{
                        console.log(res,'学校列表')
                        this.shcool_list = res.data
                        this.page = res.page
                        this.pages = res.pages
                    })

                    let scrollElem = this.$refs.scrollDiv; // 对应ref的命名
                    setTimeout(function(){
                      scrollElem.scrollTo({ top:'0', behavior: 'smooth' });
                    },100)

                    this.province_all = '1'
                    this.school_id = ''
                    this.shcool_vla = '0'
                    this.school_shadow = '0'

                }else{
                    for(let i=0;i<pro_list.length;i++){
                        pro_list[i].is = '0'
                        if(pro_list[i].id == val){
                            pro_list[i].is = '1'
                        }
                    }
                    this.pro_list = pro_list
                    province_list({parentid:val}).then((res) =>{
                        console.log(res,'市')
                        this.city_list = res.data
                    })
                    this.province_all = '0'
                }
            },
            // 市 选择
            city_date(val){
                let pro_list = this.pro_list
                let city_list = this.city_list
                for(let i=0;i<city_list.length;i++){
                    city_list[i].is = '0'
                    if(city_list[i].id == val){
                        city_list[i].is = '1'
                    }
                }
                this.city_list = city_list
                school_list_page({is_show_cost_common:'1',city_id:val}).then((res)=>{
                    console.log(res)
                    this.shcool_list = res.data
                    this.page = res.page
                    this.pages = res.pages
                    this.school_id = val
                    if(res.error_code == '1'){
                        this.$message({
                            message:res.msg,
                            duration:'2000'
                        })
                    }
                    this.shcool_vla = '0'
                    this.school_shadow = '0'
                })
            },
            // 院校区域 下拉
            school_select(){
                let school_shadow = this.school_shadow
                if(school_shadow == '0'){
                    this.shcool_vla = '1'
                    this.school_shadow = '1'
                }else{
                    this.shcool_vla = '0'
                    this.school_shadow = '0'
                }

            },
            // 院校区域 下拉隐藏
            school_shadow_hide(){
                this.shcool_vla = '0'
                this.school_shadow = '0'
            },
            // 院校区域 搜索
            school_input_data(){
                let school_input_val = this.school_input_val
                school_list_page({is_show_cost_common:'1',keyword:school_input_val}).then((res)=>{
                    if(res.error_code == '0'){
                        const loading = this.$loading({
                            lock: true,
                            text: '加载中...',
                            spinner: 'el-icon-loading',
                            background: 'rgba(0, 0, 0, 0.7)'
                        });
                        this.shcool_list = res.data
                        this.page = res.page
                        this.pages = res.pages
                        loading.close();
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            },
            // 学校确定
            school_data(val,title){
                if(val == '0'){
                    this.weitr_school_if = '0'
                }else if(val == '-1'){
                    this.weitr_school_if = '0'
                    this.weitr_school_title_is = '0'
                    this.weitr_school_title = ''
                }else{
                    this.weitr_school_if = '0'
                    this.weitr_school_title = title
                    this.weitr_school_title_is = '1'
                    this.school_data_id = val
                }
                
            },
            // 全部学校 弹窗显示
            weitr_school_show(){
                this.weitr_school_if = '1'
            },
            // 全部年级 弹窗显示
            grade_show(){
                let weitr_school_title_is = this.weitr_school_title_is
                // if(weitr_school_title_is == '0'){
                //     this.$message({
                //         message: '请先选择学校',
                //         duration:'2000'
                //     })
                // }else{
                //     this.comm_shadows_value = '1'
                //     this.grade_is = '1'
                // }
                this.comm_shadows_value = '1'
                this.grade_is = '1'
            },
            // 跳转详情
            page_write_date(val,is){
                if(is == 'success'){
                    this.$message({
                        message: '已核销',
                        duration:'2000'
                    })
                }else{
                    this.$router.push({
                        path:'/write_offs_date',
                        query: {
                            award_joiner_id:val
                        }
                    })
                }
            },
            // 全部抽奖
            Scroll_all_cj(event) {
                const { target } = event;
                const { scrollTop, clientHeight, scrollHeight } = target;
                if (scrollTop + clientHeight >= scrollHeight - 5 && !this.isLoading) {
                    this.loadMoreData();
                }
            },
            Scroll_all_hxz(event) {
                const { target } = event;
                const { scrollTop, clientHeight, scrollHeight } = target;
                if (scrollTop + clientHeight >= scrollHeight - 5 && !this.isLoading) {
                    this.loadMoreData();
                }
            },
            // 参与者列表 下拉
            Scroll_all(event){
                const { target } = event;
                const { scrollTop, clientHeight, scrollHeight } = target;
                if (scrollTop + clientHeight >= scrollHeight - 5 && !this.isLoading) {
                    this.loadMoreData_all();
                }
            },
            async loadMoreData() {
                let that = this
                let token = that.token
                let tc_page = that.tc_page
                let tc_pages = that.tc_pages
                let pop_index = that.pop_index
                let all_cj_value = that.all_cj_value
                let all_hxz_value = that.all_hxz_value
                that.isLoading = true;
                await new Promise((resolve) => setTimeout(resolve, 500));
                const loading = this.$loading({
                    lock: true,
                    text: '加载中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
    
                if(pop_index == '1'){
                    if(tc_page*1+1 <= tc_pages){
                        award_list_check({token:token,page:tc_page*1+1,keyword:all_cj_value}).then((res)=>{
                            let award_list = that.award_list
                            let arr = res.data  
                            for(let i=0;i<arr.length;i++){
                                if(arr[i].award_prize.length > 0){
                                    arr[i].award_prize[0].is = '0'
                                    award_list.push(arr[i])
                                }
                            }
                            that.award_list = award_list
                            that.tc_page = res.page
                            that.tc_pages = res.pages
                            console.log(that.tc_page,'that.tc_page')
                        })
                    }else{
                        this.$message({
                            message: '到底了',
                            duration:'2000'
                        })
                    }
                }else if(pop_index == '3'){
                    if(tc_page*1+1 < tc_pages){
                        checker_list({token:token,page:tc_page*1+1,keyword:all_hxz_value}).then((res)=>{
                            let write_offs_list = that.write_offs_list
                            let arr = res.data  
                            for(let i=0;i<arr.length;i++){
                                arr[i].is = '0'
                                write_offs_list.push(arr[i])
                            }
                            that.write_offs_list = write_offs_list
                            that.tc_page = res.page
                            that.tc_pages = res.pages
                        })
                    }else{
                        this.$message({
                            message: '到底了',
                            duration:'2000'
                        })
                    }
                }
                loading.close();
                that.isLoading = false;
            },
            async loadMoreData_all(){
                    let that = this
                    let token = that.token
                    let keyword = that.z_keyword
                    let award_id = that.z_award_id
                    let award_prize_id = that.z_award_prize_id
                    let status = that.z_status.toString()
                    let school_id = that.school_data_id
                    let grade_level_text = that.grade_list[0].z_val[that.c_grade_list_index]
                    let check_uid = that.time_one_Value
                    let starttime = that.time_two_Value
                    let endtime = that.z_endtime
                    let page = that.z_page
                    let pages = that.z_pages
                    let write_list = that.write_list
                    await new Promise((resolve) => setTimeout(resolve, 500));
                    const loading = this.$loading({
                        lock: true,
                        text: '加载中...',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    if(page*1+1 <= pages){
                        award_joiner_list({token:token,keyword,award_id,award_prize_id,status,school_id,grade_level_text,check_uid,check_uid,starttime,endtime,page:page*1+1}).then((res)=>{
                            if(res.error_code == '0'){
                                let list = res.list
                                for(let i=0;i<list.length;i++){
                                    if(list[i].award_prize_level == '1'){
                                        list[i].award_prize_level = '一'
                                    }else if(list[i].award_prize_level == '2'){
                                        list[i].award_prize_level = '二'
                                    }else if(list[i].award_prize_level == '3'){
                                        list[i].award_prize_level = '三'
                                    }else if(list[i].award_prize_level == '4'){
                                        list[i].award_prize_level = '四'
                                    }else if(list[i].award_prize_level == '5'){
                                        list[i].award_prize_level = '五'
                                    }else if(list[i].award_prize_level == '6'){
                                        list[i].award_prize_level = '六'
                                    }else if(list[i].award_prize_level == '7'){
                                        list[i].award_prize_level = '七'
                                    }else if(list[i].award_prize_level == '8'){
                                        list[i].award_prize_level = '八'
                                    }else if(list[i].award_prize_level == '9'){
                                        list[i].award_prize_level = '九'
                                    }else if(list[i].award_prize_level == '10'){
                                        list[i].award_prize_level = '十'
                                    }
                                    write_list.push(list[i])
                                }
                                that.write_list = write_list
                                that.total_people = res.count
                                that.z_page = res.page
                                that.z_pages = res.pages
                            }else{
                                this.$message({
                                    message: res.msg,
                                    duration:'2000'
                                })
                            }
                        })
                    }else{
                        this.$message({
                            message: '到底了',
                            duration:'2000'
                        })
                    }
                    
                    loading.close();
            },
            // 全部抽奖 选中
            raffle_select(id){
                let that = this
                let award_list = that.award_list
                for(let i=0;i<award_list.length;i++){
                    award_list[i].award_prize[0].is = '0'
                    if(award_list[i].award_id == id){
                        award_list[i].award_prize[0].is = '1'
                        that.all_cj_one = award_list[i]
                        that.c_award_id = id
                    }
                }
                that.award_list = award_list
            },
            raffle_select_jp(id){
                let that = this
                let all_cj_one = that.all_cj_one
                for (const item of all_cj_one.award_prize) {
                    item.is = '0'
                    if(item.award_prize_id == id){
                        item.is = '1'
                        that.all_cj_two_value = item.title
                        that.c_award_prize_id = id
                    }
                }
                that.all_cj_one = all_cj_one
            },
            raffle_select_hxz(id){
                let that = this
                let write_offs_list = that.write_offs_list
                for(let i=0;i<write_offs_list.length;i++){
                    write_offs_list[i].is = '0'
                    if(write_offs_list[i].id == id){
                        write_offs_list[i].is = '1'
                        that.hxz_title_cun = write_offs_list[i].nicname
                        that.c_check_uid = write_offs_list[i].id
                    }
                }
                that.write_offs_list = write_offs_list
            },
            // 全部抽奖 确定
            pop_pick(val){
                let that = this
                that.pop_value = '0'
                that.comm_shadows_value = '0'
                if(val == '1'){
                    that.z_award_id = that.c_award_id
                }
                if(val == '2'){
                    that.z_award_prize_id = that.c_award_prize_id
                }
                if(val == '3'){
                    that.hxz_title = that.hxz_title_cun
                    that.z_check_uid = that.c_check_uid
                    console.log(that.hxz_title,that.hxz_title_cun)
                }
            },
            // 全部抽奖 搜索
            all_cj_search(){
                let that = this
                let token = that.token
                let all_cj_value = that.all_cj_value
                const loading = this.$loading({
                    lock: true,
                    text: '加载中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                award_list_check({token:token,keyword:all_cj_value}).then((res)=>{
                    let award_list = []
                    let arr = res.data  
                    for (const item of arr) {
                        if(item.award_prize.length > 0){
                            item.award_prize[0].is = '0'
                            award_list.push(item)
                        }
                    }
                    that.award_list = award_list
                })
                loading.close();
            },
            // 核销者 搜索
            all_hxz_search(){
                let that = this
                let token = that.token
                let all_hxz_value = that.all_hxz_value
                const loading = this.$loading({
                    lock: true,
                    text: '加载中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                checker_list({token:token,keyword:all_hxz_value}).then((res)=>{
                    let write_offs_list = []
                    let arr = res.data  
                    for (const item of arr) {
                        item.is = '0'
                        write_offs_list.push(item)
                    }
                    that.write_offs_list = write_offs_list
                })
                loading.close();
            },
            // 查看全部
            view_all(index){
                let that = this
                let token = that.token
                if(index == '1'){
                    award_list_check({token:token,page:'1'}).then((res)=>{
                        let award_list = res.data
                        for(let i=0;i<award_list.length;i++){
                            if(award_list[i].award_prize.length > 0){
                                award_list[i].award_prize[0].is = '0'
                            }
                        }
                        that.award_list = award_list
                        that.tc_page = res.page
                        that.tc_pages = res.pages
                    })
                }else if(index == '3'){
                    checker_list({token:token,page:'1'}).then((res)=>{
                        let write_offs_list = res.data
                        for(let i=0;i<write_offs_list.length;i++){
                            if(write_offs_list[i].length > 0){
                                write_offs_list[i].is = '0'
                            }
                        }
                        that.write_offs_list = write_offs_list
                        that.tc_page = res.page
                        that.tc_pages = res.pages
                    })
                }
            },
            // 查询
            participant_Enquiry(){
                let that = this
                let token = that.token
                let keyword = that.z_keyword
                let award_id = that.z_award_id
                let award_prize_id = that.z_award_prize_id
                let status = that.z_status.toString()
                let school_id = that.school_data_id
                let grade_level_text = that.grade_list[0].z_val[that.c_grade_list_index]
                let check_uid = that.time_one_Value
                let starttime = that.time_two_Value
                let endtime = that.z_endtime
                let page = that.z_page
                
                let write_list = that.write_list
                award_joiner_list({token:token,keyword,award_id,award_prize_id,status,school_id,grade_level_text,check_uid,check_uid,starttime,endtime,page}).then((res)=>{
                    if(res.error_code == '0'){
                        let list = res.list
                        for(let i=0;i<list.length;i++){
                            if(list[i].award_prize_level == '1'){
                                list[i].award_prize_level = '一'
                            }else if(list[i].award_prize_level == '2'){
                                list[i].award_prize_level = '二'
                            }else if(list[i].award_prize_level == '3'){
                                list[i].award_prize_level = '三'
                            }else if(list[i].award_prize_level == '4'){
                                list[i].award_prize_level = '四'
                            }else if(list[i].award_prize_level == '5'){
                                list[i].award_prize_level = '五'
                            }else if(list[i].award_prize_level == '6'){
                                list[i].award_prize_level = '六'
                            }else if(list[i].award_prize_level == '7'){
                                list[i].award_prize_level = '七'
                            }else if(list[i].award_prize_level == '8'){
                                list[i].award_prize_level = '八'
                            }else if(list[i].award_prize_level == '9'){
                                list[i].award_prize_level = '九'
                            }else if(list[i].award_prize_level == '10'){
                                list[i].award_prize_level = '十'
                            }
                        }
                        that.write_list = list
                        that.total_people = res.count
                        that.z_page = res.page
                        that.z_pages = res.pages
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            },
        },
        beforeDestroy() {
          window.removeEventListener('scroll', this.handleScroll)
        },
        setup() {
            const comm_shadows_value = ref('0');
            const grade_is = ref('0');
            const grade_value = ref('');
            const currentDate_min = ref(new Date(2023, 0, 1));
            const currentDate_max = ref(new Date());
            let c_grade_list_index = ref('');
            const onConfirm_eight_point_block = (value,index) => {
                console.log(`${value}`,index);
                c_grade_list_index.value = index[0]
                comm_shadows_value.value = '0'
                grade_is.value = '0'
                grade_value.value = `${value}`
            };
            const showPicker_eight_point_none = (value) => {
                comm_shadows_value.value = '0'
                grade_is.value = '0'
            };
            return {
                // minDate: new Date(),
                // maxDate: new Date(2035, 11, 1),
                currentDate_min,
                currentDate_max,
                onConfirm_eight_point_block,
                showPicker_eight_point_none,
                comm_shadows_value,
                grade_is,
                grade_value,
                c_grade_list_index
            };
        },
        
    }

</script>

<style>
    .y_comm_shadows{
        transition:ease 0s all
    }
    .y_write_list_1{
        width: 100%;
        background: #fff;
    }
    .y_write_list_2{
        padding: 0rem 0.25rem;
        border-bottom: 0.01rem solid #EEEEEE;
    }
    .y_write_list_3{
        width: 100%;
        height: 0.8rem;
        display: flex;
        align-items: center;
    }
    .y_write_list_4{
        padding: 0rem 0.25rem 0rem 0.3rem;
        border-radius: 0.5rem;
        height: 0.6rem;
        background: #F0F0F0;
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }
    .y_write_list_5{
        width: 0.33rem;
    }
    .y_write_list_5 img{
        width: 100%;
        object-fit: contain;
    }
    .y_write_list_6{
        flex: 1;
        margin-left: 0.15rem;
    }
    .y_write_list_6 input{
        width: 100%;
        font-size: 0.24rem;
        color: #333;
        background: transparent;
        border: none;
        line-height: 0.6rem;
        padding: 0px;
        height: 0.6rem;
    }
    .y_write_list_6 input::placeholder{
        color: #ccc;
    }
    .y_write_list_7{
        width: 0.5rem;
        height: 100%;
    }
    .y_write_list_7 p{
        font-size: 0.24rem;
        color: #333;
        line-height: 0.6rem;
    }
    .y_write_list_8{
        width: 100%;
        height: 0.8rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_write_list_9{
        width: 1.3rem;
    }
    .y_write_list_9 h2{
        color: #333333;
        font-size: 0.26rem;
    }
    .y_write_list_10{
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }
    .y_write_list_11{
        flex: 1;
    }
    .y_write_list_12{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .y_write_list_12 p{
        color: #333333;
        font-size: 0.26rem;
        margin-right: 0.2rem;
    }
    .y_write_list_12 img{
        width: 0.18rem;
        object-fit: contain;
    }
    .y_write_list_13{
        padding: 0rem 0.15rem;
        text-align: center;
    }
    .y_write_list_13 p{
        font-size: 0.26rem;
        color: #333;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
    .y_write_list_14{
        padding: 0rem 0.25rem;
        border-bottom: 0.01rem solid #EEEEEE;
    }
    .y_write_list_15{
        width: 100%;
        height: 0.8rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_write_list_16{
        width: 1.3rem;
    }
    .y_write_list_16 h2{
        font-size: 0.26rem;
        color: #333333;
    }
    .y_write_list_17{
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }
    .y_write_list_17 p{
        line-height: 0.6rem;
        background: #F0F0F0;
        transition: ease 0.5s all;
        padding: 0rem 0.15rem;
        margin-right: 0.2rem;
        font-size: 0.24rem;
        color: #333333;
        border-radius: 0.1rem;
    }   
    .y_write_list_17 .y_write_list_17_active{
        background: #FECE0A;
        transition: ease 0.5s all;
    }
    .y_write_list_18{
        justify-content: flex-start;
    }
    .y_write_list_19{
        justify-content: flex-start;
        display: flex;
    }
    .y_write_list_19 .y_push_setting_7{
        width: 2rem;
        height: 0.6rem;
        overflow: hidden;
        border:0.01rem solid #F0F0F0;
        border-radius: 0.1rem;
    }
    .y_write_list_19 .y_push_setting_7 p{
        background: transparent;
        line-height: 0.6rem;
        height: 0.6rem;
        font-size: 0.24rem;
    }
    .y_write_list_19>p{
        font-size: 0.28rem;
        color: #333333;
        padding: 0rem 0.15rem;
        display: flex;
        align-items: center;
    } 
    .y_write_list_20{
        height: 1rem;
    }
    .y_write_list_21{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }
    .y_write_list_22{
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }
    .y_write_list_23{
        width: 1rem;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .y_write_list_23 p{
        width: 100%;
        height: 0.6rem;
        border-radius: 0.1rem;
        background: #FECE0A;
        color: #333;
        font-size: 0.3rem;
        text-align: center;
        line-height: 0.6rem;
        font-weight: 600;
    }
    .y_write_list_24{
        background: #F4F5F7;
        height: 0.8rem;
    }
    .y_write_list_25{
        padding: 0rem 0.25rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }
    .y_write_list_25 h2{
        color: #AAAAAA;
        font-size: 0.24rem;
    }
    .y_write_list_25 p{
        color: #333333;
        font-size: 0.24rem;
    }
    .y_write_list_26{
        width: 100%;
        height: calc(100vh - 5.85rem);
        overflow: scroll;
    }
    .y_write_list_27{
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
    .y_write_list_28{
        width: 1rem;
        margin-top: 0.4rem;
        padding-left: 0.25rem;
    }
    .y_write_list_28 img{
        width: 0.6rem;
        height: 0.6rem;
        object-fit: cover;
        border-radius: 1rem;
        overflow: hidden;
    }
    .y_write_list_29{
        flex: 1;
        border-bottom: 0.01rem solid #EEEEEE;
        margin-top: 0.4rem;
        padding-bottom: 0.3rem;
    }
    .y_write_list_30{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_write_list_31{
        margin-right: 0.17rem;
    }
    .y_write_list_31 h2{
        color: #666666;
        font-size: 0.22rem;
    }
    .y_write_list_32{
        max-width: 1.35rem;
        padding: 0rem 0.08rem;
        background: #FECE0A;
        margin-right: 0.1rem;
    }
    .y_write_list_32 p{
        line-height: 0.3rem;
        color: #333;
        font-size: 0.2rem;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
    .y_write_list_33{
        margin-right: 0.2rem;
    }
    .y_write_list_33 p{
        display: inline-block;
        padding: 0rem 0.1rem;
        background: #FBF8E9;
        color: #FECE0A;
        font-size: 0.2rem;
        line-height: 0.3rem;
    }
    .y_write_list_34{
        margin-right: 0.15rem;
    }
    .y_write_list_34 p{
        color: #666666;
        font-size: 0.22rem;
    }
    .y_write_list_35 p{
        color: #BBBBBB;
        font-size: 0.22rem;
    }
    .y_write_list_36{
        display: flex;
        margin-top: 0.15rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .y_write_list_37{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 0.2rem;
    }
    .y_write_list_37 h2{
        color: #BBBBBB;
        font-size: 0.22rem;
        margin-right: 0.1rem;
    }
    .y_write_list_37 p{
        color: #666666;
        font-size: 0.22rem;
    }
    .y_write_list_38{
        border-left: 0.01rem solid #EEEEEE;
        padding-left: 0.15rem;
    }
    .y_write_list_39{
        display:flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0.2rem;
    }
    .y_write_list_40{
        margin-right: 0.15rem;
       
    }
    .y_write_list_40 h2{
        color: #FECE0A;
        font-size: 0.22rem;
    }
    .y_write_list_41{
        padding-left: 0.15rem;
        border-left: 0.01rem solid #EEEEEE;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_write_list_42{
        margin-left: 0.1rem;
    }
    .y_write_list_42 h2{
        color: #BBBBBB;
        font-size: 0.22rem;
    }
    .y_write_list_43{
        color: #666666;
        font-size: 0.22rem;
    }
    .y_write_list_44{
        margin-right: 0.15rem;
    }
    .y_write_list_44 .y_write_list_42{
        margin-left: 0rem;
        margin-right: 0.1rem;
    }

    .y_pop_cj_1{
        width: 100%;
        position: fixed;
        height: 7.5rem;
        left: 0rem;
        bottom: 0rem;
        z-index: 19;
        background: #fff;
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
    }
    .y_pop_cj_2{
        padding: 0rem 0.25rem;
    }
    .y_pop_cj_3{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1rem;
        position: relative;
    }
    .y_pop_cj_4{
        width: 0.35rem;
        height: 0.35rem;
    }
    .y_pop_cj_4 img{
        width: 100%;
        object-fit: cover;
    }
    .y_pop_cj_5{
        flex: 1;
        text-align: center;
        position: absolute;
        z-index: -1;
        width: 100%;
    }
    .y_pop_cj_5 h2{
        font-size: 0.36rem;
        color: #000000;
        font-weight: 600;
    }
    .y_pop_cj_6{
        width: 1.2rem;
    }
    .y_pop_cj_6 p{
        font-size: 0.3rem;
        color: #333333;
        background: #FECE0A;
        width: 100%;
        height: 0.6rem;
        line-height: 0.6rem;
        border-radius: 0.1rem;
        text-align: center;
    }
    .y_pop_cj_7{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1rem;
    }
    .y_pop_cj_8{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 0.15rem;
        background: #F0F0F0;
        height: 0.6rem;
        border-radius: 0.1rem;
        flex: 1;
    }
    .y_pop_cj_9{
        width: 0.33rem;
        margin: 0rem 0.15rem 0rem 0.2rem;
    }
    .y_pop_cj_9 img{
        width: 100%;
        object-fit: cover;
    }
    .y_pop_cj_10{
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_pop_cj_10 input{
        background: transparent;
        border: none;
        width: 100%;
        line-height: 0.6rem;
        font-size: 0.24rem;
        height: 0.6rem;
        padding: 0px;
    }
    .y_pop_cj_11{
        margin-right: 0.25rem;
        width: 0.6rem;
    }
    .y_pop_cj_11 p{
        width: 100%;
        text-align: right;
        font-size: 0.24rem;
        color: #333333;
    }
    .y_pop_cj_12{
        width: 1.4rem;
    }
    .y_pop_cj_12 p{
        width: 100%;
        text-align: center;
        line-height: 0.6rem;
        height: 0.6rem;
        background: #FBF8E9;
        color: #FECE0A;
        font-size: 0.24rem;
    }
    .y_pop_cj_13{
        width: 100%;
        height: 5.5rem;
        overflow: scroll;
    }
    .y_pop_cj_14{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1rem;
        border-bottom: 0.01rem solid #F0F0F0;
    }
    .y_pop_cj_15{
        flex: 1;
    }
    .y_pop_cj_15 h2{
        color: #333333;
        font-size: 0.3rem;
    }
    .y_pop_cj_16{
        width: 0.3rem;
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
    .y_pop_cj_16 img{
        width: 0.25rem;
        height: 0.25rem;
        object-fit: contain;
    }

    .y_weitr_school_1{
        position: fixed;
        width: 100%;
        height: 100vh;
        background: #F4F5F7;
        z-index: 10;
        top: 0rem;
        left: 0rem;
        overflow: scroll;
    }
    .y_weitr_school_2{
        background: #fff;
        padding: 0rem 0.25rem;
        position: fixed;
        top: 0rem;
        left: 0rem;
        width: calc(100% - 0.5rem);
        z-index: 19;
    }
    .y_weitr_school_3{
        height: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .y_weitr_school_4{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.8rem;
        margin-bottom: 0.2rem;
    }
    .y_weitr_school_5{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }
    .y_weitr_school_5 p{
        color: #333333;
        font-size: 0.28rem;
        margin-right: 0.15rem;
    }
    .y_weitr_school_5 img{
        width: 0.2rem;
        object-fit: cover;
        transition: ease 0.5s all;
    }
    .y_weitr_school_5 .y_weitr_school_5_img{
        transition: ease 0.5s all;
        transform: rotate(180deg);
    }
    .y_weitr_school_6{
        margin-left:auto;
    }
    .y_weitr_school_6 p{
        display: inline-block;
        color: #333;
        font-size: 0.24rem;
        background: #FECE0A;
        padding: 0rem 0.18rem;
        border-radius: 0.1rem;
        line-height: 0.51rem;
        height: 0.5rem;
        font-weight: 600;
    }
    .y_weitr_school_7{
        width: 100%;
        margin-top: 2rem;
        background: #fff;
    }
    .y_weitr_school_8{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1.3rem;
    }
    .y_weitr_school_9{
        width: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .y_weitr_school_9 img{
        width: 0.8rem;
        height: 0.8rem;
        object-fit: cover;
        overflow: hidden;
        border-radius: 1rem;
    }
    .y_weitr_school_10{
        border-bottom: 0.01rem solid #EEEEEE;
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .y_weitr_school_10 h2{
        color: #333333;
        font-size: 0.3rem;
    }
    .y_weitr_school_11{
        width: 100%;
        position: fixed;
        z-index: 15;
        background: #fff;
        height: 8.2rem;
        top: -8.2rem;
        left: 0rem;
        transition: ease 0.5s all;
        display: flex;
        justify-content: flex-start;
    }
    .y_weitr_school_11_active{
        top: 1.8rem;
        transition: ease 0.5s all;
    }
    .y_weitr_school_12{
        width: 2.5rem;
        height: 100%;
        overflow: scroll;
    }
    .y_weitr_school_12 p{
        width: 100%;
        font-size: 0.28rem;
        color: #000;
        text-align: center;
        line-height: 0.8rem;
    }
    .y_weitr_school_12 .y_weitr_school_12_active{
        color: #FECE0A;
    }
    .y_weitr_school_12 p:nth-child(1){
        font-weight: 600;
    }
    .y_weitr_school_13{
        flex: 1;
        height: 100%;
        overflow: scroll;
        background: #F6F6F6;
    }
    .y_weitr_school_13 p{
        padding-left: 0.6rem;
        line-height: 0.8rem;
        color: #333333;
        font-size: 0.28rem;
    }
    .y_weitr_school_13 .y_weitr_school_13_active{
        color: #FECE0A;
    }
    .y_weitr_school_14{
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0rem;
        left: 0rem;
        background: rgba(0,0,0,0.5);
        z-index: 11;
    }





</style>

