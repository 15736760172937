<template>
    <div class="y_login_1">
        <img src="../../../images/img_6.png" alt="">
    </div>
    <div class="y_login_2">
        <div class="y_login_3">
            <div class="y_login_4 ">
                <div class="y_login_5">
                    <h2>请填写手机号</h2>
                </div>
                <div class="y_login_6">
                    <el-input v-model="input_one" placeholder="请输入"></el-input>
                </div>
            </div>
            <div class="y_login_4 y_login_7">
                <div class="y_login_5">
                    <h2>请填写验证码</h2>
                </div>
                <div class="y_login_6">
                    <el-input v-model="input_two" placeholder="请输入"></el-input>
                    <div class="y_my_change_phone_one_5">
                        <div  v-if="show == 0" @click="getcode">获取验证码</div>
                        <div  v-if="show == 1">已发送{{count}}秒</div>
                        <div  v-if="show == 2" @click="getcode">重新获取</div>
                    </div>
                </div>
            </div>
            <div class="y_login_4 y_login_7">
                <div class="y_login_5">
                    <h2>请填写邀请码(不必填)</h2>
                </div>
                <div class="y_login_6">
                    <el-input v-model="input_three" placeholder="请输入"></el-input>
                </div>
            </div>
            <div class="y_login_8" @click="login_sub">
                <p>登录</p>
            </div>
        </div>

        
    </div>
</template>

<style scoped>
    .y_login_1{
        width: 100%;
        position: relative;
        z-index: -1;
        height: 100vh;
    }
    .y_login_1 img{
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }
    .y_login_2{
        width: 6.6rem;
        background: #fff;
        overflow: hidden;
        border-radius: 0.2rem;
        height: 8.5rem;
        top:50%;
        left: 50%;
        transform: translate(-50%,-50%);
        position: absolute;
    }
    .y_login_3{
        padding: 0rem 0.4rem;
        display: flex;
        align-content: center;
        height: 100%;
        flex-wrap: wrap;
    }
    .y_login_4{
        width: 100%;
    }
    .y_login_5{
        width: 100%;
        margin-bottom: 0.4rem;
    }
    .y_login_5 h2{
        font-size: 0.36rem;
        color: #999;
    }
    .y_login_6{
        width: 100%;
        height: 0.9rem;
        overflow: hidden;
        background: #F4F4F4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 0.5rem;
        overflow: hidden;
    }
    .y_login_6 /deep/ input{
        flex: 1;
        background: transparent;
        border-radius: 0rem;
        border: none;
        height: 0.9rem;
        line-height: 0.9rem;
        font-size: 0.3rem;
        color: #333;
        padding: 0rem 0rem 0rem 0.4rem;
    }
    .y_login_7{
        margin-top: 0.4rem;
    }
    .y_login_8{
        width: 100%;
        margin-top: 0.4rem;
        height: 0.9rem;
        border-radius: 1rem;
        overflow: hidden;
        background: #FECE0A;
    }
    .y_login_8 p{
        width: 100%;
        color: #393536;
        font-size: 0.36rem;
        line-height: 0.9rem;
        font-weight: 600;
        text-align: center;
    }

    /*  */
    .y_my_change_phone_one_5{
        width: 3rem;
        text-align: center;
    }
    .y_my_change_phone_one_5 div{
        font-size: 0.3rem;
        color: #FECE0A;
    }
</style>

<script>
    import axios from 'axios'   // 接口
    import {login} from '../../api/request.js'   // 登录
    import {ajax_code} from '../../api/request.js'   // 发送验证码
    import VueCookies from 'vue-cookies';
    export default {
        data(){
            return {
                input_one: '',
                input_two:'',
                input_three:'',

                show: 0,
			    count: '',
			    timer: null,

                phone:'',
                checkcode:'',
                invitation_code:'',
                token:'',
                msg:'',
            }
        },  
        components:{
            axios,
            VueCookies
        },
        created(){
            // 获取页面传参 推送类型
            this.token = this.$route.query.token
            this.msg = this.$route.query.msg
            this.input_one = this.$route.query.phone
        },
        mounted(){
            // this.$cookies.set('token', 111,"7D")  
            // sessionStorage.setItem("token",111)
            // return
            let token = this.token
            if(token !== undefined){
                this.$message({
                    message: '登录成功',
                    duration:'500'
                })
                this.$cookies.set('token',token,"7D")  
                sessionStorage.setItem("token",token)
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/'}) 
                },1000);
            }else if(this.input_one !== undefined){
                console.log(this.input_one)
                if(sessionStorage.getItem('input_one') == this.input_one){
                    let msg = this.msg
                    this.input_one = sessionStorage.getItem('input_one')
                    this.input_two = sessionStorage.getItem('input_two')
                    this.input_three = sessionStorage.getItem('input_three')
                    this.$message({
                        message: msg,
                        duration:'500'
                    })
                }
            }
        },
        methods: {
            // 倒计时时间
            getcode(){
                let input_one = this.input_one
                if(input_one.length !== 11){
                    this.$message({
                        message: '请输入正确手机号码',
                        duration:'1000'
                    })
                    return
                }
                ajax_code({phone:input_one}).then((res) =>{
                    console.log(res,'发送验证码')
                    if(res.error_code == '0'){
                        this.$message({
                            message: res.msg,
                            duration:'1000'
                        })
                        const times = 60; 
	                    if (!this.timer) {
	                     	this.count = times;
	                    	this.show = 1;
	                    	this.timer = setInterval(() => {
	                    		if (this.count > 0 && this.count <= times) {
	                    			this.count--;
	                    		} else {
	                    			this.show = 2;
	                    			clearInterval(this.timer);
	                    			this.timer = null;
	                    		}
	                    	}, 1000)
	                    }
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'1000'
                        })
                    }
                })
	        	
	        },
            // 登录
            login_sub(){
                let input_one = this.input_one
                let input_two = this.input_two
                let input_three = this.input_three
                if(input_one == ''){
                    this.$message({
                        message: '请先填写手机号',
                        duration:'1000'
                    })
                    return
                }
                if(input_two == ''){
                    this.$message({
                        message: '请先填写验证码',
                        duration:'1000'
                    })
                    return
                }
          
                login({phone:input_one,checkcode:input_two,invitation_code:input_three}).then((res) =>{
                    console.log(res,'登录')
                    if(res.error_code == '0'){
                        if(res.has_openid == '0'){

                            sessionStorage.setItem("input_one",input_one)
                            sessionStorage.setItem("input_two",input_two)
                            sessionStorage.setItem("input_three",input_three)

                            window.location.href= 'https://xcx.mjxyq.com/api/index.php/business-index/login-weixin.html?phone='+input_one+'&checkcode='+input_two+'&invitation_code='+input_three
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'1000'
                            })
                            
                            sessionStorage.setItem("token",res.token)
                            this.$cookies.set('token', res.token,"7D")  
                            var that = this;
                            setTimeout(function () {
                                that.$router.push({path:'/'}) 
                            },1000);
                        }

                    }else if(res.error_code == '1'){
                        this.$message({
                            message: res.msg,
                            duration:'1000'
                        }) 
                    }else if(res.error_code == '1001'){
                        window.location.href= 'https://xcx.mjxyq.com/api/index.php/business-index/login-weixin.html?phone='+input_one+'&checkcode='+input_two+'&invitation_code='+input_three
                    }
                })

                return
                
                // this.$router.push({
                //     path:'/push_settings',
                //     query: {
                //         general_index: '1',
                //         general_index_index:this.index_index,
                //     }
                // })

            }
        }
    }
</script>