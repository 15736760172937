<template>
    <div class="y_write_date_0">
        <div class="y_write_list_27 y_write_date_1" v-if="award_joiner_list  == ''?false:true">
            <div class="y_write_list_28">
                <img :src="award_joiner_list.award_prize_img" alt="">
            </div>
            <div class="y_write_list_29">
                <div class="y_write_list_30">
                    <div class="y_write_list_31">
                        <h2>ID:{{award_joiner_list.award_id}}</h2>
                    </div>
                    <div class="y_write_list_32">
                        <p>{{award_joiner_list.school_title}}</p>
                    </div>
                    <div class="y_write_list_33">
                        <p>{{(award_joiner_list.school_type_text == 'undergraduate'?'本科':'专科')}}</p>
                    </div>
                    <div class="y_write_list_34">
                        <p>{{award_joiner_list.start_school_year}}</p>
                    </div>
                    <div class="y_write_list_35">
                        <p>入学</p>
                    </div>
                </div>
                <div class="y_write_list_36">
                    <div class="y_write_list_37">
                        <h2>抽奖ID</h2>
                        <p>{{award_joiner_list.id}}</p>
                    </div>
                    <div class="y_write_list_37 y_write_list_38">
                        <h2>{{award_joiner_list.award_prize_level}}等奖</h2>
                        <p>{{award_joiner_list.award_prize_title}}</p>
                    </div>
                </div>
                <div class="y_write_list_39">
                    <div class="y_write_list_40" v-if="award_joiner_list.status == 'success'?true:false">
                        <h2>已核销</h2>
                    </div>
                    <div class="y_write_list_40" v-if="award_joiner_list.status == 'not_check'?true:false">
                        <h2>未核销</h2>
                    </div>
                    <div class="y_write_list_41 y_write_list_44">
                        <div class="y_write_list_42">
                            <h2>核销者 </h2>
                        </div>
                        <div class="y_write_list_43">
                            <p>{{award_joiner_list.business_nicname}}</p>
                        </div>
                    </div>
                    <div class="y_write_list_41">
                        <div class="y_write_list_43">
                            <p>{{award_joiner_list.check_time}}</p>
                        </div>
                        <div class="y_write_list_42">
                            <h2>核销</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="y_write_date_2" @click="write_date_show()">
            <p>点击核销</p>
        </div>
        <div class="y_write_date_2" @click="go_back()">
            <p>返回</p>
        </div>
        <div class="y_write_date_3" v-if="write_date_is == '0'?false:true">
            <div class="y_write_date_4">
                <div class="y_write_date_5">
                    <h2>是否确认核销</h2>
                </div>
                <div class="y_write_date_6">
                    <div class="y_write_date_7" @click="write_date_no()">
                        <p>取消</p>
                    </div>
                    <div class="y_write_date_8" @click="write_date_yes()">
                        <p>确认</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {award_check_detail} from '../../api/request.js'   // 抽奖核销详情页
    import {award_check} from '../../api/request.js'   // 核销中奖记录
    
    export default {
        data(){
            return {
                token:'',
                award_joiner_id:'',
                award_joiner_list:'',
                write_date_is:'0',
            }
        },  
        components:{

        },
        created(){
            if(this.$route.query.award_joiner_id !== undefined){
              this.award_joiner_id = this.$route.query.award_joiner_id
            }
        },
        mounted(){
            let token = this.$cookies.get('token')
            let city_id_chun = sessionStorage.getItem('city_id_chun')  // 城市
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            let award_joiner_id = this.award_joiner_id
            // 抽奖核销详情页
            award_check_detail({token:token,award_joiner_id:award_joiner_id}).then((res)=>{
                console.log(res)
                if(res.error_code == '0'){
                    this.award_joiner_list = res.award_joiner
                }
            })
        },
        methods: {
            // 取消
            write_date_no(){
                this.write_date_is = '0'
            },
            // 弹窗显示
            write_date_show(){
                this.write_date_is = '1'
            },
            // 确定
            write_date_yes(){
                let token = this.token
                let award_joiner_id = this.award_joiner_id
                award_check({token:token,award_joiner_id:award_joiner_id}).then((res)=>{
                    console.log(res,'核销中奖记录')
                    if(res.error_code == '0'){
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                        let that = this
                        setTimeout(function () {
                            that.$router.push({path:'/my_index'}) 
                        },2500);
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            },
            go_back(){
                this.$router.go(-1)
            }
        },
    }

</script>

<style>
    body{
        background:#F4F5F7;
    }
    .y_write_date_0{
        width:100%;
        margin-top:0.2rem;
    }
    .y_write_date_1{
        background:#fff;
    }
    .y_write_date_2{
        padding:0rem 0.25rem;
        margin-top:0.6rem;
    }
    .y_write_date_2 p{
        width:100%;
        background:#FECE0A;
        line-height:1rem;
        border-radius:0.2rem;
        text-align:center;
        color:#333333;
        font-size:0.32rem;
        font-weight:600;
    }
    .y_write_date_3{
        width:100%;
        height:100vh;
        position:absolute;
        background:rgba(0,0,0,0.5);
        top:0rem;
        left:0rem;
        z-index:5;
    }
    .y_write_date_4{
        width:5.2rem;
        height:2.6rem;
        background:#fff;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        border-radius:0.2rem;
        position:absolute;
    }
    .y_write_date_5{
        width:100%;
        text-align:center;
        margin-top:0.6rem;
    }
    .y_write_date_5 h2{
        font-size:0.36rem;
        color:#333333;
        text-align:center;
        font-weight:600;
    }
    .y_write_date_6{
        margin-top:0.3rem;
        display:flex;
        justify-content:center;
        align-content:center;
    }
    .y_write_date_6>div>p{
        width: 2rem;
        line-height: 0.7rem;
        font-size: 0.3rem;
        color: #333;
        border-radius: 1rem;
        text-align: center;
    }
    .y_write_date_7{
        margin-right: 0.1rem;
    }
    .y_write_date_7 p{
        background: #ECECEC;
    }
    .y_write_date_8 p{
        background: #FECE0A;
    }


</style>
