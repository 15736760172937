<template>
    <div class="y_index">
        <!-- <div class='y_index_1'>
            <router-link class='y_index_2' to='/'>关于喵君</router-link>
            <router-link class='y_index_2' to='/'>使用攻略</router-link>
        </div> -->
        <push_charges></push_charges>
        <div class="y_recruit_1">
          <img src="../../../images/img_3.png" alt="">
        </div>

        <div class="y_advanced_push_two_1">
            <div class="y_advanced_push_two_2">
                <div class="y_advanced_push_two_3">推送内容类别</div>
                <div class="y_advanced_push_two_4" @click="push_content_category">
                    <p>{{pop_three_right_value == ''?'点击选择':pop_three_lefts_value+'-'+pop_three_right_value}}</p>
                    <img src="../../../images/icon_12.png" alt="">
                </div>
            </div>
        </div>
        <div class="y_advanced_push_two_5">
            <div class="y_advanced_push_two_6">
                <!-- <div class="y_advanced_push_two_7">
                    <h2>推送时间</h2>
                    <p>2021年11月17日~2021年11月27日</p>
                </div> -->
                <div class='y_push_setting_5'>
                    <h2>推送时间</h2>
                    <div class='y_push_setting_6'>
                        <div class='y_push_setting_7' @click="pop_time_one_block">
                            <p>{{time_one_Value}}</p>
                        </div>
                        <p>-</p>
                        <div class='y_push_setting_7' @click="pop_time_two_block">
                            <p>{{time_two_Value}}</p>
                        </div>
                    </div>
                </div>
                <!-- 本科学校 -->
                <div class="y_advanced_push_two_8" >
                    <div class="y_advanced_push_two_9">
                        <h2>本科学校</h2>
                        <div class="y_advanced_push_two_10">
                            <div class="y_advanced_push_two_11">
                                <h2>每天每人曝光</h2>
                                <p>{{senior_push_undergraduate_exposure_price}}元</p>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                            <div class="y_advanced_push_two_11">
                                <h2>每天点击</h2>
                                <p>{{senior_push_undergraduate_click_price}}元</p>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="y_advanced_push_two_12" v-for="undergraduate in undergraduates" :key="undergraduate.index">
                        <div class="y_advanced_push_two_13">
                            <h2>{{undergraduate.title}}</h2>
                            <div class="y_advanced_push_two_14" @click="un_grade_pop(undergraduate.index)">
                                <h2>推送给</h2>
                                <p>{{undergraduate.grade}}</p>
                                <img src="../../../images/icon_24.png" alt="">
                            </div>
                            <div class="y_advanced_push_two_15" :class="undergraduate.is == '1'? 'y_advanced_push_two_15_active':''" @click="under_select(undergraduate.index)">
                                <img src="../../../images/icon_19.png" alt="">
                                <img src="../../../images/icon_20.png" alt="">
                            </div>
                        </div>
                        <div class="y_advanced_push_two_16">
                            <div class="y_advanced_push_two_17">
                                <div class="y_advanced_push_two_18">
                                    <h2>曝光人次至多</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <div class="y_advanced_push_two_19" v-if="is_val == '0'?true:false">
                                    <p>{{undergraduate.expact_exposure_number}}</p>
                                </div>
                                <div class="y_advanced_push_two_19 y_advanced_push_two_19_1" v-if="is_val == '1'?true:false">
                                    <input type="text" placeholder="请输入" v-model="undergraduate.expact_exposure_number" @input="henfu_click">
                                </div>
                            </div>
                            <div class="y_advanced_push_two_17">
                                <div class="y_advanced_push_two_18">
                                    <h2>点击人数至多</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <div class="y_advanced_push_two_19">    
                                    <p>{{undergraduate.expact_click_number}}</p>
                                </div>
                            </div>
                            <div class="y_advanced_push_two_17">
                                <div class="y_advanced_push_two_18">
                                    <h2>预算</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <div class="y_advanced_push_two_19">
                                    <p>{{((senior_push_undergraduate_exposure_price*undergraduate.expact_exposure_number)+(senior_push_undergraduate_click_price*undergraduate.expact_click_number)).toFixed(2)}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 大专学校 -->
                <div class="y_advanced_push_two_8" >
                    <div class="y_advanced_push_two_9">
                        <h2>大专学校</h2>
                        <div class="y_advanced_push_two_10">
                            <div class="y_advanced_push_two_11">
                                <h2>每天每人曝光</h2>
                                <p>{{senior_push_specialty_exposure_price}}元</p>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                            <div class="y_advanced_push_two_11">
                                <h2>每天点击</h2>
                                <p>{{senior_push_specialty_click_price}}元</p>
                                <img src="../../../images/icon_21.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="y_advanced_push_two_12" v-for="college in colleges" :key="college.index">
                        <div class="y_advanced_push_two_13">
                            <h2>{{college.title}}</h2>
                            <div class="y_advanced_push_two_14" @click="coll_grade_pop(college.index)">
                                <h2>推送给</h2>
                                <p>{{college.grade}}</p>
                                <img src="../../../images/icon_24.png" alt="">
                            </div>
                            <div class="y_advanced_push_two_15" :class="college.is == '1'? 'y_advanced_push_two_15_active':''" @click="coll_select(college.index)">
                                <img src="../../../images/icon_19.png" alt="">
                                <img src="../../../images/icon_20.png" alt="">
                            </div>
                        </div>
                        <div class="y_advanced_push_two_16">
                            <div class="y_advanced_push_two_17">
                                <div class="y_advanced_push_two_18">
                                    <h2>曝光人次至多</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <div class="y_advanced_push_two_19"  v-if="is_val == '0'?true:false">
                                    <p>{{college.expact_exposure_number}}</p>
                                </div>
                                <div class="y_advanced_push_two_19 y_advanced_push_two_19_1"  v-if="is_val == '1'?true:false">
                                    <input type="text" placeholder="请输入" v-model="college.expact_exposure_number" @input="henfu_click">
                                </div>
                            </div>
                            <div class="y_advanced_push_two_17">
                                <div class="y_advanced_push_two_18">
                                    <h2>点击人数至多</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <div class="y_advanced_push_two_19">    
                                    <p>{{college.expact_click_number}}</p>
                                </div>
                            </div>
                            <div class="y_advanced_push_two_17">
                                <div class="y_advanced_push_two_18">
                                    <h2>预算</h2>
                                    <img src="../../../images/icon_21.png" alt="">
                                </div>
                                <div class="y_advanced_push_two_19">
                                    <p>{{((senior_push_specialty_exposure_price*college.expact_exposure_number)+(senior_push_specialty_click_price*college.expact_click_number)).toFixed(2)}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 公共 阴影 -->
        <div class='y_recruit_two_shade' :class="shade_value == '0'?'':'y_recruit_two_shade_block'"></div>
        <!-- 本科 弹窗 除横幅 -->
        <div class="y_advanced_two_un_pop_1" :class="is_un_grade_diaplay == '0'?'y_display_none':''">
            <div class="y_advanced_two_un_pop_2">
                <div class="y_advanced_two_un_pop_3">
                    <h2>本科年级</h2>
                    <!-- <p>有空位后自动推送您的内容</p> -->
                </div>
                <div class="y_advanced_two_un_pop_4">
                    <div class="y_advanced_two_un_pop_5" :class="un_grade_list.is == '1'?'y_advanced_two_un_pop_5_active':''" v-for="un_grade_list in un_grade_lists" :key="un_grade_list.index" @click="un_grade_select(un_grade_list.index)">
                        <h2>{{un_grade_list.title}}</h2>
                        <!-- <p>{{un_grade_list.content}}</p> -->
                    </div>
                </div>
                <div class="y_advanced_two_un_pop_6">
                    <p class="y_advanced_two_un_pop_7" @click="un_no_select()">返回</p>
                    <p class="y_advanced_two_un_pop_8" @click="un_yes_select()">确定</p>
                </div>
            </div>
        </div>

        <!-- 大专 弹窗 除横幅-->
        <!-- <div class="y_advanced_two_coll_pop_1 y_advanced_two_un_pop_1" :class="is_coll_grade_diaplay == '0'?'y_display_none':''">
            <div class="y_advanced_two_un_pop_2">
                <div class="y_advanced_two_un_pop_3">
                    <h2>可多选，目前无空位的也可选</h2>
                    <p>有空位后自动推送您的内容</p>
                </div>
                <div class="y_advanced_two_coll_pop_2">
                    <div class="y_advanced_two_coll_pop_3 y_advanced_two_coll_pop_3_active">
                        <h2>大一</h2>
                        <p>有空位</p>
                    </div>
                    <div class="y_advanced_two_coll_pop_3">
                        <h2>大二</h2>
                        <p>26小时后有空位</p>
                    </div>
                </div>
                <div class="y_advanced_two_un_pop_6">
                    <p class="y_advanced_two_un_pop_7" @click="coll_no_select()">返回</p>
                    <p class="y_advanced_two_un_pop_8" @click="coll_yes_select()">确定</p>
                </div>
            </div>
        </div> -->

        <div class="y_advanced_two_un_pop_1" :class="is_coll_grade_diaplay == '0'?'y_display_none':''">
            <div class="y_advanced_two_un_pop_2">
                <div class="y_advanced_two_un_pop_3">
                    <h2>专科年级</h2>
                    <!-- <p>有空位后自动推送您的内容</p> -->
                </div>
                <div class="y_advanced_two_un_pop_4">
                    <div class="y_advanced_two_un_pop_5" :class="colleges_grade_list.is == '1'?'y_advanced_two_un_pop_5_active':''" v-for="colleges_grade_list in colleges_grade_lists" :key="colleges_grade_list.index" @click="colleges_grade_select(colleges_grade_list.index)">
                        <h2>{{colleges_grade_list.title}}</h2>
                        <!-- <p>{{un_grade_list.content}}</p> -->
                    </div>
                </div>
                <div class="y_advanced_two_un_pop_6">
                    <p class="y_advanced_two_un_pop_7" @click="coll_no_select()">返回</p>
                    <p class="y_advanced_two_un_pop_8" @click="coll_yes_select()">确定</p>
                </div>
            </div>
        </div>

        <!-- 本科 弹窗 横幅样式 -->
        <div class="y_advanced_banner_un_pop_1" :class="is_un_banner_grade_diaplay == '0'?'y_display_none':''">
            <div class="y_advanced_banner_un_pop_0">
                <div class="y_advanced_banner_un_pop_2">
                    <h2>选择推送给哪些年级</h2>
                </div>
                <div class="y_advanced_banner_un_pop_3">
                    <p class="y_advanced_banner_un_pop_3_active">大一</p>
                    <p>大二</p>
                    <p>大三</p>
                    <p>大四</p>
                    <p>大五</p>
                    <p>研一</p>
                    <p>研二</p>
                    <p>研三</p>
                </div>
                <div class="y_advanced_two_un_pop_6 y_advanced_banner_un_pop_4">
                    <p class="y_advanced_two_un_pop_7" @click="un_banner_no_select()">返回</p>
                    <p class="y_advanced_two_un_pop_8" @click="un_banner_yes_select()">确定</p>
                </div>
            </div>
        </div>

        <!-- 大专 弹窗 横幅样式 -->
        <div class="y_advanced_banner_un_pop_1" :class="is_coll_banner_grade_diaplay == '0'?'y_display_none':''">
            <div class="y_advanced_banner_un_pop_0">
                <div class="y_advanced_banner_un_pop_2">
                    <h2>选择推送给哪些年级</h2>
                </div>
                <div class="y_advanced_banner_coll_pop_1">
                    <p class="y_advanced_banner_coll_pop_1_active">大一</p>
                    <p>大二</p>
                    <p>大三</p>
                </div>
                <div class="y_advanced_two_un_pop_6 y_advanced_banner_un_pop_4">
                    <p class="y_advanced_two_un_pop_7" @click="coll_banner_no_select()">返回</p>
                    <p class="y_advanced_two_un_pop_8" @click="coll_banner_yes_select()">确定</p>
                </div>
            </div>
        </div>

        <!-- 推送内容类别 -->
        <div class='y_pop_three_1' :class="pop_three == 0 ? 'y_display_none':'y_display_block'">
          <div class='y_pop_six_2'>
            <div class='y_pop_six_3' @click="pop_three_none">
              <img src="../../../images/icon_16.png" alt="">
            </div>
            <div class='y_pop_six_4'>
              <h2>岗位类型</h2>
            </div>
            <div class='y_pop_six_5' @click='pop_three_determine'>
              <p>确定</p>
            </div>
          </div>
          <div class='y_pop_three_2'>
            <div class='y_pop_three_6'>
              <p :class='pop_three_left.is == "0"? "":"y_pop_three_left_active"' v-for='pop_three_left in pop_three_lefts' :key="pop_three_left.id" @click="pop_three_left_click(pop_three_left.id)">{{pop_three_left.text}}</p>
            </div>
            <div class='y_pop_three_7'>
              <p :class='pop_three_right.is == "0"? "":"y_pop_three_right_active"' v-for="pop_three_right in pop_three_rights" :key="pop_three_right.id" @click="pop_three_right_click(pop_three_right.id)">{{pop_three_right.title}}</p>
            </div>
          </div>
        </div>

        <!-- 底部垫高 -->
        <div class='y_recruit_two_dian'></div>
        <div class='y_recruit_two_foot_1'>
            <div class='y_recruit_two_foot_2'>
                <div class='y_recruit_two_foot_3'>
                    <p>曝光总人次至多<span>{{exposure_people_max}}</span>点击总人数至多<span>{{cilck_people_max}}</span></p>
                    <div>预算总计<span>{{Total_money}}</span>元
                        <p v-show="price_comparison == '0'?'':true">
                            ，余额不足，<span @click="showDialog(true)">点击充值</span>
                        </p>
                    </div>
                </div>
                <div class='y_recruit_two_foot_4'>
                    <p class='y_recruit_two_foot_5' @click="previous_step">上一步</p>
                    <p class='y_recruit_two_foot_6' @click="pop_up">发布</p>
                </div>
            </div>
        </div>
    </div>
    <!-- 公共弹窗 阴影-->
    <div class='y_comm_shadows' :class="comm_shadows_value == '1'?'y_comm_shadows_block':''"></div>
    <!-- 时间选择 最小值 -->
    <div class='y_pop_time_min' :class='pop_time_one_value == "0"?"":"y_pop_time_min_active"'>
        <van-datetime-picker v-model="currentDate" disabled="disabled" type="datetime" title="" :min-date="minDate" :max-date="maxDate" @confirm='pop_time_one_dete' @cancel='pop_time_one_cancel'/>
    </div>
    <!-- 时间选择 最大值 --> 
    <div class='y_pop_time_min' :class='pop_time_two_value == "0"?"":"y_pop_time_min_active"'>
        <van-datetime-picker v-model="currentDate" disabled="disabled" type="datetime" title="" :min-date="minDate" :max-date="maxDate" @confirm='pop_time_two_dete' @cancel='pop_time_two_cancel'/>
    </div>


    <!-- 余额不足 弹出 -->
    <div class="y_balance_not_pop_1" :class="balance_not_is == '0'?'y_display_none':'y_display_block'">
        <div class="y_balance_not_pop_2">
            <div class="y_balance_not_pop_3">
                <p>预算总计<span>{{Total_money}}</span>元，当前余额<span>{{principal_amount}}</span>元 余额不足，请先充足余额</p>
            </div>
            <div class="y_balance_not_pop_4">
                <p class="y_balance_not_pop_5" @click="balance_not_back">返回</p>
                <p class="y_balance_not_pop_6" @click="balance_not_recharge(true)">充值余额</p>
            </div>
        </div>
    </div>

    <!-- 提交审核 -->
    <div class="y_submit_review_pop_1" :class="submit_review_is == '0'?'y_display_none':'y_display_block'">
        <div class="y_submit_review_pop_2">
            <div class="y_submit_review_pop_3">
                <p>喵君将尽快审核完毕，<br>审核通过后自动发布</p>
            </div>
            <div class="y_submit_review_pop_4">
                <p class="y_submit_review_pop_5" @click="submit_review_back">返回</p>
                <p class="y_submit_review_pop_6" @click="submit_review_recharge">提交审核</p>
            </div>
        </div>
    </div>

    <kefu></kefu>
    <recharge v-show="dialog_visible" :dialog_visible="dialog_visible" @dialogVisibleEvent="showDialog"></recharge>
</template>
<style>
    .y_advanced_push_two_1{
        width: 100%;
        height: 1.2rem;
        background: #fff;
        display: flex;
        align-items: center;
        border-bottom: 0.01rem solid #F3F3F3;
    }
    .y_advanced_push_two_2{
        padding: 0rem 0.25rem 0rem 0.32rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }
    .y_advanced_push_two_3{
        width: 2rem;
        font-size: 0.28rem;
        color: #999;
    }
    .y_advanced_push_two_4{
        flex: 1;
        height: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #F7F8FA;
        padding: 0rem 0.25rem;
    }
    .y_advanced_push_two_4 p{
        font-size: 0.28rem;
        color: #333;
    }
    .y_advanced_push_two_4 img{
        width: 0.14rem;
        object-fit: cover;
    }
    .y_advanced_push_two_5{
        width: 100%;
        background: #fff;
    }
    .y_advanced_push_two_6{
        margin: 0rem 0.25rem;
        width: calc(100% - 0.5rem);
        padding-bottom: 0.5rem;
    }
    .y_advanced_push_two_7{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1rem;
    }
    .y_advanced_push_two_7 h2{
        font-size: 0.28rem;
        color: #999;
        margin-right: 0.3rem;
        margin-left: 0.07rem;
    }
    .y_advanced_push_two_7 p{
        flex: 1;
        font-size: 0.28rem;
        color: #333;
    }
    .y_advanced_push_two_8{
        background: #FECE0A;
        border-radius: 0.1rem;
        padding: 0rem 0.2rem 0.5rem 0.2rem;
        margin-bottom: 0.3rem;
    }
    .y_advanced_push_two_9{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1rem;
    }
    .y_advanced_push_two_9>h2{
        font-size: 0.32rem;
        color: #333;
        font-weight: 600;
        margin-right: 0.23rem;
    }
    .y_advanced_push_two_10{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
        background: #FFF5CE;
        border-radius: 0.1rem;
        height: 0.6rem;
    }
    .y_advanced_push_two_11{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
        margin-left: 0.15rem;
    }
    .y_advanced_push_two_10 .y_advanced_push_two_11:nth-child(1){
        min-width: 52%;
    }
    .y_advanced_push_two_11 h2{
        font-size: 0.24rem;
        color: #999;
    }
    .y_advanced_push_two_11 p{
        margin-left: 0.05rem;
        font-size: 0.28rem;
        color: #FECE0A;
    }
    .y_advanced_push_two_11 img{
        width: 0.2rem;
        height: 0.2rem;
        margin-left: 0.05rem;
    }
    .y_advanced_push_two_12{
        width: 100%;
        background: #fff;
        border-radius: 0.1rem;
        overflow: hidden;
        margin-bottom: 0.2rem;
    }
    .y_advanced_push_two_13{
        background: #FFFBED;
        height: 0.9rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0rem 0.2rem 0rem 0.32rem;
    }
    .y_advanced_push_two_13>h2{
        font-size: 0.28rem;
        color: #333;
        width: 2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 600;
    }
    .y_advanced_push_two_14{
        flex: 1;
        display: flex;
        justify-items: flex-start;
        align-items: center;
        margin-left: 0.1rem;
        height: 100%;
    }
    .y_advanced_push_two_14 h2{
        font-size: 0.28rem;
        color: #999;
        margin-right: 0.1rem;
    }
    .y_advanced_push_two_14 p{
        font-size: 0.28rem;
        color:#FECE0A;
        margin-right: 0.1rem;
        width: 2.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .y_advanced_push_two_14 img{
        width: 0.14rem;
        object-fit: cover;
    }
    .y_advanced_push_two_15{
        width: 0.3rem;
    }
    .y_advanced_push_two_15 img{
        width: 100%;
    }
    .y_advanced_push_two_15 img:nth-child(1){
        display: none;
    }
    .y_advanced_push_two_15 img:nth-child(2){
        display: block;
    }
    .y_advanced_push_two_15_active img:nth-child(1){
        display: block;
    }
    .y_advanced_push_two_15_active img:nth-child(2){
        display: none;
    }
    .y_advanced_push_two_16{
        width: 100%;
        display: flex;
        justify-items: center;
        align-items: center;
        height: 1.5rem;
    }
    .y_advanced_push_two_17{
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
    }
    .y_advanced_push_two_18{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .y_advanced_push_two_18 h2{
        font-size: 0.26rem;
        color: #333;
        margin-right: 0.1rem;
    }
    .y_advanced_push_two_18 img{
        width: 0.2rem;
        height: 0.2rem;
    }
    .y_advanced_push_two_19{
        width: 100%;
        text-align: center;
        margin-top: 0.2rem;
        color:#FECE0A;
        font-size: 0.36rem;
    }
    .y_advanced_push_two_19_1{
        margin-top: 0.1rem;
    }
    .y_advanced_push_two_19_1 input{
        font-size: 0.28rem;
        color: #333;
        line-height: 0.5rem;
        width: 1.6rem;
        border: none;
        text-align: center;
        padding-left: 0.2rem;
        background: #F7F8FA;
    }
    /* 本科选择年级 弹窗 */
    .y_advanced_two_un_pop_1{
        width: 5.6rem;
        background: #fff;
        border-radius: 0.1rem;
        padding: 0.3rem 0rem 0.5rem;
        position: fixed;
        z-index: 20;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .y_advanced_two_un_pop_2{
        display: block;
        margin: 0rem 0.3rem;
    }
    .y_advanced_two_un_pop_3{
        width: 100%;
    }
    .y_advanced_two_un_pop_3 h2{
        font-size: 0.36rem;
        color:#333;
        width: 100%;
        text-align: center;
        font-weight: 600;
    }
    .y_advanced_two_un_pop_3 p{
        width: 100%;
        text-align: center;
        margin-top: 0.2rem;
        color: #999;
        font-size: 0.28rem;
    }
    .y_advanced_two_un_pop_4{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 0.2rem;
    }
    .y_advanced_two_un_pop_5{
        width: 2.4rem;
        height: 1rem;
        background: #F7F8FA;
        transition: ease 0.5s all;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        border-radius: 0.1rem;
        margin-right: 0.2rem;
        margin-bottom: 0.2rem;
    }
    .y_advanced_two_un_pop_5_active{
        background: #FECE0A;
        transition: ease 0.5s all;
    }
    .y_advanced_two_un_pop_4 .y_advanced_two_un_pop_5:nth-child(2n){
        margin-right: 0rem;
    } 
    .y_advanced_two_un_pop_5 h2{
        width: 100%;
        text-align: center;
        font-size: 0.3rem;
        color: #333;
        font-weight: 600;
    }
    .y_advanced_two_un_pop_5 p{
        width: 100%;
        text-align: center;
        font-size: 0.24rem;
        color: #333;
    }
    .y_advanced_two_un_pop_6{
        width: 100%;
        margin-top: 0.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0.7rem;
    }
    .y_advanced_two_un_pop_6 p{
        width: 2rem;
        height: 0.7rem;
        line-height: 0.7rem;
        border-radius: 1rem;
        font-size: 0.3rem;
        text-align: center;
        font-weight: 600;
    }
    .y_advanced_two_un_pop_7{
        background: #ECECEC;
        margin-right: 0.1rem;
        color:#666;
    }
    .y_advanced_two_un_pop_8{
        color: #333;
        background: #FECE0A;
    }
    .y_advanced_two_coll_pop_2{
        width: 100%;
        margin-top: 0.2rem;
    }
    .y_advanced_two_coll_pop_3{
        width: 2.4rem;
        height: 1rem;
        background: #F7F8FA;
        transition: ease 0.5s all;
        margin: 0 auto 0.2rem;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        border-radius: 0.1rem;
    }
    .y_advanced_two_coll_pop_3_active{
        background: #FECE0A;
        transition: ease 0.5s all;
    }
    .y_advanced_two_coll_pop_3 h2{
        width: 100%;
        text-align: center;
        font-size: 0.3rem;
        color: #333;
        font-weight: 600;
    }
    .y_advanced_two_coll_pop_3 p{
        width: 100%;
        text-align: center;
        font-size: 0.24rem;
        color: #333;
    }
    .y_advanced_banner_un_pop_0{
        margin: 0rem 0.3rem;
    }
    .y_advanced_banner_un_pop_1{
        width: 5.6rem;
        background: #fff;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border-radius: 0.1rem;
        z-index: 20;
        padding: 0.65rem 0rem 0.55rem;
    }
    .y_advanced_banner_un_pop_2{
        width: 100%;
    }
    .y_advanced_banner_un_pop_2 h2{
        font-size: 0.36rem;
        color: #333;
        font-weight: 600;
        width: 100%;
        text-align: center;
    }
    .y_advanced_banner_un_pop_3{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 0.4rem;
    }
    .y_advanced_banner_un_pop_3 p{
        width: 1.1rem;
        background: #F7F8FA;
        line-height: 0.8rem;
        height: 0.8rem;
        border-radius: 0.1rem;
        margin-right: 0.2rem;
        margin-bottom: 0.2rem;
        transition: ease 0.5s all;
        text-align: center;
        font-size: 0.28rem;
        font-weight: 600;
        color: #333;
    }
    .y_advanced_banner_un_pop_3 p:nth-child(4n){
        margin-right: 0rem;
    }
    .y_advanced_banner_un_pop_3 .y_advanced_banner_un_pop_3_active{
        transition: ease 0.5s all;
        background: #FECE0A;
    }
    .y_advanced_banner_un_pop_4{
        margin-top: 0.4rem;
    }
    .y_advanced_banner_coll_pop_1{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 0.4rem;
    }
    .y_advanced_banner_coll_pop_1 p{
        width: 1.3rem;
        height: 0.8rem;
        line-height: 0.8rem;
        text-align: center;
        border-radius: 0.1rem;
        background: #F7F8FA;
        margin-right: 0.2rem;
        font-size: 0.28rem;
        color:#333;
        transition: ease 0.5s all;
    }
    .y_advanced_banner_coll_pop_1 .y_advanced_banner_coll_pop_1_active{
        transition: ease 0.5s all;
        background: #FECE0A;
    }
    .y_pop_three_1{
        z-index: 20;
    }

    .y_recruit_two_foot_1{
        height: 2.7rem;
    }
    .y_recruit_two_foot_2{
        border-top: 0.01rem solid #eee;
    }
    .y_push_setting_5{
        padding-left: 0rem;
        padding-right: 0rem;
        width: 100%;
    }


    /* 余额不足 弹出 */
    .y_balance_not_pop_1{
        width: 5.2rem;
        height: 3.5rem;
        background: #fff;
        border-radius: 0.1rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 20;
        overflow: hidden;
    }
    .y_balance_not_pop_2{
        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        height: 100%;
    }
    .y_balance_not_pop_3{
        width: 100%;
        text-align: center;
    }    
    .y_balance_not_pop_3 p{
        color: #333;
        font-size: 0.3rem;
    }
    .y_balance_not_pop_3 p span{
        color: #FECE0A;
        font-size: 0.3rem;
    }
    .y_balance_not_pop_4{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
    }
    .y_balance_not_pop_4 p{
        width: 2rem;
        height: 0.7rem;
        border-radius: 1rem;
        text-align: center;
        font-size: 0.3rem;
        line-height: 0.7rem;
    }
    .y_balance_not_pop_5{
        color: #666;
        background: #ECECEC;
        margin-right: 0.1rem;
    }
    .y_balance_not_pop_6 {
        color: #333;
        background: #FECE0A;
    }
    /* 余额不足 弹出 */

    /* 提交审核 */
    .y_submit_review_pop_1{
        width: 5.2rem;
        height: 3.5rem;
        background: #fff;
        border-radius: 0.1rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 20;
        overflow: hidden;
    }
    .y_submit_review_pop_2{
        width: 95%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        height: 100%;
    }
    .y_submit_review_pop_3{
        width: 100%;
        text-align: center;
    }    
    .y_submit_review_pop_3 p{
        color: #333;
        font-size: 0.3rem;
    }
    .y_submit_review_pop_4{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
    }
    .y_submit_review_pop_4 p{
        width: 2rem;
        height: 0.7rem;
        border-radius: 1rem;
        text-align: center;
        font-size: 0.3rem;
        line-height: 0.7rem;
    }
    .y_submit_review_pop_5{
        color: #666;
        background: #ECECEC;
        margin-right: 0.1rem;
    }
    .y_submit_review_pop_6 {
        color: #333;
        background: #FECE0A;
    }
    .y_recruit_two_foot_3>div{
        color: #333;
        font-size: 0.28rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .y_recruit_two_foot_3>div>span{
        color: #FECE0A;
    }

    /* 提交审核 */
</style>
<script>
import kefu from '../../components/kefu/kefu'
import recharge from '../../components/recharge/recharge'
import push_charges from '../../components/push_charges/push_charges'

import axios from 'axios'   // 接口
import {user_info} from '../../api/request.js'   // 用户信息
import {all_prices} from '../../api/request.js'   // 商务端所有价格的信息
import {school_grade_list} from '../../api/request.js'   // 所有学校和年级列表
import {adspace_info} from '../../api/request.js'   // 广告位详情，高级推广
import {edit_adspace} from '../../api/request.js'   // 添加和编辑广告位，高级推广
import {submit_check_adspace} from '../../api/request.js'   // 广告位提交审核

export default {
        data(){
            return {
                shade_value:'0',  // 公共 阴影
                dialog_visible: false,
                // 本科 列表
                undergraduates:[
                    {index:'0',name:'宁波大学',grade:'大一、大二',is:'0'},
                    {index:'1',name:'浙江大学',grade:'大一、大三',is:'0'}
                ],
                // 大专 列表
                colleges:[
                    {index:'0',name:'宁波职业技术学院',grade:'大一、大二',is:'0'},
                    {index:'1',name:'浙江职业技术学院',grade:'大一、大三',is:'0'}
                ],
                // 本科 年级 列表
                un_grade_lists:[
                    {index:'0',name:'大一',content:'有空位',is:'0'},
                    {index:'1',name:'大二',content:'26小时后有空位',is:'0'},
                    {index:'2',name:'大三',content:'有空位',is:'0'},
                    {index:'3',name:'大四',content:'有空位',is:'0'},
                    {index:'4',name:'大五',content:'有空位',is:'0'},
                    {index:'5',name:'研一',content:'有空位',is:'0'},
                    {index:'6',name:'研二',content:'有空位',is:'0'},
                    {index:'7',name:'研三',content:'有空位',is:'0'},
                ],
                // 大专 年级 列表
                colleges_grade_lists:[],

                un_grade_lists_index:'',
                un_coll_lists_index:'',
                is_un_grade_diaplay:'0',  // 本科 年级弹窗 是否显示
                is_un_banner_grade_diaplay:'0', // 横幅 本科 年级弹窗 是否显示
                is_coll_grade_diaplay:'0',  // 大专 年级弹窗 是否显示
                is_coll_banner_grade_diaplay:'0', // 横幅 大专 年级弹窗 是否显示
            
                // 推送内容类别 弹窗
                pop_three_lefts:[
                  {id:'0',text:'推广',is:'0',value:'push'},
                  {id:'1',text:'招生',is:'0',value:'student'},
                  {id:'2',text:'招聘',is:'0',value:'recruit'},
                ],
                pop_three_rights:[
                  {id:'0',text:'吃喝',is:'0'},
                  {id:'1',text:'玩乐',is:'0'},
                  {id:'2',text:'好物',is:'0'},
                  {id:'3',text:'活动',is:'0'},
                  {id:'4',text:'其它',is:'0'},
                ],
                minDate:new Date(),
                pop_three_lefts_value:'',
                pop_three_right_value:'',
                pop_three:'0',
                pop_three_value:'0',  // 判断右侧是否有点击
                price_type:'',  // 广告位类型
                push_price_id:'', // 推送价格id
                senior_push_undergraduate_exposure_price:'0',   // 高级推送本科曝光单价
                senior_push_specialty_exposure_price:'0',       // 高级推送专科曝光单价
                senior_push_undergraduate_click_price:'0',      // 高级推送本科点击单价
                senior_push_specialty_click_price:'0',          // 高级推送专科点击单价
                // 第一个时间选择
                time_one_Value:'请选择时间',
                pop_time_one_value:'0',
                // 第二个时间选择
                time_two_Value:'请选择时间',
                pop_time_two_value:'0',
                comm_shadows_value:'0',
                city_id_chun:'',  // 城市id

                adspace_id:'0',       // 广告位列表，高级推广 进入传id
                promotion_value:'0',  // 进入传value  0代表正常进入，1为待推送进入

                price_comparison:'0',  // 价格比较 默认为0不显示
                principal_amount:'',  // 本金金额
                balance_not_is:'0',  // 余额不足
                submit_review_is:'0', // 提交审核
                back_award_id:'',       // 提交审核之后返回列表id
                bj_news:'0', // 编辑新推送 0为正常进入 1是编辑新推送

                exposure_people_max:'',    // 曝光人数至多
                cilck_people_max:'', // 点击人数至多
                Total_money:'',    // 预算总价
                is_val:'0',
            }
        },
        created(){
            // 获取页面传参 推送类型
            if(this.$route.query.adspace_id !== undefined){
                this.adspace_id = this.$route.query.adspace_id
                this.promotion_value = this.$route.query.promotion_value
                this.bj_news = this.$route.query.bj_news
            }
            if(this.$route.query.is_val == '1'){
                this.is_val = '1'
            }
        },
        activated(){
            if(this.$route.query.is_val == '1'){
                this.is_val = '1'
            }
            let token = this.$cookies.get('token')
            let that = this;
            let user = JSON.parse(sessionStorage.getItem('user'))
            that.user = user
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            // 钱包金额
            user_info({token:token}).then((res)=>{
                this.user = res.data
                this.principal_amount = res.data.balance*1 + res.data.bonus_fee*1
            })
            // if(that.user == 'user'){
            //     that.principal_amount = that.user.balance*1 + that.user.bonus_fee*1
            // }else{
            //     let user = JSON.parse(sessionStorage.getItem('user'))
            //     that.user = user
            //     that.principal_amount = that.user.balance*1 + that.user.bonus_fee*1
            // }

            let adspace_id = this.adspace_id
            let promotion_value = this.promotion_value
            if(promotion_value == '1'){
                adspace_info({token:token,adspace_id:adspace_id}).then((res) =>{ 
                    console.log(res,'广告位详情，高级推广')
                    let pop_three_lefts = this.pop_three_lefts
                    for(let i=0;i<pop_three_lefts.length;i++){
                        pop_three_lefts[i].is = '0'
                        if(pop_three_lefts[i].value == res.adspace.price_type){
                            pop_three_lefts[i].is = '1'
                            this.pop_three_lefts_value = pop_three_lefts[i].text
                            all_prices({price_type:pop_three_lefts[i].value}).then((res_left)=>{
                                console.log(res_left)
                                let pop_three_rights = res_left.push_price
                                for(let i=0;i<pop_three_rights.length;i++){
                                    pop_three_rights[i].is = '0'
                                    if(pop_three_rights[i].id == res.adspace.push_price_id){
                                        pop_three_rights[i].is = '1'
                                        this.pop_three_right_value = res.adspace.push_price_title
                                    }
                                }
                                this.pop_three_rights = pop_three_rights
                            })
                        }
                    }
                    
                    school_grade_list({city_id:sessionStorage.getItem('city_id_chun'),is_show_cost_common:'1'}).then((res_school)=>{
                        console.log(res_school,'所有学校和年级列表')
                        let school_undergraduate = res_school.school_undergraduate
                        let grade_undergraduate = res_school.grade_undergraduate
                        let common_school = res.cost_adspace_school
                        let is_val = this.is_val
                        for(let j=0;j<grade_undergraduate.length;j++){
                            grade_undergraduate[j].is = '0'
                            grade_undergraduate[j].index = j
                        }
                        for(let i=0;i<school_undergraduate.length;i++){
                            school_undergraduate[i].index = i
                            school_undergraduate[i].is = '0'
                            school_undergraduate[i].grade = ''
                            school_undergraduate[i].grade_arr = ''
                            if(is_val == '1'){
                                school_undergraduate[i].expact_exposure_number = ''
                            }
                            for(let k=0;k<common_school.length;k++){
                                if(common_school[k].school_id == school_undergraduate[i].school_id){
                                    if(common_school[k].school_type_text == 'undergraduate'){
                                        school_undergraduate[i].is = '1'
                                        school_undergraduate[i].expact_exposure_number = common_school[k].exposure_number
                                        let grade = []
                                        for(let a=0;a<common_school[k].grade_list.length;a++){
                                            grade.push(common_school[k].grade_list[a].title)
                                        }
                                        school_undergraduate[i].grade_arr = common_school[k].grade_level_texts.split(',')
                                        let bachelor_dete_list_one = JSON.stringify(grade)
                                        school_undergraduate[i].grade = bachelor_dete_list_one.replace(/\[|]|"/g,"")
                                        // school_undergraduate[i].grade = grade
                                    }
                                }
                                
                            }
                        }
                        for(let q=0;q<school_undergraduate.length;q++){
                            for(let w=0;w<grade_undergraduate.length;w++){
                                for(let x=0;x<school_undergraduate[q].grade_arr.length;x++){
                                    if(school_undergraduate[q].grade_arr[x] == grade_undergraduate[w].grade_level_text){
                                        grade_undergraduate[w].is = '1'
                                    }
                                }
                            }
                            school_undergraduate[q].grade_arr_list = JSON.parse(JSON.stringify(grade_undergraduate))
                            for(let j=0;j<grade_undergraduate.length;j++){
                                grade_undergraduate[j].is = '0'
                            }
                        }
                        
                        let school_specialty = res_school.school_specialty
                        let grade_specialty = res_school.grade_specialty
                        
                        for(let j=0;j<grade_specialty.length;j++){
                            grade_specialty[j].is = '0'
                            grade_specialty[j].index = j
                        }
                        for(let i=0;i<school_specialty.length;i++){
                            school_specialty[i].index = i
                            school_specialty[i].is = '0'
                            school_specialty[i].grade = ''
                            school_specialty[i].grade_arr = ''
                            if(is_val == '1'){
                                school_specialty[i].expact_exposure_number = ''
                            }
                            for(let k=0;k<common_school.length;k++){
                                if(common_school[k].school_id == school_specialty[i].school_id){
                                    if(common_school[k].school_type_text == 'specialty'){
                                        school_specialty[i].is = '1'
                                        school_specialty[i].expact_exposure_number = common_school[k].exposure_number
                                        let grade = []
                                        for(let a=0;a<common_school[k].grade_list.length;a++){
                                            grade.push(common_school[k].grade_list[a].title)
                                        }
                                        school_specialty[i].grade_arr = common_school[k].grade_level_texts.split(',')
                                        let bachelor_dete_list_one = JSON.stringify(grade)
                                        school_specialty[i].grade = bachelor_dete_list_one.replace(/\[|]|"/g,"")
                                        // school_specialty[i].grade = grade
                                    }
                                }
                            }
                        }
                        for(let q=0;q<school_specialty.length;q++){
                            for(let w=0;w<grade_specialty.length;w++){
                                for(let x=0;x<school_specialty[q].grade_arr.length;x++){
                                    if(school_specialty[q].grade_arr[x] == grade_specialty[w].grade_level_text){
                                        grade_specialty[w].is = '1'
                                    }
                                }
                            }
                            school_specialty[q].grade_arr_list = JSON.parse(JSON.stringify(grade_specialty))
                            for(let j=0;j<grade_specialty.length;j++){
                                grade_specialty[j].is = '0'
                            }
                        }
                        // for(let i=0;i<school_specialty.length;i++){
                        //     school_specialty[i].index = i
                        //     school_specialty[i].is = '0'
                        //     school_specialty[i].grade = ''
                        //     school_specialty[i].grade_arr = ''
                        //     school_specialty[i].grade_arr_list = ''
                        //     for(let j=0;j<grade_specialty.length;j++){
                        //         grade_specialty[j].is = '0'
                        //         grade_specialty[j].index = j
                        //     }
                        // }

                        this.undergraduates = school_undergraduate
                        this.un_grade_lists = grade_undergraduate
                        this.colleges = school_specialty
                        this.colleges_grade_lists = grade_specialty

                        this.budget_money()
                    })

                    this.time_one_Value = res.adspace.push_starttime
                    this.time_two_Value = res.adspace.push_endtime
                    this.senior_push_undergraduate_exposure_price = res.cost_adspace.undergraduate_exposure_price
                    this.senior_push_specialty_exposure_price = res.cost_adspace.specialty_exposure_price
                    this.senior_push_undergraduate_click_price = res.cost_adspace.undergraduate_click_price
                    this.senior_push_specialty_click_price = res.cost_adspace.specialty_click_price
                    this.pop_three_lefts = pop_three_lefts

                    this.city_id_chun = res.adspace.city_id
                    this.price_type = res.adspace.price_type
                    this.push_price_id = res.adspace.push_price_id
              
                })
            }
            let adspace_type = sessionStorage.getItem('adspace_type');
            let city_id_chun = sessionStorage.getItem('city_id_chun');
            let advanced_list_xg = JSON.parse(sessionStorage.getItem('advanced_list_xg'));
            console.log(advanced_list_xg)
            this.adspace_type = adspace_type
            this.city_id_chun = city_id_chun
            this.advanced_list_xg = advanced_list_xg
            console.log(adspace_type,'广告位类型')
            // 所有学校和年级列表
            if(promotion_value !== '1'){
                school_grade_list({city_id:sessionStorage.getItem('city_id_chun'),is_show_cost_common:'1'}).then((res)=>{
                    console.log(res,'所有学校和年级列表')
                    let school_undergraduate = res.school_undergraduate
                    let grade_undergraduate = res.grade_undergraduate
                    let is_val = this.is_val
                    for(let i=0;i<school_undergraduate.length;i++){
                        school_undergraduate[i].index = i
                        school_undergraduate[i].is = '0'
                        school_undergraduate[i].grade = ''
                        school_undergraduate[i].grade_arr = ''
                        school_undergraduate[i].grade_arr_list = ''
                        if(is_val == '1'){
                            school_undergraduate[i].expact_exposure_number = ''
                        }
                        for(let j=0;j<grade_undergraduate.length;j++){
                            grade_undergraduate[j].is = '0'
                            grade_undergraduate[j].index = j
                        }
                    }
                    let school_specialty = res.school_specialty
                    let grade_specialty = res.grade_specialty
                    for(let i=0;i<school_specialty.length;i++){
                        school_specialty[i].index = i
                        school_specialty[i].is = '0'
                        school_specialty[i].grade = ''
                        school_specialty[i].grade_arr = ''
                        school_specialty[i].grade_arr_list = ''
                        if(is_val == '1'){
                            school_specialty[i].expact_exposure_number = ''
                        }
                        for(let j=0;j<grade_specialty.length;j++){
                            grade_specialty[j].is = '0'
                            grade_specialty[j].index = j
                        }
                    }


                    this.undergraduates = school_undergraduate
                    this.un_grade_lists = grade_undergraduate
                    this.colleges = school_specialty
                    this.colleges_grade_lists = grade_specialty
                })
            }
        },
        watch:{
            '$route'(){
                if(this.$route.query.is_val == '1'){
                    this.is_val = '1'
                }else{
                    this.is_val = '0'
                }
            }
        },
        mounted(){
            let token = this.$cookies.get('token')
            let that = this;
            let user = JSON.parse(sessionStorage.getItem('user'))
            that.user = user
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            // 钱包金额
            user_info({token:token}).then((res)=>{
                this.user = res.data
                this.principal_amount = res.data.balance*1 + res.data.bonus_fee*1
            })
            // if(that.user == 'user'){
            //     that.principal_amount = that.user.balance*1 + that.user.bonus_fee*1
            // }else{
            //     let user = JSON.parse(sessionStorage.getItem('user'))
            //     that.user = user
            //     that.principal_amount = that.user.balance*1 + that.user.bonus_fee*1
            // }

            let adspace_id = this.adspace_id
            let promotion_value = this.promotion_value
            if(promotion_value == '1'){
                adspace_info({token:token,adspace_id:adspace_id}).then((res) =>{ 
                    console.log(res,'广告位详情，高级推广')
                    let pop_three_lefts = this.pop_three_lefts
                    for(let i=0;i<pop_three_lefts.length;i++){
                        pop_three_lefts[i].is = '0'
                        if(pop_three_lefts[i].value == res.adspace.price_type){
                            pop_three_lefts[i].is = '1'
                            this.pop_three_lefts_value = pop_three_lefts[i].text
                            all_prices({price_type:pop_three_lefts[i].value}).then((res_left)=>{
                                console.log(res_left)
                                let pop_three_rights = res_left.push_price
                                for(let i=0;i<pop_three_rights.length;i++){
                                    pop_three_rights[i].is = '0'
                                    if(pop_three_rights[i].id == res.adspace.push_price_id){
                                        pop_three_rights[i].is = '1'
                                        this.pop_three_right_value = res.adspace.push_price_title
                                    }
                                }
                                this.pop_three_rights = pop_three_rights
                            })
                        }
                    }
                    school_grade_list({city_id:sessionStorage.getItem('city_id_chun'),is_show_cost_common:'1'}).then((res_school)=>{
                        console.log(res_school,'所有学校和年级列表')
                        let school_undergraduate = res_school.school_undergraduate
                        let grade_undergraduate = res_school.grade_undergraduate
                        let common_school = res.cost_adspace_school
                        let is_val = this.is_val
                        for(let j=0;j<grade_undergraduate.length;j++){
                            grade_undergraduate[j].is = '0'
                            grade_undergraduate[j].index = j
                        }
                        for(let i=0;i<school_undergraduate.length;i++){
                            school_undergraduate[i].index = i
                            school_undergraduate[i].is = '0'
                            school_undergraduate[i].grade = ''
                            school_undergraduate[i].grade_arr = ''
                            if(is_val == '1'){
                                school_undergraduate[i].expact_exposure_number = ''
                            }
                            for(let k=0;k<common_school.length;k++){
                                if(common_school[k].school_id == school_undergraduate[i].school_id){
                                    if(common_school[k].school_type_text == 'undergraduate'){
                                        school_undergraduate[i].is = '1'
                                        school_undergraduate[i].expact_exposure_number = common_school[k].exposure_number
                                        let grade = []
                                        for(let a=0;a<common_school[k].grade_list.length;a++){
                                            grade.push(common_school[k].grade_list[a].title)
                                        }
                                        school_undergraduate[i].grade_arr = common_school[k].grade_level_texts.split(',')
                                        let bachelor_dete_list_one = JSON.stringify(grade)
                                        school_undergraduate[i].grade = bachelor_dete_list_one.replace(/\[|]|"/g,"")
                                        // school_undergraduate[i].grade = grade
                                    }
                                }
                                
                            }
                        }
                        for(let q=0;q<school_undergraduate.length;q++){
                            for(let w=0;w<grade_undergraduate.length;w++){
                                for(let x=0;x<school_undergraduate[q].grade_arr.length;x++){
                                    if(school_undergraduate[q].grade_arr[x] == grade_undergraduate[w].grade_level_text){
                                        grade_undergraduate[w].is = '1'
                                    }
                                }
                            }
                            school_undergraduate[q].grade_arr_list = JSON.parse(JSON.stringify(grade_undergraduate))
                            for(let j=0;j<grade_undergraduate.length;j++){
                                grade_undergraduate[j].is = '0'
                            }
                        }
                        
                        let school_specialty = res_school.school_specialty
                        let grade_specialty = res_school.grade_specialty
                        
                        for(let j=0;j<grade_specialty.length;j++){
                            grade_specialty[j].is = '0'
                            grade_specialty[j].index = j
                        }
                        for(let i=0;i<school_specialty.length;i++){
                            school_specialty[i].index = i
                            school_specialty[i].is = '0'
                            school_specialty[i].grade = ''
                            school_specialty[i].grade_arr = ''
                            if(is_val == '1'){
                                school_specialty[i].expact_exposure_number = ''
                            }
                            for(let k=0;k<common_school.length;k++){
                                if(common_school[k].school_id == school_specialty[i].school_id){
                                    if(common_school[k].school_type_text == 'specialty'){
                                        school_specialty[i].is = '1'
                                        school_specialty[i].expact_exposure_number = common_school[k].exposure_number
                                        let grade = []
                                        for(let a=0;a<common_school[k].grade_list.length;a++){
                                            grade.push(common_school[k].grade_list[a].title)
                                        }
                                        school_specialty[i].grade_arr = common_school[k].grade_level_texts.split(',')
                                        let bachelor_dete_list_one = JSON.stringify(grade)
                                        school_specialty[i].grade = bachelor_dete_list_one.replace(/\[|]|"/g,"")
                                        // school_specialty[i].grade = grade
                                    }
                                }
                            }
                        }
                        for(let q=0;q<school_specialty.length;q++){
                            for(let w=0;w<grade_specialty.length;w++){
                                for(let x=0;x<school_specialty[q].grade_arr.length;x++){
                                    if(school_specialty[q].grade_arr[x] == grade_specialty[w].grade_level_text){
                                        grade_specialty[w].is = '1'
                                    }
                                }
                            }
                            school_specialty[q].grade_arr_list = JSON.parse(JSON.stringify(grade_specialty))
                            for(let j=0;j<grade_specialty.length;j++){
                                grade_specialty[j].is = '0'
                            }
                        }
                        // for(let i=0;i<school_specialty.length;i++){
                        //     school_specialty[i].index = i
                        //     school_specialty[i].is = '0'
                        //     school_specialty[i].grade = ''
                        //     school_specialty[i].grade_arr = ''
                        //     school_specialty[i].grade_arr_list = ''
                        //     for(let j=0;j<grade_specialty.length;j++){
                        //         grade_specialty[j].is = '0'
                        //         grade_specialty[j].index = j
                        //     }
                        // }

                        this.undergraduates = school_undergraduate
                        this.un_grade_lists = grade_undergraduate
                        this.colleges = school_specialty
                        this.colleges_grade_lists = grade_specialty

                        this.budget_money()
                    })

                    this.time_one_Value = res.adspace.push_starttime
                    this.time_two_Value = res.adspace.push_endtime
                    this.senior_push_undergraduate_exposure_price = res.cost_adspace.undergraduate_exposure_price
                    this.senior_push_specialty_exposure_price = res.cost_adspace.specialty_exposure_price
                    this.senior_push_undergraduate_click_price = res.cost_adspace.undergraduate_click_price
                    this.senior_push_specialty_click_price = res.cost_adspace.specialty_click_price
                    this.pop_three_lefts = pop_three_lefts

                    this.city_id_chun = res.adspace.city_id
                    this.price_type = res.adspace.price_type
                    this.push_price_id = res.adspace.push_price_id
              
                })
            }
            let adspace_type = sessionStorage.getItem('adspace_type');
            let city_id_chun = sessionStorage.getItem('city_id_chun');
            let advanced_list_xg = JSON.parse(sessionStorage.getItem('advanced_list_xg'));
            console.log(advanced_list_xg)
            this.adspace_type = adspace_type
            this.city_id_chun = city_id_chun
            this.advanced_list_xg = advanced_list_xg
            console.log(adspace_type,'广告位类型')
            // 所有学校和年级列表
            if(promotion_value !== '1'){
                school_grade_list({city_id:sessionStorage.getItem('city_id_chun'),is_show_cost_common:'1'}).then((res)=>{
                    console.log(res,'所有学校和年级列表')
                    let school_undergraduate = res.school_undergraduate
                    let grade_undergraduate = res.grade_undergraduate
                    let is_val = this.is_val
                    for(let i=0;i<school_undergraduate.length;i++){
                        school_undergraduate[i].index = i
                        school_undergraduate[i].is = '0'
                        school_undergraduate[i].grade = ''
                        school_undergraduate[i].grade_arr = ''
                        school_undergraduate[i].grade_arr_list = ''
                        if(is_val == '1'){
                            school_undergraduate[i].expact_exposure_number = ''
                        }
                        for(let j=0;j<grade_undergraduate.length;j++){
                            grade_undergraduate[j].is = '0'
                            grade_undergraduate[j].index = j
                        }
                    }
                    let school_specialty = res.school_specialty
                    let grade_specialty = res.grade_specialty
                    for(let i=0;i<school_specialty.length;i++){
                        school_specialty[i].index = i
                        school_specialty[i].is = '0'
                        school_specialty[i].grade = ''
                        school_specialty[i].grade_arr = ''
                        school_specialty[i].grade_arr_list = ''
                        if(is_val == '1'){
                            school_specialty[i].expact_exposure_number = ''
                        }
                        for(let j=0;j<grade_specialty.length;j++){
                            grade_specialty[j].is = '0'
                            grade_specialty[j].index = j
                        }
                    }


                    this.undergraduates = school_undergraduate
                    this.un_grade_lists = grade_undergraduate
                    this.colleges = school_specialty
                    this.colleges_grade_lists = grade_specialty
                })
            }
        },
        components:{
            kefu,
            recharge,
            push_charges,
        },
        methods: {
            // 充值
            showDialog(visible) {
              this.dialog_visible = visible;
            },
            // 选中预算价格
            budget_money(){
                let undergraduates = this.undergraduates
                let colleges = this.colleges
                console.log(undergraduates,'undergraduatesundergraduates')
                let Total_money = ''
                let exposure_people_max = ''
                let cilck_people_max = ''
                let senior_push_undergraduate_exposure_price = this.senior_push_undergraduate_exposure_price  // 本科 每天每人曝光
                let senior_push_undergraduate_click_price = this.senior_push_undergraduate_click_price        // 本科 每天点击
                let senior_push_specialty_exposure_price = this.senior_push_specialty_exposure_price          // 大专 每天每人曝光
                let senior_push_specialty_click_price = this.senior_push_specialty_click_price                // 大专 每天点击
                for(let i=0;i<undergraduates.length;i++){
                    if(undergraduates[i].is == '1'){
                        exposure_people_max = exposure_people_max*1 + undergraduates[i].expact_exposure_number*1
                        cilck_people_max = cilck_people_max*1 +undergraduates[i].expact_click_number
                        Total_money = Total_money*1 + ((senior_push_undergraduate_exposure_price*undergraduates[i].expact_exposure_number*1)+(senior_push_undergraduate_click_price*undergraduates[i].expact_click_number*1)).toFixed(2)*1
                    }
                }
                for(let i=0;i<colleges.length;i++){
                    if(colleges[i].is == '1'){
                        exposure_people_max = exposure_people_max*1 + colleges[i].expact_exposure_number*1
                        cilck_people_max = cilck_people_max*1 +colleges[i].expact_click_number
                        Total_money = Total_money*1 + ((senior_push_specialty_exposure_price*colleges[i].expact_exposure_number*1)+(senior_push_specialty_click_price*colleges[i].expact_click_number*1)).toFixed(2)*1
                    }
                }
                let principal_amount = this.principal_amount
                this.Total_money = (Total_money*1).toFixed(2)
                this.exposure_people_max = exposure_people_max
                this.cilck_people_max = cilck_people_max
                if(principal_amount*1 < Total_money*1){
                    this.price_comparison = '1'
                }else{
                    this.price_comparison = '0'
                }
            },  
            // 横幅输入曝光人次
            henfu_click(){
                this.budget_money()
            },
            // 余额不足弹出 返回
            balance_not_back(){
                this.comm_shadows_value = '0'
                this.balance_not_is = '0'
            },  
            // 本科 年级 勾选
            under_select(e){
                console.log(e)
                let that = this
                let undergraduates = this.undergraduates
                for(let i=0;i<undergraduates.length;i++){
                    if(undergraduates[i].index == e){
                        if(undergraduates[i].is == '0'){
                            that.un_grade_pop(e)
                        }else{
                            undergraduates[i].is = '0'
                        }
                    }
                }
                this.undergraduates = undergraduates
                this.budget_money()
            },
            // 大专 年级 勾选
            coll_select(e){
                console.log(e)
                let that = this
                let colleges = this.colleges
                for(let i=0;i<colleges.length;i++){
                    if(colleges[i].index == e){
                        if(colleges[i].is == '0'){
                            that.coll_grade_pop(e)
                        }else{
                            colleges[i].is = '0'
                        }
                    }
                }
                this.colleges = colleges
                this.budget_money()
            },
            // 本科 弹窗 年级选择
            un_grade_select(e){
                let un_grade_lists = this.un_grade_lists
                for(let i=0;i<un_grade_lists.length;i++){
                    if(un_grade_lists[i].index == e){
                        if(un_grade_lists[i].is == '0'){
                            un_grade_lists[i].is = '1'
                        }else{
                            un_grade_lists[i].is = '0'
                        }
                    }
                }
                this.un_grade_lists = un_grade_lists
            },
            // 专科 弹窗 年级选择
            colleges_grade_select(e){
                let colleges_grade_lists = this.colleges_grade_lists
                for(let i=0;i<colleges_grade_lists.length;i++){
                    if(colleges_grade_lists[i].index == e){
                        if(colleges_grade_lists[i].is == '0'){
                            colleges_grade_lists[i].is = '1'
                        }else{
                            colleges_grade_lists[i].is = '0'
                        }
                    }
                }
                this.colleges_grade_lists = colleges_grade_lists
            },
            // 本科 弹窗 年级选择 显示
            un_grade_pop(index){
                this.un_grade_lists_index = index
                let un_grade_lists = this.un_grade_lists
                
                let undergraduates = this.undergraduates
                console.log(undergraduates)
                for(let i=0;i<undergraduates.length;i++){
                    if(undergraduates[i].index == index){
                        if(undergraduates[i].grade_arr == ''){
                            for(let j=0;j<un_grade_lists.length;j++){
                                un_grade_lists[j].is = '0'
                            }
                            this.un_grade_lists = un_grade_lists
                        }else{
                            this.un_grade_lists = undergraduates[i].grade_arr_list
                        }
                    }
                }
                this.undergraduates = undergraduates
                this.shade_value = '1'
                this.is_un_grade_diaplay = '1'
            },
            // 本科 弹窗 年级选择 返回
            un_no_select(){
                this.shade_value = '0'
                this.is_un_grade_diaplay = '0'
            },
            // 本科 弹窗 年级选择 确定
            un_yes_select(){
                let un_grade_lists_index = this.un_grade_lists_index  // 当前选中的index
                let un_grade_lists = this.un_grade_lists  // 年级
                let undergraduates = this.undergraduates  // 学校
                let grade = []
                let grade_arr = []
                let un_grade_lists_news = JSON.parse(JSON.stringify(this.un_grade_lists ))
                for(let i=0;i<undergraduates.length;i++){
                    if(undergraduates[i].index == un_grade_lists_index){
                        for(let j=0;j<un_grade_lists.length;j++){
                            if(un_grade_lists[j].is == '1'){
                                grade.push(un_grade_lists[j].title)
                                grade_arr.push(un_grade_lists[j].grade_level_text)
                                un_grade_lists_news[j].is = '1'
                            }
                        }
                        if(grade == ''){
                            this.$message({
                                message: '请至少选择一个年级！',
                                duration:'2000'
                            })
                            return
                        }
                        let bachelor_dete_list_one = JSON.stringify(grade)
                        undergraduates[i].is = '1'
                        undergraduates[i].grade = bachelor_dete_list_one.replace(/\[|]|"/g,"")
                        // undergraduates[i].grade = grade
                        undergraduates[i].grade_arr = grade_arr
                        undergraduates[i].grade_arr_list = un_grade_lists_news
                    }
                }

                this.undergraduates = undergraduates
                console.log(this.undergraduates)
                this.shade_value = '0'
                this.is_un_grade_diaplay = '0'

                this.budget_money()
            },
            // 大专 弹窗 年级选择 显示
            coll_grade_pop(index){
                this.un_coll_lists_index = index
                let colleges_grade_lists = this.colleges_grade_lists

                let colleges = this.colleges
                for(let i=0;i<colleges.length;i++){
                    if(colleges[i].index == index){
                        if(colleges[i].grade_arr == ''){
                            for(let j=0;j<colleges_grade_lists.length;j++){
                                colleges_grade_lists[j].is = '0'
                            }
                            this.colleges_grade_lists = colleges_grade_lists
                        }else{
                            this.colleges_grade_lists = colleges[i].grade_arr_list
                        }
                    }
                }
                this.colleges = colleges

                this.shade_value = '1'
                this.is_coll_grade_diaplay = '1'
            },
            // 大专 弹窗 年级选择 返回
            coll_no_select(){
                this.shade_value = '0'
                this.is_coll_grade_diaplay = '0'
            },
            // 大专 弹窗 年级选择 确定
            coll_yes_select(){
                let un_coll_lists_index = this.un_coll_lists_index  // 当前选中的index
                let colleges_grade_lists = this.colleges_grade_lists  // 年级
                let colleges = this.colleges  // 学校
                let grade = []
                let grade_arr = []
                let colleges_grade_lists_news = JSON.parse(JSON.stringify(this.colleges_grade_lists ))
                for(let i=0;i<colleges.length;i++){
                    if(colleges[i].index == un_coll_lists_index){
                        for(let j=0;j<colleges_grade_lists.length;j++){
                            if(colleges_grade_lists[j].is == '1'){
                                grade.push(colleges_grade_lists[j].title)
                                grade_arr.push(colleges_grade_lists[j].grade_level_text)
                                colleges_grade_lists_news[j].is = '1'
                                console.log(colleges_grade_lists_news[j].is)
                            }
                        }
                        if(grade == ''){
                            this.$message({
                                message: '请至少选择一个年级！',
                                duration:'2000'
                            })
                            return
                        }
                        let bachelor_dete_list_one = JSON.stringify(grade)
                        colleges[i].is = '1'
                        // colleges[i].grade = grade
                        colleges[i].grade = bachelor_dete_list_one.replace(/\[|]|"/g,"")
                        colleges[i].grade_arr = grade_arr
                        colleges[i].grade_arr_list = colleges_grade_lists_news
                    }
                }

                this.colleges = colleges

                this.shade_value = '0'
                this.is_coll_grade_diaplay = '0'

                this.budget_money()
            },
            // 推送内容类别 右侧选择
            pop_three_right_click(id){
                console.log(id)
                this.push_price_id = id
                let pop_three_rights_list = this.pop_three_rights
                for(let i=0;i<pop_three_rights_list.length;i++){
                    pop_three_rights_list[i].is = '0'
                    if(pop_three_rights_list[i].id == id){
                        pop_three_rights_list[i].is = '1'
                        this.price_type = pop_three_rights_list[i].price_type
                    }
                }
                this.pop_three_rights = pop_three_rights_list
            },
            // 推送内容类别 左侧选择
            pop_three_left_click(id){
                console.log(id)
                let pop_three_lefts_list = this.pop_three_lefts
                for(let i=0;i<pop_three_lefts_list.length;i++){
                  pop_three_lefts_list[i].is = '0'
                  if(pop_three_lefts_list[i].id == id){
                    pop_three_lefts_list[i].is = '1'
                    // this.price_type = pop_three_lefts_list[i].value
                    //  商务端所有价格的信息
                    all_prices({price_type:pop_three_lefts_list[i].value}).then((res)=>{
                      if(res.error_code == '0'){
                        console.log(res,'商务端所有价格的信息')
                        let pop_three_rights = res.push_price
                        for(let i=0;i<pop_three_rights.length;i++){
                            pop_three_rights[i].is = '0'
                        }
                        this.pop_three_rights = pop_three_rights
                        // this.pop_three_value = '0'
                        this.push_price_id = ''
                      }
                    })
                  }
                }
                
                this.pop_three_lefts = pop_three_lefts_list
            },
            // 推送内容类别 关闭
            pop_three_none(e){
              this.shade_value = '0'
              this.pop_three = '0'
            },
            // 推送内容类别 显示
            push_content_category(e){
                this.shade_value = '1'
                this.pop_three = '1'
            },
            //  推送内容类别 确定
            pop_three_determine(){
                let push_price_id = this.push_price_id
                if(push_price_id == ''){
                    this.$message({
                        message: '请选择二级列表',
                        duration:'2000'
                    })
                }else{
                    let pop_three_rights = this.pop_three_rights
                    let pop_three_lefts = this.pop_three_lefts
                    for(let i=0;i<pop_three_lefts.length;i++){
                        if(pop_three_lefts[i].is == '1'){
                            this.pop_three_lefts_value = pop_three_lefts[i].text
                        }
                    }
                    for(let i=0;i<pop_three_rights.length;i++){
                        if(pop_three_rights[i].is == '1'){
                            this.pop_three_right_value = pop_three_rights[i].title
                        }
                        if(pop_three_rights[i].id == push_price_id){
                            this.senior_push_undergraduate_exposure_price = pop_three_rights[i].senior_push_undergraduate_exposure_price*1
                            this.senior_push_specialty_exposure_price = pop_three_rights[i].senior_push_specialty_exposure_price*1
                            this.senior_push_undergraduate_click_price = pop_three_rights[i].senior_push_undergraduate_click_price*1
                            this.senior_push_specialty_click_price = pop_three_rights[i].senior_push_specialty_click_price*1
                        }
                    }
                    this.shade_value = '0'
                    this.pop_three = '0'
                    this.budget_money()
                }
            },
            // 第一个时间选择 弹窗
            pop_time_one_block(e){
                this.comm_shadows_value = '1'
                this.pop_time_one_value = '1'
            },
            pop_time_one_dete(val){
                let year = val.getFullYear()
                let month = val.getMonth() + 1
                let day = val.getDate()
                let hour = val.getHours()
                let minute = val.getMinutes()
                if (month >= 1 && month <= 9) { month = `0${month}` }
                if (day >= 1 && day <= 9) { day = `0${day}` }
                if (hour >= 0 && hour <= 9) { hour = `0${hour}` }
                if (minute >= 0 && minute <= 9) { minute = `0${minute}` }
                this.time_one_Value = `${year}-${month}-${day} ${hour}:${minute}`

                this.comm_shadows_value = '0'
                this.pop_time_one_value = '0'
                console.log(this.time_one_Value)
            },
            pop_time_one_cancel(e){
                this.comm_shadows_value = '0'
                this.pop_time_one_value = '0'
            },
            // 第二个时间选择 弹窗
            pop_time_two_block(e){
                this.comm_shadows_value = '1'
                this.pop_time_two_value = '1'
            },
            pop_time_two_dete(val){
                let year = val.getFullYear()
                let month = val.getMonth() + 1
                let day = val.getDate()
                let hour = val.getHours()
                let minute = val.getMinutes()
                if (month >= 1 && month <= 9) { month = `0${month}` }
                if (day >= 1 && day <= 9) { day = `0${day}` }
                if (hour >= 0 && hour <= 9) { hour = `0${hour}` }
                if (minute >= 0 && minute <= 9) { minute = `0${minute}` }
                this.time_two_Value = `${year}-${month}-${day} ${hour}:${minute}`

                this.comm_shadows_value = '0'
                this.pop_time_two_value = '0'
                console.log(this.time_two_Value)
            },
            pop_time_two_cancel(e){
                this.comm_shadows_value = '0'
                this.pop_time_two_value = '0'
            },
            // 上一步
            previous_step(){
                this.$router.push("/advanced_push")
            },
            // 发布 跳转
            // pop_up(){
            //     let adspace_id = this.adspace_id // 广告位id，可以为0
            //     let adspace_type = this.adspace_type  // 广告位类型
            //     let price_type = this.price_type  // 推送价格类型
            //     let push_price_id = this.push_price_id  // 推送价格id
            //     let push_starttime = this.time_one_Value  // 推送开始时间
            //     let push_endtime = this.time_two_Value // 推送结束时间

            //     let school_json = []  // 传 学校信息
            //     let undergraduates = this.undergraduates // 本科 学校列表
            //     let colleges = this.colleges  // 大专 学校列表

                
            //     for(let i=0;i<undergraduates.length;i++){
            //         if(undergraduates[i].is == '1'){
            //             let grade_arr = undergraduates[i].grade_arr.toString()
            //             let arr_list = {school_id:undergraduates[i].school_id,grade_ids:grade_arr,exposure_number:'1'}  // exposure_number 曝光次数暂时为1
            //             school_json.push(arr_list)
            //         }
            //     }
            //     for(let i=0;i<colleges.length;i++){
            //         if(colleges[i].is == '1'){
            //             let grade_arr = colleges[i].grade_arr.toString()
            //             let arr_list = {school_id:colleges[i].school_id,grade_ids:grade_arr,exposure_number:'1'}   // exposure_number 曝光次数暂时为1
            //             school_json.push(arr_list)
            //         }
            //     }

            //     let city_id = this.city_id_chun  // 城市id

            //     let advanced_list = {adspace_id:adspace_id,adspace_type:adspace_type,price_type:price_type,push_price_id:push_price_id,push_starttime:push_starttime,push_endtime:push_endtime,school_json:school_json,city_id:city_id}
                
            //     let dd = JSON.stringify(advanced_list)
            //     sessionStorage.setItem("advanced_list",dd)
                
            //     let promotion_value = this.promotion_value
            //     if(promotion_value == '1'){
            //         this.$router.push({
            //             path:'/advanced_push_three',
            //             query: {
            //                 adspace_id:adspace_id,
            //                 promotion_value:promotion_value,
            //             }
            //         })
            //     }else{
            //         this.$router.push("/advanced_push_three")
            //     }
            // },
            pop_up(){
                let token = this.token
                let Total_money = this.Total_money  // 总价
                let principal_amount = this.principal_amount  // 本金金额
                console.log(principal_amount)
                if(principal_amount*1 < Total_money*1){
                    this.comm_shadows_value = '1'
                    this.balance_not_is = '1'
                    return
                }

                let advanced_list_xg = this.advanced_list_xg
                let adspace_id = advanced_list_xg.adspace_id // 广告位id，可以为0
                let adspace_type = advanced_list_xg.adspace_type  // 广告位类型
                let img = advanced_list_xg.img  // banner 图片
                let content = JSON.stringify(advanced_list_xg.content)  // 内容
                let price_type = this.price_type  // 推送价格类型
                let push_price_id = this.push_price_id  // 推送价格id
                let push_starttime = this.time_one_Value  // 推送开始时间
                let push_endtime = this.time_two_Value // 推送结束时间
                let school_json = []  // 传 学校信息
                let undergraduates = this.undergraduates // 本科 学校列表
                if(content == '""'){
                    content = ''
                }
                let colleges = this.colleges  // 大专 学校列表
                let is_val = this.is_val
                for(let i=0;i<undergraduates.length;i++){ 
                    if(is_val == '1'){
                        if(undergraduates[i].is == '1'){
                            let grade_arr = undergraduates[i].grade_arr.toString()
                            let arr_list = {school_id:undergraduates[i].school_id,grade_level_texts:undergraduates[i].grade_level_text,grade_level_texts:grade_arr,exposure_number:undergraduates[i].expact_exposure_number,school_type_text:'undergraduate'}  // exposure_number 曝光次数暂时为1
                            school_json.push(arr_list)
                        }
                    }else{
                        if(undergraduates[i].is == '1'){
                            let grade_arr = undergraduates[i].grade_arr.toString()
                            let arr_list = {school_id:undergraduates[i].school_id,grade_level_texts:undergraduates[i].grade_level_text,grade_level_texts:grade_arr,exposure_number:'',school_type_text:'undergraduate'}  // exposure_number 曝光次数暂时为1
                            school_json.push(arr_list)
                        }
                    }
                    
                }
                for(let i=0;i<colleges.length;i++){
                    if(is_val == '1'){
                        if(colleges[i].is == '1'){
                            let grade_arr = colleges[i].grade_arr.toString()
                            let arr_list = {school_id:colleges[i].school_id,grade_level_texts:colleges[i].grade_level_text,grade_level_texts:grade_arr,exposure_number:colleges[i].expact_exposure_number,school_type_text:'specialty'}   // exposure_number 曝光次数暂时为1
                            school_json.push(arr_list)
                        }
                    }else{
                        if(colleges[i].is == '1'){
                            let grade_arr = colleges[i].grade_arr.toString()
                            let arr_list = {school_id:colleges[i].school_id,grade_level_texts:colleges[i].grade_level_text,grade_level_texts:grade_arr,exposure_number:'',school_type_text:'specialty'}   // exposure_number 曝光次数暂时为1
                            school_json.push(arr_list)
                        }
                    }
                    
                }
                let city_id = this.city_id_chun // 城市id
                let redirect_type = advanced_list_xg.redirect_type  // 传 跳转方式
                let url = advanced_list_xg.url
                
                
                // console.log(adspace_type,img,content,price_type,push_price_id,push_starttime,push_endtime,school_json,redirect_type,city_id)
                if(push_starttime == '请选择时间'){
                    this.$message({
                        message: '请选择开始时间！',
                        duration:'2000'
                    })
                    return
                }
                if(push_endtime == '请选择时间'){
                    this.$message({
                        message: '请选择结束时间！',
                        duration:'2000'
                    })
                    return
                }
                if(price_type == ''){
                    this.$message({
                        message: '请选择推送内容类别！',
                        duration:'2000'
                    })
                    return
                }
                if(school_json == ''){
                    this.$message({
                        message: '请选择至少选择一所学校',
                        duration:'2000'
                    })
                    return
                }
                let promotion_value = this.promotion_value
                if( promotion_value == '0'){
                    edit_adspace({token:token,adspace_id,adspace_type,img,redirect_type,url,content,price_type,push_price_id,push_starttime,push_endtime,school_json:JSON.stringify(school_json),city_id}).then((res)=>{
                        if(res.error_code == '0'){
                            this.shade_value = '1'
                            this.submit_review_is = '1'
                            this.back_award_id = res.adspace_id
                            return

                            var that = this;
                            setTimeout(function () {
                                that.$router.push({path:'/'}) 
                            },1000);
                            console.log(res,'提交')
                        }else{
                            console.log('111')
                            this.$message({
                                message: res.msg,
                                duration:'2000'
                            })
                        }
                    })
                }else if(promotion_value == '1'){
                    // let business_gallery = this.fileList
                    // let business_gallery2 = this.fileList_list
                    // let img_list = ''
                    // var reg1 = new RegExp("https://xcx.mjxyq.com")
                    // console.log(business_gallery,business_gallery2,img_list)
                    // for(let i=0;i<business_gallery2.length;i++){
                    //     img_list = img_list +','+ business_gallery2[i].url.replace(reg1,"");
                    // }
                    // business_gallery2 = img_list.substr(1,img_list.length)
                    // console.log(business_gallery,business_gallery2)
                    let bj_news = this.bj_news
                    if(bj_news == '1'){
                        edit_adspace({token:token,adspace_id:'0',adspace_type,img,redirect_type,url,content,price_type,push_price_id,push_starttime,push_endtime,school_json:JSON.stringify(school_json),city_id}).then((res)=>{
                            if(res.error_code == '0'){
                                this.shade_value = '1'
                                this.submit_review_is = '1'
                                this.back_award_id = res.adspace_id
                                return
                            }else{
                                this.$message({
                                    message: res.msg,
                                    duration:'2000'
                                })
                            }
                        })
                    }else{
                        edit_adspace({token:token,adspace_id,adspace_type,img,redirect_type,url,content,price_type,push_price_id,push_starttime,push_endtime,school_json:JSON.stringify(school_json),city_id}).then((res)=>{
                            if(res.error_code == '0'){
                                this.shade_value = '1'
                                this.submit_review_is = '1'
                                this.back_award_id = res.adspace_id
                                return
                            }else{
                                this.$message({
                                    message: res.msg,
                                    duration:'2000'
                                })
                            }
                        })
                    }
                }
            },
            // 提交审核 返回
            submit_review_back(){
                this.comm_shadows_value = '0'
                this.submit_review_is = '0'
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/'}) 
                },1000);
            },
            // 提交审核 确定
            submit_review_recharge(){
                let back_award_id = this.back_award_id
                let token = this.token
                submit_check_adspace({token,adspace_id:back_award_id}).then((res) =>{
                    console.log(res,'提交审核')
                    if(res.error_code == '0'){
                        this.$message({
                            message:res.msg,
                            duration:'2000'
                        })
                        var that = this;
                        setTimeout(function () {
                            that.$router.push({path:'/'}) 
                        },1000);
                    }else{
                        this.$message({
                            message:res.msg,
                            duration:'2000'
                        })
                    }
                })
            },
        },
    }
</script>