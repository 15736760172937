<style scoped>
.y_com_kefu_1{
    position: fixed;
    width: 1.5rem;
    height: 1.5rem;
    right: 0.1rem;
    bottom: 1.5rem;
    touch-action: none;
    z-index: 9;
}
.y_com_kefu_2{
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
}
.y_com_kefu_3{
    font-size: 0.24rem;
    color: #333;
    text-decoration: none;
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 0.35rem;
    left: 0rem;
    font-weight: 600;
}
.y_qr_code_1{
    width: 80%;
    max-height: 80vh;
    position: fixed;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
}
.y_qr_code_1 img{
    width: 100%;
    object-fit: cover;
}
.y_display_none{
    display: none;
}
</style>
<template>
    <div class='y_com_kefu_1' @click="callback" @mousedown="down" @touchstart="down" @mousemove="move" @touchmove="move" @mouseup="end" @touchend="end" ref="fu">
        <div class='y_com_kefu_2'>
            <img src="../../../images/icon_11.png" alt="">
        </div>
        <div class="y_com_kefu_3">客服</div>
    </div>
    <div class="y_qr_code_1" :class="comm_shadows_value == '1'?'y_comm_shadows_block':'y_display_none'" v-if="qr_code_img !==''?true:false" @click="pop_hide()">
        <img :src="qr_code_img" alt="">
    </div>
    <!-- 公共弹窗 阴影-->
    <div class='y_comm_shadows' :class="comm_shadows_value == '1'?'y_comm_shadows_block':''" @click="pop_hide()"></div>
</template>

<script>
    // import './style.css'
    import axios from 'axios'   // 接口
    import {configuration_fields} from '../../api/request.js'   // 商务端所有价格的信息
    export default {
        data() { 
            return {
                isLoading: false,
                flags: false, //控制使用
                position: {
                    x: 0,
                    y: 0,
                },
                nx: "",
                ny: "",
                dx: "",
                dy: "",
                xPum: "",
                yPum: "",

                comm_shadows_value:'0',
                qr_code_is:'0',
                qr_code_img:'',
            };
        },
        activated(){
            
        },
        methods: {
            // 二维码展示
            callback() {
                // this.$router.go(-1);
                configuration_fields({names:'business_kefu_qrcode'}).then((res)=>{
                    console.log(res,'二维码')
                    if(res.error_code == '0'){
                        this.qr_code_img = res.data.business_kefu_qrcode
                        this.comm_shadows_value = '1'
                    }
                })
            },
            // 二维码隐藏
            pop_hide(){
                this.comm_shadows_value = '0'
            },
            onRefresh() {
                setTimeout((res) => {
                    console.log(res);
                    this.isLoading = false;
                }, 1000);
            },
            down() {
                this.flags = true;
                var touch;
                if (event.touches) {
                    touch = event.touches[0];
                } else {
                    touch = event;
                }
                this.position.x = touch.clientX;
                this.position.y = touch.clientY;
                this.dx = this.$refs.fu.offsetLeft;
                this.dy = this.$refs.fu.offsetTop;
            },
            move() {
                if (this.flags) {
                    var touch;
                    if (event.touches) {
                        touch = event.touches[0];
                    } else {
                        touch = event;
                    }
                    this.nx = touch.clientX - this.position.x;
                    this.ny = touch.clientY - this.position.y;
                    this.xPum = this.dx + this.nx;
                    this.yPum = this.dy + this.ny;
                    let width = window.innerWidth - this.$refs.fu.offsetWidth; //屏幕宽度减去自身控件宽度
                    let height = window.innerHeight - this.$refs.fu.offsetHeight; //屏幕高度减去自身控件高度
                    this.xPum < 0 && (this.xPum = 0);
                    this.yPum < 0 && (this.yPum = 0);
                    this.xPum > width && (this.xPum = width);
                    this.yPum > height && (this.yPum = height);
                    // if (this.xPum >= 0 && this.yPum >= 0 && this.xPum<= width &&this.yPum<= height) {
                    this.$refs.fu.style.left = this.xPum + "px";
                    this.$refs.fu.style.top = this.yPum + "px";
                    // }
                    //阻止页面的滑动默认事件
                    document.addEventListener("touchmove",{ passive: true },function () {
                            event.preventDefault();
                        },
                        false
                    );
                }
            },
            //鼠标释放时候的函数
            end() {
                this.flags = false;
            },
            onClick() {
                //在这里我是作为子组件来使用的
                // this.$emit("click");
                
            },
        },
    }
</script>
