<template>
    <div class="y_index">
        <push_charges></push_charges>
        <div class="y_advanced_push_1">
            <div class="y_advanced_push_2">
                <p v-for="list_title in list_titles" :key="list_title.index" :class="list_title.is == '1'?'y_advanced_push_2_active':''" @click="title_click(list_title.index)">{{list_title.name}}</p>
            </div>
        </div>
        <div class="y_advanced_push_0">
            <div class="y_advanced_push_3">
                <!-- <div class="y_advanced_push_4" :class="is_val == '0'?'':'y_display_none'"></div>
                <div class="y_advanced_push_5" :class="is_val == '1'?'':'y_display_none'"></div>
                <div class="y_advanced_push_6" :class="is_val == '2'?'':'y_display_none'">开屏弹窗</div> -->
                <div class="y_advanced_push_7" :class="is_val == '3'?'':'y_display_none'">
                    <div class="y_message_private_7">
                        <a :href="news_list.url" class="y_message_private_8" v-for="news_list in news_lists" :key="news_list.id" >
                            <div class="y_message_private_9">
                                <img :src="src+news_list.img" alt="">
                            </div>
                            <div class="y_message_private_10">
                                <div class="y_message_private_11">
                                    <div class="y_message_private_12">
                                        <h2>{{news_list.title}}</h2>
                                    </div>
                                </div>
                            </div>
                        </a>

                    </div>
                </div>
            </div>
        </div>
        
        <div class="y_advanced_push_three_1" :class="is_val == '0'?'':'y_display_none'">
            <h2>上传轮播图封面图片</h2>
            <p>{{configuration_fields_list.business_banner_height}}</p>
        </div>
        <div class="y_advanced_push_three_1" :class="is_val == '1'?'':'y_display_none'">
            <h2>上传横幅封面图片</h2>
            <p>{{configuration_fields_list.business_horizontal_height}}</p>
        </div>
        <div class="y_advanced_push_three_1" :class="is_val == '2'?'':'y_display_none'">
            <h2>上传开屏广告封面图片</h2>
            <p>{{configuration_fields_list.business_pop_height}}</p>
        </div>
        <div class="y_advanced_push_three_2" :class="is_val == '3'?'y_display_none':''">
            <div class="y_advanced_push_three_xg_1" :style="'height:'+(is_val == '0'?configuration_fields_list.business_banner_height:(is_val == '1'?configuration_fields_list.business_horizontal_height:(is_val == '2'?configuration_fields_list.business_pop_height:false)))+'px'">
                <el-upload class="avatar-uploader" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :show-file-list="false" :on-success="banner_success" :before-upload="banner_settings">
                    <img src="../../../images/img_8.png" v-if="img == ''? true : false " alt="">
                    <img :src="''+img"  v-if="img == ''? false:true"  alt="">
                </el-upload>
            </div>
            
        </div>


        <div class="y_external_select_xg_1" :class="is_val == '3'?'y_display_none':''">
            <div class="y_external_select_xg_2">
                <h2 class="y_external_select_xg_3">跳转方式</h2>
                <div class="y_external_select_xg_4">
                    <el-select v-model="external_value" @change='external_select' placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
        </div>

        <div class="y_advanced_push_three_5" :class="external_value == 'detail'?'':'y_display_none'">
            <div class="y_advanced_push_three_1" :class="is_val == '3'?'y_display_none':''">
                <h2>推送内容编辑</h2>
            </div>

            <div class="y_sweep_settings_3 y_sweep_settings_16 y_sweep_settings_18" :class="is_val == '3'?'y_display_none':''">
                <div class="y_sweep_settings_4" @click="add_awards" v-if="uplist_list_chun?.length ?? true">
                    <div class="y_sweep_settings_19">
                        <img src="../../../images/icon_31.png" alt="">
                        <p>可添加图文介绍 / 一键复制框</p>
                    </div>
                </div>
                <div class="y_sweep_settings_4 y_sweep_settings_xg_0" v-if="uplist_list_chun?.length ?? true" @click="add_awards">
                    <div class="y_sweep_settings_xg_1" v-for="(uplist,index) in uplist_list_chun" :key="index">

                        <div class="y_sweep_settings_xg_2" v-if="uplist.type == 'text'">
                            <p v-html="uplist.content"></p>
                        </div>

                        <div class="y_sweep_settings_xg_2 listshowpersonshow" v-if="uplist.type == 'award_contract_id'">
                            <!-- <p>{{uplist.phone_text}}</p> -->
                            <p>联系人</p>
                            <p>加好友</p>
                            <img src="../../../images/icon_perright.png" alt="">
                        </div>

                        <div class="y_sweep_settings_xg_2 listshowpersonshow y_sweep_settings_xg_2_xg_0" v-if="uplist.type == 'text_copy'">
                            <div class="y_sweep_settings_xg_2_xg_1">
                                <div class="y_sweep_settings_xg_2_xg_2">
                                    <h2>{{uplist.content}}</h2>
                                </div>
                                <div class="y_sweep_settings_xg_2_xg_3">
                                    <p>复制：{{uplist.content_copy}}</p>
                                </div>
                            </div>
                            <div class="y_sweep_settings_xg_2_xg_4">
                                <p>复制</p>
                            </div>
                        </div>

                        <div class="y_sweep_settings_xg_2" v-if="uplist.type == 'img'">
                            <img :src="''+uplist.content" alt="">
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <div class="y_external_select_xg_5" :class="external_value == 'url'?'':'y_display_none'">
            <div class="y_advanced_push_three_5">
                <div class="y_advanced_push_three_1">
                    <h2>跳转链接</h2>
                </div>
                <div class="y_external_select_xg_6">
                    <el-input v-model="url" placeholder="请输入链接"></el-input>
                </div>
            </div>
        </div>


 
        <div class="y_topics_dian"></div>
        <div class='y_recruit_foot_1'>
            <div class='y_recruit_foot_2'>
                <div class='y_recruit_foot_3' @click="back_to_top">
                    <p>返回</p>
                </div>
            </div>
            <div class='y_recruit_foot_7' @click="advabced_page" :class="adspace_type == ''?'y_display_none':''">
                <p>下一步(1/2)</p>
            </div>
        </div>
    </div>
    <kefu></kefu>
    <recharge v-show="dialog_visible" :dialog_visible="dialog_visible" @dialogVisibleEvent="showDialog"></recharge>
</template>
<style>
    .y_index{
        padding-bottom: 0rem;
    }
    .y_topics_dian{
        background: transparent;
    }
    .y_advanced_push_1{
        width: 100%;
        background: #fff;
        height: 1.2rem;
        border-bottom:0.01rem solid #F4F5F7;
    }
    .y_advanced_push_2{
        width: calc(100% - 0.5rem); 
        display: flex;
        justify-content: space-between; 
        margin: 0 auto;
        height: 100%;
        align-items: center;
    }
    .y_advanced_push_2 p{
        background: #F6F6F6;
        font-size: 0.3rem;
        color: #333;
        text-align: center;
        height: 0.7rem;
        border-radius: 0.1rem;
        width: 1.6rem;
        line-height: 0.7rem;
        transition: ease 0.5s all;
    }
    .y_advanced_push_2 .y_advanced_push_2_active{
        transition: ease 0.5s all;
        background: #FECE0A; 
    }
    .y_advanced_push_0{
        background:#fff;
    }
    .y_advanced_push_3{
        width: calc(100% - 0.9rem);
        margin: 0 auto;
        /* padding: 0.4rem 0rem 0.6rem; */
        display: block;
    }
    .y_advanced_push_3 div{
        font-size: 0.3rem;
        color: #333;
    }
    .y_advanced_push_4{
        width: 100%;
        height: 10rem;
        background: #FECE0A;
    }
    .y_advanced_push_5{
        width: 100%;
        height: 1.8rem;
        background: #FECE0A;
    }
    .y_message_private_7{
        width: 100%;
    }
    .y_message_private_8{
        padding: 0rem 0.25rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1.6rem;
    }
    .y_message_private_9{
        width: 0.9rem;
        height: 0.9rem;
        position: relative;
    }
    .y_message_private_9 img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1rem;
    }
    .y_message_private_10{
        margin-left: 0.15rem;
        height: 100%;
        border-bottom: 0.01rem solid #EEEEEE;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        flex: 1;
    }
    .y_message_private_11{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_message_private_12{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_message_private_12 h2{
        font-size: 0.26rem;
        color: #333;
    }
    .y_message_private_12 p{
        font-size: 0.2rem;
        width: 1.3rem;
        height: 0.34rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #333;
        background: #FECE0A;
        margin-left: 0.1rem;
        line-height: 0.34rem;
        padding: 0rem 0.1rem;
        border-radius: 0.05rem;
    }
    .y_message_private_13 p{
        font-size: 0.22rem;
        color: #888;
    }
    .y_message_private_14{
        width: 100%;
        margin-top: 0.15rem;
    }
    .y_message_private_14 p{
        font-size: 0.24rem;
        color: #999;
        line-height: 0.3rem;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }


    .y_message_private_15{
        top: 0%;
        left: 50%;
        transform: translate(50%,0%);
    }
    .y_display_none{
        display: none;
    }


    /* 新增 */
    .y_advanced_push_three_1{
        width: 100%;
        height: 1.05rem;
        background: #fff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_advanced_push_three_1 h2{
        font-size: 0.28rem;
        color: #333;
        padding-left: 0.3rem;
        font-weight: 600;
    }
    .y_advanced_push_three_1 p{
        color: #999;
        font-size: 0.28rem;
    }
    .y_advanced_push_three_2{
        width: 100%;
        background: #f6f6f6;
        /* height: 2rem; */
    }
    .y_advanced_push_three_3{
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
    }
    .y_advanced_push_three_3 p{
        width: 100%;
        text-align: center;
        font-size: 0.28rem;
        color: #999;
        margin-top: 0.15rem;
    }
    .y_advanced_push_three_4{
        width: 100%;
        height: 0.76rem;
        text-align: center;
    }
    .y_advanced_push_three_4 img{
        height: 100%;
        object-fit: cover;
    }
    .y_advanced_push_three_5{
        width: 100%;
        background: #fff;
        padding-bottom: 0.4rem;
    }
    .y_advanced_push_three_6{
        margin: 0rem 0.35rem;
    }
    .y_advanced_push_three_6 /deep/ textarea{
        padding: 0.3rem 0.2rem;
        background: #F7F8FA;
        border-radius: 0.1rem;
        height: 2.6rem;
        font-size: 0.28rem;
        color: #333;
        border: none;
    }
    .y_advanced_push_three_6 /deep/ textarea::placeholder{
        color: #ccc;
        font-size: 0.28rem;
    }
    .y_advanced_push_three_7{
        margin: 0.44rem 0.35rem 0rem;
    }



    .y_advanced_push_three_xg_1{
        width: 100%;
        /* min-height: 4rem; */
        display: flex;
        align-items: center;
        justify-content: center;
        /* overflow: hidden; */
    }
    .y_advanced_push_three_xg_1 img{
        width: 100%;
        /* height: 100%;
        object-fit: cover; */
    }
    .y_advanced_push_three_xg_1 .avatar-uploader{
        width: 100%;
        height: 100%;
    }
    .y_advanced_push_three_xg_1 .el-upload--text{
        width: 100%;
        height: 100%;
    }

    .y_external_select_xg_1{
        font-size: 0.3rem;
        height: 1rem;
        background: #fff;
        margin-bottom: 0.2rem;
    }
    .y_external_select_xg_2{
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin: 0rem 0.35rem;
        height: 1rem;
    }
    .y_external_select_xg_3{
        font-size: 0.3rem;
        line-height: 1rem;
        font-weight: 600;
    }
    .y_external_select_xg_4{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .y_external_select_xg_4  input{
        font-size: 0.3rem;
        border-radius: 0rem;
        border: none;
        text-align: right;
    }
    .y_external_select_xg_6  input{
        font-size: 0.3rem;
        margin: 0rem 0.35rem;
        height: 1rem;
        line-height: 1rem;
        padding-left: 0.1rem;
        border-radius: 0rem;
        border: none;
        color: #333;
        background: #f6f6f6;
        border-radius: 0.1rem;
    }
    

    .y_sweep_settings_3{
        margin: 0rem 0.25rem;
    }
    .y_sweep_settings_4{
        border-radius: 0.1rem;
        background: #f4f5f7;
        padding: 0.3rem 0.3rem 0rem 0.3rem;
        overflow: hidden;
    }
    .y_sweep_settings_5{
        width: 100%;
        height: 6.4rem;
        position: relative;
        margin-bottom: 0.1rem;
    }
    .y_sweep_settings_5>img{
        width: 100%;
        height: 6.4rem;
        object-fit: cover;
    }
    .y_sweep_settings_6{
        position: absolute;
        width: 1.8rem;
        height: 0.6rem;
        background: rgba(0,0,0,0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.1rem;
        top: 0.2rem;
        right: 0.2rem;
    }
    .y_sweep_settings_6 img{
        width: 0.3rem;
        height: 0.24rem;
    }
    .y_sweep_settings_6 p{
        font-size: 0.24rem;
        color: #fff;
        margin-left: 0.1rem;
        font-weight: 300;
    }
    .y_sweep_settings_7{
        width: 100%;
    }
    .y_sweep_settings_8{
        width: 100%;
        display: flex;
        justify-items: flex-start;
        align-items: center;
        height: 1rem;
        border-bottom: 0.01rem solid #EEEEEE;
    }
    .y_sweep_settings_8>h2{
        font-size: 0.28rem;
        color:#333;
        width: 1.5rem;
    }
    .y_sweep_settings_9{
        flex: 1;
        height: 100%;
        display: flex;
        justify-items: flex-start;
        align-items: center;
    }
    .y_sweep_settings_9>div{
        flex: 1;
    }
    .y_sweep_settings_9 /deep/ input{
        font-size: 0.28rem;
        color: #333;
        background: transparent;
        border: none;
        line-height: 1rem;
        height: 1rem;
        padding: 0rem;
    }
    .y_sweep_settings_9 /deep/ input::placeholder{
        color: #aaa;
        font-size: 0.28rem;
    }
    .y_sweep_settings_9>p{
        font-size: 0.28rem;
        color: #333;
        flex: 2;
    }
    .y_sweep_settings_10{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.2rem;
    }
    .y_sweep_settings_10 img{
        width: 0.3rem;
    }
    .y_sweep_settings_10 p{
        margin-left: 0.2rem;
        color: #FECE0A;
        font-size: 0.3rem;
    }
    .y_sweep_settings_11{
        width: 100%;
        height: 0.8rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_sweep_settings_11 p{
        flex: 1;
        margin-right: 0.1rem;
        height: 0.8rem;
        text-align: center;
        line-height: 0.8rem;
        background: #F6F6F6;
        transition: ease 0.5s all;
        border-radius: 0.1rem;
        font-size: 0.28rem;
        color:#333;
    }
    .y_sweep_settings_11 p:nth-last-child(1){
        margin-right: 0rem;
    }
    .y_sweep_settings_11 .y_sweep_settings_11_active{
        background: #FECE0A;
        transition: ease 0.5s all;
    }
    .y_sweep_settings_12{
        width: 100%;
        margin-top: 0.3rem;
        min-height: 1rem;
    }
    .y_sweep_settings_12 p{
        color: #999999;
        font-size: 0.28rem;
    }
    .y_sweep_settings_13{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1rem;
    }
    .y_sweep_settings_13>h2{
        font-size: 0.28rem;
        color:#333;
    }
    .y_sweep_settings_14{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .y_sweep_settings_14 p{
        font-size: 0.28rem;
        color: #aaa;
        margin-right: 0.15rem;
    }
    .y_sweep_settings_14 img{
        width: 0.14rem;
    }
    .y_sweep_settings_15{
        margin-bottom: 0.2rem;
    }
    .y_sweep_settings_16 .y_sweep_settings_4{
        padding-top:0.3rem;
        padding-bottom: 0.3rem;
    }
    .y_sweep_settings_16 .y_sweep_settings_13{
        border-bottom: 0.01rem solid #EEEEEE;
    }
    .y_sweep_settings_17{
        width: 100%;
        margin: 0.3rem 0rem 0.2rem;
    }
    .y_sweep_settings_17 /deep/ textarea{
        font-size: 0.28rem;
        color: #333;
        padding: 0rem;
        border: none;
        line-height: 0.28rem;
        border-radius: 0rem;
        min-height: 2rem !important;
    }
    .y_sweep_settings_17 /deep/ textarea::placeholder{
        color:#999;
    }
    .y_sweep_settings_18{
        /* margin-top: 0.2rem; */
    }
    .y_sweep_settings_19{
        width: 100%;
        height: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .y_sweep_settings_19 img{
        width: 0.3rem;
        height: 0.3rem;
    }
    .y_sweep_settings_19 p{
        margin-left: 0.1rem;
        color: #999;
        font-size: 0.28rem;
    }
    .y_sweep_settings_20{
        width: 100%;
        height: 1.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_sweep_settings_21{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_sweep_settings_21 p{
        font-size: 0.28rem;
        color: #333;
        margin-right: 0.1rem;
    }
    .y_sweep_settings_21 img{
        width: 0.2rem;
        height: 0.2rem;
    }
    .y_sweep_settings_22 /deep/ .el-switch__core{
        width: 0.7rem;
        height: 0.5rem;
    }
    .y_sweep_settings_22 /deep/ .el-switch .el-switch__action{
        width: 0.4rem !important;
        height: 0.4rem !important;
        transform: translate(0.16rem, -0.07rem);
    }
    .y_sweep_settings_22 /deep/ .is-checked .el-switch__action{
        width: 0.4rem !important;
        height: 0.4rem !important;
        transform: translate(0.43rem, -0.07rem);
    }
    .y_sweep_settings_23{
        border-top: 0.01rem solid #eee;
        justify-content: flex-start;
    }
    .y_sweep_settings_24{
        width: 1.2rem;
        flex: none;
        margin: 0rem 0.2rem;
    }
    .y_sweep_settings_xg_2{
        margin-bottom: 0.3rem;
    }
    .y_sweep_settings_xg_2 p{
        font-size: 0.3rem;
        color:#333;
    }
    .y_sweep_settings_xg_2 img{
        max-width: 100%;
        object-fit: cover;
    }

    .y_sweep_settings_xg_2_xg_1{
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
    }
    .y_sweep_settings_xg_2_xg_2{
        width: 100%;
    }
    .y_sweep_settings_xg_2_xg_2 h2{
        font-size: 0.32rem;
        color: #333;
        font-weight: 600;
    }
    .y_sweep_settings_xg_2_xg_3{
        width: 100%;
        margin-top: 0.1rem;
    }
    .y_sweep_settings_xg_2_xg_3 p{
        font-size: 0.24rem;
        color: #ccc;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
    .y_sweep_settings_xg_2_xg_4{
        margin-left: 0.2rem;
    }
    .y_sweep_settings_xg_2_xg_4 p{
        height: 0.5rem;
        line-height: 0.5rem;
        background: #FECE0A;
        color: #fff;
        font-size: 0.26rem;
        padding: 0rem 0.28rem;
        border-radius: 0.1rem;
    }
    .listshowpersonshow{
        height: 1rem;
        border-radius: 0.1rem;
        background: #FFFBED;
        padding: 0 0.3rem;
        display: flex;
        align-items: center;
    }
    .listshowpersonshow p:nth-child(2){
        margin-left: auto;
        margin-right: 0.11rem;
        color: #fece0a;
    }
    .y_sweep_settings_xg_2_xg_0{
        height: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

</style>
<script>
import kefu from '../../components/kefu/kefu'
import recharge from '../../components/recharge/recharge'
import push_charges from '../../components/push_charges/push_charges'

import axios from 'axios'   // 接口
import {configuration_fields} from '../../api/request.js'   // 配置字段
import {adspace_article_list} from '../../api/request.js'   // 广告位文章列表
import {adspace_info} from '../../api/request.js'   // 广告位详情，高级推广

    export default { 
        data(){
            return {
                src:'',
                list_titles:[
                    {index:'0',name:'轮播图',is:'1',value:'banner'},
                    {index:'1',name:'横幅',is:'0',value:'streamer'},
                    {index:'2',name:'开屏弹窗',is:'0',value:'open_screen'},
                    {index:'3',name:'其它方式',is:'0',value:''},
                ],
                is_val:'0',
                // adspace_type:'',  // 广告位类型
                news_lists:'',
                adspace_type:'banner',  // 广告位类型

                img:'',         // banner 单图
                external_value: '',  // 传 跳转方式
                options: [{
                    value: 'none',
                    label: '无跳转'
                }, {
                    value: 'detail',
                    label: '详情页跳转'
                }, {
                    value: 'url',
                    label: '外链跳转'
                }],
                url:'',  // 外链跳转连接
                rich_text:'0',
                advanced_push_value:'1',   // 判断是否是高级推送进入
                uplist_list:'',
                uplist_list_chun:'',

                adspace_id:'0',       // 广告位列表，高级推广 进入传id
                promotion_value:'0',  // 进入传value  0代表正常进入，1为待推送进入
                bj_news:'0', // 编辑新推送 0为正常进入 1是编辑新推送
                configuration_fields_list:'',  // 配置字段列表
            }
        },
        created(){
            // 获取页面传参 推送类型

            if(this.$route.query.adspace_id !== undefined){
                this.adspace_id = this.$route.query.adspace_id
                this.promotion_value = this.$route.query.promotion_value
                this.bj_news = this.$route.query.bj_news
            }
            
            // if(this.$route.query.rich_text !== undefined){

            //     let uplist_list = JSON.parse(sessionStorage.getItem('uplist_list'));
            //     this.uplist_list = uplist_list
            //     let uplist_list_chun = JSON.parse(sessionStorage.getItem('uplist_list_chun'));
            //     this.uplist_list_chun = uplist_list_chun
            // }
        },
        watch:{
          '$route'(val,from){
            let rich_text = val.query.rich_text
            this.rich_text = rich_text
            if(rich_text == '1'){
                this.rich_text = val.query.rich_text
                let uplist_list = JSON.parse(sessionStorage.getItem('uplist_list'));
                this.uplist_list = uplist_list
                let uplist_list_chun = JSON.parse(sessionStorage.getItem('uplist_list_chun'));
                this.uplist_list_chun = uplist_list_chun
            }
            if(rich_text == '2'){
                let uplist_list = JSON.parse(sessionStorage.getItem('uplist_list'));
                this.uplist_list = uplist_list
                let uplist_list_chun = JSON.parse(sessionStorage.getItem('uplist_list_chun'));
                this.uplist_list_chun = uplist_list_chun
            }
          }
        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            let adspace_id = this.adspace_id
            let promotion_value = this.promotion_value
            if(promotion_value == '1'){
                //  广告位详情，高级推广
                adspace_info({token:token,adspace_id:adspace_id}).then((res) =>{ 
                    console.log(res,'广告位详情，高级推广')
                    let list_titles = this.list_titles
                    this.img = res.adspace.img
                    this.external_value = res.adspace.redirect_type
                    if(res.adspace.content !== ''){
                        this.rich_text = '1'
                        let dd = JSON.stringify(res.adspace.content )
                        sessionStorage.setItem("uplist_list",dd)
                    }
                    this.advanced_push_value = '2'
                    this.uplist_list = res.adspace.content
                    this.uplist_list_chun = res.adspace.content
                    this.url = res.adspace.url
                    console.log(this.uplist_list,this.uplist_list_chun)
                    if(res.adspace.adspace_type == 'banner'){
                        this.is_val = '0'
                        this.adspace_type = 'banner'
                        for(let i=0;i<list_titles.length;i++){
                            list_titles[i].is = '0'
                            if(list_titles[i].value == 'banner'){
                                list_titles[i].is = '1'
                            }
                        }
                        this.list_titles = list_titles
                    }else if(res.adspace.adspace_type == 'streamer'){
                        this.is_val = '1'
                        this.adspace_type = 'streamer'
                        for(let i=0;i<list_titles.length;i++){
                            list_titles[i].is = '0'
                            if(list_titles[i].value == 'streamer'){
                                list_titles[i].is = '1'
                            }
                        }
                        this.list_titles = list_titles
                    }else if(res.adspace.adspace_type == 'open_screen'){
                        this.is_val = '2'
                        this.adspace_type = 'open_screen'
                        for(let i=0;i<list_titles.length;i++){
                            list_titles[i].is = '0'
                            if(list_titles[i].value == 'open_screen'){
                                list_titles[i].is = '1'
                            }
                        }
                        this.list_titles = list_titles
                    }
                })
            }
            // 广告位文章列表
            adspace_article_list({}).then((res) => {
                console.log(res,'广告位文章列表')
                this.news_lists = res.data
            })
            // 配置字段
            configuration_fields({}).then((res)=>{
                console.log(res,'配置字段')
                this.configuration_fields_list = res.data
            })
            
        },
        components:{
            kefu,
            recharge,
            push_charges,
        },
        methods: {
            // banner 图片上传成功
            banner_success(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
                console.log(res,file)
                let img = res.img
                this.img = img
            },
            // banner 图片上传设置
            banner_settings(file) {
                console.log(file)
                // const isJPG = file.type === 'image/jpeg';
                // const isLt2M = file.size / 1024 / 1024 < 2;
                // if (!isJPG) {
                //   this.$message.error('上传头像图片只能是 JPG 格式!');
                // }
                // if (!isLt2M) {
                //   this.$message.error('上传头像图片大小不能超过 2MB!');
                // }
                // return isJPG && isLt2M;
            },

            // 图片上传
            // 删除图片
            handleRemove(file, fileList) {
              console.log(file, fileList);
              let promotion_value = this.promotion_value
              if(promotion_value == '0'){
                let fileList_list = []
                for(let i=0;i<fileList.length;i++){
                  fileList_list.push(fileList[i].response.img)
                }
                this.fileList_list = fileList_list 
                console.log(this.fileList_list,'删除图片1')
              }else if(promotion_value == '1'){
                let fileList_list = []
                for(let i=0;i<fileList.length;i++){
                    fileList_list.push(fileList[i])
                }
                this.fileList_list = fileList_list 
                console.log(this.fileList_list,'删除图片2')
              }
            },
            // 上传成功
            hand_success(file,fileList){
              console.log(file,fileList)
              let promotion_value = this.promotion_value
              if(promotion_value == '0'){
                let fileList_list = this.fileList_list
                fileList_list.push(fileList.response.img)
                this.fileList_list = fileList_list
                console.log(this.fileList_list,'上传成功1')
              }else if(promotion_value == '1'){
                console.log(file,fileList)
                let fileList_list = this.fileList_list
                fileList_list.push({url:''+file.img})
                this.fileList_list = fileList_list
                console.log(this.fileList_list,'上传成功2')
              }
            },
            handlePreview(file,fileList) {
                console.log(file,fileList);
            },
            // 跳转方式
            external_select(){
                let external_value = this.external_value
                console.log(external_value)
            },
            // 可添加图文介绍 / 一键复制框
            add_awards(){
                let adspace_id = this.adspace_id
                let promotion_value = this.promotion_value
                let rich_text = this.rich_text
                let advanced_push_value = this.advanced_push_value   
                if(promotion_value == '1'){
                    this.$router.push({
                        path:'/richtext',
                        query: {
                            rich_text:'1',
                            adspace_id:adspace_id,
                            promotion_value:promotion_value,
                            advanced_push_value:advanced_push_value,
                        }
                    })
                }else{
                    this.$router.push({
                    path:'/richtext',
                        query: {
                            rich_text:'0',
                            advanced_push_value:advanced_push_value,
                        }
                    })
                }
                
            },
            title_click(e){
                let list_titles = this.list_titles
                let is_val = this.is_val
                for(let i=0;i<list_titles.length;i++){
                    list_titles[i].is = '0'
                    if(e == list_titles[i].index){
                        list_titles[i].is = '1'
                        is_val = list_titles[i].index
                        if(list_titles[i].index == '0'){
                            this.adspace_type = 'banner'
                        }else if(list_titles[i].index == '1'){
                            this.adspace_type = 'streamer'
                        }else if(list_titles[i].index == '2'){
                            this.adspace_type = 'open_screen'
                        }else if(list_titles[i].index == '3'){
                            this.adspace_type = ''
                        }
                    }
                }
                this.list_titles = list_titles
                this.is_val = is_val
            },
            // 返回首页
            back_to_top(){
                let promotion_value = this.promotion_value
                if(promotion_value == '1'){
                    this.$router.go(-1)
                }else{
                    this.$router.push({path:'/'})
                }
               

            },
            //  下一步 
            advabced_page(){
                let adspace_id = this.adspace_id
                let promotion_value = this.promotion_value
                // if(this.img == ''){
                //     this.$message({
                //         message: '请选择封面图！',
                //         duration:'2000'
                //     })
                //     return
                // }
                // if(this.external_value == ''){
                //     this.$message({
                //         message: '请选择跳转方式！',
                //         duration:'2000'
                //     })
                //     return
                // }
                // if(this.external_value == 'url'){
                //     if(this.url == ''){
                //         this.$message({
                //             message: '请填写跳转链接！',
                //             duration:'2000'
                //         })
                //         return
                //     }
                // }
                // if(this.external_value == 'detail'){
                //     if(this.uplist_list_chun == ''){
                //         this.$message({
                //             message: '请添加内容编辑！',
                //             duration:'2000'
                //         })
                //         return
                //     }
                // }
                if(promotion_value == '1'){
                    sessionStorage.setItem("adspace_type",this.adspace_type)
                    let adspace_id = this.adspace_id
                    let adspace_type = this.adspace_type
                    let img = this.img
                    let redirect_type = this.external_value
                    let url = this.url
                    let content = this.uplist_list_chun
                    let advanced_list_xg = {adspace_id:adspace_id,adspace_type:adspace_type,img:img,redirect_type:redirect_type,url:url,content:content}
                    let dd = JSON.stringify(advanced_list_xg)
                    sessionStorage.setItem("advanced_list_xg",dd)

                    let bj_news = this.bj_news
                    let is_val = this.is_val
                    if(bj_news == '1'){
                        if(is_val == '1'){
                            this.$router.push({
                                path:'/advanced_push_two',
                                query: {
                                    adspace_id:adspace_id,
                                    promotion_value:promotion_value,
                                    bj_news:'1',
                                    is_val:'1'
                                }
                            })
                        }else{
                            this.$router.push({
                                path:'/advanced_push_two',
                                query: {
                                    adspace_id:adspace_id,
                                    promotion_value:promotion_value,
                                    bj_news:'1'
                                }
                            })
                        }
                        
                    }else{
                        if(is_val == '1'){
                            this.$router.push({
                                path:'/advanced_push_two',
                                query: {
                                    adspace_id:adspace_id,
                                    promotion_value:promotion_value,
                                    is_val:'1'
                                }
                            })
                        }else{
                            this.$router.push({
                                path:'/advanced_push_two',
                                query: {
                                    adspace_id:adspace_id,
                                    promotion_value:promotion_value,
                                }
                            })
                        }
                        
                    }
                }else{
                    let is_val = this.is_val
                    console.log(is_val)
                    sessionStorage.setItem("adspace_type",this.adspace_type)
                    let adspace_id = this.adspace_id
                    let adspace_type = this.adspace_type
                    let img = this.img
                    let redirect_type = this.external_value
                    let url = this.url
                    let content = this.uplist_list_chun
                    let advanced_list_xg = {adspace_id:adspace_id,adspace_type:adspace_type,img:img,redirect_type:redirect_type,url:url,content:content}
                    let dd = JSON.stringify(advanced_list_xg)
                    sessionStorage.setItem("advanced_list_xg",dd)
                    if(is_val == '1'){
                        this.$router.push({
                            path:'/advanced_push_two',
                            query: {
                                is_val:'1'
                            }
                        })
                    }else{
                        this.$router.push({
                            path:'/advanced_push_two'
                        })
                    }
                    
                }

            },
        },
    }
</script>