
<template>
    <div class="y_index">
        <div class='y_index_1'>
            <div class='y_index_2' @click="text_pop_show()">关于喵君</div>
            <div class='y_index_2' @click="how_use(1)">使用攻略</div>
        </div>
        <div class='y_index_3'>
            <div class='y_index_4'>
                <div class='y_index_5'>
                    <router-link class='y_index_6' to='/login'>登录</router-link>
                </div>
                <div class='y_index_7' @click="province_city_show()">
                    <div class='y_index_8'>
                        <img src="../../../images/icon_1.png" alt="">
                    </div>
                    <div class='y_index_6' to='/'>{{city_name}}</div>
                </div>
            </div>
            <van-swipe class="my-swipe" :autoplay="3000" :show-indicators="false" >
                <van-swipe-item v-for='(todo,index) in banner_img' :key="index">
                    <img :src="''+''+todo.img" alt="">
                </van-swipe-item>
            </van-swipe>
            <!-- <el-carousel class='y_index_9' indicator-position='none' arrow='never'>
    
            </el-carousel> -->
            <div class='y_index_10'>
                <div class='y_index_11' @click="index_one_page">
                    <div class='y_index_12'>
                        <img src="../../../images/icon_2.png" alt="">
                    </div>
                    <div class='y_index_13'>
                        <p>招聘</p>
                    </div>
                </div>
                <div class='y_index_11' @click="promotion_jump">
                    <div class='y_index_12'>
                        <img src="../../../images/icon_3.png" alt="">
                    </div>
                    <div class='y_index_13'>
                        <p>推广</p>
                    </div>
                </div>
                <div class='y_index_11' @click="admissions_jump" >
                    <div class='y_index_12'>
                        <img src="../../../images/icon_4.png" alt="">
                    </div>
                    <div class='y_index_13'>
                        <p>招生</p>
                    </div>
                </div>
                <router-link to='/advanced_push' class='y_index_11'>
                    <div class='y_index_12'>
                        <img src="../../../images/icon_5.png" alt="">
                    </div>
                    <div class='y_index_13'>
                        <p>高级推送</p>
                    </div>
                </router-link>
                <div class='y_index_11' @click="topics_jump">
                    <div class='y_index_12'>
                        <img src="../../../images/icon_6.png" alt="">
                    </div>
                    <div class='y_index_13'>
                        <p>话题推送</p>
                    </div>
                </div>
                <div class='y_index_11' @click="panel_jump">
                    <div class='y_index_12'>
                        <img src="../../../images/icon_7.png" alt="">
                    </div>
                    <div class='y_index_13'>
                        <p>小组推送</p>
                    </div>
                </div>
                <div class='y_index_11' @click="activity_jump" >
                    <div class='y_index_12'>
                        <img src="../../../images/icon_8.png" alt="">
                    </div>
                    <div class='y_index_13'>
                        <p>活动推送</p>
                    </div>
                </div>
                <div class='y_index_11' @click="fq_cj">
                    <div class='y_index_12'>
                        <img src="../../../images/icon_9.png" alt="">
                    </div>
                    <div class='y_index_13'>
                        <p>发起抽奖</p>
                    </div>
                </div>
            </div>
            <div class='y_index_14' v-if="business_index_notice == '0'?false:true">
                <div class='y_index_15'>
                    <img src="../../../images/icon_10.png" alt="">
                </div>
                <div class='y_index_16'>
                    <!-- <vue-seamless-scroll></vue-seamless-scroll> -->
                    <!-- 两个for循环。push进一个数组，为两个一组 -->
                    <van-swipe class="my-swipe y_index_17" :autoplay="3000" :show-indicators="false" :touchable="false" vertical>
                        <van-swipe-item v-for='(horn,index) in horn_list' :key="index" class='y_index_20'>
                            <div class='y_index_19'>
                                <div class='y_index_18'>
                                    <h2>{{horn.phone}}</h2>
                                    <h3>{{horn.title}}</h3>
                                </div>
                                <!-- <p>{{horn.time}}</p> -->
                            </div>
                            <div class='y_index_19'>
                                <div class='y_index_18'>
                                    <h2>{{horn.phone}}</h2>
                                    <h3>{{horn.title}}</h3>
                                </div>
                                <!-- <p>{{horn.time}}</p> -->
                            </div>
                        </van-swipe-item>
                    </van-swipe>
                </div>
            </div>
            <div class='y_index_21' v-if="business_user_data == '0'?false:true">
                <div class='y_index_22'>
                    <div class='y_index_23'>
                        <p>喵君校友圈（微信小程序）用户数据：</p>
                    </div>
                    <router-link to='/sw_user_detail' class='y_index_24'>
                        查看详情
                    </router-link>
                </div>
                <div class='y_index_25'>
                    共有<p>{{school_count}}</p>所{{city_name}}高校学生用户，用户总数<p>{{user_count}}</p>
                </div>
            </div>
        </div>
        <div class='y_index_26'>
            <div class='y_index_27'>喵君服务过：</div>
            <div class='y_index_28'>
                <div class="y_index_29">
                    <div class="y_index_30" v-for='(todo,index) in partner_list' :key="index">
                        <img :src="todo.img" alt="">
                    </div>
                </div>
            </div>
        </div>

        <!-- 公共弹窗   遮罩层 -->
        <div class='y_pop_masking' :class='pop_masking == 0 ? "":"y_masking_block"'></div>
        <!-- 关于喵君 弹窗 --> 
        <div class="y_text_pop_1" :class="text_pop_value == 0? '':'y_masking_block'">
            <div class="y_text_pop_6">
                <div class="y_text_pop_2">
                    <div class="y_text_pop_3">
                        <h2>关于喵君</h2>
                    </div>
                    <div class="y_text_pop_4" @click="text_pop_hide()">
                        <img src="../../../images/icon_16.png" alt="">
                    </div>
                </div>
                <div class="y_text_pop_5">
                    <p>活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容活动规则内容</p>
                </div>
            </div>
        </div>
        <!-- 省市选择 弹窗 -->
        <div class="y_province_city_1" :class="province_city_value == '0'?'':'y_masking_block'">
            <div class="y_province_city_6">
                <div class="y_province_city_2">
                    <div class="y_province_city_3" @click="province_city_hide()">
                        <img src="../../../images/icon_16.png" alt="">
                    </div>
                    <div class="y_province_city_4">
                        <h2>选择城市</h2>
                    </div>
                    <div class="y_province_city_5" @click="province_city_dete()">
                        <p>确定</p>
                    </div>
                </div>
            </div>
            <div class="y_province_city_7">
                <div class="y_province_city_9">
                    <div class="y_province_city_8" :class="province.is == '0'?'':'y_province_city_8_active'" v-for="(province,index) in province_list" :key="index" @click="province_data(province.id)">
                        <p>{{province.areaname}}</p>
                    </div>
                </div>
                <div class="y_province_city_9">
                    <div class="y_province_city_8" :class="city_list == ''?'y_display_none':(city.is == '0'?'':'y_province_city_8_active')" v-for="(city,index) in city_list" :key="index" @click="city_data(city.id)">
                        <p>{{city.areaname}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <kefu></kefu>
    <bottom></bottom>
</template>
<style scoped>
    @import url(./style.scss);
    .y_text_pop_1{
        width: 100%;
        height: 5.5rem;
        position: fixed;
        bottom: 0rem;
        left: 0rem;
        z-index: 20;
        background: #fff;
        border-top-left-radius: 0.1rem;
        border-top-right-radius: 0.1rem;
        display: none;
    }
    .y_text_pop_2{
        width: 100%;
        height: 1.3rem;
        position: relative;
        display: flex;
        align-items: center;
    }
    .y_text_pop_3{
        width: 100%;
        height: 100%;
    }
    .y_text_pop_3 h2{
        text-align: center;
        line-height: 1.3rem;
        color: #333;
        font-size: 0.3rem;
        font-weight: 600;
    }
    .y_text_pop_4{
        width: 0.35rem;
        height: 0.35rem;
        position: absolute;
        right: 0rem;
        top: 50%;
        transform: translate(0%,-50%);
    }
    .y_text_pop_4 img{
        width: 100%;
        object-fit: cover;
    }
    .y_text_pop_5{
        width: 100%;
        height: 3.9rem;
        overflow-y: scroll;
    }
    .y_text_pop_5 p{
        font-size: 0.3rem;
        color: #333;
    }
    .y_text_pop_6{
        padding:0rem 0.25rem;
        position: relative;
    }

    .y_province_city_1{
        width: 100%;
        height: 7.3rem;
        background: #fff;
        position: fixed;
        bottom: 0rem;
        left: 0rem;
        z-index: 20;
        border-top-left-radius: 0.1rem;
        border-top-right-radius: 0.1rem;
        display: none;
    }
    .y_province_city_2{
        width: 100%;
        height: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_province_city_3{
        width: 0.35rem;
        height: 0.35rem;
    }
    .y_province_city_3 img{
        width: 100%;
        object-fit: cover;
    }
    .y_province_city_4 h2{
        font-weight: 600;
        font-size: 0.36rem;
        color: #000;
    }
    .y_province_city_5{
        width: 1.2rem;
        border-radius: 0.1rem;
        background: #FECE0A;
        border-radius: 0.1rem;
        line-height: 0.6rem;
        text-align: center;
        color: #333;
        font-size: 0.3rem;
    }
    .y_province_city_6{
        padding: 0rem 0.25rem;
    }
    .y_province_city_7{
        width: 100%;
        height: 5.5rem;
        display: flex;
        justify-content: flex-start;
    }
    .y_province_city_9{
        flex: 1;
        height: 100%;
        overflow-y: scroll;
    }
    .y_province_city_8{
        width: 100%;
        display: flex;
        align-items: center;
        height: 1rem;
    }
    .y_province_city_8 p {
        font-size: 0.32rem;
        width: 100%;
        text-align: center;
        color: #aaa;
        position: relative;
        line-height: 0.75rem;
        transition: ease 0.5s all;
    }
    .y_province_city_8_active p{
        background: #F0F0F0;
        transition: ease 0.5s all;
    }
    .y_masking_block{
        display: block;
    }

    .y_index_29{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .y_index_30{
        width: 32%;
        margin-right: 2%;
        height: 1.3rem;
        margin-bottom: 0.1rem;
        text-align: center;
    }
    .y_index_29 .y_index_30:nth-child(3n){
        margin-right: 0%;
    }
    .y_index_30 img{
        height: 100%;
        object-fit: cover;
    }

</style>
<script>
    import axios from 'axios'
    import {index} from '../../api/request.js'
    import {province_city} from '../../api/request.js'

    import kefu from '../../components/kefu/kefu'
    import bottom from '../../components/bottom/bottom'
    import {edit_default_city} from '../../api/request.js'  // 修改默认城市 
    import {user_info} from '../../api/request.js'  // 用户信息
    
    export default {
        data(){
            return {
                banner_img:[
                    
                ],
                horn_list:[
                    {phone:'',title:'',time:''}
                ],
                pop_masking:'0',   // 公共弹窗 遮罩层
                text_pop_value:'0', // 使用攻略 弹窗
                province_city_value:'0', // 选择城市 弹窗
                province_list:[
                    {index:'',name:'',is:''}
                ],
                city_list:[
                    
                ],
                partner_list:[

                ],
                school_count:'',
                user_count:'',
                city_name:'请选择', // 城市 名称
                city_name_chun:'',
                city_id_chun:'', // 城市id 存
                city_bh:'',   // 城市编号
                is_city:'',   // 判断城市选择
                business_index_notice:'',
                business_user_data:'',
            }
        },  
        components:{
            kefu,
            bottom,
            axios,
        },
        mounted(){
            let that = this
            this.token = this.$cookies.get('token')
            let token = this.token
            province_city({pid:'0'}).then((res) => {
                console.log(res)
                that.province_list = res.data
                let province_list = that.province_list
                for(let i=0;i<province_list.length;i++){
                    province_list[i].is = '0'
                }
                that.province_list
                console.log(that.province_list)
            })
            user_info({token}).then((res) =>{
                console.log(res)
                let is_city = res.data.business_default_city_id

                if(is_city == ''){
                    this.is_city = '0'
                }else{
                    this.is_city = is_city
                    this.city_name = res.data.business_default_city_areaname
                    sessionStorage.setItem("city_id_chun",is_city)
                }
                index({token:token,city_id:is_city}).then((res) => {
                    console.log(res)
                    that.banner_img = res.banner            // banner
                    that.partner_list = res.partner         // 服务过的
                    that.school_count = res.school_count    // 学校
                    that.user_count = res.user_count        // 学员
                    that.horn_list = res.notice_list        // 滚动文字
                    that.business_index_notice = res.business_index_notice       
                    that.business_user_data = res.business_user_data       
                })
            })
        },
        methods: {
            // 使用攻略 弹窗
            text_pop_show(){
                this.pop_masking = '1'
                this.text_pop_value = '1'
            },
            text_pop_hide(){
                this.pop_masking = '0'
                this.text_pop_value = '0'
            },
            // 使用攻略跳转
            how_use(value){
                this.$router.push({
                    path:'/user_comm_jump',
                    query: {
                        user_value: value,
                    }
                })
            },
            // 选择城市 弹窗 显示 隐藏
            province_city_show(){
                this.pop_masking = '1'
                this.province_city_value = '1'
            },
            province_city_hide(){
                this.pop_masking = '0'
                this.province_city_value = '0'
            },
            // 选择省
            province_data(e){
                console.log(e)
                let that = this
                let province_list = that.province_list
                for(let i=0;i<province_list.length;i++){
                    province_list[i].is = '0' 
                    if(province_list[i].id == e){
                        province_list[i].is = '1'
                    }
                }
                that.province_list = province_list

                province_city({pid:e}).then((res) => {
                    that.city_list = res.data
                    let city_list = that.city_list
                    for(let i=0;i<city_list.length;i++){
                        city_list[i].is = '0'
                    }
                })
            },
            // 选择市
            city_data(e){
                let that = this
                let city_list = that.city_list
                let city_name_chun = that.city_name_chun
                for(let i=0;i<city_list.length;i++){
                    city_list[i].is = '0' 
                    if(city_list[i].id == e){
                        city_list[i].is = '1'
                        city_name_chun = city_list[i].areaname
                        that.city_id_chun = city_list[i].id
                    }
                }
                that.city_list = city_list
                that.city_name_chun = city_name_chun
                that.city_bh = e
                console.log(e)
            },
            // 城市确定
            province_city_dete(){
                let that = this
                let token = that.token
                let city_bh = that.city_bh
                let city_name_chun = that.city_name_chun
                // that.city_name = city_name_chun
                console.log(that.city_name)
                edit_default_city({token,business_default_city_id:city_bh}).then((res) =>{
                    user_info({token}).then((res) =>{
                        console.log(res)
                        this.city_bh = res.data.business_default_city_id
                        this.city_name = res.data.business_default_city_areaname
                        
                    })
                })
                index({token:token,city_id:city_bh}).then((res) => {
                    console.log(res)
                    that.school_count = res.school_count    // 学校
                    that.user_count = res.user_count        // 学员
                })
                sessionStorage.setItem("city_id_chun",that.city_id_chun)
                this.is_city = that.city_id_chun
                that.province_city_value = '0'
                that.pop_masking = '0'

            },
            // 招聘跳转 
            index_one_page(){
                // let is_city = this.is_city
                // if(is_city == '0'){
                //     this.$message({
                //         message: '请先选择城市',
                //         duration:'2000'
                //     })
                //     return
                // }
                this.$router.push({
                    path:'/recruit_index',
                })
            },
            // 话题跳转 信息类
            topics_jump(){
                let is_city = this.is_city
                if(is_city == '0'){
                    this.$message({
                        message: '请先选择城市',
                        duration:'2000'
                    })
                    return
                }
                this.$router.push({
                    path:'/topics_push',
                    query: {
                      name: 'topic',
                      index_index:'1'
                    }
                })
            },
            // 小组跳转 信息类
            panel_jump(){
                let is_city = this.is_city
                if(is_city == '0'){
                    this.$message({
                        message: '请先选择城市',
                        duration:'2000'
                    })
                    return
                }
                this.$router.push({
                    path:'/topics_push',
                    query: {
                      name: 'group',
                      index_index:'2'
                    }
                })
            },
            // 活动推广 信息类
            activity_jump(){
                let is_city = this.is_city
                if(is_city == '0'){
                    this.$message({
                        message: '请先选择城市',
                        duration:'2000'
                    })
                    return
                }
                this.$router.push({
                    path:'/topics_push',
                    query: {
                      name: 'activity',
                      index_index:'3'
                    }
                })
            },
            // 普通 推广
            promotion_jump(){
                let is_city = this.is_city
                if(is_city == '0'){
                    this.$message({
                        message: '请先选择城市',
                        duration:'2000'
                    })
                    return
                }
                this.$router.push({
                    path:'/promotion',
                    query: {
                      name: 'push',
                      index_index:'1',
                    }
                })
            },
            // 普通 招生 
            admissions_jump(){
                let is_city = this.is_city
                if(is_city == '0'){
                    this.$message({
                        message: '请先选择城市',
                        duration:'2000'
                    })
                    return
                }
                this.$router.push({
                    path:'/promotion',
                    query: {
                      name: 'student',
                      index_index:'2',
                    }
                })
            },
            // 发起抽奖
            fq_cj(){
                let is_city = this.is_city
                if(is_city == '0'){
                    this.$message({
                        message: '请先选择城市',
                        duration:'2000'
                    })
                    return
                }
                this.$router.push({
                    path:'/sweepstakes_settings',
                })
            },
        },
    }

</script>
