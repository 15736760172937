<template>
    <div class="y_message_private_1">
        <div class="y_message_private_2">
            <router-link to='/message_push_progress' class="y_message_private_3">
                <div class="y_message_private_4">
                    <img src="../../../images/icon_44.png" alt="">
                    <img src="../../../images/icon_52.png" alt="">
                    <div class="y_message_private_6" :class="unread_three_val == '0' ?'y_display_none':''">
                        <h2>5</h2>
                    </div>
                </div>
                <div class="y_message_private_5">
                    <h2>推送中</h2>
                </div>
            </router-link>
            <router-link to='/message_to_pushed' class="y_message_private_3">
                <div class="y_message_private_4">
                    <img src="../../../images/icon_45.png" alt="">
                    <img src="../../../images/icon_53.png" alt="">
                    <div class="y_message_private_6" :class="unread_four_val == '0' ?'y_display_none':''">
                        <h2>5</h2>
                    </div>
                </div>
                <div class="y_message_private_5">
                    <h2>待推送</h2>
                </div>
            </router-link>
            <router-link to='/message_already_offline' class="y_message_private_3">
                <div class="y_message_private_4">
                    <img src="../../../images/icon_46.png" alt="">
                    <img src="../../../images/icon_54.png" alt="">
                    <div class="y_message_private_6" :class="unread_five_val == '0' ?'y_display_none':''">
                        <h2>5</h2>
                    </div>
                </div>
                <div class="y_message_private_5">
                    <h2>已下线</h2>
                </div> 
            </router-link>
            <router-link to='/message_private' class="y_message_private_3 ">
                <div class="y_message_private_4">
                    <img src="../../../images/icon_42.png" alt="">
                    <img src="../../../images/icon_50.png" alt="">
                    <div class="y_message_private_6" :class="unread_one_val == '0' ?'y_display_none':''">
                        <h2>{{business_not_read_chat_number}}</h2>
                    </div>
                </div>
                <div class="y_message_private_5">
                    <h2>私信</h2>
                </div>
            </router-link>
            <router-link to='/message_comments' class="y_message_private_3 y_message_private_3_active">
                <div class="y_message_private_4">
                    <img src="../../../images/icon_43.png" alt="">
                    <img src="../../../images/icon_51.png" alt="">
                    <div class="y_message_private_6" :class="unread_two_val == '0' ?'y_display_none':''">
                        <h2>{{business_not_read_comment_number}}</h2>
                    </div>
                </div>
                <div class="y_message_private_5">
                    <h2>评论</h2>
                </div>
            </router-link>
        </div>

        <div class="y_message_private_7">
            <div class="y_message_private_8" v-for="(item,index) in comments_list" :key="index" @click="pop_show(item.blog_id,item.message_at_nicname,item.message_at_uid,item.blog_comment_parent_id,item.comment_id)">
                <div class="y_message_private_9" >
                    <img :src="''+item.head_portrait" alt="" v-if="item.head_portrait == 'false'?false:true">
                    <img src="../../../images/icon_112.png" alt="" v-if="item.head_portrait == 'false'?true:false">
                </div>
                <div class="y_message_comments_1">
                    <div class="y_message_comments_2">
                        <div class="y_message_comments_3">
                            <h2>{{item.nicname}}</h2>
                            <p>{{item.school_title}}</p>
                        </div>
                        <div class="y_message_comments_4">
                            <p>{{item.append_string}}</p>
                        </div>
                        <div class="y_message_comments_5">
                            <div class="y_message_comments_6">
                                <img src="../../../images/icon_48.png" alt="">
                            </div>
                            <div class="y_message_comments_7">
                                <div class="y_message_comments_8" v-if="item.has_content == '1'?true:false">
                                    <span class="y_message_comments_8_1"></span>
                                    <span class="y_message_comments_8_2">{{item.nicname}}</span>
                                    <span class="y_message_comments_8_3">：{{ item.blog_comment_content }}</span>
                                </div>  
                                <div class="y_message_comments_8" v-if="item.has_content == '0'?true:false">
                                    <span class="y_message_comments_8_1">这条评论已删除</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="y_message_comments_10"  v-if="item.message_img == '' ?false:true">
                        <img :src="''+item.message_img" alt="">
                    </div>
                </div>
            </div>
            
        </div>


        <div class="comments_return_1" :class="is_pop == '1'?'y_display_block':''">
            <div class="comments_return_2">
                <p class="comments_return_4" @click="pop_hide()">取消</p>
                <p class="comments_return_5" @click="comments_sending()">发送</p>
            </div>
            <div class="comments_return_3">
                <el-input type="textarea" name="" id="" v-model="text_val" cols="30" rows="10" placeholder="写评论"></el-input>
            </div>
            <div class='y_recruit_13'>
                <el-upload class="upload-demo" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :on-preview="handlePreview" :on-success="hand_success" :on-remove="handleRemove" :file-list="fileList" list-type="picture">
                    <div class='y_recruit_14' >
                        <img src="../../../images/icon_14.png" alt="">
                    </div>
                </el-upload>
            </div>
        </div>

        <div class="y_page_1">
            <el-pagination background :pager-count="10" :current-page="currentPage" layout="prev, pager, next" @current-change="handleCurrentChange" :hide-on-single-page="true" :total="count"></el-pagination>
        </div>
    </div>
    <!-- 公共弹窗 阴影-->
    <div class='y_comm_shadows' :class="is_pop == '1'?'y_comm_shadows_block':''"></div>
    <kefu></kefu>
    <bottom></bottom>
</template>

<style>
    .y_message_comments_1{
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        margin-left: 0.13rem;
        border-bottom: 0.01rem solid #eee;
    }
    .y_message_comments_2{
        flex: 1;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
    }
    .y_message_comments_3{
        display: flex;
        justify-items: flex-start;
        align-items: center;
        width: 100%;
    }
    .y_message_comments_3 h2{
        font-size: 0.26rem;
        color: #333;
    }
    .y_message_comments_3 p{
        font-size: 0.2rem;
        width: 1.3rem;
        height: 0.34rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #333;
        background: #FECE0A;
        margin-left: 0.1rem;
        line-height: 0.34rem;
        padding: 0rem 0.1rem;
        border-radius: 0.05rem;
    }
    .y_message_comments_4{
        width: 100%;
    }
    .y_message_comments_4 p{
        font-size: 0.22rem;
        color: #888;
    }
    .y_message_comments_5{
        display: flex;
        justify-content: flex-start;
        
    }
    .y_message_comments_6{
        width: 0.3rem;
        height: 0.3rem;
        margin-right: 0.1rem;
        display: flex;
        align-items: center;
        justify-content: center;
    } 
    .y_message_comments_6 img{
        width: 100%;
        object-fit: cover;
    }
    .y_message_comments_7{
        flex: 1;
        display: flex;
        flex-wrap: wrap;
    }
    .y_message_comments_8{
        max-height: 0.72rem;
        overflow: hidden;
    }
    .y_message_comments_8_1{
        color: #333333;
        font-size: 0.24rem;
    }
    .y_message_comments_8_2{
        color: #FECE0A;
        font-size: 0.24rem;
    }
    .y_message_comments_8_3{
        font-size: 0.24rem;
        color: #333;
    }
    .y_message_comments_10{
        width: 1.1rem;
        height: 1.1rem;
        margin-left: 0.4rem;
    }
    .y_message_comments_10 img{
        width: 100%;
        object-fit: cover;
        height: 100%;
    }
    .y_display_none{
        display: none;
    }


    .comments_return_1{
        width: 85%;
        padding: 0rem 0.25rem 0.2rem;
        position: fixed;
        top: 50%;
        left: 50%;
        background: #fff;
        border-radius: 0.2rem;
        transform: translate(-50%,-50%);
        z-index: 100;
        display: none;
    }
    .comments_return_2{
        line-height: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .comments_return_3{
        position: relative;
        padding: 0.15rem;
        background: #eee;
        border-radius: 0.2rem;
        margin-bottom: 0.1rem;
    }
    .comments_return_3 textarea{
        width: 100%;
        height: 2rem;
        border: none;
        padding: 0px;
        color: #000;
        font-size: 0.3rem;
        background: transparent;
        border-radius: 0px;
    }
    .y_recruit_13 .upload-demo .el-upload-list>li{
        margin-right: 0.15rem;
    }
    .comments_return_4{
        color: #ccc;
        font-size: 0.3rem;
    }
    .comments_return_5 {
        color: #FECE0A;
        font-size: 0.3rem;
    }
    .y_display_block{
        display: block;
    }
</style>

<script>
    import kefu from '../../components/kefu/kefu'
    import bottom from '../../components/bottom/bottom'
    import {user_info} from '../../api/request.js'   // 用户信息
    import {clear_not_read_comment_number} from '../../api/request.js'   // 清空未读评论数量
    import {add_comment} from '../../api/request.js'   // 添加评论-商务端
    import {message_list} from '../../api/request.js'   // 消息列表
    
    export default {
        data(){
            return {
                unread_one_val: '0',
                unread_two_val: '0',
                unread_three_val: '0',
                unread_four_val: '0',
                unread_five_val: '0',

                token:'',
                comments_list:'' , // 评论列表

                business_not_read_chat_number:'', // 私信未读数量
                business_not_read_comment_number:'', // 评论未读数量

                page:'1',   // 当前页数
                pages:'',  // 总页数
                count:'', // 总个数
                currentPage:'',
                is_pop:'0',
                text_val:'',
                blog_id:'',
                parent_id:'',
                comment_id:'',
                fileList: [],
                fileList_list:[],
            }
        },  
        components:{
            kefu,
            bottom,
        },

        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            message_list({token:token}).then((res) =>{
                console.log(res,'消息列表')
                if(res.error_code == '0'){
                    this.comments_list = res.data
                    this.page = res.page     // 当前页数
                    this.pages = res.pages   // 总页数
                    this.count = res.count*1   // 总个数
                }else{
                    this.$message({
                        message: res.msg,
                        duration:'2000'
                    })
                }
            })
            user_info({token:token}).then((res)=>{
                console.log(res,'用户信息')
                if(res.error_code == '0'){
                    if(res.data.business_not_read_chat_number > 0){
                        this.unread_one_val = '1'
                        this.business_not_read_chat_number = res.data.business_not_read_chat_number
                    }
                }
            })
            clear_not_read_comment_number({token:token}).then((res)=>{
                console.log(res,'清空未读评论数量')
            })

        },
        methods: {
            // 分页
            handleCurrentChange (currentPage) {
                let token = this.token
                console.log(currentPage,'currentPage');
                this.currentPage = currentPage;
                message_list({token:token,page:currentPage}).then((res) =>{
                    console.log(res,'消息列表')
                    if(res.error_code == '0'){
                        this.comments_list = res.data
                        this.page = res.page     // 当前页数
                        this.pages = res.pages   // 总页数
                        this.count = res.count*1   // 总个数
                    }else{
                        this.$message({
                            message: res.msg,
                            duration:'2000'
                        })
                    }
                })
            },
            // 图片上传
            // 删除图片
            handleRemove(file, fileList) {
                console.log(file, fileList);
                let fileList_list = []
                for(let i=0;i<fileList.length;i++){
                    fileList_list.push(fileList[i])
                }
                this.fileList_list = fileList_list 
                console.log(this.fileList_list,'删除图片')
            },
            // 上传成功
            hand_success(file,fileList){
                console.log(file,fileList)
                let fileList_list = this.fileList_list
                fileList_list.push({url:''+file.img})
                this.fileList_list = fileList_list
                console.log(this.fileList_list,'上传成功')
            },
            handlePreview(file,fileList) {
                console.log(file,fileList);
            },
            // 回复弹窗显示
            pop_show(id,name,uid,parent_id,comment_id){
                let that = this
                that.is_pop = '1'
                that.blog_id = id
                that.at_nicname = name
                that.at_uid = uid
                that.parent_id = parent_id
                that.comment_id = comment_id
            },
            // 回复弹窗关闭
            pop_hide(){
                this.is_pop = '0'
            },
            comments_sending(){
                let that = this
                let token = that.token
                let blog_id = that.blog_id
                let text_val = that.text_val
                let parent_id = that.parent_id
                let comment_id = that.comment_id
                let at_nicname = that.at_nicname
                let at_uid = that.at_uid

                let business_gallery = this.fileList
                let business_gallery2 = this.fileList_list
                let img_list = ''
                var reg1 = new RegExp("")
                // business_gallery = business_gallery.replace('/')
                for(let i=0;i<business_gallery2.length;i++){
                    img_list = img_list +','+ business_gallery2[i].url.replace(reg1,"");
                }
                business_gallery2 = img_list.substr(1,img_list.length)

                if(parent_id == '0'){
                    add_comment({token,blog_id,content:text_val,gallery:business_gallery2,parent_id:comment_id,level:'2'}).then((res) =>{
                        if(res.error_code == '0'){
                            console.log(res)
                            that.is_pop = '0'
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'1000'
                            })
                        }
                    })
                }else{
                    add_comment({token,blog_id,content:text_val,gallery:business_gallery2,parent_id,level:'2',at_nicname,at_uid}).then((res) =>{
                        if(res.error_code == '0'){
                            console.log(res)
                            that.is_pop = '0'
                        }else{
                            this.$message({
                                message: res.msg,
                                duration:'1000'
                            })
                        }
                    })
                }
                
            },
        },
    }
</script>