<template>
    <div class="y_index">
         <!-- <div class='y_index_1'>
            <router-link class='y_index_2' to='/'>关于喵君</router-link>
            <router-link class='y_index_2' to='/'>使用攻略</router-link>
        </div> -->
        <push_charges></push_charges>
        <div class="y_recruit_1">
          <img src="../../../images/img_3.png" alt="">
        </div>

        <div class="y_sweep_settings_1">
            <div class="y_sweep_settings_2" @click="description_show">
                <p>友情说明</p>
            </div>

            <div class="y_sweep_settings_3 y_sweep_settings_25" v-for="(award_prize_list,index) in award_prize_lists" :key="index">
                <div class="y_sweep_settings_4">
                    <div class="y_sweep_settings_5">
                        <img src="../../../images/img_4.png" alt="" v-show="award_prize_list.img == ''?true:false">
                        <img :src="'' + award_prize_list.img" alt="" v-show="award_prize_list.img == ''?false:true">
                        <div class="y_sweep_settings_6" @click="replacement_img(index)">
                            <img src="../../../images/icon_32.png" alt="">
                            <p>更换图片</p>
                        </div>
                    </div>
                    <div class="y_sweep_settings_7">
                        <div class="y_sweep_settings_8">
                            <h2>{{award_list[index].name}}</h2>
                            <div class="y_sweep_settings_9">
                                <el-input type="text" v-model="award_prize_list.title" placeholder="请输入奖品名称，最多24个字"></el-input>
                            </div>
                        </div>
                        <div class="y_sweep_settings_8">
                            <h2>奖品份数</h2>
                            <div class="y_sweep_settings_9">
                                <el-input v-model="award_prize_list.number" type="number" placeholder="请输入份数" @input="number_people_input"></el-input>
                            </div>
                        </div>
                        <div class="y_sweep_settings_8">
                            <h2>奖品总值</h2>
                            <div class="y_sweep_settings_9">
                                <el-input v-model="award_prize_list.deposit_fee"  type="number"  placeholder="请输入金额"></el-input>
                            </div>
                        </div>
                        <div class="y_sweep_settings_8">
                            <h2>中奖率</h2>
                            <div class="y_sweep_settings_9">
                                <el-input v-model="award_prize_list.people_number" type="number" placeholder="输入人数" @input="number_people_input"></el-input>
                                <p>人抽中一份</p>
                            </div>
                        </div>
                    </div>
                    <div class="y_sweep_settings_10" @click="replacement_dele(index)">
                        <img src="../../../images/icon_34.png" alt="">
                        <p>删除此奖项</p>
                    </div>
                </div>
            </div>
            
            <div class="y_sweep_settings_3 y_sweep_settings_16 y_sweep_settings_18" @click="replacement_add">
                <div class="y_sweep_settings_4">
                    <div class="y_sweep_settings_10">
                        <img src="../../../images/icon_33.png" alt="">
                        <p>添加奖项</p>
                    </div>
                </div>
            </div>

            <div class="y_sweep_settings_2" @click="introduction_show">
                <p>开奖方式介绍</p>
            </div>
            <div class="y_sweep_settings_3 y_sweep_settings_15">
                <div class="y_sweep_settings_4">
                    <div class="y_sweep_settings_11" >
                        <p v-for="(winning_method_list,index) in winning_method_lists" :key="index" :class="winning_method_list.is == '0'?'':'y_sweep_settings_11_active'" @click="winning_select(index)">{{winning_method_list.title}}</p>
                    </div>
                    <div class="y_sweep_select_1" :class="winning_method_lists[0].is == '0'?'y_display_none':''">
                        <div class="y_sweep_settings_13">
                            <h2>开奖时间</h2>
                            <div class="y_sweep_settings_14" @click="pop_time_one_block">
                                <p>{{time_one_Value}}</p>
                                <img src="../../../images/icon_12.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="y_sweep_select_1" :class="winning_method_lists[1].is == '0'?'y_display_none':''">
                        <div class="y_sweep_settings_13">
                            <h2>到达指定人数自动开奖</h2>
                            <div class="y_sweep_settings_14 y_sweep_select_3">
                                <div class="y_sweep_select_2">
                                    <el-input type="number" :rows="2" :placeholder="'输入至少'+(number_people_input_value == '0'?'':number_people_input_value)" v-model="prize_time_input_two" @input="number_people_input_val"></el-input>
                                    <p>人</p>
                                </div>
                            </div>
                        </div>
                        <div class="y_sweep_settings_13_1" :class="prize_time_input_two == ''?'y_display_none':(prize_time_input_two < number_people_input_value ?'y_display_block':'y_display_none')">
                            <p>不能小于最少人数！</p>
                        </div>
                        <div class="y_sweep_settings_13">
                            <h2>未达到人数自动开奖时间</h2>
                            <div class="y_sweep_settings_14" @click="pop_time_two_block">
                                <p @click="pop_time_two_block">{{time_two_Value}}</p>
                                <img src="../../../images/icon_12.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="y_sweep_select_1" :class="winning_method_lists[2].is == '0'?'y_display_none':''">
                        <!-- <div class="y_sweep_settings_13">
                            <h2>人数上限</h2>
                            <div class="y_sweep_settings_14 y_sweep_select_3">
                                <div class="y_sweep_select_2">
                                    <el-input type="text" :rows="2" placeholder="输入至少" v-model="prize_time_input_three"></el-input>
                                    <p>人</p>
                                </div>
                            </div>
                        </div> -->
                        <div class="y_sweep_settings_13">
                            <h2>截止日期</h2>
                            <div class="y_sweep_settings_14 y_sweep_select_3">
                                <p @click="pop_time_three_block">{{time_three_Value}}</p>
                                <img src="../../../images/icon_12.png" alt="">
                            </div>
                        </div>
                    </div>

                    <!-- <div class="y_sweep_select_1">
                        <div class="y_sweep_settings_12">
                            <p>根据您设置的中奖率，人数达N人时自动开奖，所有奖品全被领完。</p>
                        </div>
                        <div class="y_sweep_settings_13">
                            <h2>未达到人数自动开奖时间</h2>
                            <div class="y_sweep_settings_14" @click="pop_time_one_block">
                                <p>点击设置</p>
                                <img src="../../../images/icon_12.png" alt="">
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="y_sweep_settings_3 y_sweep_settings_16">
                <div class="y_sweep_settings_4">
                    <div class="y_sweep_settings_13" @click="contact_jump">
                        <h2>领奖联系方式</h2>
                        <div class="y_sweep_settings_14">
                            <p>{{award_contact_text}}</p>
                            <img src="../../../images/icon_12.png" alt="">
                        </div>
                    </div>
                    <!-- <div class="y_sweep_settings_17">
                        <el-input type="textarea" :rows="2" placeholder="请输入规则详情" v-model="textarea"></el-input>
                    </div> -->
                    <div class="y_sweep_settings_17 y_sweep_settings_17_1">
                        <div class="y_sweep_settings_17_2">
                            <h2>抽奖说明</h2>
                        </div>
                        <el-input type="textarea" :rows="2" placeholder="请输入抽奖说明" v-model="textarea_xz"></el-input>
                    </div>
                    <div class="y_sweep_settings_17 y_sweep_settings_17_1">
                        <div class="y_sweep_settings_17_2">
                            <h2>领奖说明</h2>
                        </div>
                        <el-input type="textarea" :rows="2" placeholder="请输入领奖说明" v-model="textarea_lz"></el-input>
                    </div>
                </div>
            </div>
            <div class="y_sweep_settings_3 y_sweep_settings_16 y_sweep_settings_18">
                <div class="y_sweep_settings_4" @click="add_awards" v-if="rich_text == undefined ? true:''">
                    <div class="y_sweep_settings_19">
                        <img src="../../../images/icon_31.png" alt="">
                        <p>可添加图文介绍 / 一键复制框</p>
                    </div>
                </div>
                <div class="y_sweep_settings_4 y_sweep_settings_xg_0" v-if="rich_text == undefined ? '':true" @click="add_awards">
                    <div class="y_sweep_settings_xg_1" v-for="(uplist,index) in uplist_list_chun" :key="index">

                        <div class="y_sweep_settings_xg_2" v-if="uplist.type == 'text'">
                            <p v-html="uplist.content"></p>
                        </div>

                        <div class="y_sweep_settings_xg_2 listshowpersonshow" v-if="uplist.type == 'award_contract_id'">
                            <!-- <p>{{uplist.phone_text}}</p> -->
                            <p>联系人</p>
                            <p>加好友</p>
                            <img src="../../../images/icon_perright.png" alt="">
                        </div>

                        <div class="y_sweep_settings_xg_2 listshowpersonshow y_sweep_settings_xg_2_xg_0" v-if="uplist.type == 'text_copy'">
                            <div class="y_sweep_settings_xg_2_xg_1">
                                <div class="y_sweep_settings_xg_2_xg_2">
                                    <h2>{{uplist.content}}</h2>
                                </div>
                                <div class="y_sweep_settings_xg_2_xg_3">
                                    <p>复制：{{uplist.content_copy}}</p>
                                </div>
                            </div>
                            <div class="y_sweep_settings_xg_2_xg_4">
                                <p>复制</p>
                            </div>
                        </div>

                        <div class="y_sweep_settings_xg_2" v-if="uplist.type == 'img'">
                            <img :src="''+uplist.content" alt="">
                        </div>

                    </div>
                </div>
            </div>
            <div class="y_sweep_settings_3 y_sweep_settings_16 y_sweep_settings_18">
                <div class="y_sweep_settings_4">
                    <div class="y_sweep_settings_20" v-if="winning_method_select == '2'?false:true">
                        <div class="y_sweep_settings_21">
                            <p>参与者可邀请好友助力</p>
                            <img src="../../../images/icon_21.png" alt="">
                        </div>
                        <div class="y_sweep_settings_22">
                            <el-switch v-model="hyzl_value" active-color="#FECE0A" inactive-color="#DEDEDE" @change="hyzl_value_click"></el-switch>
                        </div>
                    </div>
                </div>
            </div>
            <div class="y_sweep_settings_3 y_sweep_settings_16 y_sweep_settings_18">
                <div class="y_sweep_settings_4">
                    <div class="y_sweep_settings_20" v-if="winning_method_select == '2'?false:true">
                        <div class="y_sweep_settings_21">
                            <p>参与者可邀请好友组队</p>
                            <img src="../../../images/icon_21.png" alt="">
                        </div>
                        <div class="y_sweep_settings_22">
                            <el-switch v-model="hyzd_value" active-color="#FECE0A" @change="hyzd_value_click" inactive-color="#DEDEDE"></el-switch>
                        </div>
                    </div>
                    <div class="y_sweep_settings_20 y_sweep_settings_23" v-if="winning_method_select == '2'?false:true">
                        <div class="y_sweep_settings_21">
                            <p>单个队伍最多</p>
                        </div>
                        <div class="y_sweep_settings_24 y_sweep_settings_9">
                            <el-input type="number" v-model="max_single" placeholder="输入人数"></el-input>
                        </div>
                        <div class="y_sweep_settings_21">
                            <p>人</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 时间选择 按时间开奖-->
        <div class='y_pop_time_min' :class='pop_time_one_value == "0"?"":"y_pop_time_min_active"' v-show="hideshow">
            <van-datetime-picker v-model="currentDate" disabled="disabled" type="datetime" title="" :min-date="minDate" @confirm='pop_time_one_dete' @cancel='pop_time_one_cancel'/>
        </div>
        <!-- 时间选择 按人数开奖-->
        <div class='y_pop_time_min' :class='pop_time_two_value == "0"?"":"y_pop_time_min_active"' v-show="hideshow">
            <van-datetime-picker v-model="currentDate" disabled="disabled" type="datetime" title="" :min-date="minDate" :max-date="maxDate" @confirm='pop_time_two_dete' @cancel='pop_time_two_cancel'/>
        </div>
        <!-- 时间选择 即抽即开奖-->
        <div class='y_pop_time_min' :class='pop_time_three_value == "0"?"":"y_pop_time_min_active"' v-show="hideshow">
            <van-datetime-picker v-model="currentDate" disabled="disabled" type="datetime" title="" :min-date="minDate" :max-date="maxDate" @confirm='pop_time_three_dete' @cancel='pop_time_three_cancel'/>
        </div>
        <!-- 图片替换 -->
        <div class="y_replacement_img_1" :class="replacement_img_is == '0'?'y_display_none':''">
            <div class="y_replacement_img_2">
                <h2>奖品图片 900x900 px</h2>
                <div class="y_replacement_img_2_img" @click="replacement_none()">
                    <img src="../../../images/icon_35.png" alt="">
                </div>
            </div>
            <div class="y_replacement_img_3">
                <div class="y_replacement_img_4">
                    <div class="y_replacement_img_5" >
                        <img src="../../../images/icon_36.png" alt="">
                    </div>
                    <p>重相册选择</p>
                </div>
                <div class="y_sweep_settings_xg_3">
                    <el-upload class="avatar-uploader" action="https://xcx.mjxyq.com/api/index.php/index/file.html" :show-file-list="false" :on-success="banner_success" :before-upload="banner_settings">
                        <img src="../../../images/icon_36.png" alt="">
                    </el-upload>
                </div>
                <!-- <div class="y_replacement_img_4">
                    <div class="y_replacement_img_5">
                        <img src="../../../images/icon_37.png" alt="">
                    </div>
                    <p>拍照</p>
                </div> -->
            </div>
            <div class="y_replacement_img_6">
                <div class="y_replacement_img_7">
                    <div class="y_replacement_img_8">
                        <h2>奖品图库</h2>
                    </div>
                    <div class="y_replacement_img_9">
                        <div class="y_replacement_img_10" v-for="(prizes_img_list,index) in prizes_img_lists" :key="index" >
                            <img :src="''+prizes_img_list.img" alt="" @click="img_replacement(index)">
                            <!-- <div class="y_replacement_img_11">
                                <img src="../../../images/icon_38.png" alt="">
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- 公共 阴影 -->
        <div class='y_recruit_two_shade' :class="shade_value == '0'?'':'y_recruit_two_shade_block'"></div>
        <!-- 底部垫高 -->
        <div class='y_recruit_two_dian'></div>
        <div class='y_recruit_two_foot_1'>
            <div class='y_recruit_two_foot_2'>
                <div class='y_recruit_two_foot_4'>
                    <p class='y_recruit_two_foot_5' @click="back_to">返回</p>
                    <!-- <div class="y_advanced_push_three_9">
                        <img src="../../../images/icon_15.png" alt="">
                        <h3>预览</h3>
                    </div> -->
                    <p class='y_recruit_two_foot_6' @click="pop_up">下一步（1/2）</p>
                </div>
            </div>
        </div>
        <!-- 友情说明 弹窗 -->
        <div class="y_tuisong_1" :class="description_value == '0'?'y_display_none':''">
            <div class="y_tuisong_2">
                <div class="y_tuisong_3">
                    <h2>友情说明</h2>
                </div>
                <p class="y_tuisong_4">{{business_award_info}}</p>
                <div class="y_tuisong_5" @click="description_hide">
                    <p>知道了</p>
                </div>
            </div>
        </div>
        <!-- 开奖方式说明 -->
        <div class="y_tuisong_1" :class="introduction_value == '0'?'y_display_none':''">
            <div class="y_tuisong_2">
                <div class="y_tuisong_3">
                    <h2>开奖方式说明</h2>
                </div>
                <p class="y_tuisong_4">{{business_award_open_info}}</p>
                <div class="y_tuisong_5" @click="introduction_hide">
                    <p>知道了</p>
                </div>
            </div>
        </div>
    </div>
    
    <kefu></kefu>
</template>
<style scoped>
    .y_sweep_settings_1{
        width: 100%;
        background: #F4F5F7;
    }
    .y_sweep_settings_2{
        margin: 0rem 0.25rem;
        height: 0.95rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .y_sweep_settings_2 p{
        font-size: 0.28rem;
        color: #333;
        text-decoration: underline;
    }
    .y_sweep_settings_3{
        margin: 0rem 0.25rem;
    }
    .y_sweep_settings_4{
        border-radius: 0.1rem;
        background: #fff;
        padding: 0.3rem 0.3rem 0rem 0.3rem;
        overflow: hidden;
    }
    .y_sweep_settings_5{
        width: 100%;
        height: 6.4rem;
        position: relative;
        margin-bottom: 0.1rem;
    }
    .y_sweep_settings_5>img{
        width: 100%;
        height: 6.4rem;
        object-fit: cover;
    }
    .y_sweep_settings_6{
        position: absolute;
        width: 1.8rem;
        height: 0.6rem;
        background: rgba(0,0,0,0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.1rem;
        top: 0.2rem;
        right: 0.2rem;
    }
    .y_sweep_settings_6 img{
        width: 0.3rem;
        height: 0.24rem;
    }
    .y_sweep_settings_6 p{
        font-size: 0.24rem;
        color: #fff;
        margin-left: 0.1rem;
        font-weight: 300;
    }
    .y_sweep_settings_7{
        width: 100%;
    }
    .y_sweep_settings_8{
        width: 100%;
        display: flex;
        justify-items: flex-start;
        align-items: center;
        height: 1rem;
        border-bottom: 0.01rem solid #EEEEEE;
    }
    .y_sweep_settings_8>h2{
        font-size: 0.28rem;
        color:#333;
        width: 1.5rem;
    }
    .y_sweep_settings_9{
        flex: 1;
        height: 100%;
        display: flex;
        justify-items: flex-start;
        align-items: center;
    }
    .y_sweep_settings_9>div{
        flex: 1;
    }
    .y_sweep_settings_9 /deep/ input{
        font-size: 0.28rem;
        color: #333;
        background: transparent;
        border: none;
        line-height: 1rem;
        height: 1rem;
        padding: 0rem;
    }
    .y_sweep_settings_9 /deep/ input::placeholder{
        color: #aaa;
        font-size: 0.28rem;
    }
    .y_sweep_settings_9>p{
        font-size: 0.28rem;
        color: #333;
        flex: 2;
    }
    .y_sweep_settings_10{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1.2rem;
    }
    .y_sweep_settings_10 img{
        width: 0.3rem;
    }
    .y_sweep_settings_10 p{
        margin-left: 0.2rem;
        color: #FECE0A;
        font-size: 0.3rem;
    }
    .y_sweep_settings_11{
        width: 100%;
        height: 0.8rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_sweep_settings_11 p{
        flex: 1;
        margin-right: 0.1rem;
        height: 0.8rem;
        text-align: center;
        line-height: 0.8rem;
        background: #F6F6F6;
        transition: ease 0.5s all;
        border-radius: 0.1rem;
        font-size: 0.28rem;
        color:#333;
    }
    .y_sweep_settings_11 p:nth-last-child(1){
        margin-right: 0rem;
    }
    .y_sweep_settings_11 .y_sweep_settings_11_active{
        background: #FECE0A;
        transition: ease 0.5s all;
    }
    .y_sweep_settings_12{
        width: 100%;
        margin-top: 0.3rem;
        min-height: 1rem;
    }
    .y_sweep_settings_12 p{
        color: #999999;
        font-size: 0.28rem;
    }
    .y_sweep_settings_13{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1rem;
    }
    .y_sweep_settings_13>h2{
        font-size: 0.28rem;
        color:#333;
    }
    .y_sweep_settings_14{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .y_sweep_settings_14 p{
        font-size: 0.28rem;
        color: #aaa;
        margin-right: 0.15rem;
    }
    .y_sweep_settings_14 img{
        width: 0.14rem;
    }
    .y_sweep_settings_15{
        margin-bottom: 0.2rem;
    }
    .y_sweep_settings_16 .y_sweep_settings_4{
        padding-top:0rem;
    }
    .y_sweep_settings_16 .y_sweep_settings_13{
        border-bottom: 0.01rem solid #EEEEEE;
    }
    .y_sweep_settings_17{
        width: 100%;
        margin: 0.3rem 0rem 0.2rem;
    }
    .y_sweep_settings_17 /deep/ textarea{
        font-size: 0.28rem;
        color: #333;
        padding: 0rem;
        border: none;
        line-height: 0.28rem;
        border-radius: 0rem;
        min-height: 2rem !important;
    }
    .y_sweep_settings_17 /deep/ textarea::placeholder{
        color:#999;
    }
    .y_sweep_settings_18{
        margin-top: 0.2rem;
    }
    .y_sweep_settings_19{
        width: 100%;
        height: 1.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .y_sweep_settings_19 img{
        width: 0.3rem;
        height: 0.3rem;
    }
    .y_sweep_settings_19 p{
        margin-left: 0.1rem;
        color: #999;
        font-size: 0.28rem;
    }
    .y_sweep_settings_20{
        width: 100%;
        height: 1.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_sweep_settings_21{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_sweep_settings_21 p{
        font-size: 0.28rem;
        color: #333;
        margin-right: 0.1rem;
    }
    .y_sweep_settings_21 img{
        width: 0.2rem;
        height: 0.2rem;
    }
    .y_sweep_settings_22 /deep/ .el-switch__core{
        width: 0.7rem;
        height: 0.5rem;
    }
    .y_sweep_settings_22 /deep/ .el-switch .el-switch__action{
        width: 0.4rem !important;
        height: 0.4rem !important;
        transform: translate(0.16rem, -0.07rem);
    }
    .y_sweep_settings_22 /deep/ .is-checked .el-switch__action{
        width: 0.4rem !important;
        height: 0.4rem !important;
        transform: translate(0.43rem, -0.07rem);
    }
    .y_sweep_settings_23{
        border-top: 0.01rem solid #eee;
        justify-content: flex-start;
    }
    .y_sweep_settings_24{
        width: 1.2rem;
        flex: none;
        margin: 0rem 0.2rem;
    }

    .y_recruit_two_foot_1{
        height: 1.2rem;
    }
    .y_advanced_push_three_9{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
    }
    .y_advanced_push_three_9 img{
        width: 0.34rem;
        height: 0.26rem;
    }
    .y_advanced_push_three_9 h3{
        margin-left: 0.05rem;
        color: #FECE0A;
        font-size: 0.3rem;
    }
    .y_recruit_two_foot_2 .y_recruit_two_foot_4{
        justify-content: space-between;
        width: 100%;
        margin-top: 0rem;
    }
    .y_recruit_two_foot_4 .y_recruit_two_foot_5{
        flex: none;
        width: 1.8rem;
        margin-left: 0.25rem;
    }
    .y_recruit_two_foot_4 .y_recruit_two_foot_6{
        width: 3rem;
        flex: none;
        margin-right: 0.25rem;
    }

    /* 图片替换 弹窗 */
    .y_replacement_img_1{
        width: 100%;
        height: 8.5rem;
        background: #F6F5F5;
        position: fixed;
        bottom: 0rem;
        left: 0rem;
        z-index: 20;
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
    }
    .y_replacement_img_2{
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 1rem;
    }
    .y_replacement_img_2 h2{
        font-size: 0.32rem;
        color: #333;
        font-weight: 600;
    }
    .y_replacement_img_2_img{
        top: 0rem;
        right: 0rem;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1rem;
        height: 1rem;
    }
    .y_replacement_img_2_img img{
        width: 0.28rem;
        height: 0.28rem;
    }
    .y_replacement_img_3{
        margin: 0rem 0.3rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 2.5rem;
        position: relative;
    }
    .y_replacement_img_4{
        width: 1.3rem;
        margin-right: 0.3rem;
    }
    .y_replacement_img_4>p{
        width: 100%;
        text-align: center;
        color: #333;
        font-size: 0.24rem;
        font-weight: 400;
        margin-top: 0.2rem;
    }
    .y_replacement_img_5{
        width: 1.3rem;
        height: 1.3rem;
        background: #fff;
        border-radius: 0.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .y_replacement_img_5 img{
        width: 0.5rem;
    }
    .y_replacement_img_6{
        background: #fff;
        height: 5rem;
        overflow-y: scroll;
    }
    .y_replacement_img_7{
        margin: 0rem 0.3rem;
    }
    .y_replacement_img_8{
        width: 100%;
        height: 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_replacement_img_8 h2{
        font-size: 0.26rem;
        color: #333;
    }
    .y_replacement_img_9{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .y_replacement_img_10{
        position: relative;
        width: 32%;
        margin-right: 2%;
        margin-bottom: 0.2rem;
    }
    .y_replacement_img_9 .y_replacement_img_10:nth-child(3n){
        margin-right: 0%; 
    }
    .y_replacement_img_10>img{
        width: 100%;
        height: 2.2rem;
        object-fit: cover;
    }
    .y_replacement_img_11{
        position: absolute;
        width: 0.65rem;
        top: 0.1rem;
        left: 0.1rem;
    }
    .y_replacement_img_11 img{
        width: 100%;
        object-fit: cover;
    }

    .y_tuisong_1{
        z-index: 15;
    }
    .y_sweep_settings_25{
        margin-bottom: 0.2rem;
    }
    .y_recruit_two_shade_block{
        z-index: 10;
    }
    .y_sweep_select_2{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex:1;
    }
    .y_sweep_select_2 /deep/ input{
        height: 0.8rem;
        line-height: 0.8rem;
        border-radius: 0rem;
        padding: 0rem;
        border: none;
        font-size: 0.3rem;
        text-align: right;
        flex: 1;
        margin-right: 0.1rem;
    }
    .y_sweep_select_3{
        flex:1;
    }

    /* 修改 */
    .y_sweep_settings_xg_0{
        padding: 0.5rem 0.3rem !important;
    }
    .y_sweep_settings_xg_1{
        width: 100%;
    }
    .y_sweep_settings_xg_2{
        margin-bottom: 0.3rem;
    }
    .y_sweep_settings_xg_2 p{
        font-size: 0.3rem;
        color:#333;
    }
    .y_sweep_settings_xg_2 img{
        max-width: 100%;
        object-fit: cover;
    }
    .listshowpersonshow{
        height: 1rem;
        border-radius: 0.1rem;
        background: #FFFBED;
        padding: 0 0.3rem;
        display: flex;
        align-items: center;
    }
    .listshowpersonshow p{
        font-size: 0.28rem;
        color: #FECE0A;
    }
    .listshowpersonshow p:nth-child(2){
        margin-left: auto;
        margin-right: 0.11rem;
    }
    .y_sweep_settings_xg_3{
        width: 1.3rem;
        height: 1.9rem;
        position: absolute;
        top: 0.3rem;
        opacity: 0;
        z-index: 1;
    }
    .y_sweep_settings_13_1{
        width: 100%;
    }
    .y_sweep_settings_13_1 p{
        font-size: 0.2rem;
        background: #ffe4e4;
        color: #fd1818;
        padding: 0.02rem 0rem 0.02rem 0.15rem;
        border-radius: 0.05rem;
        font-weight: 900;
    }
    .y_sweep_settings_17_1{
        margin-top: 0rem;
    }
    .y_sweep_settings_17_2{
        background: #FECE0A;
        padding-left: 0.2rem;
        border-radius: 0.1rem;
        height: 0.8rem;
        margin-bottom: 0.2rem;
    }
    .y_sweep_settings_17_2 h2{
        font-size: 0.28rem;
        color: #333;
        line-height: 0.8rem;
        font-weight: 600;
    }
    .y_sweep_settings_xg_2_xg_0{
        height: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_sweep_settings_xg_2_xg_1{
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
    }
    .y_sweep_settings_xg_2_xg_2{
        width: 100%;
    }
    .y_sweep_settings_xg_2_xg_2 h2{
        font-size: 0.32rem;
        color: #333;
        font-weight: 600;
    }
    .y_sweep_settings_xg_2_xg_3{
        width: 100%;
        margin-top: 0.1rem;
    }
    .y_sweep_settings_xg_2_xg_3 p{
        font-size: 0.24rem;
        color: #ccc;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
    .y_sweep_settings_xg_2_xg_4{
        margin-left: 0.2rem;
    }
    .y_sweep_settings_xg_2_xg_4 p{
        height: 0.5rem;
        line-height: 0.5rem;
        background: #FECE0A;
        color: #fff;
        font-size: 0.26rem;
        padding: 0rem 0.28rem;
        border-radius: 0.1rem;
    }
</style>
<script>
import { Dialog } from "vant";
import kefu from '../../components/kefu/kefu'
import push_charges from '../../components/push_charges/push_charges'

import axios from 'axios'   // 接口
import {configuration_fields} from '../../api/request.js'   // 配置字段
import {award_picture_list} from '../../api/request.js'   // 奖品图片列表
import {award_info} from '../../api/request.js'   // 抽奖详情
import {award_contact_list} from '../../api/request.js' // 联系人列表


export default {
        data(){
            return {
                docmHeight: document.documentElement.clientHeight ||document.body.clientHeight,
                showHeight: document.documentElement.clientHeight ||document.body.clientHeight,
                hideshow:true,  //显示或者隐藏footer


                shade_value:'0',  // 公共 阴影
                input:'',
                hyzl_value:false,    // 好友助力
                is_help:'0', 
                hyzd_value:true,    // 好友组队
                is_group:'0',
                replacement_img_is:'0', // 选择图片 值
                max_single:'',      // 单个队伍最多

                business_award_info:'', // 商务端-抽奖说明
                business_award_open_info:'', // 商务端-开奖方式介绍
                description_value:'0',   // 说明弹窗 0是关闭 1是显示
                introduction_value:'0',  // 开奖方式介绍弹窗 0是关闭 1是显示
                prizes_img_lists:'',      // 奖品图片列表
                // 添加奖品列表  没有几等奖
                award_prize_lists:[
                    {title:'',number:'',people_number:'',img:'',deposit_fee:'',level:''},
                ],
                // 添加奖品列表 存用作添加 没有几等奖
                award_prize_list_c:{title:'',number:'',people_number:'',img:'',deposit_fee:'',level:''},
                // 奖品等级 列表
                award_list:[
                    {name:'一等奖'},
                    {name:'二等奖'},
                    {name:'三等奖'},
                    {name:'四等奖'},
                    {name:'五等奖'},
                    {name:'六等奖'},
                    {name:'七等奖'},
                    {name:'八等奖'},
                    {name:'九等奖'},
                    {name:'十等奖'},
                ],
                // 开奖方式介绍 列表
                winning_method_lists:[
                    {title:'按时间开奖',is:'1',value:'time',select:'0'},
                    {title:'按人数开奖',is:'0',value:'people',select:'1'},
                    {title:'即抽即开奖',is:'0',value:'now',select:'2'},
                ],
                number_people_input_value:'',   // 按人数开奖 提示人数值
                winning_method_value:'time',  // 开奖方式介绍 选中的value
                winning_method_select:'0', // 开奖方式介绍 选中的select
                open_time:'', // 传 开奖时间
                award_list_index:'',   // 点击显示替换图片库 index

                award_contact_text:'点击设置',  // 领奖联系方式 文字显示
                contact_list_id:'',  // 领奖联系方式 id
                textarea:'',   // 抽奖说明
                textarea_xz:'',  // 抽奖需知
                textarea_lz:'',  // 领奖需知
                contact_list_phone:'',  // 领奖联系方式 phone

                time_one_Value:'点击设定', // 按时间开奖
                pop_time_one_value:'0',

                prize_time_input_two:'',  // 按人数开奖 input
                time_two_Value:'点击设定', // 按人数开奖
                pop_time_two_value:'0',

                prize_time_input_three:'', // 即抽即开奖 input
                time_three_Value:'点击设定', // 即抽即开奖
                pop_time_three_value:'0',
                // open_time:'', // 传 开奖时间
                uplist_list:'',   // 可添加图文介绍 / 一键复制框 数组 传传传传传
                uplist_list_chun:'',  // 展示 可添加图文介绍 / 一键复制框 数组
                rich_text:undefined, //可添加图文介绍 / 一键复制框 跳转判断

                contact_list_id_one:'',  // 单独存领奖联系方式 id
                award_id:'0',  // 订单id 默认为0,详情会改变

                adspace_id:'0',       // 广告位列表，高级推广 进入传id
                promotion_value:'0',  // 进入传value  0代表正常进入，1为待推送进入
                minDate:new Date(),
                bj_news:'0', // 编辑新推送 0为正常进入 1是编辑新推送
            }
        },
        components:{
            kefu,
            push_charges,
        },
        created(){
            // 获取页面传参 推送类型
            this.contact_list_id = this.$route.query.contact_list_id;
            this.contact_list_phone = this.$route.query.contact_list_phone;

            if(this.$route.query.adspace_id !== undefined){
                this.adspace_id = this.$route.query.adspace_id
                this.promotion_value = this.$route.query.promotion_value
                this.bj_news = this.$route.query.bj_news
            }
        },
        watch:{
            '$route'(val,from){
              // 帖子传参
              console.log(val,'sweepstakes_settings')
              let contact_list_id = val.query.contact_list_id
              this.contact_list_id = contact_list_id
              let contact_list_phone = val.query.contact_list_phone
              this.contact_list_phone = contact_list_phone
              let rich_text = val.query.rich_text
     
              this.rich_text = rich_text
              
              let contact_list_phone_one = val.query.contact_list_phone_one
              if(contact_list_phone_one == '0'){
                  if(contact_list_phone !== ''){
                      this.award_contact_text = contact_list_phone
                      this.contact_list_id_one = contact_list_id
                  }
              }
              if(rich_text == '1'){
                  let uplist_list = JSON.parse(sessionStorage.getItem('uplist_list'));
                  this.uplist_list = uplist_list
                  let uplist_list_chun = JSON.parse(sessionStorage.getItem('uplist_list_chun'));
                  this.uplist_list_chun = uplist_list_chun
              }
            },
            //监听显示高度
            showHeight:function() {
                if(this.docmHeight > this.showHeight){
                    //隐藏
                    this.hideshow=false
                }else{
                    //显示
                    this.hideshow=true
                }
            }
        },
        mounted(){
            // let arr = [55,12,53,36,59]
            // let max=Math.max.apply(null,arr);
            // console.log(max);

            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            let adspace_id = this.adspace_id
            let promotion_value = this.promotion_value
            // 抽奖详情
            if(promotion_value == '1'){
                award_info({token:token,award_id:adspace_id}).then((res) =>{ 
                    console.log(res,'抽奖详情')
                    this.award_id = res.award.id
                    let award_prize_lists = []   // 奖品循环
                    let Assignment_award_prize_lists = res.award_prize
                    for(let i=0;i<Assignment_award_prize_lists.length;i++){
                        let arr = {title:Assignment_award_prize_lists[i].title,number:Assignment_award_prize_lists[i].number,people_number:Assignment_award_prize_lists[i].people_number,img:Assignment_award_prize_lists[i].img,deposit_fee:Assignment_award_prize_lists[i].deposit_fee,level:Assignment_award_prize_lists[i].level}
                        award_prize_lists.push(arr)
                    }
                    this.award_prize_lists = award_prize_lists
                    let winning_method_lists = this.winning_method_lists
                    for(let i=0;i<winning_method_lists.length;i++){
                        winning_method_lists[i].is = '0'
                        if(winning_method_lists[i].value == res.award.open_type){
                            winning_method_lists[i].is = '1'
                        }
                    }
                    this.winning_method_value = res.award.open_type
                    if(res.award.open_type == 'time'){
                        this.winning_method_select = '0'
                    }else if(res.award.open_type == 'people'){
                        this.winning_method_select = '1'
                    }else if(res.award.open_type == 'now'){
                        this.winning_method_select = '2'
                    }

                    this.time_one_Value = res.award.open_time_string
                    this.prize_time_input_two = res.award.open_people_number
                    this.time_two_Value = res.award.open_time_string
                    this.prize_time_input_three = res.award.now_max_people_number	
                    this.time_three_Value = res.award.now_endtime_string
                    // 联系人列表
                    award_contact_list({token:token,page:'1'}).then((res_lxr) =>{
                        console.log(res_lxr,'联系人列表')
                        let arr_lxr = res_lxr.data
                        let id_lxr = res.award.award_contact_ids
                        for(let i=0;i<arr_lxr.length;i++){
                            if(arr_lxr[i].id == id_lxr){
                                this.award_contact_text = arr_lxr[i].phone // 领奖联系方式
                                this.contact_list_id_one = id_lxr
                            }
                        }
                    })
                    this.textarea = res.award.info  // 抽奖说明
                    this.textarea_xz = res.award.notice // 抽奖须知
                    this.textarea_lz = res.award.get_prize_info // 领奖说明
                    let arr_content = res.award.content
                    if(arr_content == ''){
                        this.rich_text = undefined
                    }else{
                        this.rich_text = true
                        this.uplist_list = res.award.content
                        this.uplist_list_chun = res.award.content
                    }
                    if(res.award.is_help == '0'){
                        this.hyzl_value = false
                    }else if(res.award.is_help == '1'){
                        this.hyzl_value = true
                    }
                    this.is_help = res.award.is_help
                    if(res.award.is_group == '0'){
                        this.hyzd_value = false
                    }else if(res.award.is_group == '1'){
                        this.hyzd_value = true
                    }
                    this.is_group = res.award.is_group

                    this.max_single = res.award.group_max_people_number  // 单个队伍最多
                })
            }
            //监听事件
            window.onresize = ()=>{
              return(()=>{
                  this.showHeight = document.documentElement.clientHeight || document.body.clientHeight;
              })()
            },
            // 配置字段
            configuration_fields({}).then((res) => {
                console.log(res,'配置字段')
                this.business_award_info = res.data.business_award_info
                this.business_award_open_info = res.data.business_award_open_info
            })
            // 奖品图片列表
            award_picture_list({}).then((res) =>{
                console.log(res,'奖品图片列表')
                this.prizes_img_lists = res.data
            })
            let contact_list_id = this.contact_list_id
            let contact_list_phone = this.contact_list_phone
            if(contact_list_id !== ''){
                this.award_contact_text = contact_list_phone
            }
        },
        methods: {
            // 返回
            back_to(){
                this.$router.push({
                    path:'/',
                })
            },
            // banner 图片上传成功
            banner_success(res, file) {
                console.log(res,file)
                let award_list_index = this.award_list_index
                let award_prize_lists = JSON.parse(JSON.stringify(this.award_prize_lists))
                for(let i=0;i<award_prize_lists.length;i++){
                    if(i == award_list_index){
                        award_prize_lists[i].img = res.img
                    }
                }
                this.award_prize_lists = award_prize_lists
                this.shade_value = '0'
                this.replacement_img_is = '0'
            },
            // banner 图片上传设置
            banner_settings(file) {
                console.log(file)
            },
            // 奖品图库 显示传index
            replacement_img(index){
                this.shade_value = '1'
                this.replacement_img_is = '1'
                this.award_list_index = index
            },
            // 选择图片 关闭
            replacement_none(){
                this.shade_value = '0'
                this.replacement_img_is = '0'
            },
            // 按人数开奖-最少指定人数 展示
            number_people_input(){
                let award_prize_lists = this.award_prize_lists
                let list = []
                for(let i=0;i<award_prize_lists.length;i++){
                    list.push((award_prize_lists[i].number*award_prize_lists[i].people_number)*1)
                }
                var max1 = Math.max.apply(null,list);
                this.number_people_input_value = max1
                console.log(max1,list)
            },
            // 按人数开奖-最少指定人数 判断展示提示
            number_people_input_val(){
                let number_people_input_value = this.number_people_input_value
                let prize_time_input_two = this.prize_time_input_two
                console.log(prize_time_input_two)
            },
            // 提交
            pop_up(){
                let award_id = this.award_id  //添加为0
                let award_prize_lists = this.award_prize_lists
                let prizes_img_lists = this.prizes_img_lists
                for(let i=0;i<award_prize_lists.length;i++){
                    if(award_prize_lists[i].img == ''){
                        award_prize_lists[i].img = prizes_img_lists[0].img
                    }
                }
                let award_prize_json_level =  JSON.parse(JSON.stringify(this.award_prize_lists))  // 奖项信息
                for(let i=0;i<award_prize_json_level.length;i++){
                    award_prize_json_level[i].level = i+1*1
                }
                let award_prize_json = award_prize_json_level  // 传
                let open_type = this.winning_method_value  // 开奖方式介绍 选中的value
                let open_time_select =  this.winning_method_select  // 开奖方式介绍 选中的select
                if(open_time_select == '0'){
                    this.open_time = this.time_one_Value
                }else if(open_time_select == '1'){
                    this.open_time = this.time_two_Value
                }else if(open_time_select == '2'){
                    this.open_time = this.time_three_Value
                    this.hyzl_value = false
                    this.hyzd_value = false
                }
                let open_time = this.open_time   // 传 	开奖时间
                let open_people_number = this.prize_time_input_two // 自动开奖人数
        
                let now_max_people_number_let = ''
                for(let i=0;i<award_prize_lists.length;i++){
                    now_max_people_number_let = now_max_people_number_let*1 + (award_prize_lists[i].number*1)*(award_prize_lists[i].people_number*1)
                }
                this.prize_time_input_three = now_max_people_number_let
                let now_max_people_number = this.prize_time_input_three  // 即抽人数上限
                let now_endtime = this.time_three_Value   // 即抽截止时间
                let award_contact_ids = this.contact_list_id_one  //  领奖联系人id
                let info = this.textarea   // 抽奖说明
                let content = this.uplist_list // 可添加图文介绍 / 一键复制框 数组
                let notice = this.textarea_xz  // 抽奖需知
                let get_prize_info = this.textarea_lz  // 领奖说明
                let is_help_value = this.hyzl_value  // 好友助力
                if(is_help_value == true){
                    this.is_help = '1'
                }else if(is_help_value == false){
                    this.is_help = '0'
                }
                let is_help = this.is_help  // 是否可邀请助力 传
                let is_group_value = this.hyzd_value  // 好友组队
                if(is_group_value == true){
                    this.is_group = '1'
                }else if(is_group_value == false){
                    this.is_group = '0'
                }
                let is_group = this.is_group  // 是否可组队 传
                let group_max_people_number = this.max_single  // 队伍人数
                
                let sweepstakes_list = {award_id:award_id,award_prize_json:award_prize_json,open_type:open_type,open_time:open_time,open_people_number:open_people_number,now_max_people_number:now_max_people_number,now_endtime:now_endtime,award_contact_ids:award_contact_ids,info:info,content:content,notice:notice,get_prize_info:get_prize_info,is_help:is_help,is_group:is_group,group_max_people_number:group_max_people_number}
                let dd = JSON.stringify(sweepstakes_list)
                console.log(this.contact_list_id,award_contact_ids,'-0-0-0-',sweepstakes_list,dd)
                if(is_group == '1' && is_help == '1'){
                    this.$message({
                        message:'助力和组队只能选择其中一项',
                        duration:'2000'
                    })
                    return
                }
                sessionStorage.setItem("sweepstakes_list",dd)

                let adspace_id = this.adspace_id
                let promotion_value = this.promotion_value
                if(promotion_value == '0'){
                    this.$router.push("/sweepstakes_settings_two")
                }else if(promotion_value == '1'){
                    let bj_news = this.bj_news
                    if(bj_news == '1'){
                        this.$router.push({
                            path:'/sweepstakes_settings_two',
                            query: {
                                adspace_id:adspace_id,
                                promotion_value:promotion_value,
                                bj_news:'1'
                            }
                        })
                    }else{
                        this.$router.push({
                            path:'/sweepstakes_settings_two',
                            query: {
                                adspace_id:adspace_id,
                                promotion_value:promotion_value,
                            }
                        })
                    }
                }
                
            },
            // 说明弹窗 显示
            description_show(){
                this.description_value = '1'
                this.shade_value = '1'
            },
            // 说明弹窗 关闭
            description_hide(){
                this.description_value = '0'
                this.shade_value = '0'
            },
            // 开奖方式说明弹窗 显示
            introduction_show(){
                this.introduction_value = '1'
                this.shade_value = '1'
            },
            // 开奖方式说明弹窗 关闭
            introduction_hide(){
                this.introduction_value = '0'
                this.shade_value = '0'
            },
            // 奖品图库 图片替换
            img_replacement(index){
                console.log(index)
                let prizes_img_lists = this.prizes_img_lists
                let award_list_index = this.award_list_index
                let award_prize_lists = JSON.parse(JSON.stringify(this.award_prize_lists))
                for(let i=0;i<prizes_img_lists.length;i++){
                    if(i == index){
                        award_prize_lists[award_list_index].img = prizes_img_lists[i].img
                    }
                }
                this.award_prize_lists = award_prize_lists
                this.shade_value = '0'
                this.replacement_img_is = '0'
            },
            // 奖品列表增加
            replacement_add(){
                let award_prize_lists = JSON.parse(JSON.stringify(this.award_prize_lists))
                let award_prize_list_c = JSON.parse(JSON.stringify(this.award_prize_list_c))
                award_prize_lists.push(award_prize_list_c)
                this.award_prize_lists = award_prize_lists
            },
            // 可添加图文介绍 / 一键复制框
            add_awards(){
                let adspace_id = this.adspace_id
                let promotion_value = this.promotion_value

                if(promotion_value == '1'){
                    this.$router.push({
                        path:'/richtext',
                        query: {
                            adspace_id:adspace_id,
                            promotion_value:promotion_value,
                        }
                    })
                }else{
                    this.$router.push({
                        path:'/richtext',
                    })
                }
            },
            //  奖品列表删除
            replacement_dele(index){
                let award_prize_lists = JSON.parse(JSON.stringify(this.award_prize_lists))
                for(let i=0;i<award_prize_lists.length;i++){
                    if(i == index){
                        award_prize_lists.splice(index,1)
                    }
                }
                this.award_prize_lists = award_prize_lists
            },
            // 开奖方式选择
            winning_select(index){
                let winning_method_lists = this.winning_method_lists
                for(let i=0;i<winning_method_lists.length;i++){
                    winning_method_lists[i].is = '0'
                    if(i == index){
                        winning_method_lists[i].is = '1'
                        if(i == '0'){
                            this.winning_method_value = 'time'
                            this.winning_method_select = '0'
                        }else if(i == '1'){
                            this.winning_method_value = 'people'
                            this.winning_method_select = '1'
                        }else if(i == '2'){
                            this.winning_method_value = 'now'
                            this.winning_method_select = '2'

                        }
                    }
                }
                this.winning_method_lists = winning_method_lists
            },
            // 领奖联系方式 选择联系人  
            contact_jump(){
                let adspace_id = this.adspace_id
                let promotion_value = this.promotion_value
                if(promotion_value == '1'){
                    this.$router.push({
                        path:'/sweepstakes_settings_lxr',
                        query: {
                            contact_jump_judgment:'0',
                            adspace_id:adspace_id,
                            promotion_value:promotion_value,
                        }
                    })
                }else{
                    this.$router.push({
                        path:'/sweepstakes_settings_lxr',
                        query: {
                            contact_jump_judgment:'0',
                        }
                    })
                }
                
            },
            // 邀请好友助力
            hyzl_value_click(){
                let hyzl_value = this.hyzl_value
                if(hyzl_value == true){
                    this.hyzd_value = false
                }
            },
            // 邀请好友组队
            hyzd_value_click(){
                let hyzd_value = this.hyzd_value
                if(hyzd_value == true){
                    this.hyzl_value = false
                }
            },
            // 第一个时间选择 弹窗
            pop_time_one_block(e){
                this.shade_value = '1'
                this.pop_time_one_value = '1'
            },
            pop_time_two_block(e){
                this.shade_value = '1'
                this.pop_time_two_value = '1'
            },
            pop_time_three_block(e){
                this.shade_value = '1'
                this.pop_time_three_value = '1'
            },
            pop_time_one_dete(val){
                let year = val.getFullYear()
                let month = val.getMonth() + 1
                let day = val.getDate()
                let hour = val.getHours()
                let minute = val.getMinutes()
                if (month >= 1 && month <= 9) { month = `0${month}` }
                if (day >= 1 && day <= 9) { day = `0${day}` }
                if (hour >= 0 && hour <= 9) { hour = `0${hour}` }
                if (minute >= 0 && minute <= 9) { minute = `0${minute}` }
                this.time_one_Value = `${year}-${month}-${day} ${hour}:${minute}`

                this.shade_value = '0'
                this.pop_time_one_value = '0'
                console.log(this.time_one_Value)
            },
            pop_time_two_dete(val){
                let year = val.getFullYear()
                let month = val.getMonth() + 1
                let day = val.getDate()
                let hour = val.getHours()
                let minute = val.getMinutes()
                if (month >= 1 && month <= 9) { month = `0${month}` }
                if (day >= 1 && day <= 9) { day = `0${day}` }
                if (hour >= 0 && hour <= 9) { hour = `0${hour}` }
                if (minute >= 0 && minute <= 9) { minute = `0${minute}` }
                this.time_two_Value = `${year}-${month}-${day} ${hour}:${minute}`

                this.shade_value = '0'
                this.pop_time_two_value = '0'
                console.log(this.time_two_Value)
            },
            pop_time_three_dete(val){
                let year = val.getFullYear()
                let month = val.getMonth() + 1
                let day = val.getDate()
                let hour = val.getHours()
                let minute = val.getMinutes()
                if (month >= 1 && month <= 9) { month = `0${month}` }
                if (day >= 1 && day <= 9) { day = `0${day}` }
                if (hour >= 0 && hour <= 9) { hour = `0${hour}` }
                if (minute >= 0 && minute <= 9) { minute = `0${minute}` }
                this.time_three_Value = `${year}-${month}-${day} ${hour}:${minute}`

                this.shade_value = '0'
                this.pop_time_three_value = '0'
                console.log(this.time_three_Value)
            },
            pop_time_one_cancel(e){
                this.shade_value = '0'
                this.pop_time_one_value = '0'
            },
            pop_time_two_cancel(e){
                this.shade_value = '0'
                this.pop_time_two_value = '0'
            },
            pop_time_three_cancel(e){
                this.shade_value = '0'
                this.pop_time_three_value = '0'
            },
        },
    }
</script>