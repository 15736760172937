
<template>
    <div class="y_my_edit_profile_1">
        <div class="y_my_certification_1">
            <div class="y_my_certification_2">
                <div class="y_my_certification_3">
                    <h2>【个人认证】</h2>
                    <p>{{gr_accreditation}}</p>
                </div>
                <div class="y_my_certification_3">
                    <h2>【高级认证】</h2>
                    <p>{{gj_accreditation}}</p>
                </div>
            </div>

            <div class="y_my_certification_4">
                <router-link to="/my_personal_certification_one" class="y_my_certification_5">
                    <p>*个人认证</p>
                </router-link>
                <router-link to="/my_advanced_certification_one" class="y_my_certification_5">
                    <p>高级认证</p>
                </router-link>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .y_my_edit_profile_1{
        min-height: 100vh;
        width: 100%;
        background: #F4F5F7;
    }
    .y_my_certification_1{
        padding: 0.3rem 0.25rem 0rem;
    }
    .y_my_certification_2{
        width: 100%;
        border-radius: 0.1rem;
        background: #fff;
        overflow: hidden;
        box-shadow: 0 0 0.2rem 0.05rem #ccc;
    }
    .y_my_certification_3{
        min-height: 2rem;
        display: flex;
        flex-wrap: wrap;
        padding: 0rem 0.4rem;
        align-content: center;
    }
    .y_my_certification_3 h2{
        font-size: 0.32rem;
        color: #333;
        font-weight: 600;
        width: 100%;
    }
    .y_my_certification_3 p{
        width: 100%;
        font-size: 0.28rem;
        color: #999;
        margin-top: 0.2rem;
    }
    .y_my_certification_4{
        width: 100%;
        margin-top: 0.9rem;
    }
    .y_my_certification_5{
        width: 100%;
        height: 1rem;
        border-radius: 0.1rem;
        overflow: hidden;
        margin-bottom: 0.4rem;
        display: block;
    }
    .y_my_certification_5 p{
        width: 100%;
        height: 1rem;
        line-height: 1rem;
        background: #FECE0A;
        text-align: center;
        color: #333;
        font-size: 0.32rem;
        font-weight: 600;
    }
</style>
<script>
    import axios from 'axios'   // 接口
    import {configuration_fields} from '../../api/request.js' // 配置字段的信息

    export default {
        data(){
            return {
                input: '',
                gr_accreditation:'',
                gj_accreditation:'',
            }
        },  
        components:{

        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            // 配置字段的信息
            configuration_fields({}).then((res)=>{
                console.log(res,'配置字段的信息')
                this.gr_accreditation = res.data.personal_authentication
                this.gj_accreditation = res.data.advanced_certification
            })
        },
        methods: {
           
        },
    }

</script>