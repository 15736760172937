<template>
    <div class="y_my_edit_profile_1">
        <div class="y_invite_new_1">
            <div class="y_invite_new_2">
                <div class="y_invite_new_3">
                    <div class="y_invite_new_4">
                        <h2>累计总收益：</h2>
                        <p>{{my_rebate_fee_total}}元</p>
                    </div>
                    <router-link to="/my_earnings" class="y_invite_new_5">
                        <p>查看详情</p>
                    </router-link>
                </div>
                <div class="y_invite_new_3">
                    <div class="y_invite_new_4">
                        <h2>本月收益：</h2>
                        <p>{{rebate_fee_month}}元</p>
                    </div>
                </div>
            </div>

            <div class="y_invite_new_6">
                <div class="y_invite_new_7">
                    <div class="y_invite_new_8">
                        <h2>活动一</h2>
                        <!-- <p>仅剩1天10小时56分</p> -->
                        <p>活动规则</p>
                    </div>
                    <div class="y_invite_new_9">
                        <p>邀请新用户使用喵君商务端服务，应邀用户使用本金消费多少钱，您得<span>{{configuration_list.rebate_ratio*100}}%</span>返现收益，每位新用户最多在最初{{configuration_list.rebate_days}}天内给您产生<span>{{configuration_list.rebate_fee_limit}}元</span>返现收益，多邀多得，不设上限。</p>
                        <div class="y_invite_new_10">
                            <p>去邀请</p>
                        </div>
                    </div>
                    <div class="y_my_index_27">
                        <div class="y_my_index_28">
                            <p><span>{{rebates_user_count}}</span>名喵君商务端用户已邀新获得现金</p>
                            <h2>￥{{rebate_fee_total}}</h2>
                        </div>
                        <div class="y_my_index_29">
                            <div class="y_my_index_30 y_flex_1">
                                <h2>排名</h2>
                            </div>
                            <div class="y_my_index_30 y_flex_6">
                                <h2>用户</h2>
                            </div>
                            <div class="y_my_index_30 y_flex_2">
                                <h2>已获现金</h2>
                            </div>
                        </div>
                        <div class="y_my_index_31">
                            <div class="y_my_index_32" v-for="(item,index) in rebates_list" :key="index">
                                <div class="y_my_index_33 y_flex_1" v-if="index == '0'?true:false">
                                    <img src="../../../images/icon_90.png" alt="">
                                </div>
                                <div class="y_my_index_33 y_flex_1" v-if="index == '1'?true:false">
                                    <img src="../../../images/icon_91.png" alt="">
                                </div>
                                <div class="y_my_index_33 y_flex_1" v-if="index == '2'?true:false">
                                    <img src="../../../images/icon_92.png" alt="">
                                </div>
                                <div class="y_my_index_33 y_flex_1" v-if="index > '2'?true:false">
                                    <p>{{index}}</p>
                                </div>
                                <div class="y_my_index_34 y_flex_6">
                                    <h2>{{item.phone}}</h2>
                                </div>
                                <div class="y_my_index_35 y_flex_2">
                                    <p>￥{{item.rebate_fee_total}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .y_my_edit_profile_1{
        min-height: 100vh;
        width: 100%;
        background: #F4F5F7;
        height: 100%;
    }
    .y_invite_new_1{
        width: 100%;
        background: #fff;
        height: 1.8rem;
    }
    .y_invite_new_2{
        padding: 0rem 0.25rem;
    }
    .y_invite_new_3{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0.9rem;
    }
    .y_invite_new_4{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_invite_new_4 h2{
        font-size: 0.28rem;
        color: #333;
        margin-right: 0.2rem;
    }
    .y_invite_new_4 p{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_invite_new_5{
        display: inline-block;
        padding: 0rem 0.2rem;
        background: #FECE0A;
        line-height: 0.5rem;
        height: 0.5rem;
        color: #fff;
        font-size: 0.24rem;
        border-radius: 1rem;
        overflow: hidden;
    }

    .y_invite_new_6{
        margin: 0.3rem 0.25rem 0rem;
        background: #fff;
    }
    .y_invite_new_7{
        width: 100%;
        border-radius: 0.1rem;
        overflow: hidden;
    }
    .y_invite_new_8{
        background: #FECE0A;
        height: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0rem 0.3rem;
    }
    .y_invite_new_8 h2{
        font-size: 0.28rem;
        color: #333;
    }
    .y_invite_new_8 p{
        font-size: 0.24rem;
        color: #333;
    }
    .y_invite_new_9{
        margin-top: 0.3rem;
        padding: 0rem 0.25rem 0.2rem;
        border-bottom: 0.01rem solid #EEEEEE;
    }
    .y_invite_new_9 p{
        width: 100%;
        font-size: 0.3rem;
        color: #333;
        font-weight: 600;
    }
    .y_invite_new_9 p span{
        color: #FE0A0A;
        font-size: 0.3rem;
        font-weight: 600;
    }
    .y_invite_new_10{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 0.2rem;
    }
    .y_invite_new_10 p{
        display: inline-block;
        text-align: center;
        background: #FECE0A;
        border-radius: 1rem;
        color: #fff;
        font-size: 0.24rem;
        width: 1.2rem;
        height: 0.5rem;
        line-height: 0.5rem;
    }


    /* my 我的首页 */
    .y_my_index_27{
        box-shadow: none;
        border-radius: 0.1rem;
        padding: 0rem 0.35rem;
    }
    .y_my_index_28{
        padding-top: 0.5rem;
    }
    .y_my_index_28 p{
        width: 100%;
        text-align: center;
        font-size: 0.3rem;
        color:#222;
    }
    .y_my_index_28 p span{
        color: #FECE0A;
        font-size: 0.3rem;
    }
    .y_my_index_28 h2{
        width: 100%;
        text-align: center;
        margin-top: 0.25rem;
        color: #FECE0A;
        font-size: 0.3rem;
    }
    .y_my_index_29{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0.65rem;
    }
    .y_my_index_30 h2{
        font-size: 0.24rem;
        color: #333;
    }
    .y_flex_1{
        flex: 1;
        margin-right: 0.4rem;
    }
    .y_flex_6{
        flex: 6;
    }
    .y_flex_2{
        flex: 2;
        text-align: right;
    }
    .y_my_index_31{
        width: 100%;
        margin-top: 0.3rem;
    }
    .y_my_index_32{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 1rem;
    }
    
    .y_my_index_33 img{
        width: 0.48rem;
        height: 0.41rem;
    }
    .y_my_index_33 p{
        width: 0.46rem;
        height: 0.46rem;
        background: #FDF6DA;
        border-radius: 1rem;
        overflow: hidden;
        text-align: center;
        line-height: 0.46rem;
        font-size: 0.26rem;
        color: #FECE0A;
    }
    .y_my_index_34{
        border-bottom: 0.01rem solid #EEEEEE;
        height: 100%;
    }
    .y_my_index_35{
        border-bottom: 0.01rem solid #EEEEEE;
        height: 100%;
    }
    .y_my_index_34 h2{
        color: #333;
        font-size: 0.26rem;
        font-weight: 600;
        line-height: 1rem;
    }
    .y_my_index_35 p{
        color: #FECE0A;
        font-size: 0.3rem;
        /* font-weight: 600; */
        line-height: 1rem;
    }
</style>
<script>
    import axios from 'axios'   // 接口
    import {rebate_user_list} from '../../api/request.js' // 返利用户列表
    import {rebate_detail} from '../../api/request.js' // 我的收益详情
    import {configuration_fields} from '../../api/request.js' // 配置字段的信息
    
    export default {
        data(){
            return {
                rebates_list:'', // 返利用户 列表
                rebates_user_count:'', // 返利用户总数
                rebate_fee_total:'', // 全部用户 累计获得返利金额
                configuration_list:'', // 配置字段的信息列表
                my_rebate_fee_total:'', // 我的 累计总收益
                rebate_fee_month:'', //    我的 月收入
            }
        },  
        components:{

        },
        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () {
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            // 返利用户列表
            rebate_user_list({parent_uid:'0'}).then((res) =>{
                console.log(res,'返利用户列表')
                this.rebates_user_count = res.user_count
                this.rebates_list = res.data
                this.rebate_fee_total = res.rebate_fee_total
            })
            // 我的收益详情
            rebate_detail({token:token}).then((res) =>{
                console.log(res,'我的收益详情')
                this.my_rebate_fee_total = res.rebate_fee_total
                this.rebate_fee_month = res.rebate_fee_month
            })
            // 配置字段的信息
            configuration_fields({}).then((res)=>{
                console.log(res,'配置字段的信息')
                this.configuration_list = res.data
            })
        },
        methods: {
           
        },
    }

</script>