
<template>
    <div class="y_user_comm_1">
        <p v-html="list.content"></p>
    </div>
</template>
<style>
    /* 使用攻略 */
    .y_user_comm_1{
        padding: 0rem;
        margin: 0.1rem;
    }
    .y_user_comm_1 p{
        color: #333;
        font-size: 0.3rem;
    }
</style>
<script>
    import axios from 'axios'   // 接口
    import {gonggao_detail} from '../../api/request.js' // 公告详情

    export default {
        data(){
            return {
                list:'',
                id:'',
            }
        },  
        components:{

        },
        created(){
            // 获取页面传参 推送类型
            this.id = this.$route.query.id;
            console.log(this.id)
            gonggao_detail({gonggao_id:this.id}).then((res)=>{
                if(res.error_code == '0'){
                    this.list = res.data
                }
            })
        },
        mounted(){
           
        },
        methods: {

        },

    }
</script>