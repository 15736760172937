<template>
    <div class="y_push_date_tg_1">
        <div class="y_push_date_tg_2">
            <div class="y_push_date_tg_3">
                <p>余额<span>{{ available_balance }}</span>元</p>
            </div>
            <div class="y_push_date_tg_4">
                <p @click="showDialog(true)">充值余额</p>
            </div>
        </div>

        <div class="y_push_date_tg_5">
            <div class="y_push_date_tg_6">
                <div class="y_push_date_tg_7">
                    <p>给每人最多推送 {{blog_list.max_push_number}}次</p>
                </div>
            </div>
            <div class="y_push_date_tg_9">
                <!-- <p>共有<span>{{blog_list.view_number}}</span>人浏览，人均浏览次数<span>N</span>次</p> -->
                <p>共有<span>{{blog_list.view_number_business}}</span>人浏览，人均浏览次数<span>{{(((blog_list.view_number_total_business*1)/(blog_list.view_number_business*1)).toFixed(0)) == 'NaN'?'0':''}}</span>次</p>
            </div>
            <!-- <div class="y_push_date_tg_9">
                <p>原设推送时间:{{blog_list.push_starttime}} ~ {{blog_list.push_endtime}}</p>
            </div> -->
            <div class="y_push_date_tg_6">
                <div class="y_push_date_tg_7">
                    <p>下线时间：</p>
                    <h2>{{blog_list.offline_time}}</h2>
                </div>
                <!-- <div class="y_push_date_tg_8">
                    <p @click="djs_show()">+时</p>
                </div> -->
            </div>

        </div>
        <div class="y_push_date_tg_10">
            <div class="y_push_date_tg_12">
                <p>单次曝光</p>
                <p>共完成/预设总曝光量</p>
                <p>扣费/预算</p>
            </div>
        </div>
        <div class="y_push_date_tg_13">
            <div class="y_push_date_tg_14">
                <div class="y_push_date_tg_15">
                    <div class="y_push_date_tg_16 y_push_date_tg_16_color">
                        <h2>{{cost_info_list.exposure_price}}元</h2>
                    </div>
                </div>
                <div class="y_push_date_tg_15">
                    <div class="y_push_date_tg_16">
                        <h2>{{cost_info_list.exposure_number - cost_info_list.left_exposure_number}}/{{cost_info_list.exposure_number}}</h2>
                    </div>
                </div>
                <div class="y_push_date_tg_15">
                    <div class="y_push_date_tg_16 y_push_date_tg_16_color">
                        <h2>{{(cost_info_list.exposure_number - cost_info_list.left_exposure_number)*cost_info_list.exposure_price}}元/{{(cost_info_list.exposure_number*cost_info_list.exposure_price).toFixed(2)}}元</h2>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <kefu></kefu>
    <recharge v-show="dialog_visible" :dialog_visible="dialog_visible" @dialogVisibleEvent="showDialog"></recharge>
</template>

<style scoped>
    .y_push_date_tg_1{
        width: 100%;
        min-height: 100vh;
        background: #F4F5F7;
    }
    .y_push_date_tg_2{
        padding: 0rem 0.45rem;
        height: 0.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .y_push_date_tg_3 p{
        font-size: 0.28rem;
        color: #333;
    }
    .y_push_date_tg_3 p span{
        color:#FECE0A;
        font-size: .28rem;
    }
    .y_push_date_tg_4 p{
        font-size: 0.28rem;
        color: #333;
        text-decoration:underline
    }
    .y_push_date_tg_5{
        padding: 0.28rem 0.25rem;
        background: #fff;
    }
    .y_push_date_tg_6{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.15rem;
    }
    .y_push_date_tg_7{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .y_push_date_tg_7 p{
        font-size: 0.24rem;
        color: #999;
    }
    .y_push_date_tg_7 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_7 h2{
        margin-left: 0.15rem;
        color: #333;
        /* font-weight: 600; */
        font-size: 0.28rem;
    }
    .y_push_date_tg_7 h2 span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_8{
        display: inline-block;
        background: #FECE0A;
        padding: 0rem 0.15rem;
        height: 0.46rem;
        border-radius: 0.1rem;
    }
    .y_push_date_tg_8 p{
        line-height: 0.46rem;
        color: #333;
        font-size: 0.26rem;
    }
    .y_push_date_tg_9{
        width: 100%;
        margin-bottom: 0.15rem;
    }
    .y_push_date_tg_9 p{
        color: #999;
        font-size: 0.24rem;
        line-height: 0.3rem;
    }
    .y_push_date_tg_9 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_10{
        width: 100%;
    }
    .y_push_date_tg_11{
        padding: 0rem 0.35rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.8rem;
    }
    .y_push_date_tg_11 p{
        color:#333;
        font-size: 0.28rem;
    }
    .y_push_date_tg_11 h2{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_12{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 0.8rem;
    }
    .y_push_date_tg_12 p{
        flex: 1;
        text-align: center;
        color: #999;
        font-size: 0.26rem;
    }
    .y_push_date_tg_12 p span{
        color: #FECE0A;
        font-size: 0.26rem;
    }
    .y_push_date_tg_13{
        width: 100%;
        background: #fff;
        /* padding-bottom: 0.4rem; */
    }
    .y_push_date_tg_14{
        display: flex;
        justify-content: flex-start;
    }
    .y_push_date_tg_15{
        flex:1;
    }
    .y_push_date_tg_16{
        width: 100%;
    }
    .y_push_date_tg_16 h2{
        width: 100%;
        line-height: 1rem;
        text-align: center;
        color: #333;
        font-size: 0.28rem;    
    }
    .y_push_date_tg_16_color h2{
        color: #FECE0A;
    } 
    .y_push_date_tg_17{
        width: 100%;
    }
    .y_push_date_tg_17>p{
        font-size: 0.28rem;
        color: #333;
        width: 100%;
        text-align: center;
    }
    .y_push_date_tg_18{
        width: 100%;
        text-align: center;
        margin-top: 0.25rem;
    }
    .y_push_date_tg_18 /deep/ input{
        width: 1.6rem;
        height: 0.8rem;
        border-radius: 0.1rem;
        background: #F7F8FA;
        overflow: hidden;
        padding: 0rem;
        line-height: 0.8rem;
        margin: 0 auto;
        font-size: 0.28rem;
        color: #ccc;
        text-align: center;
        border: none;
    }
    .y_push_date_tg_18 p{
        color: #FECE0A;
        font-size: 0.28rem;
        line-height: 0.8rem;
    }
    .y_push_date_tg_19{
        width: 100%;
        height: 5rem;
        background: transparent;
    }
    .y_push_date_tg_20{
        width: 100%;
        height: 2.7rem;
        background: #fff;
        position: fixed ;
        left: 0rem;
        bottom: 0rem;
    }
    .y_push_date_tg_21{
        display: flex;
        align-content: center;
        flex-wrap: wrap;
        padding: 0rem 0.25rem;
        height: 100%;
    }
    .y_push_date_tg_22{
        width: 100%;
        text-align: center;
    }
    .y_push_date_tg_22 p{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_date_tg_22 p span{
        color:#FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_tg_23{
        display: flex;
        margin-top: 0.2rem;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .y_push_date_tg_23 p{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_date_tg_23 h2{
        color: #FECE0A;
        font-size: 0.28rem;
        text-decoration:underline;
    }
    .y_push_date_tg_24{
        width: 100%;
        margin-top: 0.3rem;
    }
    .y_push_date_tg_24 p{
        height: 0.8rem;
        background: #FECE0A;
        line-height: 0.8rem;
        width: 100%;
        border-radius: 0.1rem;
        text-align: center;
        font-size:0.32rem;
        color: #333;
        font-weight: 600;
    }


    /* 招聘 */
    .y_push_date_zp_1{
        margin-bottom: 0.2rem;
    }
    .y_push_date_zp_2{
        height: 2.2rem;
    }
    .y_push_date_zp_3{
        height: 4rem;
    }
    .y_push_date_zp_4{
        border-top: 0.01rem solid #F3F3F3;
        width: 100%;
        margin-top: 0.4rem;
        padding-bottom: 0.1rem;
    }
    .y_push_date_zp_5{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 1rem;
    }
    .y_push_date_zp_6 h2{
        font-size: 0.3rem;
        color: #333;
        font-weight: 600;
    }
    .y_push_date_zp_7 p{
        color: #999;
        font-size: 0.28rem;
    }
    .y_push_date_zp_7 p span{
        color: #FECE0A;
        font-size: 0.28rem;
    }
    .y_push_date_zp_8{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 0.8rem;
    }
    .y_push_date_zp_9{
        width: 2.8rem;
        background: #F7F8FA;
        position: relative;
        height: 100%;
    }
    .y_push_date_zp_9 /deep/ input{
        width: 2rem;
        height: 0.8rem;
        font-size: 0.28rem;
        color: #333;
        background: transparent;
        border: none;
        line-height: 0.8rem;
        padding: 0rem 0rem 0rem 0.2rem;
    }
    .y_push_date_zp_10{
        position: absolute;
        top: 50%;
        display: inline-block;
        color: #333;
        font-size: 0.28rem;
        right: 0.3rem;
        transform: translate(0%,-50%);
    }
    .y_push_date_zp_11{
        width: 6.4rem;
        height: 3.6rem;
        background: #fff;
        border-radius: 0.1rem;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 20;
        display: none;
        flex-wrap: wrap;
        align-content: center;
    }
    .y_push_date_zp_11_active{
        display: flex;
    }
    .y_push_date_zp_12{
        width: 100%;
        text-align: center;
    }
    .y_push_date_zp_12 h2{
        color:#999;
        font-size: 0.3rem;
    }
    .y_push_date_zp_13{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 0.3rem;
    }
    .y_push_date_zp_14{
        background: #F7F8FA;
        height: 0.8rem;
        border-radius: 0.1rem;
        overflow: hidden;
    }
    .y_push_date_zp_14 /deep/ input{
        height: 0.8rem;
        padding: 0rem 0.1rem 0rem 0.15rem;
        background: transparent;
        width: 100%;
        border: none;
        font-size: 0.28rem;
        color: #FECE0A;
    }
    .y_push_date_zp_14_1{
        width: 1rem;
    }
    .y_push_date_zp_14_2{
        width: 0.6rem;
    }
    .y_push_date_zp_15{
        display: inline-block;
        margin: 0rem 0.1rem;
    }
    .y_push_date_zp_15 p{
        font-size: 0.28rem;
        color: #333;
    }
    .y_push_date_zp_16{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.35rem;
        width: 100%;
    }
    .y_push_date_zp_16 p{
        width: 2rem;
        height: 0.7rem;
        border-radius: 1rem;
        text-align: center;
        line-height: 0.7rem;
        font-size: 0.3rem;
    }
    .y_push_date_zp_17{
        background: #ECECEC;
        color: #666;
        margin-right: 0.1rem;
    }
    .y_push_date_zp_18{
        color: #333;
        background: #FECE0A;
    }
    .y_push_date_zp_19{
        width: 5.2rem;
        height: 5.6rem;
        background: #fff;
        border-radius: 0.1rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        position: fixed;
        display: none;
        align-content: center;
        flex-wrap: wrap;
        z-index: 20;
    }
    .y_push_date_zp_19_active{
        display: flex;
    }
    .y_push_date_zp_20{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.3rem;
    }
    .y_push_date_zp_20 h2{
        font-size: 0.3rem;
        color: #999;
    }
    .y_push_date_zp_20 p{
        font-size: 0.3rem;
        color: #333;
        margin-left: 0.1rem;
    }
    .y_push_date_zp_21{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.3rem;
    }
    .y_push_date_zp_21 p{
        color: #333;
        font-size: 0.28rem;
    }
    .y_push_date_zp_21 /deep/ .el-input{
        width: 1.3rem;
        margin: 0rem 0.1rem;
        padding:0rem;
        overflow: hidden;
    }
    .y_push_date_zp_21 /deep/ input{
        width: 1.3rem;
        height: 0.8rem;
        line-height: 0.8rem;
        background: #F7F8FA;
        text-align: center;
        border-radius: 0rem;
        border: none;
        color: #333;
        font-size: 0.28rem;
        padding:0rem 0.1rem;
    }
    .y_push_date_zp_22{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: 0.5rem;
    }
    .y_push_date_zp_22 p{
        width: 1.3rem;
        height: 0.7rem;
        border-radius: 0.1rem;
        text-align: center;
        font-size: 0.3rem;
        background: #ECECEC;
        line-height: 0.7rem;
        margin-left: 0.2rem;
        transition: ease 0.5s all;
    }
    .y_push_date_zp_22 .y_push_date_zp_22_active{
        transition: ease 0.5s all;
        background: #FECE0A;
    }   
    .y_publish_pop_6{
        width: 100%;
    }
</style>

<script>
    import kefu from '../../components/kefu/kefu'
    import recharge from '../../components/recharge/recharge'
    import axios from 'axios'   // 接口
    import {blog_info_info} from '../../api/request.js'   // 帖子详情,信息类推广
    import {user_info} from '../../api/request.js' // 用户信息
    export default {
        data(){
            return {
                shade_value:'0',
                comm_shadows_value:'',
                input: '',
                publish_pop_value:'0',
                djs_pop_value:'0',
                cs_pop_value:'0',

                //公共 钱包充值
                dialog_visible: false,
                available_balance:'',

                recruit_id:'',
                push_status:'',  // 1为帖子 2位招聘     

                blog_list:'',
                cost_info_list:'', 
            }
        },  
        components:{
            kefu,
            recharge,
            axios,
        },
        created(){
            // 获取页面传参 推送类型
            if(this.$route.query.recruit_id !== undefined){
                this.recruit_id = this.$route.query.recruit_id
                this.push_status = this.$route.query.push_status
            }
        },

        mounted(){
            let token = this.$cookies.get('token')
            if(token == null){
                this.$message({
                    message: '请先登录！',
                    duration:'2000'
                })
                var that = this;
                setTimeout(function () { 
                    that.$router.push({path:'/login'}) 
                },2500);
            }
            this.token = token
            let recruit_id = this.recruit_id
            blog_info_info({token:token,blog_id:recruit_id}).then((res) =>{
                console.log(res,'帖子类详情')
                this.blog_list = res.blog
                this.cost_info_list = res.cost_info
                this.push_endtime_stamptime = res.blog.push_endtime_stamptime
                this.max_push_number_input = res.blog.max_push_number
                this.max_push_number_day_input = res.blog.max_push_number_day
                this.recruit_date_new = res.blog.push_endtime
            })
            user_info({token:token}).then((res)=>{
                console.log(res,'用户信息')
                let balance = res.data.balance  // 	余额
                let bonus_fee = res.data.bonus_fee // 奖励金
                let frozen_fee = res.data.frozen_fee  // 冻结金额
                let deposit_fee = res.data.deposit_fee  // 押金金额
                let available_balance = ''      // 可用余额

                this.balance = balance
                this.bonus_fee = bonus_fee
                this.frozen_fee = frozen_fee
                this.deposit_fee = deposit_fee
                this.available_balance = balance*1 + bonus_fee*1
            })
        },
        methods: {
            // 充值
            showDialog(visible) {
                this.dialog_visible = visible;
            },
        },
    }
</script>